import ActionType from "./actionTypes";

export function expandSidebar(route: string) {
  return {
    type: ActionType.INVENTORY_SIDEBAR_EXPAND,
    route,
  };
}
export function collapseSidebar(route: string) {
  return {
    type: ActionType.INVENTORY_SIDEBAR_COLLAPSE,
    route,
  };
}
export function toggleSidebar(route: string) {
  return {
    type: ActionType.INVENTORY_SIDEBAR_TOGGLE,
    route,
  };
}
