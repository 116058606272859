import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-day-picker/lib/style.css";

import {
  ScheduleEventType,
  getFixedScheduleForAWeekRange,
  getRequestsForADay,
  getScheduleForADate,
} from "controllers/schedule";

import { convertToBusinessTimezoneMoment } from "utils/dateUtils";
import { getWeekDays } from "utils/manager";

const generateWeekRangeForSelectedDate = (
  sd: Date,
  business: TangoBusiness
) => {
  if (business) {
    const sdMoment = moment(sd).startOf("day").add(4, "hours");
    const sdNumber = sdMoment.day();
    const payrollFirstDayNumber = moment()
      .day(business?.payrollStartOfTheWeek)
      .get("day");
    if (sdNumber < payrollFirstDayNumber) {
      const adjustedWeekRangeStart = sdMoment
        .clone()
        .subtract(7, "days")
        .day(payrollFirstDayNumber);
      const adjustedSelectedDays = getWeekDays(adjustedWeekRangeStart.toDate());
      return adjustedSelectedDays;
    } else {
      const regularWeekRangeStart = sdMoment.clone().day(payrollFirstDayNumber);
      const regularSelectedDays = getWeekDays(regularWeekRangeStart.toDate());
      return regularSelectedDays;
    }
  } else {
    return [];
  }
};

interface DayPickerDayProps {
  requestType: ScheduleEventType | null;
  day: Date;
  business: TangoBusiness;
  schedulesWithLocalSchedule: TangoSchedule[];
  fixedSchedules: TangoFixedSchedule[];
  schedules: TangoSchedule[];
  scheduleEvents: ScheduleEvent[];
}

export const DayPickerDay = ({
  requestType,
  day,
  business,
  schedulesWithLocalSchedule,
  fixedSchedules,
  schedules,
  scheduleEvents,
}: DayPickerDayProps) => {
  const startOfWeekNumber = moment()
    .day(business?.payrollStartOfTheWeek)
    .get("day");

  const dayToBeRendered = moment(day).startOf("day").add(4, "hours");
  let startOfWeekMoment = moment(day)
    .startOf("day")
    .add(4, "hours")
    .set("day", startOfWeekNumber);

  dayToBeRendered.set("day", startOfWeekNumber);
  const startOfSelectedDayMoment = moment(day).startOf("day").add(4, "hours");

  if (startOfSelectedDayMoment.isBefore(startOfWeekMoment)) {
    startOfWeekMoment = startOfWeekMoment.subtract(7, "days");
  }

  const selectedDays = generateWeekRangeForSelectedDate(
    startOfWeekMoment.toDate(),
    business
  );

  if (!requestType) {
    const scheduleForADay = getScheduleForADate(
      startOfSelectedDayMoment.toDate(),
      schedulesWithLocalSchedule,
      business.timezone
    );

    const duplicatedScheduleForADay = getFixedScheduleForAWeekRange(
      selectedDays,
      fixedSchedules,
      business.timezone
    );

    let dotColor = "#089AC8";

    if (scheduleForADay) {
      const publishedSchedule = scheduleForADay?.associatedScheduleId
        ? schedules.find(
          (sch) => sch.id === scheduleForADay?.associatedScheduleId
        )
        : null;
      if (publishedSchedule) {
        dotColor = "#61C554";
      }
    }

    const determineWhetherAShiftIsSameDate = (shift: TangoShift, d: Date) => {
      const shiftStartDate = moment(shift?.startDate?.toDate());
      const selectedDateMoment = convertToBusinessTimezoneMoment(d, business)
        .startOf("day")
        .add(4, "hours");

      return (
        shiftStartDate.isSameOrAfter(selectedDateMoment) &&
        shiftStartDate.isBefore(selectedDateMoment.clone().add(1, "day"))
      );
    };

    const hasShiftForADay = scheduleForADay
      ? scheduleForADay.shifts?.find((shift) =>
        determineWhetherAShiftIsSameDate(
          shift,
          startOfSelectedDayMoment.toDate()
        )
      )
      : false;
    const hasDuplicatedShiftForADay = duplicatedScheduleForADay
      ? duplicatedScheduleForADay.shifts?.find((shift) =>
        determineWhetherAShiftIsSameDate(shift, day)
      )
      : false;

    if (scheduleForADay && hasShiftForADay) {
      return (
        <div>
          <div>{startOfSelectedDayMoment.format("D")}</div>
          <div
            style={{
              height: 3,
              width: 3,
              borderRadius: "50%",
              backgroundColor: dotColor,
              position: "absolute",
              left: 0,
              right: 0,
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          />
        </div>
      );
    }
    if (
      !scheduleForADay &&
      duplicatedScheduleForADay &&
      hasDuplicatedShiftForADay
    ) {
      return (
        <div>
          <div>{startOfSelectedDayMoment.format("D")}</div>
          <div
            style={{
              height: 3,
              width: 3,
              borderRadius: "50%",
              backgroundColor: dotColor,
              position: "absolute",
              left: 0,
              right: 0,
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          />
        </div>
      );
    }
    return (
      <div>
        <div>{startOfSelectedDayMoment.format("D")}</div>
      </div>
    );
  }
  if (requestType === ScheduleEventType.drop) {
    const requestsForADay = getRequestsForADay(
      startOfSelectedDayMoment.toDate(),
      scheduleEvents,
      selectedDays
    )
      .filter((s) => s.eventType === requestType)
      .filter((r) => r.eventType === ScheduleEventType.drop);
    return (
      <div>
        <div>{startOfSelectedDayMoment.format("D")}</div>
        {requestsForADay.length ? (
          <div
            style={{
              height: 3,
              width: 3,
              borderRadius: "50%",
              backgroundColor: "#089AC8",
              position: "absolute",
              left: 0,
              right: 0,
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          />
        ) : null}
      </div>
    );
  }
  if (
    requestType === ScheduleEventType.trade ||
    requestType === ScheduleEventType.cover
  ) {
    const requestsForADay = getRequestsForADay(
      day,
      scheduleEvents,
      selectedDays
    ).filter(
      (r) =>
        r.eventType === ScheduleEventType.trade ||
        r.eventType === ScheduleEventType.cover
    );
    return (
      <div>
        <div>{startOfSelectedDayMoment.format("D")}</div>
        {requestsForADay.length ? (
          <div
            style={{
              height: 3,
              width: 3,
              borderRadius: "50%",
              backgroundColor: "#089AC8",
              position: "absolute",
              left: 0,
              right: 0,
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          />
        ) : null}
      </div>
    );
  }
  if (requestType === ScheduleEventType.time_off) {
    const requestsForADay = getRequestsForADay(
      day,
      scheduleEvents,
      selectedDays
    )
      .filter((s) => s.eventType === requestType)
      .filter((r) => r.eventType === ScheduleEventType.time_off);
    return (
      <div>
        <div>{startOfSelectedDayMoment.format("D")}</div>
        {requestsForADay.length ? (
          <div
            style={{
              height: 3,
              width: 3,
              borderRadius: "50%",
              backgroundColor: "#089AC8",
              position: "absolute",
              left: 0,
              right: 0,
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          />
        ) : null}
      </div>
    );
  }
  return <div>{startOfSelectedDayMoment.format("D")}</div>;
};
