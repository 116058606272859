import { useSelector } from 'react-redux'
import moment from "moment-timezone";

import { ColumnInstruction } from "components/Table/HorizontalTable";
import { ItemProps } from "./instructions";
import { RootState } from 'model/store';
import { useCallback, useMemo, useState } from 'react';
import { getPositionById } from 'model/selectors/businessSettings';
import { generateWeekRangeForSelectedDate } from 'views/manager/dailyLogs/dailyLogsFunctions';

const columns: ColumnInstruction<ItemProps>[] = [
  { type: "projection", header: "Status", attribute: "status" },
  { type: "data", header: "Employee", attribute: "employeeFullName" },
  { type: "data", header: "Role", attribute: "role" },
  { type: "data", header: "Mon", attribute: "Monday" },
  { type: "data", header: "Tue", attribute: "Tuesday" },
  { type: "data", header: "Wed", attribute: "Wednesday" },
  { type: "data", header: "Thu", attribute: "Thursday" },
  { type: "data", header: "Fri", attribute: "Friday" },
  { type: "data", header: "Sat", attribute: "Saturday" },
  { type: "data", header: "Sun", attribute: "Sunday" },
];



const availabilityStatusTitle = (status: AvailabilityRequestStatus) => {
  switch (status) {
    case "approved":
      return "Approved";
    case "denied":
      return "Denied";
    case "pending":
      return "Pending";
    default:
      return "Pending";
  }
};
const useAvailabilities = () => {
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const [requestsStatusFilter, setRequestsStatusFilter] = useState<
    "pending" | "reviewed"
  >("pending");
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const publishedSchedules: TangoSchedule[] = useSelector(
    (state: RootState) => state.schedules
  );

  const unFilteredAvailabilities: FixedAvailability[] = useSelector(
    (state: RootState) => state.fixedAvailabilities
  );

  const [selectedDate, setSelectedDate] = useState(new Date());

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const weekRange = useMemo(() => {
    return generateWeekRangeForSelectedDate(business, selectedDate);
  }, [business, selectedDate]);

  const onCalendarDayPress = useCallback(
    (d: Date) => {
      setSelectedDate(d);
    },
    [setSelectedDate]
  );

  const availabilities = useMemo(() => {
    if (requestsStatusFilter === "pending") {
      return unFilteredAvailabilities.filter(
        (av) => !av.deleted && av.status === "pending"
      ).sort((a, b) => {
        const aDate = moment(a.createdAt.toDate())
        const bDate = moment(b.createdAt.toDate())
        return bDate.diff(aDate)
      })
    }
    return unFilteredAvailabilities.filter(
      (av) => !av.deleted && (av.status === "approved" || av.status === "denied")
    ).sort((a, b) => {
      const aDate = moment(a.createdAt.toDate())
      const bDate = moment(b.createdAt.toDate())
      return bDate.diff(aDate)
    })
  }, [unFilteredAvailabilities, requestsStatusFilter]);

  const composeDailyAvailabilityString = useCallback((dayName: string, schedule: FixedAvailabilitySchedule[]) => {
    const daySchedule = schedule?.find(s => s.day === dayName)
    if (daySchedule) {
      if (daySchedule.available) {
        return `${daySchedule.startTime} - ${daySchedule.endTime}`
      }
      return "Not Available"
    }
    return "Not Available"

  }, [])

  const transformAvailabilityToTableFormat = useCallback(
    (a: FixedAvailability): ItemProps | null => {
      const statusTitle = availabilityStatusTitle(a.status ?? "approved");
      const staff = fellowStaffMembers.find(
        (sm) => sm?.id === a.staffId
      );

      if (!staff) return null;

      const employeeFullName = `${staff.contact.firstName} ${staff.contact.lastName}`;
      const Monday = composeDailyAvailabilityString("Monday", a.schedule)
      const Tuesday = composeDailyAvailabilityString("Tuesday", a.schedule)
      const Wednesday = composeDailyAvailabilityString("Wednesday", a.schedule)
      const Thursday = composeDailyAvailabilityString("Thursday", a.schedule)
      const Friday = composeDailyAvailabilityString("Friday", a.schedule)
      const Saturday = composeDailyAvailabilityString("Saturday", a.schedule)
      const Sunday = composeDailyAvailabilityString("Sunday", a.schedule)
      return {
        uniqueId: a.id,
        status: statusTitle,
        Monday,
        Tuesday,
        Wednesday,
        Thursday,
        Friday,
        Saturday,
        Sunday,
        fullAvailabilityObject: a,
        employeeFullName,
        role: getPositionById(businessSettings, staff.primaryRole ?? "")?.title ?? "",

      };
    },
    [fellowStaffMembers, publishedSchedules, businessSettings]
  );

  const itemsData = useMemo(() => {
    return availabilities.map(transformAvailabilityToTableFormat).filter(x => !!x) as ItemProps[]
  }, [availabilities, transformAvailabilityToTableFormat])


  return {
    columns,
    itemsData,
    onCalendarDayPress,
    weekRange,
    requestsStatusFilter, setRequestsStatusFilter
  }
}
export default useAvailabilities;