import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { getTaxRateAndTangoFees } from "models/orders";

import { RootState } from "model/store";

import "../style.css";

const BankDeposit = () => {
  const today = new Date();
  const payouts: ManualPayouts[] = useSelector(
    (state: RootState) => state.payouts
  );
  const payoutsLedger: PayoutLedger[] = useSelector(
    (state: RootState) => state.payoutLedgers
  );

  const business = useSelector(
    (state: RootState) => state.business
  ) as TangoBusiness;

  const businessSettings: TangoBusinessSettings = useSelector((state: RootState) => state.businessSettings)
  const allPricingModels = [].concat.apply(
    [],
    business?.tangoProducts.map((model: DocumentData) => {
      const { name } = model;
      return model.orderTypes.map((data: DocumentData) => {
        return {
          orderChannel: name,
          orderType: data.name,
          taxRate: data.taxRate,
          tangoFeeCents: data.tangoFeeCents,
          tangoFeePercent: data.tangoFeePercent,
          tangoFeeRate: data.tangoFeeRate ?? 0,
          waitTime: data.waitTime || 0,
        };
      });
    })
  );
  const pricingModel = getTaxRateAndTangoFees(allPricingModels);
  console.log("Pricing models: ", pricingModel);

  return (
    <div className="w-full">
      <div className="page-header">
        <div className="page-title">Deposits</div>
      </div>
      <table>
        <tr>
          <th>Business Date</th>
          <th>Number of Sales</th>
          <th>Net Sales</th>
          <th>Processing Transaction Fees</th>
          <th>Processing Sales Fees</th>
          <th>Tango Fees</th>
          <th>Net Deposits</th>
          <th>Status</th>
          {/* <th>Deposit Date</th> */}
          <th>Disputes/Refunds</th>
        </tr>
        {payoutsLedger
          .sort(
            (a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix()
          )
          .map((payout) => {
            const netSales = payout.amountEarned +
              payout.applicationFeeAmount +
              payout.amountRefunded +
              payout.disputeTotal
            const tangoFees = (netSales / 100) * 0.01
            return (
              <tr>
                <td>
                  {moment(payout.createdAt).subtract(1, "day").format("MMM D")}
                </td>
                <td>{payout.numberOfTransactions}</td>
                <td>
                  $
                  {(
                    netSales /
                    100
                  ).toFixed(2)}
                </td>
                <td>
                  $
                  {(
                    (pricingModel.tangoFeeCents * payout.numberOfTransactions) /
                    100
                  ).toFixed(2)}
                </td>
                <td>
                  $
                  {(
                    ((payout.applicationFeeAmount -
                      pricingModel.tangoFeeCents *
                      payout.numberOfTransactions) /
                      100) - tangoFees
                  ).toFixed(2)}
                </td>
                <td>${(tangoFees).toFixed(2)}</td>
                <td>${(payout.amountEarned / 100).toFixed(2)}</td>
                {/* TODO: Make this more concrete */}
                <td className="status">
                  <span className={payout.completed ? "deposited" : "transit"}>
                    {payout.completed ? "Deposited" : "In Transit"}
                  </span>
                </td>
                {/* <td></td> */}
                <td>
                  $
                  {(
                    (payout.amountRefunded + payout.disputeTotal) /
                    100
                  ).toFixed(2)}
                </td>
              </tr>
            );
          })}
        {/* Manual Payouts */}
        {payouts
          .sort(
            (a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix()
          )
          .map((payout) => {
            return (
              <tr>
                <td>{moment(payout.businessDate).format("MMM D")}</td>
                <td>{payout.numberOfTransactions}</td>
                <td>
                  $
                  {(
                    (payout.amount +
                      payout.applicationFeeAmount +
                      payout.amountRefunded +
                      payout.disputeTotal) /
                    100
                  ).toFixed(2)}
                </td>
                <td>
                  $
                  {(
                    (pricingModel.tangoFeeCents * payout.numberOfTransactions) /
                    100
                  ).toFixed(2)}
                </td>
                <td>
                  $
                  {(
                    (payout.applicationFeeAmount -
                      pricingModel.tangoFeeCents *
                      payout.numberOfTransactions) /
                    100
                  ).toFixed(2)}
                </td>
                <td>${(payout.amount / 100).toFixed(2)}</td>
                {/* TODO: Make this more concrete */}
                <td className="status">
                  {today < payout.arrivalDate ? (
                    <span className="transit">In Transit</span>
                  ) : (
                    <span className="deposited">Deposited</span>
                  )}
                </td>
                {/* HACK: Adding a day because it seems like there's a 1 day difference between Stripe and our system */}
                <td>
                  {moment(payout.arrivalDate).add(1, "day").format("MMM D")}
                </td>
                <td>
                  $
                  {(
                    (payout.amountRefunded + payout.disputeTotal) /
                    100
                  ).toFixed(2)}
                </td>
              </tr>
            );
          })}
      </table>
    </div>
  );
};

export default BankDeposit;
