import axios from "axios";
import { Overwrite } from "utility-types";

import { apiErrorHandler, baseUrl } from "./core";
import { generateBearerToken } from "./init";

const tangoWorkEventsApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/work-events`,
});

const tangoPayrollApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/payroll`,
});

const WorkEventsApiRoutesMap = {
  generateBiWeeklyPaystubReportsForStaffMember:
    "/generateBiWeeklyPaystubReportsForStaffMember",
  getSnapshotWorkEventsGroupedByWeek: "/grouped-by-week/snapshot",
};

tangoPayrollApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

tangoWorkEventsApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

export const getWorkEventsForDateRange = async (
  businessId: string,
  startDate: number, //YYYYMMDD
  endDate: number //YYYYMMDD
) => {
  const r = await tangoWorkEventsApiClient.get(`${businessId}`, {
    params: {
      startDate,
      endDate,
    },
  });

  return r.data as WorkEvent[];
};

export type ApiWorkEvent = Overwrite<
  WorkEvent,
  { clockedIn: Date; clockedOut: Date }
>;

export type ApiWorkEventsGroupedByWeek = {
  [startOfWeekDateString: string]: {
    startDate: Date;
    endDate: Date;
    workEvents: ApiWorkEvent[];
  };
};

export const getGroupedWorkEventsSnapshotsForDateRange = async (
  businessId: string,
  startDate: number, //YYYYMMDD
  endDate: number //YYYYMMDD
) => {
  try {
    const r = await tangoWorkEventsApiClient.get<ApiWorkEventsGroupedByWeek>(
      `${WorkEventsApiRoutesMap.getSnapshotWorkEventsGroupedByWeek}/${businessId}`,
      {
        params: {
          startDate,
          endDate,
        },
      }
    );

    return r.data;
  } catch (e: any) {
    apiErrorHandler(e);
  }
};

export type PayPeriod = {
  startISOString: string;
  endISOString: string;
};

export const fetchLatestPayPeriodForBusiness = async (businessId: string) => {
  const res = await tangoPayrollApiClient.get<PayPeriod>(
    `/${businessId}/latest-pay-period`
  );
  return res.data;
};

export interface PayrollData {
  businessId: string;
  startDateISOString: string;
  endDateISOString: string;
  staffMemberOrientedData: StaffMemberOrientedData[];
}

export interface PayrollStaffDetailsData {
  businessId: string;
  startDateISOString: string;
  endDateISOString: string;
  staffMemberOrientedData: StaffMemberOrientedData;
}

export interface PayrollPunchIn {
  workEventId: string;
  clockedInISOString: string;
  clockedOutISOString: string | null;
  positionId: string;
  positionTitle: string;
  totalHours: number;
  totalPay: number;
  payRate: number;
  autoClockOut: boolean;
}

export interface GroupedPunchIn {
  positionId: string;
  positionTitle: string;
  staffUID: string;
  totalHours: number;
  latestPayRateForPosition: number;
  totalPay: number;
  punchIns: PayrollPunchIn[];
}

export interface StaffMemberOrientedData {
  staffUID: string;
  fullName: string;
  abbrName: string;
  imageUrl: string | null;
  primaryRoleTitle: string;
  totalHours: number;
  totalGrossPay: number;
  roleTitles: string[];
  punchIns: PayrollPunchIn[];
  groupedPunchIns: GroupedPunchIn[];
}

export const fetchStaffDetailsPayrollData = async (
  businessId: string,
  uid: string,
  startDateISOString: string,
  endDateISOString: string
) => {
  const res = await tangoPayrollApiClient.get<PayrollStaffDetailsData>(
    `/${businessId}/${uid}`,
    {
      params: {
        startDateISOString,
        endDateISOString,
      },
    }
  );
  return res.data;
};

export const fetchStaffMemberOrientedPayrollData = async (
  businessId: string,
  startDateISOString: string,
  endDateISOString: string
) => {
  const res = await tangoPayrollApiClient.get<PayrollData>(`/${businessId}`, {
    params: {
      startDateISOString,
      endDateISOString,
    },
  });
  return res.data;
};

export const fetchViablePayrollISODateStringEdges = async (
  businessId: string
) => {
  const res = await tangoPayrollApiClient.get<string[]>(
    `/${businessId}/viable-payroll-edges`
  );

  return res.data;
};

export const createClockPunch = async (
  businessId: string,
  clockedIn: string,
  clockedOut: string | null,
  roleId: string,
  staffUID: string,
  payRate: number
) => {
  console.log({
    businessId,
    clockedInISOString: clockedIn,
    clockedOutISOString: clockedOut,
    positionId: roleId,
    staffUID,
    payRate,
  });
  const res = await tangoPayrollApiClient.post(`/clock-punch`, {
    businessId,
    clockedInISOString: clockedIn,
    clockedOutISOString: clockedOut,
    positionId: roleId,
    staffUID,
    payRate,
  });
  return res.data;
};

export const updateMultipleClockPunches = async (
  businessId: string,
  updateRequests: {
    clockedInISOString: string;
    clockedOutISOString: string | null;
    workEventId: string;
    roleId: string;
  }[]
) => {
  console.log("updateRequests", updateRequests);
  const res = await tangoPayrollApiClient.put(`/clock-punch-multiple`, {
    businessId,
    updateRequests,
  });
  return res.data;
};

export const updateClockPunch = async (
  businessId: string,
  clockedIn: string,
  clockedOut: string | null,
  workEventId: string
) => {
  const res = await tangoPayrollApiClient.put(`/clock-punch`, {
    businessId,
    clockedInISOString: clockedIn,
    clockedOutISOString: clockedOut,
    workEventId,
  });
  return res.data;
};

export const deleteClockPunch = async (
  businessId: string,
  workEventId: string
) => {
  const res = await tangoPayrollApiClient.post(`/delete-clock-punch`, {
    businessId,
    workEventId,
  });
  return res.data;
};

export type ProcessedWorkEvent = {
  id: string;
  staffId: string;
  clockedIn: string;
  clockedOut: string;
  duration: number;
  totalPay: number;
};

export type PayStubReport = {
  payRangeStartISOString: string;
  payRangeEndISOString: string;
  processedWorkEvents: ProcessedWorkEvent[];
  totalPay: number;
  totalHours: number;
};

export const fetchBiWeeklyPaystubs = async (
  businessId: string,
  staffUID: string,
  startDateISOString: string,
  endDateISOString: string
) => {
  try {
    const res = await tangoWorkEventsApiClient.post<{
      groupedStaffMemberPaystubsData: PayStubReport[];
    }>(WorkEventsApiRoutesMap.generateBiWeeklyPaystubReportsForStaffMember, {
      businessId,
      staffUID,
      startDateISOString,
      endDateISOString,
    });
    console.log("res", res.data);
    return res.data;
  } catch (e: any) {
    apiErrorHandler(e);
  }
};
