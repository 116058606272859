import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import moment from "moment-timezone";
import UIComponents from "@tangopay/tango-ui-library";
import { approveNewFixedAvailability, denyNewFixedAvailability } from "controllers/scheduleEvents";
const { Dropdown } = UIComponents;
export type ItemProps = {
  uniqueId: string;
  status: string;
  role: string;
  employeeFullName: string;
  Monday: string;
  Tuesday: string;
  Wednesday: string;
  Thursday: string;
  Friday: string;
  Saturday: string;
  Sunday: string;
  fullAvailabilityObject: FixedAvailability;
};
export const instructions: { [x: string]: RenderInstruction<ItemProps> } = {};
instructions.status = {
  type: "complex-custom",
  viewComponent: (props) => {
    const { status, fullAvailabilityObject } = props.fullObject;
    if (status === "Pending") {
      return (
        <div className="w-24">
          <Dropdown
            placeholder="Respond"
            options={[
              {
                label: "Approve",
                value: "approve"
              },
              {
                label: "Deny",
                value: "deny"
              }
            ]
            }
            onChange={async (e: any) => {
              console.log("e?.value", e?.value)
              if (e?.value === "approve") {
                console.log("approveNewFixedAvailability")
                await approveNewFixedAvailability(fullAvailabilityObject)
              } else if (e?.value === "deny") {
                await denyNewFixedAvailability(fullAvailabilityObject)
              }
            }}

            className="respond-dropdown"
            size="small"
          />
        </div>

      )
    }
    const getClassesBasedOnStatus = (status: "Approved" | "Pending" | "Denied") => {
      if (status === "Approved") {
        return "border-green-3 bg-green-2"
      }

      return "border-orange-4 bg-orange-2"
    }
    return (
      <div
        //@ts-ignore
        className={`w-24 ${getClassesBasedOnStatus(status ?? "Pending")}
        border border-solid inline-flex py-1 px-6 rounded-full text-tiny font-lato-bold text-black`}

      >

        {props.fullObject.status}
      </div>
    )
  },
  editComponent: null,
};
