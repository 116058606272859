import { baseUrl } from './core';
import { generateBearerToken } from './init';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { ClosedChecksProps } from 'views/Payments/ClosedChecks/useClosedChecks';
import { DiscountedPaymentProps } from 'views/Payments/DiscountedPayments/useDiscounts';
import { DisputeProps } from 'views/Payments/Disputes/useDisputes';
import { GiftCardProps } from 'views/Payments/GiftCards/useGiftCards';
import { CompletedPaymentProps } from 'views/Payments/PaymentsScreen/usePaymentScreen';

const testURl = 'http://localhost:9001/tango-2/us-central1';

const testPaymentsApiClient = axios.create({
  baseURL: `${testURl}/nestApi/payments`,
});

const paymentsApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/payments`,
});

const csvDownloadClient = axios.create({
  baseURL: `${baseUrl}/nestApi/payments`,
});

paymentsApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

csvDownloadClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
    responseType: 'blob', //declare this to be able to convert to xlsx or csv
  };
});

export interface TangoTab {
  id: string;
  businessId: string;
  name: string | null;
  isExplicitTab: boolean;
  deleted: boolean;
  staffId: string;
  createdAt: Date;
  updatedAt: Date;
  tableId: string;
  tableNumber: number | null;
  numberOfGuests: number;
  customer: CustomerModel[];
  status: OrderStatus;
  paymentComplete: boolean;
  refundPayload: any | null;
  allSplitTypes: string[];
  sessionId: string;
  serviceAreaId: string;
  tabNumber: string;
  voidedProducts: any;
  tableAlias: any;
}

interface CustomerModel {
  firstName: string;
  lastName: string;
  email: string;
  phone: number | string;
  userId: string;
  payment: OrderPayment;
  splitType: string | null;
  orderChannel: string;
  orderType: string;
  productsPaid: ProductsPaid[];
  isDineInOrder: boolean;
  amount: CustomerAmount;
}

export interface ProductsPaid {
  productId: string;
  productName: string;
  productCategory: string;
  menuCategory: string;
  menuName: string;
  quantity: number;
  price: number;
  taxRate: number | null;
  productOrderId: string;
  selectedModifiers: SelectedModifiers[];
  name: string;
}

export interface OrderStatus {
  open: boolean;
  data: string;
  error: number;
  refunded: boolean;
  refundedAmount: number;
  deleted: boolean;
}

interface OrderPayment {
  type: string;
  successfulCharge: SuccessfulCharge;
  paymentType: CashPaymentType | StripePaymentType | any;
}
interface CashPaymentType {
  staffId: string;
  amount: number;
  drawerId: string;
  type: string;
}

interface StripePaymentType {
  customerTokenId: string;
  acct_stripeConnectId: string;
  merchantTokenId: string;
  type: string;
}
interface SuccessfulCharge {
  id: string | null;
  amount: number;
  timestampCharged: Date | null;
  paymentVendor: 'stripe' | 'fattmerchant' | 'cash' | 'external' | 'gift-card' | null;
  applicationFeeAmount: number;
}

export interface Product {
  productOrderId: string;
  productId: string;
  businessId: string;
  imageUrl: string;
  menuName: string;
  menuCategory: string;
  name: string;
  quantity: number;
  price: number;
  discountedPrice: number | null;
  taxRate: number | null;
  type: string;
  alcohol: boolean;
  discountsAppliedToProduct: DiscountApplied[];
  selectedModifiers: SelectedModifiers[];
  note: string | null;
  sentQuantity: number;
  orderQuantity: number;
  plu: string | null;
  orderId?: string;
  orderCancelled?: boolean;
  seat: string;
  course: string;
  fulfilled: boolean;
  status: string;
  isPrinted: boolean;
}
export interface SelectedModifiers {
  modifierId: string;
  additionalCost: number;
  modifierName: string;
  optionNames: string[];
  options: {
    name: string;
    plu: string | null;
    id: string;
    additionalCost: number;
  }[];
}

export interface CustomerAmount {
  netTotal: number;
  refundPayload: any;
  refundedAmount: number;
  tip: number;
  tax: number;
  deliveryFee: number;
}

export interface DiscountApplied {
  discountName: string;
  discountId: string;
  discountTotal: number;
}

interface StaffMember {
  id: string;
  contact: {
    firstName: string;
    lastName: string;
  };
  managerPin: any;
}

export interface RefundPaymentDTO {
  customerIndex: number;
  amount: number;
  paymentIntentId: string;
  reverseTransfer: boolean; //always true
  reason: string;
  cashRefund: boolean;
}

export interface GiftCardPurchase {
  id: string;
  createdAt: any;
  deleted: boolean;
  accountId: string;
  amount: number;
  businessId: string;
  paymentType: string;
  transactionType: string; //send, reload, credit, debit
  updatedAt: any;
  userId: string;
}

export interface TabWithStaff {
  tab: TangoTab;
  staffMember: StaffMember;
}

export interface SearchParameters {
  checkNumber: string;
  last4: string;
  amount: number;
}

export type SearchParam = 'check-number' | 'amount' | 'last4';

export interface OptionType {
  label: string;
  value: string;
}
export const searchCheckOptions = [
  {
    value: 'Check Number',
    label: 'Check Number',
  },
  {
    value: 'Amount',
    label: 'Amount',
  },
  {
    value: 'Last 4',
    label: 'Last 4',
  },
];

//download any array passed as data
export const downloadCSV = async (businessId: string, data: any[], type: string) => {
  try {
    if (data.length > 0) {
      switch (type) {
        //format array of data to be human readable in excel rows
        case 'closedChecks':
          const formattedClosedChecksData = data.map((row: ClosedChecksProps) => {
            const excelRow = {
              id: row.completedCheck.id,
              check_number: row.completedCheck.tabNumber,
              date: moment(new Date(row.date._seconds * 1000)).format('MM/DD/YYYY'),
              time_closed: moment(new Date(row.date._seconds * 1000)).format('h:mm A'),
              total_amount: row.totalAmount,
              server: row.serverName,
              type: row.type,
              payments: row.payments,
            };
            return excelRow;
          });

          data = formattedClosedChecksData;
          break;

        case 'payments':
          const formattedPaymentsData = data.map((row: CompletedPaymentProps) => {
            const excelRow = {
              date: row.date,
              total_amount: '$' + (row.amount * 0.01).toFixed(2),
              status: row.status,
              customer: row.customerName,
              last_4: row.last4,
              check_number: row.checkNumber,
              description: row.description,
            };
            return excelRow;
          });

          data = formattedPaymentsData;
          break;

        case 'disputes':
          const formattedDisputesData = data.map((row: DisputeProps) => {
            const excelRow = {
              date: moment(new Date(row.date._seconds * 1000)).format('MM/DD/YYYY'),
              total_amount: '$' + (row.amount * 0.01).toFixed(2),
              status: row.status,
              reason: row.reason,
              customer: row.customer,
              last_4: row.last4,
            };
            return excelRow;
          });

          data = formattedDisputesData;
          break;

        case 'discounts':
          const formattedDiscountsData = data.map((row: DiscountedPaymentProps) => {
            const excelRow = {
              date: moment(new Date(row.date._seconds * 1000)).format('MM/DD/YYYY'),
              check_number: row.checkNumber,
              discount_name: row.discountName,
              server_name: row.serverName,
              customer: row.customer,
              item_name: row.itemName,
              item_quantity: row.itemQuantity,
              original_price: row.price,
              discounted_price: row.discountedPrice,
              final_price: row.finalPrice,
            };
            return excelRow;
          });

          data = formattedDiscountsData;
          break;

        case 'gift-cards':
          const formattedGiftCardsData = data.map((row: GiftCardProps) => {
            const excelRow = {
              date_issued: moment(new Date(row.dateIssued._seconds * 1000)).format('MM/DD/YYYY'),
              gift_card_number: row.giftCardNumber,
              location_issued: row.locationIssued,
              balance: row.balance,
              transactions: row.transactions.length,
              customer: row.customer,
            };
            return excelRow;
          });
          data = formattedGiftCardsData;
          break;

        default:
          //data = data
          //any array that doesn't meet previous condiditons will send to nest backend as is (i.e. no formatting)
          //could easily add more cases above to handle other tables that need xlsx downloads
          break;
      }
    } else {
      return console.log('no data');
    }

    const blob = await csvDownloadClient.post(`${businessId}/csv-download`, data);
    //save blob that is returned from nest backend
    return saveAs(blob.data, `${type}.xlsx`);
  } catch (error: any) {
    console.log('error creating csv', error);
    // alert(error.response.data.message);
    return false;
  }
};

export const fetchTabWithStaff = async (businessId: string, tabId: string) => {
  const res = await paymentsApiClient.get<TabWithStaff>(`${businessId}/tab/${tabId}`);
  return res.data;
};

export const fetchCompletedTabs = async (businessId: string, lastCompletedTabId: string) => {
  if (!businessId) return null;
  const res = await paymentsApiClient.get<TabWithStaff[]>(`${businessId}/${lastCompletedTabId}`);
  return res.data;
};

export const searchQueryFirestoreForTabs = async (businessId: string, searchParam: SearchParam, searchValue: string) => {
  if (!businessId) return null;
  const res = await paymentsApiClient.get<TabWithStaff[]>(`${businessId}/search/${searchParam}/${searchValue}`);
  return res.data ? res.data : [];
};

export const useCompletedTabs = (businessId: string, lastCompletedTabId: string) => {
  return useQuery({
    queryKey: ['completedTabsInfo', lastCompletedTabId],
    queryFn: () => fetchCompletedTabs(businessId, lastCompletedTabId),
    keepPreviousData: true, //this keeps old data in table until new data is returned
    retry: 2,
  });
};

export const fetchCompletedTabsAndGiftCardPurchases = async (businessId: string, lastCompletedTabId: string) => {
  if (!businessId) return null;
  const res = await paymentsApiClient.get<TabWithStaff[]>(`${businessId}/${lastCompletedTabId}/giftCards`);
  return res.data;
};

export const useCompletedTabsAndGiftCardPurchases = (businessId: string, lastCompletedTabId: string) => {
  return useQuery({
    queryKey: ['paymentsAndGiftCardPurchasesInfo', businessId, lastCompletedTabId],
    queryFn: () => fetchCompletedTabsAndGiftCardPurchases(businessId, lastCompletedTabId),
    keepPreviousData: true,
    retry: 2,
  });
};

export const fetchStaffMember = async (businessId: string, staffId: string) => {
  const res = await paymentsApiClient.get<StaffMember>(`${businessId}/staffMember/${staffId}`);
  return res.data;
};

export const useStaffMember = (businessId: string, staffId: string) => {
  return useQuery(['staffInfo', businessId, staffId], () => fetchStaffMember(businessId, staffId));
};

export const createRefund = async (businessId: string, country: string, tabId: string, refundData: RefundPaymentDTO) => {
  try {
    const success = await paymentsApiClient.post(`${businessId}/${country}/${tabId}/refundPayment`, refundData);
    return success.data;
  } catch (error: any) {
    console.log('error refund', error.response.data);
    alert(error.response.data.message);
    return false;
  }
};
