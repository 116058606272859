
import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { UnitQuantityDTO } from "controllers/inventoryItemInfo";
import { locationInstruction, primaryGroupInstruction, secondaryGroupInstruction, stockLevelInstruction } from "../tableCells";


export type ItemProps = {
    uniqueId: string;
    itemName: string;
    primaryGroup: string;
    secondaryGroup: string;
    productionUnitName: string;
    ingredientUnitName: string;

    minPar: number,
    maxPar: number,
    theoreticalStock: UnitQuantityDTO[],
    conversionRatio: number;
    locations: string[];
};
const instructions: { [x: string]: RenderInstruction<ItemProps> } = {};
instructions.stockLevel = stockLevelInstruction;
instructions.conversionRatio = {
    type: "number",
};
instructions.ingredientUnitName = {
    type: "default",
    readOnly: true,
    editablePrefix: "new__",
};

instructions.primaryGroup = primaryGroupInstruction;

instructions.secondaryGroup = secondaryGroupInstruction;

instructions.locations = locationInstruction;

export default instructions;
