import { Classes, Icon, Popover, Spinner } from "@blueprintjs/core";
import { pdf } from "@react-pdf/renderer";
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import "react-day-picker/lib/style.css";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import DailyScheduleReportAMPMSplitPdfDocument from "views/BusinessAdmin/Reporting/PdfReports/DailyScheduleReportAMPMSplitPdfDocument";
import DailyScheduleReportPdfDocument from "views/BusinessAdmin/Reporting/PdfReports/DailyScheduleReportPdfDocument";

import {
  generateDailyScheduleReportData,
  generateDailyScheduleReportDataWithAMPMSplit,
} from "controllers/reporting";
import {
  getShiftsForADay,
  groupShiftsByPosition,
  groupShiftsByShiftTypeAndTimeFrame,
} from "controllers/schedule";
import { DepartmentId } from "controllers/staff";

import Box from "components/Box";

import { getPositionById } from "model/selectors/businessSettings";
import { RootState } from "model/store";

import EyeOffIcon from "assets/eye-off.svg";
import EyeOnIcon from "assets/eye-on.svg";

import { SingleDayGrid } from "../SingleDayView/Grid";
import "../style.css";
import {getWorkEventsForDateRange} from "../../../../controllers/payroll";
import {convertToBusinessTimezoneMoment} from "../../../../utils/dateUtils";

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};

interface ShiftDetailsViewProps {
  schedulesWithLocalSchedule: TangoSchedule[];
  weekRange: Date[];
  departmentScheduleViewType: DepartmentId | null;
  setDepartmentScheduleViewType: (depId: DepartmentId | null) => void;
  duplicatedScheduleForAWeekRange: TangoSchedule | null;
  scheduleForWeekRange: TangoSchedule | null;
  setSelectedShiftForEdit: (shift: TangoShift) => void;
  jobFunctions: TangoJobFunctions;
  generateWeekRangeForSelectedDate: (sd: Date) => Date[];
}

export const ShiftDetailsView = (props: ShiftDetailsViewProps) => {
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const fixedSchedules: TangoFixedSchedule[] = useSelector(
    (state: RootState) => state.fixedSchedules
  );
  const [dailyPdfReportLoading, setDailyPdfReportLoading] = useState(false);

  const [
    hiddenShiftTypeStartEndTimeItems,
    setHiddenShiftTypeStartEndTimeItems,
  ] = useState<string[]>([]);

  const params = useParams();
  const navigate = useNavigate();

  const {
    schedulesWithLocalSchedule,
    departmentScheduleViewType,
    setDepartmentScheduleViewType,
    duplicatedScheduleForAWeekRange,
    scheduleForWeekRange,
    setSelectedShiftForEdit,
    jobFunctions,
    generateWeekRangeForSelectedDate
  } = props;



  const shiftDateString = params?.shiftDateString;
  if (!shiftDateString) return <Navigate to="/manager/scheduling" />;
  const shiftDateStringMoment = moment(
    params.shiftDateString,
    "YYYY-MM-DD",
    true
  );


  if (!shiftDateStringMoment.isValid()) {
    return <Navigate to="/manager/scheduling" />;
  }
  const weekRange = generateWeekRangeForSelectedDate(shiftDateStringMoment.toDate())
  console.log("weekRange", weekRange)
  const selectedDayForViewingShifts = shiftDateStringMoment.toDate();
  const groupShiftsByJobFunction = (
    shifts: TangoShift[]
  ): { name: string; quantity: number; total: number }[] => {
    const groupedShifts = _.groupBy(
      shifts,
      (shift: TangoShift) => shift.position
    );
    return _.keys(groupedShifts).map((key) => ({
      name: jobFunctions[key]?.title,
      total: groupedShifts[key]?.length,
      quantity: groupedShifts[key].filter(({ staffId }) => staffId).length,
    }));
  };
  const renderSingleDaySideBar = () => {
    if (!selectedDayForViewingShifts) {
      return null;
    }
    let shiftsForADay = getShiftsForADay(
      selectedDayForViewingShifts,
      weekRange,
      schedulesWithLocalSchedule,
      business.timezone,
      fixedSchedules
    );

    if (departmentScheduleViewType) {
      shiftsForADay = shiftsForADay.filter((shift) => {
        const shiftPosition = shift.position;
        const positionData = getPositionById(businessSettings, shiftPosition);
        return positionData?.departmentId === departmentScheduleViewType;
      });
    }

    const shiftsByShiftType = groupShiftsByShiftTypeAndTimeFrame(shiftsForADay, null);

    return (
      <Box className="single-day-sidebar">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          className="side-nav-item current-menu-item"
        >
          <Icon
            onClick={() => {
              navigate(-1);
            }}
            icon="chevron-left"
            iconSize={14}
          />
          <div>
            {moment(selectedDayForViewingShifts).format("dddd, MMMM D")}
          </div>
          <div />
          <Popover
            className="more-action-popover"
            modifiers={{ arrow: { enabled: false } }}
            position="bottom-right"
            content={<DailyScheduleViewMoreAction />}
            target={<Icon icon="more" iconSize={18} />}
          />
        </Box>
        <div
          className="animated-staff-controls-container"
          style={{
            justifyContent: !departmentScheduleViewType
              ? "flex-start"
              : departmentScheduleViewType === "foh"
                ? "center"
                : "flex-end",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <motion.div
            className="triple-left"
            onClick={() => setDepartmentScheduleViewType(null)}
            style={{
              color: !departmentScheduleViewType ? "#FFFFFF" : "black",
            }}
          >
            All
          </motion.div>
          <motion.div
            className="triple-center"
            onClick={() => setDepartmentScheduleViewType("foh")}
            style={{
              color: departmentScheduleViewType === "foh" ? "#FFFFFF" : "black",
            }}
          >
            FOH
          </motion.div>
          <motion.div
            className="triple-right"
            onClick={() => setDepartmentScheduleViewType("boh")}
            style={{
              color: departmentScheduleViewType === "boh" ? "#FFFFFF" : "black",
            }}
          >
            BOH
          </motion.div>
          <motion.div
            className="animated-weeekly-triple-view-handle"
            layout
            transition={spring}
          />
        </div>
        {shiftsByShiftType
          .sort(
            (a, b) =>
              moment(a.startTime, "HH:mm").unix() -
              moment(b.startTime, "HH:mm").unix()
          )
          .map((groupedShits, i) => {
            const shiftType = businessSettings?.shiftTypes?.find(
              (st) => st.id === groupedShits.shiftTypeId
            );
            const identifierForShiftGroup = groupedShits.shiftTypeId;
            const hasHiddenIdentifier = hiddenShiftTypeStartEndTimeItems.find(
              (i) => i === identifierForShiftGroup
            );
            return (
              <Box className="shift-menu-item-container">
                <Box
                  style={{
                    backgroundColor: shiftType?.backgroundColor,
                    borderBottom: `2px solid ${shiftType?.mainColor}`,
                    position: "relative",
                  }}
                  className="shift-menu-item"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <motion.img
                    style={{ position: "absolute", top: 10, right: 10 }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    src={hasHiddenIdentifier ? EyeOffIcon : EyeOnIcon}
                    onClick={() => {
                      if (hasHiddenIdentifier) {
                        setHiddenShiftTypeStartEndTimeItems(
                          hiddenShiftTypeStartEndTimeItems.filter(
                            (a) => a !== identifierForShiftGroup
                          )
                        );
                      } else {
                        setHiddenShiftTypeStartEndTimeItems([
                          ...hiddenShiftTypeStartEndTimeItems,
                          identifierForShiftGroup,
                        ]);
                      }
                    }}
                  />
                  <Box>
                    <div className="title">{shiftType?.name}</div>
                    <div className="subtitle">
                      {groupedShits.startTime} - {groupedShits.endTime}
                    </div>
                  </Box>
                  {/* <Icon onClick={() => {
                    setShowEditShiftModal(true);
                    setShiftsToEdit(groupedShits.shifts);
                  }} icon="chevron-right" iconSize={10} color="#454545" /> */}
                </Box>
                {groupShiftsByJobFunction(groupedShits.shifts).map((gs) => (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    className="role-detail-row"
                    justifyContent="space-between"
                    style={{
                      backgroundColor:
                        gs.quantity === gs.total
                          ? shiftType?.backgroundColor
                          : "white",
                    }}
                  >
                    <div className="role-name">{gs.name}</div>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <div
                        className="assignment"
                        style={{
                          color:
                            gs.quantity === gs.total
                              ? shiftType?.mainColor
                              : "#6E6A6B",
                        }}
                      >
                        {gs.quantity}/{gs.total}
                      </div>
                      <Icon icon="chevron-right" iconSize={10} />
                    </Box>
                    {gs.quantity === gs.total && (
                      <Icon
                        icon="tick"
                        iconSize={10}
                        color={shiftType?.mainColor}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            );
          })}
      </Box>
    );
  };

  const DailyScheduleViewMoreAction = () => (
    <Box className="more-action">
      <Box className="action-header">
        <Icon icon="delete" className={Classes.POPOVER_DISMISS} />
        Other Actions
      </Box>
      <Box
        className="action-item"
        onClick={async () => {
          try {
            if (scheduleForWeekRange && selectedDayForViewingShifts) {
              setDailyPdfReportLoading(true);
              const reportData = generateDailyScheduleReportData(
                businessSettings,
                fellowStaffMembers,
                selectedDayForViewingShifts,
                schedulesWithLocalSchedule,
                business
              );

              if (reportData) {
                const blob = await pdf(
                  <DailyScheduleReportPdfDocument
                    report={reportData}
                    businessName={business.businessName}
                  />
                ).toBlob();
                // setReportIdLoading(null)
                const url = URL.createObjectURL(blob);
                setDailyPdfReportLoading(false);

                window.open(url, "_blank");
              } else {
                setDailyPdfReportLoading(false);
                alert("No report data available");
              }
            }
          } catch (e) {
            setDailyPdfReportLoading(false);
            alert("Opps, something went wrong generating your report");
            console.log("error generating report", e);
          }
        }}
      >
        Download
        {dailyPdfReportLoading ? (
          <Box style={{ position: "absolute", right: 10, bottom: 15 }}>
            <Spinner size={20} />
          </Box>
        ) : null}
      </Box>
      <Box
        className="action-item"
        onClick={async () => {
          try {
            if (scheduleForWeekRange && selectedDayForViewingShifts) {
              setDailyPdfReportLoading(true);
              const startDateForReport = convertToBusinessTimezoneMoment(
                selectedDayForViewingShifts,
                business
              )
                .startOf("day")
                .add(4, "hours")

              const numericStartDate = startDateForReport.clone().subtract(1, 'day').date() +
                100 * (startDateForReport.month() + 1) +
                10000 * startDateForReport.year();
              const endDateForReport = moment(startDateForReport).add(1, "day");
              const numericEndDate = endDateForReport.clone().add(1, 'day').date() +
                100 * (startDateForReport.month() + 1) +
                10000 * startDateForReport.year();
              const workEvents = await getWorkEventsForDateRange(business.id, numericStartDate, numericEndDate)
              const reportData = generateDailyScheduleReportDataWithAMPMSplit(
                businessSettings,
                fellowStaffMembers,
                selectedDayForViewingShifts,
                schedulesWithLocalSchedule,
                business,
                workEvents
              );

              if (reportData) {
                const blob = await pdf(
                  <DailyScheduleReportAMPMSplitPdfDocument
                    report={reportData}
                    businessName={business.businessName}
                  />
                ).toBlob();
                // setReportIdLoading(null)
                const url = URL.createObjectURL(blob);
                setDailyPdfReportLoading(false);

                window.open(url, "_blank");
              } else {
                setDailyPdfReportLoading(false);
                alert("No report data available");
              }
            }
          } catch (e) {
            setDailyPdfReportLoading(false);
            alert("Opps, something went wrong generating your report");
            console.log("error generating report", e);
          }
        }}
      >
        Download with AM/PM split
        {dailyPdfReportLoading ? (
          <Box style={{ position: "absolute", right: 10, bottom: 15 }}>
            <Spinner size={20} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );

  const renderSingleDayGrid = () => {
    if (
      !selectedDayForViewingShifts ||
      (!scheduleForWeekRange && !duplicatedScheduleForAWeekRange)
    ) {
      return null;
    }

    let shiftsForADay = getShiftsForADay(
      selectedDayForViewingShifts,
      weekRange,
      schedulesWithLocalSchedule,
      business.timezone,
      fixedSchedules
    );
    if (departmentScheduleViewType) {
      shiftsForADay = shiftsForADay.filter((shift) => {
        const shiftPosition = shift.position;
        const positionData = getPositionById(businessSettings, shiftPosition);
        return positionData?.departmentId === departmentScheduleViewType;
      });
    }

    const userFilteredShiftsForADay = shiftsForADay.filter(
      (sh) =>
        !hiddenShiftTypeStartEndTimeItems.find((i) => i === sh.shiftTypeId)
    );
    const shiftsByShiftType = groupShiftsByPosition(userFilteredShiftsForADay);

    // if (!scheduleForWeekRange) return null;
    return (
      <SingleDayGrid
        duplicateScheduleToUse={duplicatedScheduleForAWeekRange}
        scheduleId={scheduleForWeekRange?.id}
        onShiftPress={(shift) => {
          setSelectedShiftForEdit(shift);
          // setShowAssignStaffModalContent({shiftId: shift?.id, scheduleId: scheduleForWeekRange.id});
        }}
        groupedShifts={shiftsByShiftType}
        jobFunctions={jobFunctions}
        scheduleForWeekRange={scheduleForWeekRange}
      />
    );
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        style={{
          overflowY: "scroll",
          overflowX: "visible",
          width: "20vw",
          minWidth: 280,
        }}
      >
        {renderSingleDaySideBar()}
      </Box>
      <Box
        display="flex"
        style={{
          width: "80vw",
          overflowX: "scroll",
          height: "90vh",
          overflowY: "scroll",
        }}
      >
        {renderSingleDayGrid()}
      </Box>
    </>
  );
};
