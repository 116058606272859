import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";

import {
  useCreateRawItem,
  useUpdateRawItem,
  UpdateRawItemDTO,
  UpdatePrepItemDTO,
  useDeleteRawItem,
  useRawItemsInfo,
} from "controllers/inventoryItemInfo";

import {
  ColumnInstruction,
  UpdateState,
} from "components/Table/HorizontalTable";

import { RootState } from "../../../model/store";
import instructions, { ItemProps } from "./instructions";

const columns: ColumnInstruction<ItemProps>[] = [
  { type: "data", header: "Item", attribute: "itemName" },
  { type: "data", header: "Primary Group", attribute: "primaryGroup" },
  { type: "data", header: "Secondary Group", attribute: "secondaryGroup" },
  { type: "data", header: "Ingredient Unit", attribute: "ingredientUnitName" },
  { type: "data", header: "Reporting Unit", attribute: "productionUnitName" },
  { type: "data", header: "Conversion Ratio", attribute: "conversionRatio" },
  { type: "data", header: "Locations", attribute: "locations", width: 2 },
  { type: "projection", header: "Stock Level", attribute: "stockLevel" },
];

const useItems = () => {
  const businessId: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.id;
  const itemsQuery = useRawItemsInfo(businessId);
  const updateRaw = useUpdateRawItem(businessId);
  const createRaw = useCreateRawItem(businessId);
  const deleteRaw = useDeleteRawItem(businessId);

  const [isEditing, setEditing] = useState(false);

  const prefix = "new__item";

  const [addedIds, updateItems] = useReducer(
    (curr: string[], clear: boolean) => {
      if (clear) return [];
      return [...curr, prefix + curr.length];
    },
    []
  );

  const add = useCallback(() => {
    setEditing(true);
    updateItems(false);
  }, []);

  useEffect(() => {
    if (!isEditing) updateItems(true);
  }, [isEditing]);

  const itemsFromServer = useMemo(() => {
    return (
      itemsQuery.data?.map((data) => ({
        uniqueId: data.id,
        minPar: data.minPar,
        maxPar: data.maxPar,
        theoreticalStock: data.theoreticalStock,
        itemName: data.name,
        primaryGroup: data.primaryGroup?.id,
        secondaryGroup: data.secondaryGroup?.id,
        productionUnitName: data.productionUnitName,
        ingredientUnitName: data.ingredientUnitName ?? "",
        conversionRatio: data.ingredientsPerProduct,
        locations: data.locations.map(i => i.id),
      })) ?? []
    );
  }, [itemsQuery.data]);

  const addedItems = useMemo(() => {
    return addedIds.map((uniqueId) => ({
      uniqueId,
      itemName: "",
      primaryGroup: "",
      secondaryGroup: "",
      productionUnitName: "Unit",
      ingredientUnitName: "Unit",
      minPar: 0,
      maxPar: 0,
      theoreticalStock: [],
      conversionRatio: 1,
      locations: [],
    }));
  }, [addedIds]);

  const dataWithAdditions = useMemo(
    () => [...addedItems, ...itemsFromServer],
    [addedItems, itemsFromServer]
  );

  const saveChanges = useCallback(
    async (instructions: UpdateState) => {
      const toCreate = addedIds.map((id) => {
        const updates = instructions[id];
        return {
          // actual values - defaults must stay in sync with addedIds.map...
          name: updates?.itemName?.newValue as string,
          productionUnitName: (updates?.productionUnitName?.newValue ?? "Unit") as string,
          ingredientUnitName: (updates?.ingredientUnitName?.newValue ?? "Unit") as string,
          ingredientsPerProduct: (updates?.conversionRatio?.newValue ?? 1) as number,
          locationIds: (updates?.locations?.newValue ?? []) as string[],
          primaryGroupId: (updates?.primaryGroup?.newValue ?? "") as string,
          secondaryGroupId: (updates?.secondaryGroup?.newValue ?? "") as string,
          // some default values
          allergenIds: [],
          isKey: false,
          isActualized: false,
          shelfLifeHours: 24,
          shelfLifeUnits: "days",
          minPar: 1,
          maxPar: 10,

        };
      });
      return Promise.all([
        ...Object.entries(instructions)
          .filter(([id]) => !id.startsWith(prefix))
          .map(([itemId, newValue]) => {
            const updateDTO = Object.keys(newValue).reduce((curr, key) => {
              const nv = newValue[key].newValue;
              if (key == "itemName") {
                curr.name = nv as string;
              } else if (key == "locations") {
                curr.locationIds = nv as string[];
              } else if (key == "primaryGroup") {
                curr.primaryGroupId = nv as string;
              } else if (key == "secondaryGroup") {
                curr.secondaryGroupId = nv as string;
              } else if (key == "productionUnitName") {
                curr.productionUnitName = nv as string;
              } else if (key == "conversionRatio") {
                curr.ingredientsPerProduct = Number(nv) as number;
              }
              return curr;
            }, {} as UpdateRawItemDTO | UpdatePrepItemDTO);
            return updateRaw.mutateAsync({
              itemId,
              updatedItem: updateDTO,
            });
          }),
        ...toCreate.map((item) => {
          return createRaw.mutateAsync(item);
        }),
      ]).then((result) => {
        if (toCreate.length === 1) {
          const created = result[result.length - 1];
          return created?.id ?? null;
        }
        return null;
      });
    },
    [addedIds, createRaw, updateRaw, itemsFromServer]
  );

  const deleteItems = useCallback(
    async (ids: string[]) => {
      const deletions = ids.map((id) => {
        const item = itemsFromServer.find(i => i.uniqueId == id);
        if (!item) return Promise.resolve();
        return deleteRaw.mutateAsync(id);
      });
      return Promise.all(deletions);
    },
    [itemsFromServer],
  );

  const filterItems = useCallback((item: ItemProps, search: string) => {
    const lc = search.toLowerCase();
    return item.itemName.toLowerCase().includes(lc);
  }, []);

  return {
    itemsData: dataWithAdditions,
    addItem: add,
    saveChanges,
    deleteItems,
    columns,
    instructions,
    isEditing,
    setEditing,
    filterItems,
  };
};
export default useItems;
