import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import {
  composeAdditionalShiftsFromShiftStarters,
  createEmptyScheduleFromShiftStarters,
  editMultipleShifts,
} from "controllers/schedule";

import { getPositionById } from "model/selectors/businessSettings";
import { RootState } from "model/store";

type ShiftTypeColor = {
  mainColor: string;
  backgroundColor: string;
  name: string;
};
export type ShiftType = {
  label: string;
  value: string;
  color: string;
  borderColor: string;
};
export type Roles = {
  quantity: string;
  jobfunction: string;
};

export type EditShiftypeProp = {
  roles: Roles[];
  shiftStarters: ShiftStarter[];
  shiftType: ShiftType;
  selectedDate: Date;
};

export type DeleteShiftType = {
  shiftId: string;
  draftScheduleId: string;
};
export type ComposeShiftType = {
  businessId: string;
  weekRange: Date[];
  shiftType: string;
  selectedDays: string[];
  shiftStarters: ShiftStarter[];
  draftScheduleId: string;
};
const availableColorsForShiftTypes: ShiftTypeColor[] = [
  { backgroundColor: "#F6FEF1", mainColor: "#D3F3BF", name: "Green" },
  { backgroundColor: "#ECF5F8", mainColor: "#C8E8F3", name: "Blue" },
  { backgroundColor: "#FDF2F9", mainColor: "#F4D7E7", name: "Pink" },
  { backgroundColor: "#FAF7E0", mainColor: "#F6EEAA", name: "Yellow" },
  { backgroundColor: "#FAEDDF", mainColor: "#F2DEC1", name: "Orange" },
  { backgroundColor: "#F4F4FE", mainColor: "#DEDEF5", name: "Purple" },
];
const quantityData = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "4",
    value: "4",
  },
  {
    label: "5",
    value: "5",
  },
  {
    label: "6",
    value: "6",
  },
  {
    label: "7",
    value: "7",
  },
  {
    label: "8",
    value: "8",
  },
  {
    label: "9",
    value: "9",
  },
  {
    label: "10",
    value: "10",
  },
];
const useCreateShift = (
  fohScheduleForAWeekRange: TangoSchedule | undefined,
  bohScheduleForAWeekRange: TangoSchedule | undefined,
  jobFunctions: TangoBusinessSettings["jobFunctions"],
  editData?: EditShiftypeProp | null
) => {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const colors = [
    {
      backgroundColor: "#FDF2F9",
      mainColor: "#F4D7E7",
      name: "pink",
    },
    {
      backgroundColor: "#FAEDDF",
      mainColor: "#F2DEC1",
      name: "orange",
    },
    {
      backgroundColor: "#FAF7E0",
      mainColor: "#F6EEAA",
      name: "yellow",
    },
    {
      backgroundColor: "#F6FEF1",
      mainColor: "#D3F3BF",
      name: "green",
    },
    {
      backgroundColor: "#ECF5F8",
      mainColor: "#C8E8F3",
      name: "blue",
    },
    {
      backgroundColor: "#F4F4FE",
      mainColor: "#DEDEF5",
      name: "purple",
    },
  ];
  const [shiftType, setShiftType] = useState<ShiftType | null>(null);
  const [showColorPopover, setShowColorPopover] = useState(false);
  const [showShiftTypeDropdown, setShowShiftTypeDropdown] = useState(false);
  const [shiftStart, setShiftStart] = useState("09:00");
  const [shiftEnd, setShiftEnd] = useState("18:00");
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [shiftStarters, setShiftStarters] = useState<ShiftStarter[]>([]);
  const [listOfShiftsToBeDeleted, setListOfShiftsToBeDelete] = useState<
    DeleteShiftType[]
  >([]);

  const [shiftTypeColor, setShiftTypeColor] = useState<{
    backgroundColor: string;
    mainColor: string;
    name: string;
  }>(colors[0]);
  const [roles, setRoles] = useState<Roles[]>([
    { quantity: "1", jobfunction: "" },
  ]);

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  useEffect(() => {
    if (editData) {
      setRoles(editData.roles);
      setShiftStarters(editData.shiftStarters);
      setShiftType(editData.shiftType);
      setShiftStart(editData.shiftStarters[0].startTime);
      setShiftEnd(editData.shiftStarters[0].endTime);
    }
  }, [editData]);

  const clearState = useCallback(() => {
    setRoles([{ quantity: "1", jobfunction: "" }]);
    setShiftStarters([]);
    setShiftType(null);
    setShiftStart("09:00");
    setShiftEnd("18:00");
    setSelectedDays([]);
    setListOfShiftsToBeDelete([]);
  }, []);
  const updateSelectedDays = useCallback(
    (day: string) => {
      const existingDays = [...selectedDays];
      const dayIndex = existingDays.findIndex((item) => item === day);

      if (dayIndex > -1) {
        existingDays.splice(dayIndex, 1);
      } else {
        existingDays.push(day);
      }
      setSelectedDays(existingDays);
    },
    [selectedDays]
  );

  const updateShiftStarters = useCallback(
    (jobFunctioId: string, numberOfRoles: number) => {
      console.log(shiftStarters);
      const counter = numberOfRoles;
      const remainingShifts = shiftStarters.filter(
        (item) => item.position !== jobFunctioId
      );
      do {
        const additionalShiftStarter: ShiftStarter = {
          id: uuidv4(),
          startTime: shiftStart,
          endTime: shiftEnd,
          position: jobFunctioId,
          staffId: null,
        };
        remainingShifts.push(additionalShiftStarter);
      } while (counter == 1);
      setShiftStarters(remainingShifts);
    },
    [shiftStart, shiftEnd]
  );

  const updateRoles = useCallback(
    (roleIndex: number, qunatity: string, jobfunction: string) => {
      const updatedRoles = [...roles].map((role, index) => {
        if (index === roleIndex) {
          return { quantity: qunatity, jobfunction };
        }
        return role;
      });
      setRoles(updatedRoles);
    },
    [roles]
  );
  const addNewRole = useCallback(() => {
    const newRoles: Roles[] = [...roles, { quantity: "1", jobfunction: "" }];
    setRoles(newRoles);
  }, [roles]);

  const deleteRole = useCallback(
    (roleIndex: number, isEditing: boolean) => {
      if (isEditing) {
        const deletedRole = roles[roleIndex];
        const shiftsWithGivenRole = shiftStarters.filter(
          (shift) => shift.position === deletedRole.jobfunction
        );
        const arrayOfShiftsToDelete = shiftsWithGivenRole.map((shift) => ({
          shiftId: shift.id,
          draftScheduleId: shift.draftScheduleId || "",
        }));
        setListOfShiftsToBeDelete([
          ...listOfShiftsToBeDeleted,
          ...arrayOfShiftsToDelete,
        ]);
      }
      if (roles.length > 1) {
        const newRoles = [...roles].filter(
          (role, index) => index !== roleIndex
        );
        setRoles(newRoles);
      }
    },
    [roles]
  );

  const saveShift = useCallback(
    async (
      weekRange: Date[],
      businessId: string,
      closeModal: () => void,
      existingDrafts?: { fohScheduleId: string; bohScheduleId: string }
    ) => {
      console.log(shiftType, shiftEnd.length, shiftStart.length, roles.length);
      if (shiftType && shiftEnd.length && shiftStart.length && roles.length) {
        console.log("here 2");
        const processedShiftStarters: ShiftStarter[] = [];
        roles.forEach((role, index) => {
          let counter = parseInt(role.quantity);
          console.log(counter);
          do {
            const additionalShiftStarter: ShiftStarter = {
              id: uuidv4(),
              startTime: shiftStart,
              endTime: shiftEnd,
              position: role.jobfunction,
              staffId: null,
            };
            processedShiftStarters.push(additionalShiftStarter);
            counter--;
          } while (counter >= 1);
        });
        try {
          if (existingDrafts) {
            console.log("existingDrafts", existingDrafts);
            const groupedShiftStarters = _.groupBy(
              processedShiftStarters,
              (sh) =>
                getPositionById(businessSettings, sh.position)?.departmentId
            );
            const fohStarters = groupedShiftStarters?.foh ?? [];
            if (fohStarters.length) {
              await composeAdditionalShiftsFromShiftStarters(
                fohStarters,
                weekRange,
                shiftType.value,
                selectedDays,
                existingDrafts.fohScheduleId,
                businessId
              );
            }
            const bohStarters = groupedShiftStarters?.boh ?? [];
            if (bohStarters.length) {
              await composeAdditionalShiftsFromShiftStarters(
                bohStarters,
                weekRange,
                shiftType.value,
                selectedDays,
                existingDrafts.bohScheduleId,
                businessId
              );
            }
          } else {
            await createEmptyScheduleFromShiftStarters(
              weekRange,
              processedShiftStarters,
              businessId,
              shiftType.value,
              selectedDays
            );
          }

          clearState();
          closeModal();
        } catch (error) {}
      }
    },
    [roles, shiftEnd, shiftStart, shiftType, selectedDays, businessSettings]
  );

  const updateMultipleShifts = useCallback(
    async (businessId: string, closeModal: () => void, weekRange: Date[]) => {
      if (
        shiftType &&
        shiftEnd.length &&
        shiftStart.length &&
        roles.length &&
        editData
      ) {
        console.log("editData", editData);
        const editRequests: EditShiftType[] = [];
        const newShifts: ComposeShiftType[] = [];
        const deleteRequests: DeleteShiftType[] = [];
        roles.forEach((role) => {
          const shiftsWithRole = shiftStarters
            .filter((item) => item.position === role.jobfunction)
            .sort((a, b) => {
              if (!b.staffId) {
                return -1;
              } else {
                return 1;
              }
            });
          const totalRoles = Number(role.quantity);
          if (totalRoles >= shiftsWithRole.length) {
            const selectedJobFunction = jobFunctions[role.jobfunction];
            const draftScheduleId =
              selectedJobFunction.departmentId === "foh"
                ? fohScheduleForAWeekRange?.id
                : bohScheduleForAWeekRange?.id;
            const composeRequests: ComposeShiftType = {
              businessId,
              shiftType: shiftType.value,
              weekRange,
              selectedDays: [moment(editData.selectedDate, "D").toString()],
              shiftStarters: [],
              draftScheduleId: draftScheduleId ?? "",
            };

            Array.from({
              length: totalRoles,
            }).forEach((item, index) => {
              const currentShift = shiftsWithRole[index];
              if (currentShift) {
                const data = {
                  shiftId: currentShift.id,
                  startTime: shiftStart,
                  endTime: shiftEnd,
                  position: role.jobfunction,
                  staffId: currentShift.staffId,
                  draftScheduleId: currentShift.draftScheduleId || "",
                  notes: currentShift.notes || "",
                  shiftTypeId: shiftType.value,
                };
                editRequests.push(data);
              } else {
                const additionalShiftStarter: ShiftStarter = {
                  id: uuidv4(),
                  startTime: shiftStart,
                  endTime: shiftEnd,
                  position: role.jobfunction,
                  staffId: null,
                };
                composeRequests.shiftStarters.push(additionalShiftStarter);
                console.log("add new one", totalRoles + role.jobfunction);
              }
            });
            newShifts.push(composeRequests);
          } else {
            const decreasedRoles = shiftsWithRole.length - totalRoles;
            const shiftsToBeDeleted = shiftsWithRole.splice(
              decreasedRoles,
              decreasedRoles
            );
            shiftsToBeDeleted.forEach((item) => {
              const data = {
                shiftId: item.id,
                draftScheduleId: item.draftScheduleId || "",
              };
              deleteRequests.push(data);
            });
          }
        });

        try {
          await editMultipleShifts(
            businessId,
            editRequests,
            [...deleteRequests, ...listOfShiftsToBeDeleted],
            newShifts
          );
          clearState();
          closeModal();
        } catch (error) {
          console.log(error);
        }
      }
    },
    [shiftType, shiftEnd, shiftStart, roles, shiftStarters]
  );

  return {
    shiftType,
    setShiftType,
    showColorPopover,
    setShowColorPopover,
    days,
    shiftStart,
    shiftEnd,
    setShiftStart,
    setShiftEnd,
    selectedDays,
    setSelectedDays,
    updateSelectedDays,
    updateShiftStarters,
    roles,
    setRoles,
    updateRoles,
    addNewRole,
    deleteRole,
    quantityData,
    saveShift,
    updateMultipleShifts,
    showShiftTypeDropdown,
    setShowShiftTypeDropdown,
    shiftTypeColor,
    setShiftTypeColor,
    colors,
    clearState,
  };
};
export default useCreateShift;
