import React, { useCallback, useMemo, useState } from 'react';
import UIComponents from '@tangopay/tango-ui-library';

const { Modal, Button } = UIComponents;
import {
    DraftIdWithDepartmentId,
    publishDraft,
    publishDraftSchedule,
    publishDuplicateSchedule,
    publishMultipleDrafts,
} from "controllers/schedule";
type Props = {
    isOpen: boolean;
    closeModal: () => void;
    schedule?: TangoSchedule | null;
    duplicatedScheduleForAWeekRange?: TangoSchedule | null;
    scheduleForWeekRange?: TangoSchedule | null;
    schedulesWithLocalSchedule: TangoSchedule[];
    draftsWithDepartmentForAWeek: DraftIdWithDepartmentId[];
    business: TangoBusiness
}
const PublishScheduleModal = ({ isOpen,
    closeModal,
    schedule,
    duplicatedScheduleForAWeekRange,
    scheduleForWeekRange,
    schedulesWithLocalSchedule, draftsWithDepartmentForAWeek, business }: Props) => {
    const [isFohSelected, setIsFohSelected] = useState(false);
    const [isBohSelected, setIsBohSelected] = useState(false);
    const clearState = () => {
        setIsBohSelected(false);
        setIsFohSelected(false);
    };
    const departmentToPublish = useMemo(() => {
        if (isBohSelected && isFohSelected) {
            return "both"
        } else if (isBohSelected) {
            return "boh";
        } else if (isFohSelected) {
            return "foh";
        } else {
            return null
        }
    }, [isBohSelected, isFohSelected])
    const publishSchedulesHandler = useCallback(async () => {
        const scheduleToUse = schedule || duplicatedScheduleForAWeekRange;


        if (scheduleToUse && business) {
            if (scheduleToUse?.isDuplicate && departmentToPublish) {
                await publishDuplicateSchedule(
                    business.id,
                    scheduleToUse,
                    departmentToPublish,
                );
                clearState();
            } else {
                if (isFohSelected && isBohSelected) {
                    const fohSchedule = draftsWithDepartmentForAWeek.find(
                        (ds) => ds.departmentId === "foh"
                    );
                    const bohSchedule = draftsWithDepartmentForAWeek.find(
                        (ds) => ds.departmentId === "boh"
                    );
                    if (fohSchedule && bohSchedule) {
                        await publishMultipleDrafts(business.id, [
                            fohSchedule.draftId,
                            bohSchedule.draftId,
                        ]);
                    } else {
                        // setLoading(false);
                        // toaster.show({
                        //     message:
                        //         "Something went wrong here, weren't able to find drafts.",
                        //     intent: Intent.DANGER,
                        // });
                    }
                }
                else if (isBohSelected) {
                    const bohSchedule = draftsWithDepartmentForAWeek.find(
                        (ds) => ds.departmentId === "boh"
                    );
                    if (bohSchedule) {
                        await publishDraft(business.id, bohSchedule.draftId);
                    } else {
                        // toaster.show({
                        //     message:
                        //         "Something went wrong here, weren't able to find boh draft.",
                        //     intent: Intent.DANGER,
                        // });
                    }
                } else if (isFohSelected) {
                    const fohSchedule = draftsWithDepartmentForAWeek.find(
                        (ds) => ds.departmentId === "foh"
                    );
                    if (fohSchedule) {
                        await publishDraft(business.id, fohSchedule.draftId);
                    } else {
                        // setLoading(false);
                        // toaster.show({
                        //     message:
                        //         "Something went wrong here, weren't able to find foh draft.",
                        //     intent: Intent.DANGER,
                        // });
                    }
                }
                // setLoading(false);
                clearState();
            }
            closeModal()
        }
    }, [
        schedule,
        duplicatedScheduleForAWeekRange,
        business,
        draftsWithDepartmentForAWeek,
        isFohSelected,
        isBohSelected,
    ]);
    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            width="521"
        >
            <div className='font-lato-black text-2xl text-black'>Publish Schedule Changes?</div>
            <div className='flex items-center mt-9'>
                <Button
                    onClick={() => setIsFohSelected(!isFohSelected)}
                    label={"FOH Schedule"}

                    className={`${isFohSelected ? 'bg-black text-white border-black' : ' border-grey-1 text-grey-2'} mb-2 mr-2 border-solid cursor-pointer`}
                    type="btn-style-outline"
                    size="btn-medium"
                    rightIconColor={`${isFohSelected ? '#fff' : '#424452'}`}
                    rightIcon={`${isFohSelected ? "checkmark" : 'placeholder'}`} />

                <Button
                    onClick={() => setIsBohSelected(!isBohSelected)}
                    label={"BOH Schedule"}

                    className={`${isBohSelected ? 'bg-black text-white border-black' : ' border-grey-1 text-grey-2'} mb-2 mr-2 border-solid cursor-pointer`}
                    type="btn-style-outline"
                    size="btn-medium"
                    rightIconColor={`${isBohSelected ? '#fff' : '#424452'}`}
                    rightIcon={`${isBohSelected ? "checkmark" : 'placeholder'}`} />
            </div>
            <div className='mt-9 flex'>
                <Button
                    label='Cancel'
                    className='w-full mr-4 justify-center cursor-pointer'
                    type="btn-style-1"
                    size="btn-medium" />
                <Button
                    label={`Publish `}
                    onClick={() => publishSchedulesHandler()}
                    className={`w-full justify-center cursor-pointer ${!isBohSelected && !isFohSelected ? 'text-grey-2 hover:text-grey-2' : ''}`}
                    disabled={!isBohSelected && !isFohSelected}
                    type="btn-style-1"
                    size="btn-medium" />
            </div>
        </Modal>
    )
}
export default PublishScheduleModal;