import { baseUrl } from './core';
import { generateBearerToken } from './init';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { GiftCardData } from 'views/Payments/GiftCards/useGiftCards';

const testURl = 'http://localhost:9001/tango-2/us-central1';

const giftCardsApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/gift-cards`,
});

giftCardsApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

export const fetchSingleGiftCard = async (businessId: string, cardId: string) => {
  const res = await giftCardsApiClient.get<GiftCardData>(`${businessId}/card/${cardId}`);
  return res.data;
};

export const fetchGiftCards = async (businessId: string, lastGiftCardId: string) => {
  if (!businessId) return null;
  const res = await giftCardsApiClient.get<GiftCardData[]>(`${businessId}/${lastGiftCardId}`);
  return res.data ? res.data : [];
};
export const fetchGiftCardsBetweenDates = async (businessId: string, start: Date, end: Date) => {
  if (!businessId) return null;
  const res = await giftCardsApiClient.get<GiftCardData[]>(`${businessId}/${start}/${end}`);
  return res.data ? res.data : [];
};

export const useGiftCardsQuery = (businessId: string, lastCompletedTabId: string) => {
  return useQuery({
    queryKey: ['giftCardsInfo', businessId, lastCompletedTabId],
    queryFn: () => fetchGiftCards(businessId, lastCompletedTabId),
    keepPreviousData: true, //this keeps old fetchGiftCards in table until new data is returned
    retry: 2,
    // retryDelay: 10000,
  });
};

export const useGiftCardsBetweenDatesQuery = (businessId: string, start: Date, end: Date) => {
  return useQuery(['giftCardsBetweenDatesInfo', businessId, start, end], () => fetchGiftCardsBetweenDates(businessId, start, end), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 2,
    keepPreviousData: true,
    // retryDelay: 10000,
  });
};
