import _ from "lodash";
import moment from "moment-timezone";
import React, { useCallback, useMemo, useState } from "react";



import {
  getPositionById,
  getPositionNameById,
  getShiftTypeById,
} from "model/selectors/businessSettings";
import { getShiftsForADay } from "controllers/schedule";
import UIComponents from "@tangopay/tango-ui-library";
import DailyShiftCard from "./DailyShiftCard";
import { groupShiftsByJobFunction } from "./WeeklyShifts";
import { updateBusinessSettings } from "controllers/business";
import { extractShiftDurationInHours, roundNumberToTwoDecimals, sanitizeNumericField } from "controllers/reporting";

const { Icon, Modal, TimePickerInput, Button } = UIComponents;
interface StaffMemberOrientedWeeklyViewProps {
  schedulesWithLocalSchedule: TangoSchedule[];
  weekRange: Date[];
  departmentScheduleViewType: DepartmentId | null;
  duplicatedScheduleForAWeekRange: TangoSchedule | null;
  scheduleForWeekRange: TangoSchedule | null;
  setSelectedShiftForEdit: (shift: TangoShift) => void;
  jobFunctions: TangoJobFunctions;
  fixedSchedules: TangoFixedSchedule[];
  business: TangoBusiness;
  businessSettings: TangoBusinessSettings;
  fellowStaffMembers: StaffMember[];
  onOptionSelect: (type: 'view' | 'move' | 'delete', groupedShift: GroupedShifts) => void;
  staffMemberNameFilter: string;
  roleFilterId: string | null;
  setCurrentDateForDailyShiftView: (date: Date | null) => void;
  dayIndex: number;
  mergedScheduleForAWeekRange?: TangoSchedule;
}

const DailyShiftView = ({
  schedulesWithLocalSchedule,
  weekRange,
  departmentScheduleViewType,
  duplicatedScheduleForAWeekRange,
  scheduleForWeekRange,
  setSelectedShiftForEdit,
  jobFunctions,
  business,
  fixedSchedules,
  businessSettings,
  fellowStaffMembers,
  onOptionSelect,
  staffMemberNameFilter,
  roleFilterId,
  setCurrentDateForDailyShiftView,
  mergedScheduleForAWeekRange,
  dayIndex,
}: StaffMemberOrientedWeeklyViewProps) => {
  const [currentDateIndex, setCurrentDateIndex] = useState(dayIndex);
  const [showAmPmModal, setShowAmPmModal] = useState(false);
  const [splitTime, setSplitTime] = useState(businessSettings.amPmSplitTime);
  const currentDate = useMemo(() => {
    return weekRange[currentDateIndex]
  }, [currentDateIndex, weekRange])

  const amPmTime = useMemo(() => {
    return moment(splitTime, 'HH:mm').format('h a').toString()
  }, [splitTime])
  const [selectedView, setSelectedView] = useState<"ampm-view" | "tag-view">("tag-view")
  const shiftsForADay = useMemo(() => {
    if (!mergedScheduleForAWeekRange || !mergedScheduleForAWeekRange.shifts) {
      return []
    }

    if (departmentScheduleViewType) {
      return mergedScheduleForAWeekRange
        .shifts
        .filter(sh => {
          const shiftPosition = sh.position;
          const positionData = getPositionById(businessSettings, shiftPosition);
          return positionData?.departmentId === departmentScheduleViewType;
        })
        .filter(sh =>
          moment(sh.startDate.toDate()).isSameOrAfter(moment(currentDate))
          && moment(sh.startDate.toDate()).isSameOrBefore(moment(currentDate).add(1, 'day')))
    }


    return mergedScheduleForAWeekRange
      .shifts
      .filter(sh =>
        moment(sh.startDate.toDate()).isSameOrAfter(moment(currentDate))
        && moment(sh.startDate.toDate()).isSameOrBefore(moment(currentDate).add(1, 'day')))

  }, [currentDate, mergedScheduleForAWeekRange, departmentScheduleViewType, businessSettings])




  const shiftsByPosition = useMemo(() => {
    const filteredShiftsForADay = (roleFilterId ? shiftsForADay.filter((shift) => shift.position === (roleFilterId)) : shiftsForADay)
    const nameFilteredShifts = staffMemberNameFilter ? filteredShiftsForADay.filter((shift) => {
      const staff = fellowStaffMembers.find((staff) => shift.staffId === staff.id)
      const staffName = staff ? staff.contact.firstName + ' ' + staff.contact.lastName : "";
      return (staffName.toLowerCase()).search(staffMemberNameFilter.toLowerCase()) > -1
    }) : filteredShiftsForADay;
    const shiftsGroupedByPosition = _.groupBy(nameFilteredShifts, (shift) => shift.position);
    return shiftsGroupedByPosition

  }, [currentDate, weekRange, schedulesWithLocalSchedule, business, fixedSchedules, shiftsForADay, roleFilterId, fellowStaffMembers, staffMemberNameFilter])

  const colums = useMemo(() => {
    if (selectedView === 'ampm-view') {
      return ["AM", "PM"]
    } else {
      const groupedByShiftTypes = _.groupBy(shiftsForADay, (item) => item.shiftTypeId)
      return _.orderBy(_.keys(groupedByShiftTypes), (item) => item.toLowerCase(), ['asc']);
    }
  }, [selectedView, weekRange, shiftsForADay])

  const updateSplitTime = useCallback(async () => {

    const response = await updateBusinessSettings({ amPmSplitTime: splitTime }, businessSettings.businessId)
    if (response.data) {
      setShowAmPmModal(false)
    }
  }, [splitTime, businessSettings])
  return (
    <div style={{ width: "80vw", height: '100%' }} className="px-10 overflow-y-auto border-l border-solid border-t-0 border-b-0 border-r-0 border-grey-1">
      <div className="flex relative pt-8 pb-4 border-b border-solid border-grey-1 border-t-0 border-l-0 border-r-0">
        <div className="flex items-center text-grey-3 font-lato-bold text-base  flex-1">
          <div className="flex">
            <span>{moment(currentDate).format("ddd")}</span>
            <span className="ml-3">{moment(currentDate).format("D")}</span>
          </div>
          <div className="flex items-center ml-8">
            {
              currentDateIndex > 0 ?
                <Icon name="chevron-left" onClick={() => {
                  const newIndex = currentDateIndex - 1
                  setCurrentDateIndex(newIndex)
                  setCurrentDateForDailyShiftView(weekRange[newIndex])
                }} size="20" color="#7C7F93" className="cursor-pointer" />
                :
                null
            }
            {
              currentDateIndex < weekRange.length - 1 ?
                <Icon name="chevron-right" onClick={() => {
                  const newIndex = currentDateIndex + 1
                  setCurrentDateIndex(newIndex)
                  setCurrentDateForDailyShiftView(weekRange[newIndex])
                }} size="20" color="#7C7F93" className="cursor-pointer" />
                :
                null
            }

          </div>
        </div>
        {
          selectedView === 'tag-view' ?
            colums.map((column) => {
              const shiftType = getShiftTypeById(businessSettings, column)
              return (
                <div key={column} className="flex flex-1 text-grey-3 font-lato-bold text-base">
                  {shiftType?.name}
                </div>
              )
            })
            :
            <>
              <div className="flex flex-1 text-grey-3 font-lato-bold text-base items-center">
                AM <span className="text-xs font-lato-regular text-grey-2 ml-2">(before {amPmTime})</span>
              </div>
              <div className="flex flex-1 text-grey-3 font-lato-bold text-base items-center">
                PM <span className="text-xs font-lato-regular text-grey-2 ml-2">(after  {amPmTime})</span>
              </div>
            </>
        }
        {
          selectedView === 'ampm-view' ?
            <div className=" absolute right-0  flex">
              <div onClick={() => setShowAmPmModal(true)} className="mr-2 cursor-pointer text-xs font-lato-bold text-black py-1 px-4 border border-solid border-grey-1 rounded-full">
                Set AM/PM Time
              </div>
              <div onClick={() => setSelectedView('tag-view')} className="cursor-pointer text-xs font-lato-bold text-black py-1 px-4 border border-solid border-grey-1 rounded-full">
                Tag View
              </div>
            </div>

            :
            <div onClick={() => setSelectedView('ampm-view')} className=" absolute right-0 cursor-pointer text-xs font-lato-bold text-black py-1 px-4 border border-solid border-grey-1 rounded-full">
              AM/PM  View
            </div>
        }

      </div>
      {
        colums && colums.length && shiftsByPosition ? Object.keys(shiftsByPosition).map((position) => {

          return (
            <div className="flex min-h-[152px] border-b border-solid border-grey-1 border-l-0 border-r-0 border-t-0" key={position}>
              <div className="flex flex-1 pt-2 font-lato-bold text-xs text-black">
                {getPositionNameById(businessSettings, position)}
              </div>
              {
                selectedView === 'tag-view' ? colums.map((column) => {
                  const shifts = shiftsByPosition[position].filter((shift) => shift.shiftTypeId === column)
                  const shiftsByTime = _.groupBy(shifts, (shift) => `${shift.startTime} ${shift.endTime}`)
                  const shiftType = getShiftTypeById(businessSettings, column)

                  if (_.values(shiftsByTime).length) {
                    return (
                      <div className="flex-1" style={{ display: "flex", flexDirection: "column" }}>
                        {_.values(shiftsByTime).map((shifts) => {
                          const startTime = shifts[0].startTime;
                          const endTime = shifts[0].endTime;
                          const shiftPositions = groupShiftsByJobFunction(shifts, jobFunctions, fellowStaffMembers, staffMemberNameFilter)

                          return (
                            <DailyShiftCard
                              shiftType={shiftType}
                              startTime={startTime}
                              endTime={endTime}
                              shifts={shifts}
                              staffMembers={fellowStaffMembers}
                              openAssignShiftModal={(shift) => {

                                if (shift) setSelectedShiftForEdit(shift)
                              }}
                              staffMemberNameFilter={staffMemberNameFilter}
                              onOptionSelect={(selectedOption) => {
                                const data = {
                                  startTime,
                                  endTime,
                                  shiftTypeId: shifts[0].shiftTypeId,
                                  shifts,
                                  shiftPositions
                                }
                                onOptionSelect(selectedOption, data)
                              }}
                            />

                          )
                        })}
                      </div>
                    )
                  }
                  return <div className="flex flex-1 items-center">
                  </div>

                })
                  :
                  colums.map((column) => {
                    const shiftsGroupedByAMPMSplit = _.groupBy(shiftsByPosition[position], (shift) => {
                      const shiftStartMoment = moment(shift.startDate.toDate());
                      const amPmSplitTimeMoment = moment(businessSettings.amPmSplitTime, "HH:mm");
                      const amPmSplitBasedOnShiftStartMoment = shiftStartMoment.clone().set({
                        hours: amPmSplitTimeMoment.hours(),
                        minutes: amPmSplitTimeMoment.minutes(),
                      });
                      const shiftDuration = extractShiftDurationInHours(shift);

                      if (amPmSplitBasedOnShiftStartMoment.isAfter(shiftStartMoment)) {
                        const splitVsShiftStartDifferenceInHours = roundNumberToTwoDecimals(
                          sanitizeNumericField(
                            Math.abs(
                              moment
                                .duration(shiftStartMoment.diff(amPmSplitBasedOnShiftStartMoment))
                                .asHours()
                            )
                          )
                        );
                        if (splitVsShiftStartDifferenceInHours <= shiftDuration / 2) {
                          return "pm";
                        }
                        return "am";
                      }
                      return "pm";
                    });


                    const filteredShifts = column === 'AM' ? shiftsGroupedByAMPMSplit.am ?? [] : shiftsGroupedByAMPMSplit.pm ?? [];


                    if (!filteredShifts.length) {
                      return <div className="flex flex-1"></div>
                    }
                    const groupByShiftType = _.groupBy(filteredShifts, (shift) => shift.shiftTypeId);

                    return (<div className="flex flex-1 flex-col pr-1">
                      {
                        Object.keys(groupByShiftType).map((shiftTypeId) => {
                          const shiftType = getShiftTypeById(businessSettings, shiftTypeId)
                          const startTime = groupByShiftType[shiftTypeId][0].startTime;
                          const endTime = groupByShiftType[shiftTypeId][0].endTime;
                          const shifts = groupByShiftType[shiftTypeId];
                          const shiftPositions = groupShiftsByJobFunction(shifts, jobFunctions, fellowStaffMembers, staffMemberNameFilter)

                          return (
                            <DailyShiftCard
                              shiftType={shiftType}
                              startTime={startTime}
                              endTime={endTime}
                              shifts={shifts}
                              staffMembers={fellowStaffMembers}
                              openAssignShiftModal={(shift) => {


                                if (shift) setSelectedShiftForEdit(shift)
                              }}
                              staffMemberNameFilter={staffMemberNameFilter}

                              onOptionSelect={(selectedOption) => {

                                const data = {
                                  startTime,
                                  endTime,
                                  shiftTypeId: shifts[0].shiftTypeId,
                                  shifts,
                                  shiftPositions
                                }
                                onOptionSelect(selectedOption, data)
                              }}
                            />

                          )
                        })
                      }
                    </div>
                    )

                  })

              }
            </div>
          )
        })
          :
          null
      }
      <Modal isOpen={showAmPmModal} closeModal={() => setShowAmPmModal(false)} width="404">
        <div className="text-black  text-big font-lato-black">
          Set AM/PM Time
        </div>
        <div className="font-lato-medium text-sm text-grey-2 mt-2">
          Select the crossover time where the AM shift ends and the PM shift starts.
        </div>
        <div className="font-lato-medium text-sm text-grey-2 mt-4 mb-10">
          Shifts will appear under the AM or PM column based on where the majority of the shift time occurs.
        </div>
        <TimePickerInput size="large" className="split-time-picker" onChange={(date) => setSplitTime(moment(date).format('H:mm').toString())} value={moment(splitTime, 'H:mm').toDate()} />
        <div className="flex mt-10">
          <Button label="Cancel" onClick={() => setShowAmPmModal(false)} className="flex flex-1 justify-center mr-4" type="btn-style-1" size="btn-large" />
          <Button label="Save" onClick={() => updateSplitTime()} className="flex flex-1 justify-center" type="btn-style-1" size="btn-large" />
        </div>
      </Modal>
    </div>
  )
}

export default DailyShiftView;