import React from "react";

import { RenderInstruction } from "../../../components/Table/GenericCell/TableCell";
import HorizontalTable, {
  ColumnInstruction,
} from "../../../components/Table/HorizontalTable";

type VendorItemDetail = {
  uniqueId: string;
  item: string;
  price: string;
  cost: string;
  profitPercentage: string;
  productionUnit: string;
};

const vendorsData: VendorItemDetail[] = [
  {
    uniqueId: "1",
    item: "Guacamole",
    price: "N/A",
    cost: "$4.62",
    profitPercentage: "N/A",
    productionUnit: "Container",
  },
  {
    uniqueId: "2",
    item: "Avocado Toast",
    price: "$12.00",
    cost: "$5.00",
    profitPercentage: "240%",
    productionUnit: "Each",
  },
];

const columns: ColumnInstruction<VendorItemDetail>[] = [
  { type: "data", header: "Item", attribute: "item" },
  { type: "data", header: "Price", attribute: "price" },
  { type: "data", header: "Cost", attribute: "cost" },
  { type: "data", header: "Profit Percentage", attribute: "profitPercentage" },
  { type: "data", header: "Reporting Unit", attribute: "productionUnit" },
];

const instructions: { [x: string]: RenderInstruction<VendorItemDetail> } = {};

const RecipeItemsTable = () => {
  return (
    <div
      className={
        "bg-white border border-grey border-b-0 border-solid rounded-2xl"
      }
    >
      <HorizontalTable
        title="Recipes Containing"
        columns={columns}
        data={vendorsData}
        instructions={instructions}
        saveResults={() => Promise.resolve(true)}
        isEditing={false}
        hideEdit
        subTitle="Avocado"
        customActionButton="Replace Ingredient"
        onCustomAction={(ids) => console.log(ids)}
      />
    </div>
  );
};
export default RecipeItemsTable;
