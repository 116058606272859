import axios from "axios";

import { apiErrorHandler, baseUrl } from "./core";
import { generateBearerToken } from "./init";

const tangoSchedulingSettingsApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/scheduling-settings`,
});

export interface UpdateSchedulingSettingsDTO {
  businessId: string;

  overtimeQualification?: OverTimeQualification | null;

  payrollStartOfTheWeek?: string;

  amPmSplitTime?: string;

  showFullStaffSchedule?: boolean;

  payPeriodCadence?: PayPeriodCadence;

  payPeriodStartDate?: Date;

  turnLocationOff?: boolean;

  enableDropShifts?: boolean;
}

tangoSchedulingSettingsApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

export const fetchSchedulingSettingsByBusinessId = async (
  businessId: string
) => {
  const res = await tangoSchedulingSettingsApiClient.get<SchedulingSettings>(
    `/${businessId}`
  );
  return res.data;
};

export const updateSchedulingSettings = async (
  dto: UpdateSchedulingSettingsDTO
) => {
  try {
    console.log("dto", dto);
    const res = await tangoSchedulingSettingsApiClient.put<SchedulingSettings>(
      `/${dto.businessId}`,
      { ...dto }
    );
    return res.data;
  } catch (e) {
    console.log("error", e);
    apiErrorHandler(e);
  }
};
