enum ActionType {
  RECEIVE_AVAILABILITIES = "RECEIVE_AVAILABILITIES",
  RECEIVE_BUSINESS = "RECEIVE_BUSINESS",
  RECEIVE_ACCOUNT = "RECEIVE_ACCOUNT",
  RECEIVE_ACCOUNTS = "RECEIVE_ACCOUNTS",
  RECEIVE_BUSINESSES = "RECEIVE_BUSINESSES",
  RECEIVE_USER = "RECEIVE_USER",
  RECEIVE_FELLOW_STAFF_MEMBERS = "RECEIVE_FELLOW_STAFF_MEMBERS",
  RECEIVE_SCHEDULE_EVENTS = "RECEIVE_SCHEDULE_EVENTS",
  RECEIVE_WORK_EVENTS = "RECEIVE_WORK_EVENTS",
  RECEIVE_FIXED_AVAILABILITIES = "RECEIVE_FIXED_AVAILABILITIES",

  LOG_OUT = "LOG_OUT",
  RECEIVE_CHANNELS = "RECEIVE_CHANNELS",
  RECEIVE_WORKSPACES = "RECEIVE_WORKSPACES",

  RECEIVE_MESSAGES = "RECEIVE_MESSAGES",
  RECEIVE_HUDDLES = "RECEIVE_HUDDLES",

  RECEIVE_STAFF_MEMBERS = "RECEIVE_STAFF_MEMBERS",
  FETCH_OPEN_SHIFTS_REQUEST = "FETCH_OPEN_SHIFTS",
  FETCH_OPEN_SHIFTS_SUCESS = "FETCH_OPEN_SHIFTS_SUCESS",
  FETCH_OPEN_SHIFTS_ERROR = "FETCH_OPEN_SHIFTS_ERROR",
  FETCH_ANNOUNCEMENTS_REQUEST = "FETCH_ANNOUNCEMENTS_REQUEST",
  FETCH_ANNOUNCEMENTS_SUCCESS = "FETCH_ANNOUNCEMENTS_SUCCESS",
  FETCH_ANNOUNCEMENTS_ERROR = "FETCH_ANNOUNCEMENTS_ERROR",
  RECEIVE_ANNOUNCEMENTS = "RECEIVE_ANNOUNCEMENTS",

  FETCH_TRADE_SHIFTS_REQUEST = "FETCH_TRADE_SHIFTS_REQUEST",
  FETCH_TRADE_SHIFTS_SUCCESS = "FETCH_TRADE_SHIFTS_SUCCESS",
  FETCH_TRADE_SHIFTS_ERROR = "FETCH_TRADE_SHIFTS_ERROR",
  UPDATE_SCHEDULE_EVENT_REQUEST = "UPDATE_SCHEDULE_EVENT_REQUEST",
  UPDATE_SCHEDULE_EVENT_SUCCESS = "UPDATE_SCHEDULE_EVENT_SUCCESS",
  UPDATE_SCHEDULE_EVENT_ERROR = "UPDATE_SCHEDULE_EVENT_ERROR",
  RECEIVE_JOB_FUNCTIONS = "RECEIVE_JOB_FUNCTIONS",
  RECEIVE_BUSINESS_SETTINGS = "RECEIVE_BUSINESS_SETTINGS",
  RECEIVE_SCHEDULES = "RECEIVE_SCHEDULES",
  RECEIVE_DRAFT_SCHEDULES = "RECEIVE_DRAFT_SCHEDULES",

  RECEIVE_FIXED_SCHEDULES = "RECEIVE_FIXED_SCHEDULES",
  RECEIVE_AVAILABLE_LOCATIONS = "RECEIVE_AVAILABLE_LOCATIONS",

  RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS",
  RECEIVE_PRODUCT_TYPES = "RECEIVE_PRODUCT_TYPES",
  RECEIVE_MODIFIERS = "RECEIVE_MODIFIERS",
  RECEIVE_MODIFIER_OPTIONS = "RECEIVE_MODIFIER_OPTIONS",
  RECEIVE_MENU_CATEGORY = "RECEIVE_MENU_CATEGORY",
  RECEIVE_MENUS = "RECEIVE_MENUS",
  RECEIVE_ORDER_DISPLAYS = "RECEIVE_ORDER_DISPLAYS",
  RECEIVE_SERVICE_AREAS = "RECEIVE_SERVICE_AREAS",
  RECEIVE_PHYSICAL_TABLES = "RECEIVE_PHYSICAL_TABLES",
  RECEIVE_CASH_DRAWERS = "RECEIVE_CASH_DRAWERS",
  RECEIVE_ORDERS = "RECEIVE_ORDERS",
  RECEIVE_TABS = "RECEIVE_TABS",
  RECEIVE_DISCOUNTS = "RECEIVE_DISCOUNTS",
  RECEIVE_CASH_EVENTS = "RECEIVE_CASH_EVENTS",

  RECEIVE_ENTERPRISE_DOCS = "RECEIVE_ENTERPRISE_DOCS",
  RECEIVE_DOCS = "RECEIVE_DOCS",

  RECEIVE_PAYOUTS = "RECEIVE_PAYOUTS",
  RECEIVE_PAYOUT_LEDGERS = "RECEIVE_PAYOUT_LEDGERS",

  RECEIVE_PRINTERS = "RECEIVE_PRINTERS",
  EMULATOR_MODE = "EMULATOR_MODE",
  ENTERPRISE_INIT_COMPLETE = "ENTERPRISE_INIT_COMPLETE",

  RECEIVE_BOARDS = "RECEIVE_BOARDS",
  RECEIVE_BOARD_TICKETS = "RECEIVE_BOARD_TICKETS",

  // Local Action Types
  // Staffing Action Types
  STAFFING_SAVE_SCHEDULE = "STAFFING_SAVE_SCHEDULE",
  SET_CURRENT_SCHEDULE = "SET_CURRENT_SCHEDULE",
  UPDATE_DUPLICATE_SCHEDULE_TO_DRAFT = "UPDATE_DUPLICATE_SCHEDULE_TO_DRAFT",

  INVENTORY_SIDEBAR_EXPAND = "INVENTORY_SIDEBAR_EXPAND",
  INVENTORY_SIDEBAR_TOGGLE = "INVENTORY_SIDEBAR_TOGGLE",
  INVENTORY_SIDEBAR_COLLAPSE = "INVENTORY_SIDEBAR_COLLAPSE",

}

export default ActionType;
