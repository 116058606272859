import UIComponents from "@tangopay/tango-ui-library";
import React from "react";

import HorizontalTable from "components/Table/HorizontalTable";

import ShareModal from "../ShareModal";

import useFiles from "./useFiles";
import { useDocs } from "./hooks/useDocs";



const { Icon, Dropdown } = UIComponents;

export const CloudFiles = () => {
  const {
    noOp,

    isEditing,
    setEditing,
    updateData,

  } = useFiles();

  const {
    data,
    columns,
    instructions,
    handleCloudFilesRowClick,
    folderDepthHistory,
    setCurrentFolder,
    uploadFileHandler,
    createNewFolderHandler,
    setSelectedFileOrFolderIdForSharing,
    selectedFileOrFolderForSharing,
    staffMemberSharingOptions,
    handleInvites,
    extendedCurrentFolder
  } = useDocs()

  const renderTopBarFolderNavigation = () => {
    // This is the root directory
    if (folderDepthHistory.length === 1) {
      return (
        <span
          className="text-big font-lato-black text-black"
          onClick={() => {
            // Set the current folder to the first folder
            if (folderDepthHistory.length) {
              setCurrentFolder(folderDepthHistory[0]);
            }
          }}
        >
          Folders
        </span>
      );
    }

    // With more that one directory set the folder depth history
    // to the directory name selected
    return folderDepthHistory.map((directory, index) => {
      return (
        <span
          className="text-big font-lato-black text-black"
          onClick={() => {
            const newFolderDepth = folderDepthHistory.slice(0, index + 1);
            setCurrentFolder(newFolderDepth[newFolderDepth.length - 1]);
          }}
        >
          {directory.name === "root" ? "Folders" : directory.name}
          {index < folderDepthHistory.length - 1 && (
            <Icon size="30" name="chevron-right" />
          )}
        </span>
      );
    });
  };

  console.log("extendedCurrentFolder", extendedCurrentFolder)

  return (
    <>
      <div className={"flex px-10 py-6 flex-col"} style={{ width: "90%" }}>
        <input
          type="file"
          id="upload-file"
          name="filename"
          multiple
          style={{ display: "none" }}
        />
        <div className="flex items-center justify-between">
          <ul className="p-0 m-0 list-none flex items-center">

            {renderTopBarFolderNavigation()}
          </ul>
          {extendedCurrentFolder && extendedCurrentFolder.currUserAccessType === "editor" && (
            <Dropdown
              onChange={(e: any) => {
                if (e?.value === "new_file") {
                  uploadFileHandler()
                } else if (e?.value === "new_folder") {
                  createNewFolderHandler()
                }
              }}
              placeholder="Create New"
              size="medium" options={[{
                label: 'New Folder',
                value: 'new_folder'
              },
              // {
              //   label: 'New Document',
              //   value: 'New Document'
              // },
              // {
              //   label: 'New Sheet',
              //   value: 'New Sheet'
              // },
              {
                label: 'Upload File',
                value: 'new_file'
              },
              ]} />
          )}

        </div>

        <HorizontalTable
          title=""
          columns={columns}
          data={data}
          instructions={instructions}
          saveResults={noOp}
          setEditing={setEditing}
          isEditing={isEditing}
          hideEdit
          hideCheckboxes
          recievedUpdate={updateData}
          onRowClick={(uniqueId: string) => {
            console.log("uniqueId", uniqueId)
            handleCloudFilesRowClick(uniqueId);
            // const folderIndex = parseInt(data);
            // if (treeData[folderIndex]?.kind === "folder") {
            //   setSelectedFolders((folders) => [
            //     ...folders,
            //     treeData[folderIndex],
            //   ]);
            // }
          }}
          onPopupEvent={(object, event) => {
            console.log("event", event)
            if (event === "share") {
              setSelectedFileOrFolderIdForSharing(object.id)
              // setShowShareModal(true);
            }
            // if (event === "move-to") {
            //   setShowMoveModal(true);
            // }
          }}
        />
      </div>
      <ShareModal
        handleInvites={handleInvites}
        isOpen={!!selectedFileOrFolderForSharing}
        fileOrFolderForSharing={selectedFileOrFolderForSharing}
        closeModal={() => setSelectedFileOrFolderIdForSharing(null)}
        staffMemberSharingOptions={staffMemberSharingOptions}
      />
      {/* <MoveFolder
        isOpen={showMoveModal}
        closeModal={() => setShowMoveModal(false)}
        data={data}
      /> */}
    </>
  );
};