import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import './style.css';
import Box from "components/Box";
import UIComponents from "@tangopay/tango-ui-library";
import { RootState } from "model/store";
const { Icon, Popover } = UIComponents;
interface Props {
  id: string | number;
  type: string;
  shiftPositions: { name: string; quantity: number; total: number, staffIds: ({ id: string | undefined, name: string | undefined, shift: TangoShift })[] }[];
  startTime: string;
  endTime: string;
  shiftTypeId: string;
  onOptionSelect: (type: 'view' | 'move' | 'delete') => void;
  openAssignShiftModal: (v: TangoShift | null) => void;
  fohScheduleForAWeekRange: TangoSchedule | undefined;
  bohScheduleForAWeekRange: TangoSchedule | undefined;
  currentDate: string;
  selectedDate: string;
  selectedPosition: string;
  setSelectedDate: (date: string) => void;
  setSelectedPosition: (date: string) => void;
}
export const CheckMark = ({ fill }: { fill: string }) => (
  <svg
    className="checkmark"
    width="9"
    height="7"
    fill="none"
    viewBox="0 0 9 7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M4.07394 6.54851C3.66589 6.95657 3.00381 6.95657 2.59596 6.54851L0.306033 4.25853C-0.102011 3.85068 -0.102011 3.18858 0.306033 2.78072C0.713879 2.37267 1.37597 2.37267 1.78401 2.78072L3.14836 4.1449C3.25135 4.2477 3.41855 4.2477 3.52174 4.1449L7.21599 0.450571C7.62384 0.0425181 8.28592 0.0425181 8.69397 0.450571C8.88991 0.646522 9 0.912387 9 1.18948C9 1.46657 8.88991 1.73243 8.69397 1.92838L4.07394 6.54851Z"
    />
  </svg>
);

const getClassNameFromColor = (color: string) => {
  switch (color) {
    case "#FAF7E0":
    case "#FFF6E3":
      return "yellow";
    case "#F6FEF1": case "#EDFAED":
      return "green";
    case "#FDF2F9": case "#FFF0FF":
      return "pink";
    case "#FAEDDF": case "#FFEDEE":
      return "orange";
    case "#ECF5F8": case "#E3F6FA":
      return "blue";
    case "#F4F4FE": case "#F5DDFF":
      return "purple"
    default:
      return color;
  }
}
const ShiftCard = ({
  id,
  type,
  startTime,
  endTime,
  shiftTypeId,
  shiftPositions,
  onOptionSelect,
  openAssignShiftModal,
  fohScheduleForAWeekRange,
  bohScheduleForAWeekRange,
  currentDate,
  selectedDate,
  selectedPosition,
  setSelectedDate,
  setSelectedPosition,
}: Props) => {
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const shiftTypes = businessSettings.shiftTypes || [];
  const shiftType = shiftTypes.find((st) => st.id === shiftTypeId);
  const [showMenu, setShowMenu] = useState(false);

  const handleOptionSelect = (option: 'view' | 'delete' | 'move') => {
    onOptionSelect(option)
    setShowMenu(false)
  }

  const draftFohShiftPositions = useMemo(() => fohScheduleForAWeekRange?.isDraft ? fohScheduleForAWeekRange?.shifts.map((shift) => shift.position) : [], [fohScheduleForAWeekRange])
  const draftBohShiftPositions = useMemo(() => bohScheduleForAWeekRange?.isDraft ? bohScheduleForAWeekRange?.shifts.map((shift) => shift.position) : [], [bohScheduleForAWeekRange])

  return (
    <div
      key={id}
      className="mx-1 mb-6"
      style={{
        border: `0px`,
        overflow: "hidden",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="flex mb-2 justify-between">
          <div className="">
            <div className="text-tiny font-lato-bold text-black uppercase">
              {shiftType?.name}
            </div>
            <div className=" text-tiny font-lato-medium text-grey-2">
              {moment(startTime, "HH:mm").format("hh:mm a")} -{" "}
              {moment(endTime, "HH:mm").format("hh:mm a")}
            </div>
          </div>
          <Popover isOpen={showMenu} positions={["bottom"]} onClickOutside={() => setShowMenu(false)} content={
            <div className="bg-white shadow-card p-1 rounded-xl">
              <ul className="p-0 m-0 list-none cursor-pointer">
                <li className="p-2 text-tiny text-black font-lato-bold" onClick={() => {
                  handleOptionSelect('view')
                }}>View Details</li>
                <li className="p-2 text-tiny text-black font-lato-bold" onClick={() => {
                  handleOptionSelect('move')
                }}>Move Or Duplicate</li>
                <li className="p-2 text-tiny text-error-red font-lato-bold" onClick={() => {
                  handleOptionSelect('delete')
                }}>Delete Shift</li>
              </ul>
            </div>
          }>
            <div>
              <Icon name="more" onClick={() => setShowMenu(true)} size="12" className="cursor-pointer mr-1" />

            </div>

          </Popover>
        </div>
        <Box style={{ width: "100%" }}>
          <ul style={{ width: "100%" }} className="p-0 m-0 list-none rounded-md overflow-hidden">
            {shiftPositions.sort((a, b) => (a.quantity + a.total) - (b.quantity + b.total)).map((shiftPosition, positionIndex) => {
              const isScheduleStatusDraft = draftBohShiftPositions?.includes(shiftPosition.staffIds[0].shift.position) || draftFohShiftPositions?.includes(shiftPosition.staffIds[0].shift.position)
              let marginClass = '';
              const previousPosition = shiftPositions[positionIndex - 1];

              if (previousPosition && (shiftPosition.quantity === shiftPosition.total) && (previousPosition.quantity !== previousPosition.total)) {
                marginClass = 'mt-1'
              }
              const colorClassName = shiftType ? getClassNameFromColor(shiftType.backgroundColor) : "grey"
              const expandedShift = (currentDate === selectedDate && selectedPosition === shiftPosition.name)
              return (
                <li
                  className={`text-xs font-lato-bold text-black ${marginClass} ${colorClassName}__role ${isScheduleStatusDraft ? 'role-draft' : ''}`}
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                  key={positionIndex + shiftPosition.name}

                >

                  <div className="px-2 py-1 flex justify-between items-center" onClick={() => {
                    if (!expandedShift) {
                      setSelectedDate(currentDate)
                      setSelectedPosition(shiftPosition.name)
                    } else {
                      setSelectedDate('')
                      setSelectedPosition('')
                    }
                  }} style={{ backgroundColor: shiftType ? shiftType.backgroundColor + 'cc' : 'transparent' }}>
                    <div>
                      {shiftPosition.quantity}/{shiftPosition.total}{" "}
                      {shiftPosition.name}s
                    </div>
                    {
                      expandedShift ?
                        <Icon name="chevron-up" size="16" />
                        :
                        null
                    }

                  </div>



                  {
                    expandedShift ?
                      <ul className="p-0 m-0 list-none">
                        {shiftPosition.staffIds.map((staffId) => {
                          return <li className={`px-2 py-1 ${colorClassName}__assignee ${isScheduleStatusDraft ? 'assignee-draft' : ''}`} key={staffId.shift.id} onClick={() => openAssignShiftModal(staffId.shift)}>{staffId.name ? staffId.name : 'Unassigned'}</li>
                        })}
                      </ul> :
                      null
                  }

                </li>

              )
            })}
          </ul>
        </Box>
      </div>
    </div>
  );
};
export default ShiftCard;
