import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Assign } from "utility-types";

import { apiErrorHandler, generalErrorMessageDisplay } from "controllers/core";
import {
  EmployeeDetailsAddressInfo,
  EmployeeDetailsBasicInfo,
  EmployeeDetailsEmergencyContactInfo,
  EmployeeDetails as IEmployeeDetails,
  updateEmployeeAddress,
  updateEmployeeBasicInfo,
  updateEmployeeEmail,
  updateEmployeeEmergencyContactInfo,
  updateEmployeePhoneNumber,
} from "controllers/team";

import HorizontalTable, {
  ColumnInstruction,
  UpdateInstruction,
  UpdateState,
} from "components/Table/HorizontalTable";
import SampleHoriTable from "components/Table/HorizontalTable/example";

import { RootState } from "model/store";

interface PersonalInfoProps {
  teamTableData: IEmployeeDetails;
  refetch: () => void;
  hideExtraButton: boolean;
}

const PersonalInfo = (props: PersonalInfoProps) => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const [basicInfoEditing, setBasicInfoEditing] = React.useState(false);

  const onBasicInfoEditStart = useCallback(
    (v: boolean) => {
      setBasicInfoEditing(v);
    },
    [setBasicInfoEditing]
  );

  const [addressInfoEditing, setAddressInfoEditing] = React.useState(false);

  const onAddressInfoEditStart = useCallback(
    (v: boolean) => {
      setAddressInfoEditing(v);
    },
    [setAddressInfoEditing]
  );

  const [emergencyContactInfoEditing, setEmergencyContactInfoEditing] =
    React.useState(false);

  const onEmergencyContactInfoEditStart = useCallback(
    (v: boolean) => {
      setEmergencyContactInfoEditing(v);
    },
    [setEmergencyContactInfoEditing]
  );

  const basicInfoColumns: ColumnInstruction<
    Assign<EmployeeDetailsBasicInfo, { uniqueId: string }>
  >[] = [
      { type: "data", header: "First Name", attribute: "firstName" },
      { type: "data", header: "Last Name", attribute: "lastName" },
      { type: "data", header: "Email", attribute: "email" },
      { type: "data", header: "Phone", attribute: "phone" },
      { type: "data", header: "Date Of Birth", attribute: "formattedDOB" },
    ];

  const basicInfoData = useMemo(() => {
    return [
      { ...props.teamTableData.basicInfo, uniqueId: props.teamTableData.uid },
    ];
  }, [props.teamTableData.basicInfo]);

  const addressColumns: ColumnInstruction<
    Assign<EmployeeDetailsAddressInfo, { uniqueId: string }>
  >[] = [
      { type: "data", header: "Street Address", attribute: "streetAddress" },
      { type: "data", header: "Unit/Suite", attribute: "unit" },
      { type: "data", header: "City", attribute: "city" },
      { type: "data", header: "Postal Code/Zip", attribute: "zipCode" },
      { type: "data", header: "Province/State", attribute: "state" },
      { type: "data", header: "Country", attribute: "country" },
    ];

  const addressData = useMemo(() => {
    return [
      { ...props.teamTableData.addressInfo, uniqueId: props.teamTableData.uid },
    ];
  }, [props.teamTableData.addressInfo]);

  const emergencyContactColumns: ColumnInstruction<
    Assign<EmployeeDetailsEmergencyContactInfo, { uniqueId: string }>
  >[] = [
      { type: "data", header: "Name", attribute: "name" },
      { type: "data", header: "Relation", attribute: "relation" },
      { type: "data", header: "Primary Phone", attribute: "phone" },
    ];

  const emergencyContactData = useMemo(() => {
    return [
      {
        ...props.teamTableData.emergencyContactInfo,
        uniqueId: props.teamTableData.uid,
      },
    ];
  }, [props.teamTableData.emergencyContactInfo]);

  const queryClient = useQueryClient();

  const invalidateDetailsRequest = useCallback(() => {
    queryClient.invalidateQueries([
      "employeeDetails",
      business?.id,
      props.teamTableData.uid,
    ]);
  }, [queryClient, business?.id, props.teamTableData.uid]);

  const saveBasicInfoMutation = useMutation(
    (data: {
      uid: string;
      businessId: string;
      firstName: string;
      lastName: string;
      dob: string;
    }) => {
      return updateEmployeeBasicInfo(
        data.businessId,
        data.uid,
        data.firstName,
        data.lastName,
        data.dob
      );
    },
    {
      onSuccess: invalidateDetailsRequest,
      onError: apiErrorHandler,
    }
  );

  const saveEmergencyContactInfoMutation = useMutation(
    (data: {
      uid: string;
      businessId: string;
      name: string;
      phone: string;
      relationship: string;
    }) => {
      return updateEmployeeEmergencyContactInfo(
        data.businessId,
        data.uid,
        data.name,
        data.phone,
        data.relationship
      );
    },
    {
      onSuccess: invalidateDetailsRequest,
      onError: apiErrorHandler,
    }
  );

  const saveAddressInfoMutation = useMutation(
    (data: {
      uid: string;
      businessId: string;
      address: string | null;
      unit: string | null;
      city: string | null;
      zip: string | null;
      state: string | null;
      country: string | null;
    }) => {
      return updateEmployeeAddress(
        data.businessId,
        data.uid,
        data.address,
        data.unit,
        data.city,
        data.state,
        data.zip,
        data.country
      );
    },
    {
      onSuccess: invalidateDetailsRequest,
      onError: apiErrorHandler,
    }
  );

  const editPhoneNumberMutation = useMutation(
    (data: { uid: string; businessId: string; phone: string | null }) => {
      return updateEmployeePhoneNumber(data.businessId, data.uid, data.phone);
    },
    {
      onSuccess: invalidateDetailsRequest,
      onError: apiErrorHandler,
    }
  );

  const editEmailMutation = useMutation(
    (data: { uid: string; businessId: string; email: string | null }) => {
      return updateEmployeeEmail(data.businessId, data.uid, data.email);
    },
    {
      onSuccess: invalidateDetailsRequest,
      onError: apiErrorHandler,
    }
  );

  const onBasicInfoSaveHandler = useCallback(
    async (updateState: UpdateState) => {
      if (!business?.id || !props.teamTableData.uid) {
        return;
      }
      const incomingUpdates = updateState[props.teamTableData.uid] ?? {};
      if (!_.keys(incomingUpdates).length) {
        return;
      }

      const updates = _.values(incomingUpdates);

      const basicUpdates = updates.filter((u) =>
        ["firstName", "lastName", "dob"].includes(u.key)
      );
      const userId = props.teamTableData.uid;
      const businessId = business.id;
      if (basicUpdates.length) {
        const updatedFirstName =
          (updates.find((update) => update.key === "firstName")
            ?.newValue as string) ?? props.teamTableData.basicInfo.firstName;
        const updatedLastName =
          (updates.find((update) => update.key === "lastName")
            ?.newValue as string) ?? props.teamTableData.basicInfo.lastName;
        const updatedDOB =
          (updates.find((update) => update.key === "formattedDOB")
            ?.newValue as string) ?? props.teamTableData.basicInfo.formattedDOB;
        await saveBasicInfoMutation.mutateAsync({
          uid: userId,
          businessId,
          firstName: updatedFirstName,
          lastName: updatedLastName,
          dob: updatedDOB,
        });
      }

      const hasPhoneUpdate = updates.find((update) => update.key === "phone");
      const hasEmailUpdate = updates.find((update) => update.key === "email");
      let preemptiveNonEmptyEmailUpdate = false;
      if (hasPhoneUpdate) {
        const newPhone = hasPhoneUpdate?.newValue
          ? (hasPhoneUpdate?.newValue as string)
          : null;
        if (
          !newPhone &&
          !props.teamTableData.basicInfo.email &&
          !hasEmailUpdate?.newValue
        ) {
          generalErrorMessageDisplay(
            "The employee must have either a phone number or an email address"
          );
          return;
        } else if (
          !newPhone &&
          !props.teamTableData.basicInfo.email &&
          hasEmailUpdate?.newValue
        ) {
          await editEmailMutation.mutateAsync({
            uid: userId,
            businessId,
            email: hasEmailUpdate?.newValue as string,
          });
          preemptiveNonEmptyEmailUpdate = true;
        }
        await editPhoneNumberMutation.mutateAsync({
          uid: userId,
          businessId,
          phone: newPhone,
        });
      }

      if (hasEmailUpdate && !preemptiveNonEmptyEmailUpdate) {
        const newEmail = hasEmailUpdate?.newValue
          ? (hasEmailUpdate?.newValue as string)
          : null;
        if (
          !newEmail &&
          !props.teamTableData.basicInfo.phone &&
          !hasPhoneUpdate?.newValue
        ) {
          generalErrorMessageDisplay(
            "The employee must have either a phone number or an email address"
          );
          return;
        }
        await editEmailMutation.mutateAsync({
          uid: userId,
          businessId,
          email: newEmail,
        });
      }
    },
    [saveBasicInfoMutation, props.teamTableData.basicInfo, business?.id]
  );

  const onAddressInfoSaveHandler = useCallback(
    async (updateState: UpdateState) => {
      if (!business?.id || !props.teamTableData.uid) {
        return;
      }
      const incomingUpdates = updateState[props.teamTableData.uid] ?? {};
      if (!_.keys(incomingUpdates).length) {
        return;
      }

      const updates = _.values(incomingUpdates);
      const userId = props.teamTableData.uid;
      const businessId = business.id;

      const updatedAddress =
        (updates.find((update) => update.key === "streetAddress")
          ?.newValue as string) ??
        props.teamTableData.addressInfo.streetAddress;
      const updatedUnit =
        (updates.find((update) => update.key === "unit")?.newValue as string) ??
        props.teamTableData.addressInfo.unit;
      const updatedCity =
        (updates.find((update) => update.key === "city")?.newValue as string) ??
        props.teamTableData.addressInfo.city;
      const updatedState =
        (updates.find((update) => update.key === "state")
          ?.newValue as string) ?? props.teamTableData.addressInfo.state;
      const updatedZip =
        (updates.find((update) => update.key === "zipCode")
          ?.newValue as string) ?? props.teamTableData.addressInfo.zipCode;
      const updatedCountry =
        (updates.find((update) => update.key === "country")
          ?.newValue as string) ?? props.teamTableData.addressInfo.country;

      await saveAddressInfoMutation.mutateAsync({
        uid: userId,
        businessId,
        address: updatedAddress,
        unit: updatedUnit,
        city: updatedCity,
        state: updatedState,
        zip: updatedZip,
        country: updatedCountry,
      });
    },
    [saveAddressInfoMutation, props.teamTableData.addressInfo, business?.id]
  );

  const onEmergencyContactInfoSaveHandler = useCallback(
    async (updateState: UpdateState) => {
      if (!business?.id || !props.teamTableData.uid) {
        return;
      }
      const incomingUpdates = updateState[props.teamTableData.uid] ?? {};
      if (!_.keys(incomingUpdates).length) {
        return;
      }

      const updates = _.values(incomingUpdates);
      const userId = props.teamTableData.uid;
      const businessId = business.id;

      const updatedName =
        (updates.find((update) => update.key === "name")?.newValue as string) ??
        props.teamTableData.emergencyContactInfo.name;

      const updatedPhone =
        (updates.find((update) => update.key === "phone")
          ?.newValue as string) ??
        props.teamTableData.emergencyContactInfo.phone;

      const updatedRelationship =
        (updates.find((update) => update.key === "relation")
          ?.newValue as string) ??
        props.teamTableData.emergencyContactInfo.relation;

      await saveEmergencyContactInfoMutation.mutateAsync({
        uid: userId,
        businessId,
        name: updatedName,
        phone: updatedPhone,
        relationship: updatedRelationship,
      });
    },
    [
      saveEmergencyContactInfoMutation,
      props.teamTableData.emergencyContactInfo,
      business?.id,
    ]
  );

  return (
    <>
      <div className="mb-10">
        <HorizontalTable
          saveResults={onBasicInfoSaveHandler}
          title="Basic Info"
          isEditing={basicInfoEditing}
          isVertical
          columns={basicInfoColumns}
          instructions={{}}
          data={basicInfoData}
          setEditing={onBasicInfoEditStart}
          loading={
            saveBasicInfoMutation.isLoading ||
            editPhoneNumberMutation.isLoading ||
            editEmailMutation.isLoading
          }
          hideEdit={props.hideExtraButton}
        />
      </div>

      <div className="mb-10">
        <HorizontalTable
          title="Address"
          isEditing={addressInfoEditing}
          setEditing={onAddressInfoEditStart}
          columns={addressColumns}
          data={addressData}
          instructions={{}}
          isVertical
          loading={saveAddressInfoMutation.isLoading}
          saveResults={onAddressInfoSaveHandler}
          hideEdit={props.hideExtraButton}
        />
      </div>
      <div className="mb-10">
        <HorizontalTable
          title="Emergency Contact"
          isEditing={emergencyContactInfoEditing}
          columns={emergencyContactColumns}
          data={emergencyContactData}
          setEditing={onEmergencyContactInfoEditStart}
          saveResults={onEmergencyContactInfoSaveHandler}
          loading={saveEmergencyContactInfoMutation.isLoading}
          instructions={{}}
          isVertical
          hideEdit={props.hideExtraButton}
        />
      </div>
    </>
  );
};

export default PersonalInfo;
