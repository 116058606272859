import { UpdateState } from "components/Table/HorizontalTable";
import { UpdatePrepItemDTO, usePrepItemsInfo, useUpdatePrepItem } from "controllers/inventoryItemInfo";
import { RootState } from "model/store";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { PrepItem, columns, instructions } from "./instructions";

const deleteItemsTODO = (args: string[]) => {
  console.log("I would like to delete ", args);
}
const usePrepItems = () => {
  const businessId: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.id;
  const query = usePrepItemsInfo(businessId, true);
  const updateItem = useUpdatePrepItem(businessId);
  const data: PrepItem[] = useMemo(() => {
    if (!query.data?.length) return [];
    return query.data.map((item) => ({
      uniqueId: item.id,
      name: item.name,
      primaryGroup: item.primaryGroup?.id ?? null,
      secondaryGroup: item.secondaryGroup?.id ?? null,
      yield: item.yield ?? 1,
      productionUnitName: item.productionUnitName,
      defaultCost: item.defaultCost,
      shelfLifeHours: item.shelfLifeHours,
      shelfLifeUnits: item.shelfLifeUnits,
      recipe: item.recipe,
      ingredients: item.ingredients,
    } as PrepItem))
  }, [query.data])
  const [isEditing, setEditing] = useState(false);
  const saveItems = useCallback((updates: UpdateState) => {
    const promises = Object.keys(updates).map((itemId) => {
      const update = updates[itemId]
      const updatedItem = Object.keys(update).reduce((curr, key) => {
        const nv = update[key].newValue;
        switch (key) {
          case "name": curr.name = nv as string; break;
          case "primaryGroup": curr.primaryGroupId = nv as string; break;
          case "secondaryGroup": curr.secondaryGroupId = nv as string; break;
          case "yield": curr.yield = nv as number; break;
          case "shelfLifeHours": curr.shelfLifeHours = nv as number; break;
          case "shelfLifeUnits": curr.shelfLifeUnits = nv as string; break;
          default:
            console.warn("Discarding key", key);
        }
        return curr;
      }, {} as UpdatePrepItemDTO);
      return updateItem.mutateAsync({ itemId, updatedItem })
    });
    return Promise.all(promises);
  }, [updateItem])

  return {
    data,
    saveItems,
    deleteItems: deleteItemsTODO,
    columns,
    instructions,
    isEditing,
    setEditing,
  };
};
export default usePrepItems;
