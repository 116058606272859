import { GiftCardTransactions } from '../useGiftCards';

export const getLifetimeSpend = (transactions: GiftCardTransactions[]) => {
  const allSpendEvents = transactions.reduce((accumulator: number, transaction: GiftCardTransactions) => {
    if (transaction.transactionType === 'spend') {
      return accumulator + transaction.amount;
    } else {
      return accumulator + 0;
    }
  }, 0);

  return (allSpendEvents * 0.01).toFixed(2); //convert from cents to dollar
};

export const getLifetimeLoad = (transactions: GiftCardTransactions[]) => {
  const allLoadEvents = transactions.reduce((accumulator: number, transaction: GiftCardTransactions) => {
    if (transaction.transactionType !== 'spend') {
      return accumulator + transaction.amount;
    } else {
      return accumulator + 0;
    }
  }, 0);

  return (allLoadEvents * 0.01).toFixed(2);
};
