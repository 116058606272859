import { useState } from "react";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";

export type ItemProps = {
  uniqueId: string;
  date: string;
  countType: string;
  countMethod: string;
  schedule: string;
};

const itemsData: ItemProps[] = [
  {
    uniqueId: "1",
    date: "09/22/2022",
    countType: "Back Storage",
    countMethod: "Tablet",
    schedule: "Every Monday",
  },
  {
    uniqueId: "2",
    date: "09/22/2022",
    countType: "Back Storage",
    countMethod: "Tablet",
    schedule: "Every Monday",
  },
];
const columns: ColumnInstruction<ItemProps>[] = [
  { type: "data", header: "Date", attribute: "date" },
  { type: "data", header: "Count Type", attribute: "countType" },
  { type: "data", header: "Count Method", attribute: "countMethod" },
  { type: "data", header: "Schedule", attribute: "schedule" },
];

const instructions: { [x: string]: RenderInstruction<ItemProps> } = {};
const useItems = () => {
  const [isEditing, setEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const noOp = () =>
    Promise.resolve(() => {
      setShowModal(true);
    });
  const logIt = (args: string[]) => console.log(args);

  return {
    itemsData,
    noOp,
    logIt,
    columns,
    instructions,
    isEditing,
    setEditing,
    showModal,
    setShowModal,
  };
};
export default useItems;
