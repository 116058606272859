import * as Sentry from "@sentry/react";
import { UseQueryResult, useQueries, useQuery } from "@tanstack/react-query";
import _ from "lodash";
import moment from "moment-timezone";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { getSummaryForDate } from "controllers/dailyLogs";
import { getWorkEventsForDateRange } from "controllers/payroll";
import {
  extractAPIWorkEventDurationInHours,
  extractShiftDurationInHours,
  roundNumberToTwoDecimals,
} from "controllers/reporting";
import { findDepartmentSchedulesIdsForWeekRange } from "controllers/schedule";

import { RootState } from "model/store";

interface IUseSchedulingStats {
  weekRange: Date[];
}

interface IDailyActual {
  day: string;
  amount: number;
  foh: number;
  boh: number;
}

interface IDailyActualPercentage {
  day: string;
  amount: number;
  foh: number | null;
  boh: number | null;
}

interface IGroupedWeeklyRoleData {
  roleId: string;
  roleTitle: string;
  weeklyTotalActualAmount: number;
  weeklyMeanActualPercentage: number;
  weeklyTotalProjectedAmount: number;
  weeklyMeanProjectedPercentage: number;
  daysData: {
    [dayName: string]: {
      actuals: {
        amount: number;
        percentage: number | null;
      };
      projections: {
        amount: number;
        percentage: number | null;
      };
    };
  };
}

interface IWeeklyStatsGroupedByRoles {
  foh: IGroupedWeeklyRoleData[];
  boh: IGroupedWeeklyRoleData[];
}

interface IDailyStatsGroupedByRoleAndDay {
  day: string;
  bohRoles: {
    roleId: string;
    roleTitle: string;
    actuals: {
      amount: number;
      percentage: number | null;
    };
    projections: {
      amount: number;
      percentage: number | null;
    };
  }[];
  fohRoles: {
    roleId: string;
    roleTitle: string;
    actuals: {
      amount: number;
      percentage: number | null;
    };
    projections: {
      amount: number;
      percentage: number | null;
    };
  }[];
}

interface IDailyActualGroupedByRoles {
  day: string;
  amount: number;
  bohRoles: {
    roleId: string;
    roleTitle: string;
    amount: number;
    percentage: number;
  }[];
  fohRoles: {
    roleId: string;
    roleTitle: string;
    amount: number;
    percentage: number;
  }[];
}

export const splitDateNum = (num: number) => {
  const day = num % 100;
  num -= day;
  num /= 100;
  const month = num % 100;
  num -= month;
  num /= 100;
  const year = num;
  return {
    day,
    month,
    year,
  };
};

export const numberToStartOfDay = (num: number, tz: string) => {
  const { day, month, year } = splitDateNum(num);
  return moment()
    .tz(tz)
    .year(year)
    .month(month - 1)
    .date(day)
    .startOf("day")
    .add(4, "hours")
    .toDate();
};

export interface DailyActualSales {
  amount: number;
  dayName: string;
  tbd: boolean;
}

export interface ICostScheduleData {
  dailyActuals: IDailyActual[];
  dailyActualsAsPercentagesOfActualSales?: IDailyActualPercentage[];
  dailyProjectedSales?: { [dayName: string]: DailyProjectedSales };
  dailyActualSales?: { [dayName: string]: DailyActualSales };
  bohDraftScheduleId: string | null;
  fohDraftScheduleId: string | null;
  groupedDailyBOHProjections: {
    [dayName: string]: {
      dayNameString: string;
      dayIndex: number;
      amount: number;
      percentage: number | null;
    };
  };

  groupedDailyFOHProjections: {
    [dayName: string]: {
      dayNameString: string;
      dayIndex: number;
      amount: number;
      percentage: number | null;
    };
  };

  fohTargetLaborCostAsPercentage?: {
    [dayName: string]: TargetLaborCostAsPercentage;
  };
  bohTargetLaborCostAsPercentage?: {
    [dayName: string]: TargetLaborCostAsPercentage;
  };
  fohTargetLaborCostAsDollars?: {
    [x: string]: {
      amount: number;
      percentage: number | null;
      dayNameString: string;
      dayIndex: number;
    };
  };
  bohTargetLaborCostAsDollars?: {
    [x: string]: {
      amount: number;
      percentage: number | null;
      dayNameString: string;
      dayIndex: number;
    };
  };
  avgBohTargetLaborCostAsPercentage?: number;
  avgFohTargetLaborCostAsPercentage?: number;
  avgBohTargetLaborCostAsDollars?: number;
  avgFohTargetLaborCostAsDollars?: number;
  dailyActualsGroupedByRoles?: IDailyActualGroupedByRoles[];
  statsGroupedByRoles: IDailyStatsGroupedByRoleAndDay[];
  weeklyStatsGroupedByRoles: IWeeklyStatsGroupedByRoles;

  actualScheduleCost: {
    total: number;
    foh: number;
    boh: number;
  };

  totalWeeklyProjectedSales: number;
  actualSalesDataQueries: UseQueryResult<
    {
      fohCost: number;
      bohCost: number;
      totalCost: number;
      targetAll: number;
      targetFoh: number;
      targetBoh: number;
      actualSales: number;
      projectedSales: number;
      projectionId: string | null;
      numericCalendarDate: number;
    },
    unknown
  >[];
  refetchActualSalesData: () => void;
  actualSalesDataLoading: boolean;
}

export const useSchedulingStatsData = (
  props: IUseSchedulingStats
): ICostScheduleData => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const draftSchedules: TangoSchedule[] = useSelector(
    (state: RootState) => state.draftSchedules
  );

  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const draftsWithDepartmentForAWeek = useMemo(() => {
    if (business) {
      return findDepartmentSchedulesIdsForWeekRange(
        draftSchedules,
        props.weekRange,
        business?.timezone ?? "America/Toronto"
      );
    }
    return [];
  }, [business, draftSchedules, props.weekRange]);

  const numericWeekEdges = useMemo(() => {
    const startDate = moment(props.weekRange[0]);
    const endDate = moment(props.weekRange[props.weekRange.length - 1]);

    const calendarNumericStartDate =
      startDate.date() +
      100 * (startDate.month() + 1) +
      10000 * startDate.year();

    const calendarNumericEndDate =
      endDate.date() + 100 * (endDate.month() + 1) + 10000 * endDate.year();
    return {
      calendarNumericStartDate,
      calendarNumericEndDate,
    };
  }, [props.weekRange]);

  const workEventsForTheWeekQuery = useQuery(
    [
      "workEvents",
      business.id,
      numericWeekEdges.calendarNumericStartDate,
      numericWeekEdges.calendarNumericEndDate,
    ],
    () =>
      getWorkEventsForDateRange(
        business.id,
        numericWeekEdges.calendarNumericStartDate,
        numericWeekEdges.calendarNumericEndDate
      ),
    {
      enabled:
        !!business.id &&
        !!numericWeekEdges.calendarNumericStartDate &&
        !!numericWeekEdges.calendarNumericEndDate,
      onError: (err) => {
        Sentry.captureException(err);
        console.error(err);
      },
    }
  );

  const workEvents: WorkEvent[] = useMemo(
    () => workEventsForTheWeekQuery.data ?? [],
    [workEventsForTheWeekQuery.data]
  );

  const bohScheduleForAWeekRange = useMemo(() => {
    const bohRef = draftsWithDepartmentForAWeek.find(
      (d) => d.departmentId === "boh"
    );
    if (bohRef) {
      return draftSchedules.find((schedule) => schedule.id === bohRef.draftId);
    }
  }, [draftsWithDepartmentForAWeek, draftSchedules]);

  const fohScheduleForAWeekRange = useMemo(() => {
    const fohRef = draftsWithDepartmentForAWeek.find(
      (d) => d.departmentId === "foh"
    );
    if (fohRef) {
      return draftSchedules.find((schedule) => schedule.id === fohRef.draftId);
    }
  }, [draftsWithDepartmentForAWeek, draftSchedules]);

  const mergedScheduleForAWeekRange = useMemo(() => {
    if (bohScheduleForAWeekRange && fohScheduleForAWeekRange) {
      const bohShifts = bohScheduleForAWeekRange.shifts.map((sh) => ({
        ...sh,
        draftScheduleId: bohScheduleForAWeekRange.id,
      }));
      const fohShifts = fohScheduleForAWeekRange.shifts.map((sh) => ({
        ...sh,
        draftScheduleId: fohScheduleForAWeekRange.id,
      }));
      const mergedShifts = [...bohShifts, ...fohShifts];
      const mergedSchedule: TangoSchedule = {
        ...fohScheduleForAWeekRange,
        shifts: mergedShifts,
        mergedSchedules: [
          fohScheduleForAWeekRange.id,
          bohScheduleForAWeekRange.id,
        ],
      };
      return mergedSchedule;
    }
    return undefined;
  }, [bohScheduleForAWeekRange, fohScheduleForAWeekRange]);

  const currentScheduleStartDate = useMemo(() => {
    if (mergedScheduleForAWeekRange) {
      return moment(mergedScheduleForAWeekRange.startDate?.toDate());
    }
    return null;
  }, [mergedScheduleForAWeekRange]);

  const currentScheduleEndDate = useMemo(() => {
    if (mergedScheduleForAWeekRange) {
      return moment(mergedScheduleForAWeekRange.endDate?.toDate());
    }
    return null;
  }, [mergedScheduleForAWeekRange]);
  const workEventsInsideCurrentSchedule = useMemo(() => {
    if (!currentScheduleEndDate || !currentScheduleStartDate) return [];

    return workEvents.filter((workEvent) => {
      const workEventStartDate = moment(workEvent?.clockedIn?._seconds * 1000);
      return (
        workEvent.clockedOut &&
        workEventStartDate.isSameOrAfter(currentScheduleStartDate) &&
        workEventStartDate.isSameOrBefore(currentScheduleEndDate)
      );
    });
  }, [workEvents, currentScheduleStartDate, currentScheduleEndDate]);

  const groupedDailyWorkEvents = useMemo(() => {
    return _.groupBy(workEventsInsideCurrentSchedule, (we) => {
      const weDateWithTimezone = moment(we?.clockedIn?._seconds * 1000);
      weDateWithTimezone.tz(business.timezone, true);
      return weDateWithTimezone.day() - 1;
    });
  }, [workEventsInsideCurrentSchedule]);

  const actualSalesDataQueries = useQueries({
    queries: props.weekRange.map((day) => {
      const momentDate = moment(day);
      const calendarNumericDate =
        momentDate.date() +
        100 * (momentDate.month() + 1) +
        10000 * momentDate.year();
      return {
        queryKey: ["actualSalesData", business.id, calendarNumericDate],
        queryFn: () => getSummaryForDate(business.id, calendarNumericDate),
        enabled: !!business.id,
        refetchOnWindowFocus: false,
        onError: (err: any) => {
          Sentry.captureException(err);
          console.error(err);
        },
      };
    }),
  });

  const actualSalesDataLoading = useMemo(() => {
    return (
      actualSalesDataQueries.some((q) => q.isLoading || q.isFetching) ||
      workEventsForTheWeekQuery.isLoading ||
      workEventsForTheWeekQuery.isFetching
    );
  }, [actualSalesDataQueries, workEventsForTheWeekQuery]);

  const refetchActualSalesData = useCallback(() => {
    actualSalesDataQueries.forEach((q) => q.refetch());
    workEventsForTheWeekQuery.refetch();
  }, [actualSalesDataQueries, workEventsForTheWeekQuery]);

  const dailyActualSales: { [dayName: string]: DailyActualSales } =
    useMemo(() => {
      const actualSalesQueriesData = actualSalesDataQueries.map((q) => q.data);
      const businessTimezone = business.timezone ?? "America/Toronto";
      const result: { [dayName: string]: DailyActualSales } = {};

      moment.weekdays().forEach((dayName) => {
        result[dayName] = {
          amount: 0,
          tbd: true,
          dayName,
        };
      });
      actualSalesQueriesData.forEach((data) => {
        if (data?.numericCalendarDate) {
          const dateFromNumericDate = numberToStartOfDay(
            data.numericCalendarDate,
            businessTimezone
          );
          const dayName = moment(dateFromNumericDate).format("dddd");
          if (result[dayName]) {
            result[dayName].amount = Math.round(data.actualSales);
            result[dayName].tbd = false;
          }
        }
      });

      return result;
    }, [actualSalesDataQueries, business]);

  const actualCostReducer = useCallback(
    (
      accumulator: {
        total: number;
        foh: number;
        boh: number;
      },
      item: WorkEvent
    ) => {
      if (
        item?.staffId &&
        item?.clockedOut &&
        item?.clockedIn &&
        item.payRate
      ) {
        const positionId = item.position;
        const positionData = businessSettings?.jobFunctions?.[positionId];
        if (!positionData) return accumulator;
        const department = positionData.departmentId;
        const result = { ...accumulator };
        const amount = extractAPIWorkEventDurationInHours(item) * item.payRate;
        result.total += amount;
        if (department == "boh") {
          result.boh += amount;
        }
        if (department == "foh") {
          result.foh += amount;
        }
        return result;
      }
      return accumulator;
    },
    []
  );

  const actualScheduleCost = useMemo(() => {
    return workEventsInsideCurrentSchedule.reduce(actualCostReducer, {
      total: 0,
      foh: 0,
      boh: 0,
    });
  }, [actualCostReducer, workEventsInsideCurrentSchedule]);

  const weekDays = useMemo(() => moment.weekdays(true), []);

  const dailyActuals: IDailyActual[] = useMemo(() => {
    return weekDays.map((weekday, index) => {
      const weForTheWeekDay = groupedDailyWorkEvents?.[index];
      if (weForTheWeekDay?.length) {
        const weekdayAmount = weForTheWeekDay.reduce(actualCostReducer, {
          total: 0,
          foh: 0,
          boh: 0,
        });
        return {
          day: weekday,
          amount: weekdayAmount.total,
          foh: weekdayAmount.foh,
          boh: weekdayAmount.boh,
        };
      }
      return { day: weekday, amount: 0, foh: 0, boh: 0 };
    });
  }, [groupedDailyWorkEvents, actualCostReducer, weekDays]);

  const dailyProjectedSales = useMemo(() => {
    const dailyProjectedSalesSkeleton: {
      [dayName: string]: DailyProjectedSales;
    } = {};

    moment.weekdays(true).forEach((dayName, index) => {
      dailyProjectedSalesSkeleton[dayName] = {
        dayNameString: dayName,
        dayIndex: index,
        projectedAmount: 0,
      };
    });

    const fohScheduleProjectedSales =
      fohScheduleForAWeekRange?.dailyProjectedSales ?? {};

    _.keys(fohScheduleProjectedSales).forEach((dayName) => {
      const dayProjectedSales = fohScheduleProjectedSales[dayName];
      if (dayProjectedSales && dayProjectedSales.projectedAmount) {
        dailyProjectedSalesSkeleton[dayName] = {
          ...dailyProjectedSalesSkeleton[dayName],
          projectedAmount: dayProjectedSales.projectedAmount,
        };
      }
    });
    return dailyProjectedSalesSkeleton;
  }, [fohScheduleForAWeekRange]);

  const totalWeeklyProjectedSales = useMemo(() => {
    return _.sumBy(_.values(dailyProjectedSales), (day) => day.projectedAmount);
  }, [dailyProjectedSales]);

  const weeklyStatsGroupedByRoles: IWeeklyStatsGroupedByRoles = useMemo(() => {
    if (!bohScheduleForAWeekRange || !fohScheduleForAWeekRange)
      return { boh: [], foh: [] };

    const bohShifts = bohScheduleForAWeekRange.shifts;
    const fohShifts = fohScheduleForAWeekRange.shifts;

    const rolesToIncludeIntoStats = _.uniq([
      ...(workEventsInsideCurrentSchedule ?? []).map((we) => we.position),
      ...(bohShifts ?? []).map((sh) => sh.position),
      ...(fohShifts ?? []).map((sh) => sh.position),
    ]);

    const bohRolesToInclude = rolesToIncludeIntoStats
      .map((r) => {
        return businessSettings?.jobFunctions?.[r] ?? null;
      })
      .filter((x) => !!x && x.departmentId === "boh");

    const fohRolesToInclude = rolesToIncludeIntoStats
      .map((r) => {
        return businessSettings?.jobFunctions?.[r] ?? null;
      })
      .filter((x) => !!x && x.departmentId === "foh");

    const fohData = fohRolesToInclude.map((role) => {
      const roleId = role.id;
      const roleTitle = role.title;
      const daysData: {
        [dayName: string]: {
          actuals: {
            amount: number;
            percentage: number | null;
          };
          projections: {
            amount: number;
            percentage: number | null;
          };
        };
      } = {};
      weekDays.forEach((weekDay, index) => {
        const weForTheWeekDayForTheRole = (
          groupedDailyWorkEvents?.[index] ?? []
        ).filter((w) => w.position === roleId);
        const projectedSalesForTheDay =
          dailyProjectedSales[weekDay]?.projectedAmount;

        const actualAmountForRoleInCents = roundNumberToTwoDecimals(
          weForTheWeekDayForTheRole?.reduce((acc, we) => {
            if (we.payRate) {
              return acc + extractAPIWorkEventDurationInHours(we) * we.payRate;
            }
            return acc;
          }, 0)
        );

        const actualAmountAsPercentageOfProjectedSales = projectedSalesForTheDay
          ? roundNumberToTwoDecimals(
              (actualAmountForRoleInCents * 100) / projectedSalesForTheDay
            )
          : null;

        const shiftsForTheRoleForTheDay = fohShifts.filter((sh) => {
          return (
            sh.position === roleId &&
            moment(sh.startDate.toDate()).startOf("day").weekday() === index
          );
        });

        const projectedCostForTheRole = roundNumberToTwoDecimals(
          shiftsForTheRoleForTheDay.reduce((acc, shift) => {
            if (shift.staffId) {
              const shiftRole = shift.position;
              const shiftStaffMember = fellowStaffMembers.find(
                (staffMember) => staffMember.uid === shift.staffId
              );
              if (!shiftStaffMember) return acc;
              const payRateDataForShiftRole = shiftStaffMember.payRates.find(
                (payRate) => payRate.roleId === shiftRole
              );
              if (!payRateDataForShiftRole) return acc;
              const shiftDuration = extractShiftDurationInHours(shift);
              const totalProjectedPayForTheShift =
                payRateDataForShiftRole.amount * shiftDuration;
              return acc + totalProjectedPayForTheShift;
            }
            return acc;
          }, 0)
        );

        const projectedAmountAsPercentageOfProjectedSales =
          projectedSalesForTheDay
            ? roundNumberToTwoDecimals(
                (projectedCostForTheRole * 100) / projectedSalesForTheDay
              )
            : null;

        daysData[weekDay] = {
          actuals: {
            amount: actualAmountForRoleInCents,
            percentage: actualAmountAsPercentageOfProjectedSales,
          },
          projections: {
            amount: projectedCostForTheRole,
            percentage: projectedAmountAsPercentageOfProjectedSales,
          },
        };
      });

      const weeklyTotalActualAmount = roundNumberToTwoDecimals(
        _.sumBy(_.values(daysData), (d) => d.actuals.amount)
      );

      const weeklyTotalProjectedAmount = roundNumberToTwoDecimals(
        _.sumBy(_.values(daysData), (d) => d.projections.amount)
      );

      const weeklyMeanActualPercentage = roundNumberToTwoDecimals(
        _.meanBy(
          _.values(daysData).filter((d) => d.actuals.percentage),
          (d) => d.actuals.percentage
        )
      );

      const weeklyMeanProjectedPercentage = roundNumberToTwoDecimals(
        _.meanBy(
          _.values(daysData).filter((d) => d.projections.percentage),
          (d) => d.projections.percentage
        )
      );

      const statsForTheRole: IGroupedWeeklyRoleData = {
        roleId,
        roleTitle,
        daysData,
        weeklyTotalActualAmount,
        weeklyTotalProjectedAmount,
        weeklyMeanActualPercentage,
        weeklyMeanProjectedPercentage,
      };

      return statsForTheRole;
    });

    const bohData = bohRolesToInclude.map((role) => {
      const roleId = role.id;
      const roleTitle = role.title;
      const daysData: {
        [dayName: string]: {
          actuals: {
            amount: number;
            percentage: number | null;
          };
          projections: {
            amount: number;
            percentage: number | null;
          };
        };
      } = {};
      weekDays.forEach((weekDay, index) => {
        const weForTheWeekDayForTheRole = (
          groupedDailyWorkEvents?.[index] ?? []
        ).filter((w) => w.position === roleId);
        const projectedSalesForTheDay =
          dailyProjectedSales[weekDay]?.projectedAmount;

        const actualAmountForRoleInCents = roundNumberToTwoDecimals(
          weForTheWeekDayForTheRole?.reduce((acc, we) => {
            if (we.payRate) {
              return acc + extractAPIWorkEventDurationInHours(we) * we.payRate;
            }
            return acc;
          }, 0)
        );

        const actualAmountAsPercentageOfProjectedSales = projectedSalesForTheDay
          ? roundNumberToTwoDecimals(
              (actualAmountForRoleInCents * 100) / projectedSalesForTheDay
            )
          : null;

        const shiftsForTheRoleForTheDay = bohShifts.filter((sh) => {
          return (
            sh.position === roleId &&
            moment(sh.startDate.toDate()).startOf("day").weekday() === index
          );
        });

        const projectedCostForTheRole = roundNumberToTwoDecimals(
          shiftsForTheRoleForTheDay.reduce((acc, shift) => {
            if (shift.staffId) {
              const shiftRole = shift.position;
              const shiftStaffMember = fellowStaffMembers.find(
                (staffMember) => staffMember.uid === shift.staffId
              );
              if (!shiftStaffMember) return acc;
              const payRateDataForShiftRole = shiftStaffMember.payRates.find(
                (payRate) => payRate.roleId === shiftRole
              );
              if (!payRateDataForShiftRole) return acc;
              const shiftDuration = extractShiftDurationInHours(shift);
              const totalProjectedPayForTheShift =
                payRateDataForShiftRole.amount * shiftDuration;
              return acc + totalProjectedPayForTheShift;
            }
            return acc;
          }, 0)
        );

        const projectedAmountAsPercentageOfProjectedSales =
          projectedSalesForTheDay
            ? roundNumberToTwoDecimals(
                (projectedCostForTheRole * 100) / projectedSalesForTheDay
              )
            : null;

        daysData[weekDay] = {
          actuals: {
            amount: actualAmountForRoleInCents,
            percentage: actualAmountAsPercentageOfProjectedSales,
          },
          projections: {
            amount: projectedCostForTheRole,
            percentage: projectedAmountAsPercentageOfProjectedSales,
          },
        };
      });

      const weeklyTotalActualAmount = roundNumberToTwoDecimals(
        _.sumBy(_.values(daysData), (d) => d.actuals.amount)
      );

      const weeklyTotalProjectedAmount = roundNumberToTwoDecimals(
        _.sumBy(_.values(daysData), (d) => d.projections.amount)
      );

      const weeklyMeanActualPercentage = roundNumberToTwoDecimals(
        _.meanBy(
          _.values(daysData).filter((d) => d.actuals.percentage),
          (d) => d.actuals.percentage
        )
      );

      const weeklyMeanProjectedPercentage = roundNumberToTwoDecimals(
        _.meanBy(
          _.values(daysData).filter((d) => d.projections.percentage),
          (d) => d.projections.percentage
        )
      );

      const statsForTheRole: IGroupedWeeklyRoleData = {
        roleId,
        roleTitle,
        daysData,
        weeklyTotalActualAmount,
        weeklyTotalProjectedAmount,
        weeklyMeanActualPercentage,
        weeklyMeanProjectedPercentage,
      };

      return statsForTheRole;
    });

    return {
      foh: fohData,
      boh: bohData,
    };
  }, [
    workEventsInsideCurrentSchedule,
    businessSettings,
    groupedDailyWorkEvents,
    weekDays,
    fohScheduleForAWeekRange,
    bohScheduleForAWeekRange,
    dailyProjectedSales,
    fellowStaffMembers,
  ]);

  const statsGroupedByRoles: IDailyStatsGroupedByRoleAndDay[] = useMemo(() => {
    if (!bohScheduleForAWeekRange || !fohScheduleForAWeekRange) return [];
    return weekDays.map((weekday, index): IDailyStatsGroupedByRoleAndDay => {
      const weForTheWeekDay = groupedDailyWorkEvents?.[index] ?? [];
      const dayName = weekday;

      const bohShifts = bohScheduleForAWeekRange.shifts;
      const fohShifts = fohScheduleForAWeekRange.shifts;

      const rolesToIncludeIntoStats = _.uniq([
        ...(weForTheWeekDay ?? []).map((we) => we.position),
        ...(bohShifts ?? []).map((sh) => sh.position),
        ...(fohShifts ?? []).map((sh) => sh.position),
      ]);

      const bohRolesToInclude = rolesToIncludeIntoStats
        .map((r) => {
          return businessSettings?.jobFunctions?.[r] ?? null;
        })
        .filter((x) => !!x && x.departmentId === "boh");

      const fohRolesToInclude = rolesToIncludeIntoStats
        .map((r) => {
          return businessSettings?.jobFunctions?.[r] ?? null;
        })
        .filter((x) => !!x && x.departmentId === "foh");

      // {
      //   roleId: string;
      //   roleTitle: string;
      //   actuals: {
      //     amount: number;
      //     percentage: number | null;
      //   };
      //   projections: {
      //     amount: number;
      //     percentage: number | null;
      //   };
      // }[];

      const projectedSalesForTheDay =
        dailyProjectedSales[dayName]?.projectedAmount;

      const bohResult = bohRolesToInclude.map((roleData) => {
        const workEventsForTheRole = weForTheWeekDay.filter(
          (we) => we.position === roleData.id
        );

        const actualAmountForRoleInCents = roundNumberToTwoDecimals(
          workEventsForTheRole?.reduce((acc, we) => {
            if (we.payRate) {
              return acc + extractAPIWorkEventDurationInHours(we) * we.payRate;
            }
            return acc;
          }, 0)
        );

        const actualAmountAsPercentageOfProjectedSales = projectedSalesForTheDay
          ? roundNumberToTwoDecimals(
              (actualAmountForRoleInCents * 100) / projectedSalesForTheDay
            )
          : null;

        const shiftsForTheRole = bohShifts.filter(
          (sh) => sh.position === roleData.id
        );

        const projectedCostForTheRole = roundNumberToTwoDecimals(
          shiftsForTheRole.reduce((acc, shift) => {
            if (shift.staffId) {
              const shiftRole = shift.position;
              const shiftStaffMember = fellowStaffMembers.find(
                (staffMember) => staffMember.uid === shift.staffId
              );
              if (!shiftStaffMember) return acc;
              const payRateDataForShiftRole = shiftStaffMember.payRates.find(
                (payRate) => payRate.roleId === shiftRole
              );
              if (!payRateDataForShiftRole) return acc;
              const shiftDuration = extractShiftDurationInHours(shift);
              const totalProjectedPayForTheShift =
                payRateDataForShiftRole.amount * shiftDuration;
              return acc + totalProjectedPayForTheShift;
            }
            return acc;
          }, 0)
        );

        const projectedAmountAsPercentageOfProjectedSales =
          projectedSalesForTheDay
            ? roundNumberToTwoDecimals(
                (projectedCostForTheRole * 100) / projectedSalesForTheDay
              )
            : null;

        return {
          roleId: roleData.id,
          roleTitle: roleData.title,
          actuals: {
            amount: actualAmountForRoleInCents,
            percentage: actualAmountAsPercentageOfProjectedSales,
          },
          projections: {
            amount: projectedCostForTheRole,
            percentage: projectedAmountAsPercentageOfProjectedSales,
          },
        };
      });

      const fohResult = fohRolesToInclude.map((roleData) => {
        const workEventsForTheRole = weForTheWeekDay.filter(
          (we) => we.position === roleData.id
        );

        const actualAmountForRoleInCents = roundNumberToTwoDecimals(
          workEventsForTheRole?.reduce((acc, we) => {
            if (we.payRate) {
              return acc + extractAPIWorkEventDurationInHours(we) * we.payRate;
            }
            return acc;
          }, 0)
        );

        const actualAmountAsPercentageOfProjectedSales = projectedSalesForTheDay
          ? roundNumberToTwoDecimals(
              (actualAmountForRoleInCents * 100) / projectedSalesForTheDay
            )
          : null;

        const shiftsForTheRole = fohShifts.filter(
          (sh) => sh.position === roleData.id
        );

        const projectedCostForTheRole = roundNumberToTwoDecimals(
          shiftsForTheRole.reduce((acc, shift) => {
            if (shift.staffId) {
              const shiftRole = shift.position;
              const shiftStaffMember = fellowStaffMembers.find(
                (staffMember) => staffMember.uid === shift.staffId
              );
              if (!shiftStaffMember) return acc;
              const payRateDataForShiftRole = shiftStaffMember.payRates.find(
                (payRate) => payRate.roleId === shiftRole
              );
              if (!payRateDataForShiftRole) return acc;
              const shiftDuration = extractShiftDurationInHours(shift);
              const totalProjectedPayForTheShift =
                payRateDataForShiftRole.amount * shiftDuration;
              return acc + totalProjectedPayForTheShift;
            }
            return acc;
          }, 0)
        );

        const projectedAmountAsPercentageOfProjectedSales =
          projectedSalesForTheDay
            ? roundNumberToTwoDecimals(
                (projectedCostForTheRole * 100) / projectedSalesForTheDay
              )
            : null;

        return {
          roleId: roleData.id,
          roleTitle: roleData.title,
          actuals: {
            amount: actualAmountForRoleInCents,
            percentage: actualAmountAsPercentageOfProjectedSales,
          },
          projections: {
            amount: projectedCostForTheRole,
            percentage: projectedAmountAsPercentageOfProjectedSales,
          },
        };
      });
      return {
        day: dayName,
        fohRoles: fohResult,
        bohRoles: bohResult,
      };
    });
  }, [
    businessSettings,
    dailyProjectedSales,
    groupedDailyWorkEvents,
    weekDays,
    fohScheduleForAWeekRange,
    bohScheduleForAWeekRange,
    dailyProjectedSales,
    fellowStaffMembers,
  ]);

  const dailyActualsGroupedByRoles: IDailyActualGroupedByRoles[] =
    useMemo(() => {
      return weekDays.map((weekday, index): IDailyActualGroupedByRoles => {
        const weForTheWeekDay = groupedDailyWorkEvents?.[index];
        const projectedSalesForTheDay = dailyProjectedSales[weekday];

        const bohWorkEvents = weForTheWeekDay?.filter((we) => {
          const positionId = we.position;
          const positionData = businessSettings?.jobFunctions?.[positionId];
          if (!positionData) return false;
          const department = positionData.departmentId;
          return department === "boh";
        });
        const fohWorkEvents = weForTheWeekDay?.filter((we) => {
          const positionId = we.position;
          const positionData = businessSettings?.jobFunctions?.[positionId];
          if (!positionData) return false;
          const department = positionData.departmentId;
          return department === "foh";
        });

        const bohWorkEventsGroupedByRoles = _.groupBy(bohWorkEvents, (we) => {
          return we.position;
        });

        const fohWorkEventsGroupedByRoles = _.groupBy(fohWorkEvents, (we) => {
          return we.position;
        });

        const bohGroupedActuals = _.mapValues(
          bohWorkEventsGroupedByRoles,
          (wes, key) => {
            const amountForRoleInCents = wes?.reduce((acc, we) => {
              if (we.payRate) {
                return (
                  acc + extractAPIWorkEventDurationInHours(we) * we.payRate
                );
              }
              return acc;
            }, 0);
            const roleId = key;
            const roleData = businessSettings?.jobFunctions?.[roleId];
            const roleTitle = roleData.title;
            const weCostAsPercentageOfProjectedSales =
              (amountForRoleInCents * 100) /
              (projectedSalesForTheDay.projectedAmount || 1);

            return {
              roleId,
              roleTitle,
              amount: roundNumberToTwoDecimals(amountForRoleInCents),
              percentage: roundNumberToTwoDecimals(
                weCostAsPercentageOfProjectedSales
              ),
            };
          }
        );

        const fohGroupedActuals = _.mapValues(
          fohWorkEventsGroupedByRoles,
          (wes, key) => {
            const amountForRoleInCents = wes?.reduce((acc, we) => {
              if (we.payRate) {
                return (
                  acc + extractAPIWorkEventDurationInHours(we) * we.payRate
                );
              }
              return acc;
            }, 0);
            const roleId = key;
            const roleData = businessSettings?.jobFunctions?.[roleId];
            const roleTitle = roleData.title;
            const weCostAsPercentageOfProjectedSales =
              (amountForRoleInCents * 100) /
              (projectedSalesForTheDay.projectedAmount || 1);

            return {
              roleId,
              roleTitle,
              amount: roundNumberToTwoDecimals(amountForRoleInCents),
              percentage: roundNumberToTwoDecimals(
                weCostAsPercentageOfProjectedSales
              ),
            };
          }
        );

        const bohAmount = bohWorkEvents?.reduce((acc, we: WorkEvent) => {
          if (we.payRate) {
            return acc + extractAPIWorkEventDurationInHours(we) * we.payRate;
          }
          return acc;
        }, 0);

        const fohAmount = fohWorkEvents?.reduce((acc, we: WorkEvent) => {
          if (we.payRate) {
            return acc + extractAPIWorkEventDurationInHours(we) * we.payRate;
          }
          return acc;
        }, 0);

        return {
          day: weekday,
          amount: roundNumberToTwoDecimals(bohAmount + fohAmount),
          bohRoles: _.keys(bohGroupedActuals).map(
            (key) => bohGroupedActuals[key]
          ),
          fohRoles: _.keys(fohGroupedActuals).map(
            (key) => fohGroupedActuals[key]
          ),
        };
      });
    }, [
      businessSettings,
      dailyProjectedSales,
      groupedDailyWorkEvents,
      weekDays,
    ]);

  const targetLaborCostAsPercentageSkeleton: {
    [dayName: string]: TargetLaborCostAsPercentage;
  } = useMemo(() => {
    const skeleton: { [dayName: string]: TargetLaborCostAsPercentage } = {};

    moment.weekdays(true).forEach((dayName, index) => {
      skeleton[dayName] = {
        dayNameString: dayName,
        dayIndex: index,
        percentage: null,
      };
    });
    return skeleton;
  }, []);

  const bohTargetLaborCostAsPercentage = useMemo(() => {
    const initial = _.clone(targetLaborCostAsPercentageSkeleton);
    const bohTargetLaborCostAsPercentageFromStore =
      bohScheduleForAWeekRange?.targetLaborCostAsPercentage ?? {};

    _.keys(bohTargetLaborCostAsPercentageFromStore).forEach((dayName) => {
      const dayTargetLaborCostAsPercentage =
        bohTargetLaborCostAsPercentageFromStore[dayName];
      if (
        dayTargetLaborCostAsPercentage &&
        dayTargetLaborCostAsPercentage.percentage
      ) {
        initial[dayName] = {
          ...initial[dayName],
          percentage: roundNumberToTwoDecimals(
            dayTargetLaborCostAsPercentage.percentage
          ),
        };
      }
    });
    return initial;
  }, [bohScheduleForAWeekRange, targetLaborCostAsPercentageSkeleton]);

  const fohTargetLaborCostAsPercentage = useMemo(() => {
    const result = _.clone(targetLaborCostAsPercentageSkeleton);
    const fohTargetLaborCostAsPercentageFromStore =
      fohScheduleForAWeekRange?.targetLaborCostAsPercentage ?? {};

    _.keys(fohTargetLaborCostAsPercentageFromStore).forEach((dayName) => {
      const dayTargetLaborCostAsPercentage =
        fohTargetLaborCostAsPercentageFromStore[dayName];
      if (
        dayTargetLaborCostAsPercentage &&
        dayTargetLaborCostAsPercentage.percentage
      ) {
        result[dayName] = {
          ...result[dayName],
          percentage: roundNumberToTwoDecimals(
            dayTargetLaborCostAsPercentage.percentage
          ),
        };
      }
    });
    return result;
  }, [fohScheduleForAWeekRange, targetLaborCostAsPercentageSkeleton]);

  const fohTargetLaborCostAsDollars = useMemo(() => {
    return _.mapValues(_.clone(fohTargetLaborCostAsPercentage), (day) => {
      const projectedSalesForTheDay = dailyProjectedSales[day.dayNameString];

      return {
        ...day,
        amount:
          ((day.percentage ?? 0) / 100) *
          (projectedSalesForTheDay.projectedAmount ?? 0),
      };
    });
  }, [fohTargetLaborCostAsPercentage, dailyProjectedSales]);

  const avgFohTargetLaborCostAsPercentage = useMemo(() => {
    const totalTargetLC = _.sumBy(
      _.values(fohTargetLaborCostAsDollars),
      (day) => day.amount
    );
    const totalProjectedSales = _.sumBy(
      _.values(dailyProjectedSales),
      (day) => day.projectedAmount
    );
    const targetLCPerc = roundNumberToTwoDecimals(
      (totalTargetLC / totalProjectedSales) * 100
    );
    return targetLCPerc;
  }, [fohTargetLaborCostAsDollars, dailyProjectedSales]);

  const bohTargetLaborCostAsDollars = useMemo(() => {
    return _.mapValues(_.clone(bohTargetLaborCostAsPercentage), (day) => {
      const projectedSalesForTheDay = dailyProjectedSales[day.dayNameString];
      return {
        ...day,
        amount:
          ((day.percentage ?? 0) / 100) *
          (projectedSalesForTheDay.projectedAmount ?? 0),
      };
    });
  }, [bohTargetLaborCostAsPercentage, dailyProjectedSales]);

  const avgBohTargetLaborCostAsPercentage = useMemo(() => {
    const totalTargetLC = _.sumBy(
      _.values(bohTargetLaborCostAsDollars),
      (day) => day.amount
    );
    const totalProjectedSales = _.sumBy(
      _.values(dailyProjectedSales),
      (day) => day.projectedAmount
    );
    const targetLCPerc = roundNumberToTwoDecimals(
      (totalTargetLC / totalProjectedSales) * 100
    );
    return targetLCPerc;
  }, [bohTargetLaborCostAsDollars, dailyProjectedSales]);

  const avgBohTargetLaborCostAsDollars = useMemo(() => {
    return _.meanBy(_.values(bohTargetLaborCostAsDollars), (day) => day.amount);
  }, [bohTargetLaborCostAsDollars]);

  const avgFohTargetLaborCostAsDollars = useMemo(() => {
    return roundNumberToTwoDecimals(
      _.meanBy(_.values(fohTargetLaborCostAsDollars), (day) => day.amount)
    );
  }, [fohTargetLaborCostAsDollars]);

  const groupedDailyFOHShifts = useMemo(() => {
    if (!fohScheduleForAWeekRange) return {};
    return _.groupBy(fohScheduleForAWeekRange.shifts, (shift) => {
      const shiftStartDateWithTimezone = moment(shift?.startDate?.toDate());
      shiftStartDateWithTimezone.tz(business.timezone, true);
      const dayIndex = shiftStartDateWithTimezone.day();
      return moment.weekdays()[dayIndex];
    });
  }, [fohScheduleForAWeekRange]);

  const groupedDailyFOHProjections: {
    [dayName: string]: {
      dayNameString: string;
      dayIndex: number;
      amount: number;
      percentage: number | null;
    };
  } = useMemo(() => {
    const result: {
      [dayName: string]: {
        dayNameString: string;
        dayIndex: number;
        amount: number;
        percentage: number | null;
      };
    } = {};

    moment.weekdays(true).forEach((dayName, index) => {
      result[dayName] = {
        dayNameString: dayName,
        dayIndex: index,
        amount: 0,
        percentage: null,
      };
    });

    _.mapValues(groupedDailyFOHShifts, (shifts, dayName) => {
      const totalShiftsCost = shifts.reduce((acc, shift) => {
        if (shift.staffId) {
          const shiftRole = shift.position;
          const shiftStaffMember: StaffMember | undefined =
            fellowStaffMembers.find(
              (staffMember) => staffMember.uid === shift.staffId
            );
          if (!shiftStaffMember) return acc;
          const payRateDataForShiftRole = shiftStaffMember.payRates.find(
            (payRate) => payRate.roleId === shiftRole
          );
          if (!payRateDataForShiftRole) return acc;
          const shiftDuration = extractShiftDurationInHours(shift);
          const totalProjectedPayForTheShift =
            payRateDataForShiftRole.amount * shiftDuration;

          return acc + totalProjectedPayForTheShift;
        }
        return acc;
      }, 0);

      const projectedSalesForTheDay =
        dailyProjectedSales[dayName]?.projectedAmount;

      result[dayName].percentage = projectedSalesForTheDay
        ? roundNumberToTwoDecimals(
            (totalShiftsCost * 100) / projectedSalesForTheDay
          )
        : null;

      result[dayName].amount = roundNumberToTwoDecimals(totalShiftsCost);
    });
    return result;
  }, [groupedDailyFOHShifts, fellowStaffMembers]);

  const groupedDailyBOHShifts = useMemo(() => {
    if (!bohScheduleForAWeekRange) return {};
    return _.groupBy(bohScheduleForAWeekRange.shifts, (shift) => {
      const shiftStartDateWithTimezone = moment(shift?.startDate?.toDate());
      shiftStartDateWithTimezone.tz(business.timezone, true);
      const dayIndex = shiftStartDateWithTimezone.day();
      return moment.weekdays()[dayIndex];
    });
  }, [bohScheduleForAWeekRange]);

  const groupedDailyBOHProjections: {
    [dayName: string]: {
      dayNameString: string;
      dayIndex: number;
      amount: number;
      percentage: number | null;
    };
  } = useMemo(() => {
    const result: {
      [dayName: string]: {
        dayNameString: string;
        dayIndex: number;
        amount: number;
        percentage: number | null;
      };
    } = {};

    moment.weekdays(true).forEach((dayName, index) => {
      result[dayName] = {
        dayNameString: dayName,
        dayIndex: index,
        amount: 0,
        percentage: null,
      };
    });

    _.mapValues(groupedDailyBOHShifts, (shifts, dayName) => {
      const totalShiftsCost = shifts.reduce((acc, shift) => {
        if (shift.staffId) {
          const shiftRole = shift.position;
          const shiftStaffMember = fellowStaffMembers.find(
            (staffMember) => staffMember.uid === shift.staffId
          );
          if (!shiftStaffMember) return acc;
          const payRateDataForShiftRole = shiftStaffMember.payRates.find(
            (payRate) => payRate.roleId === shiftRole
          );
          if (!payRateDataForShiftRole) return acc;
          const shiftDuration = extractShiftDurationInHours(shift);
          const totalProjectedPayForTheShift =
            payRateDataForShiftRole.amount * shiftDuration;
          return acc + totalProjectedPayForTheShift;
        }
        return acc;
      }, 0);

      const projectedSalesForTheDay =
        dailyProjectedSales[dayName]?.projectedAmount;

      result[dayName].percentage = projectedSalesForTheDay
        ? roundNumberToTwoDecimals(
            (totalShiftsCost * 100) / projectedSalesForTheDay
          )
        : null;
      result[dayName].amount = roundNumberToTwoDecimals(totalShiftsCost);
    });
    return result;
  }, [groupedDailyBOHShifts, fellowStaffMembers]);

  const dailyActualsAsPercentagesOfActualSales = useMemo(() => {
    return dailyActuals.map((da) => {
      const actualSalesForTheDay = dailyActualSales[da.day]?.amount;

      const actualFOHLCAsPercentageOfProjectedSales = actualSalesForTheDay
        ? roundNumberToTwoDecimals((da.foh * 100) / actualSalesForTheDay)
        : null;

      const actualBOHLCAsPercentageOfProjectedSales = actualSalesForTheDay
        ? roundNumberToTwoDecimals((da.boh * 100) / actualSalesForTheDay)
        : null;

      return {
        ...da,
        foh: actualFOHLCAsPercentageOfProjectedSales,
        boh: actualBOHLCAsPercentageOfProjectedSales,
      };
    });
  }, [dailyProjectedSales, dailyActualSales]);

  return {
    dailyActuals,
    dailyActualsAsPercentagesOfActualSales,
    dailyProjectedSales,
    bohDraftScheduleId: bohScheduleForAWeekRange?.id ?? null,
    fohDraftScheduleId: fohScheduleForAWeekRange?.id ?? null,
    groupedDailyBOHProjections,
    groupedDailyFOHProjections,
    fohTargetLaborCostAsPercentage,
    bohTargetLaborCostAsPercentage,
    fohTargetLaborCostAsDollars,
    bohTargetLaborCostAsDollars,
    avgBohTargetLaborCostAsPercentage,
    avgFohTargetLaborCostAsPercentage,
    avgBohTargetLaborCostAsDollars,
    avgFohTargetLaborCostAsDollars,
    dailyActualsGroupedByRoles,
    totalWeeklyProjectedSales,
    actualScheduleCost,
    statsGroupedByRoles,
    weeklyStatsGroupedByRoles,
    actualSalesDataQueries,
    refetchActualSalesData,
    actualSalesDataLoading,
    dailyActualSales,
  };
};
