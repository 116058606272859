import React, { useCallback, useEffect, useMemo, useState } from 'react';
import UIComponents from "@tangopay/tango-ui-library";
import moment from 'moment';
import { getPositionNameById } from 'model/selectors/businessSettings';
import { useSelector } from "react-redux";
import { RootState } from 'model/store';
import {
  approverTimeOffRequestsSelector,
  getStaffMembersThatHaveBeenApprovedForTimeOffDuringShfit,
  staffMemberIsAvailableToWorkTheShift,
  staffMemberIsUnavailableToWorkTheShift,
} from "model/selectors/scheduleEvents";
import {
  createDraftScheduleFromDuplicateWithEditShiftPayload,
  deleteShift,
  deleteShiftInDuplicate,
  editScheduleShift,
} from "controllers/schedule";

const { Modal, Button, Dropdown, TimePickerInput } = UIComponents;

interface Props {
  shift: TangoShift | null;
  schedule: TangoSchedule | null;
  closeModal: () => void;
  mergedScheduleForAWeekRange: TangoSchedule | undefined;
}
const AssignShiftModal = ({ shift, schedule, mergedScheduleForAWeekRange, closeModal }: Props) => {
  const [startTime, setStartTime] = useState(shift?.startTime ?? '');
  const [endTime, setEndTime] = useState(shift?.endTime ?? '');
  const [notes, setNotes] = useState(shift?.notes ?? '');
  const [selectedStaff, setSelectedStaff] = useState(shift?.staffId);

  useEffect(() => {
    setStartTime(shift?.startTime ?? '');
    setEndTime(shift?.endTime ?? '');
    setNotes(shift?.notes ?? '');
    setSelectedStaff(shift?.staffId)
  }, [shift]);
  if (shift) {
    console.log("Selected shift", { ...shift, startDate: shift?.startDate.toDate(), endDate: shift?.endDate.toDate() })

  }
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );
  const fixedAvailabilities: FixedAvailability[] = useSelector(
    (state: RootState) => state.fixedAvailabilities
  );
  const approvedTimeOffRequests: ScheduleEvent[] = useSelector(
    approverTimeOffRequestsSelector
  );
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );


  const saveShiftHandler = useCallback(async () => {
    try {
      if (shift && business && schedule && startTime && endTime) {
        if (schedule?.isDuplicate) {
          await createDraftScheduleFromDuplicateWithEditShiftPayload(
            schedule,
            business.id,
            shift.id,
            startTime ?? shift.startTime,
            endTime ?? shift.endTime,
            selectedStaff ?? null,
            notes
          );
          closeModal()

        } else {
          const scheduleId = schedule?.mergedSchedules?.length
            ? shift.draftScheduleId
            : schedule.id;
          if (scheduleId) {
            await editScheduleShift(
              shift.id,
              scheduleId,
              business.id,
              startTime ?? shift.startTime,
              endTime ?? shift.endTime,
              selectedStaff ?? null,
              notes
            );
            closeModal()

          } else {
            throw new Error("Opps, we weren't able to find this schedule.");
          }
        }
      }
    } catch (e: any) {
      console.log('error', e)
    }
  }, [schedule, shift, business, startTime, endTime, notes, selectedStaff]);

  const deleteShiftHandler = useCallback(async () => {
    if (schedule && shift && business) {
      if (schedule?.isDuplicate) {
        await deleteShiftInDuplicate(business.id, schedule, shift.id);
      } else {
        const scheduleId = schedule?.mergedSchedules?.length
          ? shift.draftScheduleId
          : schedule.id;
        if (scheduleId) {
          await deleteShift(business.id, scheduleId, shift.id);
        }
      }
      closeModal();
    }
  }, [schedule, shift, business]);

  const scheduleToUse = useMemo(() => {
    return mergedScheduleForAWeekRange || schedule;
  }, [mergedScheduleForAWeekRange, schedule])

  const shiftSchedule = useMemo(() => {
    return scheduleToUse?.shifts.find(
      ({ id }: TangoShift) => id === shift?.id
    );

  }, [scheduleToUse, shift])
  const staffMember = useMemo(() => fellowStaffMembers.find((sm) => sm?.id === selectedStaff), [fellowStaffMembers, selectedStaff])


  // if (!scheduleToUse) return null

  // if (!shiftSchedule) return null

  const staffMembersThatHaveApprovedTimeOffForThisShift = useMemo(() => {
    if (!shiftSchedule) return []

    return getStaffMembersThatHaveBeenApprovedForTimeOffDuringShfit(
      fellowStaffMembers,
      approvedTimeOffRequests,
      shiftSchedule
    )
  }, [shiftSchedule, fellowStaffMembers, approvedTimeOffRequests])

  const shiftTypes = useMemo(() => businessSettings.shiftTypes || [], [businessSettings])

  const shiftType = useMemo(() => shiftTypes.find((st) => st.id === shift?.shiftTypeId), [shiftTypes, shift])

  const staffMembersThatAreAvaiableToWorkThisShift = useMemo(() => {
    if (!shiftSchedule) return []
    return fellowStaffMembers
      .filter(
        (staffMember) => !staffMember.status || staffMember.status === "active"
      )
      .filter((staffMember) =>
        staffMemberIsAvailableToWorkTheShift(
          staffMember,
          fixedAvailabilities,
          business,
          scheduleToUse,
          shiftSchedule,
          false
        )
      )
      .filter(
        (staffMember) =>
          !staffMembersThatHaveApprovedTimeOffForThisShift.find(
            (sm) => sm.id === staffMember.id
          )
      ).map((staffMember) => ({ value: staffMember.id, label: staffMember.contact.firstName + ' ' + staffMember.contact.lastName }))
  }, [
    shiftSchedule,
    fellowStaffMembers,
    fixedAvailabilities,
    business,
    scheduleToUse,
    staffMembersThatHaveApprovedTimeOffForThisShift,
  ])

  const staffMembersThatAreUnAvaiableToWorkThisShift = useMemo(() => {
    if (!shiftSchedule) return []
    return fellowStaffMembers
      .filter(
        (staffMember) => !staffMember.status || staffMember.status === "active"
      )
      .filter((staffMember) =>
        staffMemberIsUnavailableToWorkTheShift(
          staffMember,
          fixedAvailabilities,
          business,
          scheduleToUse,
          shiftSchedule,
          false,
          []
        )
      )
      .filter(
        (staffMember) =>
          !staffMembersThatHaveApprovedTimeOffForThisShift.find(
            (sm) => sm.id === staffMember.id
          )
      ).map((staffMember) => ({ value: staffMember.id, label: staffMember.contact.firstName + ' ' + staffMember.contact.lastName }))
  }, [
    shiftSchedule,
    fellowStaffMembers,
    fixedAvailabilities,
    business,
    scheduleToUse,
    staffMembersThatHaveApprovedTimeOffForThisShift,
  ])

  const dropdownOptions = useMemo(() => {

    return [
      {
        label: "Available",
        options: staffMembersThatAreAvaiableToWorkThisShift
      },
      {
        label: "Unavailable",
        options: staffMembersThatAreUnAvaiableToWorkThisShift.map((sm) => {
          if (staffMembersThatHaveApprovedTimeOffForThisShift.find(sm2 => sm2.id === sm.value)) {
            return { ...sm, label: sm.label + ' (Time Off)', disabled: true }
          }
          return { ...sm, label: sm.label + ' (Unavailable)' }

        })
      },

    ]
  }, [staffMembersThatAreAvaiableToWorkThisShift, staffMembersThatAreUnAvaiableToWorkThisShift, staffMembersThatHaveApprovedTimeOffForThisShift])
  console.log("shift", shift)
  return (
    <Modal isOpen={Boolean(shift)} closeModal={closeModal} width="450" >
      {
        shift && schedule ?
          <>
            <div className='flex -mt-2 pl-10 items-center pb-6 border-b border-solid border-grey-1 border-l-0 border-r-0 border-t-0 mb-10 -mx-10'>
              <div className=' text-sm text-black font-lato-black '>
                {moment(shift.startDate.toMillis()).format("dddd, MMM D")}
              </div>
              <div className='h-6 bg-grey-1 w-px mx-3' />
              <div className=' text-xs font-lato-bold text-error-red cursor-pointer' onClick={deleteShiftHandler}>
                Delete
              </div>
            </div>
            <div className='mb-8'>
              <div className='text-base font-lato-black text-black mb-3'>{getPositionNameById(businessSettings, shift.position)}</div>
              <Dropdown
                menuPosition="absolute"
                menuPlacement="bottom"
                value={{ value: staffMember?.id ?? '', label: staffMember ? staffMember.contact.firstName + ' ' + staffMember?.contact.lastName : '' }}
                options={dropdownOptions}
                onChange={(staff) => {
                  if (staff) {
                    //@ts-ignore
                    setSelectedStaff(staff.value)
                  }
                }}
                isOptionDisabled={(option: any) => option.disabled}
                size="medium"
              />
            </div>

            <div className='mb-8'>
              <div className='text-base font-lato-black text-black mb-3'>Shift Type</div>
              <Dropdown
                isDisabled
                value={{ value: shiftType?.id ?? '', label: shiftType?.name ?? '' }}
                options={[{
                  label: "test",
                  value: "test"
                }]}
                menuIsOpen={false}
                menuPosition="absolute"
                styles={{
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#424242",
                    fontSize: '12px',
                    fontFamily: "Lato-Bold",
                    backgroundColor: shiftType?.backgroundColor,
                    border: '1px solid ' + shiftType?.mainColor,
                    width: "fit-content",
                    padding: "1px 16px !important",
                    borderRadius: "100px"
                  })
                }}
                size="medium"
              />
            </div>
            <div className='flex mb-8'>
              <div className='w-1/2 pr-4'>
                <div className='text-base font-lato-black text-black mb-3'>Start Time</div>
                <TimePickerInput size="large"
                  value={moment(startTime, 'HH:mm').toDate() ?? new Date()}
                  format="h:mm a"
                  onChange={(date) => setStartTime(moment(date).format('HH:mm'))}
                />
              </div>
              <div className='w-1/2 pl-4'>
                <div className='text-base font-lato-black text-black mb-3'>End Time</div>
                <TimePickerInput size="large"
                  value={moment(endTime, 'HH:mm').toDate() ?? new Date()}
                  format="h:mm a"
                  onChange={(date) => setEndTime(moment(date).format('HH:mm'))}
                />
              </div>
            </div>
            <div className='mb-8'>
              <div className='text-base font-lato-black text-black mb-3'>Shift Type</div>
              <textarea
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
                placeholder='Type your message here...'
                className='w-full border border-solid border-grey-1 rounded-2xl min-h-[80px] p-4 text-sm font-lato-regular text-black'
              />
            </div>
            <div className='mt-9 flex justify-between'>
              <Button label='Cancel' className='w-full justify-center mr-4' type="btn-style-1" size="btn-large" />
              <Button onClick={() => saveShiftHandler()} label='Save Shift' className='w-full justify-center' type="btn-style-1" size="btn-large" />
            </div>
          </>
          :
          null
      }
    </Modal>
  )
};
export default AssignShiftModal;