import React from 'react';
import UIComponents from '@tangopay/tango-ui-library';
const { Modal, Dropdown, Button } = UIComponents;
type Props = {
    isOpen: boolean;
    closeModal: () => void;
}

const AssignShiftModal = ({ isOpen, closeModal }: Props) => {
    return (
        <Modal isOpen={isOpen} closeModal={closeModal} width="736">
            <div className=' text-xl font-lato-black text-black'>Assign Shift</div>
            <div className='flex items-center mt-4'>
                <span className=' text-sm font-lato-regular text-black'>Sunday, July 24, 2022, 9:00 AM — 12:00 PM</span>
                <div className='h-9 w-px bg-grey-1 mx-4' />
                <span className=' text-sm font-lato-regular text-black'>Server</span>
            </div>
            <div className='mt-8'>
                <div className=' text-lg font-lato-black text-black mb-4'>Available Servers</div>
                <div className='relative'>
                    <Dropdown options={[{
                        value: "Test",
                        label: "test",
                    }]}
                        menuPosition="absolute"
                        size="medium"
                        showUserIcon
                    />
                </div>

            </div>
            <div className='mt-9 flex'>
                <Button label='Cancel' onClick={closeModal} className='w-full mr-4 justify-center' type="btn-style-1" size="btn-medium" />
                <Button label={`Assign Shift`} className='w-full justify-center' type="btn-style-1" size="btn-medium" />
            </div>
        </Modal>
    )
}
export default AssignShiftModal;