import _ from "lodash";
import React, { useMemo, useState } from "react";

import { DraftIdWithDepartmentId } from "controllers/schedule";
import { DepartmentId } from "controllers/staff";

import Box from "components/Box";

import { AvailabilityForADay } from "./AvailabilitiesContent";
import { ScheduleStatus } from "./ShiftViewTypeSidebarContent";
import { StaffWeeklyContent } from "./StaffWeeklyContent";
import { WeeklySidebar } from "./WeeklySidebar";
import { WeeklyScheduleViewType } from "./WeeklyViewSelect";
import CreateShiftModal from "./CreateShiftModal";

interface WeeklyScheduleViewProps {
  weekRange: Date[];
  selectedRequestType?: ScheduleEventType;
  isSelectRequestType: boolean | undefined;
  isAvailabilityView: boolean;
  onCalendarDateClick: (d: Date) => void;
  business: TangoBusiness;
  schedulesWithLocalSchedule: TangoSchedule[];
  fixedSchedules: TangoFixedSchedule[];
  schedules: TangoSchedule[];
  scheduleEvents: ScheduleEvent[];
  setAvailabilityViewFilterAnchorEl: (t: any) => void;
  availabilityViewFilterAnchorEl: any;
  availabilityRoleFilterId: string | null;
  businessSettings: TangoBusinessSettings;
  jobFunctions: TangoJobFunctions;
  onAvailabilityFullNameInputChangeDebounced: (v: any) => void;
  setAvailabilityRoleFilterId: (v: any) => void;
  weeklyScheduleViewType: WeeklyScheduleViewType;
  setWeeklyScheduleViewType: (v: WeeklyScheduleViewType) => void;
  departmentScheduleViewType: DepartmentId | null;
  setDepartmentScheduleViewType: (d: DepartmentId | null) => void;
  scheduleForWeekRange: TangoSchedule | null;
  duplicatedScheduleForAWeekRange: TangoSchedule | null;
  publishScheduleLoading: boolean;
  scheduleStatus: ScheduleStatus;
  setWeeklyStaffMemberViewFilterAnchorEl: (t: any) => void;
  weeklyStaffMemberViewFilterAnchorEl: any;
  onFullNameInputChangeDebounced: (e: any) => void;
  roleFilterId: string | null;
  setRoleFilterId: (v: any) => void;

  filteredStaffMembersAvailability: StaffMember[];
  generateAvailabilitiesForADay: (d: Date) => AvailabilityForADay[];

  selectedDepartment: "boh" | "foh" | undefined;

  setShowSingleDay: (v: boolean) => void;

  staffMemberNameFilter: string;
  fellowStaffMembers: StaffMember[];
  setSelectedShiftForEdit: (v: TangoShift | null) => void;
  draftsWithDepartmentForAWeek: DraftIdWithDepartmentId[];
  bohDraftStatus: ScheduleStatus;
  fohDraftStatus: ScheduleStatus;
  mergedScheduleForAWeekRange: TangoSchedule | undefined;
  fohScheduleForAWeekRange: TangoSchedule | undefined;
  bohScheduleForAWeekRange: TangoSchedule | undefined;
  currentDateForDailyShiftView: null | Date;
  setCurrentDateForDailyShiftView: (date: Date | null) => void;
}

export const WeeklyScheduleView = ({
  weekRange,
  selectedRequestType,
  isSelectRequestType = false,
  isAvailabilityView = false,
  onCalendarDateClick,
  business,
  schedulesWithLocalSchedule,
  fixedSchedules,

  schedules,
  scheduleEvents,
  setAvailabilityViewFilterAnchorEl,
  availabilityViewFilterAnchorEl,
  availabilityRoleFilterId,
  businessSettings,

  jobFunctions,
  onAvailabilityFullNameInputChangeDebounced,
  setAvailabilityRoleFilterId,
  weeklyScheduleViewType,
  setWeeklyScheduleViewType,
  departmentScheduleViewType,
  setDepartmentScheduleViewType,
  scheduleStatus,
  publishScheduleLoading,
  scheduleForWeekRange,

  duplicatedScheduleForAWeekRange,
  setWeeklyStaffMemberViewFilterAnchorEl,
  weeklyStaffMemberViewFilterAnchorEl,
  onFullNameInputChangeDebounced,
  roleFilterId,
  setRoleFilterId,

  filteredStaffMembersAvailability,
  generateAvailabilitiesForADay,
  selectedDepartment,
  setShowSingleDay,

  staffMemberNameFilter,
  fellowStaffMembers,
  setSelectedShiftForEdit,
  draftsWithDepartmentForAWeek,
  bohDraftStatus,
  fohDraftStatus,
  mergedScheduleForAWeekRange,
  fohScheduleForAWeekRange,
  bohScheduleForAWeekRange,
  currentDateForDailyShiftView,
  setCurrentDateForDailyShiftView,
}: WeeklyScheduleViewProps) => {
  const [showCreateShiftModal, setShowCreateShiftModal] = useState(false);
  const shiftTypes: { value: string, label: string, color: string, borderColor: string }[] = (businessSettings?.shiftTypes || []).filter(
    (shiftT) => !shiftT?.deleted
  ).map((item) => {
    return { value: item.id, label: item.name, color: item.backgroundColor, borderColor: item.mainColor }
  });

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        className="relative staffing-sidebar"
        style={{
          width: "20vw",
          height: '100%'
        }}
      >
        <WeeklySidebar
          weekRange={weekRange}
          selectedRequestType={selectedRequestType}
          isSelectRequestType={isSelectRequestType}
          isAvailabilityView={isAvailabilityView}
          onCalendarDateClick={onCalendarDateClick}
          business={business}
          schedulesWithLocalSchedule={schedulesWithLocalSchedule}
          fixedSchedules={fixedSchedules}
          schedules={schedules}
          scheduleEvents={scheduleEvents}
          setAvailabilityViewFilterAnchorEl={setAvailabilityViewFilterAnchorEl}
          availabilityViewFilterAnchorEl={availabilityViewFilterAnchorEl}
          availabilityRoleFilterId={availabilityRoleFilterId}
          businessSettings={businessSettings}
          jobFunctions={jobFunctions}
          onAvailabilityFullNameInputChangeDebounced={
            onAvailabilityFullNameInputChangeDebounced
          }
          setAvailabilityRoleFilterId={setAvailabilityRoleFilterId}
          weeklyScheduleViewType={weeklyScheduleViewType}
          setWeeklyScheduleViewType={setWeeklyScheduleViewType}
          departmentScheduleViewType={departmentScheduleViewType}
          setDepartmentScheduleViewType={setDepartmentScheduleViewType}
          scheduleStatus={scheduleStatus}
          publishScheduleLoading={publishScheduleLoading}
          scheduleForWeekRange={scheduleForWeekRange}
          duplicatedScheduleForAWeekRange={duplicatedScheduleForAWeekRange}
          setWeeklyStaffMemberViewFilterAnchorEl={
            setWeeklyStaffMemberViewFilterAnchorEl
          }
          weeklyStaffMemberViewFilterAnchorEl={
            weeklyStaffMemberViewFilterAnchorEl
          }
          onFullNameInputChangeDebounced={onFullNameInputChangeDebounced}
          roleFilterId={roleFilterId}
          setRoleFilterId={setRoleFilterId}
          draftsWithDepartmentForAWeek={draftsWithDepartmentForAWeek}
          bohDraftStatus={bohDraftStatus}
          fohDraftStatus={fohDraftStatus}
          mergedScheduleForAWeekRange={mergedScheduleForAWeekRange}
          fohScheduleForAWeekRange={fohScheduleForAWeekRange}
          bohScheduleForAWeekRange={bohScheduleForAWeekRange}
          openCreateShiftModal={() => setShowCreateShiftModal(true)}
          currentDateForDailyShiftView={currentDateForDailyShiftView}
          setCurrentDateForDailyShiftView={setCurrentDateForDailyShiftView}
        />
      </Box>
      <StaffWeeklyContent
        isAvailabilityView={isAvailabilityView}
        weekRange={weekRange}
        filteredStaffMembersAvailability={filteredStaffMembersAvailability}
        generateAvailabilitiesForADay={generateAvailabilitiesForADay}
        weeklyScheduleViewType={weeklyScheduleViewType}
        setWeeklyScheduleViewType={setWeeklyScheduleViewType}
        schedulesWithLocalSchedule={schedulesWithLocalSchedule}
        business={business}
        fixedSchedules={fixedSchedules}
        selectedDepartment={selectedDepartment}
        departmentScheduleViewType={departmentScheduleViewType}
        businessSettings={businessSettings}
        setShowSingleDay={setShowSingleDay}
        jobFunctions={jobFunctions}
        setWeeklyStaffMemberViewFilterAnchorEl={
          setWeeklyStaffMemberViewFilterAnchorEl
        }
        weeklyStaffMemberViewFilterAnchorEl={
          weeklyStaffMemberViewFilterAnchorEl
        }
        onFullNameInputChangeDebounced={onFullNameInputChangeDebounced}
        roleFilterId={roleFilterId}
        setRoleFilterId={setRoleFilterId}
        staffMemberNameFilter={staffMemberNameFilter}
        fellowStaffMembers={fellowStaffMembers}
        setSelectedShiftForEdit={(shift) => setSelectedShiftForEdit(shift)}
        scheduleForWeekRange={scheduleForWeekRange}
        duplicatedScheduleForAWeekRange={duplicatedScheduleForAWeekRange}
        setCurrentDateForDailyShiftView={setCurrentDateForDailyShiftView}
        fohScheduleForAWeekRange={fohScheduleForAWeekRange}
        bohScheduleForAWeekRange={bohScheduleForAWeekRange}
        mergedScheduleForAWeekRange={mergedScheduleForAWeekRange}

      />
      <CreateShiftModal
        isOpen={showCreateShiftModal}
        closeModal={() => setShowCreateShiftModal(false)}
        shiftTypes={shiftTypes}
        jobFunctions={businessSettings.jobFunctions}
        weekRange={weekRange}
        schedulesWithLocalSchedule={schedulesWithLocalSchedule}
        business={business}
        selectedDepartment={selectedDepartment}
        fohScheduleForAWeekRange={fohScheduleForAWeekRange}
        bohScheduleForAWeekRange={bohScheduleForAWeekRange}
      />
    </>
  );
};
