import { Button, Dialog, Icon, InputGroup } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import axios from "axios";
import firebase from "config/firebase";
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useLaunchpad } from "views/LaunchPad/LaunchpadProvider";

import Spinner from "components/Spinner";

import {
  composeUsableTabAndOrder,
  formatSalesFromRust,
} from "models/reporting";

import { TabAndOrderForRust } from "types/order";

import { getOrdersAndTabsForReporting } from "model/selectors/reporting";
import { RootState } from "model/store";

import SwitchBusinessPolygonSvg from "assets/switch_business_polygon.svg";

import Box from "../../components/Box";
import { sortHours } from "../../utils/dateUtils";
import {
  fetchAccountTempPassword,
  updateCurrentUserPassword,
} from "../../utils/functions";
import { SwitchLocationOverlay } from "./SwitchLocationOverlay";
import "./style.css";

type AggSales = { [T: string]: number };

interface ClickableWrapperProps {
  children: any;
  onClick?: () => void;
}

const ClickableWraper = (props: ClickableWrapperProps) => (
  <motion.div
    onClick={props.onClick}
    whileTap={{ scale: 0.9, opacity: 0.9 }}
    className="navigation-item"
  >
    {props.children}
  </motion.div>
);

const DashBoard = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const staff: StaffMember = useSelector((state: RootState) => state.user);
  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);
  const today =
    moment().hour() <= 4
      ? moment().subtract(1, "day").startOf("day").toDate()
      : moment().startOf("day").toDate();
  // const today = new Date();
  const [selectedView, setSelectedView] = useState("$");
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const navigate = useNavigate();

  const { orders, tabs } = useSelector(getOrdersAndTabsForReporting);

  // Drop the duplicates using the chargeId

  const products = useSelector((state: RootState) => state.products);

  let reporting: any | null = null;

  const [covers, setCovers] = useState(0);
  const [coversDiff, setCoversDiff] = useState(0);
  const [checkSize, setCheckSize] = useState(0);
  const [checkSizeDiff, setCheckSizeDiff] = useState(0);
  const [discountsAndPromos, setDiscountsAndPromos] = useState(0);
  const [discountsAndPromosPercent, setDiscountsAndPromosPercent] = useState(0);
  const [sales, setSales] = useState(0);
  const [salesDiff, setSalesDiff] = useState(0);
  const [salesGraph, setSalesGraph] = useState<AggSales>({});
  const [lastWeekSalesGraph, setLastWeekSalesGraph] = useState<AggSales>({});
  const [deliverySales, setDeliverySales] = useState(0);
  const [deliverySalesDiff, setDeliverySalesDiff] = useState(0);
  const [deliverySalesGraph, setDeliverySalesGraph] = useState<AggSales>({});
  const [deliveryCountGraph, setDeliveryCountGraph] = useState<AggSales>({});
  const [deliveryLastWeekSalesGraph, setDeliveryLastWeekSalesGraph] =
    useState<AggSales>({});
  const [deliveryLastWeekCountGraph, setDeliveryLastWeekCountGraph] =
    useState<AggSales>({});
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [lastUpdatedMessage, setLastUpdatedMessage] = useState("");
  const [weatherData, setWeatherData] = useState(null);
  const [iconData, setIconData] = useState<string | null>(null);
  const [country, setCountry] = useState<string>("USA");

  const [switchLocationVisiblity, setSwitchLocationVisiblity] = useState(false);

  const launchpad = useLaunchpad();

  const aggSales = (sales: number[], isCount = false) => {
    const get2HourInterval = () => {
      const hours: number[] = [];

      // TODO: This should probably based on
      // the timezone of where the business is.
      const now = new Date();
      let hour = now.getHours();

      while (hours.length < 7) {
        hours.push(hour);

        hour -= 2;
        if (hour < 0) {
          hour += 24;
        }
      }

      return hours;
    };

    const all_sales = formatSalesFromRust(sales);
    const times = get2HourInterval();

    return all_sales.reduce(
      (acc, sale) => {
        // Hit the correct or almost correct hour
        const lower: string = sale.hour ? String(sale.hour - 1) : String(0);
        const current = String(sale.hour);
        if (Object.keys(acc).includes(current)) {
          (acc as AggSales)[current] += isCount ? sale.count : sale.amount;
        } else if (Object.keys(acc).includes(lower)) {
          (acc as AggSales)[lower] += isCount ? sale.count : sale.amount;
        }

        return acc;
      },
      times.reduce((agg: AggSales, time: number) => ({ ...agg, [time]: 0 }), {})
    );
  };

  const convertHourToAmPm = (hour: string) => {
    if (parseInt(hour) === 0) {
      return "12 am";
    }

    if (parseInt(hour) < 12) {
      return `${hour} am`;
    }

    const newHour = parseInt(hour) == 12 ? hour : parseInt(hour) - 12;
    return `${newHour} pm`;
  };

  const getData = async (ordersForReporting: TabAndOrderForRust[]) => {
    const { Reporting: RustReporting } = await import("../../rustUtils");

    reporting = RustReporting.new(ordersForReporting);

    // TODO: Change todayDate and pastDate to be moment().toDate() and moment().subtract(1, 'day').toDate() respectively
    const todayDate =
      moment().hour() <= 4
        ? moment().subtract(1, "day").startOf("day").toDate()
        : moment().startOf("day").toDate();
    const pastDate =
      moment().hour() <= 4
        ? moment().subtract(2, "day").startOf("day").toDate()
        : moment().subtract(1, "day").startOf("day").toDate();

    // Covers

    const thisWeekCovers = parseInt(
      reporting.find_covers(
        todayDate,
        todayDate,
        new Date().getTimezoneOffset() / 100
      )
    );
    const lastWeekCovers = parseInt(
      reporting.find_covers(
        pastDate,
        pastDate,
        new Date().getTimezoneOffset() / 100
      )
    );

    const coversDiffWeekly = lastWeekCovers
      ? (100 * (thisWeekCovers - lastWeekCovers)) / lastWeekCovers
      : 100;
    setCovers(thisWeekCovers);
    setCoversDiff(coversDiffWeekly);

    // Average check size

    const thisWeekCheckSize = reporting.average_check_size(
      todayDate,
      todayDate,
      new Date().getTimezoneOffset() / 100
    );
    const lastWeekCheckSize = reporting.average_check_size(
      pastDate,
      pastDate,
      new Date().getTimezoneOffset() / 100
    );

    const checkSizeDiffWeekly = lastWeekCheckSize[0]
      ? (100 * (thisWeekCheckSize[0] - lastWeekCheckSize[0])) /
      lastWeekCheckSize[0]
      : 100;
    setCheckSize(thisWeekCheckSize[0]);
    setCheckSizeDiff(checkSizeDiffWeekly);

    // Promos and discounts

    const thisWeekDiscounts = reporting.promos_and_discounts_total(
      todayDate,
      todayDate,
      new Date().getTimezoneOffset() / 100
    );
    const discountsPercent = thisWeekDiscounts[1]
      ? 100 * (thisWeekDiscounts[0] / thisWeekDiscounts[1])
      : 0;
    setDiscountsAndPromos(thisWeekDiscounts[0]);
    setDiscountsAndPromosPercent(discountsPercent);

    // Sales

    const thisWeekSales = reporting.total_sales(
      todayDate,
      todayDate,
      false,
      new Date().getTimezoneOffset() / 100
    );
    const lastWeekSales = reporting.total_sales(
      pastDate,
      pastDate,
      false,
      new Date().getTimezoneOffset() / 100
    );
    const salesDiffWeekly = lastWeekSales[0]
      ? (100 * (thisWeekSales[0] - lastWeekSales[0])) / lastWeekSales[0]
      : 100;

    setSales(thisWeekSales[0]);
    setSalesDiff(salesDiffWeekly);
    setSalesGraph(aggSales(thisWeekSales[1]));
    setLastWeekSalesGraph(aggSales(lastWeekSales[1]));

    aggSales(thisWeekSales[1]);

    // Delivery and Take out sales

    const thisWeekSalesDelivery = reporting.total_sales(
      todayDate,
      todayDate,
      true,
      new Date().getTimezoneOffset() / 100
    );
    const lastWeekSalesDelivery = reporting.total_sales(
      pastDate,
      pastDate,
      true,
      new Date().getTimezoneOffset() / 100
    );
    const deliverySalesDiffWeekly = lastWeekSalesDelivery[0]
      ? (100 * (thisWeekSalesDelivery[0] - lastWeekSalesDelivery[0])) /
      lastWeekSalesDelivery[0]
      : 100;
    setDeliverySales(thisWeekSalesDelivery[0]);
    setDeliverySalesDiff(deliverySalesDiffWeekly);
    setDeliverySalesGraph(aggSales(thisWeekSalesDelivery[1]));
    setDeliveryCountGraph(aggSales(thisWeekSalesDelivery[1], true));
    setDeliveryLastWeekSalesGraph(aggSales(lastWeekSalesDelivery[1]));
    setDeliveryLastWeekCountGraph(aggSales(lastWeekSalesDelivery[1], true));
  };

  useEffect(() => {
    if (orders.length > 0 && tabs.length > 0 && products.length > 0) {
      const productsSubTypeRef = products.reduce(
        (acc, val) => ({ ...acc, [val.id]: val.subType }),
        {}
      );
      const ordersForReporting = composeUsableTabAndOrder(
        orders,
        tabs,
        productsSubTypeRef
      );
      const now = new Date();
      getData(ordersForReporting);
      setLastUpdated(now);
    }
  }, [orders, tabs, products]);

  const salesChartOptions = {
    options: {
      tooltip: {
        enabled: false,
      },
      chart: {
        id: "basic-bar",
        dropShadow: {
          enabled: true,
          color: "#fff",
          top: 5,
          left: 0,
          blur: 10,
          opacity: 0.5,
        },
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: sortHours(
          Object.keys(salesGraph).map((hour) => parseInt(hour)),
          new Date().getHours()
        ).map((hour) => convertHourToAmPm(String(hour))), // ["8 am", "10 am", "12 pm", "2 pm", "4 pm","6 pm", "8 pm"],
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#fff",
          },
          format: "h tt",
        },
        crosshairs: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#fff",
          },
        },
        crosshairs: {
          show: false,
        },
      },
      fill: {
        colors: ["none"],
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      stroke: {
        width: [5, 1],
        colors: ["#fff"],
        curve: "smooth" as const,
        lineCap: "round" as const,
        dashArray: [0, 3],
      },
    },

    series: [
      {
        name: "series-1",
        data: Object.keys(salesGraph).map((data) =>
          Math.round(salesGraph[data])
        ), // [30, 40, 45, 50, 49, 60, 70]
      },
      {
        name: "Total Visits",
        data: Object.keys(lastWeekSalesGraph).map((data) =>
          Math.round(lastWeekSalesGraph[data])
        ), // [20, 50, 45, 30, 40, 30, 25]
      },
    ],
  };

  const deliveryAndTakeoutsChartOptions = {
    options: {
      tooltip: {
        enabled: false,
      },
      chart: {
        id: "basic-bar",
        dropShadow: {
          enabled: true,
          color: "#fff",
          top: 5,
          left: 0,
          blur: 10,
          opacity: 0.5,
        },
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: sortHours(
          Object.keys(salesGraph).map((hour) => parseInt(hour)),
          new Date().getHours()
        ).map((hour) => convertHourToAmPm(String(hour))), // ["8 am", "10 am", "12 pm", "2 pm", "4 pm","6 pm", "8 pm"],
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#fff",
          },
          format: "h tt",
        },
        crosshairs: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#fff",
          },
        },
        crosshairs: {
          show: false,
        },
      },
      fill: {
        colors: ["none"],
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      stroke: {
        width: [5, 1],
        colors: ["#fff"],
        curve: "smooth" as const,
        lineCap: "round" as const,
        dashArray: [0, 3],
      },
    },

    series: [
      {
        name: "series-1",
        data:
          selectedView === "$"
            ? Object.keys(deliverySalesGraph).map(
              (data) => deliverySalesGraph[data]
            )
            : Object.keys(deliveryCountGraph).map(
              (data) => deliveryCountGraph[data]
            ),
      },
      {
        name: "Total Visits",
        data:
          selectedView === "$"
            ? Object.keys(deliveryLastWeekSalesGraph).map(
              (data) => deliveryLastWeekSalesGraph[data]
            )
            : Object.keys(deliveryLastWeekCountGraph).map(
              (data) => deliveryLastWeekCountGraph[data]
            ),
      },
    ],
  };

  // on page load (ie first time login check if autogenerated password exist - if it does it means they need to change passwords)
  useEffect(() => {
    const checkTempPassord = async () => {
      const tempPassowrdExists = await fetchAccountTempPassword(
        business.accountId
      );
      if (tempPassowrdExists) {
        console.log("temp password exists");
        setResetPasswordModal(true);
      } else {
      }
    };

    if (business) {
      checkTempPassord();
    }
  }, [business]);

  useEffect(() => {
    const timeout = setInterval(() => {
      setLastUpdatedMessage(moment(lastUpdated).fromNow());
    }, 60 * 1000);

    return () => clearInterval(timeout);
  }, [lastUpdated]);

  const handleSubmitPassword = () => {
    if (!password || !passwordConfirm) {
      return; // do nothing
    }
    // @ts-ignore
    if (!password.length >= 6) {
      return alert("Your passwords must be at least 6 characters.");
    }
    if (password !== passwordConfirm) {
      return alert("Your passwords do not match.");
    }

    const setNewPassword = async () => {
      const success = await updateCurrentUserPassword(password);
      if (success) {
        console.log("success", success);
        setResetPasswordModal(false);
      } else {
        return alert("There was a problem updating your password");
      }
    };
    setNewPassword();
  };

  const convertCelsiusToFahrenheit = (degree: number | undefined) => {
    if (degree) {
      return Math.round((degree * (9 / 5) + 32) * 100) / 100;
    }
    return 0;
  };
  useEffect(() => {
    const fetchWeatherData = async (
      lat: number,
      long: number,
      getCountry: boolean
    ) => {
      //get forecast could be useful?
      // const response = await axios.get('https://api.openweathermap.org/data/2.5/onecall?lat=' + lat + '&lon=' + long + '&exclude=minutely,daily&appid=1ff2141311b9292e9339ecc0a3919050&units=metric')

      const response = await axios.get(
        "https://api.openweathermap.org/data/2.5/weather?lat=" +
        lat +
        "&lon=" +
        long +
        "&appid=1ff2141311b9292e9339ecc0a3919050&units=metric"
      );

      //the "name" is not accurate - so just use the city in the business location which we aready have
      response.data.name = business.location.address.substring(
        business.location.address.indexOf(",") + 1
      );
      response.data.name = response.data.name.split(",")[0];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data = response.data;
      console.log("Weather data: ", business.location, lat, long, data);
      const country =
        data && data.sys && data.sys.country ? data.sys.country : null;
      const weatherIcon =
        data && data.weather && data.weather.length > 0 && data.weather[0].icon
          ? "http://openweathermap.org/img/wn/" + data.weather[0].icon + ".png"
          : null;

      if (country && getCountry) {
        setCountry(country);
      }

      setWeatherData(data);
      setIconData(weatherIcon);
      // console.log('Icn: ', weatherIcon)
    };

    if (business && business.location) {
      const lat = business.location.lat || 0;
      const lon = business.location.lon || 0;
      const country = business.location.country;
      if (country) {
        setCountry(country);
        fetchWeatherData(lat, lon, false)
          .then((r) => console.log(r))
          .catch((e) => console.log("error", e));
      } else {
        fetchWeatherData(lat, lon, true)
          .then((r) => console.log(r))
          .catch((e) => console.log("error", e));
      }
    }
  }, [business]);

  const selectLocationModalRef = useRef(null);

  if (staff && !!staff.corporateAccess && !emulatorMode) {
    navigate("/enterprise");
  }

  if (business && staff) {
    return (
      <div id="dashboard-container">
        <SwitchLocationOverlay
          visible={switchLocationVisiblity}
          setVisibility={setSwitchLocationVisiblity}
        />
        {/* POPUP FOR SET NEW PASSWORD */}
        <Dialog
          isOpen={resetPasswordModal}
          icon="info-sign"
          onClose={() => setResetPasswordModal(false)}
          style={{ borderRadius: "21.76px", fontFamily: "SFProText-Semibold" }}
        >
          <div>
            <div
              style={{
                marginLeft: "60px",
                marginTop: "28px",
                marginRight: "20px",
              }}
            >
              <h1
                style={{
                  fontSize: "20px",
                  fontFamily: "SFProText-Semibold",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Welcome to Tango, {business.owner.firstName}!
                <br />
                Let’s quickly reset your password{" "}
              </h1>
              <br />
              <span
                style={{ fontSize: "14px", fontFamily: "SFProText-Semibold" }}
              >
                {" "}
                Enter a new password, 6 characters or longer
              </span>
              <br />
              <span
                style={{ fontSize: "14px", fontFamily: "SFProText-Regular" }}
              >
                {" "}
                You can always change this later if you need to
              </span>
              <br />
              <br />
            </div>
            <div style={{ textAlign: "center" }}>
              <InputGroup
                onChange={(e: any) => setPassword(e.target.value)}
                type="password"
                required
                large
                id="password"
                placeholder="Password"
                style={{
                  width: "387px",
                  fontSize: "12px",
                  borderRadius: "5px",
                }}
              />
              <br />

              <InputGroup
                onChange={(e: any) => setPasswordConfirm(e.target.value)}
                type="password"
                required
                large
                id="passwordConfirm"
                placeholder="Re-Type Password"
                style={{
                  width: "387px",
                  fontSize: "12px",
                  borderRadius: "5px",
                }}
              />
              <br />
              <br />

              <Button
                onClick={() => handleSubmitPassword()}
                minimal
                large
                style={{
                  color: "white",
                  borderRadius: "5px",
                  width: "387px",
                  backgroundColor: "#61C554",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Done
              </Button>
              <br />
              <br />
            </div>
          </div>
        </Dialog>

        <div className="app-name">
          <img src={require("../../assets/tango-icon.svg").default} />
        </div>
        <Button
          onClick={async () => {
            await firebase.auth().signOut();
            navigate("/signIn");
          }}
          rightIcon={<Icon icon="arrow-right" color="#CFD2E2" />}
          text="Logout"
          className="logout-button"
        />

        <motion.div ref={selectLocationModalRef}>
          <Box
            className="header"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: 18 }}
          >
            <motion.div
              whileTap={{ scale: 0.85 }}
              onClick={() => setSwitchLocationVisiblity(true)}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                zIndex: 999,
              }}
            >
              <div className="business-name">{business.businessName}</div>
              <img
                style={{ marginLeft: 15, marginTop: 3 }}
                src={SwitchBusinessPolygonSvg}
              />
            </motion.div>
            <div className="date" style={{ zIndex: 999 }}>
              {moment(today).format("dddd, MMMM Do YYYY")}
            </div>
          </Box>
        </motion.div>

        <Box
          className="bottom-dock-container"
          display="flex"
          justifyContent="center"
        >
          <Box className="bottom-dock" display="flex" flexDirection="row">
            <Tooltip2
              className="doc-tooltip"
              content="Food & Bev"
              intent="success"
              placement="top"
              usePortal={false}
            >
              <ClickableWraper>
                <Link to="/products">
                  <img
                    src={
                      require("assets/new_dock_panel/Products & Menus.svg")
                        .default
                    }
                    alt="doc icon"
                  />
                </Link>
              </ClickableWraper>
            </Tooltip2>
            <Tooltip2
              className="doc-tooltip"
              content="Reports"
              intent="success"
              placement="top"
              usePortal={false}
            >
              <ClickableWraper>
                <Link to="/core-reports">
                  <img
                    src={require("assets/new_dock_panel/Reporting.svg").default}
                    alt="doc icon"
                  />
                </Link>
              </ClickableWraper>
            </Tooltip2>
            {/* <Tooltip2
              className="doc-tooltip"
              content="Launchpad"
              intent="success"
              placement="top"
              usePortal={false}
            >
              <ClickableWraper onClick={() => launchpad.setVisibility(true)}>
                <img
                  src={require("assets/launchpad/launch-pad.svg").default}
                  alt="doc icon"
                />
              </ClickableWraper>
            </Tooltip2> */}
            <Tooltip2
              className="doc-tooltip"
              content="People"
              intent="success"
              placement="top"
              usePortal={false}
            >
              <ClickableWraper>
                <Link to="/manager/team">
                  <img
                    src={require("assets/new_dock_panel/People.svg").default}
                    alt="doc icon"
                  />
                </Link>
              </ClickableWraper>
            </Tooltip2>
            <Tooltip2
              className="doc-tooltip"
              content="Customers"
              intent="success"
              placement="top"
              usePortal={false}
            >
              <ClickableWraper>
                <Link to="/payments">
                  <img
                    src={require("assets/new_dock_panel/Customers.svg").default}
                    alt="doc icon"
                  />
                </Link>
              </ClickableWraper>
            </Tooltip2>



            <Tooltip2
              className="doc-tooltip"
              content="Store Setup"
              intent="success"
              placement="top"
              usePortal={false}
            >
              <ClickableWraper>
                <Link to="/device-setup">
                  <img
                    src={
                      require("assets/new_dock_panel/Device Setup.svg").default
                    }
                    alt="doc icon"
                  />
                </Link>
              </ClickableWraper>
            </Tooltip2>
            <Tooltip2
              className="doc-tooltip"
              content="Productivity"
              intent="success"
              placement="top"
              usePortal={false}
            >
              <ClickableWraper>
                <Link to="/documents">
                  <img
                    src={
                      require("assets/new_dock_panel/Productivity.svg").default
                    }
                    alt="doc icon"
                  />
                </Link>
              </ClickableWraper>
            </Tooltip2>

          </Box>
        </Box>
        <button className="roadmap-button" onClick={() => window.open('https://tangohq.notion.site/a342950649aa4e39b4b386a1d8c05290?v=eed4c8c9901d44159396afb15759c95d', '_blank')}>
          Tango Product Roadmap
        </button>
      </div>
    );
  }

  return <Spinner />;
};
export default DashBoard;
