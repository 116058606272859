import React, { useCallback, useMemo } from "react";
import _ from "lodash";
import moment from "moment";

import "../style.css";
import GenericReportGenerator, { ReportGenArgs } from "./generic";
import {
    fetchInventoryReport,
    fetchInvoicesReport,
    fetchMenuEngineeringReport,
    fetchSalesMixByProductReport,
    fetchSalesMixByStoreReport,
    fetchTheoreticalUsageReport,
    fetchTop25Bottom10Report,
    fetchUsageCountsReport,
    fetchUsageValuesReport,
    fetchWasteReport
} from "controllers/inventoryReports";

const useThisMonth = () => useMemo(
    () => {
        const endDate = new Date();
        const startDate = moment(endDate).subtract(1, 'month').toDate();
        return { startDate, endDate }
    },
    [],
);

export const WasteReport = () => {
    const { startDate, endDate } = useThisMonth();
    const generate = useCallback((args: ReportGenArgs) =>
        fetchWasteReport(
            args.businessId,
            args.startDate as number,
            args.endDate as number,
        ),
        [],
    );
    return <GenericReportGenerator
        startDate={startDate}
        endDate={endDate}
        title="Daily Waste Report"
        description="Generate a detailed report of waste in a date range"
        onSubmit={generate}
        useDates
    />
};

export const UsageValuesReport = () => {
    const { startDate, endDate } = useThisMonth();
    const generate = useCallback((args: ReportGenArgs) =>
        fetchUsageValuesReport(
            args.businessId,
            args.startDate as number,
            args.endDate as number,
        ),
        [],
    );
    return <GenericReportGenerator
        startDate={startDate}
        endDate={endDate}
        title="Usage Values Report"
        description="Generate a detailed report of item use in a date range"
        onSubmit={generate}
        useDates
    />
};

export const UsageCountsReport = () => {
    const { startDate, endDate } = useThisMonth();
    const generate = useCallback((args: ReportGenArgs) =>
        fetchUsageCountsReport(
            args.businessId,
            args.startDate as number,
            args.endDate as number,
        ),
        [],
    );
    return <GenericReportGenerator
        startDate={startDate}
        endDate={endDate}
        title="Usage Counts Report"
        description="Generate a high level report of item use in a date range"
        onSubmit={generate}
        useDates
    />
};

export const TheoreticalUsageReport = () => {
    const { startDate, endDate } = useThisMonth();
    const generate = useCallback((args: ReportGenArgs) =>
        fetchTheoreticalUsageReport(
            args.businessId,
            args.startDate as number,
            args.endDate as number,
        ),
        [],
    );
    return <GenericReportGenerator
        startDate={startDate}
        endDate={endDate}
        title="Theoretical Usage Report"
        description="Generate a detailed report of theoretical item use in a date range"
        onSubmit={generate}
        useDates
    />
};

// TODO: select top, bottom, or both
export const VarianceReport = () => {
    const { startDate, endDate } = useThisMonth();
    const generate = useCallback((args: ReportGenArgs) =>
        fetchTop25Bottom10Report(
            args.businessId,
            args.startDate as number,
            args.endDate as number,
        ),
        [],
    );
    return <GenericReportGenerator
        startDate={startDate}
        endDate={endDate}
        title="Top 25 / Bottom 10"
        description="See the items with the highest and lowest variance in a date range"
        onSubmit={generate}
        useDates
    />
};
export const InventoryReport = () => {
    const generate = useCallback((args: ReportGenArgs) =>
        fetchInventoryReport(
            args.businessId,
        ),
        [],
    );
    return <GenericReportGenerator
        title="Inventory Items"
        description="Generate a summary of your item costs"
        onSubmit={generate}
    />
};

export const SalesMixByStore = () => {
    const { startDate, endDate } = useThisMonth();
    const generate = useCallback((args: ReportGenArgs) =>
        fetchSalesMixByStoreReport(
            args.businessId,
            args.startDate as number,
            args.endDate as number,
        ),
        [],
    );
    return <GenericReportGenerator
        startDate={startDate}
        endDate={endDate}
        title="Sales Mix by Store"
        description="Generate a high level overview of your daily sales and costs"
        onSubmit={generate}
        useDates
    />
};

export const SalesMixByProduct = () => {
    const { startDate, endDate } = useThisMonth();
    const generate = useCallback((args: ReportGenArgs) =>
        fetchSalesMixByProductReport(
            args.businessId,
            args.startDate as number,
            args.endDate as number,
        ),
        [],
    );
    return <GenericReportGenerator
        startDate={startDate}
        endDate={endDate}
        title="Sales Mix by Product"
        description="Generate a detailed report of your sales and costs"
        onSubmit={generate}
        useDates
    />
};

export const MenuEngineering = () => {
    const { startDate, endDate } = useThisMonth();
    const generate = useCallback((args: ReportGenArgs) =>
        fetchMenuEngineeringReport(
            args.businessId,
            args.startDate as number,
            args.endDate as number,
        ),
        [],
    );
    return <GenericReportGenerator
        startDate={startDate}
        endDate={endDate}
        title="Menu Engineering"
        description="Generate a report of the profitability and popularity of your menu items"
        onSubmit={generate}
        useDates
    />
};

export const Invoices = () => {
    const { startDate, endDate } = useThisMonth();
    const generate = useCallback((args: ReportGenArgs) =>
        fetchInvoicesReport(
            args.businessId,
            args.startDate as number,
            args.endDate as number,
        ),
        [],
    );
    return <GenericReportGenerator
        startDate={startDate}
        endDate={endDate}
        title="Invoices"
        description="Generate an overview of your spending on raw items"
        onSubmit={generate}
        useDates
    />
};
