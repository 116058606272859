import React, { useState } from 'react';
import { GiftCardProps } from '../useGiftCards';

const useDetails = () => {

    const [updatedGiftCard, setUpdateGiftCard] = useState<GiftCardProps | null>(null);
    const [showBackButton, setShowBackButton] = useState(true);
    const [loading, setLoading] = useState(false);

    return {
        setUpdateGiftCard,
        updatedGiftCard,
        setShowBackButton,
        showBackButton,
        setLoading,
        loading
    }
}
export default useDetails;