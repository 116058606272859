export const headerRoutes = [
  {
    label: "Scheduling",
    link: "/manager/scheduling",
  },
  {
    label: "Team",
    link: "/manager/team",
  },
  {
    label: "Daily Logs",
    link: "/manager/daily-logs",
  },
  {
    label: "Hiring",
    link: "/manager/application-tracker",
  },

  {
    label: "Payroll",
    link: "/manager/payroll",
  },

  {
    label: "Payouts",
    link: "/manager/payouts",
  },
];
