import UIComponents from "@tangopay/tango-ui-library";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Header from "components/Header";

import Editor from "../../../components/TextEditor";
import Sidebar from "../Sidebar";
import { headerRoutes } from "../routes";
import useMenuItemDetail from "./useModifierDetail";
import HorizontalTable from "components/Table/HorizontalTable";

const { Icon, Button } = UIComponents;
const ModifierDetail = () => {
  const navigate = useNavigate();
  const loc = useLocation();
  const itemId = useMemo(() => {
    const split = loc.pathname.split("/");
    return split[split.length - 1];
  }, [loc]);
  const {
    item,
    detailsColumns,
    detailsInstructions,

    ingredients,
    isEditingIngredients,
    setIsEditingIngredients,
    addIngredient,
    ingredientsColumns,
    ingredientsInstructions,
    saveIngredientsChanges,
    deleteIngredients,

    recipe,
    isEditingRecipe,
    setIsEditingRecipe,
    saveRecipeChanges,
  } = useMenuItemDetail(itemId);
  const [editedRecipe, setEditedRecipe] = useState(recipe);
  useEffect(() => {
    setEditedRecipe(recipe);
  }, [recipe]);
  const submitRecipe = useCallback(() => {
    saveRecipeChanges(editedRecipe);
  }, [editedRecipe, setIsEditingRecipe]);
  const clearRecipe = useCallback(() => {
    setEditedRecipe(recipe);
    setIsEditingRecipe(false);
  }, [recipe, setIsEditingRecipe]);
  const itemDetailList = useMemo(() => item ? [item] : [], [item]);
  const taggables = useMemo(() => {
    return [];
  }, []);
  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header
        routes={headerRoutes}
        selectedRoute={"/inventory/items/basic"}
      />
      <div className="flex h-full">
        <div
          className={
            "border-r border-grey-1 border-solid border-t-0 border-b-0"
          }
          style={{ width: "10%" }}
        >
          <Sidebar />
        </div>

        <div style={{ width: "90%" }} className={"bg-off-white"}>
          <div
            className={
              "flex p-8 justify-between items-center bg-white border-b border-grey-1"
            }
            style={{ borderBottomStyle: "solid" }}
          >
            <div className={"flex items-center"}>
              <Icon
                name={"arrow-left"}
                size={"20"}
                className={"cursor-pointer"}
                onClick={() => navigate(-1)}
              />
              <div className={"text-big text-black font-lato-black ml-10"}>
                {item?.optionName}
              </div>
            </div>
            {/* <div className={"flex items-center"}>
              <Button label={"Print"} type={"btn-style-2"} size={"btn-large"} />
            </div> */}
          </div>
          <div className={"flex p-8"}>
            <div className={"w-4/12"}>
              <HorizontalTable
                isVertical
                title="Details"
                isEditing={false}
                data={itemDetailList}
                instructions={detailsInstructions}
                columns={detailsColumns}
              />
            </div>
            <div className={"w-8/12 pl-8"}>
              <div className="bg-white border border-grey border-b-0 border-solid rounded-2xl">
                <HorizontalTable
                  title="Recipe"
                  isEditing={isEditingIngredients}
                  setEditing={setIsEditingIngredients}
                  data={ingredients}
                  instructions={ingredientsInstructions}
                  columns={ingredientsColumns}
                  primaryActionHandler={addIngredient}
                  primaryActionLabel="Add ingredient"
                  deleteIds={deleteIngredients}
                  saveResults={saveIngredientsChanges}
                />
              </div>
              <div className="bg-white border border-grey border-b-0 border-solid rounded-2xl mt-6">
                <div
                  className="border-b border-b-grey border-b-solid flex flex-row p-6"
                >
                  <div
                    className="flex-1 font-lato-black text-xl"
                  >
                    Instructions
                  </div>
                  {
                    !isEditingRecipe && (
                      <Button
                        type="btn-style-1"
                        label="Edit"
                        size="btn-medium"
                        onClick={() => setIsEditingRecipe(true)}
                      />
                    )
                  }
                  {
                    !!isEditingRecipe && (<>
                      <Button
                        type="btn-style-minimal"
                        label="Cancel"
                        size="btn-medium"
                        onClick={clearRecipe}
                      />
                      <Button
                        type="btn-style-1"
                        label="Save"
                        size="btn-medium"
                        onClick={submitRecipe}
                      />
                    </>)
                  }
                </div>
                <Editor
                  initialValue={recipe ?? ""}
                  placeholder="Add instructions..."
                  taggables={taggables}
                  readOnly={!isEditingRecipe}
                  onChange={setEditedRecipe}
                  minimal
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifierDetail;
