import React, { useCallback, useState } from "react";
import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";
import Sidebar from "components/Sidebar";
import { headerRoutes, sidebarRoutes } from "../routes";
import UIComponents from '@tangopay/tango-ui-library';
import useDisputes, { DisputeProps } from "./useDisputes";
import { useNavigate } from "react-router-dom";
import { downloadCSV } from "../../../controllers/payments"

const { TextField, Button } = UIComponents;
const Disputes = () => {
    const {
        businessId,
        data,
        noOp,
        logIt,
        columns,
        instructions,
        isEditing,
        setEditing,
    } = useDisputes();

    const navigate = useNavigate();
    const nav = useCallback((id: string) => {
        const item = data.filter((dispute: DisputeProps) => {
            return dispute.uniqueId === id
        })
        navigate(`/payments/dispute/${id}`, { state: item[0].dispute });
    }, [navigate, data]);

    return (
        <div className="absolute top-[63px] bottom-0 w-full overflow-hidden">
            <Header routes={headerRoutes} selectedRoute={"/inventory/measurements"} selectedPage={{ label: "Customers", value: "/payments" }} />
            <div className="flex h-full">
                <div
                    className={"border-r border-grey-1 border-t-0 border-b-0"}
                    style={{ width: "10%", borderRightStyle: "solid" }}
                >
                    <Sidebar routes={sidebarRoutes} />
                </div>

                <div className={"flex px-10 py-6 overflow-y-auto"} style={{ width: "90%" }}>
                    <HorizontalTable
                        title="Disputes"
                        columns={columns}
                        data={data}
                        instructions={instructions}
                        saveResults={noOp}
                        setEditing={setEditing}
                        isEditing={isEditing}
                        hideEdit
                        onRowClick={nav}
                        customHeaderRightContent={<div className="flex items-center">
                            <Button
                                label="Export"
                                size="btn-medium"
                                type="btn-style-1"
                                onClick={async () => await downloadCSV(businessId, data, 'disputes')}
                            />
                            <TextField
                                className="hori-table-search-input bg-blue-grey-2 border-0"
                                value={''}
                                onChange={() => console.log('yey')}
                                sizeType="medium"
                                placeholder="Search"
                            />
                        </div>}
                    />
                </div>


            </div>
        </div>
    );
};

export default Disputes;
