import React from "react";
import UIComponents from "@tangopay/tango-ui-library";
import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";

import Sidebar from "../Sidebar";
import { headerRoutes, inventorySelectedPage } from "../routes";
import useWaste from "./useWaste";

const { Modal } = UIComponents

const Waste = () => {
  const {
    wasteGroups,
    columns,
    instructions,
    isEditing,
    setEditing,
    openModal,
    closeModal,
    modalOpen,
    addWasteGroup,

    itemColumns,
    itemInstructions,
    wastedItems,
    isEditingItems,
    setEditingItems,
    addItem,
    saveItems,
    selectedDate,
  } = useWaste();
  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header routes={headerRoutes} selectedRoute={"/inventory/items/basic"} selectedPage={inventorySelectedPage} />
      <div className="flex h-full overflow-hidden">
        <div
          className={"border-r border-grey-1 border-t-0 border-b-0 overflow-y-auto"}
          style={{ width: "10%", borderRightStyle: "solid" }}
        >
          <Sidebar />
        </div>

        <div
          className={"flex px-10 py-6 overflow-y-auto"}
          style={{ width: "90%" }}
        >
          <HorizontalTable
            title="Waste"
            columns={columns}
            data={wasteGroups}
            instructions={instructions}
            // saveResults={save}
            setEditing={setEditing}
            isEditing={isEditing}
            primaryActionLabel="New Waste"
            primaryActionHandler={addWasteGroup}
            // deleteIds={deleteVendors}
            onRowClick={openModal}
            hideEdit
          />
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        closeModal={closeModal}
      >
        <HorizontalTable
          title={`Waste - ${selectedDate}`}
          columns={itemColumns}
          data={wastedItems}
          instructions={itemInstructions}
          saveResults={saveItems}
          setEditing={setEditingItems}
          isEditing={isEditingItems}
          primaryActionLabel="New Waste Item"
          primaryActionHandler={addItem}
          // deleteIds={deleteVendors}
          hideCheckboxes
        />
      </Modal>
    </div>
  );
};

export default Waste;
