import React, { useState } from 'react';
import _ from 'lodash'
import CurrencyInput from 'react-currency-input-field';
import UIComponents from '@tangopay/tango-ui-library';
import ActivityIndicator from 'components/ActivityIndicator';
import { formatCentsToDollars, formatCentsToWholeDollars, formatCentValue } from 'controllers/reporting';
import { useSchedulingStatsWidget } from 'hooks/scheduling/stats';
import { ICostScheduleData } from 'hooks/scheduling/stats/useData';
import { Spinner, SpinnerSize } from '@blueprintjs/core';

const { Icon, Modal, Button, Popover } = UIComponents;

type CostWidgetCellProps = {
  cellTitle: string,
  value: number | null,
  margin: string,
  isEditing?: boolean,
  inputData?: number
  updateInputData?: (data: number) => void;
  tbd?: boolean;
  status?: {
    type: "danger" | "success";
    number: number | null;
  },
  hasPopup?: boolean;

}

type CurrencyWidgetCellProps = {
  cellTitle: string,
  value: number | null,
  margin: string,
  isEditing?: boolean,
  inputData?: number
  tbd?: boolean;
  updateInputData?: (data: number) => void;
  status?: {
    type: "danger" | "success";
    number: number | null;
  },
  hasPopup?: boolean;

}
const CostWidgetCell = ({ cellTitle, value, margin, isEditing, inputData, updateInputData, tbd, status, hasPopup = false }: CostWidgetCellProps) => {

  const renderStatus = () => {
    if (status) {
      return (

        <span
          className={
            ` cursor-pointer relative px-2 py-0 bg-green-4 text-white text-tiny font-lato-bold rounded-3xl border border-solid border-error-red`
          }
          style={{
            backgroundColor: status.type === "danger" ? "#F17C75" : "rgba(211, 243, 191, 0.7)",
            border: `1px solid ${status.type === "danger" ? "#E3564D" : "#D3F3BF"}`,
            color: status.type === "danger" ? "#FFFFFF" : "#7AC181"
          }}

        >
          {status.number ?? "--"}%
        </span>
      )
    }
    return null
  }
  if (tbd) {
    return (
      <div className={`${margin} relative`}>
        <div className='font-lato-bold text-grey-2 text-tiny'>{cellTitle}</div>
        <div className='flex justify-between items-center'>
          <span
            className='text-xs text-grey-2 font-lato-bold'
          >
            TBD
          </span>
          {renderStatus()}

        </div>

      </div>
    )
  }
  return (
    <div className={`${margin} relative`}>
      <div className='font-lato-bold text-grey-2 text-tiny'>{cellTitle}</div>
      <div className='flex justify-between items-center'>
        {
          isEditing ?
            (
              <div className='relative'>
                <input type='text'
                  onChange={(e) => {
                    if (updateInputData) {
                      const value = (e.target.value).replace('%', '')
                      updateInputData(parseInt(value))
                    }

                  }}
                  className='w-full py-0 rounded-md px-2 border border-solid border-grey-1 text-xs fotn-lato-regular' value={(inputData || 0) + '%'} />
              </div>
            )
            :
            (
              <span
                className='text-xs font-lato-bold'
                style={{ color: status ? status.type === "danger" ? "#E3564D" : "#7AC181" : "#AAADB6" }}
              >
                {_.isNumber(value) ? `${value.toFixed(2)} ${value ? '%' : ''}` : "--%"}
              </span>
            )
        }

        {renderStatus()}

      </div>
    </div>
  )
}

const CostCurrencyWidgetCell = ({ cellTitle, value, margin, isEditing, inputData, tbd, status, updateInputData, hasPopup = false }: CurrencyWidgetCellProps) => {
  const [showPopup, setShowPopup] = useState(false);

  const renderStatus = () => {
    if (status) {
      return (
        <Popover isOpen={showPopup} content={
          <div className=' shadow-card bg-white p-3 rounded-lg'>
            <div className=' text-tiny text-grey-2 font-lato-medium'>Target LC% - Projected LC%</div>
            <div className=' text-tiny font-lato-medium text-black text-center mt-2'>15.00% - 14.50%</div>
          </div>
        }>
          <span
            className={
              `cursor-pointer px-2 py-0 bg-green-4 text-white text-tiny font-lato-bold rounded-3xl border border-solid border-error-red`
            }
            style={{
              backgroundColor: status.type === "danger" ? "#F17C75" : "rgba(211, 243, 191, 0.7)",
              border: `1px solid ${status.type === "danger" ? "#E3564D" : "#D3F3BF"}`,
              color: status.type === "danger" ? "#FFFFFF" : "#7AC181"
            }}
            onMouseEnter={() => setShowPopup(true)}
            onMouseLeave={() => setShowPopup(false)}
          >
            {status.number ?? "--"}%
          </span>
        </Popover>
      )
    }
    return (
      <span
        className={
          `px-2 py-0  text-white text-tiny font-lato-bold rounded-3xl`
        }></span>
    )
  }

  if (tbd) {
    return (
      <div className={`${margin} relative`}>
        <div className='font-lato-bold text-grey-2 text-tiny'>{cellTitle}</div>
        <div className='flex justify-between items-center'>
          <span
            className='text-xs text-grey-2 font-lato-bold'
          >
            TBD
          </span>
          {renderStatus()}
        </div>

      </div>
    )
  }

  return (
    <div className={`${margin} relative`}>
      <div className='font-lato-bold text-grey-2 text-tiny'>{cellTitle}</div>
      <div className='flex justify-between items-center'>
        {
          isEditing ?
            <div className='relative'>
              <CurrencyInput allowDecimals={false}
                onValueChange={(value) => {
                  if (updateInputData) {
                    updateInputData(parseInt(value ?? ""))
                  }

                }}
                className='w-full py-0 rounded-md px-2 border border-solid border-grey-1 text-xs fotn-lato-regular' value={(inputData)} />
            </div>
            :
            <span
              className=' text-xs font-lato-bold'
              style={{ color: status ? status.type === "danger" ? "#E3564D" : "#7AC181" : "#AAADB6" }}
            >
              {_.isNumber(value) ? formatCentsToDollars(value) : "--$"}
            </span>
        }
        {renderStatus()}
      </div>
    </div>
  )
}
type CostWidgetProps = {
  business: TangoBusiness;
  fohScheduleForAWeekRange: TangoSchedule | undefined;
  bohScheduleForAWeekRange: TangoSchedule | undefined;
}

const CostWidget = ({ business, fohScheduleForAWeekRange, bohScheduleForAWeekRange }: CostWidgetProps) => {
  const {
    stats,
    showBohRoles,
    showFohRoles,
    totalProjectSales,
    totalProjectedFohLCPerc,
    hideActuals,
    setHideActuals,
    widgetFohBohData,
    widgetDollarFohBohData,
    isExpanded,
    setIsExpanded,
    setShowBohRoles,
    setShowFohRoles,
    setSelectedDataType,
    selectedDataType,
    totalProjectedBohLCPerc,
    isEditing,
    setIsEditing,
    inputData,
    updateInputData,
    updateInputDataByDayString,
    updateWidgetData,
    loading,
    meanFohActualsPerc,
    meanBohActualsPerc,
    meanProjectedBohLCPerc,
    meanProjectedFohLCPerc,
    totalBOHActualsCentData,
    totalFOHActualsCentData,
    totalProjectionBOHCentData,
    totalProjectionFOHCentData,
    totalTargetBOHCentData,
    totalTargetFOHCentData,

    totalProjectionFOHStatus,
    totalProjectionBOHStatus,
    totalActualBOHStatus,
    totalActualFOHStatus
  } = useSchedulingStatsWidget()
  const [showFringeModal, setShowFringeModal] = useState(false);

  if (!stats.bohDraftScheduleId || !stats.fohDraftScheduleId) return null


  const renderRolesData = (rolesData: ICostScheduleData['weeklyStatsGroupedByRoles']['boh'] | ICostScheduleData['weeklyStatsGroupedByRoles']['foh']) => {
    return (
      <div>
        {
          rolesData.map((roleData) => {
            const {
              weeklyMeanActualPercentage,
              weeklyMeanProjectedPercentage,
              weeklyTotalActualAmount,
              weeklyTotalProjectedAmount } = roleData;
            return (
              <div className='flex'>
                <div className='flex flex-col flex-1'>
                  <div key={roleData.roleId} className='pt-4 pb-6 px-3 border-b border-t-0 border-l-0 border-r border-solid border-grey-1'>
                    {
                      selectedDataType === '%' ?
                        <>
                          <CostWidgetCell
                            cellTitle={`${roleData.roleTitle} Projected LC%`}
                            value={weeklyMeanProjectedPercentage}
                            margin="mb-6"
                          />
                          {
                            hideActuals ? null :
                              <CostWidgetCell
                                cellTitle={`${roleData.roleTitle} Actual  LC%`}
                                value={weeklyMeanActualPercentage}
                                margin="mb-0"
                              />
                          }

                        </>
                        :
                        <>
                          <CostCurrencyWidgetCell
                            cellTitle={`${roleData.roleTitle} Projected LC $`}
                            value={weeklyTotalProjectedAmount}
                            margin="mb-6"
                          />
                          {
                            hideActuals ? null :
                              <CostCurrencyWidgetCell
                                cellTitle={`${roleData.roleTitle} Actual  LC $`}
                                value={weeklyTotalActualAmount}
                                margin="mb-0"
                              />
                          }

                        </>

                    }


                  </div>
                </div>

                {
                  Object.keys(roleData.daysData).map((dayName) => {
                    const { projections, actuals } = roleData.daysData[dayName]
                    return (
                      <div className='flex flex-col flex-1'>
                        <div key={dayName} className="pt-4 pb-6 px-3 border-b border-t-0 border-l-0 border-r-0 border-solid border-grey-1">
                          <>
                            {selectedDataType === "%" ? (
                              <>
                                <CostWidgetCell
                                  cellTitle={roleData.roleTitle + ' Projected LC%'}
                                  value={projections.percentage || 0}
                                  margin={"mb-6"}
                                />
                                {hideActuals ? null : (
                                  <CostWidgetCell
                                    tbd={!actuals.percentage}
                                    cellTitle={roleData.roleTitle + ' Actual LC%'}
                                    value={actuals.percentage}
                                    margin={"mb-0"}
                                  />
                                )}

                              </>
                            ) : (
                              <>
                                <CostCurrencyWidgetCell
                                  cellTitle={roleData.roleTitle + ' Projected LC $'}
                                  value={projections.amount}
                                  margin={"mb-6"}
                                />
                                {hideActuals ? null : (
                                  <CostCurrencyWidgetCell
                                    tbd={!actuals.amount}
                                    cellTitle={roleData.roleTitle + ' Actual LC $'}
                                    value={actuals.amount}
                                    margin={"mb-0"}
                                  />
                                )}

                              </>
                            )}
                          </>
                        </div>
                      </div>
                    )
                  })
                }
              </div>

            )
          })
        }
      </div>
    )
  }
  const renderData = () => {
    return (
      <>
        <div className='flex'>
          <div className='flex flex-1 flex-col border-r border-t-0 border-l-0 border-b-0 border-solid border-grey-1 '>
            <div className={`pt-4 pb-6 px-3 border-b border-t-0 border-l-0 border-r-0 border-solid border-grey-1 pl-4`}>
              <div className='font-lato-bold text-grey-2 text-tiny'>Projected Sales</div>
              <div className='flex justify-between items-center'>

                <span className=' text-xs text-black font-lato-bold'>{formatCentsToDollars(totalProjectSales)}</span>
              </div>
            </div>
          </div>
          {
            stats && stats.dailyProjectedSales ? _.sortBy(_.values(stats.dailyProjectedSales), "dayIndex").map((sales: DailyProjectedSales) => {
              return (
                <div className='flex flex-1 flex-col'>
                  <div className={`pt-4 pb-6 px-3 border-b border-t-0 border-l-0 border-r-0 border-solid border-grey-1 ${sales.dayNameString === "Sunday" ? 'pr-4' : ''}`}>
                    <div className='font-lato-bold text-grey-2 text-tiny'>Projected Sales</div>
                    <div className='flex justify-between items-center'>
                      {
                        isEditing ?
                          <CurrencyInput
                            prefix="$"
                            className='w-full py-0 flex items-center rounded-md px-2 border border-solid border-grey-1 text-xs fotn-lato-regular'
                            value={(formatCentValue(inputData?.find(i => i.day === sales.dayNameString)?.['projectedSales'] || 0))}
                            onValueChange={(value) => {
                              if (updateInputDataByDayString) {
                                // const value = (e.target.value).replace('$', '').replace(',', '')
                                updateInputDataByDayString(sales.dayNameString, 'projectedSales', parseFloat(value ?? "") * 100)
                              }

                            }}
                          />
                          :
                          //@ts-ignore
                          <span className=' text-xs text-grey-3 font-lato-bold'>{formatCentsToDollars(sales.projectedAmount)}</span>
                      }

                    </div>
                  </div>
                </div>
              )
            })
              :
              null
          }
        </div>

        <div className='flex'>
          {selectedDataType === "%" ? (
            <div className='flex flex-1 flex-col border-r border-t-0 border-l-0 border-b-0 border-solid border-grey-1 '>
              <div className={`pt-4 pb-6 px-3 border-b border-t-0 border-l-0 border-r-0 border-solid border-grey-1 pl-4`}>
                <CostWidgetCell
                  cellTitle='Target FOH LC %'
                  value={stats.avgFohTargetLaborCostAsPercentage ?? null}
                  margin="mb-6"
                />
                <CostWidgetCell
                  cellTitle='Projected FOH LC %'
                  value={meanProjectedFohLCPerc}
                  status={totalProjectionFOHStatus}
                  margin="mb-6"
                  hasPopup
                />
                {
                  !hideActuals ?
                    <CostWidgetCell
                      cellTitle='Actual FOH LC %'
                      tbd={!meanFohActualsPerc}
                      value={meanFohActualsPerc}
                      status={totalActualFOHStatus}
                      margin={"m-0"}

                      hasPopup
                    />
                    :
                    null
                }

              </div>
              <div className={`pt-4 pb-6 px-3 pl-4`}>
                <CostWidgetCell
                  cellTitle='Target BOH LC %'
                  value={stats.avgBohTargetLaborCostAsPercentage ?? null}
                  margin="mb-6"
                />
                <CostWidgetCell
                  cellTitle='Projected BOH LC %'
                  value={totalProjectedBohLCPerc / 7}
                  status={totalProjectionBOHStatus}
                  margin="mb-6"
                  hasPopup
                />
                {
                  !hideActuals ?
                    <CostWidgetCell
                      tbd={!meanBohActualsPerc}
                      cellTitle='Actual BOH LC %'
                      value={meanBohActualsPerc}
                      status={totalActualBOHStatus}
                      margin={"m-0"}
                      hasPopup
                    />
                    :
                    null
                }


              </div>
            </div>

          ) : (
            <div className='flex flex-1 flex-col border-r border-t-0 border-l-0 border-b-0 border-solid border-grey-1 '>
              <div className={`pt-4 pb-6 px-3 border-b border-t-0 border-l-0 border-r-0 border-solid border-grey-1 pl-4`}>
                <CostCurrencyWidgetCell
                  cellTitle='Target FOH LC $'
                  value={totalTargetFOHCentData}
                  margin="mb-6"
                />
                <CostCurrencyWidgetCell
                  cellTitle='Projected FOH LC $'
                  value={totalProjectionFOHCentData}
                  status={totalProjectionFOHStatus}
                  margin="mb-6"
                />
                {
                  !hideActuals ?
                    <CostCurrencyWidgetCell
                      cellTitle='Actual FOH LC $'
                      tbd={!totalFOHActualsCentData}
                      status={totalActualFOHStatus}
                      value={totalFOHActualsCentData}
                      margin={"m-0"}
                    />
                    :
                    null
                }

              </div>
              <div className={`pt-4 pb-6 px-3 pl-4`}>
                <CostCurrencyWidgetCell
                  cellTitle='Target BOH LC $'
                  value={totalTargetBOHCentData}
                  margin="mb-6"
                />
                <CostCurrencyWidgetCell
                  cellTitle='Projected BOH LC $'
                  value={totalProjectionBOHCentData}
                  status={totalProjectionBOHStatus}
                  margin="mb-6"
                />
                {
                  !hideActuals ?
                    <CostCurrencyWidgetCell
                      cellTitle='Actual BOH LC $'
                      tbd={!totalBOHActualsCentData}
                      status={totalActualBOHStatus}
                      value={totalBOHActualsCentData}
                      margin={"m-0"}
                    />
                    :
                    null
                }


              </div>
            </div>

          )}
          {
            selectedDataType === "%" ? widgetFohBohData.map((dataColumn, index) => {
              return (
                <div className='flex flex-1 flex-col'>
                  <div className={`pt-4 pb-6 px-3 border-b border-t-0 border-l-0 border-r-0 border-solid border-grey-1  ${index === 6 ? 'pr-4' : ''}`}>
                    {
                      dataColumn.fohData.filter((rowData) => hideActuals && rowData.title.includes('Actual') ? false : true).map((rowData, rowIndex) => {
                        return (
                          <CostWidgetCell
                            tbd={rowData.tbd}
                            status={rowData.status}
                            cellTitle={rowData.title}
                            value={rowData.value}
                            margin={rowIndex === 2 ? "m-0" : "mb-6"}
                            isEditing={rowData.title.includes('Target') ? isEditing : false}
                            inputData={inputData[index]['fohTargetLc']}
                            updateInputData={(newValue) => updateInputData(index, 'fohTargetLc', newValue)}
                          />
                        )
                      })
                    }
                  </div>
                  <div className={`pt-4 pb-6 px-3 border-b border-t-0 border-l-0 border-r-0 border-solid border-grey-1  ${index === 6 ? 'pr-4' : ''}`}>
                    {
                      dataColumn.bohData.filter((rowData) => hideActuals && rowData.title.includes('Actual') ? false : true).map((rowData, rowIndex) => (
                        <CostWidgetCell
                          tbd={rowData.tbd}
                          status={rowData.status}
                          cellTitle={rowData.title}
                          value={rowData.value}
                          margin={rowIndex === 2 ? "m-0" : "mb-6"}
                          isEditing={rowData.title.includes('Target') ? isEditing : false}
                          inputData={inputData[index]['bohTargetLc']}
                          updateInputData={(newValue) => updateInputData(index, 'bohTargetLc', newValue)}
                        />
                      ))
                    }
                  </div>
                </div>
              )
            }) : widgetDollarFohBohData.map((dataColumn, index) => {
              return (
                <div className='flex flex-1 flex-col'>
                  <div className={`pt-4 pb-6 px-3 border-b border-t-0 border-l-0 border-r-0 border-solid border-grey-1  ${index === 6 ? 'pr-4' : ''}`}>
                    {
                      dataColumn.fohData.filter((rowData) => hideActuals && rowData.title.includes('Actual') ? false : true).map((rowData, rowIndex) => {
                        return (
                          <CostCurrencyWidgetCell
                            tbd={rowData.tbd}
                            cellTitle={rowData.title}
                            value={rowData.value}
                            status={rowData.status}
                            margin={rowIndex === 2 ? "m-0" : "mb-6"}
                            isEditing={rowData.title.includes('Target') && (rowData.title !== "Target BOH LC $" && rowData.title !== "Target FOH LC $") ? isEditing : false}
                            inputData={inputData[index]['fohTargetLc']}
                            updateInputData={(newValue) => updateInputData(index, 'fohTargetLc', newValue)}
                          />
                        )
                      })
                    }
                  </div>
                  <div className={`pt-4 pb-6 px-3 border-b border-t-0 border-l-0 border-r-0 border-solid border-grey-1  ${index === 6 ? 'pr-4' : ''}`}>
                    {
                      dataColumn.bohData.filter((rowData) => hideActuals && rowData.title.includes('Actual') ? false : true).map((rowData, rowIndex) => (
                        <CostCurrencyWidgetCell
                          tbd={rowData.tbd}
                          cellTitle={rowData.title}
                          value={rowData.value}
                          status={rowData.status}
                          margin={rowIndex === 2 ? "m-0" : "mb-6"}
                          isEditing={rowData.title.includes('Target') ? isEditing : false}
                          inputData={inputData[index]['bohTargetLc']}
                          updateInputData={(newValue) => updateInputData(index, 'bohTargetLc', newValue)}
                        />
                      ))
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </>
    )
  }
  if (isExpanded) {
    return (
      <div className='relative flex flex-col shadow-card bg-white' style={{ maxHeight: "400px", overflowY: "auto" }} >
        <div className='flex px-4 py-3 border-b border-t-0 border-l-0 border-r-0 border-solid border-grey-1'>
          {
            !isEditing ?
              <div onClick={() => setIsEditing(true)} className='font-lato-bold text-xs cursor-pointer text-white rounded-full bg-black py-1 px-5 flex items-center'>
                Edit Input
              </div>
              :
              <div className='flex items-center'>
                <div className=' text-xs font-lato-bold text-black mr-4 cursor-pointer' onClick={() => setIsEditing(false)}>Cancel</div>
                <div onClick={async () => {
                  if (fohScheduleForAWeekRange && bohScheduleForAWeekRange) {
                    await updateWidgetData(business.id, fohScheduleForAWeekRange.id, bohScheduleForAWeekRange.id)

                  }

                }} className='font-lato-bold text-xs cursor-pointer text-white rounded-full bg-black py-1 px-5 flex items-center'>
                  Save Changes
                </div>
                {
                  loading ?
                    <ActivityIndicator className='h-6 w-6 ml-4' />
                    :
                    null
                }

              </div>

          }

          <div className='h-6 w-px bg-grey-1 mx-4' />
          <div className='cursor-pointer flex items-center font-lato-bold text-xs text-black rounded-full border-grey-1 border border-solid py-1 px-5'
            onClick={() => setHideActuals(!hideActuals)}
          >
            {hideActuals ? 'Show Actuals' : "Hide Actuals"}
          </div>
          {
            !isEditing ?
              <>
                <div className='h-6 w-px bg-grey-1 mx-4' />
                <div
                  className={`flex items-center cursor-pointer font-lato-bold text-xs text-black rounded-full ${showFohRoles ? 'border-black ' : 'border-grey-1 '} border border-solid py-1 px-5`}
                  onClick={() => setShowFohRoles(!showFohRoles)}
                >
                  FOH Roles
                  {
                    showFohRoles ? <Icon name='cross' className='ml-4 cursor-pointer' size='16' /> : null
                  }

                </div>
                <div className='h-6 w-px bg-grey-1 mx-4' />
                <div
                  className={`flex items-center cursor-pointer font-lato-bold text-xs text-black rounded-full ${showBohRoles ? 'border-black ' : 'border-grey-1 '} border-grey-1 border border-solid py-1 px-5`}
                  onClick={() => setShowBohRoles(!showBohRoles)}
                >
                  BOH Roles
                  {
                    showBohRoles ? <Icon name='cross' className='ml-4 cursor-pointer' size='16' /> : null
                  }
                </div>

                {/* <div className='h-6 w-px bg-grey-1 mx-4' /> */}
                {/* <div className='flex items-center font-lato-bold text-xs text-black rounded-full border-grey-1 border border-solid py-1 px-5'>
                  Include Fringe
                </div> */}
                {/* <div className='h-6 w-px bg-grey-1 mx-4' /> */}
                {/* <div
                  className=' cursor-pointer flex items-center font-lato-bold text-xs text-black rounded-full border-grey-1 border border-solid py-1 px-5'
                  onClick={() => setShowFringeModal(true)}
                >
                  Set Fringe%
                </div> */}
                <div className='h-6 w-px bg-grey-1 mx-4' />
                <div className='bg-blue-grey-2 text-xs font-lato-medium items-center flex p-1 rounded-full justify-center'>
                  <span className={`w-5 h-5 cursor-pointer flex justify-center items-center rounded-full ${selectedDataType === '%' ? 'bg-blue-grey-4' : ''}`}
                    onClick={() => setSelectedDataType('%')}
                  >
                    %
                  </span>
                  <span className={`w-5 h-5 cursor-pointer flex justify-center items-center rounded-full ${selectedDataType === '$' ? 'bg-blue-grey-4' : ''}`}
                    onClick={() => setSelectedDataType('$')}
                  >
                    $
                  </span>
                </div>
                <div className='h-6 w-px bg-grey-1 mx-4' />
                <div >
                  {stats.actualSalesDataLoading ? (
                    <div className='flex items-center align-center  font-lato-bold text-xs text-black h-full'>Actuals Loading <Spinner className="ml-3" size={15} /></div>
                  ) : (

                    <div
                      className='font-lato-bold text-xs cursor-pointer text-white rounded-full bg-black py-1 px-5 flex items-center'
                      onClick={stats.refetchActualSalesData}
                    >
                      Refresh Actuals
                    </div>
                  )}
                </div>
              </>
              :
              null
          }

        </div>
        <div className='flex items-center border-b border-t-0 border-l-0 border-r-0 border-solid border-grey-1'>
          <div className='font-lato-bold  text-xs text-black flex flex-1 flex-col border-r border-b-0 border-t-0 border-l-0  border-solid border-grey-1'>
            <span className='pl-4 p-3'>Week</span>
          </div>
          {
            ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day, index) => (
              <div key={day} className={`font-lato-bold text-xs text-black flex flex-col flex-1 border-r border-t-0 border-l-0  border-solid border-white`}>
                <span className={`${index === 6 ? 'pr-4' : ''} p-3`}> {day}</span>
              </div>
            ))
          }
        </div>
        {!showFohRoles && !showBohRoles ? renderData() : null}
        {
          showFohRoles ? renderRolesData(stats.weeklyStatsGroupedByRoles.foh) : null
        }
        {
          showBohRoles ? renderRolesData(stats.weeklyStatsGroupedByRoles.boh) : null
        }
        <Icon name="chevron-down" className='absolute right-3 top-2 cursor-pointer' onClick={() => setIsExpanded(false)} />
        <Modal isOpen={showFringeModal} closeModal={() => setShowFringeModal(false)} width="404">
          <div className=' text-2xl text-black font-lato-black'>
            Set Fringe %
          </div>
          <div className='w-36 border border-solid border-grey-1 relative py-3 px-6 rounded-full mt-10 mx-auto'>
            <input type="text" className='border-0 w-full text-2xl text-black font-lato-regular' value={"0.25"} />
            <span className='absolute top-3 right-6 text-2xl text-black font-lato-regular'>%</span>
          </div>
          <div className='mt-9 flex'>
            <Button label='Cancel' onClick={() => setShowFringeModal(false)} className='w-full mr-4 justify-center' type="btn-style-1" size="btn-medium" />
            <Button label='Save' className='w-full justify-center' type="btn-style-1" size="btn-medium" />
          </div>
        </Modal>
      </div>
    )
  }
  return (
    <div className='right-0 flex shadow-card bg-white relative' onClick={() => setIsExpanded(true)}>
      <div className='flex flex-1 flex-col border-r border-t-0 border-l-0 border-b-0 border-solid border-grey-1 '>
        <div className={`pt-4 pb-6 px-3 border-b border-t-0 border-l-0 border-r-0 border-solid border-grey-1 pl-4`}>
          <CostWidgetCell
            cellTitle={'Projected FOH LC %'}
            value={meanProjectedFohLCPerc}
            status={totalProjectionFOHStatus}
            margin={"mb-0"}
          />

        </div>
        <div className={`pt-4 pb-6 px-3 pl-4`}>
          <CostWidgetCell
            cellTitle={'Projected BOH LC %'}
            value={meanProjectedBohLCPerc}
            status={totalProjectionBOHStatus}
            margin={"mb-0"}
          />
        </div>
      </div>
      {

        widgetFohBohData.map((dataColumn, index) => {
          return (
            <div className='flex flex-1 flex-col'>
              <div className={`pt-4 pb-6 px-3 border-b border-t-0 border-l-0 border-r-0 border-solid border-grey-1  ${index === 6 ? 'pr-4' : ''}`}>
                {
                  dataColumn.fohData.filter((rowData) => rowData.title.includes('Projected')).map((rowData, rowIndex) => {
                    return (
                      <CostWidgetCell
                        tbd={rowData.tbd}
                        status={rowData.status}
                        cellTitle={rowData.title}
                        value={rowData.value}
                        margin={"m-0"}
                      />
                    )
                  })
                }
              </div>
              <div className={`pt-4 pb-6 px-3 border-b border-t-0 border-l-0 border-r-0 border-solid border-grey-1  ${index === 6 ? 'pr-4' : ''}`}>
                {
                  dataColumn.bohData.filter((rowData) => rowData.title.includes('Projected')).map((rowData, rowIndex) => (
                    <CostWidgetCell
                      tbd={rowData.tbd}
                      status={rowData.status}
                      cellTitle={rowData.title}
                      value={rowData.value}
                      margin={"m-0"}
                    />
                  ))
                }
              </div>
            </div>
          )
        })
      }
      <Icon name="chevron-up" className='absolute right-1 top-1 cursor-pointer' />

    </div>
  )
};

export default CostWidget;