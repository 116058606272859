import { Alert, Spinner } from "@blueprintjs/core";
import moment from "moment";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { ScheduleEventType } from "controllers/schedule";

import Box from "components/Box";

import ActionModal from "./ActionModal";
import AssignedPersonModal from "./AssignedPersonModal";
import EditShiftModal from "./EditShiftModal";
import EditSingleShiftModal from "./EditSingleShiftModal";
import RequestModal from "./RequestModal";
import { ShiftDetailsView } from "./ShiftDetailsView";
import ShiftStudio from "./ShiftStudio";
import TempModal from "./TempModal";
import TradeRequestModal from "./TradeRequestModal";
import { WeeklyScheduleView } from "./WeeklyView";
import { RequestsView } from "./WeeklyView/RequestsView";
import { WeeklyShifts } from "./WeeklyView/WeeklyShifts";
import "./style.css";
import { useScheduling } from "./viewModel/scheduling";
import AssignShiftModal from "./WeeklyView/AssignShiftModal";
import TradeRequests from "./Requests/TradeRequests";
import Availabilities from "./Requests/Availabilities";
import TimeOffRequests from "./Requests/TimeOffRequests";
import DropRequests from "./Requests/DropRequests";
import Settings from "./Settings";
import CoverRequests from "./Requests/CoverRequests";

const Staffing = () => {
  const {
    user,
    schedules,
    fellowStaffMembers,
    business,
    businessSettings,
    scheduleEvents,
    fixedSchedules,
    onEditShiftsDelete,
    onEditShiftsSave,
    onCalendarDateClick,
    jobFunctions,
    scheduleStatus,
    filteredStaffMembersAvailability,
    duplicatedScheduleForAWeekRange,
    scheduleForWeekRange,
    schedulesWithLocalSchedule,
    selectedDepartment,
    generateAvailabilitiesForADay,
    weekRange,
    selectedRequestId,
    setSelectedRequestId,
    selectedRequest,
    setSelectedRequest,
    selectedTradeOrCoverRequestId,
    setSelectedTradeOrCoverRequestId,
    selectedTradeOrCoverRequest,
    setSelectedTradeOrCoverRequest,
    weeklyScheduleViewType,
    setWeeklyScheduleViewType,
    departmentScheduleViewType,
    setDepartmentScheduleViewType,
    setShowSingleDay,
    weeklyStaffMemberViewFilterAnchorEl,
    setWeeklyStaffMemberViewFilterAnchorEl,
    availabilityViewFilterAnchorEl,
    setAvailabilityViewFilterAnchorEl,
    roleFilterId,
    setRoleFilterId,
    availabilityRoleFilterId,
    setAvailabilityRoleFilterId,
    showActionModal,
    setShowActionModal,
    actionModalTitle,
    showEditShiftModal,
    setShowEditShiftModal,
    shiftsToEdit,
    showAssignedPersonModal,
    setShowAssignedPersonModal,
    showTempModal,
    setShowTempModal,
    setShowAssignStaffModalContent,
    publishScheduleLoading,
    changeWeekAlertOpen,
    setChangeWeekAlertOpen,
    selectedShiftForEdit,
    setSelectedShiftForEdit,
    onFullNameInputChangeDebounced,
    onAvailabilityFullNameInputChangeDebounced,
    staffMemberNameFilter,
    draftsWithDepartmentForAWeek,
    bohDraftStatus,
    fohDraftStatus,
    mergedScheduleForAWeekRange,
    fohScheduleForAWeekRange,
    bohScheduleForAWeekRange,
    currentDateForDailyShiftView,
    setCurrentDateForDailyShiftView,
    generateWeekRangeForSelectedDate

  } = useScheduling();

  if (!business) {
    return <Spinner />;
  }

  const renderChangeWeekAlert = () => (
    <Alert
      confirmButtonText="Okay"
      isOpen={changeWeekAlertOpen}
      onClose={() => setChangeWeekAlertOpen(false)}
    >
      <p>
        You have an unpublished schedule, please publish your changes before
        switching to a different week
      </p>
    </Alert>
  );



  const staffingWeeklyView = (
    isSelectRequestType: boolean | undefined = false,
    isAvailabilityView: boolean | undefined = false
  ) => {
    return (
      <WeeklyScheduleView
        mergedScheduleForAWeekRange={mergedScheduleForAWeekRange}
        weekRange={weekRange}
        selectedRequestType={undefined}
        isSelectRequestType={isSelectRequestType}
        isAvailabilityView={isAvailabilityView}
        onCalendarDateClick={onCalendarDateClick}
        business={business}
        schedulesWithLocalSchedule={schedulesWithLocalSchedule}
        fixedSchedules={fixedSchedules}
        schedules={schedules}
        scheduleEvents={scheduleEvents}
        setAvailabilityViewFilterAnchorEl={setAvailabilityViewFilterAnchorEl}
        availabilityViewFilterAnchorEl={availabilityViewFilterAnchorEl}
        availabilityRoleFilterId={availabilityRoleFilterId}
        businessSettings={businessSettings}
        jobFunctions={jobFunctions}
        onAvailabilityFullNameInputChangeDebounced={
          onAvailabilityFullNameInputChangeDebounced
        }
        setAvailabilityRoleFilterId={setAvailabilityRoleFilterId}
        weeklyScheduleViewType={weeklyScheduleViewType}
        setWeeklyScheduleViewType={setWeeklyScheduleViewType}
        departmentScheduleViewType={departmentScheduleViewType}
        setDepartmentScheduleViewType={setDepartmentScheduleViewType}
        scheduleStatus={scheduleStatus}
        publishScheduleLoading={publishScheduleLoading}
        scheduleForWeekRange={scheduleForWeekRange}
        duplicatedScheduleForAWeekRange={duplicatedScheduleForAWeekRange}
        setWeeklyStaffMemberViewFilterAnchorEl={
          setWeeklyStaffMemberViewFilterAnchorEl
        }
        weeklyStaffMemberViewFilterAnchorEl={
          weeklyStaffMemberViewFilterAnchorEl
        }
        onFullNameInputChangeDebounced={onFullNameInputChangeDebounced}
        roleFilterId={roleFilterId}
        setRoleFilterId={setRoleFilterId}
        filteredStaffMembersAvailability={filteredStaffMembersAvailability}
        generateAvailabilitiesForADay={generateAvailabilitiesForADay}
        selectedDepartment={selectedDepartment}
        setShowSingleDay={setShowSingleDay}
        staffMemberNameFilter={staffMemberNameFilter}
        fellowStaffMembers={fellowStaffMembers}
        setSelectedShiftForEdit={setSelectedShiftForEdit}
        draftsWithDepartmentForAWeek={draftsWithDepartmentForAWeek}
        bohDraftStatus={bohDraftStatus}
        fohDraftStatus={fohDraftStatus}
        fohScheduleForAWeekRange={fohScheduleForAWeekRange}
        bohScheduleForAWeekRange={bohScheduleForAWeekRange}
        currentDateForDailyShiftView={currentDateForDailyShiftView}
        setCurrentDateForDailyShiftView={(date) => setCurrentDateForDailyShiftView(date)}
      />
    );
  };

  const createScheduleView = () => (
    <>
      <Box
        display="flex"
        flex={0.2}
        flexDirection="column"
        style={{ overflowY: "auto", overflowX: "hidden" }}
      >
        <ShiftStudio
          weekRange={weekRange}
          scheduleForWeekRange={
            scheduleForWeekRange || duplicatedScheduleForAWeekRange
          }
        />
      </Box>
      <Box display="flex" flex={0.8} style={{ overflowY: "auto" }}>
        {/* {weekRange.map((item: any) => (
          <Box className="day-column" key={new Date(item).toString()}>
            <Box className="day-header">
              <div className="day-name">{moment(item).format("ddd")}</div>
              <div className="day-date">{moment(item).format("D")}</div>
            </Box>
            <WeeklyShifts
              dayOfWeek={item}
              weekRange={weekRange}
              schedulesWithLocalSchedule={schedulesWithLocalSchedule}
              business={business}
              fixedSchedules={fixedSchedules}
              selectedDepartment={selectedDepartment}
              departmentScheduleViewType={departmentScheduleViewType}
              businessSettings={businessSettings}
              setShowSingleDay={setShowSingleDay}
              jobFunctions={jobFunctions}
            />
          </Box>
        ))} */}
      </Box>
    </>
  );
  if (businessSettings && business && user) {
    return (
      <>
        <Box
          display="flex"
          flexDirection="row"
          flex={1}
          className="absolute top-[75px] bottom-0 w-full overflow-hidden"
        >
          <Routes>
            <Route index element={staffingWeeklyView()} />
            {/* <Route
              path="select-request-type"
              element={staffingWeeklyView(true)}
            /> */}
            <Route
              path="select-request-type/availability"
              element={<Availabilities
                weekRange={weekRange}
                business={business}
                schedulesWithLocalSchedule={schedulesWithLocalSchedule}
                fixedSchedules={fixedSchedules}
                schedules={schedules}
                scheduleEvents={scheduleEvents}
              />}
            />
            <Route
              path="select-request-type/time-off-requests/*"
              element={(
                <TimeOffRequests
                  weekRange={weekRange}
                  business={business}
                  schedulesWithLocalSchedule={schedulesWithLocalSchedule}
                  fixedSchedules={fixedSchedules}
                  schedules={schedules}
                  scheduleEvents={scheduleEvents}
                />
              )}
            />
            <Route
              path="select-request-type/drop-requests"
              element={<DropRequests
                weekRange={weekRange}
                business={business}
                schedulesWithLocalSchedule={schedulesWithLocalSchedule}
                fixedSchedules={fixedSchedules}
                schedules={schedules}
                scheduleEvents={scheduleEvents}
              />}
            />
            <Route
              path="settings"
              element={<Settings
                weekRange={weekRange}
                business={business}
                schedulesWithLocalSchedule={schedulesWithLocalSchedule}
                fixedSchedules={fixedSchedules}
                schedules={schedules}
                scheduleEvents={scheduleEvents}
              />}
            />
            <Route
              path="select-request-type/trade-requests"
              element={<TradeRequests
                weekRange={weekRange}
                business={business}
                schedulesWithLocalSchedule={schedulesWithLocalSchedule}
                fixedSchedules={fixedSchedules}
                schedules={schedules}
                scheduleEvents={scheduleEvents}
              />}
            />
            <Route
              path="select-request-type/cover-requests"
              element={<CoverRequests
                weekRange={weekRange}
                business={business}
                schedulesWithLocalSchedule={schedulesWithLocalSchedule}
                fixedSchedules={fixedSchedules}
                schedules={schedules}
                scheduleEvents={scheduleEvents}
              />}
            />
            <Route path="create-schedule" element={createScheduleView()} />
            <Route
              path={`daily-schedule/:shiftDateString`}
              element={
                <>
                  <ShiftDetailsView
                    schedulesWithLocalSchedule={schedulesWithLocalSchedule}
                    weekRange={weekRange}
                    departmentScheduleViewType={departmentScheduleViewType}
                    setDepartmentScheduleViewType={
                      setDepartmentScheduleViewType
                    }
                    duplicatedScheduleForAWeekRange={
                      duplicatedScheduleForAWeekRange
                    }
                    scheduleForWeekRange={scheduleForWeekRange}
                    setSelectedShiftForEdit={setSelectedShiftForEdit}
                    jobFunctions={jobFunctions}
                    generateWeekRangeForSelectedDate={generateWeekRangeForSelectedDate}
                  />
                </>
              }
            />
            <Route path="*" element={<Navigate to="/manager/scheduling" />} />
          </Routes>
        </Box>

        <RequestModal
          requestId={selectedRequestId}
          request={(scheduleEvents || []).find(
            (s) => s.id === selectedRequestId
          )}
          showAssignStaffModal={(shiftId: string, scheduleId: string) => {
            setShowAssignStaffModalContent({ shiftId, scheduleId });
          }}
          selectedRequest={selectedRequest}
          closeModal={() => {
            setSelectedRequestId(null);
          }}
        />
        <ActionModal
          title={actionModalTitle}
          isOpen={showActionModal}
          closeModal={() => setShowActionModal(false)}
        />
        <EditShiftModal
          shifts={shiftsToEdit}
          isOpen={showEditShiftModal}
          onSave={onEditShiftsSave}
          onDelete={onEditShiftsDelete}
          closeModal={() => setShowEditShiftModal(false)}
        />
        <AssignedPersonModal
          isOpen={showAssignedPersonModal}
          closeModal={() => {
            setShowAssignedPersonModal(false);
          }}
        />
        <TempModal
          isOpen={showTempModal}
          closeModal={() => setShowTempModal(false)}
        />
        {/* <EditSingleShiftModal
          mergedScheduleForAWeekRange={mergedScheduleForAWeekRange}
          shift={selectedShiftForEdit}
          schedule={
            scheduleForWeekRange || duplicatedScheduleForAWeekRange || null
          }
          closeModal={() => setSelectedShiftForEdit(null)}
        /> */}
        <AssignShiftModal
          schedule={
            scheduleForWeekRange || duplicatedScheduleForAWeekRange || null
          }
          mergedScheduleForAWeekRange={mergedScheduleForAWeekRange}

          shift={selectedShiftForEdit}
          closeModal={() => setSelectedShiftForEdit(null)}

        />
        <TradeRequestModal
          requestId={selectedTradeOrCoverRequestId}
          request={(scheduleEvents || []).find(
            (s) => s.id === selectedTradeOrCoverRequestId
          )}
          selectedTradeOrCoverRequest={selectedTradeOrCoverRequest}
          showAssignStaffModal={(shiftId: string, scheduleId: string) => {
            setShowAssignStaffModalContent({ shiftId, scheduleId });
          }}
          closeModal={() => {
            setSelectedTradeOrCoverRequestId(null);
            setSelectedTradeOrCoverRequest(null);
          }}
        />
        {renderChangeWeekAlert()}
      </>
    );
  }
  return <Spinner />;
};

export default Staffing;
