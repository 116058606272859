import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import UIComponents from "@tangopay/tango-ui-library";
import moment from 'moment';
import { useSelector } from "react-redux";
import { RootState } from 'model/store';
import Creatable from 'react-select/creatable';
import { components } from 'react-select';
import { v4 as uuidv4 } from "uuid";
import firebase from 'config/firebase'

import {

  approverTimeOffRequestsSelector,
  getStaffMembersThatHaveBeenApprovedForTimeOffDuringShfit,
  staffMemberIsAvailableToWorkTheShiftWithDetails,
} from "model/selectors/scheduleEvents";
import { createShiftType } from 'controllers/staff';
import { ComposeShiftType } from './useCreateShift';
import { composeAdditionalShiftsFromShiftStarters } from 'controllers/schedule';

const { Modal, Button, Dropdown, TimePickerInput, Popover, Checkbox, Icon } = UIComponents;
const colors = [
  {
    backgroundColor: "#FDF2F9",
    mainColor: "#F4D7E7",
    name: "pink",
  },
  {
    backgroundColor: "#FAEDDF",
    mainColor: "#F2DEC1",
    name: "orange",
  },
  {
    backgroundColor: "#FAF7E0",
    mainColor: "#F6EEAA",
    name: "yellow",
  },
  {
    backgroundColor: "#F6FEF1",
    mainColor: "#D3F3BF",
    name: "green",
  },
  {
    backgroundColor: "#ECF5F8",
    mainColor: "#C8E8F3",
    name: "blue",
  },
  {
    backgroundColor: "#F4F4FE",
    mainColor: "#DEDEF5",
    name: "purple",
  },
];
interface Props {
  staffId: string;
  closeModal: () => void;
  selectedDate: Date | null;
  schedule: TangoSchedule | null;
  mergedScheduleForAWeekRange: TangoSchedule | undefined;
  weekRange: Date[];
  jobFunctions: TangoBusinessSettings['jobFunctions'];
  fohScheduleForAWeekRange: TangoSchedule | undefined;
  bohScheduleForAWeekRange: TangoSchedule | undefined;
}
const AddNewShiftForStaffModal = ({ staffId, closeModal, selectedDate, mergedScheduleForAWeekRange, schedule, weekRange, jobFunctions, fohScheduleForAWeekRange, bohScheduleForAWeekRange }: Props) => {
  const [startTime, setStartTime] = useState('9:00');
  const [endTime, setEndTime] = useState('18:00');
  const [notes, setNotes] = useState('');
  const [selectedRole, setSelectedRole] = useState<{ label: string, value: string } | null>(null);
  const [showShiftTypeDropdown, setShowShiftTypeDropdown] = useState(false);
  const selectRef = useRef<Creatable<{ value: string, label: string, color: string, borderColor: string }>>(null);
  const [shiftType, setShiftType] = useState<{ value: string, label: string, color: string, borderColor: string } | null>(null);
  const [shiftTypeColor, setShiftTypeColor] = useState<{
    backgroundColor: string;
    mainColor: string;
    name: string;
  }>(colors[0]);
  const [showColorPopover, setShowColorPopover] = useState(false);

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );
  const fixedAvailabilities: FixedAvailability[] = useSelector(
    (state: RootState) => state.fixedAvailabilities
  );

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const approvedTimeOffRequests: ScheduleEvent[] = useSelector(
    approverTimeOffRequestsSelector
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  useEffect(() => {
    if (!staffId) {
      setSelectedRole(null)
      setNotes('');
      setShiftType(null);
    }
  }, [staffId])

  const clearState = useCallback(() => {
    setSelectedRole(null)
    setNotes('');
    setShiftType(null);
    setErrorMessage(null);
    setStartTime('9:00');
    setEndTime('18:00');
    setShowShiftTypeDropdown(false);
  }, [
    setSelectedRole,
    setNotes,
    setShiftType,
    setErrorMessage,
  ]);

  const scheduleToUse = useMemo(() => {
    return mergedScheduleForAWeekRange || schedule;
  }, [mergedScheduleForAWeekRange, schedule])

  // const shiftSchedule = useMemo(() => {
  //     return scheduleToUse?.shifts.find(
  //         ({ id }: TangoShift) => id === shift?.id
  //     );

  // }, [scheduleToUse, shift])
  const preComposeShiftFromStarter = useCallback((shiftStarter: ShiftStarter, selectedDay: string): TangoShift => {
    const businessTimezone = business.timezone ?? "America/Toronto"
    const startOfWeek = moment(weekRange[0]);
    const dayConvertedToDate =
      startOfWeek.day() && selectedDay.trim().toLowerCase() === "sunday"
        ? startOfWeek.add(7 - startOfWeek.day(), "days").day(selectedDay)
        : startOfWeek.day(selectedDay);
    const momentStartTime = moment(shiftStarter.startTime, "HH:mm");
    const momentEndTime = moment(shiftStarter.endTime, "HH:mm");
    const startDate = dayConvertedToDate
      .clone()
      .set({
        hour: momentStartTime.get("hour"),
        minute: momentStartTime.get("minute"),
      })
      .toDate();
    let endDate = dayConvertedToDate
      .clone()
      .set({
        hour: momentEndTime.get("hour"),
        minute: momentEndTime.get("minute"),
      })
      .toDate();
    if (endDate <= startDate) {
      endDate = moment(endDate).add(1, "days").toDate();
    }

    const startDateWithTimeZone = moment(startDate);
    startDateWithTimeZone.tz(businessTimezone, true);
    const endDateWithTimeZone = moment(endDate);
    endDateWithTimeZone.tz(businessTimezone, true);
    const shiftType = shiftStarter.shiftTypeId

    return {
      id: uuidv4(),
      position: shiftStarter.position,
      startDate: firebase.firestore.Timestamp.fromDate(startDateWithTimeZone.toDate()),
      endDate: firebase.firestore.Timestamp.fromDate(endDateWithTimeZone.toDate()),
      startTime: shiftStarter.startTime,
      endTime: shiftStarter.endTime,
      staffId: null,
      overTimeRate: 0,
      shiftTypeId: shiftType ?? "",
      onCall: false,
      subRole: null,
    }
  }, [business])
  const staffMember = useMemo(() => fellowStaffMembers.find((sm) => sm?.id === staffId), [fellowStaffMembers, staffId])
  const availableRoles = useMemo(() => {
    if (staffMember && staffMember.payRates) {
      return staffMember.payRates.map((payRate) => ({ value: payRate.roleId || '', label: payRate.role || '' }))
    }
    return [];
  }, [staffMember])
  const shiftTypes: { value: string, label: string, color: string, borderColor: string }[] = (businessSettings?.shiftTypes || []).filter(
    (shiftT) => !shiftT?.deleted
  ).map((item) => {
    return { value: item.id, label: item.name, color: item.backgroundColor, borderColor: item.mainColor }
  });

  const createShift = useCallback(async () => {
    setErrorMessage(null);
    const selectedJobFunction = selectedRole ? jobFunctions[selectedRole.value] : null;
    const draftScheduleId =
      selectedJobFunction && selectedJobFunction.departmentId === "foh"
        ? fohScheduleForAWeekRange?.id
        : bohScheduleForAWeekRange?.id;
    if (staffMember && shiftType && selectedRole && draftScheduleId) {
      const shiftStarter = {
        id: uuidv4(),
        startTime,
        endTime,
        position: selectedRole?.value,
        staffId: staffId,
      }

      const precomposedShift = preComposeShiftFromStarter(
        shiftStarter,
        moment(selectedDate).format("dddd")
      )
      const staffMemberHasTimeOffForTheShift = getStaffMembersThatHaveBeenApprovedForTimeOffDuringShfit(
        [staffMember],
        approvedTimeOffRequests,
        precomposedShift
      )
      if (staffMemberHasTimeOffForTheShift.length) {
        setErrorMessage(`${staffMember.contact.firstName} has approved time-off for this day, so they cannot be scheduled`);
        return;
      }
      const availabilityValidationResult = staffMemberIsAvailableToWorkTheShiftWithDetails(
        staffMember,
        fixedAvailabilities,
        business,
        scheduleToUse,
        precomposedShift,
      )
      if (!availabilityValidationResult.available) {
        setErrorMessage(availabilityValidationResult.message);
        return;
      }
      const data: ComposeShiftType = {
        businessId: business.id,
        weekRange,
        shiftType: shiftType?.value,
        selectedDays: [moment(selectedDate).format("dddd")],
        shiftStarters: [shiftStarter],
        draftScheduleId,
      }


      const res = await composeAdditionalShiftsFromShiftStarters(
        data.shiftStarters,
        data.weekRange,
        data.shiftType,
        data.selectedDays,
        data.draftScheduleId,
        data.businessId,
      )
      clearState()
      closeModal()
    } else {
      setErrorMessage("Please select a role and a shift type")
    }

  }, [closeModal, clearState, staffMember, shiftType, startTime, endTime, fixedAvailabilities, business, scheduleToUse, fohScheduleForAWeekRange, bohScheduleForAWeekRange, jobFunctions, selectedRole])
  return (
    <Modal onRequestClose={() => {
      clearState()
    }} isOpen={Boolean(staffMember)} closeModal={() => {
      closeModal()
      clearState()
    }} width="450" onAfterClose={() => {
      closeModal()
      clearState()
    }}>
      {
        staffMember ?
          <>
            <div className='flex -mt-2 pl-10 items-center pb-6 border-b border-solid border-grey-1 border-l-0 border-r-0 border-t-0 mb-5 -mx-10'>
              <div className=' text-sm text-black font-lato-black '>

                {moment(selectedDate).format("dddd, MMM D")}
              </div>
              <div className='h-6 bg-grey-1 w-px mx-3' />

              <div className=' text-xs font-lato-medium text-grey-2'>
                {`${staffMember.contact.firstName} ${staffMember.contact.lastName}`}
              </div>
            </div>
            {errorMessage ? (
              <div className='text-error-red text-sm font-lato-medium text-center'>{errorMessage}</div>
            ) : null}
            <div className='mb-8'>
              <div className='text-base font-lato-black text-black mb-3'>Role</div>
              <Dropdown
                menuPosition="absolute"
                menuPlacement="bottom"
                value={selectedRole}
                options={availableRoles}
                onChange={(role) => {
                  if (role) {
                    //@ts-ignore
                    setSelectedRole(role)
                  }
                }}
                className="z-100"
                size="medium"
              />
            </div>

            <div className='mb-8'>
              <div className='text-base font-lato-black text-black mb-3'>Shift Type</div>

              <div className='relative' onMouseDown={() => setShowShiftTypeDropdown(true)}
                onBlur={(e) => {
                  setShowShiftTypeDropdown(false)
                }} >
                <Creatable
                  ref={selectRef}
                  options={shiftTypes}
                  value={shiftType}
                  onChange={(item) => {
                    if (item) {
                      setShiftType(item)
                      setShowShiftTypeDropdown(false)
                    }
                  }}
                  onCreateOption={async (inputValue) => {
                    await createShiftType(
                      business.id,
                      inputValue,
                      shiftTypeColor.mainColor,
                      shiftTypeColor.backgroundColor
                    );
                  }}
                  menuIsOpen={showShiftTypeDropdown}

                  styles={{
                    control: (baseStyles, state) => {
                      const { menuIsOpen } = state.selectProps
                      return ({
                        ...baseStyles,
                        border: '0px',
                        boxShadow: 'none',
                        backgroundColor: menuIsOpen ? "#fff" : "#F5F7FB",
                        borderTopLeftRadius: '24px',
                        borderTopRightRadius: '24px',
                        borderBottomLeftRadius: menuIsOpen ? '0px' : '24px',
                        borderBottomRightRadius: menuIsOpen ? '0px' : '24px'
                      })
                    },
                    option: (baseStyles, state) => {
                      return ({
                        ...baseStyles,
                        backgroundColor: state.isFocused ? '#F8FAFD' : 'transparent'
                      })
                    },
                    menu: (baseStyles, state) => ({
                      ...baseStyles,
                      marginTop: '-1px',
                      boxShadow: 'none',
                      borderBottomLeftRadius: '24px',
                      borderBottomRightRadius: '24px',
                      borderTop: '1px solid #DBDDE0',

                    }),
                    container: (baseStyles, state) => {
                      const { menuIsOpen } = state.selectProps;
                      return ({
                        ...baseStyles,
                        zIndex: '5',
                        filter: menuIsOpen ? 'drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.03)) drop-shadow(3px 4px 15px rgba(0, 0, 0, 0.12))' : '',
                      })
                    },
                    valueContainer: (baseStyles, state) => ({
                      padding: '3px 12px'
                    }),
                    input: (baseStyles, state) => {
                      return ({
                        ...baseStyles,
                        //@ts-ignore
                        backgroundColor: state.value.length ? shiftTypeColor.backgroundColor : "transparent",
                        //@ts-ignore
                        borderWidth: state.value.length ? '1px' : "0px",
                        borderColor: shiftTypeColor.mainColor,
                        //@ts-ignore
                        padding: state.value.length ? '4px 16px' : "0px",
                        borderRadius: '100px',
                        borderStyle: "solid",
                      })
                    }
                  }}
                  components={{
                    SingleValue: (props) => {
                      const { label, borderColor, color, value } = props.data

                      return (
                        <components.SingleValue {...props}>
                          <span style={{ backgroundColor: color, border: `1px solid ${borderColor}`, width: 'fit-content' }} className=" flex py-1 px-4 rounded-full font-lato-bold text-xs text-black">{props.data.label}</span>
                        </components.SingleValue>
                      )
                    },
                    Option: (props) => {
                      const { label, borderColor, color, value } = props.data
                      return (
                        <components.Option {...props}>
                          <div className="cursor-pointer">
                            <span style={{ backgroundColor: color, border: `1px solid ${borderColor}`, width: 'fit-content' }} className=" flex py-1 px-4 rounded-full font-lato-bold text-xs text-black">{props.data.label}</span>

                          </div>
                        </components.Option>

                      );
                    },
                    MenuList: (props) => (
                      <div {...props}>
                        <div className='pl-4 pt-4 pb-4 text-veryTiny font-lato-medium text-black'>Select an option or create one</div>
                        {props.children}
                      </div>
                    ),
                    IndicatorSeparator: null,
                    DropdownIndicator: (props) => {
                      const { menuIsOpen } = props.selectProps;
                      return (
                        <Popover isOpen={showColorPopover}
                          reposition={false}
                          positions={["bottom"]}
                          containerClassName="z-[100]"
                          onClickOutside={() => {
                            setShowColorPopover(false)
                            selectRef.current?.focus()
                          }}
                          content={
                            <div className='p-2 bg-white shadow-card rounded'>
                              <Checkbox onChange={() => setShiftTypeColor(colors[0])} checked={shiftTypeColor.name === 'pink' ? true : false} type="large" colorType="pink" className='mr-2' />
                              <Checkbox onChange={() => setShiftTypeColor(colors[1])} checked={shiftTypeColor.name === 'orange' ? true : false} type="large" colorType="orange" className='mr-2' />
                              <Checkbox onChange={() => setShiftTypeColor(colors[2])} checked={shiftTypeColor.name === 'yellow' ? true : false} type="large" colorType="yellow" className='mr-2' />
                              <Checkbox onChange={() => setShiftTypeColor(colors[3])} checked={shiftTypeColor.name === 'green' ? true : false} type="large" colorType="green" className='mr-2' />
                              <Checkbox onChange={() => setShiftTypeColor(colors[4])} checked={shiftTypeColor.name === 'blue' ? true : false} type="large" colorType="blue" className='mr-2' />
                              <Checkbox onChange={() => setShiftTypeColor(colors[5])} checked={shiftTypeColor.name === 'purple' ? true : false} type="large" colorType="purple" />
                            </div>
                          }>
                          <div className='pr-3 pt-2 relative' {...props}>
                            {
                              menuIsOpen ?
                                <Icon name="more" onClick={() => setShowColorPopover(true)} size='16' />
                                :
                                <Icon name="chevron-down" size='16' />

                            }
                          </div>
                        </Popover>
                      )
                    },
                    Control: (props) => {

                      return (
                        <components.Control {...props}  >
                          {props.children}
                        </components.Control>
                      )
                    },
                  }}
                />


              </div>
            </div>
            <div className='flex mb-8'>
              <div className='w-1/2 pr-4'>
                <div className='text-base font-lato-black text-black mb-3'>Start Time</div>
                <TimePickerInput size="large"
                  value={moment(startTime, 'HH:mm').toDate() ?? new Date()}
                  format="h:mm a"
                  onChange={(date) => setStartTime(moment(date).format('HH:mm'))}
                />
              </div>
              <div className='w-1/2 pl-4'>
                <div className='text-base font-lato-black text-black mb-3'>End Time</div>
                <TimePickerInput size="large"
                  value={moment(endTime, 'HH:mm').toDate() ?? new Date()}
                  format="h:mm a"
                  onChange={(date) => setEndTime(moment(date).format('HH:mm'))}
                />
              </div>
            </div>
            <div className='mb-8'>
              <div className='text-base font-lato-black text-black mb-3'>Shift Notes</div>
              <textarea
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
                placeholder='Type your message here...'
                className='w-full border border-solid border-grey-1 rounded-2xl min-h-[80px] p-4 text-sm font-lato-regular text-black'
              />
            </div>
            <div className='mt-9 flex justify-between'>
              <Button label='Cancel' onClick={closeModal} className='w-full justify-center mr-4' type="btn-style-1" size="btn-large" />
              <Button onClick={createShift} label='Create Shift' className='w-full justify-center' type="btn-style-1" size="btn-large" />
            </div>
          </>
          :
          null

      }

    </Modal>
  )
};
export default AddNewShiftForStaffModal;