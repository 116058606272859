import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  // useWasteGroup,
  useWasteGroups,
  useCreateWasteGroup,
  useCreateWastedItem,
} from "controllers/waste";

import {
  useAllItemsInfo,
} from "controllers/inventoryItemInfo";

import {
  ColumnInstruction,
  UpdateState,
} from "components/Table/HorizontalTable";

import { RootState } from "../../../model/store";
import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { newWastedItemPrefix, WastedItem, WasteGroup, itemInstructions } from "./instructions";
import { useUniqueIdList } from "../hooks";
import moment from "moment";

const columns: ColumnInstruction<WasteGroup>[] = [
  { type: "data", header: "Date", attribute: "date", width: 1 },
  { type: "data", header: "Items", attribute: "wastedItemNames", width: 8 },
  // { type: "data", header: "Reason", attribute: "reason", width: 1 },
  { type: "data", header: "Total Cost", attribute: "totalCost", width: 1 },
];
const itemColumns: ColumnInstruction<WastedItem>[] = [
  { type: "data", header: "Item", attribute: "itemName", width: 1 },
  { type: "data", header: "Qty", attribute: "quantity", width: 1 },
  { type: "data", header: "Unit", attribute: "unit", width: 1 },
  { type: "data", header: "Reason", attribute: "reason", width: 1 },
  { type: "data", header: "Unit Cost", attribute: "unitCost", width: 1 },
  { type: "data", header: "Total Cost", attribute: "totalCost", width: 1 },
];

const instructions: { [x: string]: RenderInstruction<WasteGroup> } = {
  totalCost: {
    type: "currency",
    readOnly: true,
  },
  date: {
    type: "numericDate",
    readOnly: true,
  },
  wastedItemNames: {
    type: "default",
    readOnly: true,
  },
};
const newWasteGroupPrefix = "new__waste__group";
const useVendorsState = () => {
  const businessId: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.id;

  const [selectedGroupId, setSelectedGroupId] = useState("");

  const [isEditing, setEditing] = useState(false);
  const [isEditingItems, setEditingItems] = useState(false);

  const {
    addedIds: addedGroupIds,
    clear: clearGroups,
    // remove: removeItems,
  } = useUniqueIdList(newWasteGroupPrefix);
  useEffect(() => {
    if (!isEditing) clearGroups();
  }, [isEditing]);

  const {
    addedIds: addedItemIds,
    push: addItemId,
    clear: clearItems,
    // remove: removeItems,
  } = useUniqueIdList(newWastedItemPrefix);
  const addItem = useCallback(() => {
    setEditingItems(true);
    addItemId();
  }, []);
  useEffect(() => {
    if (!isEditingItems) clearItems();
  }, [isEditingItems]);

  const allItems = useAllItemsInfo(businessId, true);
  const groupsQuery = useWasteGroups(businessId);
  const createGroup = useCreateWasteGroup(businessId);
  const createWastedItem = useCreateWastedItem(businessId);
  const selectedGroup = useMemo(() =>
    groupsQuery.data?.find(i => i.id == selectedGroupId) ?? null
    , [groupsQuery.data, selectedGroupId]);
  // const update = useUpdateVendor(businessId);
  // const create = useCreateVendor(businessId);
  // const deleteVendor = useDeleteVendor(businessId);

  const addWasteGroup = useCallback(() => {
    const today = moment().subtract(4, 'hours');
    // +100 because months are 0 indexed
    const date = (today.year() * 10000) + (today.month() * 100) + 100 + today.date();
    return createGroup.mutateAsync({ date });
  }, [])

  const wasteGroups = useMemo(() => {
    const today = moment().subtract(4, 'hours');
    // +100 because months are 0 indexed
    const date = (today.year() * 10000) + (today.month() * 100) + 100 + today.date();
    const added = addedGroupIds.map((uniqueId) => ({
      uniqueId,
      date,
      reason: "",
      totalCost: 0,
      wastedItemNames: "None",
    }));
    const curr = (
      (groupsQuery.data?.map((wasteGroup) => ({
        uniqueId: wasteGroup.id,
        date: wasteGroup.dateWasted,
        reason: wasteGroup.reason,
        totalCost: wasteGroup.totalCost,
        wastedItemNames: wasteGroup.wastedItems.map(
          i => `${i.wasteAmount} ${i.unit} ${i.itemName}`
        ).join(", ") || "None",
      })) ?? []).sort((a, b) => b.date - a.date)
    );
    return [...added, ...curr];
  }, [groupsQuery.data, addedGroupIds]);

  const wastedItems: WastedItem[] = useMemo(() => {
    return [
      ...addedItemIds.map(i => ({
        uniqueId: i,
        quantity: 0,
        reason: "",
        itemId: null,
        isIngredientUnit: true,
        // isRaw: true,
      })),
      ...(selectedGroup?.wastedItems ?? []).map(i => ({
        uniqueId: i.id,
        quantity: i.wasteAmount,
        reason: i.reason,
        itemName: i.itemName,
        unit: i.unit,
        unitCost: i.unitCost,
        totalCost: i.totalCost,
      }))
    ]
  }, [selectedGroup, addedItemIds])
  const selectedDate = useMemo(() => {
    if (!selectedGroup?.dateWasted) return "";
    const val = selectedGroup.dateWasted.toString() ?? "YYYYMMDD";
    const day = val.substring(6, 8);
    const month = val.substring(4, 6);
    const year = val.substring(0, 4);
    return `${month}/${day}/${year}`;
  }, [selectedGroup])

  const openModal = useCallback((uniqueId: string) => {
    setSelectedGroupId(uniqueId);
  }, []);
  const closeModal = useCallback(() => {
    setSelectedGroupId("");
  }, []);

  const items = allItems?.data ?? [];
  const saveItems = useCallback(
    (updates: UpdateState) => {
      return Promise.all(addedItemIds.map((id) => {
        const u = updates[id];
        const itemId = u.itemId.newValue as string;
        const sel = items.find(i => i.id == itemId);
        if (!sel) return;
        const isRaw = sel.isRaw;
        return createWastedItem.mutateAsync({
          groupId: selectedGroupId,
          itemId,
          isRaw,
          isIngredientUnit: !!u.isIngredientUnit.newValue,
          reason: u.reason.newValue as string,
          unitCount: u.quantity.newValue as number,
        })
      }));
    },
    [addedItemIds, selectedGroupId],
  )

  return {
    wasteGroups,
    columns,
    instructions,
    isEditing,
    setEditing,
    openModal,
    closeModal,
    modalOpen: !!selectedGroup,
    addWasteGroup,

    itemColumns,
    itemInstructions,
    wastedItems,
    isEditingItems,
    setEditingItems,
    addItem,
    saveItems,
    selectedDate,
  };
};
export default useVendorsState;
