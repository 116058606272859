import comps from "@tangopay/tango-ui-library";
import { useSelector } from "react-redux";
import firebase from "firebase";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import "react-quill/dist/quill.snow.css";

import { hashString } from "utils/strings";

import SendIcon from "assets/send-icon.svg";

import TextEditor from "../../../../../components/TextEditor";
import AuthorSelector from "../authorSelector";
import "./style.css";
import { RootState } from "model/store";

const { Button, TextField } = comps;

type MessageType = {
  id: string;
  body: string;
  author: {
    id: string;
    firstName: string;
    lastName: string;
  };
};

type Props = {
  title: string;
  tags: string[];
  originalMessage: MessageType;
  replies: MessageType[];
  people: { id: string; firstName: string; lastName: string }[];
  sendUpdate: (partialMessage: {
    id: string;
    text?: string;
    title?: string;
    deleted?: boolean;
    author?: { id: string; firstName: string; lastName: string };
    replies?: [] | firebase.firestore.FieldValue;
  }) => Promise<unknown>;
};

type MessageProps = {
  people: { id: string; firstName: string; lastName: string }[];
  body: string;
  authorId: string;
  messageId: string;
  className?: string;
  editing?: boolean;
  onSelectAuthor?: (id: string) => unknown;
  onChangeBody?: (newBody: string) => unknown;
};

const Message = ({
  authorId,
  body,
  className,
  editing,
  onChangeBody,
  onSelectAuthor,
  messageId,
  people,
}: MessageProps) => {
  const taggables = useMemo(
    () =>
      people.map(({ id, firstName, lastName }) => ({
        id,
        value: firstName + " " + lastName,
      })),
    [people]
  );
  return (
    <div className={`${className} daily-note-message-container`}>
      <AuthorSelector
        options={people}
        selected={authorId}
        onSelect={onSelectAuthor}
        disabled={!editing}
      />
      <div className="daily-note-message-content">
        {/* if there are no people, we are still loading */}
        {/* taggables cannot change once it is set, because */}
        {/* quill is an elaborate prank */}
        {!!people.length && (
          <TextEditor
            initialValue={body}
            readOnly={!editing}
            onChange={onChangeBody}
            placeholder=""
            taggables={taggables}
            uniqueId={"messageId-" + messageId}
            minimal
            toolbarBottom
          />
        )}
        {/* emoji row */}
      </div>
    </div>
  );
};

const Tile = (props: Props) => {
  const replyCount = props.replies.length;
  const [showReplies, toggleShowReplies] = useReducer((curr) => !curr, false);
  const [replying, setReplying] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editedBody, setEditedBody] = useState(props.originalMessage.body);
  const [editedTitle, setEditedTitle] = useState(props.title);
  const updateEditedTitle = useCallback(
    (evt) => setEditedTitle(evt.target.value),
    []
  );
  const user: StaffMember = useSelector((state: RootState) => state.user)
  const [editedAuthor, setEditedAuthor] = useState(
    props.originalMessage.author.id
  );
  const [reply, setReply] = useState("");

  const deleteNote = useCallback(() => {
    props
      .sendUpdate({
        id: props.originalMessage.id,
        deleted: true,
      })
      .then(() => setEditing(false));
  }, [editedBody, props.originalMessage.id]);

  const sendUpdate = useCallback(() => {
    props
      .sendUpdate({
        id: props.originalMessage.id,
        text: editedBody,
        author: props.people.find(({ id }) => id == editedAuthor),
        title: editedTitle,
      })
      .then(() => setEditing(false));
  }, [editedBody, props.originalMessage.id, editedTitle]);
  const sendReply = useCallback(() => {
    if (user) {
      props
        .sendUpdate({
          id: props.originalMessage.id,
          replies: firebase.firestore.FieldValue.arrayUnion({
            author: props.people.find(({ id }) => id == user.id),
            createdAt: new Date(),
            updatedAt: new Date(),
            text: reply,
          }),
        })
        .then(() => setReplying(false));
    }

  }, [reply, props.originalMessage.id, props.people, user]);

  // used as a key for the editor to force a state reset
  // this is janky but I don't care
  const [editCount, setEditCount] = useState(0);
  const clearEdit = useCallback(() => {
    setEditing(false);
    setEditedBody(props.originalMessage.body);
    setEditedTitle(props.title);
    setEditedAuthor(props.originalMessage.author.id);
    setEditCount(editCount + 1);
  }, [props.originalMessage.body, props.originalMessage.author.id]);

  useEffect(() => {
    if (!replying) setReply("");
  }, [replying]);

  const taggables = useMemo(
    () =>
      props.people.map(({ id, firstName, lastName }) => ({
        id,
        value: firstName + " " + lastName,
      })),
    [props.people]
  );

  return (
    <div className="daily-note-tile">
      <div className="daily-note-tile-header">
        {editing ? (
          <TextField
            sizeType="medium"
            value={editedTitle}
            onChange={updateEditedTitle}
          />
        ) : (
          <div className="daily-note-tile-title">{props.title}</div>
        )}
        <div className="daily-note-tile-tag-container">
          {props.tags.map((tag) => (
            <div
              key={tag}
              className={`daily-note-tag tag-color-${hashString(tag, 6)}`}
            >
              {tag}
            </div>
          ))}
        </div>
        <div className="daily-note-header-divider" />
        <Button
          className="daily-note-action-button"
          type="btn-style-minimal"
          label="Delete"
          size="btn-small"
          onClick={deleteNote}
        />
        {editing && (
          <Button
            className="daily-note-action-button"
            type="btn-style-minimal"
            label="Cancel"
            size="btn-small"
            onClick={clearEdit}
          />
        )}
        {editing && (
          <Button
            className="daily-note-action-button"
            type="btn-style-minimal"
            label="Save"
            size="btn-small"
            onClick={sendUpdate}
          />
        )}
        {!editing && (
          <Button
            className="daily-note-action-button"
            type="btn-style-1"
            label="Edit"
            size="btn-small"
            onClick={() => setEditing(true)}
          />
        )}
        {!editing && (
          <Button
            className="daily-note-action-button"
            type="btn-style-1"
            label="Reply"
            size="btn-small"
            onClick={() => setReplying(true)}
          />
        )}
      </div>
      <Message
        key={editCount}
        body={props.originalMessage.body}
        messageId={props.originalMessage.id}
        className="first-message"
        editing={editing}
        people={props.people}
        authorId={editedAuthor}
        onSelectAuthor={setEditedAuthor}
        onChangeBody={setEditedBody}
      />
      {!!replyCount && (
        <>
          <div className="toggle-replies" onClick={toggleShowReplies}>
            {showReplies ? "HIDE REPLIES" : "SHOW REPLIES"}
          </div>
          {showReplies &&
            props.replies.map((reply, idxUNSAFE) => (
              <Message
                key={reply.author.id + idxUNSAFE}
                body={reply.body}
                className="reply-message"
                // replies are not complete messages and do not have IDs
                messageId={reply.author.id + idxUNSAFE}
                people={props.people}
                authorId={reply.author.id}
              />
            ))}
        </>
      )}
      {!!replying && (
        <div className="new-reply-container">
          <div className="new-reply-editor-container">
            <TextEditor
              initialValue=""
              placeholder="Type a message..."
              taggables={taggables}
              onChange={setReply}
              uniqueId={`reply-to-${props.originalMessage.id}`}
              toolbarBottom
            />
          </div>
          <button className="send-reply-button">
            <img src={SendIcon} onClick={sendReply} />
          </button>
        </div>
      )}
    </div>
  );
};

export default Tile;
