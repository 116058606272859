import { useEffect, useState } from "react";
import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";
import { useSelector } from "react-redux";
import { RootState } from "model/store";
import moment from "moment";
import { useGiftCardsQuery } from "controllers/giftCards";

export interface GiftCardData {
    balance: number;
    businessId: string;
    cardNumber: string;
    createdAt: any;
    deleted: boolean;
    enabled: boolean;
    id: string;
    liveMode: boolean;
    receiverName: string;
    updatedAt: any;
    locationIssued: string;
    customer: any;
    transactions: GiftCardTransactions[];
}

export interface GiftCardTransactions {
    createdAt: any;
    deleted: boolean;
    id: string;
    accountId: string;
    amount: number;
    businessId: string;
    paymentType: string;
    transactionType: string; //send, reload, credit, debit
    updatedAt: any;
}

export type GiftCardProps = {
    uniqueId: string;
    dateIssued: any;
    giftCardNumber: string;
    locationIssued: string;
    balance: string;
    transactions: GiftCardTransactions[];
    customer: string;
};

const columns: ColumnInstruction<GiftCardProps>[] = [
    { type: "projection", header: "Date Issued", attribute: "dateIssued" },
    { type: "data", header: "Gift Card Number", attribute: "giftCardNumber" },
    { type: "data", header: "Location Issued", attribute: "locationIssued" },
    { type: "data", header: "Balance", attribute: "balance" },
    { type: "data", header: "Customer", attribute: "customer" },
];

const instructions: { [x: string]: RenderInstruction<GiftCardProps> } = {};

instructions.dateIssued = {
    type: "complex-custom",
    viewComponent: ({ fullObject }) => {
        return <div>
            {moment(new Date(fullObject.dateIssued._seconds * 1000)).format("MM/DD/YYYY")}
        </div>
    },
    editComponent: null
}

instructions.amount = {
    type: "currency",
}

const useGiftCards = () => {
    const businessId: string = useSelector(
        (state: RootState) => state.business as TangoBusiness
    )?.id;
    const currency: string = useSelector(
        (state: RootState) => state.business as TangoBusiness
    )?.currency;
    const [loading, setLoading] = useState<boolean>(false)
    const [startDate, setStartDate] = useState<any>('')
    const [endDate, setEndDate] = useState<any>('')
    const [isEditing, setEditing] = useState(false);
    const [lastgiftCardId, setLastGiftCardId] = useState('start')
    const [previousPageIds, setPreviousPageIds] = useState(['start'])
    const [pageNumber, setPageNumber] = useState<number>(0)
    const [allGiftCards, setAllGiftCards] = useState<any>([])
    const [displayCounterMessage, setDisplayCounterMessage] = useState<string>('1 - 20')

    const noOp = () => Promise.resolve(true);
    const logIt = (args: string[]) => console.log(args);

    console.log('last gift card id?', lastgiftCardId)
    const query = useGiftCardsQuery(businessId, lastgiftCardId);
    const giftCardsData: GiftCardData[] = query.data ?? [];
    const data: GiftCardProps[] = []
    giftCardsData.length > 0 && giftCardsData.forEach((giftCard: GiftCardData) => {
        data.push({
            uniqueId: giftCard.id,
            dateIssued: giftCard.createdAt,
            giftCardNumber: giftCard.cardNumber,
            locationIssued: giftCard.locationIssued,
            balance: '$' + (giftCard.balance * 0.01).toFixed(2),
            transactions: giftCard.transactions,
            customer: ''//
        })
    })

    return {
        businessId,
        data,
        noOp,
        logIt,
        columns,
        instructions,
        isEditing,
        setEditing,
        setStartDate,
        startDate,
        setEndDate,
        endDate,
        setLastGiftCardId,
        lastgiftCardId,
        setLoading,
        loading,
        setPreviousPageIds,
        previousPageIds,
        setPageNumber,
        pageNumber,
        setAllGiftCards,
        allGiftCards,
        setDisplayCounterMessage,
        displayCounterMessage,

    };
};
export default useGiftCards;
