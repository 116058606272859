import tangoComponents from "@tangopay/tango-ui-library";
import React, { useCallback, useMemo } from "react";
import { Assign } from "utility-types";

import { formatCentValue } from "controllers/reporting";
import { EmployeeDetailsEmploymentInfo, JobRolesData, RoleInfo } from "controllers/team";

import { DataPoint } from "components/Table/GenericCell/TableCell";
import { DEPARTMENTS } from "controllers/staff";

const { Dropdown, TextField } = tangoComponents;

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

type ViewProps = {
  fullObject: EmploymentDetailInstruction;
};
type EditProps = {
  fullObject: EmploymentDetailInstruction;
  onChange?: (newValue: EmploymentDetailInstruction) => unknown;
};

export type EmploymentDetailInstruction = Assign<
  EmployeeDetailsEmploymentInfo,
  {
    uniqueId: string;
    primaryRoleTitle: string;
    secondaryRolesTitles: string;
    businessAvailableRoles: RoleInfo[];
  }
>;

type CurrencyViewProps = {
  value: DataPoint;
};
type CurrencyEditProps = {
  value: DataPoint;
  onChange?: (newValue: DataPoint) => unknown;
};

export const CurrencyViewComponent = ({ value }: CurrencyViewProps) => {
  return <div>{formatter.format(formatCentValue(Number(value)))}</div>;
};
export const CurrencyEditComponent = ({
  value,
  onChange,
}: CurrencyEditProps) => {
  const handleChange = useCallback(
    (e: any) => {
      if (onChange) onChange(Number(e.target.value));
    },
    [onChange]
  );

  return (
    <TextField
      sizeType="small"
      value={Number(value)}
      type="numeric"
      onChange={handleChange}
    />
  );
};

export const SecondaryRolesViewComponent = ({ fullObject }: ViewProps) => {
  const secondaryRoles = fullObject.secondaryRoles;
  const joinedRoles =
    secondaryRoles?.map((role) => role.roleTitle).join(", ") ?? "-";
  return <div>{joinedRoles}</div>;
};
export const SecondaryEditRolesComponent = ({
  fullObject,
  onChange,
}: EditProps) => {
  const handleChange = useCallback(
    (
      newValues: {
        value: string;
        label: string;
      }[]
    ) => {
      console.log("newValues", newValues);
      const newFullObject = { ...fullObject };
      newFullObject.secondaryRoles = newValues.map((value) => {
        return {
          roleId: value.value,
          roleTitle: value.label,
        };
      });
      if (onChange) onChange(newFullObject);
    },
    [onChange, fullObject]
  );

  const options = useMemo(() => {
    return fullObject.businessAvailableRoles
      .filter((sr) => !(fullObject?.primaryRole?.roleId === sr?.roleId))
      .map((role) => ({
        value: role.roleId,
        label: role.roleTitle,
      }));
  }, [fullObject.businessAvailableRoles]);

  const values = useMemo(() => {
    return (
      fullObject.secondaryRoles?.map((role) => ({
        value: role.roleId,
        label: role.roleTitle,
      })) ?? []
    );
  }, [fullObject.secondaryRoles, fullObject?.primaryRole]);

  return (
    <Dropdown
      size="small"
      isMulti
      options={options}
      value={values}
      // @ts-ignore
      onChange={handleChange}
    />
  );
};

export const PrimaryRoleViewComponent = ({ fullObject }: ViewProps) => {
  const primaryRole = fullObject.primaryRole;
  return <div>{primaryRole?.roleTitle ?? "-"}</div>;
};
export const PrimaryRoleEditComponent = ({
  fullObject,
  onChange,
}: EditProps) => {
  const options = useMemo(() => {
    return fullObject.businessAvailableRoles
      .filter(
        (r) => !fullObject.secondaryRoles?.find((sr) => sr.roleId === r.roleId)
      )
      .map((role) => ({
        value: role.roleId,
        label: role.roleTitle,
      }));
  }, [fullObject.businessAvailableRoles, fullObject.secondaryRoles]);

  const value = useMemo(() => {
    return {
      value: fullObject.primaryRole?.roleId ?? "",
      label: fullObject.primaryRole?.roleTitle ?? "",
    };
  }, [fullObject.primaryRole]);

  const handleChange = useCallback(
    (newValue: { value: string; label: string }) => {
      console.log("newValue", newValue);
      const newFullObject = { ...fullObject };
      newFullObject.primaryRole = {
        roleId: newValue.value,
        roleTitle: newValue.label,
      };

      if (onChange) onChange(newFullObject);
    },
    [onChange, fullObject]
  );

  return (
    <Dropdown
      size="small"
      options={options}
      value={value}
      // @ts-ignore
      onChange={handleChange}
    />
  );
};




type PermissionViewProps = {
  fullObject: EmploymentDetailInstruction;
};
type PermissionEditProps = {
  fullObject: EmploymentDetailInstruction;
  onChange?: (newValue: EmploymentDetailInstruction) => unknown;
};

export const PermissionViewComponent = ({ fullObject }: PermissionViewProps) => {
  const permission = fullObject.permission;
  return <div>{permission}</div>;
};
export const PermissionEditComponent = ({
  fullObject,
  onChange,
}: PermissionEditProps) => {
  const options = useMemo(() => {
    return [{
      value: "admin",
      label: "Location Admin",
    },
    {
      value: "basic",
      label: "Basic Employee",
    },]
  }, []);

  const value = useMemo(() => {
    return {
      value: fullObject.permission === "Location Admin" ? "admin" : "basic",
      label: fullObject.permission,
    };
  }, [fullObject.permission]);

  const handleChange = useCallback(
    (newValue: { value: string; label: string }) => {
      const newFullObject = { ...fullObject };
      if (newValue.value === "admin") {
        newFullObject.isAdmin = true;
        newFullObject.permission = newValue.label;
      }
      if (newValue.value === "basic") {
        newFullObject.isAdmin = false;
        newFullObject.permission = newValue.label;
      }
      if (onChange) onChange(newFullObject);
    },
    [onChange, fullObject]
  );

  return (
    <Dropdown
      size="small"
      options={options}
      value={value}
      // @ts-ignore
      onChange={handleChange}
    />
  );
};







type RolesViewProps = {
  fullObject: JobRolesData;
};
type RolesEditProps = {
  fullObject: JobRolesData;
  onChange?: (newValue: JobRolesData) => unknown;
};

export const DepartmentViewComponent = ({ fullObject }: RolesViewProps) => {
  const departmentId = fullObject.departmentId;
  return <div>{departmentId ? DEPARTMENTS[departmentId as DepartmentId]?.title : "-"}</div>;
};
export const DepartmentEditComponent = ({
  fullObject,
  onChange,
}: RolesEditProps) => {
  const options = useMemo(() => {
    return [{
      value: "boh",
      label: "Back of House",
    },
    {
      value: "foh",
      label: "Front of House",
    },]
  }, []);

  const value = useMemo(() => {
    return {
      value: fullObject.departmentId ?? "",
      label: fullObject.departmentTitle ?? "",
    };
  }, [fullObject.departmentId, fullObject.departmentTitle]);

  const handleChange = useCallback(
    (newValue: { value: string; label: string }) => {
      console.log("newValue", newValue);
      const newFullObject = { ...fullObject };
      newFullObject.departmentId = newValue.value;
      newFullObject.departmentTitle = newValue.label;
      if (onChange) onChange(newFullObject);
    },
    [onChange, fullObject]
  );

  return (
    <Dropdown
      size="small"
      options={options}
      value={value}
      // @ts-ignore
      onChange={handleChange}
    />
  );
};
