import { Spinner } from "@blueprintjs/core";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "model/store";

import "../style.css";
import UIComponents from "@tangopay/tango-ui-library";
const { Button, DatePickerInput } = UIComponents;


const numericDate = (d: Date) => {
    const m = moment(d);
    return m.date() + (100 * (1 + m.month())) + (10000 * m.year())
}

export type ReportGenArgs = {
    businessId: string;
    startDate?: number;
    endDate?: number;
    // add filters for item types
}

type Props = {
    title: string;
    description: string;
    useDates?: boolean;
    startDate?: Date;
    endDate?: Date;
    onSubmit: (args: ReportGenArgs) => Promise<Blob>;
}

const GenericReportGenerator = (
    {
        title,
        description,
        useDates,
        startDate: startDateInit,
        endDate: endDateInit,
        onSubmit,
    }: Props,
) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [startDate, setStartDate] = useState(startDateInit ?? null);
    const [endDate, setEndDate] = useState(endDateInit ?? null);
    const business: TangoBusiness = useSelector(
        (state: RootState) => state.business
    );

    const reportGenArgs: ReportGenArgs = useMemo(() => {
        const result: ReportGenArgs = {
            businessId: business?.id,
        };
        if (useDates) {
            if (startDate) {
                result.startDate = numericDate(startDate);
            }
            if (endDate) {
                result.endDate = numericDate(endDate);
            }
        }
        return result;
    }, [business, useDates && startDate, useDates && endDate])

    const createBlob = useCallback(
        async () => {
            setLoading(true);
            try {
                const rawPdf = await onSubmit(reportGenArgs)
                const url = URL.createObjectURL(rawPdf);
                window.open(url, "_blank");
            } catch (e) {
                alert("Opps, something went wrong generating your report");
                console.log("error generating report", e);
            } finally {
                setLoading(false);
            }
        },
        [reportGenArgs, onSubmit],
    );

    return (
        <>
            <div className="w-full">
                <div className="page-header">
                    <div className="page-title">{title}</div>
                    <Button
                        size="btn-large"
                        label="Generate Report"
                        type="btn-style-2"
                        onClick={createBlob}
                        disabled={loading}
                    />
                </div>
                <div>
                    <div>
                        {description}
                    </div>
                </div>
                {
                    useDates && <div className="flex flex-row items-center px-8 py-8 justify-center">
                        <div className="max-w-l px-8">
                            <DatePickerInput
                                size="large"
                                value={startDate as Date}
                                onChange={setStartDate}
                                disabled={loading}
                            />
                        </div>
                        -
                        <div className="max-w-l px-8">
                            <DatePickerInput
                                size="large"
                                value={endDate as Date}
                                onChange={setEndDate}
                                disabled={loading}
                            />
                        </div>
                    </div>
                }
                {loading && <div style={{ marginTop: 20 }}>
                    <Spinner size={40} />
                </div>}
            </div>
        </>
    );
};
export default GenericReportGenerator;
