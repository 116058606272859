import React, { useMemo, useState } from 'react';
import moment from 'moment';
import UIComponents from '@tangopay/tango-ui-library';
const { Popover, Icon } = UIComponents;
import {
  getShiftsForADay,
  groupShiftsByShiftTypeAndTimeFrame,
} from "controllers/schedule";

interface Props {
  shiftType: ShiftType | null | undefined;
  startTime: string;
  endTime: string;
  shifts: TangoShift[];
  staffMembers: StaffMember[];
  openAssignShiftModal: (v: TangoShift | null) => void;
  onOptionSelect: (type: 'view' | 'move' | 'delete') => void;
  staffMemberNameFilter: string
}
const DailyShiftCard = ({ shiftType, startTime, endTime, shifts, staffMembers, openAssignShiftModal, onOptionSelect, staffMemberNameFilter }: Props) => {
  const [showMenu, setShowMenu] = useState(false);
  const filteredShifts = useMemo(() => {
    return shifts.filter((shift) => {
      const staff = staffMembers.find((staff) => shift.staffId === staff.id)
      const staffName = staff ? staff.contact.firstName + ' ' + staff.contact.lastName : "";
      return (staffName.toLowerCase()).search(staffMemberNameFilter.toLowerCase()) > -1
    })
  }, [shifts, staffMemberNameFilter])
  return (
    <div className="flex flex-1 flex-col pt-2">
      <div className="flex justify-between">
        <div className="">
          <div className="text-tiny font-lato-bold text-black uppercase">
            {shiftType?.name}
          </div>
          <div className=" text-tiny font-lato-medium text-grey-2">
            {moment(startTime, "HH:mm").format("hh:mm a")} -{" "}
            {moment(endTime, "HH:mm").format("hh:mm a")}
          </div>
        </div>

        <Popover isOpen={showMenu} positions={["bottom"]} onClickOutside={() => setShowMenu(false)} content={
          <div className="bg-white shadow-card p-1 rounded-xl">
            <ul className="p-0 m-0 list-none cursor-pointer">
              <li className="p-2 text-tiny text-black font-lato-bold" onClick={() => {
                onOptionSelect('view')
                // handleOptionSelect('view')
              }}>View Details</li>
              <li className="p-2 text-tiny text-black font-lato-bold" onClick={() => {
                onOptionSelect('move')
              }}>Move Or Duplicate</li>
              <li className="p-2 text-tiny text-error-red font-lato-bold" onClick={() => {
                onOptionSelect('delete')
              }}>Delete Shift</li>
            </ul>
          </div>
        }>
          <div>
            <Icon name="more" onClick={() => setShowMenu(true)} size="12" className="cursor-pointer mr-1" />

          </div>

        </Popover>
      </div>
      {
        filteredShifts.length ? <div
          className="px-2 pt-2 rounded-md m-1"
          style={{
            backgroundColor: shiftType?.backgroundColor,
          }}>
          {
            filteredShifts.map((shift) => {
              const staffName = staffMembers.find((staff) => shift.staffId === staff.id)

              return (
                <div
                  className={`text-xs font-lato-regular text-black mb-2 cursor-pointer`}
                  style={{
                    textAlign: "left",

                  }}
                  key={shift.id}
                  onClick={() => openAssignShiftModal(shift)}
                >

                  {staffName ? staffName.contact.firstName + ' ' + staffName.contact.lastName : 'Unassigned'}
                </div>
              )
            })
          }
        </div>
          :
          null
      }


    </div>
  )
};
export default DailyShiftCard;