import React, { useCallback, useEffect, useState } from "react";
import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";
import Sidebar from "components/Sidebar";
import { headerRoutes, sidebarRoutes } from "../routes";
import UIComponents from '@tangopay/tango-ui-library';
import usePaymentScreen, { CompletedPaymentProps } from "./usePaymentScreen";
import { Route, Routes, useNavigate } from "react-router-dom";
import ClosedCheckDetail from "../ClosedCheckDetail";
import { downloadCSV, SearchParam, SearchParameters } from "../../../controllers/payments"
const { Button, Icon, Modal, SearchBarDropdown } = UIComponents;

const PaymentsScreen = () => {
    const {
        businessId,
        data,
        noOp,
        columns,
        instructions,
        isEditing,
        setEditing,
        setLastTabId,
        setPreviousPageTabIds,
        previousPageTabIds,
        setPageNumber,
        pageNumber,
        setChunkedPayments,
        chunkedPayments,
        setDisplayCounterMessage,
        displayCounterMessage,
        setSearchBy,
        searchBy,
        setSearchString,
        searchString,
        findInExistingData,
        setFilteredPayments,
        filteredPayments,
        searchFirestore,
        setLoading,
        loading
    } = usePaymentScreen();

    const navigate = useNavigate();

    useEffect(() => {
        if (chunkedPayments.length > 0 && pageNumber > 0) {
            const fullList = chunkedPayments.flat(1)
            const lastStartId = fullList[fullList.length - 1].uniqueId
            const startNumber = fullList.findIndex((payment: CompletedPaymentProps) => payment.uniqueId === lastStartId);
            setDisplayCounterMessage(startNumber + 1 + ' - ' + (fullList.length + data.length))
        } else {
            setDisplayCounterMessage('1 - ' + data.length)
        }
    }, [chunkedPayments, data])

    const nav = useCallback((id: string) => {
        const item = data.filter((completedPayment: CompletedPaymentProps) => {
            return completedPayment.uniqueId === id
        })
        if (item[0] && item[0].completedCheck) {
            navigate(`${item[0].completedCheck.id}`, { state: item[0] });
        }

        const searchResult = filteredPayments.filter((completedPayment: CompletedPaymentProps) => {
            return completedPayment.uniqueId === id
        })
        if (searchResult[0] && searchResult[0].completedCheck) {
            navigate(`${searchResult[0].completedCheck.id}`, { state: searchResult[0] });
        }
    }, [navigate, data]);


    const handleSearchChange = useCallback(
        (evt) => {
            console.log(evt.currentTarget.value)
            setSearchString(evt.currentTarget.value)
        }, []
    );


    useEffect(() => {
        const fetchData = async (businessId: string, searchParam: SearchParam, searchValue: string) => {
            setLoading(true)
            const newData = await searchFirestore(businessId, searchParam, searchValue);
            setFilteredPayments(newData)
            setLoading(false)
        }

        if (searchBy && searchString) {
            let searchParameters: SearchParameters = {
                checkNumber: '',
                last4: '',
                amount: 0
            }

            //@ts-ignore
            if (searchBy === 'Check Number') {
                if (searchString.length >= 3) {
                    searchParameters.checkNumber = searchString
                    const filterData = findInExistingData(searchParameters)
                    if (filterData.length > 0) {
                        setFilteredPayments(filterData)
                    } else {
                        fetchData(businessId, 'check-number', searchString)
                    }
                }
            }
            //@ts-ignore
            if (searchBy === 'Amount') {
                //search for 4 digit amount (including decimal)
                let amountString = searchString.replaceAll('$', '')
                if (searchString.length >= 4 && parseFloat(amountString)) {
                    searchParameters.amount = parseFloat(amountString)
                    const filterData = findInExistingData(searchParameters)
                    if (filterData.length > 0) {
                        setFilteredPayments(filterData)
                    } else {
                        fetchData(businessId, 'amount', amountString)
                    }
                }
            }
            //@ts-ignore
            if (searchBy === 'Last 4') {
                if (searchString.length === 4 && parseInt(searchString)) {
                    searchParameters.last4 = searchString
                    const filterData = findInExistingData(searchParameters)
                    if (filterData.length > 0) {
                        setFilteredPayments(filterData)
                    } else {
                        fetchData(businessId, 'last4', searchString)
                    }
                }
            }

            if (searchString.length <= 2 || !searchBy) {
                setFilteredPayments([])//reset when clearing search
            }
        }
    }, [searchBy, searchString])


    const returnTable = () => {
        return (
            <div className="absolute top-[63px] bottom-0 w-full overflow-hidden">
                <Header routes={headerRoutes} selectedRoute={"/payments"} selectedPage={{ label: "Customers", value: "/payments" }} />
                <div className="flex h-full">
                    <div
                        className={"border-r border-grey-1 border-t-0 border-b-0"}
                        style={{ width: "10%", borderRightStyle: "solid" }}
                    >
                        <Sidebar routes={sidebarRoutes} />
                    </div>


                    <Modal
                        isOpen={loading}
                        closeModal={() => { setLoading(false) }}
                        width={"472"}
                    >
                        <div>
                            <div className={"text-2xl text-black font-lato-black"}>
                                Please wait, getting payments...
                            </div>
                            <div className="flex mt-8 center h-full w-full justify-center content-center items-center">
                                <div className="loading-spinner"></div>
                            </div>
                        </div>
                    </Modal>

                    <div className={"flex px-10 py-6 overflow-y-auto flex-col"} style={{ width: "90%" }}>
                        <HorizontalTable
                            title="Payments"
                            columns={columns}
                            data={filteredPayments.length > 0 ? filteredPayments : data}
                            instructions={instructions}
                            saveResults={noOp}
                            setEditing={setEditing}
                            isEditing={isEditing}
                            hideEdit
                            onRowClick={nav}
                            customHeaderRightContent={<div className="flex items-center">
                                <Button
                                    label="Export"
                                    size="btn-medium"
                                    type="btn-style-1"
                                    onClick={async () => await downloadCSV(businessId, data, 'payments')}
                                    style={{ marginRight: '40px' }}
                                />

                                <SearchBarDropdown
                                    type="medium"
                                    options={["Check Number", "Amount", "Last 4"]}
                                    onOptionSelect={(value: any) => {
                                        setSearchBy(value)
                                    }}
                                    onChange={handleSearchChange}
                                />
                            </div>}
                        />
                        {data.length > 0 && filteredPayments.length < 1 &&
                            <div className="flex justify-between items-center">
                                <div className="mt-7 flex items-center justify-start">
                                    Viewing {displayCounterMessage}
                                </div>
                                <div className="mt-7 flex items-center justify-end">
                                    <Icon name="chevron-left" className=" cursor-pointer" size="20" onClick={() => {
                                        if (pageNumber > 0 && previousPageTabIds.length > 0 && chunkedPayments.length > 0) {
                                            setLastTabId(previousPageTabIds[pageNumber])
                                            setPreviousPageTabIds([
                                                ...previousPageTabIds.slice(0, previousPageTabIds.length - 1),
                                                ...previousPageTabIds.slice(previousPageTabIds.length)
                                            ]);
                                            setChunkedPayments([
                                                ...chunkedPayments.slice(0, chunkedPayments.length - 1),
                                                ...chunkedPayments.slice(chunkedPayments.length)
                                            ]);
                                            setPageNumber(pageNumber - 1)
                                        }
                                    }} />
                                    <Icon name="chevron-right" className="cursor-pointer ml-3" size="20" onClick={() => {
                                        if (!data[data.length - 1].completedCheck) {
                                            return
                                        }

                                        setLastTabId(data[data.length - 1].completedCheck.id)
                                        setChunkedPayments([...chunkedPayments, data])
                                        setPageNumber(pageNumber + 1)
                                        setPreviousPageTabIds([...previousPageTabIds, data[0].completedCheck.id])
                                    }} />
                                </div>
                            </div>
                        }
                    </div>
                </div>

            </div>
        );
    }

    const returnClosedCheck = () => {
        return (
            <ClosedCheckDetail />
        )
    }

    return (
        <div className="bottom-0 w-full overflow-hidden">
            <div className="flex h-full">
                <Routes>
                    <Route index element={returnTable()} />
                    <Route
                        path=":id"
                        element={returnClosedCheck()}
                    />
                </Routes>
            </div>
        </div>
    )

};

export default PaymentsScreen;
