import React from "react";
import UIComponents from "@tangopay/tango-ui-library";
import { DayPickerDay } from "../WeeklyView/DayPickerDay";
import DayPicker from "react-day-picker";
import { ScheduleEventType } from "controllers/schedule";
import { useLocation, useNavigate } from "react-router-dom";
import { useRequestsStats } from "../WeeklyView/useRequestStats";

const { Icon } = UIComponents;
type Props = {
  weekRange: Date[];
  business: TangoBusiness;
  schedulesWithLocalSchedule: TangoSchedule[];
  fixedSchedules: TangoFixedSchedule[];
  schedules: TangoSchedule[];
  scheduleEvents: ScheduleEvent[];
  onDayPress?: (d: Date) => void;

}


const RequestSidebar = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const {
    amountOfPendingAvailabilityRequests,
    amountOfPendingTradeRequests,
    amountOfPendingCoverRequests,
    amountOfPendingDropRequests,
    amountOfPendingTimeOffRequests,
    totalAmountOfPendingRequests,
  } = useRequestsStats()
  return (
    <div
      className='staffing-sidebar flex flex-1 flex-col overflow-y-auto'
      style={{
        width: "20vw",
      }}
    >
      <div className="p-6 flex items-center border-b border-solid border-l-0 border-r-0 border-t-0 border-grey-1" onClick={() => navigate("/manager/scheduling")}>
        <Icon name="chevron-left" size="16" className="mr-2 cursor-pointer" />
        <div className=" text-xs font-lato-medium text-grey-3">View Schedule</div>
      </div>
      {
        pathname === '/manager/scheduling/settings' ?
          <div className="pt-6 border-b border-solid border-l-0 border-r-0 border-t-0 border-grey-1 pb-4">
            <div className="flex justify-between items-center pl-6 pr-4 px-2 py-2 cursor-pointer">
              <div className=" text-xs font-lato-bold text-grey-3">All Requests</div>
            </div>
          </div>
          :
          <div className="pb-4 border-b border-solid border-l-0 border-r-0 border-t-0 border-grey-1">
            <div className="flex justify-between items-center pl-6 pr-4 px-2 py-2 bg-blue-grey-2">
              <div className=" text-xs font-lato-bold text-black flex items-center">All Requests {totalAmountOfPendingRequests ? (<span className="h-5 w-5 flex items-center justify-center bg-error-red rounded-full text-white text-tiny font-lato-bold text-center ml-4">{totalAmountOfPendingRequests}</span>) : null}
              </div>
              <Icon name="chevron-down" size="12" />
            </div>
            <ul className="list-none">
              <li
                onClick={() => navigate('/manager/scheduling/select-request-type/availability')}
                className={` ${pathname === '/manager/scheduling/select-request-type/availability' ? 'font-lato-black text-black' : 'font-lato-medium text-grey-3'}  text-tiny py-2 cursor-pointer flex items-center`}
              >
                Availabilities
                {amountOfPendingAvailabilityRequests ? (
                  <span className="h-5 w-5 flex items-center justify-center bg-error-red rounded-full text-white text-tiny font-lato-bold text-center ml-4">{amountOfPendingAvailabilityRequests}</span>
                ) : null}

              </li>
              <li onClick={() => navigate('/manager/scheduling/select-request-type/drop-requests')} className={`${pathname === '/manager/scheduling/select-request-type/drop-requests' ? 'font-lato-black text-black' : 'font-lato-medium text-grey-3'} text-tiny py-2 cursor-pointer flex items-center `}>
                Drop Requests
                {amountOfPendingDropRequests ? (
                  <span className="h-5 w-5 flex items-center justify-center bg-error-red rounded-full text-white text-tiny font-lato-bold text-center ml-4">{amountOfPendingDropRequests}</span>

                ) : null}
              </li>
              <li onClick={() => navigate('/manager/scheduling/select-request-type/time-off-requests')} className={`${pathname === '/manager/scheduling/select-request-type/time-off-requests' ? 'font-lato-black text-black' : 'font-lato-medium text-grey-3'} text-tiny py-2 cursor-pointer flex items-center`}>Time Off Request
                {amountOfPendingTimeOffRequests ? (
                  <span className="h-5 w-5 flex items-center justify-center bg-error-red rounded-full text-white text-tiny font-lato-bold text-center ml-4">{amountOfPendingTimeOffRequests}</span>

                ) : null}
              </li>
              <li onClick={() => navigate('/manager/scheduling/select-request-type/trade-requests')} className={`${pathname === '/manager/scheduling/select-request-type/trade-requests' ? 'font-lato-black text-black' : 'font-lato-medium text-grey-3'} text-tiny py-2 cursor-pointer flex items-center`}>Trade Requests
                {amountOfPendingTradeRequests ? (
                  <span className="h-5 w-5 flex items-center justify-center bg-error-red rounded-full text-white text-tiny font-lato-bold text-center ml-4">{amountOfPendingTradeRequests}</span>

                ) : null}
              </li>
              <li onClick={() => navigate('/manager/scheduling/select-request-type/cover-requests')} className={`${pathname === '/manager/scheduling/select-request-type/cover-requests' ? 'font-lato-black text-black' : 'font-lato-medium text-grey-3'} text-tiny py-2 cursor-pointer flex items-center`}>Cover Requests
                {amountOfPendingCoverRequests ? (
                  <span className="h-5 w-5 flex items-center justify-center bg-error-red rounded-full text-white text-tiny font-lato-bold text-center ml-4">{amountOfPendingCoverRequests}</span>

                ) : null}
              </li>
            </ul>
          </div>
      }

      <div className="pt-6">
        <div onClick={() => navigate('/manager/scheduling/settings')} className={`${pathname === '/manager/scheduling/settings' ? 'bg-blue-grey-2' : ''} flex justify-between items-center pl-6 pr-4 px-2 py-2 cursor-pointer`}>
          <div className=" text-xs font-lato-bold text-grey-3">Settings</div>
        </div>
      </div>

    </div >
  )
};

export default RequestSidebar;