import React, { useEffect, useMemo, useState } from "react";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import { headerRoutes, sidebarRoutes } from "../routes";
import UIComponents from '@tangopay/tango-ui-library';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useDetails from "./useDetails";
import { RootState } from "model/store";
import { useSelector } from "react-redux";
import moment from "moment";
import { getLifetimeSpend, getLifetimeLoad } from "./utils";
import { GiftCardData, GiftCardProps, GiftCardTransactions } from "../useGiftCards";
import HorizontalTable, { ColumnInstruction } from "components/Table/HorizontalTable";
import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import './index.css'
import { fetchSingleGiftCard } from "controllers/giftCards";

const { Icon, Modal } = UIComponents;

type TransactionHistoryProps = {
    uniqueId: string;
    date: any;
    transactionType: string;
    amount: string;
    payment: string;
};

const columns: ColumnInstruction<TransactionHistoryProps>[] = [
    { type: "projection", header: "Date", attribute: "date" },
    { type: "data", header: "Transaction Type", attribute: "transactionType" },
    { type: "data", header: "Amount", attribute: "amount" },
    { type: "data", header: "Payment", attribute: "payment" },
];

const instructions: { [x: string]: RenderInstruction<TransactionHistoryProps> } = {};

instructions.date = {
    type: "complex-custom",
    viewComponent: ({ fullObject }) => {
        return <div>
            {new Date(fullObject.date._seconds * 1000).toDateString()}
        </div>
    },
    editComponent: null
}

const GiftCardDetails = () => {
    const business: TangoBusiness = useSelector(
        (state: RootState) => state.business as TangoBusiness
    );
    const navigate = useNavigate();
    const params = useParams()
    const loc = useLocation();
    const {
        setUpdateGiftCard,
        updatedGiftCard,
        setShowBackButton,
        showBackButton,
        setLoading,
        loading
    } = useDetails();
    const noOp = () => Promise.resolve(true);
    //if GC is fetched directly from backend use updated check (i.e. if navigating directly to route)
    //else use data passes in loc.state (navigated from table)
    const giftCard: GiftCardProps = updatedGiftCard ? updatedGiftCard : loc.state

    const getGiftCard = async (businessId: string, cardId: string) => {
        setLoading(true)
        const card: GiftCardData = await fetchSingleGiftCard(businessId, cardId)

        const composeUsableCard: GiftCardProps = {
            uniqueId: card.id,
            dateIssued: card.createdAt,
            giftCardNumber: card.cardNumber,
            locationIssued: card.locationIssued,
            balance: (card.balance * 0.01).toFixed(2),
            transactions: card.transactions,
            customer: card.customer
        }
        setUpdateGiftCard(composeUsableCard)
        setShowBackButton(false)
        setLoading(false)
    }

    useEffect(() => {
        if (!giftCard && params.id && business && !updatedGiftCard) {
            getGiftCard(business.id, params.id)
        }
    }, [business, params, giftCard, updatedGiftCard])

    const data: TransactionHistoryProps[] = giftCard && giftCard.transactions.map((transaction: GiftCardTransactions) => {
        return {
            uniqueId: transaction.id,
            date: transaction.createdAt,
            transactionType: transaction.transactionType.charAt(0).toUpperCase() + transaction.transactionType.slice(1),
            amount: '$' + (transaction.amount * 0.01).toFixed(2),
            payment: ''//transaction.id
        }
    })
    //prevents trying to render check while loading
    // will only show set when navigating directly to check screen (ie the url was shared) 
    if (loading) {
        return <Modal
            isOpen={loading}
            closeModal={() => { }}
            width={"472"}
        >
            <div>
                <div className={"text-2xl text-black font-lato-black"}>
                    Please wait, getting gift card details...
                </div>
                <div className="flex mt-8 center h-full w-full justify-center content-center items-center">
                    <div className="loading-spinner"></div>
                </div>
            </div>
        </Modal>
    }

    if (!giftCard) {
        return null
    }

    return (
        <div className="absolute top-[63px] bottom-0 w-full">
            <Header routes={headerRoutes} selectedRoute={"/payments/gift-cards/:id"} selectedPage={{ label: "Gift Cards", value: "/payments/gift-cards/:id" }} />

            <div className="flex h-full">
                <div
                    className={"border-r border-grey-1 border-t-0 border-b-0"}
                    style={{ width: "10%", borderRightStyle: "solid" }}
                >
                    <Sidebar routes={sidebarRoutes} />
                </div>

                <div style={{ width: "90%" }}>
                    <div
                        className={
                            "flex p-8 justify-between items-center bg-white border-b border-grey-1"
                        }
                        style={{ borderBottomStyle: "solid" }}
                    >
                        <div className={"flex items-center"}>

                            {showBackButton &&
                                <Icon
                                    name={"arrow-left"}
                                    size={"20"}
                                    className={"cursor-pointer"}
                                    onClick={() => navigate(-1)}
                                />
                            }

                            <div className={"text-big text-black font-lato-bold ml-10"}>
                                {giftCard.giftCardNumber}
                            </div>


                            <div className="w-px h-8 bg-grey-1 mx-6" />
                            <div className="text-black text-base font-lato-bold">
                                Date Issued: <span className="text-black text-base font-lato-regular">
                                    {moment(new Date(giftCard.dateIssued._seconds * 1000)).format('MMM DD, YYYY')}
                                </span>
                            </div>


                            <div className="w-px h-8 bg-grey-1 mx-6" />
                            <div className="text-black text-base font-lato-bold">
                                Current Balance: <span className="text-black text-base font-lato-regular">{giftCard.balance} {business && business.currency.toUpperCase()}</span>
                            </div>

                        </div>
                    </div>


                    {/* Header: ends */}
                    {/* container starts */}
                    {giftCard &&
                        <div className="pt-10 pl-10 pr-16 flex justify-start">
                            <div className="w-8/12">
                                <div className='font-lato-black text-2xl text-black'>Gift Card Transaction History</div>
                                {data &&
                                    <div className={"flex px-10 overflow-y-auto"} >
                                        <HorizontalTable
                                            title=''
                                            columns={columns}
                                            data={data}
                                            instructions={instructions}
                                            saveResults={noOp}
                                            setEditing={() => { }}
                                            isEditing={false}
                                            hideEdit
                                            onRowClick={() => {

                                            }}
                                        />
                                    </div>
                                }
                            </div>

                            <div className="w-4/12">
                                <div className='font-lato-black text-2xl text-black'>Gift Card Summary</div>
                                <br /><br />

                                {giftCard &&
                                    <table>
                                        <tr>
                                            <td width="184" className="leftColumn">Customer Name</td>
                                            <td width="322">
                                                <span>
                                                    {giftCard.customer}
                                                </span>

                                                <span
                                                    onClick={() => { console.log('view customer') }}
                                                    className="text-xs"
                                                >
                                                    View Customer Profile
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="184" className="leftColumn">Lifetime Loaded Amount</td>
                                            <td width="322">${getLifetimeLoad(giftCard.transactions)}</td>
                                        </tr>
                                        <tr>
                                            <td width="184" className="leftColumn">Lifetime Spent Amount</td>
                                            <td width="322">${getLifetimeSpend(giftCard.transactions)}</td>
                                        </tr>
                                        <tr>
                                            <td width="184" className="leftColumn">Total Transactions</td>
                                            <td width="322">
                                                <span>
                                                    {giftCard.transactions.length}
                                                </span>

                                                <span
                                                    onClick={() => { console.log('view order history') }}
                                                    style={{ paddingLeft: '100px', }}
                                                    className="text-xs"
                                                >
                                                    View Order History
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                }


                            </div>
                        </div>
                    }



                </div>
            </div>



        </div>
    );
};

export default GiftCardDetails;
