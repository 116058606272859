import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { useModifiers } from "controllers/menuItems";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";

import { RootState } from "../../../model/store";
import { recipeWarningInstruction } from "../tableCells";

type MenuItem = {
  uniqueId: string;
  optionName: string;
  modifierName: string;
  plu: string;
  price: number;
  cost: number;
  recipe: string;
  ingredients: unknown[];
  profit: number;
  costPercentage: number | null;
  profitPercentage: number | null;
};

const columns: ColumnInstruction<MenuItem>[] = [
  { type: "data", header: "Modifier Option", attribute: "optionName" },
  { type: "data", header: "Modifier", attribute: "modifierName" },
  { type: "data", header: "PLU", attribute: "plu" },
  { type: "data", header: "Price", attribute: "price" },
  { type: "data", header: "Cost", attribute: "cost" },
  { type: "data", header: "Profit", attribute: "profit" },
  { type: "data", header: "Cost %", attribute: "costPercentage" },
  { type: "data", header: "Profit %", attribute: "profitPercentage" },
  { type: "projection", header: "", attribute: "warnings" },
];

const instructions: { [x: string]: RenderInstruction<MenuItem> } = {};

instructions.warnings = recipeWarningInstruction;

instructions.costPercentage = {
  type: "percentage",
};
instructions.profitPercentage = {
  type: "percentage",
};

const generateInstructions = (currency: string) => {
  const base = { ...instructions }
  base.profit = {
    type: "complex-custom",
    viewComponent: (props) => {
      const { cost, price } = props.fullObject;
      if (!Number.isFinite(cost)) return <div />;
      if (!Number.isFinite(price)) return <div />;
      const profit = price - cost;
      return (
        <div>
          {(profit / 100).toLocaleString("en-US", {
            style: "currency",
            currency,
          })}
        </div>
      );
    },
    editComponent: null,
  };

  base.price = {
    type: "currency",
    currency,
  };
  base.cost = {
    type: "currency",
    currency,
  };
  base.profit = {
    type: "currency",
    currency,
  };
  return base;
}

const useMenuItemsHook = () => {
  const businessId: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.id;
  const query = useModifiers(businessId);
  const menuItems = useMemo(() => {
    if (!query.data) return [];
    return [...query.data].sort((a, b) => a.modifierName?.localeCompare(b.modifierName)).map((item) => {
      return {
        uniqueId: item.id,
        optionName: item.optionName,
        modifierName: item.modifierName,
        recipe: item.recipe,
        plu: item.plu,
        price: item.price,
        cost: item.cost,
        ingredients: item.ingredients,
        profit: (item.price ?? 0) - (item.cost ?? 0),
        costPercentage: item.price
          ? (100 * (item.cost ?? 0)) / item.price
          : null,
        profitPercentage: item.price
          ? (100 * (item.price - (item.cost ?? 0))) / item.price
          : null,
      };
    });
  }, [query.data]);

  const filterItems = useCallback((item: MenuItem, search: string) => {
    const lc = search.toLowerCase();
    return (
      item.modifierName?.toLowerCase().includes(lc) ||
      item.optionName?.toLowerCase().includes(lc) ||
      item.plu?.toLowerCase().includes(lc)
    );
  }, []);

  return {
    menuItems,
    columns,
    generateInstructions,
    filterItems,
  };
};
export default useMenuItemsHook;
