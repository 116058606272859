//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import React, { useMemo } from "react";

import { DepartmentId } from "controllers/staff";

import Box from "components/Box";
import UIComponents from "@tangopay/tango-ui-library";
import NavSlider from "views/manager/NewTeam/views/EmployeeDetails/components/NavSlider";
const { Dropdown, TextField, Icon } = UIComponents;
export type WeeklyScheduleViewType = "shift_view" | "staff_member_view" | "day_view";

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};

interface WeeklyViewSelectProps {
  weeklyScheduleViewType: WeeklyScheduleViewType;
  setWeeklyScheduleViewType: (v: WeeklyScheduleViewType) => void;
  departmentScheduleViewType: DepartmentId | null;
  setDepartmentScheduleViewType: (d: DepartmentId | null) => void;
  jobFunctions: TangoJobFunctions;
  setRoleFilterId: (v: any) => void;
  onFullNameInputChangeDebounced: (e: any) => void;

}

export const WeeklyViewSelect = ({
  weeklyScheduleViewType,
  setWeeklyScheduleViewType,
  departmentScheduleViewType,
  setDepartmentScheduleViewType,
  jobFunctions,
  setRoleFilterId,
  onFullNameInputChangeDebounced,
}: WeeklyViewSelectProps) => {
  const rolesData = useMemo(() => {
    //@ts-ignore
    return Object.keys(jobFunctions).filter((jobFunctionKey) => !jobFunctions[jobFunctionKey].deleted).map((jobFunctionKey) => ({
      value: jobFunctionKey,
      label: jobFunctions[jobFunctionKey].title
    }))
  }, [jobFunctions])

  const selectedViewName = useMemo(() => {
    if (weeklyScheduleViewType === 'day_view') {
      return "Day";
    } else if (weeklyScheduleViewType === 'shift_view') {
      return "Shift";
    } else {
      return "Stafff";
    }
  }, [weeklyScheduleViewType])
  return (
    <>
      <div className="py-4 px-3 border-b border-solid border-l-0 border-t-0 border-r-0 border-grey-1">
        <div className="mb-3">
          <NavSlider
            items={[{
              index: 0,
              id: "shift_view",
              title: "Shift"
            },
            {
              index: 1,
              id: "staff_member_view",
              title: "Staff"
            },
            {
              index: 2,
              id: "day_view",
              title: "Day"
            }
            ]}
            currentIndex={weeklyScheduleViewType === 'shift_view' ? 0 : weeklyScheduleViewType === 'staff_member_view' ? 1 : 2}
            onItemPress={(selectedItem) => {
              switch (selectedItem) {
                case "shift_view":
                  setWeeklyScheduleViewType('shift_view');
                  break;
                case "staff_member_view":
                  setWeeklyScheduleViewType('staff_member_view');
                  break;
                default:
                  setWeeklyScheduleViewType("day_view");
              }
            }}
          />
        </div>

        {/* <div className="border flex p-1 relative items-center bg-blue-grey-3  px-3 rounded-full mb-3">
          <div className="w-full flex justify-center py-2" onClick={() => setWeeklyScheduleViewType("shift_view")}>
            <div className="text-xs text-grey-3 font-lato-bold">Shift</div>
          </div>
          <div className="w-full flex justify-center py-2" onClick={() => setWeeklyScheduleViewType("staff_member_view")}>
            <div className="text-xs text-grey-3 font-lato-bold">Staff</div>
          </div>
          <div className="w-full flex justify-center py-2" onClick={() => setWeeklyScheduleViewType("day_view")}>
            <div className="text-xs text-grey-3 font-lato-bold">Day</div>
          </div>
          <motion.div
            className={`${weeklyScheduleViewType === 'shift_view' ? 'left-1' : weeklyScheduleViewType === "staff_member_view" ? 'left-1/3' : ' right-1'} bg-white shadow text-black flex items-center justify-center w-2/6 rounded-full h-8 transition-all top-[4px] absolute `}
            transition={spring}
            layout
          >
            {selectedViewName}
          </motion.div>
        </div> */}
        <div className="mb-3">
          <NavSlider
            items={[{
              index: 0,
              id: "all",
              title: "All"
            },
            {
              index: 1,
              id: "foh",
              title: "FOH"
            },
            {
              index: 2,
              id: "boh",
              title: "BOH"
            }
            ]}
            currentIndex={departmentScheduleViewType ? departmentScheduleViewType === 'foh' ? 1 : 2 : 0}
            onItemPress={(selectedItem) => {
              switch (selectedItem) {
                case "foh":
                  setDepartmentScheduleViewType('foh');
                  break;
                case "boh":
                  setDepartmentScheduleViewType('boh');
                  break;
                default:
                  setDepartmentScheduleViewType(null);
              }
            }}
          />
        </div>

        {/* <div className="border flex p-1 relative items-center bg-blue-grey-3  px-3 rounded-full mb-3">
          <div className="w-full flex justify-center py-2" onClick={() => setDepartmentScheduleViewType(null)}>
            <div className="text-xs text-grey-3 font-lato-bold">All</div>
          </div>
          <div className="w-full flex justify-center py-2" onClick={() => setDepartmentScheduleViewType("foh")}>
            <div className="text-xs text-grey-3 font-lato-bold">FOH</div>
          </div>
          <div className="w-full flex justify-center py-2" onClick={() => setDepartmentScheduleViewType("boh")}>
            <div className="text-xs text-grey-3 font-lato-bold">BOH</div>
          </div>
          <motion.div
            className={`${!departmentScheduleViewType ? 'left-1' : departmentScheduleViewType === 'foh' ? 'left-1/3' : ' right-1'} bg-white shadow text-black flex items-center justify-center w-2/6 rounded-full h-8 transition-all top-[4px] absolute `}
            transition={spring}
            layout
          >
            {!departmentScheduleViewType ? 'All' : departmentScheduleViewType === 'foh' ? 'FOH' : 'BOH'}
          </motion.div>
        </div> */}
        <Dropdown
          defaultValue={{ value: "", label: "All Roles" }}
          options={[{ value: "", label: "All Roles" }, ...rolesData]}
          onChange={(newValue) => {
            if (newValue) {
              //@ts-ignore
              setRoleFilterId(newValue.value)
            }
          }}
          size="medium"
        />
        <div className="bg-blue-grey-2 rounded-full mt-3 relative">
          <Icon name="search" size="12" className="absolute left-5 top-3" />
          <TextField
            className="!bg-transparent border-0  !pl-11"
            sizeType="medium"
            placeholder="Search for someone..."
            onChange={(e) => onFullNameInputChangeDebounced(e)}
          />
        </div>

      </div>
    </>
  );
};
