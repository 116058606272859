import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";

import Sidebar from "../Sidebar";
import { headerRoutes } from "../routes";
import useMenuItemsHook from "./useMenuItems";

const PrepItem = () => {
  const { menuItems, columns, instructions, filterItems } = useMenuItemsHook();
  const navigate = useNavigate();

  const nav = useCallback(
    (uniqueId) => {
      const item = menuItems.find((i) => i.uniqueId === uniqueId);
      if (!item) return;
      navigate(
        `/inventory/recipes/menu-item/products/${item.uniqueId}`
      );
    },
    [menuItems, navigate]
  );

  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header
        routes={headerRoutes}
        selectedRoute={"/inventory/items/basic"}
      />
      <div className="flex h-full overflow-hidden">
        <div
          className={"border-r border-grey-1 border-t-0 border-b-0 overflow-y-auto"}
          style={{ width: "10%", borderRightStyle: "solid" }}
        >
          <Sidebar />
        </div>

        <div
          className={"flex px-10 py-6 overflow-y-auto"}
          style={{ width: "90%" }}
        >
          <HorizontalTable
            title="Menu Items"
            columns={columns}
            data={menuItems}
            instructions={instructions}
            isEditing={false}
            hideEdit
            hideCheckboxes
            onRowClick={nav}
            filterItems={filterItems}
          />
        </div>
      </div>
    </div>
  );
};

export default PrepItem;
