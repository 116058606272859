import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";
import Sidebar from "components/Sidebar";
import { headerRoutes, sidebarRoutes } from "../routes";
import UIComponents from '@tangopay/tango-ui-library';
import useDiscounts from "./useDiscounts";
import { Route, Routes, useNavigate } from "react-router-dom";
import { CustomerModel } from "../ClosedChecks/useClosedChecks";
import moment from "moment";
import ClosedCheckDetail from "../ClosedCheckDetail";
import { downloadCSV, ProductsPaid } from "controllers/payments";

const { Modal, DatePickerInput, Icon, Dropdown, Button } = UIComponents;

const Discounts = () => {

    const {
        businessId,
        data,
        noOp,
        columns,
        instructions,
        isEditing,
        setEditing,
        setStartDate,
        startDate,
        setEndDate,
        endDate,
        setDiscountsReport,
        discountsReport,
        setLastTabId,
        setShowCustomDatePicker,
        customDatePicker,
        setChunkedDiscounts,
        chunkedDiscounts,
        setPreviousPageTabIds,
        previousPageTabIds,
        setPageNumber,
        pageNumber,
        setDisplayCounterMessage,
        displayCounterMessage,
        setLoading,
        loading
    } = useDiscounts();


    const navigate = useNavigate();
    const nav = useCallback((id: string) => {
        let tabClicked = {
            id: ''
        }
        let staffMember = null
        data.forEach((row: any) => {
            if (row.tab.voidedProducts && row.tab.voidedProducts.length > 0) {
                for (const product of row.tab.voidedProducts) {
                    if (product.productOrderId === id) {
                        tabClicked = row.tab
                        staffMember = row.staffMember
                    }
                }
            }
            row.tab.customer.forEach((customer: CustomerModel) => {
                for (const product of customer.productsPaid) {
                    if (product.productOrderId === id) {
                        tabClicked = row.tab
                        staffMember = row.staffMember
                    }
                }
            })
        })

        if (tabClicked && tabClicked.id) {
            const closedCheckWithStaff = {
                completedCheck: tabClicked,
                staffMember: staffMember
            }
            navigate(`${tabClicked.id}`, { state: closedCheckWithStaff });
        }

    }, [navigate, data]);

    useEffect(() => {
        if (chunkedDiscounts.length > 0 && pageNumber > 0) {
            const fullList = chunkedDiscounts.flat(1)
            const lastStartId = fullList[fullList.length - 1].uniqueId
            let startNumber = fullList.findIndex((payment: any) => payment.uniqueId === lastStartId);
            if (startNumber!-- - 1) {
                setDisplayCounterMessage(startNumber + 1 + ' - ' + (fullList.length + data.length))
            }
        } else {
            setDisplayCounterMessage('1 - ' + data.length)
        }

        //@ts-ignore
        if ((prevData && prevData.data && prevData.data.length !== data.length) || !data.length) {
            setLoading(false)
        }
    }, [chunkedDiscounts, data])


    const prevData = usePrevious({ data: data ? data : [] });
    function usePrevious(value: any) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    useEffect(() => {
        if (!startDate || !endDate) {
            setLoading(false)
        }
        if (startDate && endDate && !moment(startDate).isBefore(moment(endDate))) {
            setLoading(false)
            alert('Please select a start date that is earlier than your selected end date.')
        }
        if (startDate && endDate && discountsReport?.value === 'Custom Range' && moment(startDate).isBefore(moment(endDate))) {
            setLoading(true)
        }
    }, [startDate, endDate])
    useEffect(() => {
        setLoading(true)
        switch (discountsReport?.value) {
            case 'Today':
                setShowCustomDatePicker(false)
                setStartDate(moment().startOf('day'))
                setEndDate(moment().endOf('day'))
                break;

            case 'Last 7 Days':
                setShowCustomDatePicker(false)
                setStartDate(moment().subtract(7, 'days'))
                setEndDate(moment().endOf('day'))
                break;

            case 'Last Month':
                setShowCustomDatePicker(false)
                setStartDate(moment().subtract(1, 'months').startOf('month'))
                setEndDate(moment().subtract(1, 'months').endOf('month'))
                break;

            case 'Last Year':
                setShowCustomDatePicker(false)
                setStartDate(moment().subtract(1, 'years').startOf('years'))
                setEndDate(moment().subtract(1, 'years').endOf('years'))
                break;

            case 'Year To Date':
                setShowCustomDatePicker(false)
                setStartDate(moment().startOf('years'))
                setEndDate(moment().endOf('day'))
                break;

            case 'Custom Range':
                //datepicker errors unless resetting to empty before using
                setLoading(false)
                setStartDate('')
                setEndDate('')
                setShowCustomDatePicker(true)
                break;

            default:
                setShowCustomDatePicker(false)
                setPageNumber(0)
                setStartDate('')
                setEndDate('')
                setLastTabId('start')
                setPreviousPageTabIds(['start'])
                setLoading(false)
        }
    }, [discountsReport])

    const filterOption = useMemo(() => {
        return [
            {
                value: "Today",
                label: "Today",
            },
            {
                value: "Last 7 Days",
                label: "Last 7 Days",
            },
            {
                value: "Last Month",
                label: "Last Month",
            },
            {
                value: "Last Year",
                label: "Last Year",
            },
            {
                value: "Year To Date",
                label: "Year To Date",
            },
            {
                value: "Custom Range",
                label: "Custom Range",
            },
            {
                value: "Default",
                label: "Default",
            },
        ];
    }, []);


    const returnTable = () => {
        return (
            <div className="absolute top-[63px] bottom-10 w-full">
                <Header routes={headerRoutes} selectedRoute={"/payments/discounts"} selectedPage={{ label: "Customers", value: "/payments/discounts" }} />
                <div className="flex h-full">
                    <div
                        className={"border-r border-grey-1 border-t-0 border-b-0"}
                        style={{ width: "10%", borderRightStyle: "solid" }}
                    >
                        <Sidebar routes={sidebarRoutes} />
                    </div>

                    <div className={"flex px-10 py-6 overflow-y-auto"} style={{ width: "90%" }}>
                        <HorizontalTable
                            title="Discounts"
                            columns={columns}
                            data={data}
                            instructions={instructions}
                            saveResults={noOp}
                            setEditing={setEditing}
                            isEditing={isEditing}
                            hideEdit
                            onRowClick={nav}
                            customHeaderRightContent={
                                <div className="flex items-center">
                                    <Dropdown
                                        options={filterOption}
                                        onChange={setDiscountsReport}
                                        value={discountsReport}
                                        size="medium"
                                        className='tango-dropdown w-80'
                                        placeholder='Select'
                                    />

                                    {customDatePicker &&
                                        <>
                                            <DatePickerInput
                                                size="large"
                                                className='tango-dropdown'
                                                value={startDate}
                                                onChange={(date: Date) => {
                                                    setStartDate(date)
                                                }} />
                                            <DatePickerInput
                                                size="large"
                                                className='tango-dropdown'
                                                value={endDate}
                                                onChange={(date: Date) => {
                                                    setEndDate(new Date(moment(date).endOf('day').format()))//make sure query includes full end date through end of day
                                                }} />
                                        </>
                                    }

                                    <Button
                                        label="Export"
                                        size="btn-medium"
                                        type="btn-style-1"
                                        onClick={() => downloadCSV(businessId, data, 'discounts')}
                                    />
                                </div>
                            }
                        />
                    </div>
                </div>

                <Modal
                    isOpen={loading}
                    closeModal={() => { }}
                    width={"472"}
                >
                    <div>
                        <div className={"text-2xl text-black font-lato-black"}>
                            Please wait, getting discounts...
                        </div>
                        <div className="flex mt-8 center h-full w-full justify-center content-center items-center">
                            <div className="loading-spinner"></div>
                        </div>
                    </div>
                </Modal>


                {/* only show pagination with default Dropdow option selected. Otherwise show all items returned in the report without pagination */}
                {data.length > 0 && !startDate && !endDate &&
                    <div className="flex justify-between items-center" style={{ marginLeft: '12%', marginRight: "2%", }}>
                        <div className="mt-7 flex items-center justify-start">
                            Viewing {displayCounterMessage}
                        </div>
                        <div className="mt-7 flex items-center justify-end">
                            <Icon name="chevron-left" className=" cursor-pointer" size="20" onClick={() => {
                                if (pageNumber > 0 && previousPageTabIds.length > 0 && chunkedDiscounts.length > 0) {
                                    setLastTabId(previousPageTabIds[pageNumber])
                                    setPreviousPageTabIds([
                                        ...previousPageTabIds.slice(0, previousPageTabIds.length - 1),
                                        ...previousPageTabIds.slice(previousPageTabIds.length)
                                    ]);
                                    setChunkedDiscounts([
                                        ...chunkedDiscounts.slice(0, chunkedDiscounts.length - 1),
                                        ...chunkedDiscounts.slice(chunkedDiscounts.length)
                                    ]);
                                    setPageNumber(pageNumber - 1)
                                }
                            }} />

                            <Icon name="chevron-right" className="cursor-pointer ml-3" size="20" onClick={() => {
                                if (!data[data.length - 1].paginateTabId) {
                                    return
                                }
                                setLastTabId(data[data.length - 1].paginateTabId)
                                setChunkedDiscounts([...chunkedDiscounts, data])
                                setPageNumber(pageNumber + 1)
                                setPreviousPageTabIds([...previousPageTabIds, data[0].paginateTabId])
                            }} />
                        </div>
                    </div>
                }
            </div>
        )
    }

    const returnClosedCheck = () => {
        return (
            <ClosedCheckDetail />
        )
    }

    return (
        <div className="bottom-0 w-full overflow-hidden">
            <div className="flex h-full">
                <Routes>
                    <Route index element={returnTable()} />
                    <Route
                        path=":id"
                        element={returnClosedCheck()}
                    />
                </Routes>
            </div>
        </div>
    )
};

export default Discounts;
