import { firestore } from "config/firebase";
import moment from "moment-timezone";
import Papa from "papaparse";

import { ScheduleEventType } from "./schedule";

export const approveRequest = async (requestId: string) => {
  await firestore
    .collection("ScheduleEvents")
    .doc(requestId)
    .update({ status: "approved" });
};

export const deleteRequest = async (requestId: string) => {
  await firestore
    .collection("ScheduleEvents")
    .doc(requestId)
    .update({ deleted: true });
};

export const undoApproveRequest = async (requestId: string) => {
  await firestore
    .collection("ScheduleEvents")
    .doc(requestId)
    .update({ status: "pending" });
};

export const approveDropRequest = async (
  requestId: string,
  scheduleId: string,
  shiftId: string,
  reassigneeStaffMemberId?: string
) => {
  await firestore
    .collection("ScheduleEvents")
    .doc(requestId)
    .update({ status: "approved" });
  const scheduleToUpdateSn = await firestore
    .collection("Schedule")
    .doc(scheduleId)
    .get();
  const schedule = scheduleToUpdateSn.data() as TangoSchedule;
  const newShifts = schedule.shifts.map((sh) => {
    if (sh.id === shiftId) {
      return { ...sh, staffId: reassigneeStaffMemberId || null };
    }
    return sh;
  });
  await firestore.collection("Schedule").doc(scheduleId).update({
    updatedAt: new Date(),
    shifts: newShifts,
  });
  const sendingDraftScheduleSn = await firestore
    .collection("DraftSchedule")
    .where("associatedScheduleId", "==", scheduleId)
    .get();
  sendingDraftScheduleSn.docs.forEach((sn) => {
    firestore
      .collection("DraftSchedule")
      .doc(sn.id)
      .update({ shifts: newShifts, updatedAt: new Date() });
  });
};

export const approveNewFixedAvailability = async (
  newAvailability: FixedAvailability
) => {
  const allFixedAvailabilitiesForStaff = await firestore
    .collection("FixedAvailabilities")
    .where("staffId", "==", newAvailability.staffId)
    .where("businessId", "==", newAvailability.businessId)
    .where("isActive", "==", true)
    .where("id", "!=", newAvailability.id)
    .get();

  const allFixedAvailabilities = allFixedAvailabilitiesForStaff.docs.map(
    (d) => d.data() as FixedAvailability
  );
  await Promise.all(
    allFixedAvailabilities.map((a) => {
      return firestore
        .collection("FixedAvailabilities")
        .doc(a.id)
        .update({ deleted: true, updatedAt: new Date(), isActive: false });
    })
  );
  console.log("here");
  await firestore
    .collection("FixedAvailabilities")
    .doc(newAvailability.id)
    .update({ isActive: true, updatedAt: new Date(), status: "approved" });
};

export const denyNewFixedAvailability = async (
  newAvailability: FixedAvailability
) => {
  await firestore
    .collection("FixedAvailabilities")
    .doc(newAvailability.id)
    .update({
      isActive: false,
      updatedAt: new Date(),
      status: "denied",
      deleted: false,
    });
};

type TimeOffImportItem = {
  "First Name": string;
  "Last Name": string;
  Status: string;
  "Time Off": string;
};

export const importTimeOffRequests = async (
  file: File,
  business: TangoBusiness,
  fellowStaffMembers: StaffMember[]
) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      error: () => reject("Something went wrong"),
      complete: async (
        results: Papa.ParseResult<TimeOffImportItem>,
        file: any
      ) => {
        console.log("Results", results);
        const convertedResultsToTimeOffs = await Promise.all(
          results.data.map(async (row) => {
            const associatedStaffMember = fellowStaffMembers.find((sm) => {
              // return sm?.contact?.firstName === row["First Name"] && sm?.contact?.lastName === row["Last Name"]
              return true;
            });

            console.log("associatedStaffMember", associatedStaffMember);

            if (associatedStaffMember) {
              const newDocRef = firestore.collection("ScheduleEvents").doc();
              const businessId = business.id;
              const senderStaff = {
                id: associatedStaffMember.id,
                name: `${associatedStaffMember.contact.firstName} ${associatedStaffMember.contact.lastName}`,
              };
              const timeOffMomentDateRange = row["Time Off"]
                .split("-")
                .map((ds) =>
                  moment(ds)
                    .tz(business.timezone, true)
                    .startOf("day")
                    .add(4, "hours")
                );
              const startMoment = timeOffMomentDateRange[0];
              const endMoment = timeOffMomentDateRange[1];
              if (startMoment.isBefore(endMoment)) {
                const datesBetweenDateRange = [];
                const currDate = startMoment.clone().add(1, "day");
                while (currDate.isBefore(endMoment)) {
                  datesBetweenDateRange.push(currDate.toDate());
                  currDate.add(1, "day");
                }
                const timeOffRequestDates = [
                  startMoment.toDate(),
                  ...datesBetweenDateRange,
                  endMoment.toDate(),
                ];
                const description = "";
                const timeOffRequest: ScheduleEvent = {
                  associatedStaff: [associatedStaffMember.id],
                  businessId,
                  id: newDocRef.id,
                  senderStaff,
                  eventDate: startMoment.toDate(),
                  timeOffRequestDates,
                  description,
                  eventType: ScheduleEventType.time_off,
                  status: row.Status === "Approved" ? "approved" : "pending",
                  createdAt: new Date(),
                  updatedAt: new Date(),
                  deleted: false,
                  enabled: true,
                  managerApproved: row.Status === "Approved",
                  acceptedBy: [],
                  declinedBy: [],
                  receiverStaff: null,
                };
                await newDocRef.set(timeOffRequest);
                return timeOffRequest;
              }
            }
          })
        );
        return resolve(convertedResultsToTimeOffs);
        console.log("convertedResultsToTimeOffs", convertedResultsToTimeOffs);
      },
    });
  });
  // id: timeOffDocRef.id,
  //   associatedStaff: [staffId],
  //   businessId,
  //   senderStaff: {
  //     id: staffId,
  //     name: staffName,
  //   },
  //   eventDate: dates[0],
  //   timeOffRequestDates: dates,
  //   description: reason,
  //   eventType: 'time_off',
  //   status: 'pending',
  //   createdAt: new Date(),
  //   updatedAt: new Date(),
  //   deleted: false,
  //   enabled: true,
};

export const approveTradeRequest = async (request: ScheduleEvent) => {
  const receivingScheduleSn = await firestore
    .collection("Schedule")
    .doc(request.receivingScheduleId)
    .get();

  const receivingSchedule = receivingScheduleSn.data() as TangoSchedule;
  const newReceivingScheduleShifts = receivingSchedule.shifts.map((s) => {
    if (s.id === request.receivingShiftId) {
      return { ...s, staffId: request.senderStaff.id };
    }
    return s;
  });
  await firestore
    .collection("Schedule")
    .doc(request.receivingScheduleId)
    .update({ shifts: newReceivingScheduleShifts, updatedAt: new Date() });

  const receivingDraftScheduleSn = await firestore
    .collection("DraftSchedule")
    .where("associatedScheduleId", "==", request.receivingScheduleId)
    .get();
  receivingDraftScheduleSn.docs.forEach((sn) => {
    firestore
      .collection("DraftSchedule")
      .doc(sn.id)
      .update({ shifts: newReceivingScheduleShifts, updatedAt: new Date() });
  });

  const sendingScheduleSn = await firestore
    .collection("Schedule")
    .doc(request.sendingScheduleId)
    .get();
  const sendingSchedule = sendingScheduleSn.data() as TangoSchedule;
  const newSendingScheduleShifts = sendingSchedule.shifts.map((s) => {
    if (s.id === request.sendingShiftId) {
      return { ...s, staffId: request.receiverStaff.id };
    }
    return s;
  });
  await firestore
    .collection("Schedule")
    .doc(request.sendingScheduleId)
    .update({ shifts: newSendingScheduleShifts, updatedAt: new Date() });

  const sendingDraftScheduleSn = await firestore
    .collection("DraftSchedule")
    .where("associatedScheduleId", "==", request.sendingScheduleId)
    .get();
  sendingDraftScheduleSn.docs.forEach((sn) => {
    firestore
      .collection("DraftSchedule")
      .doc(sn.id)
      .update({ shifts: newSendingScheduleShifts, updatedAt: new Date() });
  });

  const scheduleEventsForTheSameShift = await firestore
    .collection("ScheduleEvents")
    .where("sendingShiftId", "==", request.sendingShiftId)
    .get();

  scheduleEventsForTheSameShift.docs.forEach((d) => {
    const scheduleEventData: ScheduleEvent = d.data() as ScheduleEvent;
    if (scheduleEventData.id !== request.id) {
      firestore
        .collection("ScheduleEvents")
        .doc(scheduleEventData.id)
        .update({ deleted: true });
    }
  });

  if (request?.localOptionalRequestId) {
    await firestore.collection("ScheduleEvents").doc(request.id).update({
      status: "approved",
      receiverStaff: request.receiverStaff,
      receivingScheduleId: request.receivingScheduleId,
      receivingShiftId: request.receivingShiftId,
    });
  } else {
    await firestore.collection("ScheduleEvents").doc(request.id).update({
      status: "approved",
    });
  }
};

export const approveCoverRequest = async (request: ScheduleEvent) => {
  const sendingScheduleSn = await firestore
    .collection("Schedule")
    .doc(request.sendingScheduleId)
    .get();
  const sendingSchedule = sendingScheduleSn.data() as TangoSchedule;
  const newSendingScheduleShifts = sendingSchedule.shifts.map((s) => {
    if (s.id === request.sendingShiftId) {
      return { ...s, staffId: request.receiverStaff.id };
    }
    return s;
  });
  await firestore
    .collection("Schedule")
    .doc(request.sendingScheduleId)
    .update({ shifts: newSendingScheduleShifts, createdAt: new Date() });

  const sendingDraftScheduleSn = await firestore
    .collection("DraftSchedule")
    .where("associatedScheduleId", "==", request.sendingScheduleId)
    .get();
  sendingDraftScheduleSn.docs.forEach((sn) => {
    firestore
      .collection("DraftSchedule")
      .doc(sn.id)
      .update({ shifts: newSendingScheduleShifts, updatedAt: new Date() });
  });

  const scheduleEventsForTheSameShift = await firestore
    .collection("ScheduleEvents")
    .where("sendingShiftId", "==", request.sendingShiftId)
    .get();

  scheduleEventsForTheSameShift.docs.forEach((d) => {
    const scheduleEventData: ScheduleEvent = d.data() as ScheduleEvent;
    if (scheduleEventData.id !== request.id) {
      firestore
        .collection("ScheduleEvents")
        .doc(scheduleEventData.id)
        .update({ deleted: true });
    }
  });

  if (request?.localOptionalRequestId) {
    await firestore.collection("ScheduleEvents").doc(request.id).update({
      status: "approved",
      receiverStaff: request.receiverStaff,
    });
  } else {
    await firestore.collection("ScheduleEvents").doc(request.id).update({
      status: "approved",
    });
  }
};

export const denyRequest = async (requestId: string) => {
  await firestore
    .collection("ScheduleEvents")
    .doc(requestId)
    .update({ status: "denied" });
};
