import { RootState } from '../../../model/store';
import { useUniqueIdList } from '../hooks';
import { RenderInstruction } from 'components/Table/GenericCell/TableCell';
import { ColumnInstruction, UpdateState } from 'components/Table/HorizontalTable';
import { useInventoryInfo, useSetAllergens } from 'controllers/inventoryInfo';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

type AllergenInfo = {
  uniqueId: string;
  name: string;
};

const columns: ColumnInstruction<AllergenInfo>[] = [{ type: 'data', header: 'Name', attribute: 'name' }];

const instructions: { [x: string]: RenderInstruction<AllergenInfo> } = {};

const prefix = 'new__allergen__';
const useAllergens = () => {
  const businessId: string = useSelector((state: RootState) => state.business as TangoBusiness)?.id;
  const [isEditing, setEditing] = useState(false);
  // call update with false to push, true to clear
  const { addedIds, push, remove, clear } = useUniqueIdList(prefix);
  const [deletedIds, setDeletedIds] = useState<{ [id: string]: true }>({});
  const deleteIds = useCallback((ids: string[]) => {
    setEditing(true);
    ids.forEach((id) => {
      if (id.startsWith(prefix)) return;
      setDeletedIds((curr) => ({ ...curr, [id]: true }));
    });
    remove(ids);
  }, []);
  useEffect(() => {
    if (!isEditing) {
      clear();
      setDeletedIds({});
    }
  }, [isEditing, clear]);
  const addItem = useCallback(() => {
    setEditing(true);
    push();
  }, [push]);
  const query = useInventoryInfo(businessId);
  const update = useSetAllergens(businessId);
  const dataFromServer = query.data?.allergens ?? [];

  const dataWithAdditions = useMemo(() => {
    const adding = addedIds.map(
      (uniqueId: string) =>
        ({
          uniqueId,
          name: '',
        } as AllergenInfo),
    );
    const curr = dataFromServer
      .filter(({ id }) => !deletedIds[id])
      .map(
        (data) =>
          ({
            uniqueId: data.id,
            name: data.name,
          } as AllergenInfo),
      );
    return [...adding, ...curr];
  }, [deletedIds, addedIds, dataFromServer]);

  const saveChanges = useCallback(
    async (instructions: UpdateState) => {
      const names = dataWithAdditions.map((item) => {
        const currName = item.name;
        const newName = instructions[item.uniqueId]?.name?.newValue;
        return (newName ?? currName) as string;
      });
      return update.mutateAsync({ names });
    },
    [dataWithAdditions, update],
  );

  return {
    allergensData: dataWithAdditions,
    addItem,
    deleteIds,
    saveChanges,
    columns,
    instructions,
    isEditing,
    setEditing,
  };
};
export default useAllergens;
