import { UpdateState } from "components/Table/HorizontalTable";
import { UpdatePrepItemDTO, UpdateRawItemDTO, useDeletePrepItem, useDeleteRawItem, useItemInfo, useItemUsage, usePreparePrepItem, useUpdatePrepItem, useUpdateRawItem, useVendorInfoForItem } from "controllers/inventoryItemInfo";
import { UpdateSaleDTO, useCreateSale, useDeleteSale, useUpdateSale, useVendors } from "controllers/vendors";
import { RootState } from "model/store";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";

import {
  detailInstructions,
  recipeInstructions,
  vendorInstructionsFactory,
  detailColumns,
  recipeColumns,
  vendorColumns,
  ItemDetails,
  ItemInRecipeDetail,
  VendorItemDetail,
} from "./instructions";

const useItemDetail = (itemId: string, isRaw: boolean) => {

  const [isEditingDetails, setIsEditingDetails] = useState(false);
  const [isEditingSales, setIsEditingSales] = useState(false);
  const [showPrepModal, setShowPrepModal] = useState(false);
  const [prepCount, setPrepCount] = useState(1);
  const openPrepModal = useCallback(() => {
    setShowPrepModal(true);
  }, []);
  const closePrepModal = useCallback(() => {
    setShowPrepModal(false);
  }, []);
  const businessId: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.id;

  const prefix = "new__sale";

  const [addedIds, updateItems] = useReducer(
    (curr: string[], clear: boolean) => {
      if (clear) return [];
      return [prefix + curr.length, ...curr];
    },
    []
  );

  const addSale = useCallback(() => {
    setIsEditingSales(true);
    updateItems(false);
  }, []);

  useEffect(() => {
    if (!isEditingSales) updateItems(true);
  }, [isEditingSales]);

  const salesQuery = useVendorInfoForItem(businessId, itemId);
  const itemQuery = useItemInfo(businessId, itemId, isRaw);
  const updateRaw = useUpdateRawItem(businessId);
  const updatePrep = useUpdatePrepItem(businessId);
  const preparePrep = usePreparePrepItem(businessId);

  const usageQuery = useItemUsage(businessId, itemId, isRaw);

  const vendorsQuery = useVendors(businessId);
  const updateSale = useUpdateSale(businessId);
  const createSale = useCreateSale(businessId);
  const deleteSale = useDeleteSale(businessId);
  const deleteRaw = useDeleteRawItem(businessId);
  const deletePrep = useDeletePrepItem(businessId);
  const deleteItem = useCallback(
    () => (isRaw ? deleteRaw : deletePrep).mutateAsync(itemId),
    [isRaw, itemId, deletePrep, deleteRaw],
  );

  const salesFromServer: VendorItemDetail[] = useMemo(
    () => {
      if (!salesQuery.data) return [];
      return salesQuery.data.map((i: any) => {
        const sale = i.itemsSold[0];
        const vendor = i.vendor;
        return {
          uniqueId: sale.id as string,
          vendor: vendor.id as string,
          purchasePrice: sale.pricePerSaleUnit as number,
          saleUnit: sale.saleUnit as string,
          splitUnit: sale.splitUnit as string,
          splitUnitsPerSaleUnit: sale.splitUnitsPerSaleUnit as number,
          pakUnit: sale.productionUnit as string,
          pakUnitsPerSplitUnit: sale.productionUnitsPerSplitUnit as number,
          plu: "FOOBARBAZ",
          isDefault: sale.isDefaultVendor as boolean,
        }
      })
    },
    [salesQuery.data],
  );

  const itemDetail: ItemDetails | null = useMemo(() => {
    const d = itemQuery.data
    if (!d) return null;
    return {
      uniqueId: itemId,
      itemName: d.name as string,
      unitType: "Unit",
      ingredientUnitName: d.ingredientUnitName as string,
      productionUnitName: d.productionUnitName as string,
      isRaw,
      conversionRatio: d.ingredientsPerProduct as number,
      isKey: d.isKey as boolean,
      actualized: d.isActualized as boolean,
      primaryGroup: (d.primaryGroup?.id ?? null) as string | null,
      secondaryGroup: (d.secondaryGroup?.id ?? null) as string | null,
      locations: (d.locations.map(i => i.id)),
      allergens: d.allergens?.filter(notNull => notNull)?.map((a: { id: string; }) => a.id as string),
      minPar: d.minPar as number,
      theoreticalStock: d.theoreticalStock,
      maxPar: d.maxPar as number,
      shelfLifeHours: d.shelfLifeHours,
      shelfLifeUnits: d.shelfLifeUnits,
    };
  }, [itemQuery.data]);
  const addedItems: VendorItemDetail[] = useMemo(() => {
    return addedIds.map((uniqueId) => ({
      uniqueId,
      vendor: null,
      purchasePrice: 0,
      saleUnit: "Units",
      splitUnit: "Units",
      splitUnitsPerSaleUnit: 1,
      pakUnit: itemDetail?.productionUnitName ?? "Units",
      pakUnitsPerSplitUnit: 1,
      plu: "FOOBARBAZ",
      isDefault: false,
    }));
  }, [addedIds, itemDetail?.productionUnitName]);

  const sales = useMemo(
    () => [...addedItems, ...salesFromServer],
    [addedItems, salesFromServer],
  );

  const saveSales = useCallback(
    async (instructions: UpdateState) => {
      Object.keys(instructions)
        .filter((id) => !id.startsWith(prefix))
        .forEach((saleId) => {
          const sale = sales.find(i => i.uniqueId == saleId);
          if (!sale?.vendor) throw "Impossible";

          const updates = instructions[saleId];
          const newValue: Partial<UpdateSaleDTO> = {};
          if ("isDefault" in updates)
            newValue.isDefaultVendor = updates.isDefault.newValue as boolean;

          if ("purchasePrice" in updates)
            newValue.pricePerSaleUnit = updates.purchasePrice.newValue as number;

          if ("saleUnit" in updates)
            newValue.saleUnit = updates.saleUnit.newValue as string;

          if ("splitUnit" in updates)
            newValue.splitUnit = updates.splitUnit.newValue as string;

          if ("pakUnitsPerSplitUnit" in updates)
            newValue.productionUnitsPerSplitUnit = updates
              .pakUnitsPerSplitUnit.newValue as number;

          if ("splitUnitsPerSaleUnit" in updates)
            newValue.splitUnitsPerSaleUnit = updates.splitUnitsPerSaleUnit
              .newValue as number;

          updateSale.mutate({
            itemId,
            vendorId: sale.vendor,
            saleId,
            newValue,
          });
        });
      addedIds.forEach((id) => {
        const update = instructions[id];

        if (!update.vendor?.newValue) throw "Impossible";

        createSale.mutate({
          vendorId: update.vendor?.newValue as string,
          itemId,
          newValue: {
            itemId,
            isDefaultVendor: (update.isDefault?.newValue ?? false) as boolean,
            pricePerSaleUnit: (update.purchasePrice?.newValue ?? 100) as number,
            saleUnit: (update.saleUnit?.newValue ?? "Unit") as string,
            splitUnit: (update.splitUnit?.newValue ?? "Unit") as string,
            splitUnitsPerSaleUnit: (update.splitUnitsPerSaleUnit?.newValue ?? 1) as number,
            productionUnitsPerSplitUnit: (update.pakUnitsPerSplitUnit?.newValue ?? 1) as number,
          },
        });
      });
    },
    [updateSale, itemId, addedIds]
  );

  const deleteSales = useCallback(
    async (ids: string[]) => {
      await Promise.all(
        ids.map((saleId) => {
          const sale = sales.find(({ uniqueId }) => uniqueId == saleId);
          if (!sale) return;
          return deleteSale.mutateAsync({
            vendorId: sale.vendor as string,
            itemId,
            saleId,
          });
        }
        )
      );
    },
    [deleteSale, sales, itemId],
  );

  const recipes: ItemInRecipeDetail[] = useMemo(() => {
    return (usageQuery.data ?? []).map((d: any) => ({
      uniqueId: d.recipeId as string,
      isMenuItem: !(d.recipeIsPrepItem),
      isModifier: !!d.recipeIsModifier,
      itemName: d.recipeName as string,
      quantity: d.ingredientUnitCount as number,
      unitName: d.ingredientUnitName as string,
    }))
  }, [usageQuery.data]);
  const vendorInstructions = useMemo(() => {
    return vendorInstructionsFactory(
      vendorsQuery?.data?.map(v => ({ id: v.vendor.id, name: v.vendor.name })) ?? [],
    );
  }, [vendorsQuery?.data]);

  const updateDetails = useCallback(
    (updates: UpdateState) => {
      const update = updates[itemId];
      const updateDTO = Object.keys(update).reduce((curr, key) => {
        const nv = update[key].newValue;
        switch (key) {
          case "itemName": curr.name = nv as string; break;
          case "productionUnitName": curr.productionUnitName = nv as string; break;
          case "conversionRatio": curr.ingredientsPerProduct = Number(nv); break;
          case "locations": curr.locationIds = nv as string[]; break;
          case "primaryGroup": curr.primaryGroupId = nv as string; break;
          case "secondaryGroup": curr.secondaryGroupId = nv as string; break;
          case "allergens": curr.allergenIds = nv as string[]; break;
          case "isKey": curr.isKey = nv as boolean; break;
          case "actualized": curr.isActualized = nv as boolean; break;
          case "shelfLifeHours": curr.shelfLifeHours = Number(nv); break;
          case "shelfLifeUnits": curr.shelfLifeUnits = nv as string; break;
          case "maxPar": curr.maxPar = Number(nv); break;
          case "minPar": curr.minPar = Number(nv); break;
          default:
            console.warn("Discarding key", key);
        }
        return curr;
      }, {} as UpdateRawItemDTO | UpdatePrepItemDTO);
      return (isRaw ? updateRaw : updatePrep).mutateAsync({
        itemId,
        updatedItem: updateDTO,
      });
    },
    [isRaw, itemId],
  );

  const preparePrepItem = useCallback(() => {
    return preparePrep.mutateAsync({ itemId, amount: prepCount }).then(closePrepModal);
  }, [itemId, prepCount])

  return {
    itemDetail,
    detailColumns,
    detailInstructions,
    isEditingDetails,
    setIsEditingDetails,
    updateDetails,
    deleteItem,
    recipes,
    recipeColumns,
    recipeInstructions,
    sales,
    vendorColumns,
    vendorInstructions,
    isEditingSales,
    setIsEditingSales,
    addSale,
    saveSales,
    deleteSales,
    showPrepModal,
    openPrepModal,
    closePrepModal,
    preparePrepItem,
    prepCount,
    setPrepCount,
  };
};
export default useItemDetail;
