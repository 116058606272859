import axios from "axios";
import firebase from "config/firebase";
import _ from "lodash";
import { FirebaseDiscountDoc } from "views/admin/menus&Products/discounts/discountTypes";

import {
  addListener,
  clearListeners,
  clearUserListener,
  setUserUnsubscribe,
} from "controllers/listeners";

import { generateFirstDocsDocument } from "models/docs";

import { FirebaseCashDrawerDoc, FirebaseCashEvents } from "types/cashDrawers";
import { DocumentData } from "types/document";
import { FirebaseMenuDoc } from "types/menus";
import { FirebaseOrderDoc, FirebaseTabDoc } from "types/order";
import { FirebaseOrderDisplayDoc } from "types/orderDisplay";
import { FirebasePrintersDoc } from "types/printers";
import { FirebaseProductDoc } from "types/products";
import { FirebaseServiceAreaDoc } from "types/serviceArea";
import { FirebaseTableDoc } from "types/tables";

import { receiveAllStaffMembers } from "model/actions/allStaffMembersAC";
import { receiveAnnouncements } from "model/actions/announcementsAC";
import { receiveBusinesses } from "model/actions/businessesAC";
import { receiveCashDrawers } from "model/actions/cashDrawersAC";
import { receiveCashEvents } from "model/actions/cashEventsAC";
import { receiveDiscounts } from "model/actions/discountsAC";
import { receiveDocs } from "model/actions/docsAC";
import { receiveDraftSchedules } from "model/actions/draftSchedules";
import { receiveEnterpriseDocs } from "model/actions/enterpriseDocsAC";
import { changeEnterpriseInit } from "model/actions/enterpriseInitAC";
import { receiveFixedAvailabilities } from "model/actions/fixedAvailabilities";
import { receiveFixedSchedules } from "model/actions/fixedSchedules";
import { receiveAvailableLocations } from "model/actions/locationAC";
import { receivePayouts } from "model/actions/manualPayoutsAC";
import { receiveMenuCategories } from "model/actions/menuCategoriesAC";
import { receiveMenus } from "model/actions/menusAC";
import { receiveModifierOptions } from "model/actions/modifierOptionsAC";
import { receiveAccounts } from "model/actions/multipleAccountsAC";
import { receiveOrderDisplays } from "model/actions/orderDisplaysAC";
import { receiveOrders } from "model/actions/ordersAC";
import { receivePayoutLedgers } from "model/actions/payoutLedgersAC";
import { receivePrinters } from "model/actions/printersAC";
import { receiveProductTypes } from "model/actions/productTypesAC";
import { receiveScheduleEvents } from "model/actions/scheduleEvents";
import { receiveServiceAreas } from "model/actions/serviceAreasAC";
import { receivePhysicalTables } from "model/actions/tablesAC";
import { receiveTabs } from "model/actions/tabsAC";
import { receiveJobFunctions } from "model/actions/tangoSettingsAC";
import { receiveWorkspaces } from "model/actions/workspacesAC";

import { receiveAvailabilities } from "../model/actions/availabilities";
import { receiveBusiness } from "../model/actions/businessAC";
import { receiveBusinessSettings } from "../model/actions/businessSettings";
import { receiveFellowStaffMembers } from "../model/actions/fellowStaffMembersAc";
import { receiveModifiers } from "../model/actions/modifiersAC";
import { receiveProducts } from "../model/actions/productsAC";
import { logoutUser, receiveUser } from "../model/actions/userAC";
import { receiveWorkEvents } from "../model/actions/workEvents";
import store from "../model/store";
import {
  fetchAccountIdsWithParentId,
  fetchAccountsByIds,
  fetchBusinessSettingsByBusinessIds,
  fetchBusinessesByAccountsIds,
  fetchCollectionByBusinessIds,
  fetchStaffMembersByBusinessIds,
  getAccountByBusinessId,
} from "./accounts";
import { fetchBoardTickets, fetchBoards } from "./boards";
import { fetchChannels } from "./chat";
import {
  composeUsableAnnouncement,
  composeUsableAvailability,
  composeUsableBusinessSettings,
  composeUsableCashDrawerEvent,
  composeUsableDiscount,
  composeUsableDocs,
  composeUsableDraftSchedule,
  composeUsableFixedAvailability,
  composeUsableFixedSchedule,
  composeUsableManualPayout,
  composeUsableMenus,
  composeUsableModifierOptions,
  composeUsableModifiers,
  composeUsableOrder,
  composeUsableOrderDisplay,
  composeUsablePayoutLedgers,
  composeUsablePrinters,
  composeUsableProductTypes,
  composeUsableProducts,
  composeUsableScheduleEvent,
  composeUsableServiceArea,
  composeUsableStaffMember,
  composeUsableTab,
  composeUsableTable,
  composeUsableTangoBusiness,
  composeUsableWorkEvent,
  compouseUsableMenuCategories,
  compuseUsableCashDrawer,
} from "./composableTypes";
import {
  apiCall,
  fetchCollectionDocument,
  subscribeToCollectionDocument,
  subscribeToCollectionDocuments,
  subscribeToCollectionDocumentsForDataInThePastNMonths,
  subscribeToCollectionDocumentsWithTwoConditions,
  subscribeToSubCollectionDocuments,
} from "./core";
import { fetchSchedules } from "./schedule";

const { firestore } = firebase;
const { auth } = firebase;

export const generateBearerToken = async () => {
  try {
    const idToken = await auth().currentUser?.getIdToken();
    if (!idToken) {
      return null;
    }
    return `Bearer ${idToken}`;
  } catch (e) {
    console.log("Oops, something went wrong", e);
    alert("Oops, something went wrong, please try again later...");
  }
};

const fetchBusiness = (staffMember: StaffMember): void => {
  subscribeToCollectionDocument<TangoBusiness>(
    "Businesses",
    staffMember.businessId,
    receiveBusiness,
    "business",
    composeUsableTangoBusiness
  );
};

const fetchAvailabilities = (staffMember: StaffMember) => {
  subscribeToCollectionDocuments<Availability>(
    "Availability",
    ["businessId", "==", staffMember.businessId],
    receiveAvailabilities,
    "availabilities",
    composeUsableAvailability
  );
};

const fetchFellowStaffMembers = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<StaffMember>(
    "Staff",
    ["businessId", "==", currentUser.businessId],
    receiveFellowStaffMembers,
    "fellowStaffMembers",
    composeUsableStaffMember
  );
};

export async function signOut() {
  try {
    if (auth().currentUser) {
      await auth().signOut();
    }
  } catch (error: any) {
    console.log("signOut error:", error.message);
  }
}

const fetchWorkEvents = (currentUser: StaffMember): void => {
  subscribeToCollectionDocuments<WorkEvent>(
    "WorkEvents",
    ["businessId", "==", currentUser.businessId],
    receiveWorkEvents,
    "workEvents",
    composeUsableWorkEvent
  );
};

const fetchFixedAvailabilities = (staffMember: StaffMember) => {
  subscribeToCollectionDocuments<FixedAvailability>(
    "FixedAvailabilities",
    ["businessId", "==", staffMember.businessId],
    receiveFixedAvailabilities,
    "fixedAvailabilities",
    composeUsableFixedAvailability
  );
};

const fetchFixedSchedules = (staffMember: StaffMember) => {
  subscribeToCollectionDocuments<TangoFixedSchedule>(
    "FixedSchedules",
    ["businessId", "==", staffMember.businessId],
    receiveFixedSchedules,
    "fixedSchedules",
    composeUsableFixedSchedule
  );
};

const fetchAnnouncements = (staffMember: StaffMember) => {
  subscribeToCollectionDocuments<Announcement>(
    "Announcements",
    ["businessId", "==", staffMember.businessId],
    receiveAnnouncements,
    "announcements",
    composeUsableAnnouncement
  );
};

const fetchDraftSchedules = (staffMember: StaffMember) => {
  subscribeToCollectionDocuments<TangoDraftSchedule>(
    "DraftSchedule",
    ["businessId", "==", staffMember.businessId],
    receiveDraftSchedules,
    "draftSchedules",
    composeUsableDraftSchedule
  );
};

const fetchAndSaveAvailableLocations = async () => {
  try {
    const authorizationToken = await generateBearerToken();
    if (authorizationToken) {
      const url = apiCall(`nestApi/staff/available-locations`);

      const res = await axios({
        method: "GET",
        url,
        headers: { Authorization: authorizationToken },
        validateStatus: () => true,
      });

      console.log("res.status", res.status);
      console.log("res.data", res.data);

      if (res.status === 200) {
        const locations = res.data as TangoBusiness[];
        store.dispatch(receiveAvailableLocations(locations.filter((x) => !!x)));
      } else {
        console.log("Something went wrong...");
      }
    }
  } catch (e) {
    console.log("Error: subscribeToLocations ", e);
  }
};

export const switchCurrentLocation = async (
  uid: string,
  locationId: string
) => {
  clearListeners();
  clearUserListener();

  const { staffMember, business } = await NEW_setUserSubscription(
    uid,
    true,
    locationId
  );

  if (staffMember && business) {
    store.dispatch(receiveBusiness(business));
    initializeData(staffMember);
  }
};

export const NEW_setUserSubscription = async (
  uid: any,
  admin = true,
  locationId: string
) => {
  const userId: string = uid;
  console.log("userId", userId);
  console.log("admin", admin);
  const staffSn = await firestore()
    .collection("Staff")
    .where("uid", "==", userId)
    .where("businessId", "==", locationId)
    .get();

  const staffData = staffSn?.docs?.[0]?.data() as StaffMember;
  if (!staffData || !staffData.businessId || !staffData.isAdmin) {
    await auth().signOut();
    return { data: null };
  }
  const businessSn = await firestore()
    .collection("Businesses")
    .doc(staffData.businessId)
    .get();

  const business = businessSn.data() as TangoBusiness;

  if (business && staffData) {
    const staffMember = composeUsableStaffMember(staffData);
    await localStorage.setItem("locationId", locationId);
    // @ts-ignore
    store.dispatch(receiveUser(staffMember));
    subscribeToCollectionDocument<StaffMember>(
      "Staff",
      staffData.id,
      receiveUser,
      null,
      composeUsableStaffMember,
      setUserUnsubscribe
    );
    return { staffMember, business };
  } else {
    await auth().signOut();
    return { data: null };
  }
};

export const setUserSubscription = async (
  authData: any,
  admin = true
): Promise<Result> => {
  const locationId = await localStorage.getItem("locationId");
  const userId: string = authData.uid || authData.user_id;
  console.log("userId", userId);
  console.log("admin", admin);

  const staffSn = await firestore()
    .collection("Staff")
    .where("uid", "==", userId)
    .where("deleted", "==", false)
    .get();
  let staffData: StaffMember | undefined;
  const allStaffMembers: StaffMember[] = staffSn.docs.map(
    (doc) => doc.data() as StaffMember
  );
  if (locationId) {
    staffData = allStaffMembers.find((sm) => sm.businessId === locationId);
  }
  if (!staffData) {
    console.log("Staff: ", staffSn.docs);
    console.log("User id: ", userId);
    staffData = staffSn.docs?.[0]?.data() as StaffMember;
  }
  console.log("staffData nbla bla bl a", staffData);
  if (!staffData || !staffData.businessId || !staffData.isAdmin) {
    await auth().signOut();
    return { data: null };
  }
  const businessSn = await firestore()
    .collection("Businesses")
    .doc(staffData.businessId)
    .get();

  const business = businessSn.data();

  console.log("business when staffData", business);
  console.log("staffData when staffData", staffData);

  if (business && staffData) {
    const staffMember = composeUsableStaffMember(staffData);
    // @ts-ignore
    store.dispatch(receiveUser(staffMember));
    subscribeToCollectionDocument<StaffMember>(
      "Staff",
      staffData.id,
      receiveUser,
      null,
      composeUsableStaffMember,
      setUserUnsubscribe
    );
    return { data: { ...staffMember } };
  } else {
    await auth().signOut();
    return { data: null };
  }
};

const fetchJobFunctions = async () => {
  try {
    await fetchCollectionDocument<TangoJobFunctions>(
      "TangoSettings",
      "JobFunctions",
      receiveJobFunctions
    );
  } catch (error) {
    console.log("error fetching job functions", error);
  }
};

const fetchBusinessSettings = async (currentUser: StaffMember) => {
  try {
    await subscribeToCollectionDocument<TangoBusinessSettings>(
      "BusinessSettings",
      currentUser.businessId,
      receiveBusinessSettings,
      "businessSettings",
      composeUsableBusinessSettings
    );
  } catch (error) {
    console.log("error fetchBusinessSettings", error);
  }
};

const fetchEnterpriseBusinessesCollection = async (accountIds: string[]) => {
  try {
    const businesses = await fetchBusinessesByAccountsIds(accountIds);
    store.dispatch(receiveBusinesses(businesses));
    return businesses;
  } catch (error) {
    console.log("error fetchBusinessSettings", error);
  }
};

const fetchEnterpriseAccountsCollection = async (accountIds: string[]) => {
  try {
    const accounts = await fetchAccountsByIds(accountIds);
    store.dispatch(receiveAccounts(accounts));
    return accounts;
  } catch (error) {
    console.log("error fetchBusinessSettings", error);
  }
};

const fetchAllStaffCollection = async (businessIds: string[]) => {
  try {
    const staffMembers = await fetchStaffMembersByBusinessIds(businessIds);
    store.dispatch(receiveFellowStaffMembers(staffMembers));
  } catch (error) {
    console.log("error: ", error);
  }
};

const fetchAllBusinessSettings = async (businessIds: string[]) => {
  try {
    const businessSettings = await fetchBusinessSettingsByBusinessIds(
      businessIds
    );
    store.dispatch(receiveBusinessSettings(businessSettings));
  } catch (error) {
    console.log("error: ", error);
  }
};

const fetchAllProducts = async (
  businessIds: string[],
  // modifiersRef: { [T: string]: string },
  businessId: string
) => {
  try {
    const products = await fetchCollectionByBusinessIds(
      [businessId],
      "Products",
      composeUsableProducts
    );
    // const { uniqueItems, ref } = getUniqueInstance(
    //   products,
    //   "nameExternal",
    //   businessId,
    //   modifiersRef,
    //   "modifiers",
    //   mapItemsByReference
    // );
    const uniqueProducts: FirebaseProductDoc[] =
      products as FirebaseProductDoc[];
    store.dispatch(receiveProducts(uniqueProducts));
    // return ref;
  } catch (error) {
    console.log("error: ", error);
  }
};

const mapItemsByReference = (
  subItems: string[],
  subItemsRef: { [T: string]: string }
) => {
  return subItems.map((option: string) => {
    const id = subItemsRef[option];
    return id;
  });
};

const replaceItemInMap = (
  oldItem: string,
  newItem: string,
  ref: { [T: string]: string }
) => {
  return Object.keys(ref).reduce((acc, val) => {
    if (acc[val] === oldItem) {
      acc[val] = newItem;
    }
    return ref;
  }, ref);
};

const getUniqueInstance = (
  allItems: DocumentData[],
  key: string,
  businessId: string,
  subItemsRef?: DocumentData,
  subItemKey?: string,
  remap?: (subItems: string[], subItemsRef: DocumentData) => string[]
) => {
  const uniqueItems: DocumentData[] = [];
  const ref: { [T: string]: string } = {};
  for (const item of allItems) {
    if (item.businessId === businessId) {
      const index = uniqueItems.findIndex((i) => i[key] === item[key]);
      if (index === -1) {
        if (remap && subItemsRef && subItemKey) {
          const subItems: string[] = item[subItemKey];
          const newSubItems = remap(subItems, subItemsRef);
          item[subItemKey] = newSubItems;
        }
        uniqueItems.push(item);
        ref[item.id] = item.id;
      } else {
        // This implies this item was updated in the enterprise
        // level before and therefore should be the item picked
        // to be editted again regardless of the business to the item
        // selected
        // if (item?.enterpriseUpdatedDocument) {
        //   ref = replaceItemInMap(uniqueItems[index].id, item.id, ref);
        //   ref[item.id] = item.id;
        // } else {

        // }
        ref[item.id] = uniqueItems[index].id;
      }
    }
  }
  return { uniqueItems, ref };
};

const fetchAllModifiers = async (
  businessIds: string[],
  // modifierOptionsRef: { [T: string]: string },
  businessId: string
) => {
  try {
    const modifiers = await fetchCollectionByBusinessIds(
      [businessId],
      "Modifiers",
      composeUsableModifiers
    );
    // const { uniqueItems, ref } = getUniqueInstance(
    //   modifiers,
    //   "nameExternal",
    //   businessId,
    //   modifierOptionsRef,
    //   "options",
    //   mapItemsByReference
    // );
    const uniqueModifiers: FirebaseModifierDoc[] =
      modifiers as FirebaseModifierDoc[];
    store.dispatch(receiveModifiers(uniqueModifiers));
    // return ref;
  } catch (error) {
    console.log("error: ", error);
  }
};

const fetchAllModifierOptions = async (
  businessIds: string[],
  businessId: string
) => {
  try {
    const modifierOptions = await fetchCollectionByBusinessIds(
      [businessId],
      "ModifierOptions",
      composeUsableModifierOptions
    );
    // const { uniqueItems, ref } = getUniqueInstance(
    //   modifierOptions,
    //   "name",
    //   businessId
    // );
    const uniqueModifierOptions: FirebaseModifierOptionsDoc[] =
      modifierOptions as FirebaseModifierOptionsDoc[];
    store.dispatch(receiveModifierOptions(uniqueModifierOptions));
    // return ref;
  } catch (error) {
    console.log("error: ", error);
  }
};

const fetchAllMenuCategories = async (
  businessIds: string[],
  // productsRef: { [T: string]: string },
  businessId: string
) => {
  try {
    const menuCategories = await fetchCollectionByBusinessIds(
      [businessId],
      "MenuCategories",
      compouseUsableMenuCategories
    );
    // const { uniqueItems, ref } = getUniqueInstance(
    //   menuCategories,
    //   "name",
    //   businessId,
    //   productsRef,
    //   "products",
    //   mapItemsByReference
    // );
    const uniqueMenuCategories: FirebaseMenuCategoryDoc[] =
      menuCategories as FirebaseMenuCategoryDoc[];
    store.dispatch(receiveMenuCategories(uniqueMenuCategories));
    // return ref;
  } catch (error) {
    console.log("error: ", error);
  }
};

const fetchAllMenus = async (
  businessIds: string[],
  // menusRef: { [T: string]: string },
  businessId: string
) => {
  try {
    const menus = await fetchCollectionByBusinessIds(
      [businessId],
      "Menus",
      composeUsableMenus
    );
    // const { uniqueItems, ref } = getUniqueInstance(
    //   menus,
    //   "nameExternal",
    //   businessId,
    //   menusRef,
    //   "categories",
    //   mapItemsByReference
    // );
    const uniqueMenus: FirebaseMenuDoc[] = menus as FirebaseMenuDoc[];
    store.dispatch(receiveMenus(uniqueMenus));
    // return ref;
  } catch (error) {
    console.log("error: ", error);
  }
};

const fetchAllDiscounts = async (businessId: string) => {
  try {
    const discounts = await fetchCollectionByBusinessIds(
      [businessId],
      "Discounts",
      composeUsableDiscount
    );
    const uniqueDiscounts: FirebaseDiscountDoc[] =
      discounts as FirebaseDiscountDoc[];
    store.dispatch(receiveDiscounts(uniqueDiscounts));
  } catch (error) {
    console.log("error: ", error);
  }
};

const fetchProducts = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<FirebaseProductDoc>(
    "Products",
    ["businessId", "==", currentUser.businessId],
    receiveProducts,
    "products",
    composeUsableProducts
  );
};

const fetchProductTypes = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<FirebaseDeliverectProductType>(
    "DeliverectProductTypes",
    ["businessId", "==", currentUser.businessId],
    receiveProductTypes,
    "productTypes",
    composeUsableProductTypes
  );
};

export const fetchDocs = (currentUser: StaffMember) => {
  subscribeToSubCollectionDocuments<FilesAndFolders>(
    "Docs",
    "FilesAndFolders",
    currentUser.businessId,
    ["businessId", "==", currentUser.businessId],
    receiveDocs,
    "docs",
    composeUsableDocs
  );
};

export const fetchEnterpriseDocs = (accountId = "", parentId = "") => {
  if (parentId) {
    subscribeToSubCollectionDocuments<FilesAndFolders>(
      "EnterpriseDocs",
      "FilesAndFolders",
      parentId,
      ["parentAccountId", "==", parentId],
      receiveEnterpriseDocs,
      "enterpriseDocs",
      composeUsableDocs
    );
  } else {
    subscribeToSubCollectionDocuments<FilesAndFolders>(
      "EnterpriseDocs",
      "FilesAndFolders",
      accountId,
      ["accountId", "==", accountId],
      receiveEnterpriseDocs,
      "enterpriseDocs",
      composeUsableDocs
    );
  }
};

const fetchModifierOptions = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<FirebaseModifierOptionsDoc>(
    "ModifierOptions",
    ["businessId", "==", currentUser.businessId],
    receiveModifierOptions,
    "modifierOptions",
    composeUsableModifierOptions
  );
};

const fetchModifiers = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<FirebaseModifierDoc>(
    "Modifiers",
    ["businessId", "==", currentUser.businessId],
    receiveModifiers,
    "modifiers",
    composeUsableModifiers
  );
};

const fetchMenuCategories = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<FirebaseMenuCategoryDoc>(
    "MenuCategories",
    ["businessId", "==", currentUser.businessId],
    receiveMenuCategories,
    "menuCategories",
    compouseUsableMenuCategories
  );
};

const fetchMenus = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<DocumentData>(
    "Menus",
    ["businessId", "==", currentUser.businessId],
    receiveMenus,
    "menus",
    composeUsableMenus
  );
};

const fetchOrderDisplays = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<FirebaseOrderDisplayDoc>(
    "OrderDisplays",
    ["businessId", "==", currentUser.businessId],
    receiveOrderDisplays,
    "orderDisplays",
    composeUsableOrderDisplay
  );
};

const fetchScheduleEvents = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<ScheduleEvent>(
    "ScheduleEvents",
    ["businessId", "==", currentUser.businessId],
    receiveScheduleEvents,
    "scheduleEvents",
    composeUsableScheduleEvent
  );
};

const fetchServiceAreas = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<FirebaseServiceAreaDoc>(
    "ServiceAreas",
    ["businessId", "==", currentUser.businessId],
    receiveServiceAreas,
    "serviceAreas",
    composeUsableServiceArea
  );
};

const fetchPhysicalTables = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<FirebaseTableDoc>(
    "Tables",
    ["businessId", "==", currentUser.businessId],
    receivePhysicalTables,
    "physicalTables",
    composeUsableTable
  );
};

const fetchCashDrawers = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<FirebaseCashDrawerDoc>(
    "CashDrawer",
    ["businessId", "==", currentUser.businessId],
    receiveCashDrawers,
    "tables",
    compuseUsableCashDrawer
  );
};

const fetchOrdersForReporting = (currentUser: StaffMember) => {
  subscribeToCollectionDocumentsForDataInThePastNMonths<FirebaseOrderDoc>(
    "Orders",
    ["businessId", "==", currentUser.businessId],
    receiveOrders,
    "ordersForReporting",
    composeUsableOrder,
    1
  );
};

const fetchCashEvents = (currentUser: StaffMember) => {
  subscribeToCollectionDocumentsForDataInThePastNMonths<FirebaseCashEvents>(
    "CashEvents",
    ["businessId", "==", currentUser.businessId],
    receiveCashEvents,
    "cashEventsForReporting",
    composeUsableCashDrawerEvent,
    2
  );
};

const fetchTabsForReporting = (currentUser: StaffMember) => {
  subscribeToCollectionDocumentsForDataInThePastNMonths<FirebaseTabDoc>(
    "Tabs",
    ["businessId", "==", currentUser.businessId],
    receiveTabs,
    "tabsForReporting",
    composeUsableTab,
    1
  );
};

const fetchDiscounts = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<FirebaseDiscountDoc>(
    "Discounts",
    ["businessId", "==", currentUser.businessId],
    receiveDiscounts,
    "discounts",
    composeUsableDiscount
  );
};

const fetchManualPayouts = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<ManualPayouts>(
    "ManualPayouts",
    ["businessId", "==", currentUser.businessId], //
    receivePayouts,
    "payouts",
    composeUsableManualPayout
  );
};

const fetchInstantPayouts = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<ManualPayouts>(
    "InstantPayouts",
    ["businessId", "==", currentUser.businessId], //
    receivePayouts,
    "payouts",
    composeUsableManualPayout
  );
};

const fetchPayoutLedgers = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<PayoutLedger>(
    "PayoutsLedger",
    ["businessId", "==", currentUser.businessId], //  "5fc13f6dc70feebea3ed0bbb"
    receivePayoutLedgers,
    "payoutLedgers",
    composeUsablePayoutLedgers
  );
};

const fetchPrinters = (currentUser: StaffMember) => {
  subscribeToCollectionDocuments<FirebasePrintersDoc>(
    "Printers",
    ["businessId", "==", currentUser.businessId],
    receivePrinters,
    "printers",
    composeUsablePrinters
  );
};

const fetchWorkspaces = (currentUser: StaffMember) => {
  const unsubscribe = firebase
    .firestore()
    .collection("Workspaces")
    .where("participants", "array-contains", currentUser.id)
    .onSnapshot(
      async (listenerSn) => {
        const workspaces: TangoWorkspace[] = [];
        listenerSn.forEach((doc) =>
          workspaces.push(doc.data() as TangoWorkspace)
        );
        store.dispatch(receiveWorkspaces(workspaces));
      },
      (err) => {
        console.warn("fetch workspaces error", err);
      }
    );
  addListener("workspaces", unsubscribe);
};

const getMenusAndProducts = async (
  businessIds: string[],
  businessId: string
) => {
  try {
    await fetchAllModifierOptions(businessIds, businessId);
    await fetchAllModifiers(businessIds, businessId);
    await fetchAllProducts(businessIds, businessId);
    await fetchAllMenuCategories(businessIds, businessId);
    await fetchAllMenus(businessIds, businessId);
    await fetchAllDiscounts(businessId);
    return true;
  } catch (err) {
    console.log("ERR: ", err);
    return true;
  }
};

export const initializeEnterpriseData = async (
  user: StaffMember
): Promise<Result> => {
  if (user) {
    store.dispatch(changeEnterpriseInit(false));
    store.dispatch(receiveUser(user));
    if (user.accountId) {
      const accountWithParentId = await fetchAccountIdsWithParentId(
        user.accountId
      );
      if (accountWithParentId.length > 0) {
        const accountIds = _.uniq(accountWithParentId.map((i) => i.id));

        // Generate a Docs document for a new parent company
        await generateFirstDocsDocument(
          user.businessId,
          user.accountId,
          accountWithParentId[0].parentId
        );

        const businesses = await fetchEnterpriseBusinessesCollection(
          accountIds
        );
        const accounts = await fetchEnterpriseAccountsCollection(accountIds);
        if (businesses) {
          const businessIds = businesses.map((business) => business.id);
          fetchAllStaffCollection(businessIds);
          fetchAllBusinessSettings(businessIds);
          const success = await getMenusAndProducts(
            businessIds,
            user.businessId
          );
          if (success) {
            store.dispatch(changeEnterpriseInit(success));
          }
        }
        // Get the business document for the business as
        // a fallback even in enterprise access - just because
        // we can always find the information
        if (user.businessId) {
          fetchBusiness(user);
        }

        fetchEnterpriseDocs(user.accountId, accountWithParentId[0].parentId);
        fetchBoards(user, true);
        fetchBoardTickets(user, true);

        // Business in non-franchise scenarios should still be able to access
        // the enterprise level with the correct credentials
      } else {
        // Generate a Docs document for a new account
        await generateFirstDocsDocument(user.businessId, user.accountId);

        const businesses = await fetchEnterpriseBusinessesCollection([
          user.accountId,
        ]);
        if (businesses) {
          const businessIds = businesses.map((business) => business.id);
          const accountIds = _.uniq(
            businesses.map((business) => business.accountId)
          );
          fetchAllStaffCollection(businessIds);
          fetchAllBusinessSettings(businessIds);
          fetchAccountsByIds(accountIds);
          await getMenusAndProducts(businessIds, user.businessId);
        }
        fetchEnterpriseDocs(user.accountId);
      }
    }
  }

  return { data: user };
};

export const initializeData = async (user: StaffMember): Promise<Result> => {
  try {
    store.dispatch(changeEnterpriseInit(true));
    // Generate a Docs document for a new business
    await generateFirstDocsDocument(user.businessId);

    // Get the enterprise docs if applicable
    const account = await getAccountByBusinessId(user.businessId);
    if (account) {
      const accountWithParentId = await fetchAccountIdsWithParentId(account.id);
      if (accountWithParentId.length > 0) {
        console.log({ accountId: account.id, accountWithParentId });
        fetchEnterpriseDocs(account.id, accountWithParentId[0].parentId);
      }
    }

    // // fetchBusiness(user);
    fetchFellowStaffMembers(user);
    // fetchWorkEvents(user);
    // fetchChannels(user);

    fetchWorkspaces(user);

    fetchBoards(user);
    fetchBoardTickets(user);

    // // fetchJobFunctions();
    // fetchAvailabilities(user);
    fetchBusinessSettings(user);
    fetchSchedules(user);
    fetchProducts(user);
    fetchProductTypes(user);
    fetchDocs(user);
    fetchModifiers(user);
    fetchModifierOptions(user);
    fetchMenuCategories(user);
    fetchMenus(user);
    fetchOrderDisplays(user);
    fetchScheduleEvents(user);
    fetchServiceAreas(user);
    fetchPhysicalTables(user);
    fetchCashDrawers(user);
    // fetchOrdersForReporting(user);
    // fetchTabsForReporting(user);
    fetchDiscounts(user);
    fetchFixedAvailabilities(user);
    fetchFixedSchedules(user);
    fetchDraftSchedules(user);
    fetchCashEvents(user);
    fetchManualPayouts(user);
    fetchInstantPayouts(user);
    fetchPayoutLedgers(user);
    fetchPrinters(user);
    fetchAnnouncements(user);
    fetchAndSaveAvailableLocations();

    return { data: user };
  } catch (error: any) {
    console.error("fetch user error", error);
    return { error };
  }
};
