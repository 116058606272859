import React, { useCallback } from "react";
import tangoComponents from "@tangopay/tango-ui-library";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { VendorWithSale } from "controllers/vendors";
import { ColumnInstruction } from "components/Table/HorizontalTable";
import CurrencyInput from "components/Inputs/CurrencyInput";

const { Dropdown } = tangoComponents;

const prefix = "new__lineItem";
const costPrefix = prefix + "__cost"
export const purchasePrefix = prefix + "__purchase";
export const expensePrefix = costPrefix + "__expense";
export const taxPrefix = costPrefix + "__tax";
export type LineItem = {
  uniqueId: string;
  sale: string | null;
  purchaseCount: number | null;
  totalCost: number | null;
  unitCost: number | null;
  description?: string;
}
export type Invoice = {
  uniqueId: string;
  date: string;
  purchaseId: string;
  vendorName: string;
  totalPurchase: number;
  totalTax: number;
  totalExpense: number;
};


type Instruction = RenderInstruction<LineItem>
const instructions: { [x: string]: Instruction } = {}
export const instructionsFactory = (
  vendor?: VendorWithSale | null
): { [attr: string]: Instruction } => {
  const saleOpts = vendor?.itemsSold?.map((offer) => {
    const {
      id,
      rawItem,
      pricePerSaleUnit,
      productionUnit,
      productionUnitsPerSplitUnit,
      saleUnit,
      splitUnitsPerSaleUnit,
      splitUnit
    } = offer;
    const label = `${rawItem.name
      } - ${(pricePerSaleUnit / 100).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
      }/${saleUnit}, ${splitUnitsPerSaleUnit
      } ${splitUnit}/${saleUnit}, ${productionUnitsPerSplitUnit
      } ${productionUnit}/${splitUnit}`;
    return {
      value: id,
      label,
    }
  });
  saleOpts?.sort((a, b) => a.label.localeCompare(b.label));

  return {
    ...instructions,
    purchaseCount: {
      type: "number",
    },
    sale: {
      type: "complex-custom",
      viewComponent: null,
      editComponent: ({ fullObject, onChange }) => {
        const isCost = fullObject.uniqueId.startsWith(costPrefix);
        const updateName = useCallback((e) => {
          onChange?.({ ...fullObject, description: e.target.value })
        }, [fullObject, onChange]);

        const updateSale = useCallback(
          (selected) => {
            if (!selected) {
              onChange?.({
                ...fullObject,
                sale: null,
                unitCost: null,
              });
            } else {
              const item = vendor?.itemsSold?.find(i => i.id == selected.value);

              onChange?.({
                ...fullObject,
                sale: selected.value ?? null,
                unitCost: item?.pricePerSaleUnit ?? null,
              });
            }
          },
          [fullObject, onChange, vendor?.itemsSold]
        );

        if (isCost) {
          // name of the cost?
          return <input
            type="text"
            value={(fullObject.description) ?? undefined}
            onChange={updateName}
            className={"border-0 text-grey-3"}
            placeholder={fullObject.uniqueId.startsWith(taxPrefix) ? "Tax" : "Expense"}
          />
        } else {
          return <Dropdown
            options={saleOpts}
            value={saleOpts?.find((opt) => opt.value == fullObject.sale)}
            onChange={updateSale}
            size="small"
          />
        }
      },
    },
    unitCost: {
      type: "complex-custom",
      viewComponent: null,
      editComponent: ({ fullObject, onChange }) => {
        const cost = fullObject.unitCost ?? 0;
        const handler = useCallback((unitCost: number) => {
          onChange?.({ ...(fullObject), unitCost })
        }, [fullObject, onChange]);
        return <CurrencyInput value={cost} onValueChange={handler} />
      },
    },
    fullCost: {
      readOnly: true,
      type: "complex-custom",
      viewComponent: ({ fullObject }) => {
        const cost = (fullObject.purchaseCount ?? 0) * (fullObject.unitCost ?? 0);
        return <div>{cost
          ? (cost / 100).toLocaleString("en-US", { style: "currency", currency: "USD" })
          : ""
        }</div>
      },
      editComponent: null,
    }
  };
};

export const columns: ColumnInstruction<LineItem>[] = [
  { type: "data", attribute: "purchaseCount", header: "quantity" },
  { type: "data", attribute: "sale", header: "Item" },
  { type: "projection", attribute: "unitCost", header: "unit cost" },
  { type: "projection", attribute: "fullCost", header: "total cost" },
];

type InvoiceInstruction = RenderInstruction<Invoice>;

export const invoiceInstructions: { [x: string]: InvoiceInstruction } = {
  totalCost: {
    type: "complex-custom",
    readOnly: true,
    viewComponent: ({ fullObject }) => <div>{(((fullObject.totalExpense ?? 0) + (fullObject.totalTax ?? 0) + (fullObject.totalPurchase ?? 0)) / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })}</div>,
    editComponent: null,
  },
  vendorName: {
    type: "default",
    readOnly: true,
  },
  totalExpense: {
    type: "currency",
    readOnly: true,
  },
  totalTax: {
    type: "currency",
    readOnly: true,
  },
  totalPurchase: {
    type: "currency",
    readOnly: true,
  },
  // date?
};
export const detailColumns: ColumnInstruction<Invoice>[] = [
  { header: "Vendor", attribute: "vendorName", type: "data" },
  { header: "Invoice Date", attribute: "date", type: "data" },
  { header: "Invoice Number", attribute: "purchaseId", type: "data" },
  { header: "Invoice Total", attribute: "totalCost", type: "projection" },
];

export const summaryColumns: ColumnInstruction<Invoice>[] = [
  { header: "Items", attribute: "totalPurchase", type: "data" },
  { header: "Expenses", attribute: "totalExpense", type: "data" },
  { header: "Taxes", attribute: "totalTax", type: "data" },
  { header: "Invoice Total", attribute: "totalCost", type: "projection" },
];