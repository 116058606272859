import { useCallback, useEffect, useMemo, useState } from "react";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction, UpdateState } from "components/Table/HorizontalTable";
import { useSelector } from "react-redux";
import { RootState } from "model/store";
import { CashEventStaffMember, fetchStaffMembers, FlexCashEvents, FlexCashEventsWithStaff, PayoutDTO, Payouts, usePayouts, useSetPayouts, useStaffMembers } from "controllers/payouts";
import { useUniqueIdList } from "../../InventoryManagement/hooks";

type PayoutsProps = {
    uniqueId: string;
    cashEventId: string;
    date: any;
    amount: string;
    type: string;
    staff: string;
    reason: string;
};

const columns: ColumnInstruction<PayoutsProps>[] = [
    { type: "projection", header: "Date", attribute: "date" },
    { type: "data", header: "Amount", attribute: "amount" },
    { type: "data", header: "Type", attribute: "type" },
    { type: "data", header: "Name", attribute: "staff" },
    { type: "data", header: "Reason", attribute: "reason" },
];

const instructions: { [x: string]: RenderInstruction<PayoutsProps> } = {};

instructions.date = {
    type: "complex-custom",
    viewComponent: ({ fullObject }) => {
        return <div>
            {new Date(fullObject.date._seconds * 1000).toDateString()}
        </div>
    },
    editComponent: null
}
instructions.type = {
    type: "select",
    options: [
        {
            value: "Paid Out",
            label: "Paid Out",
        },
        // {
        //     value: "Paid In",
        //     label: "Paid In",
        // }
    ],
    placeholder: "select a type",
};

const prefix = "new__payout__";

const usePayoutsScreen = () => {
    const noOp = () => Promise.resolve(true);
    const logIt = (args: string[]) => console.log(args);
    const businessId: string = useSelector(
        (state: RootState) => state.business as TangoBusiness
    )?.id;
    const currency: string = useSelector(
        (state: RootState) => state.business as TangoBusiness
    )?.currency;
    const [lastPayoutId, setLastPayoutId] = useState('start');
    const [isEditing, setEditing] = useState(false);
    const { addedIds, push, remove, clear } = useUniqueIdList(prefix);
    const [deletedIds, setDeletedIds] = useState<{ [id: string]: true }>({});

    useEffect(() => {
        if (!isEditing) {
            clear();
            setDeletedIds({});
        }
    }, [isEditing, clear]);

    const addPayout = useCallback(() => {
        setEditing(true);
        push();
    }, [push]);

    const query = usePayouts(businessId, lastPayoutId);
    const update = useSetPayouts(businessId);
    const cashEvents = query.data ?? [];

    const data: PayoutsProps[] = []
    cashEvents.length > 0 && cashEvents.forEach((cashEventsWithStaff: FlexCashEventsWithStaff) => {
        cashEventsWithStaff.cashEvent.payouts.forEach((payout: Payouts, index: number) => {
            const payoutEvent: PayoutsProps = {
                uniqueId: cashEventsWithStaff.cashEvent.id + '-' + index,
                cashEventId: cashEventsWithStaff.cashEvent.id,
                date: cashEventsWithStaff.cashEvent.createdAt,
                amount: '$' + (payout.payoutAmount * 0.01).toFixed(2) + ' ' + currency.toUpperCase(),
                reason: payout.payoutReason,
                type: cashEventsWithStaff.cashEvent.endingCash > cashEventsWithStaff.cashEvent.startingCash ? "Paid In" : "Paid Out",
                staff: cashEventsWithStaff.staffMember ? cashEventsWithStaff.staffMember.id : ''
            }
            return data.push(payoutEvent)
        })
    })

    const dataWithAdditions = useMemo(() => {
        const adding = addedIds.map(
            (uniqueId: string) =>
            ({
                uniqueId,
                staff: '',
                cashEventId: '',
                date: new Date(),
                amount: '',
                type: '',
                reason: '',
            } as PayoutsProps)
        );
        const curr = data

        // const curr = data
        return [...adding, ...curr];
    }, [deletedIds, addedIds, data]);

    const staffMembersQuery = useStaffMembers(businessId);
    const staffMembers = staffMembersQuery.data ?? []

    const staffMemberOptions = staffMembers.map((staff: CashEventStaffMember) => {
        return {
            value: staff.id,
            label: staff.contact.firstName + ' ' + staff.contact.lastName,
        }
    })

    instructions.staff = {
        type: "select",
        options: staffMemberOptions,
        placeholder: "",
    };

    const saveChanges = useCallback(
        async (instructions: UpdateState) => {
            const newPayouts: any[] = []
            Object.keys(instructions).forEach((key, index) => {
                //only create new payouts for new__payout__ 
                if (key.includes(prefix)) {
                    newPayouts.push({
                        id: instructions[key].amount ? instructions[key].amount.id : '',
                        amount: instructions[key].amount ? instructions[key].amount.newValue : 0,
                        staff: instructions[key].staff ? instructions[key].staff.newValue : '',
                        reason: instructions[key].reason ? instructions[key].reason.newValue : '',
                        type: instructions[key].type ? instructions[key].type.newValue : 'Paid Out',
                    })
                }
            });
            for (let i = 0; i < newPayouts.length; i++) {
                if (!newPayouts[i].amount) {
                    return alert('Please enter an amount for your new cash event(s).')
                }
            }
            if (newPayouts.length > 0) {
                return update.mutateAsync({ newPayouts })
            }
        },
        [dataWithAdditions, update]
    );

    return {
        data: dataWithAdditions,
        noOp,
        logIt,
        columns,
        instructions,
        isEditing,
        setEditing,
        setLastPayoutId,
        lastPayoutId,
        addPayout,
        saveChanges,
        staffMembers
    };
};
export default usePayoutsScreen;
