import React from 'react';
import UIComponents from "@tangopay/tango-ui-library";
const { Modal, Button } = UIComponents;
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import useMoveShift from './useMoveShift';

const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30,
};
interface Props {
    isOpen: boolean;
    closeModal: () => void;
    onSave: (actionType: "move" | "duplicate", selectedDays: string[]) => void;
}
const MoveShiftModal = ({ isOpen, closeModal, onSave }: Props) => {
    const { selectedAction, setSelectedAction, days, updateSelectedDays, selectedDays } = useMoveShift();
    return (
        <Modal isOpen={isOpen} closeModal={closeModal} width="600">
            <div className="border flex p-1 relative items-center bg-blue-grey-3  px-3 rounded-full mb-3 w-72">
                <div className="w-full flex justify-center py-2 cursor-pointer" onClick={() => setSelectedAction('move')}>
                    <div className="text-xs text-grey-3 font-lato-bold">Move Shift</div>
                </div>
                <div className="w-full flex justify-center py-2 cursor-pointer" onClick={() => setSelectedAction('duplicate')}>
                    <div className="text-xs text-grey-3 font-lato-bold">Duplicate Shift</div>
                </div>
                <motion.div
                    className={`${selectedAction === "move" ? "left-1" : "right-1"} bg-white shadow text-xs text-black flex items-center justify-center w-1/2 rounded-full h-8 transition-all top-[4px] absolute `}
                    transition={spring}
                    layout
                >
                    {
                        selectedAction === "move" ? "Move " : "Duplicate "
                    }
                    Shift
                </motion.div>
            </div>
            <div className='text-grey-2 text-sm font-lato-medium'>
                {
                    selectedAction === 'move' ?
                        'Select a day to move this shift over to.' :
                        'Select one or more days to copy this shift over to. Duplicating this shift will not carry over any assignees.'
                }

            </div>
            <div className='flex flex-wrap mt-10'>
                {
                    days.map((day, index) => {
                        const isSelected = selectedDays.includes(day)
                        return (
                            <Button
                                onClick={() => updateSelectedDays(day)}
                                label={day}
                                key={index}
                                className={`${isSelected ? 'bg-black text-white border-black' : ' border-grey-1 text-grey-2'} mb-2 mr-2 border-solid`}
                                type="btn-style-outline"
                                size="btn-medium"
                                rightIconColor={`${isSelected ? '#fff' : '#424452'}`}
                                rightIcon={`${isSelected ? "checkmark" : 'placeholder'}`} />
                        )
                    })
                }
            </div>
            <div className='mt-9 flex'>
                <Button label='Cancel' className='w-full mr-4 justify-center' type="btn-style-1" size="btn-medium" />
                <Button label={`${selectedAction === 'move' ? 'Move Shift' : 'Duplicate Shift'} `}
                    onClick={() => onSave(selectedAction, selectedDays)}
                    className={`w-full justify-center ${selectedDays.length === 0 ? 'text-grey-2 hover:text-grey-2' : ''}`}
                    type="btn-style-1"
                    disabled={selectedDays.length === 0}
                    size="btn-medium" />
            </div>
        </Modal>
    )
};
export default MoveShiftModal;