import { useMutation, useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { nanoid } from "nanoid";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { apiErrorHandler } from "controllers/core";
import {
  AddRolesData,
  JobRolesData,
  UpdateRolesData,
  addRoles,
  deleteRoles,
  fetchJobRolesTableData,
  updateRoles,
} from "controllers/team";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import {
  ColumnInstruction,
  RowError,
  UpdateState,
} from "components/Table/HorizontalTable";

import { RootState } from "model/store";

import {
  DepartmentEditComponent,
  DepartmentViewComponent,
} from "../views/EmployeeDetails/components/TableExtentions";

type DeleteRolesMutationData = {
  businessId: string;
  rolesIds: string[];
};
type AddRolesMutationData = {
  businessId: string;
  roles: AddRolesData[];
};
type UpdateRolesMutationData = {
  businessId: string;
  roles: UpdateRolesData[];
};
export const useRolesTable = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const [searchFilter, setSearchFilter] = useState("");
  const [addedRows, setAddedRows] = useState<JobRolesData[]>([]);

  const [rolesInfoEditing, setRolesInfoEditing] = useState(false);
  const [rowErrors, setRowErrors] = useState<RowError[]>([]);

  const onRolesInfoEditStart = useCallback(
    (v: boolean) => {
      setRolesInfoEditing(v);
    },
    [setRolesInfoEditing]
  );

  const addNewRowHandler = useCallback(() => {
    setAddedRows([
      ...addedRows,
      {
        uniqueId: nanoid(),
        title: "",
        departmentId: "",
        departmentTitle: "",
        numberOfEmployees: 0,
      },
    ]);
    onRolesInfoEditStart(true);
  }, [addedRows, setAddedRows, onRolesInfoEditStart]);

  const onSearchChange = useCallback(
    (v: string) => {
      setSearchFilter(v.toLowerCase());
    },
    [setSearchFilter]
  );

  const columns: ColumnInstruction<JobRolesData>[] = [
    { type: "data", header: "Role", attribute: "title" },
    { type: "data", header: "Department", attribute: "departmentTitle" },
    {
      type: "data",
      header: "Number Of Employees",
      attribute: "numberOfEmployees",
    },
  ];

  const businessId = useMemo(() => {
    return business?.id ?? null;
  }, [business?.id]);

  const jobRolesTableData = useQuery(
    ["jobRolesTableData", businessId],
    async () => fetchJobRolesTableData(businessId),
    {
      enabled: !!businessId,
      initialData: [],
      refetchOnWindowFocus: false,
      select: (jobRoles) =>
        jobRoles.filter((jr) => jr.title.toLowerCase().includes(searchFilter)),
    }
  );

  const deleteRolesMutation = useMutation(
    (mutationData: DeleteRolesMutationData) => {
      return deleteRoles(mutationData.businessId, mutationData.rolesIds);
    },
    {
      onError: apiErrorHandler,
    }
  );

  const addRolesMutation = useMutation(
    (mutationData: AddRolesMutationData) => {
      return addRoles(mutationData.businessId, mutationData.roles);
    },
    {
      onError: apiErrorHandler,
    }
  );

  const updateRolesMutation = useMutation(
    (mutationData: UpdateRolesMutationData) => {
      return updateRoles(mutationData.businessId, mutationData.roles);
    },
    {
      onError: apiErrorHandler,
    }
  );

  const deleteRowsHandler = useCallback(
    async (args: string[]) => {
      console.log("args", args);
      //Handle removing added rows
      if (businessId) {
        await deleteRolesMutation.mutateAsync({ businessId, rolesIds: args });
        jobRolesTableData.refetch();
      }
    },
    [deleteRolesMutation, businessId, jobRolesTableData]
  );

  const loading = useMemo(() => {
    return (
      jobRolesTableData.isLoading ||
      deleteRolesMutation.isLoading ||
      jobRolesTableData.isFetching ||
      updateRolesMutation.isLoading ||
      addRolesMutation.isLoading
    );
  }, [
    jobRolesTableData.isLoading,
    deleteRolesMutation.isLoading,
    jobRolesTableData.isFetching,
    updateRolesMutation.isLoading,
    addRolesMutation.isLoading,
  ]);

  const tableDataCombinedWithAddedRows = useMemo(() => {
    return [...addedRows, ...jobRolesTableData.data];
  }, [jobRolesTableData.data, addedRows]);

  const rolesInfoInstructions: {
    [x: string]: RenderInstruction<JobRolesData>;
  } = {};

  rolesInfoInstructions.departmentTitle = {
    type: "complex-custom",
    viewComponent: DepartmentViewComponent,
    editComponent: DepartmentEditComponent,
  };

  const saveResultsHandler = useCallback(
    async (updateState: UpdateState) => {
      const addRequests = _.keys(updateState).filter((k) =>
        addedRows.find((r) => r.uniqueId === k)
      );
      const editRequests = _.keys(updateState).filter(
        (k) => !addedRows.find((r) => r.uniqueId === k)
      );

      const addErrors: RowError[] = addRequests.map((addRequestId) => {
        const updateStateData = updateState[addRequestId];
        const addedRow = addedRows.find((r) => r.uniqueId === addRequestId);
        let roleTitleError = null;
        const roleTitle = updateStateData.title?.newValue ?? addedRow?.title;
        if (!roleTitle) {
          roleTitleError = "Role title is required";
        }
        let departmentTitleError = null;
        const departmentTitle =
          updateStateData.departmentTitle?.newValue ??
          addedRow?.departmentTitle;
        if (!departmentTitle) {
          departmentTitleError = "Department is required";
        }
        return {
          rowId: addRequestId,
          generalErrorMessage: null,
          attributeErrors: {
            title: roleTitleError,
            departmentTitle: departmentTitleError,
          },
        };
      });

      const editErrors: RowError[] = editRequests
        .map((editRequestId) => {
          const updateStateData = updateState[editRequestId];
          if (!updateStateData) {
            return null;
          }
          let roleTitleError = null;
          const roleTitle =
            updateStateData.title?.newValue ??
            jobRolesTableData.data?.find((r) => r.uniqueId === editRequestId)
              ?.title;
          if (!roleTitle) {
            roleTitleError = "Role title is required";
          }
          let departmentTitleError = null;
          const departmentTitle =
            updateStateData.departmentTitle?.newValue ??
            jobRolesTableData.data?.find((r) => r.uniqueId === editRequestId)
              ?.departmentTitle;
          if (!departmentTitle) {
            departmentTitleError = "Department is required";
          }
          return {
            rowId: editRequestId,
            generalErrorMessage: null,
            attributeErrors: {
              title: roleTitleError,
              departmentTitle: departmentTitleError,
            },
          };
        })
        .filter((x) => !!x) as RowError[];
      console.log("updateState", updateState);
      setRowErrors([...addErrors, ...editErrors]);
      const hasErrors =
        (editErrors.length &&
          (editErrors.some((e) => e.generalErrorMessage) ||
            editErrors.some(
              (e) =>
                e.attributeErrors &&
                _.values(e.attributeErrors).some((v) => !!v)
            ))) ||
        (addErrors.length &&
          (addErrors.some((e) => e.generalErrorMessage) ||
            addErrors.some(
              (e) =>
                e.attributeErrors &&
                _.values(e.attributeErrors).some((v) => !!v)
            )));

      if (!hasErrors) {
        if (businessId) {
          const addMutationData = addRequests
            .map((d) => {
              const title = updateState[d].title?.newValue;
              const departmentId = updateState[d].departmentId?.newValue;
              if (title && departmentId) {
                return {
                  title,
                  departmentId,
                };
              }
              return null;
            })
            .filter((x) => !!x) as AddRolesData[];
          if (addMutationData.length) {
            await addRolesMutation.mutateAsync({
              businessId,
              roles: addMutationData,
            });
            await jobRolesTableData.refetch();
          }

          const editMutationData = editRequests
            .map((d) => {
              const roleId = d;
              const title =
                updateState[d].title?.newValue ??
                jobRolesTableData.data?.find((r) => r.uniqueId === d)?.title;
              const departmentId =
                updateState[d].departmentId?.newValue ??
                jobRolesTableData.data?.find((r) => r.uniqueId === d)
                  ?.departmentId;
              console.log({
                title,
                departmentId,
                roleId,
              });
              if (title && departmentId) {
                return {
                  title,
                  departmentId,
                  roleId,
                };
              }
              return null;
            })
            .filter((x) => !!x) as UpdateRolesData[];
          if (editMutationData.length) {
            await updateRolesMutation.mutateAsync({
              businessId,
              roles: editMutationData,
            });
            await jobRolesTableData.refetch();
          }
          setRowErrors([]);
          setAddedRows([]);
        }
      }
    },
    [addedRows, businessId, addRolesMutation, jobRolesTableData]
  );
  console.log("rowErrors", rowErrors);

  return {
    jobRolesTableData,
    columns,
    onSearchChange,
    addNewRowHandler,
    searchFilter,
    deleteRowsHandler,
    loading,
    tableDataCombinedWithAddedRows,
    rolesInfoInstructions,
    onRolesInfoEditStart,
    rolesInfoEditing,
    saveResultsHandler,
    rowErrors,
  };
};
