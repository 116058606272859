import React from 'react';
import moment from 'moment-timezone';
import RequestSidebar from '../Requests/RequestSidebar';
import UIComponents from '@tangopay/tango-ui-library';
import { useSettingsViewController } from './viewController';
import { Spinner, SpinnerSize } from '@blueprintjs/core';
const { Dropdown, TimePickerInput, DatePickerInput, Switch, Button } = UIComponents;
type Props = {
  weekRange: Date[];
  business: TangoBusiness;
  schedulesWithLocalSchedule: TangoSchedule[];
  fixedSchedules: TangoFixedSchedule[];
  schedules: TangoSchedule[];
  scheduleEvents: ScheduleEvent[];

}
const Settings = ({ weekRange, business, schedulesWithLocalSchedule, fixedSchedules, schedules, scheduleEvents }: Props) => {

  const {
    firstDayOfTheWeek,
    amPmSplitTime,
    payrollCadence,
    payPeriodStartDate,
    showFullStaffSchedule,
    enableEmployeesToDropShifts,
    locationVerifiedClockPunch,
    overtimeQualification,
    updateFirstDayOfTheWeek,
    updateAmPmSplitTime,
    updatePayrollCadence,
    updatePayPeriodStartDate,
    updateShowFullStaffSchedule,
    updateEnableEmployeesToDropShifts,
    updateLocationVerifiedClockPunch,
    updateOvertimeQualification,
    firstDayOfTheWeekDropdownValue,
    firstDayOfTheWeekDropdownOptions,
    saveLoading,
    loading,
    saveSchedulingSettings,
    payrollCadenceOptions,
    payrollCadenceValue,
  } = useSettingsViewController();
  return (
    <>
      <RequestSidebar
        weekRange={weekRange}
        business={business}
        schedulesWithLocalSchedule={schedulesWithLocalSchedule}
        fixedSchedules={fixedSchedules}
        schedules={schedules}
        scheduleEvents={scheduleEvents}
      />
      <div className=' py-12 px-28 border-l border-solid border-grey-1 border-r-0 border-t-0 border-b-0' style={{ width: "80vw", overflowX: "auto", height: '100%', }}>
        <div className=' flex w-full justify-between text-3xl font-lato-black text-black border-b border-solid border-grey-1 border-t-0 border-l-0 border-r-0 pb-6'>
          <div>Scheduling Settings</div>
          {saveLoading || loading ? <Spinner size={SpinnerSize.SMALL} /> : <Button size="btn-medium" type="btn-style-2" label="Save" onClick={saveSchedulingSettings} />}

        </div>
        <ul className='p-0 m-0 list-none'>
          <li className='py-10 flex border-b border-solid border-grey-1 border-t-0 border-l-0 border-r-0'>
            <div className='w-2/3'>
              <div className=' text-base text-black font-lato-black'>First day of the week</div>

              <div className=' text-xs text-grey-3 font-lato-regular'>What your business considers to be the first day of the week (i.e. Sunday, Monday). This is used for reporting, scheduling and pay periods.</div>
            </div>
            <div className='w-1/3 flex justify-end '>
              <div className='w-44'>
                <Dropdown
                  options={firstDayOfTheWeekDropdownOptions}
                  value={firstDayOfTheWeekDropdownValue}
                  onChange={(o: any) => updateFirstDayOfTheWeek(o.value)}
                  size="medium"
                />
              </div>

            </div>
          </li>
          <li className='py-10 flex border-b border-solid border-grey-1 border-t-0 border-l-0 border-r-0'>
            <div className='w-2/3'>
              <div className=' text-base text-black font-lato-black'>AM/PM split time</div>

              <div className=' text-xs text-grey-3 font-lato-regular'>The time of the day that your business considers to split AM vs PM (e.g. 4:00 PM).</div>
            </div>
            <div className='w-1/3 flex justify-end'>
              <div className='w-44'>
                <TimePickerInput
                  size="large"
                  value={amPmSplitTime ? moment(amPmSplitTime, "HH:mm").toDate() : undefined}
                  onChange={(date: Date) => updateAmPmSplitTime(moment(date).format("HH:mm"))}
                />
              </div>

            </div>
          </li>
          <li className='py-10 flex border-b border-solid border-grey-1 border-t-0 border-l-0 border-r-0'>
            <div className='w-2/3'>
              <div className=' text-base text-black font-lato-black'>Payroll cadence</div>

              <div className=' text-xs text-grey-3 font-lato-regular'>The cadence on which your business runs payroll (i.e weekly, bi-weekly, monthly).</div>
            </div>
            <div className='w-1/3 flex justify-end'>
              <div className='w-44'>
                <Dropdown
                  options={payrollCadenceOptions}
                  size="medium"
                  value={payrollCadenceValue}
                  onChange={(o: any) => updatePayrollCadence(o.value)}
                />
              </div>

            </div>
          </li>
          <li className='py-10 flex border-b border-solid border-grey-1 border-t-0 border-l-0 border-r-0'>
            <div className='w-2/3'>
              <div className=' text-base text-black font-lato-black'>Pay period start date</div>

              <div className=' text-xs text-grey-3 font-lato-regular'>This is the day used to kick off the pay period cadence. For example, if the pay period cadence is biweekly, the first pay period report will auto-generate two weeks after this set date.</div>
            </div>
            <div className="w-1/3 flex justify-end">
              <div className='w-54' >
                <DatePickerInput
                  size="large"
                  value={payPeriodStartDate ? moment(payPeriodStartDate).toDate() : undefined}
                  onChange={(date: Date) => updatePayPeriodStartDate(date)}
                />

              </div>
            </div>
          </li>
          <li className='py-10 flex border-b border-solid border-grey-1 border-t-0 border-l-0 border-r-0'>
            <div className='w-2/3'>
              <div className=' text-base text-black font-lato-black'>Show staff full schedule?</div>

              <div className=' text-xs text-grey-3 font-lato-regular'>Select ‘yes’ if all staff be able to see the full posted schedule.</div>
            </div>
            <div className="w-1/3 flex justify-end">
              <Switch
                size="medium"
                showLabel={false}
                checked={showFullStaffSchedule}
                onChange={(v) => updateShowFullStaffSchedule(v)}
              />

            </div>
          </li>
          <li className='py-10 flex border-b border-solid border-grey-1 border-t-0 border-l-0 border-r-0'>
            <div className='w-2/3'>
              <div className=' text-base text-black font-lato-black'>Enabled employees to drop shifts?</div>
            </div>
            <div className="w-1/3 flex justify-end">
              <Switch
                size="medium"
                showLabel={false}
                checked={enableEmployeesToDropShifts}
                onChange={(v) => updateEnableEmployeesToDropShifts(v)}
              />

            </div>
          </li>
          <li className='py-10 flex border-b border-solid border-grey-1 border-t-0 border-l-0 border-r-0'>
            <div className='w-2/3'>
              <div className=' text-base text-black font-lato-black'>Use location for verified clock-in/out?</div>
            </div>
            <div className="w-1/3 flex justify-end">
              <Switch
                size="medium"
                showLabel={false}
                checked={locationVerifiedClockPunch}
                onChange={(v) => updateLocationVerifiedClockPunch(v)}
              />

            </div>
          </li>
          {/* <li className='py-10 flex border-b border-solid border-grey-1 border-t-0 border-l-0 border-r-0'>
            <div className='w-2/3'>
              <div className=' text-base text-black font-lato-black'>Overtime qualification (hours)</div>
            </div>
            <div className="w-1/3 flex justify-end">
              <div className='w-44'>
                <Dropdown options={[
                  {
                    label: "Biweekly",
                    value: "biweekly"
                  }
                ]}
                  size="medium"
                />
              </div>

            </div>
          </li> */}
        </ul>
      </div>
    </>
  )
}

export default Settings;