import { Intent, Position, Toaster } from "@blueprintjs/core";
import axios from "axios";
import firebase from "config/firebase";
import {
  ProjectedSalesForADayType,
  TargetLaborCostForADayType,
} from "hooks/scheduling/stats";
import _, { uniqueId } from "lodash";
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import {
  ComposeShiftType,
  DeleteShiftType,
} from "views/manager/Staffing/WeeklyView/useCreateShift";

import { getWeekDays } from "utils/manager";

import { updateDuplicateScheduleToDraft } from "model/actions/staffingAC";
import store from "model/store";

import { receiveSchedules } from "../model/actions/schedulesAC";
import { composeUsableTangoSchedule } from "./composableTypes";
import { baseUrl, subscribeToCollectionDocuments } from "./core";
import { generateBearerToken } from "./init";

const { firestore } = firebase;

const tangoScheduleApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/v2/schedules`,
});

tangoScheduleApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

const ScheduleApiRoutesMap = {
  createDraftFromDuplicate: "/draft-from-duplicate",
  createDraftFromDuplicateWithShiftStarters:
    "/draft-from-duplicate-with-shift-starters",
  composeAdditionalShifts: "/compose-additional-shifts",
  createNewSchedule: "/new-schedule",
  clearDraftSchedule: "/clear-draft",
  clearDuplicateSchedule: "/clear-duplicate",
  duplicateDraft: "/duplicate-draft",
  duplicateDraftToWeekRanges: "/duplicate-draft-week-ranges",
  createDraftScheduleFromDuplicateScheduleWithEditShiftPayload:
    "/create-draft-from-duplicate-with-edit-shift-payload",
  publishDraft: "/publish-draft",
  publishMultipleDrafts: "/publish-multiple-drafts",
  publishDuplicate: "/publish-duplicate",
  deleteShiftInDuplicate: "/delete-shift-in-duplicate",
  deleteShift: "/delete-shift",
  unassignShiftInDuplicate: "/unassign-shift-in-duplicate",
  unassignShift: "/unassign-shift",
  editProjectedSalesInDuplicate: "/edit-projected-sales-in-duplicate",
  editProjectedSales: "/edit-projected-sales",
  editTargetScheduleCostInDuplicate: "/edit-target-cost-in-duplicate",
  editTargetScheduleCost: "/edit-target-cost",
  moveMultipleShifts: "/move-multiple-shifts",
  duplicateMultipleShifts: "/duplicate-multiple-shifts",
  deleteMultipleShifts: "/delete-multiple-shifts",
  editMultipleShifts: "/multiple-shifts",
  targetLc: "/target-lc",
  dailyProjectedSales: "/daily-projected-sales",
};

export const editScheduleShift = async (
  shiftId: string,
  draftScheduleId: string,
  businessId: string,
  startTime: string,
  endTime: string,
  staffId: string | null,
  notes = ""
) => {
  await tangoScheduleApiClient.put("/shift", {
    shiftId,
    draftScheduleId,
    businessId,
    startTime,
    endTime,
    staffId,
    notes,
  });
};

export const updateTargetScheduleCost = async (
  scheduleId: string,
  value: number
) => {
  await firestore()
    .collection("DraftSchedule")
    .doc(scheduleId)
    .update({ targetScheduleCost: value });
};

export const updateProjectedSales = async (
  scheduleId: string,
  value: number
) => {
  await firestore()
    .collection("DraftSchedule")
    .doc(scheduleId)
    .update({ projectedSales: value });
};

export const groupShiftsByJobFunction = (
  shifts: TangoShift[],
  jobFunctions: TangoJobFunctions
): { name: string; quantity: number; total: number }[] => {
  const groupedShifts = _.groupBy(
    shifts,
    (shift: TangoShift) => shift.position
  );
  return _.keys(groupedShifts).map((key) => ({
    name: jobFunctions[key].title,
    total: groupedShifts[key].length,
    quantity: groupedShifts[key].filter(({ staffId }) => staffId).length,
  }));
};

export const duplicateScheduleIndefinitely = async (
  schedule: TangoSchedule,
  withAssignments: boolean,
  existingFixedSchedules: TangoFixedSchedule[],
  business: TangoBusiness,
  draftSchedules: TangoSchedule[]
) => {
  const fixedScheduleRef = firestore().collection("FixedSchedules").doc();
  const fixedScheduleId = fixedScheduleRef.id;
  const fixedSchedule: TangoFixedSchedule = {
    id: fixedScheduleId,
    availabilityType: schedule.availabilityType,
    isActive: true,
    createdAt: schedule.createdAt || new Date(),
    updatedAt: new Date(),
    deleted: schedule.deleted || false,
    departmentId: schedule.departmentId || "foh",
    shifts: schedule.shifts.map((shift) => ({
      ...shift,
      id: uuidv4(),
      startDate: null,
      endDate: null,
      staffId: withAssignments ? shift.staffId : null,
      startDayOfTheWeek: moment(shift.startDate.toDate()).day(),
      endDayOfTheWeek: moment(shift.endDate.toDate()).day(),
    })),
    businessId: schedule.businessId,
    recurrence: {
      indefinite: true,
      startDate: firestore.Timestamp.fromDate(schedule?.startDate.toDate()),
      endDate: null,
    },
  };
  const momentFixedScheduleStart = moment(schedule.startDate?.toDate());
  const draftSchedulesInsideOfNewFixedSchedule = draftSchedules.filter(
    (draft) => {
      const momentDraftStart = moment(draft.startDate?.toDate());
      const draftFitsWithinFixed = momentDraftStart.isAfter(
        momentFixedScheduleStart
      );
      return draftFitsWithinFixed;
    }
  );
  const adjustedDrafts = draftSchedulesInsideOfNewFixedSchedule
    .map((ds) => {
      const selectedDays = generateWeekRangeForSelectedDate(
        ds.startDate?.toDate(),
        business
      );
      const draftFromDuplciateSchedule = getFixedScheduleForAWeekRange(
        selectedDays,
        [fixedSchedule],
        business.timezone
      );
      if (draftFromDuplciateSchedule) {
        ds.shifts = draftFromDuplciateSchedule.shifts;
        return ds;
      }
      return null;
    })
    .filter((x) => Boolean(x)) as TangoSchedule[];
  await Promise.all(
    adjustedDrafts.map(async (newDraft) => {
      await firestore()
        .collection("DraftSchedule")
        .doc(newDraft.id)
        .update({ shifts: newDraft.shifts });
    })
  );
  if (existingFixedSchedules.filter((fs) => fs.isActive).length) {
    for await (const fs of existingFixedSchedules.filter((fs) => fs.isActive)) {
      await firestore().collection("FixedSchedules").doc(fs.id).update({
        "recurrence.indefinite": false,
        "recurrence.endDate": schedule?.startDate.toDate(),
      });
    }
  }
  await firestore()
    .collection("FixedSchedules")
    .doc(fixedScheduleId)
    .set(fixedSchedule);
};

const generateWeekRangeForSelectedDate = (
  sd: Date,
  business: TangoBusiness
) => {
  const sdMoment = moment(sd).startOf("day").add(4, "hours");
  const sdNumber = sdMoment.day();
  const payrollFirstDayNumber = moment()
    .day(business?.payrollStartOfTheWeek)
    .get("day");
  if (sdNumber < payrollFirstDayNumber) {
    const adjustedWeekRangeStart = sdMoment
      .clone()
      .subtract(7, "days")
      .day(payrollFirstDayNumber);
    const adjustedSelectedDays = getWeekDays(adjustedWeekRangeStart.toDate());
    return adjustedSelectedDays;
  } else {
    const regularWeekRangeStart = sdMoment.clone().day(payrollFirstDayNumber);
    const regularSelectedDays = getWeekDays(regularWeekRangeStart.toDate());
    return regularSelectedDays;
  }
};

export const duplicateScheduleUntilACertainDate = async (
  businessId: string,
  recurrenceStartDate: Date,
  fohDraftId: string,
  bohDraftId: string,
  endDate: Date,
  withAssignments: boolean
) => {
  try {
    await tangoScheduleApiClient.post(ScheduleApiRoutesMap.duplicateDraft, {
      businessId,
      fohDraftId,
      bohDraftId,
      recurrenceStartDate,
      recurrenceEndDate: endDate,
      withAssignments,
    });
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const duplicateScheduleToWeekRanges = async (
  businessId: string,
  fohDraftId: string,
  bohDraftId: string,
  startDates: Date[],
  withAssignments: boolean
) => {
  try {
    await tangoScheduleApiClient.post(
      ScheduleApiRoutesMap.duplicateDraftToWeekRanges,
      {
        businessId,
        fohDraftId,
        bohDraftId,
        startDates,
        withAssignments,
      }
    );
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const deleteShiftInDuplicate = async (
  businessId: string,
  processedDuplicateSchedule: TangoSchedule,
  shiftId: string
) => {
  try {
    console.log({
      businessId,
      schedule: {
        startDate: processedDuplicateSchedule.startDate?.toDate(),
        endDate: processedDuplicateSchedule.endDate?.toDate(),
        shifts: processedDuplicateSchedule.shifts.map((sh) => ({
          ...sh,
          startDate: sh.startDate?.toDate(),
          endDate: sh.endDate?.toDate(),
        })),
      },
      deleteShiftId: shiftId,
    });
    await tangoScheduleApiClient.post(
      ScheduleApiRoutesMap.deleteShiftInDuplicate,
      {
        businessId,
        schedule: {
          startDate: processedDuplicateSchedule.startDate?.toDate(),
          endDate: processedDuplicateSchedule.endDate?.toDate(),
          shifts: processedDuplicateSchedule.shifts.map((sh) => ({
            ...sh,
            startDate: sh.startDate?.toDate(),
            endDate: sh.endDate?.toDate(),
          })),
        },
        deleteShiftId: shiftId,
      }
    );
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const unassignShiftInDuplicate = async (
  businessId: string,
  processedDuplicateSchedule: TangoSchedule,
  shiftId: string
) => {
  try {
    await tangoScheduleApiClient.post(
      ScheduleApiRoutesMap.unassignShiftInDuplicate,
      {
        businessId,
        schedule: {
          startDate: processedDuplicateSchedule.startDate?.toDate(),
          endDate: processedDuplicateSchedule.endDate?.toDate(),
          shifts: processedDuplicateSchedule.shifts.map((sh) => ({
            ...sh,
            startDate: sh.startDate?.toDate(),
            endDate: sh.endDate?.toDate(),
          })),
        },
        unassignShiftId: shiftId,
      }
    );
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const editProjectedSalesInDuplicate = async (
  businessId: string,
  processedDuplicateSchedule: TangoSchedule,
  projectedSales: number
) => {
  try {
    await tangoScheduleApiClient.post(
      ScheduleApiRoutesMap.editProjectedSalesInDuplicate,
      {
        businessId,
        schedule: {
          startDate: processedDuplicateSchedule.startDate?.toDate(),
          endDate: processedDuplicateSchedule.endDate?.toDate(),
          shifts: processedDuplicateSchedule.shifts.map((sh) => ({
            ...sh,
            startDate: sh.startDate?.toDate(),
            endDate: sh.endDate?.toDate(),
          })),
        },
        projectedSales,
      }
    );
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const editTargetScheduleCostInDuplicate = async (
  businessId: string,
  processedDuplicateSchedule: TangoSchedule,
  targetFOHScheduleCost: number,
  targetBOHScheduleCost: number
) => {
  try {
    await tangoScheduleApiClient.post(
      ScheduleApiRoutesMap.editTargetScheduleCostInDuplicate,
      {
        businessId,
        schedule: {
          startDate: processedDuplicateSchedule.startDate?.toDate(),
          endDate: processedDuplicateSchedule.endDate?.toDate(),
          shifts: processedDuplicateSchedule.shifts.map((sh) => ({
            ...sh,
            startDate: sh.startDate?.toDate(),
            endDate: sh.endDate?.toDate(),
          })),
        },
        targetFOHScheduleCost,
        targetBOHScheduleCost,
      }
    );
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const editTargetScheduleCost = async (
  businessId: string,
  draftScheduleId: string,
  targetScheduleCost: number
) => {
  try {
    await tangoScheduleApiClient.post(
      ScheduleApiRoutesMap.editTargetScheduleCost,
      {
        businessId,
        draftScheduleId,
        targetScheduleCost,
      }
    );
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const editProjectedSales = async (
  businessId: string,
  draftScheduleId: string,
  projectedSales: number
) => {
  try {
    await tangoScheduleApiClient.post(ScheduleApiRoutesMap.editProjectedSales, {
      businessId,
      draftScheduleId,
      projectedSales,
    });
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const unassignShift = async (
  businessId: string,
  draftScheduleId: string,
  shiftId: string
) => {
  try {
    await tangoScheduleApiClient.post(ScheduleApiRoutesMap.unassignShift, {
      businessId,
      draftScheduleId,
      shiftId,
    });
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const deleteShift = async (
  businessId: string,
  draftScheduleId: string,
  shiftId: string
) => {
  try {
    await tangoScheduleApiClient.post(ScheduleApiRoutesMap.deleteShift, {
      businessId,
      draftScheduleId,
      shiftId,
    });
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const publishDraft = async (
  businessId: string,
  draftScheduleId: string
) => {
  try {
    await tangoScheduleApiClient.post(ScheduleApiRoutesMap.publishDraft, {
      businessId,
      draftScheduleId,
    });
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const publishDuplicateSchedule = async (
  businessId: string,
  processedDuplicateSchedule: TangoSchedule,
  departmentToPublish: "foh" | "boh" | "both"
) => {
  try {
    await tangoScheduleApiClient.post(ScheduleApiRoutesMap.publishDuplicate, {
      businessId,
      departmentToPublish,
      schedule: {
        startDate: processedDuplicateSchedule.startDate?.toDate(),
        endDate: processedDuplicateSchedule.endDate?.toDate(),
        shifts: processedDuplicateSchedule.shifts.map((sh) => ({
          ...sh,
          startDate: sh.startDate?.toDate(),
          endDate: sh.endDate?.toDate(),
        })),
      },
    });
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const publishMultipleDrafts = async (
  businessId: string,
  draftScheduleIds: string[]
) => {
  try {
    await tangoScheduleApiClient.post(
      ScheduleApiRoutesMap.publishMultipleDrafts,
      {
        businessId,
        draftSchedules: draftScheduleIds,
      }
    );
  } catch (e: any) {
    console.log(e?.response?.data);
    console.log("Zishaan is here...");
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const createShift = (
  startTime: string,
  endTime: string,
  startDate: any,
  endDate: any,
  shiftType: string,
  position: string
) => ({
  id: uuidv4(),
  position,
  startDate,
  endDate,
  startTime,
  endTime,
  staffId: null,
  overTimeRate: 0,
  shiftTypeId: shiftType,
  onCall: false,
  subRole: null,
});

const toaster = Toaster.create({
  position: Position.BOTTOM_RIGHT,
});

export const composeAdditionalShiftsFromShiftStarters = async (
  shiftStarters: ShiftStarter[],
  weekRange: Date[],
  shiftType: string,
  selectedDays: string[],
  draftScheduleId: string,
  businessId: string
) => {
  try {
    const res = await tangoScheduleApiClient.put<{
      additionalShifts: TangoShift[];
    }>(ScheduleApiRoutesMap.composeAdditionalShifts, {
      businessId,
      weekRange,
      shiftStarters,
      shiftType,
      selectedDays,
      draftScheduleId,
    });
    console.log("res", res.data);
    return res?.data?.additionalShifts;
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const clearDraftSchedule = async (
  draftScheduleId: string,
  businessId: string
) => {
  try {
    await tangoScheduleApiClient.put(ScheduleApiRoutesMap.clearDraftSchedule, {
      businessId,
      draftScheduleId,
    });
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const createEmptyScheduleFromShiftStarters = async (
  weekRange: Date[],
  shiftStarters: ShiftStarter[],
  businessId: string,
  shiftType: string,
  selectedDays: string[]
) => {
  try {
    console.log({
      weekRange,
      shiftStarters,
      businessId,
      shiftType,
      selectedDays,
    });
    await tangoScheduleApiClient.post(ScheduleApiRoutesMap.createNewSchedule, {
      weekRange,
      shiftStarters,
      businessId,
      shiftType,
      selectedDays,
    });
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const createDraftScheduleFromDuplicateScheduleWithShiftStarters = async (
  processedDuplicateSchedule: TangoSchedule,
  businessId: string,
  shiftStarters: ShiftStarter[],
  weekRange: Date[],
  shiftType: string,
  selectedDays: string[]
) => {
  try {
    const res = await tangoScheduleApiClient.post<{
      draftId: string;
    }>(ScheduleApiRoutesMap.createDraftFromDuplicateWithShiftStarters, {
      businessId,
      shiftStarters,
      weekRange,
      shiftType,
      selectedDays,
      schedule: {
        startDate: processedDuplicateSchedule.startDate?.toDate(),
        endDate: processedDuplicateSchedule.endDate?.toDate(),
        shifts: processedDuplicateSchedule.shifts.map((sh) => ({
          ...sh,
          startDate: sh.startDate?.toDate(),
          endDate: sh.endDate?.toDate(),
        })),
      },
    });
    console.log("res", res.data);
    return res.data.draftId;
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const createDraftScheduleFromDuplicateWithEditShiftPayload = async (
  processedDuplicateSchedule: TangoSchedule,
  businessId: string,
  shiftId: string,
  startTime: string,
  endTime: string,
  staffId: string | null,
  notes = ""
) => {
  try {
    await tangoScheduleApiClient.post(
      ScheduleApiRoutesMap.createDraftScheduleFromDuplicateScheduleWithEditShiftPayload,
      {
        businessId,
        shiftId,
        startTime,
        endTime,
        staffId,
        notes,
        schedule: {
          startDate: processedDuplicateSchedule.startDate?.toDate(),
          endDate: processedDuplicateSchedule.endDate?.toDate(),
          shifts: processedDuplicateSchedule.shifts.map((sh) => ({
            ...sh,
            startDate: sh.startDate?.toDate(),
            endDate: sh.endDate?.toDate(),
          })),
        },
      }
    );
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const createDraftScheduleFromDuplicateScheduleWithTargetWeekCost =
  async (duplicateSchedule: TangoSchedule, targetScheduleCost: number) => {
    const newDraftScheduleRef = firestore().collection("DraftSchedule").doc();
    const newDraft = {
      ...duplicateSchedule,
      id: newDraftScheduleRef.id,
      isDraft: true,
      isDuplicate: false,
      targetScheduleCost,
    };
    await firestore()
      .collection("DraftSchedule")
      .doc(newDraftScheduleRef.id)
      .set(newDraft);
    store.dispatch(updateDuplicateScheduleToDraft(newDraft.id));
    return newDraft;
  };

export const createDraftScheduleFromDuplicateScheduleWithProjectedSales =
  async (duplicateSchedule: TangoSchedule, projectedSales: number) => {
    const newDraftScheduleRef = firestore().collection("DraftSchedule").doc();
    const newDraft = {
      ...duplicateSchedule,
      id: newDraftScheduleRef.id,
      isDraft: true,
      isDuplicate: false,
      projectedSales,
    };
    await firestore()
      .collection("DraftSchedule")
      .doc(newDraftScheduleRef.id)
      .set(newDraft);
    store.dispatch(updateDuplicateScheduleToDraft(newDraft.id));
    return newDraft;
  };

export const saveFixedSchedule = async (schedule: FixedAvailability) => {
  const docRef = firestore().collection("FixedAvailabilities").doc();

  try {
    const doc = { ...schedule, id: docRef.id };
    docRef.set(doc);
    return doc.id;
  } catch (err) {
    console.log("ERR: ", err);
    return null;
  }
};

export const composeAdditionalShifts = async (
  jobTypesQuantities: { id: string; quantity: number }[],
  weekRange: Date[],
  shiftStart: string,
  shiftEnd: string,
  shiftType: string,
  selectedDays: string[],
  businessTimezone: string,
  draftScheduleId: string
) => {
  const additionalShifts: TangoShift[] = [];
  const notEmptyShiftWorkers = jobTypesQuantities.filter(
    ({ quantity }) => quantity
  );
  _.forEach(notEmptyShiftWorkers, (shiftRole) => {
    _.range(shiftRole.quantity).forEach(() => {
      _.forEach(selectedDays, (selectedDay: string) => {
        const startOfWeek = moment(weekRange[0]);
        const dayConvertedToDate =
          startOfWeek.day() && selectedDay.trim().toLowerCase() === "sunday"
            ? startOfWeek.add(7 - startOfWeek.day(), "days").day(selectedDay)
            : startOfWeek.day(selectedDay);
        const momentStartTime = moment(shiftStart, "HH:mm");
        const momentEndTime = moment(shiftEnd, "HH:mm");

        const startDate = dayConvertedToDate
          .set({
            hour: momentStartTime.get("hour"),
            minute: momentStartTime.get("minute"),
          })
          .toDate();
        let endDate = dayConvertedToDate
          .set({
            hour: momentEndTime.get("hour"),
            minute: momentEndTime.get("minute"),
          })
          .toDate();
        if (endDate < startDate) {
          endDate = moment(endDate).add(1, "days").toDate();
        }

        const startDateWIthTimeZone = moment(startDate);
        startDateWIthTimeZone.tz(businessTimezone, true);

        const endDateWIthTimeZone = moment(endDate);
        endDateWIthTimeZone.tz(businessTimezone, true);

        additionalShifts.push({
          id: uuidv4(),
          position: shiftRole.id,
          startDate: firestore.Timestamp.fromDate(
            startDateWIthTimeZone.toDate()
          ),
          endDate: firestore.Timestamp.fromDate(endDateWIthTimeZone.toDate()),
          startTime: shiftStart,
          endTime: shiftEnd,
          staffId: null,
          overTimeRate: 0,
          shiftTypeId: shiftType,
          onCall: false,
          subRole: null,
        });
      });
    });
  });
  await firestore()
    .collection("DraftSchedule")
    .doc(draftScheduleId)
    .update({
      shifts: firestore.FieldValue.arrayUnion(...additionalShifts),
    });
  return additionalShifts;
};

export const old_composeAdditionalShiftsFromShiftStarters = async (
  shiftStarters: ShiftStarter[],
  weekRange: Date[],
  shiftType: string,
  selectedDays: string[],
  businessTimezone: string,
  draftScheduleId: string
) => {
  const additionalShifts: TangoShift[] = [];

  _.forEach(selectedDays, (selectedDay: string) => {
    shiftStarters.forEach((shiftStarter) => {
      const startOfWeek = moment(weekRange[0]);
      const dayConvertedToDate =
        startOfWeek.day() && selectedDay.trim().toLowerCase() === "sunday"
          ? startOfWeek.add(7 - startOfWeek.day(), "days").day(selectedDay)
          : startOfWeek.day(selectedDay);
      const momentStartTime = moment(shiftStarter.startTime, "HH:mm");
      const momentEndTime = moment(shiftStarter.endTime, "HH:mm");

      const startDate = dayConvertedToDate
        .clone()
        .set({
          hour: momentStartTime.get("hour"),
          minute: momentStartTime.get("minute"),
        })
        .toDate();
      let endDate = dayConvertedToDate
        .clone()
        .set({
          hour: momentEndTime.get("hour"),
          minute: momentEndTime.get("minute"),
        })
        .toDate();
      if (endDate <= startDate) {
        endDate = moment(endDate).add(1, "days").toDate();
      }

      const startDateWIthTimeZone = moment(startDate);
      startDateWIthTimeZone.tz(businessTimezone, true);

      const endDateWIthTimeZone = moment(endDate);
      endDateWIthTimeZone.tz(businessTimezone, true);
      additionalShifts.push({
        id: uuidv4(),
        position: shiftStarter.position,
        startDate: firestore.Timestamp.fromDate(startDateWIthTimeZone.toDate()),
        endDate: firestore.Timestamp.fromDate(endDateWIthTimeZone.toDate()),
        startTime: shiftStarter.startTime,
        endTime: shiftStarter.endTime,
        staffId: null,
        overTimeRate: 0,
        shiftTypeId: shiftType,
        onCall: false,
        subRole: null,
      });
    });
  });

  await firestore()
    .collection("DraftSchedule")
    .doc(draftScheduleId)
    .update({
      shifts: firestore.FieldValue.arrayUnion(...additionalShifts),
    });
  return additionalShifts;
};

export const publishDraftSchedule = async (
  draftSchedule: TangoSchedule,
  hasNoDraft = false
) => {
  const scheduleUpdate = {
    shifts: draftSchedule.shifts,
  };
  if (draftSchedule?.associatedScheduleId) {
    await firestore()
      .collection("DraftSchedule")
      .doc(draftSchedule.id)
      .update(scheduleUpdate);
    await firestore()
      .collection("Schedule")
      .doc(draftSchedule.associatedScheduleId)
      .update(scheduleUpdate);
  } else if (hasNoDraft) {
    console.log("hasNoDraft", hasNoDraft);
    const newSchedule = _.omit(draftSchedule, [
      "id",
      "associatedScheduleId",
      "isDraft",
    ]);
    const newScheduleRef = firestore().collection("Schedule").doc();
    await firestore()
      .collection("Schedule")
      .doc(newScheduleRef.id)
      .set({ ...newSchedule, id: newScheduleRef.id });
    const newDraftScheduleRef = firestore().collection("DraftSchedule").doc();
    await firestore()
      .collection("DraftSchedule")
      .doc(newDraftScheduleRef.id)
      .set({
        ...newSchedule,
        id: newDraftScheduleRef.id,
        associatedScheduleId: newScheduleRef.id,
      });
  } else {
    const newSchedule = _.omit(draftSchedule, [
      "id",
      "associatedScheduleId",
      "isDraft",
    ]);
    const newScheduleRef = firestore().collection("Schedule").doc();
    await firestore()
      .collection("Schedule")
      .doc(newScheduleRef.id)
      .set({ ...newSchedule, id: newScheduleRef.id });
    await firestore()
      .collection("DraftSchedule")
      .doc(draftSchedule.id)
      .update({ ...scheduleUpdate, associatedScheduleId: newScheduleRef.id });
  }
};

export const createEmptySchedule = async (
  weekRange: Date[],
  shiftStart: string,
  shiftEnd: string,
  jobTypesQuantities: { id: string; quantity: number }[],
  businessId: string,
  shiftType: string,
  selectedDays: string[],
  businessTimezone: string
): Promise<TangoSchedule> => {
  const shifts: TangoShift[] = [];
  const notEmptyShiftWorkers = jobTypesQuantities.filter(
    ({ quantity }) => quantity
  );

  _.forEach(notEmptyShiftWorkers, (shiftRole) => {
    _.range(shiftRole.quantity).forEach(() =>
      _.forEach(selectedDays, (selectedDay: string) => {
        const startOfWeek = moment(weekRange[0]);
        const dayConvertedToDate =
          startOfWeek.day() && selectedDay.trim().toLowerCase() === "sunday"
            ? startOfWeek.add(7 - startOfWeek.day(), "days").day(selectedDay)
            : startOfWeek.day(selectedDay);
        const momentStartTime = moment(shiftStart, "HH:mm");
        const momentEndTime = moment(shiftEnd, "HH:mm");

        const startDate = dayConvertedToDate
          .set({
            hour: momentStartTime.get("hour"),
            minute: momentStartTime.get("minute"),
          })
          .toDate();
        let endDate = dayConvertedToDate
          .set({
            hour: momentEndTime.get("hour"),
            minute: momentEndTime.get("minute"),
          })
          .toDate();
        if (endDate < startDate) {
          endDate = moment(endDate).add(1, "days").toDate();
        }

        const startDateWIthTimeZone = moment(startDate);
        console.log("startDateWIthoutTimeZone", startDateWIthTimeZone.unix());
        startDateWIthTimeZone.tz(businessTimezone, true);
        console.log("startDateWIthTimeZone", startDateWIthTimeZone.unix());
        const endDateWIthTimeZone = moment(endDate);
        endDateWIthTimeZone.tz(businessTimezone, true);
        shifts.push({
          id: uuidv4(),
          position: shiftRole.id,
          startDate: firestore.Timestamp.fromDate(
            startDateWIthTimeZone.toDate()
          ),
          endDate: firestore.Timestamp.fromDate(endDateWIthTimeZone.toDate()),
          startTime: shiftStart,
          shiftTypeId: shiftType,
          endTime: shiftEnd,
          staffId: null,
          overTimeRate: 0,
          onCall: false,
          subRole: null,
        });
      })
    );
  });

  const scheduleStartDateWithTimeZone = moment(weekRange[0]);
  scheduleStartDateWithTimeZone.tz(businessTimezone, true);

  const scheduleEndDateWithTimeZone = moment(weekRange[weekRange.length - 1]);
  scheduleEndDateWithTimeZone.tz(businessTimezone, true);

  const freshSchedule = {
    availabilityType: "weekly",
    businessId,
    startDate: firestore.Timestamp.fromDate(
      scheduleStartDateWithTimeZone.toDate()
    ),
    endDate: firestore.Timestamp.fromDate(scheduleEndDateWithTimeZone.toDate()),
    hasTimeOffPay: false,
    overtimeHourLimit: 40,
    recur: false,
    isDraft: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    deleted: false,
    shifts,
    departmentId: "foh",
  };
  const draftRef = firestore().collection("DraftSchedule").doc();

  await firestore()
    .collection("DraftSchedule")
    .doc(draftRef.id)
    .set({
      ...freshSchedule,
      id: draftRef.id,
      isDraft: true,
      associatedScheduleId: null,
    });

  return {
    ...freshSchedule,
    createdAt: new Date(),
    updatedAt: new Date(),
    deleted: false,
    isDraft: true,
    departmentId: "foh",
    id: draftRef.id,
    associatedScheduleId: null,
  };
};

export const old_createEmptyScheduleFromShiftStarters = async (
  weekRange: Date[],
  shiftStarters: ShiftStarter[],
  businessId: string,
  shiftType: string,
  selectedDays: string[],
  businessTimezone: string
): Promise<TangoSchedule> => {
  const shifts: TangoShift[] = [];

  _.forEach(selectedDays, (selectedDay: string) => {
    shiftStarters.forEach((shiftStarter) => {
      const startOfWeek = moment(weekRange[0]);
      const dayConvertedToDate =
        startOfWeek.day() && selectedDay.trim().toLowerCase() === "sunday"
          ? startOfWeek.add(7 - startOfWeek.day(), "days").day(selectedDay)
          : startOfWeek.day(selectedDay);
      const momentStartTime = moment(shiftStarter.startTime, "HH:mm");
      const momentEndTime = moment(shiftStarter.endTime, "HH:mm");

      const startDate = dayConvertedToDate
        .clone()
        .set({
          hour: momentStartTime.get("hour"),
          minute: momentStartTime.get("minute"),
        })
        .toDate();
      let endDate = dayConvertedToDate
        .clone()
        .set({
          hour: momentEndTime.get("hour"),
          minute: momentEndTime.get("minute"),
        })
        .toDate();
      if (endDate <= startDate) {
        endDate = moment(endDate).add(1, "days").toDate();
      }

      const startDateWIthTimeZone = moment(startDate);
      startDateWIthTimeZone.tz(businessTimezone, true);

      const endDateWIthTimeZone = moment(endDate);
      endDateWIthTimeZone.tz(businessTimezone, true);
      shifts.push({
        id: uuidv4(),
        position: shiftStarter.position,
        startDate: firestore.Timestamp.fromDate(startDateWIthTimeZone.toDate()),
        endDate: firestore.Timestamp.fromDate(endDateWIthTimeZone.toDate()),
        startTime: shiftStarter.startTime,
        endTime: shiftStarter.endTime,
        staffId: null,
        overTimeRate: 0,
        shiftTypeId: shiftType,
        onCall: false,
        subRole: null,
      });
    });
  });

  const scheduleStartDateWithTimeZone = moment(weekRange[0]);
  scheduleStartDateWithTimeZone.tz(businessTimezone, true);

  const scheduleEndDateWithTimeZone = moment(weekRange[weekRange.length - 1]);
  scheduleEndDateWithTimeZone.tz(businessTimezone, true);

  const freshSchedule = {
    availabilityType: "weekly",
    businessId,
    startDate: firestore.Timestamp.fromDate(
      scheduleStartDateWithTimeZone.toDate()
    ),
    endDate: firestore.Timestamp.fromDate(scheduleEndDateWithTimeZone.toDate()),
    createdAt: new Date(),
    updatedAt: new Date(),
    deleted: false,
    hasTimeOffPay: false,
    overtimeHourLimit: 40,
    recur: false,
    isDraft: true,
    shifts,
    departmentId: "foh",
  };
  const draftRef = firestore().collection("DraftSchedule").doc();

  await firestore()
    .collection("DraftSchedule")
    .doc(draftRef.id)
    .set({
      ...freshSchedule,
      id: draftRef.id,
      isDraft: true,
      associatedScheduleId: null,
    });
  console.log("freshSchedule", freshSchedule);

  return {
    ...freshSchedule,
    isDraft: true,
    id: draftRef.id,
    associatedScheduleId: null,
  };
};

export enum ScheduleEventType {
  time_off = "time_off",
  drop = "drop",
  cover = "cover",
  trade = "trade",
}

export const fetchSchedules = (staffMember: StaffMember) => {
  subscribeToCollectionDocuments<TangoSchedule>(
    "Schedule",
    ["businessId", "==", staffMember.businessId],
    receiveSchedules,
    "schedules",
    composeUsableTangoSchedule
  );
};

export const getFixedScheduleForDate = (
  weekRange: Date[],
  fixedSchedules: TangoFixedSchedule[],
  businessTimezone: string,
  date: Date
): TangoSchedule | undefined => {
  const activeFixedSchedule = fixedSchedules.find((fixedSchedule) => {
    const dateInBusinessTimezone = moment(date)
      .set({ hours: 0, minutes: 0 })
      .tz(businessTimezone);
    const fixedScheduleHasAlreadyStarted = moment(
      fixedSchedule?.recurrence?.startDate?.toDate()
    ).isBefore(dateInBusinessTimezone);
    const fixedScheduleEndDateIsInTheFuture = fixedSchedule?.recurrence?.endDate
      ? moment(fixedSchedule?.recurrence?.endDate?.toDate()).isAfter(
          dateInBusinessTimezone
        )
      : false;
    return (
      fixedSchedule.isActive &&
      fixedScheduleHasAlreadyStarted &&
      (fixedSchedule?.recurrence?.indefinite ||
        fixedScheduleEndDateIsInTheFuture)
    );
  });
  // console.log("Found fixed scheudle for a day", date, activeFixedSchedule)
  if (!activeFixedSchedule) return undefined;

  const scheduleStartDateWithTimeZone = moment(weekRange[0]);
  scheduleStartDateWithTimeZone.tz(businessTimezone, true);

  const scheduleEndDateWithTimeZone = moment(weekRange[weekRange.length - 1]);
  scheduleEndDateWithTimeZone.tz(businessTimezone, true);

  const enhanceDuplicatedShift = (
    duplicatedShift: DuplicatedTangoShift
  ): TangoShift => {
    const startOfWeek = moment(weekRange[0]);
    const dayConvertedToDate =
      startOfWeek.day() && duplicatedShift.startDayOfTheWeek === 0
        ? startOfWeek
            .add(7 - startOfWeek.day(), "days")
            .day(duplicatedShift.startDayOfTheWeek)
        : startOfWeek.day(duplicatedShift.startDayOfTheWeek);
    const momentStartTime = moment(duplicatedShift.startTime, "HH:mm");
    const momentEndTime = moment(duplicatedShift.endTime, "HH:mm");

    const startDate = dayConvertedToDate
      .set({
        hour: momentStartTime.get("hour"),
        minute: momentStartTime.get("minute"),
        day: duplicatedShift.startDayOfTheWeek,
      })
      .toDate();

    let endDate = dayConvertedToDate
      .set({
        hour: momentEndTime.get("hour"),
        minute: momentEndTime.get("minute"),
        day: duplicatedShift.endDayOfTheWeek,
      })
      .toDate();
    if (endDate < startDate) {
      endDate = moment(endDate).add(1, "days").toDate();
    }

    const startDateWIthTimeZone = moment(startDate);
    startDateWIthTimeZone.tz(businessTimezone, true);

    const endDateWIthTimeZone = moment(endDate);
    endDateWIthTimeZone.tz(businessTimezone, true);

    return {
      id: duplicatedShift.id,
      position: duplicatedShift.position,
      startDate: firestore.Timestamp.fromDate(startDateWIthTimeZone.toDate()),
      endDate: firestore.Timestamp.fromDate(endDateWIthTimeZone.toDate()),
      startTime: duplicatedShift.startTime,
      shiftTypeId: duplicatedShift.shiftTypeId,
      endTime: duplicatedShift.endTime,
      staffId: duplicatedShift.staffId,
      overTimeRate: duplicatedShift.overTimeRate,
      onCall: duplicatedShift.onCall,
      subRole: duplicatedShift.subRole,
    };
  };

  const newLocalScheduleBasedOnDuplicatedSchedule: TangoSchedule = {
    id: uniqueId(),
    availabilityType: activeFixedSchedule.availabilityType,
    businessId: activeFixedSchedule.businessId,
    startDate: firestore.Timestamp.fromDate(
      scheduleStartDateWithTimeZone.toDate()
    ),
    endDate: firestore.Timestamp.fromDate(scheduleEndDateWithTimeZone.toDate()),
    departmentId: activeFixedSchedule.departmentId,
    hasTimeOffPay: false,
    overtimeHourLimit: 40,
    recur: false,
    isDraft: undefined,
    isDuplicate: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    deleted: false,
    shifts: activeFixedSchedule.shifts.map(enhanceDuplicatedShift),
  };
  return newLocalScheduleBasedOnDuplicatedSchedule;
};

export const getFixedScheduleForAWeekRange = (
  weekRange: Date[],
  fixedSchedules: TangoFixedSchedule[],
  businessTimezone: string
): TangoSchedule | undefined => {
  const activeFixedSchedule = fixedSchedules.find((fixedSchedule) => {
    const weekRangeStartDate = moment(weekRange[0])
      .set({ hours: 0, minutes: 0 })
      .tz(businessTimezone);
    const weekRangeEndDate = moment(weekRange[weekRange.length - 1])
      .set({ hours: 0, minutes: 0 })
      .tz(businessTimezone);
    const fixedScheduleHasAlreadyStarted = moment(
      fixedSchedule?.recurrence?.startDate?.toDate()
    ).isBefore(weekRangeStartDate);
    const fixedScheduleEndDateIsInTheFuture = fixedSchedule?.recurrence?.endDate
      ? moment(fixedSchedule?.recurrence?.endDate?.toDate()).isSameOrAfter(
          weekRangeEndDate
        )
      : false;
    return (
      fixedSchedule.isActive &&
      fixedScheduleHasAlreadyStarted &&
      (fixedSchedule?.recurrence?.indefinite ||
        fixedScheduleEndDateIsInTheFuture)
    );
  });
  if (!activeFixedSchedule) return undefined;
  const scheduleStartDateWithTimeZone = moment(weekRange[0]);
  scheduleStartDateWithTimeZone.tz(businessTimezone, true);

  const scheduleEndDateWithTimeZone = moment(weekRange[weekRange.length - 1]);
  scheduleEndDateWithTimeZone.tz(businessTimezone, true);

  const enhanceDuplicatedShift = (
    duplicatedShift: DuplicatedTangoShift
  ): TangoShift => {
    const startOfWeek = moment(weekRange[0]);
    const dayConvertedToDate = startOfWeek.day(
      duplicatedShift.startDayOfTheWeek
    );
    // const dayConvertedToDate = startOfWeek.day() && duplicatedShift.startDayOfTheWeek === 0 ? startOfWeek.add(7 - startOfWeek.day(), 'days').day(duplicatedShift.startDayOfTheWeek) : startOfWeek.day(duplicatedShift.startDayOfTheWeek)
    // const dayConvertedToDate = startOfWeek.day(duplicatedShift.startDayOfTheWeek)

    const momentStartTime = moment(duplicatedShift.startTime, "HH:mm");
    const momentEndTime = moment(duplicatedShift.endTime, "HH:mm");

    let startDate = dayConvertedToDate
      .clone()
      .set({
        hour: momentStartTime.get("hour"),
        minute: momentStartTime.get("minute"),
        day: duplicatedShift.startDayOfTheWeek,
      })
      .toDate();

    let endDate = dayConvertedToDate
      .clone()
      .set({
        hour: momentEndTime.get("hour"),
        minute: momentEndTime.get("minute"),
        day: duplicatedShift.endDayOfTheWeek,
      })
      .toDate();
    // console.log("startDate", startDate)
    // console.log("endDate", endDate)
    // console.log("moment(endDate).diff(moment(startDate), 'days')", moment(endDate).diff(moment(startDate), 'days'))

    if (moment(startDate).isBefore(weekRange[0])) {
      startDate = moment(startDate).add(7, "days").toDate();
    }
    if (moment(endDate).diff(moment(startDate), "days") < -1) {
      endDate = moment(endDate).add(7, "days").toDate();
    }

    if (endDate <= startDate) {
      endDate = moment(endDate).add(1, "days").toDate();
    }

    const startDateWIthTimeZone = moment(startDate);
    startDateWIthTimeZone.tz(businessTimezone, true);

    const endDateWIthTimeZone = moment(endDate);
    endDateWIthTimeZone.tz(businessTimezone, true);

    return {
      id: duplicatedShift.id,
      position: duplicatedShift.position,
      startDate: firestore.Timestamp.fromDate(startDateWIthTimeZone.toDate()),
      endDate: firestore.Timestamp.fromDate(endDateWIthTimeZone.toDate()),
      startTime: duplicatedShift.startTime,
      shiftTypeId: duplicatedShift.shiftTypeId,
      endTime: duplicatedShift.endTime,
      staffId: duplicatedShift.staffId,
      overTimeRate: duplicatedShift.overTimeRate,
      onCall: duplicatedShift.onCall,
      subRole: duplicatedShift.subRole,
    };
  };

  // const invalidShifts = activeFixedSchedule.shifts
  //   .map(enhanceDuplicatedShift)
  //   .filter(
  //     (sh) =>
  //       Math.abs(
  //         moment(sh.startDate.toDate()).diff(
  //           moment(sh.endDate.toDate()),
  //           "hours"
  //         )
  //       ) > 12
  //   );

  // console.log("invalidShifts", invalidShifts, activeFixedSchedule.id);

  const newLocalScheduleBasedOnDuplicatedSchedule: TangoSchedule = {
    id: uniqueId(),
    availabilityType: activeFixedSchedule.availabilityType,
    businessId: activeFixedSchedule.businessId,
    startDate: firestore.Timestamp.fromDate(
      scheduleStartDateWithTimeZone.toDate()
    ),
    endDate: firestore.Timestamp.fromDate(scheduleEndDateWithTimeZone.toDate()),
    hasTimeOffPay: false,
    departmentId: activeFixedSchedule.departmentId,
    overtimeHourLimit: 40,
    recur: false,
    isDraft: undefined,
    isDuplicate: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    deleted: false,
    shifts: activeFixedSchedule.shifts.map(enhanceDuplicatedShift),
  };
  return newLocalScheduleBasedOnDuplicatedSchedule;
};

export const fixFixedAvailability = async (
  fixedAvailabilities: FixedAvailability[]
) => {
  console.log(
    "fixedAvailabilities",
    fixedAvailabilities,
    fixedAvailabilities.length
  );
  const fixedAvailabilitiesWithWrongTimes = fixedAvailabilities.filter((fa) => {
    return fa.schedule.find((s) => !s.available);
  });
  console.log(
    "fixedAvailabilitiesWithWrongTimes",
    fixedAvailabilitiesWithWrongTimes.length
  );

  // for await (const fa of fixedAvailabilities) {
  //   const fixedAvailabilitySchedule = fa.schedule.map((d) => {
  //     if (!d.available) {
  //       return d
  //     }
  //     return ({...d, startTime: "00:00", endTime: "00:00"})
  //   })

  //   await firestore().collection("FixedAvailabilities").doc(fa.id).update({schedule: fixedAvailabilitySchedule})
  //   // const fixedA = {...fa, schedule: fixedAvailabilitySchedule}
  //   // console.log("fixedA", fixedA)
  // }
  console.log("Done!");
};

export const getScheduleForADate = (
  date: Date,
  schedules: TangoSchedule[],
  businessTimezone: string,
  departmentId?: "foh" | "boh"
): TangoSchedule | undefined => {
  if (departmentId) {
    return schedules.find((schedule: TangoSchedule) => {
      const dateInBusinessTimezone = moment(date).tz(businessTimezone);
      return (
        (dateInBusinessTimezone.isSameOrAfter(
          moment(schedule.startDate.toDate())
        ) ||
          dateInBusinessTimezone.isSameOrAfter(
            moment(schedule.startDate.toDate()),
            "date"
          )) &&
        (dateInBusinessTimezone.isSameOrBefore(
          moment(schedule.endDate.toDate())
        ) ||
          dateInBusinessTimezone.isSameOrBefore(
            moment(schedule.endDate.toDate()),
            "date"
          )) &&
        schedule.departmentId === departmentId
      );
    });
  } else {
    const mergedSchedule = findAndMergeSchedulesWithDepartmentIdDefinedByDate(
      schedules,
      date,
      businessTimezone
    );
    if (mergedSchedule) {
      return mergedSchedule;
    }

    return schedules.find((schedule: TangoSchedule) => {
      const dateInBusinessTimezone = moment(date).tz(businessTimezone);
      return (
        (dateInBusinessTimezone.isSameOrAfter(
          moment(schedule.startDate.toDate())
        ) ||
          dateInBusinessTimezone.isSameOrAfter(
            moment(schedule.startDate.toDate()),
            "date"
          )) &&
        (dateInBusinessTimezone.isSameOrBefore(
          moment(schedule.endDate.toDate())
        ) ||
          dateInBusinessTimezone.isSameOrBefore(
            moment(schedule.endDate.toDate()),
            "date"
          ))
      );
    });
  }
};

const findScheduleForSelectedDepartmentId = (
  schedules: TangoSchedule[],
  departmentId: "foh" | "boh",
  weekRange: Date[],
  businessTimezone: string
) => {
  const scheduleForDepartment = schedules.find((schedule: TangoSchedule) => {
    const weekRangeInBusinessTimeZone = weekRange.map(
      (day: Date, index: number) => {
        const dayInBusinessTimeZone =
          index === weekRange.length - 1
            ? moment(day).set({ hours: 23, minutes: 59, seconds: 59 })
            : moment(day).set({ hours: 0, minutes: 0 });
        dayInBusinessTimeZone.tz(businessTimezone, true);
        return dayInBusinessTimeZone.toDate();
      }
    );
    return (
      schedule.departmentId === departmentId &&
      schedule.startDate.toDate() >= weekRangeInBusinessTimeZone[0] &&
      schedule.endDate.toDate() <=
        weekRangeInBusinessTimeZone[weekRange.length - 1]
    );
  });
  return scheduleForDepartment;
};

export type DraftIdWithDepartmentId = {
  draftId: string;
  departmentId: "boh" | "foh";
};

export const findDepartmentSchedulesIdsForWeekRange = (
  schedules: TangoSchedule[],
  weekRange: Date[],
  businessTimezone: string
): DraftIdWithDepartmentId[] => {
  const schedulesWithDepartmentId = schedules.filter(
    (schedule: TangoSchedule) => {
      const weekRangeInBusinessTimeZone = weekRange.map(
        (day: Date, index: number) => {
          const dayInBusinessTimeZone =
            index === weekRange.length - 1
              ? moment(day).set({ hours: 23, minutes: 59, seconds: 59 })
              : moment(day).set({ hours: 0, minutes: 0 });
          dayInBusinessTimeZone.tz(businessTimezone, true);
          return dayInBusinessTimeZone.toDate();
        }
      );
      return (
        !schedule.deleted &&
        schedule.departmentId &&
        schedule.startDate.toDate() >= weekRangeInBusinessTimeZone[0] &&
        schedule.endDate.toDate() <=
          weekRangeInBusinessTimeZone[weekRange.length - 1]
      );
    }
  );
  const result: DraftIdWithDepartmentId[] = [];
  schedulesWithDepartmentId.forEach((schedule) => {
    if (schedule.departmentId) {
      result.push({
        departmentId: schedule.departmentId,
        draftId: schedule.id,
      });
    }
  });
  return result;
};

const findAndMergeSchedulesWithDepartmentIdDefined = (
  schedules: TangoSchedule[],
  weekRange: Date[],
  businessTimezone: string
) => {
  const schedulesWithDepartmentId = schedules.filter(
    (schedule: TangoSchedule) => {
      const weekRangeInBusinessTimeZone = weekRange.map(
        (day: Date, index: number) => {
          const dayInBusinessTimeZone =
            index === weekRange.length - 1
              ? moment(day).set({ hours: 23, minutes: 59, seconds: 59 })
              : moment(day).set({ hours: 0, minutes: 0 });
          dayInBusinessTimeZone.tz(businessTimezone, true);
          return dayInBusinessTimeZone.toDate();
        }
      );
      return (
        schedule.departmentId &&
        schedule.startDate.toDate() >= weekRangeInBusinessTimeZone[0] &&
        schedule.endDate.toDate() <=
          weekRangeInBusinessTimeZone[weekRange.length - 1]
      );
    }
  );

  if (schedulesWithDepartmentId.length) {
    console.log(
      "schedulesWithDepartmentId.length",
      schedulesWithDepartmentId.length
    );
    if (schedulesWithDepartmentId.length > 2) {
      console.log("first schedule", schedulesWithDepartmentId);
    }
    let mergedShifts: TangoShift[] = [];
    schedulesWithDepartmentId.forEach((sch) => {
      if (sch.shifts?.length) {
        mergedShifts = [
          ...mergedShifts,
          ...sch.shifts.map((shift) => ({ ...shift, draftScheduleId: sch.id })),
        ];
      }
    });
    const mergedSchedule: TangoSchedule = {
      ...schedulesWithDepartmentId[0],
      departmentId: schedulesWithDepartmentId[0].departmentId,
      actualDailySalesOverrides:
        schedulesWithDepartmentId
          .filter((s) => s.departmentId === "foh")
          .find((s) => !_.isEmpty(s.actualDailySalesOverrides))
          ?.actualDailySalesOverrides ?? undefined,
      shifts: mergedShifts,
      mergedSchedules: schedulesWithDepartmentId.map((s) => s.id),
    };

    return mergedSchedule;
  }
};

const findAndMergeSchedulesWithDepartmentIdDefinedByDate = (
  schedules: TangoSchedule[],
  date: Date,
  businessTimezone: string
) => {
  const schedulesWithDepartmentId = schedules.filter(
    (schedule: TangoSchedule) => {
      const dateInBusinessTimezone = moment(date).tz(businessTimezone);
      return (
        (dateInBusinessTimezone.isSameOrAfter(
          moment(schedule.startDate.toDate())
        ) ||
          dateInBusinessTimezone.isSameOrAfter(
            moment(schedule.startDate.toDate()),
            "date"
          )) &&
        (dateInBusinessTimezone.isSameOrBefore(
          moment(schedule.endDate.toDate())
        ) ||
          dateInBusinessTimezone.isSameOrBefore(
            moment(schedule.endDate.toDate()),
            "date"
          )) &&
        schedule.departmentId
      );
    }
  );

  if (schedulesWithDepartmentId.length) {
    let mergedShifts: TangoShift[] = [];
    schedulesWithDepartmentId.forEach((sch) => {
      if (sch.shifts?.length) {
        mergedShifts = [
          ...mergedShifts,
          ...sch.shifts.map((shift) => ({ ...shift, draftScheduleId: sch.id })),
        ];
      }
    });
    const mergedSchedule: TangoSchedule = {
      ...schedulesWithDepartmentId[0],
      departmentId: schedulesWithDepartmentId[0].departmentId || "foh",
      shifts: mergedShifts,
      mergedSchedules: schedulesWithDepartmentId.map((s) => s.id),
    };

    return mergedSchedule;
  }
};

export const getScheduleForWeekRange = (
  weekRange: Date[],
  schedules: TangoSchedule[],
  businessTimezone: string,
  departmentId?: "foh" | "boh"
): TangoSchedule | undefined => {
  if (departmentId) {
    const scheduleForDepartmentId = findScheduleForSelectedDepartmentId(
      schedules,
      departmentId,
      weekRange,
      businessTimezone
    );
    return scheduleForDepartmentId;
  } else {
    const mergedSchedule = findAndMergeSchedulesWithDepartmentIdDefined(
      schedules,
      weekRange,
      businessTimezone
    );

    if (mergedSchedule) {
      return mergedSchedule;
    }
    return schedules.find((schedule: TangoSchedule) => {
      const weekRangeInBusinessTimeZone = weekRange.map(
        (day: Date, index: number) => {
          const dayInBusinessTimeZone =
            index === weekRange.length - 1
              ? moment(day).set({ hours: 23, minutes: 59, seconds: 59 })
              : moment(day).set({ hours: 0, minutes: 0 });
          dayInBusinessTimeZone.tz(businessTimezone, true);
          return dayInBusinessTimeZone.toDate();
        }
      );
      return (
        schedule.startDate.toDate() >= weekRangeInBusinessTimeZone[0] &&
        schedule.endDate.toDate() <=
          weekRangeInBusinessTimeZone[weekRange.length - 1]
      );
    });
  }
};

export const getFixedScheduleForWeekRange = (
  weekRange: Date[],
  schedules: TangoFixedSchedule[],
  businessTimezone: string
): TangoFixedSchedule | undefined =>
  (schedules || []).find((schedule: TangoFixedSchedule) => {
    const weekRangeInBusinessTimeZone = weekRange.map(
      (day: Date, index: number) => {
        const dayInBusinessTimeZone =
          index === weekRange.length - 1
            ? moment(day).set({ hours: 23, minutes: 59, seconds: 59 })
            : moment(day).set({ hours: 0, minutes: 0 });
        dayInBusinessTimeZone.tz(businessTimezone, true);
        return dayInBusinessTimeZone.toDate();
      }
    );
    return (
      schedule.recurrence.startDate.toDate() >=
        weekRangeInBusinessTimeZone[0] &&
      schedule.recurrence.endDate.toDate() <=
        weekRangeInBusinessTimeZone[weekRange.length - 1]
    );
  });

export const getPreviousScheduleByAWeekRange = (
  weekRange: Date[],
  schedules: TangoSchedule[]
): TangoSchedule | undefined => {
  const sortedPreviousSchedules = schedules
    .sort((a, b) => a.startDate.toDate() - b.startDate.toDate())
    .filter((schedule) => schedule.startDate.toDate() < weekRange[0]);
  return sortedPreviousSchedules[sortedPreviousSchedules.length - 1];
};

export const createShiftsBasedOnAnotherScheduleAndNewWeekRange = (
  weekRange: Date[],
  schedule: TangoSchedule
): TangoShift[] => {
  const processedShifts: TangoShift[] = [];
  schedule.shifts.forEach((originalShift) => {
    const originalShiftDay = moment(originalShift.startDate.toMillis()).get(
      "day"
    );
    const startOfWeek = moment(weekRange[0]);
    const dayConvertedToDate =
      startOfWeek.day() && originalShiftDay === 0
        ? startOfWeek.add(7 - startOfWeek.day(), "days").day(originalShiftDay)
        : startOfWeek.day(originalShiftDay);
    const momentStartTime = moment(originalShift.startTime, "HH:mm");
    const momentEndTime = moment(originalShift.endTime, "HH:mm");

    const startDate = dayConvertedToDate
      .set({
        hour: momentStartTime.get("hour"),
        minute: momentStartTime.get("minute"),
      })
      .toDate();
    const endDate = dayConvertedToDate
      .set({
        hour: momentEndTime.get("hour"),
        minute: momentEndTime.get("minute"),
      })
      .toDate();
    processedShifts.push({
      id: uuidv4(),
      position: originalShift.position,
      startDate: firestore.Timestamp.fromDate(startDate),
      endDate: firestore.Timestamp.fromDate(endDate),
      startTime: originalShift.startTime,
      shiftTypeId: originalShift.shiftTypeId,
      endTime: originalShift.endTime,
      staffId: null,
      overTimeRate: 0,
      onCall: false,
      subRole: null,
    });
  });
  return processedShifts;
};

export const createScheduleBasedOnAnotherScheduleAndNewWeekRange = (
  weekRange: Date[],
  schedule: TangoSchedule
): TangoSchedule => {
  const processedShifts = createShiftsBasedOnAnotherScheduleAndNewWeekRange(
    weekRange,
    schedule
  );

  const freshSchedule = {
    availabilityType: "weekly",
    businessId: schedule.businessId,
    startDate: firestore.Timestamp.fromDate(weekRange[0]),
    endDate: firestore.Timestamp.fromDate(weekRange[weekRange.length - 1]),
    hasTimeOffPay: false,
    departmentId: schedule.departmentId,
    overtimeHourLimit: 40,
    recur: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    deleted: false,
    isDraft: true,
    shifts: processedShifts,
  };

  const ref = firestore().collection("Schedule").doc();

  return {
    ...freshSchedule,
    id: ref.id,
  };
};

export const groupShiftsByPosition = (
  shifts: TangoShift[]
): GroupedShiftsByPosition[] => {
  const groupedShifts = _.groupBy(shifts, (shift: TangoShift) => [
    shift.position,
  ]);
  return _.keys(groupedShifts).map((key) => ({
    positionId: groupedShifts[key][0].position,
    shifts: groupedShifts[key].sort(
      (a, b) =>
        moment(a.startTime, "HH:mm").unix() -
        moment(b.startTime, "HH:mm").unix()
    ),
  }));
};

export const deleteScheduleShifts = async (
  schedule: TangoSchedule,
  shiftsToDelete: TangoShift[]
) => {
  const newShifts = schedule.shifts.filter(
    (sh) => !shiftsToDelete.find((shiftToDelete) => shiftToDelete.id === sh.id)
  );
  await firestore()
    .collection("Schedule")
    .doc(schedule.id)
    .update({ shifts: newShifts });
};

export const editScheduleShifts = async (
  schedule: TangoSchedule,
  updatedShifts: TangoShift[]
) => {
  const newShifts = [
    ...schedule.shifts.map((shift) => {
      const updatedShift = updatedShifts.find((sh) => sh.id === shift.id);
      if (updatedShift) {
        return updatedShift;
      }
      return shift;
    }),
    ...updatedShifts.filter(
      (sh) =>
        !schedule.shifts.find((existingShift) => existingShift.id === sh.id)
    ),
  ];
  await firestore()
    .collection("Schedule")
    .doc(schedule.id)
    .update({ shifts: newShifts });
};

export const groupShiftsByShiftTypeAndTimeFrame = (
  shifts: TangoShift[],
  roleFilterId: string | null
): GroupedShifts[] => {
  const groupedShifts = _.groupBy(shifts, (shift: TangoShift) => [
    shift.startTime,
    shift.endTime,
    shift.shiftTypeId,
  ]);
  return _.keys(groupedShifts).map((key) => ({
    shiftTypeId: groupedShifts[key][0].shiftTypeId,
    startTime: groupedShifts[key][0].startTime,
    endTime: groupedShifts[key][0].endTime,
    shifts: roleFilterId
      ? groupedShifts[key].filter((shift) => shift.position === roleFilterId)
      : groupedShifts[key],
    shiftPositions: [],
  }));
};

export const clearSchedule = async (scheduleId: string) => {
  try {
    await firestore().collection("Schedule").doc(scheduleId).delete();
  } catch (e) {
    console.log("error, clearing schedule", e);
  }
};

export const old_clearDraftSchedule = async (draftScheduleId: string) => {
  try {
    await firestore()
      .collection("DraftSchedule")
      .doc(draftScheduleId)
      .update({ shifts: [] });
  } catch (e) {
    console.log("error, clearing schedule", e);
  }
};

export const clearDuplicateSchedule = async (schedule: TangoSchedule) => {
  try {
    const res = await tangoScheduleApiClient.post<{
      additionalShifts: TangoShift[];
    }>(ScheduleApiRoutesMap.clearDuplicateSchedule, {
      startDate: moment(schedule.startDate?.toDate()).toDate(),
      endDate: moment(schedule.endDate?.toDate()).toDate(),
      businessId: schedule.businessId,
    });
    console.log("res", res.data);
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const old_clearDuplicateSchedule = async (schedule: TangoSchedule) => {
  try {
    const newDraftSchedule = firestore().collection("DraftSchedule").doc();
    console.log("Clearing duplicate schedule", "newDraftSchedule.id");

    await firestore()
      .collection("DraftSchedule")
      .doc(newDraftSchedule.id)
      .set({
        ...schedule,
        id: newDraftSchedule.id,
        isDraft: true,
        isDuplicate: false,
        shifts: [],
      });
  } catch (e) {
    console.log("error, clearing schedule", e);
  }
};

export const getShiftsForADay = (
  dayOfWeek: Date,
  weekRange: Date[],
  schedules: TangoSchedule[],
  businessTimezone: string,
  fixedSchedules: TangoFixedSchedule[],
  departmentId?: "foh" | "boh"
): TangoShift[] => {
  let schedule = getScheduleForWeekRange(
    weekRange,
    schedules,
    businessTimezone,
    departmentId
  );
  if (!schedule) {
    schedule = getFixedScheduleForAWeekRange(
      weekRange,
      fixedSchedules,
      businessTimezone
    );
  }

  const scheduleShifts = schedule?.shifts;
  if (scheduleShifts?.length) {
    return scheduleShifts.filter(
      (shift: TangoShift) =>
        moment.tz(shift.startDate.toDate(), businessTimezone).day() ===
        moment.tz(dayOfWeek, businessTimezone).day()
    );
  }
  return [];
};

export const getRequestsWithOpenRequestsForADay = (
  dayOfWeek: Date,
  requests: ScheduleEvent[],
  weekRange: Date[],
  fellowStaffMembers: StaffMember[]
): ScheduleEvent[] => {
  const requestsForADay: ScheduleEvent[] = requests.filter(
    (request: ScheduleEvent) =>
      moment(request.eventDate.toDate()).isBetween(
        moment(weekRange[0]).set("hour", 0),
        moment(weekRange[weekRange.length - 1]).set("hour", 23),
        null,
        "[]"
      ) &&
      moment(request.eventDate.toMillis()).day() === moment(dayOfWeek).day()
  );

  const requestsExtendedWithOpenShifts: ScheduleEvent[] = [];

  requestsForADay.forEach((request: ScheduleEvent) => {
    if (request?.acceptedBy?.length) {
      request?.acceptedBy?.forEach((staffId) => {
        const fellowStaffMember = fellowStaffMembers.find(
          (sm) => sm?.id === staffId
        );
        if (fellowStaffMember) {
          if (request?.eventType === "trade") {
            const receivingTradeData = request?.openTradeAcceptedList?.find(
              (openTradeAcceptedData) =>
                openTradeAcceptedData?.staffId === fellowStaffMember?.id
            );
            if (receivingTradeData) {
              requestsExtendedWithOpenShifts.push({
                ...request,
                localOptionalRequestId: uuidv4(),
                receivingScheduleId: receivingTradeData?.scheduleId,
                receivingShiftId: receivingTradeData?.shiftId,
                receiverStaff: {
                  id: staffId,
                  name: `${fellowStaffMember?.contact?.firstName} ${fellowStaffMember?.contact?.lastName}`,
                },
              });
            }
          } else {
            requestsExtendedWithOpenShifts.push({
              ...request,
              localOptionalRequestId: uuidv4(),
              receiverStaff: {
                id: staffId,
                name: `${fellowStaffMember?.contact?.firstName} ${fellowStaffMember?.contact?.lastName}`,
              },
            });
          }
        }
      });
    }
  });
  return [
    ...requestsForADay.filter(
      (r) => !r?.acceptedBy?.length || r?.eventType === "drop"
    ),
    ...requestsExtendedWithOpenShifts,
  ].filter((r) => ["pending", "approved", "denied"].includes(r?.status));
};

export const getRequestsForADay = (
  dayOfWeek: Date,
  requests: ScheduleEvent[],
  weekRange: Date[]
): ScheduleEvent[] =>
  requests.filter(
    (request: ScheduleEvent) =>
      request.eventDate &&
      moment(request.eventDate.toDate()).isBetween(
        moment(weekRange[0]).set("hour", 0),
        moment(weekRange[weekRange.length - 1]).set("hour", 23),
        null,
        "[]"
      ) &&
      moment(request.eventDate.toMillis()).day() === moment(dayOfWeek).day()
  );

export const moveOrDuplicateMultipleShifts = (
  businessId: string,
  draftScheduleId: string,
  shiftIds: string[],
  selectedDays: string[],
  actionType: "move" | "duplicate"
) => {
  if (actionType === "move") {
    return tangoScheduleApiClient.put(ScheduleApiRoutesMap.moveMultipleShifts, {
      businessId,
      draftScheduleId,
      shiftIds,
      weekDayToMoveTo: selectedDays[0],
    });
  } else {
    return tangoScheduleApiClient.put(
      ScheduleApiRoutesMap.duplicateMultipleShifts,
      {
        businessId,
        draftScheduleId,
        shiftIds,
        weekDaysToDuplicateTo: selectedDays,
      }
    );
  }
};

export const deleteMultipleShifts = (
  businessId: string,
  shiftsTobeDeleted: { shiftId: string; draftScheduleId: string }[]
) => {
  return tangoScheduleApiClient.post(
    ScheduleApiRoutesMap.deleteMultipleShifts,
    {
      businessId,
      deleteRequests: shiftsTobeDeleted,
    }
  );
};
export const editMultipleShifts = (
  businessId: string,
  editRequests: EditShiftType[],
  deleteRequests: DeleteShiftType[],
  newShifts: ComposeShiftType[]
) => {
  const editShiftsPromise = tangoScheduleApiClient.put(
    ScheduleApiRoutesMap.editMultipleShifts,
    {
      businessId,
      editRequests,
    }
  );
  const deleteMultipleShiftsPromise = deleteRequests.length
    ? tangoScheduleApiClient.post(ScheduleApiRoutesMap.deleteMultipleShifts, {
        businessId,
        deleteRequests,
      })
    : true;
  const promiseArrayOfAdditionalShifts = newShifts.map((newShift) => {
    if (newShift.shiftStarters.length && newShift.draftScheduleId.length) {
      return tangoScheduleApiClient.put(
        ScheduleApiRoutesMap.composeAdditionalShifts,
        newShift
      );
    }
  });
  return Promise.all([
    editShiftsPromise,
    deleteMultipleShiftsPromise,
    ...promiseArrayOfAdditionalShifts,
  ]);
};

export const createShiftForStaffMember = (newShift: ComposeShiftType) => {
  return tangoScheduleApiClient.put(
    ScheduleApiRoutesMap.composeAdditionalShifts,
    newShift
  );
};

export const updateTargetLaborCost = (
  targetLaberCostForADay: TargetLaborCostForADayType
) => {
  console.log("targetLaberCostForADay", targetLaberCostForADay);
  return tangoScheduleApiClient.put(
    ScheduleApiRoutesMap.targetLc,
    targetLaberCostForADay
  );
};

export const updateDailyProjectedSales = (
  projectedSalesForDay: ProjectedSalesForADayType
) => {
  return tangoScheduleApiClient.put(
    ScheduleApiRoutesMap.dailyProjectedSales,
    projectedSalesForDay
  );
};
