import UIComponents from "@tangopay/tango-ui-library";
import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "components/Header";

import Sidebar from "../Sidebar";
import { headerRoutes } from "../routes";
import useItemDetail from "./useItemDetail";
import HorizontalTable from "components/Table/HorizontalTable";

const { Icon, Button, Modal } = UIComponents;
const ItemDetail = () => {
  const loc = useLocation();
  const itemId = useMemo(() => {
    const split = loc.pathname.split("/");
    return split[split.length - 1];
  }, [loc]);
  const isRaw = useMemo(() => {
    return loc.pathname.includes("rawItems");
  }, [loc]);
  const {
    itemDetail,
    detailColumns,
    detailInstructions,
    isEditingDetails,
    setIsEditingDetails,
    updateDetails,
    deleteItem,
    sales,
    vendorColumns,
    vendorInstructions,
    isEditingSales,
    setIsEditingSales,
    addSale,
    saveSales,
    deleteSales,
    recipes,
    recipeColumns,
    recipeInstructions,
    showPrepModal,
    closePrepModal,
    preparePrepItem,
    prepCount,
    setPrepCount,
  } = useItemDetail(itemId, isRaw);
  const detailAsList = useMemo(() => itemDetail ? [itemDetail] : [], [itemDetail]);
  const navigate = useNavigate();

  const handleDeleteClick = useCallback(() => {
    deleteItem().then(() => navigate(-1));
  }, [deleteItem, navigate]);

  const handleRecipeClick = useCallback((uniqueId: string) => {
    const item = recipes.find(i => i.uniqueId === uniqueId);
    if (!item) return;
    if (item.isMenuItem) {
      if (item.isModifier) {
        navigate(`/inventory/recipes/menu-item/modifiers/${uniqueId}`);
      } else {
        navigate(`/inventory/recipes/menu-item/products/${uniqueId}`);
      }
    } else {
      navigate(`/inventory/recipes/prepitem/${uniqueId}`);
    }
  }, [recipes]);
  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header routes={headerRoutes} selectedRoute={"/reviews"} />
      <div className="flex h-full overflow-hidden">
        <div
          className={"border-r border-grey-1 border-t-0 border-b-0 overflow-y-auto"}
          style={{ width: "10%", borderRightStyle: "solid" }}
        >
          <Sidebar />
        </div>

        <div
          style={{ width: "90%" }}
          className={"bg-off-white overflow-y-auto"}
        >
          <div
            className={
              "flex p-8 justify-between items-center bg-white border-b border-grey-1"
            }
            style={{ borderBottomStyle: "solid" }}
          >
            <div className={"flex items-center"}>
              <Icon
                name={"arrow-left"}
                size={"20"}
                className={"cursor-pointer"}
                onClick={() => navigate(-1)}
              />


              <div className={"text-big text-black font-lato-black ml-10"}>
                {itemDetail?.itemName}
              </div>

            </div>
            <div className={"flex items-center"}>
              <div
                className={
                  "font-lato-bold text-xs text-error-red mr-7 cursor-pointer"
                }
                onClick={handleDeleteClick}
              >
                Delete
              </div>
              {/* {!isRaw && <>
                <div className={"h-9 w-px bg-grey-1 mr-7"} />
                <Button
                  label={"Prepare " + itemDetail?.itemName}
                  type={"btn-style-2"}
                  size={"btn-large"}
                  onClick={openPrepModal}
                />
              </>} */}
            </div>
          </div>
          <div className={"flex p-8 justify-between"}>
            <div className={"w-4/12"}>
              <HorizontalTable
                isVertical
                title={"Details"}
                isEditing={isEditingDetails}
                setEditing={setIsEditingDetails}
                saveResults={updateDetails}
                instructions={detailInstructions}
                data={detailAsList}
                columns={detailColumns}
              />
            </div>
            <div className={"w-8/12 pl-8"}>
              {isRaw && <div
                className={
                  "bg-white border border-grey border-b-0 border-solid rounded-2xl"
                }
              >
                <HorizontalTable
                  title="Vendors"
                  columns={vendorColumns}
                  data={sales}
                  instructions={vendorInstructions}
                  saveResults={saveSales}
                  setEditing={setIsEditingSales}
                  isEditing={isEditingSales}
                  primaryActionLabel="Add Vendor"
                  primaryActionHandler={addSale}
                  deleteIds={deleteSales}
                />
              </div>}
              <div className={"mt-8"}>
                <div
                  className={
                    "bg-white border border-grey border-b-0 border-solid rounded-2xl"
                  }
                >
                  <HorizontalTable
                    title="Recipes"
                    columns={recipeColumns}
                    data={recipes}
                    instructions={recipeInstructions}
                    isEditing={false}
                    onRowClick={handleRecipeClick}
                    hideEdit
                    hideCheckboxes
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showPrepModal}
        closeModal={closePrepModal}
      >
        <input
          type="number"
          value={prepCount}
          onChange={(e) => setPrepCount(parseFloat(e.target.value))}
        /> {prepCount > 1 ? "Batches" : "Batch"}
        <Button label="Prepare" size="btn-small" onClick={preparePrepItem} type="btn-style-1" />
      </Modal>
    </div>
  );
};

export default ItemDetail;
