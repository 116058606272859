
import React from 'react';
import UIComponents from '@tangopay/tango-ui-library';
import EmployeeDetails from 'views/manager/NewTeam/views/EmployeeDetails';

const { Modal } = UIComponents;
type Props = {
    staffId: string | null;
    closeModal: () => void;
}
const StaffProfileModal = ({ staffId, closeModal }: Props) => {
    return (
        <Modal
            isOpen={Boolean(staffId)}
            closeModal={closeModal}
            onAfterClose={closeModal}
            width="1000"
        >
            {
                staffId ?
                    <EmployeeDetails staffId={staffId} />
                    :
                    null
            }


        </Modal>
    )
};

export default StaffProfileModal;