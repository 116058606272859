import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteScheduleShifts,
  editScheduleShifts,
  findDepartmentSchedulesIdsForWeekRange,
  getFixedScheduleForAWeekRange,
  getScheduleForWeekRange,
} from "controllers/schedule";
import { DepartmentId } from "controllers/staff";

import { getWeekDays } from "utils/manager";

import { setCurrentSchedule } from "model/actions/staffingAC";
import { getPositionNameById } from "model/selectors/businessSettings";
import { RootState } from "model/store";

import { AvailabilityForADay } from "../../WeeklyView/AvailabilitiesContent";

export type WeeklyScheduleViewType =
  | "shift_view"
  | "staff_member_view"
  | "day_view";

export type ScheduleStatus =
  | "published"
  | "unpublished_changes"
  | "empty"
  | "duplicated";

export const useScheduling = () => {
  const dispatch = useDispatch();

  const [weekRange, setWeekRange] = useState<Date[]>([]);
  const [date, setDate] = useState(new Date());
  const [selectedRequestId, setSelectedRequestId] = useState<string | null>(
    null
  );
  const [selectedRequest, setSelectedRequest] = useState<ScheduleEvent | null>(
    null
  );

  const [selectedTradeOrCoverRequestId, setSelectedTradeOrCoverRequestId] =
    useState<string | null>(null);
  const [selectedTradeOrCoverRequest, setSelectedTradeOrCoverRequest] =
    useState<ScheduleEvent | null>(null);

  const [weeklyScheduleViewType, setWeeklyScheduleViewType] =
    useState<WeeklyScheduleViewType>("shift_view");
  const [departmentScheduleViewType, setDepartmentScheduleViewType] =
    useState<DepartmentId | null>(null);

  const [showSingleDay, setShowSingleDay] = useState(false);

  const [
    weeklyStaffMemberViewFilterAnchorEl,
    setWeeklyStaffMemberViewFilterAnchorEl,
  ] = useState(null);
  const [availabilityViewFilterAnchorEl, setAvailabilityViewFilterAnchorEl] =
    useState(null);

  const [roleFilterId, setRoleFilterId] = useState<string | null>(null);
  const [availabilityRoleFilterId, setAvailabilityRoleFilterId] = useState<
    string | null
  >(null);

  const [staffMemberNameFilter, setStaffMemberNameFilter] =
    useState<string>("");
  const [availabilityStaffNameFilter, setAvailabilityStaffNameFilter] =
    useState<string>("");

  const [showActionModal, setShowActionModal] = useState(false);
  const [actionModalTitle, setActionModalTitle] = useState<string>("");
  const [showEditShiftModal, setShowEditShiftModal] = useState(false);
  const [shiftsToEdit, setShiftsToEdit] = useState<TangoShift[]>([]);
  const [showAssignedPersonModal, setShowAssignedPersonModal] = useState(false);
  const [showTempModal, setShowTempModal] = useState(false);
  const [showAssignStaffModalContent, setShowAssignStaffModalContent] =
    useState<{ scheduleId: string; shiftId: string } | null>(null);

  const [publishScheduleLoading, setPublishScheduleLoading] = useState(false);
  const [changeWeekAlertOpen, setChangeWeekAlertOpen] = useState(false);
  const [selectedShiftForEdit, setSelectedShiftForEdit] =
    useState<TangoShift | null>(null);

  const onFullNameInputChange = useCallback((e: any) => {
    setStaffMemberNameFilter(e.target.value);
  }, []);
  const [currentDateForDailyShiftView, setCurrentDateForDailyShiftView] =
    useState<Date | null>(null);

  const onFullNameInputChangeDebounced = useCallback(
    _.debounce(onFullNameInputChange, 300),
    []
  );

  const onAvailabilityFullNameInputChange = useCallback((e: any) => {
    setAvailabilityStaffNameFilter(e.target.value);
  }, []);

  const onAvailabilityFullNameInputChangeDebounced = useCallback(
    _.debounce(onAvailabilityFullNameInputChange, 300),
    []
  );

  const user: StaffMember = useSelector((state: RootState) => state.user);
  const schedules: TangoSchedule[] = useSelector(
    (state: RootState) => state.schedules
  );

  const draftSchedules: TangoSchedule[] = useSelector(
    (state: RootState) => state.draftSchedules
  );

  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const scheduleEvents: ScheduleEvent[] = useSelector(
    (state: RootState) => state.scheduleEvents
  );

  const fixedSchedules: TangoFixedSchedule[] = useSelector(
    (state: RootState) => state.fixedSchedules
  );

  const fixedAvailabilities: FixedAvailability[] = useSelector(
    (state: RootState) => state.fixedAvailabilities
  );
  useEffect(() => {
    if (weeklyScheduleViewType !== "day_view") {
      setCurrentDateForDailyShiftView(null);
    } else if (
      weeklyScheduleViewType === "day_view" &&
      !currentDateForDailyShiftView
    ) {
      setCurrentDateForDailyShiftView(weekRange[0]);
    }
  }, [weeklyScheduleViewType, currentDateForDailyShiftView, weekRange]);
  const publishedSchedulesWithDrafts = useMemo(() => {
    return [
      ...schedules.map((schedule) => {
        const draftForTheSchedule = draftSchedules.find(
          (draft) => draft?.associatedScheduleId === schedule?.id
        );
        if (draftForTheSchedule) {
          return draftForTheSchedule;
        }
        return schedule;
      }),
      ...(draftSchedules?.filter((draft) => !draft?.associatedScheduleId) ??
        []),
    ]?.filter((x) => !!x);
  }, [schedules, draftSchedules]);

  const generateAvailabilitiesForADay = useCallback(
    (day: Date) => {
      return fixedAvailabilities
        ?.filter(
          (a) =>
            a.isActive && fellowStaffMembers.find((s) => s.id === a.staffId)
        )
        .map((fa) => {
          const faSchedule = fa.schedule;
          const a = faSchedule.find(
            (sh) => sh.day === moment.weekdays()[moment(day).day()]
          );
          if (a) {
            if (!a.available) return null;
            const staffMember = fellowStaffMembers.find(
              (fsm) => fsm.id === fa.staffId
            );
            if (staffMember) {
              const staffFullName = `${staffMember.contact.firstName} ${staffMember.contact.lastName}`;
              if (availabilityStaffNameFilter) {
                if (
                  !staffFullName
                    .toLowerCase()
                    .includes(availabilityStaffNameFilter.toLowerCase())
                )
                  return null;
              }
              if (availabilityRoleFilterId) {
                if (
                  !(
                    staffMember?.primaryRole === availabilityRoleFilterId ||
                    staffMember?.secondaryRoles?.includes(
                      availabilityRoleFilterId
                    )
                  )
                ) {
                  return null;
                }
              }
              const staffMainRoleTitle = getPositionNameById(
                businessSettings,
                staffMember?.primaryRole || ""
              );
              return {
                staffId: fa.staffId,
                id: fa.id,
                startTime: a.startTime,
                endTime: a.endTime,
                available: a.available,
                staffMainRoleTitle,
                staffFullName,
                day: a.day,
              };
            }
            return null;
          }
          return null;
        })
        .filter((x) => !!x) as AvailabilityForADay[];
    },
    [
      fixedAvailabilities,
      fellowStaffMembers,
      businessSettings,
      availabilityRoleFilterId,
      availabilityStaffNameFilter,
    ]
  );

  const schedulesWithLocalSchedule: TangoSchedule[] = useMemo(() => {
    return draftSchedules.filter((x) => !!x);
  }, [draftSchedules]);

  const generateWeekRangeForSelectedDate = useCallback(
    (sd: Date) => {
      if (business) {
        const sdMoment = moment(sd).startOf("day").add(4, "hours");
        const sdNumber = sdMoment.day();
        const payrollFirstDayNumber = moment()
          .day(business?.payrollStartOfTheWeek)
          .get("day");
        if (sdNumber < payrollFirstDayNumber) {
          const adjustedWeekRangeStart = sdMoment
            .clone()
            .subtract(7, "days")
            .day(payrollFirstDayNumber);
          const adjustedSelectedDays = getWeekDays(
            adjustedWeekRangeStart.toDate()
          );
          return adjustedSelectedDays;
        } else {
          const regularWeekRangeStart = sdMoment
            .clone()
            .day(payrollFirstDayNumber);
          const regularSelectedDays = getWeekDays(
            regularWeekRangeStart.toDate()
          );
          return regularSelectedDays;
        }
      } else {
        return [];
      }
    },
    [business]
  );

  const selectedDepartment = useMemo(() => {
    if (
      departmentScheduleViewType === "boh" ||
      departmentScheduleViewType === "foh"
    ) {
      return departmentScheduleViewType;
    }
    return undefined;
  }, [departmentScheduleViewType]);

  useEffect(() => {
    if (business && !weekRange.length) {
      setDate(new Date());
    }
  }, [business, weekRange]);

  const duplicatedScheduleForAWeekRange = useMemo(() => {
    if (!business) return null;
    const schedule = getFixedScheduleForAWeekRange(
      weekRange,
      fixedSchedules,
      business?.timezone
    );
    if (schedule) {
      return schedule;
    }
    return null;
  }, [weekRange, fixedSchedules, business]);

  const draftsWithDepartmentForAWeek = useMemo(() => {
    if (business) {
      return findDepartmentSchedulesIdsForWeekRange(
        schedulesWithLocalSchedule,
        weekRange,
        business?.timezone ?? "America/Toronto"
      );
    }
    return [];
  }, [business, schedulesWithLocalSchedule, weekRange]);

  const scheduleForWeekRange = useMemo(() => {
    if (!business) return null;
    const schedule = getScheduleForWeekRange(
      weekRange,
      schedulesWithLocalSchedule,
      business?.timezone,
      selectedDepartment
    );
    console.log("schedule", schedule);
    console.log("selectedDepartment", selectedDepartment);
    if (schedule) {
      return schedule;
    }
    return null;
  }, [weekRange, schedulesWithLocalSchedule, business, selectedDepartment]);

  const bohScheduleForAWeekRange = useMemo(() => {
    const bohRef = draftsWithDepartmentForAWeek.find(
      (d) => d.departmentId === "boh"
    );
    if (bohRef) {
      return schedulesWithLocalSchedule.find(
        (schedule) => schedule.id === bohRef.draftId
      );
    }
  }, [draftsWithDepartmentForAWeek, schedulesWithLocalSchedule]);

  const bohDraftStatus: ScheduleStatus = useMemo(() => {
    if (bohScheduleForAWeekRange) {
      if (bohScheduleForAWeekRange?.associatedScheduleId) {
        const publishedScheduleForAWeekRange = schedules.find(
          (publishedSchedule) =>
            publishedSchedule.id ===
            bohScheduleForAWeekRange.associatedScheduleId
        );
        if (publishedScheduleForAWeekRange) {
          const publishedShifts = publishedScheduleForAWeekRange.shifts;
          const draftShifts = bohScheduleForAWeekRange.shifts;
          const differenceBetweenShifts = _.differenceWith(
            publishedShifts,
            draftShifts,
            _.isEqual
          );
          if (publishedShifts?.length !== draftShifts?.length) {
            return "unpublished_changes";
          }
          if (differenceBetweenShifts.length) {
            return "unpublished_changes";
          }
          return "published";
        } else {
          return "unpublished_changes";
        }
      } else {
        return "unpublished_changes";
      }
    } else if (duplicatedScheduleForAWeekRange) {
      return "unpublished_changes";
    } else {
      return "empty";
    }
  }, [bohScheduleForAWeekRange, duplicatedScheduleForAWeekRange, schedules]);

  const fohScheduleForAWeekRange = useMemo(() => {
    const fohRef = draftsWithDepartmentForAWeek.find(
      (d) => d.departmentId === "foh"
    );
    if (fohRef) {
      return schedulesWithLocalSchedule.find(
        (schedule) => schedule.id === fohRef.draftId
      );
    }
  }, [draftsWithDepartmentForAWeek, schedulesWithLocalSchedule]);

  const fohDraftStatus: ScheduleStatus = useMemo(() => {
    if (fohScheduleForAWeekRange) {
      if (fohScheduleForAWeekRange?.associatedScheduleId) {
        const publishedScheduleForAWeekRange = schedules.find(
          (publishedSchedule) =>
            publishedSchedule.id ===
            fohScheduleForAWeekRange.associatedScheduleId
        );
        if (publishedScheduleForAWeekRange) {
          const publishedShifts = publishedScheduleForAWeekRange.shifts;
          const draftShifts = fohScheduleForAWeekRange.shifts;
          const differenceBetweenShifts = _.differenceWith(
            publishedShifts,
            draftShifts,
            _.isEqual
          );
          if (publishedShifts?.length !== draftShifts?.length) {
            return "unpublished_changes";
          }
          if (differenceBetweenShifts.length) {
            return "unpublished_changes";
          }
          return "published";
        } else {
          return "unpublished_changes";
        }
      } else {
        return "unpublished_changes";
      }
    } else if (duplicatedScheduleForAWeekRange) {
      return "unpublished_changes";
    } else {
      return "empty";
    }
  }, [fohScheduleForAWeekRange, duplicatedScheduleForAWeekRange, schedules]);

  useEffect(() => {
    const selectedDate = moment(date);
    if (business) {
      const startOfWeekNumber = moment()
        .day(business?.payrollStartOfTheWeek)
        .get("day");
      selectedDate.set("day", startOfWeekNumber);
      const selectedDays = generateWeekRangeForSelectedDate(date);
      setWeekRange(selectedDays);
      const scheduleForWeekRange = getScheduleForWeekRange(
        selectedDays,
        schedulesWithLocalSchedule,
        business?.timezone,
        selectedDepartment
      );

      const duplicatedScheduleForAWeekRange = getFixedScheduleForAWeekRange(
        selectedDays,
        fixedSchedules,
        business?.timezone
      );
      const schedule = scheduleForWeekRange || duplicatedScheduleForAWeekRange;
      const scheduleType = scheduleForWeekRange
        ? "draft"
        : duplicatedScheduleForAWeekRange
        ? "duplicate"
        : null;
      const currentReduxSchedule: CurrentReduxSchedule = {
        scheduleId: schedule ? schedule.id : null,
        startDate: selectedDays[0],
        endDate: selectedDays[selectedDays.length - 1],
        scheduleType,
        bohScheduleId: bohScheduleForAWeekRange?.id ?? null,
        fohScheduleId: fohScheduleForAWeekRange?.id ?? null,
        departmentId: selectedDepartment,
        weekRange,
      };
      if (scheduleType === "duplicate" && duplicatedScheduleForAWeekRange) {
        currentReduxSchedule.optionalPrecomposedDuplicateSchedule =
          duplicatedScheduleForAWeekRange;
      }
      dispatch(setCurrentSchedule(currentReduxSchedule));
    }
  }, [
    date,
    selectedDepartment,
    fohScheduleForAWeekRange,
    bohScheduleForAWeekRange,
    business,
  ]);

  const mergedScheduleForAWeekRange = useMemo(() => {
    if (bohScheduleForAWeekRange && fohScheduleForAWeekRange) {
      const bohShifts = bohScheduleForAWeekRange.shifts.map((sh) => ({
        ...sh,
        draftScheduleId: bohScheduleForAWeekRange.id,
      }));
      const fohShifts = fohScheduleForAWeekRange.shifts.map((sh) => ({
        ...sh,
        draftScheduleId: fohScheduleForAWeekRange.id,
      }));
      const mergedShifts = [...bohShifts, ...fohShifts];
      const mergedSchedule: TangoSchedule = {
        ...fohScheduleForAWeekRange,
        shifts: mergedShifts,
        mergedSchedules: [
          fohScheduleForAWeekRange.id,
          bohScheduleForAWeekRange.id,
        ],
      };
      return mergedSchedule;
    }
    return undefined;
  }, [bohScheduleForAWeekRange, fohScheduleForAWeekRange]);

  const invalidShifts = useMemo(() => {
    return scheduleForWeekRange?.shifts.filter(
      (sh) =>
        Math.abs(
          moment(sh.startDate.toDate()).diff(
            moment(sh.endDate.toDate()),
            "hours"
          )
        ) > 24
    );
  }, [scheduleForWeekRange]);
  console.log("invalidShifts", invalidShifts);

  const filteredStaffMembersAvailability = useMemo(() => {
    return fellowStaffMembers
      .filter((sm) =>
        availabilityStaffNameFilter
          ? `${sm.contact?.firstName ?? ""} ${
              sm.contact?.lastName ?? ""
            }`.includes(availabilityStaffNameFilter)
          : true
      )
      .filter((sm) =>
        availabilityRoleFilterId
          ? [sm.primaryRole, ...(sm.secondaryRoles || [])].includes(
              availabilityRoleFilterId
            )
          : true
      );
  }, [
    fellowStaffMembers,
    availabilityRoleFilterId,
    availabilityStaffNameFilter,
    business,
  ]);

  const getScheduleStatus = useCallback((): ScheduleStatus => {
    if (scheduleForWeekRange) {
      if (scheduleForWeekRange?.associatedScheduleId) {
        const publishedScheduleForAWeekRange = schedules.find(
          (publishedSchedule) =>
            publishedSchedule.id === scheduleForWeekRange.associatedScheduleId
        );
        if (publishedScheduleForAWeekRange) {
          const publishedShifts = publishedScheduleForAWeekRange.shifts;
          const draftShifts = scheduleForWeekRange.shifts;
          const differenceBetweenShifts = _.differenceWith(
            publishedShifts,
            draftShifts,
            _.isEqual
          );
          if (publishedShifts?.length !== draftShifts?.length) {
            return "unpublished_changes";
          }
          if (differenceBetweenShifts.length) {
            return "unpublished_changes";
          }
          return "published";
        } else {
          return "unpublished_changes";
        }
      } else {
        return "unpublished_changes";
      }
    } else if (duplicatedScheduleForAWeekRange) {
      return "unpublished_changes";
    } else {
      return "empty";
    }
  }, [scheduleForWeekRange, schedules, duplicatedScheduleForAWeekRange]);

  const scheduleStatus = getScheduleStatus();

  const jobFunctions: TangoJobFunctions = businessSettings?.jobFunctions || {};

  const onCalendarDateClick = useCallback((d: Date) => {
    setDate(d);
  }, []);

  const onEditShiftsSave = useCallback(
    async (shifts: TangoShift[]) => {
      const schedule = getScheduleForWeekRange(
        weekRange,
        schedules,
        business?.timezone,
        selectedDepartment
      );
      if (schedule) {
        await editScheduleShifts(schedule, shifts);
      }
    },
    [weekRange, schedules, business?.timezone, selectedDepartment]
  );

  const onEditShiftsDelete = useCallback(
    async (shifts: TangoShift[]) => {
      const schedule = getScheduleForWeekRange(
        weekRange,
        schedules,
        business?.timezone,
        selectedDepartment
      );
      if (schedule) {
        await deleteScheduleShifts(schedule, shifts);
      }
    },
    [weekRange, schedules, business?.timezone, selectedDepartment]
  );

  return {
    user,
    schedules,
    fellowStaffMembers,
    business,
    businessSettings,
    scheduleEvents,
    fixedSchedules,
    onEditShiftsDelete,
    onEditShiftsSave,
    onCalendarDateClick,
    jobFunctions,
    scheduleStatus,
    filteredStaffMembersAvailability,
    duplicatedScheduleForAWeekRange,
    scheduleForWeekRange,
    schedulesWithLocalSchedule,
    selectedDepartment,
    generateAvailabilitiesForADay,
    weekRange,
    selectedRequestId,
    setSelectedRequestId,
    selectedRequest,
    setSelectedRequest,
    selectedTradeOrCoverRequestId,
    setSelectedTradeOrCoverRequestId,
    selectedTradeOrCoverRequest,
    setSelectedTradeOrCoverRequest,
    weeklyScheduleViewType,
    setWeeklyScheduleViewType,
    departmentScheduleViewType,
    setDepartmentScheduleViewType,
    setShowSingleDay,
    weeklyStaffMemberViewFilterAnchorEl,
    setWeeklyStaffMemberViewFilterAnchorEl,
    availabilityViewFilterAnchorEl,
    setAvailabilityViewFilterAnchorEl,
    roleFilterId,
    setRoleFilterId,
    availabilityRoleFilterId,
    setAvailabilityRoleFilterId,
    showActionModal,
    setShowActionModal,
    actionModalTitle,
    showEditShiftModal,
    setShowEditShiftModal,
    shiftsToEdit,
    showAssignedPersonModal,
    setShowAssignedPersonModal,
    showTempModal,
    setShowTempModal,
    showAssignStaffModalContent,
    setShowAssignStaffModalContent,
    publishScheduleLoading,
    changeWeekAlertOpen,
    setChangeWeekAlertOpen,
    selectedShiftForEdit,
    setSelectedShiftForEdit,
    onFullNameInputChangeDebounced,
    onAvailabilityFullNameInputChangeDebounced,

    staffMemberNameFilter,
    draftsWithDepartmentForAWeek,
    bohDraftStatus,
    fohDraftStatus,
    mergedScheduleForAWeekRange,
    fohScheduleForAWeekRange,
    bohScheduleForAWeekRange,
    currentDateForDailyShiftView,
    setCurrentDateForDailyShiftView,
    generateWeekRangeForSelectedDate,
  };
};
