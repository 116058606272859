import UIComponents from "@tangopay/tango-ui-library";
import React from "react";
import { useNavigate } from "react-router-dom";

import Header from "components/Header";

import HorizontalTable from "../../../components/Table/HorizontalTable";
import Filters from "../CurrentInventory/Filters";
import Sidebar from "../Sidebar";
import { headerRoutes } from "../routes";
import useAllergens from "./useCountDetail";

const { Icon, Button } = UIComponents;
const CountDetail = () => {
  const navigate = useNavigate();
  const {
    allergensData,
    noOp,
    logIt,
    columns,
    instructions,
    isEditing,
    setEditing,
  } = useAllergens();

  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header
        routes={headerRoutes}
        selectedRoute={"/inventory/items/basic"}
      />
      <div className="flex h-full overflow-hidden">
        <div
          className={"border-r border-grey-1 border-t-0 border-b-0 overflow-y-auto"}
          style={{ width: "10%", borderRightStyle: "solid" }}
        >
          <Sidebar />
        </div>

        <div style={{ width: "90%" }} className={"overflow-y-auto"}>
          <div
            className={
              "flex p-8 justify-between items-center bg-white border-b border-grey-1"
            }
            style={{ borderBottomStyle: "solid" }}
          >
            <div className={"flex items-center"}>
              <Icon
                name={"arrow-left"}
                size={"20"}
                className={"cursor-pointer"}
                onClick={() => navigate(-1)}
              />
              <div className={"text-big text-black font-lato-black ml-10"}>
                Back Storage
              </div>
            </div>
            <div className={"flex items-center"}>
              <Button
                label={"Duplicate Count"}
                type={"btn-style-2"}
                size={"btn-large"}
              />
            </div>
          </div>
          <div className={"flex p-8 justify-between"}>
            <div className={"w-9/12"}>
              <HorizontalTable
                title="Current Inventory"
                columns={columns}
                data={allergensData}
                instructions={instructions}
                saveResults={noOp}
                setEditing={setEditing}
                isEditing={isEditing}
                primaryActionLabel="New Count"
                deleteIds={logIt}
              />
            </div>
            <div className={"w-2/12"}>
              <Filters />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountDetail;
