import _ from "lodash";
import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { percentage, roundNumberToTwoDecimals } from "controllers/reporting";
import {
  updateDailyProjectedSales,
  updateTargetLaborCost,
} from "controllers/schedule";

import { staffingAppDataSelector } from "model/selectors/staffing";

import { useSchedulingStatsData } from "./useData";

export type InputData = {
  fohTargetLc: number;
  bohTargetLc: number;
  projectedSales: number;
  day: string;
};

export type TargetLaborCostForADayType = {
  businessId: string;
  draftScheduleId: string;
  dayName: string;
  targetLaborCostPercentage: number;
};
export type ProjectedSalesForADayType = {
  businessId: string;

  fohDraftScheduleId: string;

  dayName: string;

  projectedAmount: number;
};

export const useSchedulingStatsWidget = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showFohRoles, setShowFohRoles] = useState(false);
  const [showBohRoles, setShowBohRoles] = useState(false);
  const [hideActuals, setHideActuals] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputData, setInputData] = useState<InputData[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState<"%" | "$">("%");

  const currentSchedule = useSelector(staffingAppDataSelector);

  const stats = useSchedulingStatsData({
    weekRange: currentSchedule?.currentSchedule?.weekRange ?? [],
  });

  useEffect(() => {
    if (
      stats.fohTargetLaborCostAsPercentage &&
      stats.bohTargetLaborCostAsPercentage &&
      stats.dailyProjectedSales
    ) {
      const weekdays = moment.weekdays();
      const firstDay = weekdays.shift();
      if (firstDay) weekdays.push(firstDay);

      const inputDataForWeek = weekdays.map((day) => {
        return {
          fohTargetLc:
            stats.fohTargetLaborCostAsPercentage?.[day]?.percentage ?? 0,
          bohTargetLc:
            stats.bohTargetLaborCostAsPercentage?.[day]?.percentage ?? 0,
          projectedSales: stats.dailyProjectedSales?.[day].projectedAmount ?? 0,
          day,
        };
      });
      setInputData(inputDataForWeek);
    }
  }, [
    stats.fohTargetLaborCostAsPercentage,
    stats.bohTargetLaborCostAsPercentage,
    stats.dailyProjectedSales,
  ]);

  //@ts-ignore
  const widgetFohBohData: {
    fohData: {
      title: string;
      value: number | null;
      status?: {
        type: "danger" | "success";
        number: number | null;
      };
      tbd?: boolean;
    }[];
    bohData: {
      title: string;
      value: number | null;
      status?: {
        type: "danger" | "success";
        number: number | null;
      };
      tbd?: boolean;
    }[];
  }[] = useMemo(() => {
    if (
      stats.groupedDailyFOHProjections &&
      stats.groupedDailyBOHProjections &&
      stats.fohTargetLaborCostAsPercentage &&
      stats.dailyActuals &&
      stats.bohTargetLaborCostAsPercentage &&
      stats.fohTargetLaborCostAsDollars
    ) {
      const {
        fohTargetLaborCostAsPercentage,
        groupedDailyFOHProjections,
        bohTargetLaborCostAsPercentage,
        groupedDailyBOHProjections,
        dailyActualsAsPercentagesOfActualSales,
      } = stats;
      return _.sortBy(
        _.values(groupedDailyFOHProjections),
        (v) => v.dayIndex,
        "asc"
      )
        .map((v) => v.dayNameString)
        .map((day: string) => {
          const fohData = [];
          const bohData = [];

          fohData.push({
            title: "Target FOH LC%",
            value: fohTargetLaborCostAsPercentage
              ? fohTargetLaborCostAsPercentage[day].percentage
              : null,
          });

          const fohTargetLaborCostAsCents =
            stats.fohTargetLaborCostAsDollars?.[day]?.amount ?? null;
          const fohProjectedAmountAsCents =
            groupedDailyFOHProjections[day].amount ?? 0;
          const projectedFohStatusType = fohTargetLaborCostAsCents
            ? fohTargetLaborCostAsCents >= fohProjectedAmountAsCents
              ? "success"
              : "danger"
            : "danger";
          const projectedFohStatusValue = fohTargetLaborCostAsCents
            ? roundNumberToTwoDecimals(
                percentage(
                  Math.abs(
                    fohTargetLaborCostAsCents - fohProjectedAmountAsCents
                  ),
                  fohTargetLaborCostAsCents
                )
              )
            : null;

          fohData.push({
            title: "Projected FOH LC%",
            status: {
              type: projectedFohStatusType,
              number: projectedFohStatusValue,
            },
            value: groupedDailyFOHProjections[day].percentage,
          });

          const fohActualLCAsSalesPercentage =
            dailyActualsAsPercentagesOfActualSales
              ? dailyActualsAsPercentagesOfActualSales?.find(
                  (d) => d.day === day
                )?.foh ?? 0
              : 0;
          const fohActualLaborCostAsCents = stats.dailyActuals
            ? stats.dailyActuals?.find((d) => d.day === day)?.foh ?? null
            : null;

          const actualFohStatusType =
            fohTargetLaborCostAsCents && fohActualLaborCostAsCents
              ? fohTargetLaborCostAsCents >= fohActualLaborCostAsCents
                ? "success"
                : "danger"
              : "danger";
          const actualFohStatusValue =
            fohTargetLaborCostAsCents && fohActualLaborCostAsCents
              ? roundNumberToTwoDecimals(
                  percentage(
                    Math.abs(
                      fohTargetLaborCostAsCents - fohActualLaborCostAsCents
                    ),
                    fohTargetLaborCostAsCents
                  )
                )
              : null;

          fohData.push({
            title: "Actual FOH LC%",
            tbd: !fohActualLCAsSalesPercentage,
            status: {
              type: actualFohStatusType,
              number: actualFohStatusValue,
            },
            value: fohActualLCAsSalesPercentage,
          });

          bohData.push({
            title: "Target BOH LC%",
            value: bohTargetLaborCostAsPercentage
              ? bohTargetLaborCostAsPercentage[day].percentage
              : null,
          });

          const bohTargetLaborCostAsCents =
            stats.bohTargetLaborCostAsDollars?.[day]?.amount ?? null;
          const bohProjectedAmountAsCents =
            groupedDailyBOHProjections[day].amount ?? 0;
          const projectedBohStatusType = bohTargetLaborCostAsCents
            ? bohTargetLaborCostAsCents >= bohProjectedAmountAsCents
              ? "success"
              : "danger"
            : "danger";
          const projectedBohStatusValue = bohTargetLaborCostAsCents
            ? roundNumberToTwoDecimals(
                percentage(
                  Math.abs(
                    bohTargetLaborCostAsCents - bohProjectedAmountAsCents
                  ),
                  bohTargetLaborCostAsCents
                )
              )
            : null;
          bohData.push({
            title: "Projected BOH LC%",
            status: {
              type: projectedBohStatusType,
              number: projectedBohStatusValue,
            },
            value: groupedDailyBOHProjections[day].percentage,
          });

          const bohActualLCAsSalesPercentage =
            dailyActualsAsPercentagesOfActualSales
              ? dailyActualsAsPercentagesOfActualSales?.find(
                  (d) => d.day === day
                )?.boh ?? 0
              : 0;
          const bohActualLaborCostAsCents = stats.dailyActuals
            ? stats.dailyActuals?.find((d) => d.day === day)?.boh ?? null
            : null;

          const actualBohStatusType =
            bohTargetLaborCostAsCents && bohActualLaborCostAsCents
              ? bohTargetLaborCostAsCents >= bohActualLaborCostAsCents
                ? "success"
                : "danger"
              : "danger";
          const actualBohStatusValue =
            bohTargetLaborCostAsCents && bohActualLaborCostAsCents
              ? roundNumberToTwoDecimals(
                  percentage(
                    Math.abs(
                      bohTargetLaborCostAsCents - bohActualLaborCostAsCents
                    ),
                    bohTargetLaborCostAsCents
                  )
                )
              : null;
          bohData.push({
            title: "Actual BOH LC%",
            tbd: !bohActualLCAsSalesPercentage,
            status: {
              type: actualBohStatusType,
              number: actualBohStatusValue,
            },
            value: bohActualLCAsSalesPercentage,
          });
          return { fohData, bohData };
        });
    }
    return [];
  }, [stats]);

  //@ts-ignore
  const widgetDollarFohBohData: {
    fohData: {
      title: string;
      value: number | null;
      status?: {
        type: "danger" | "success";
        number: number;
      };
      tbd?: boolean;
    }[];
    bohData: {
      title: string;
      value: number | null;
      status?: {
        type: "danger" | "success";
        number: number;
      };
      tbd?: boolean;
    }[];
  }[] = useMemo(() => {
    if (
      stats.groupedDailyFOHProjections &&
      stats.groupedDailyBOHProjections &&
      stats.fohTargetLaborCostAsDollars &&
      stats.dailyActuals &&
      stats.bohTargetLaborCostAsDollars
    ) {
      const {
        bohTargetLaborCostAsDollars,
        groupedDailyFOHProjections,
        dailyActuals,
        fohTargetLaborCostAsDollars,
        groupedDailyBOHProjections,
      } = stats;
      return _.sortBy(
        _.values(groupedDailyFOHProjections),
        (v) => v.dayIndex,
        "asc"
      )
        .map((v) => v.dayNameString)
        .map((day: string) => {
          const fohData = [];
          const bohData = [];

          const targetFohLcDollars = fohTargetLaborCostAsDollars
            ? fohTargetLaborCostAsDollars[day].amount
            : null;

          fohData.push({
            title: "Target FOH LC $",
            value: targetFohLcDollars ?? null,
          });

          const fohTargetLaborCostAsCents =
            stats.fohTargetLaborCostAsDollars?.[day]?.amount ?? null;
          const fohProjectedAmountAsCents =
            groupedDailyFOHProjections[day].amount ?? null;
          const projectedFohStatusType = fohTargetLaborCostAsCents
            ? fohTargetLaborCostAsCents >= fohProjectedAmountAsCents
              ? "success"
              : "danger"
            : "danger";
          const projectedFohStatusValue = fohTargetLaborCostAsCents
            ? roundNumberToTwoDecimals(
                percentage(
                  Math.abs(
                    fohTargetLaborCostAsCents - fohProjectedAmountAsCents
                  ),
                  fohTargetLaborCostAsCents
                )
              )
            : null;
          fohData.push({
            title: "Projected FOH LC $",
            status: {
              type: projectedFohStatusType,
              number: projectedFohStatusValue,
            },
            value: groupedDailyFOHProjections[day].amount,
          });

          const fohActualLaborCostAsCents = stats.dailyActuals
            ? stats.dailyActuals?.find((d) => d.day === day)?.foh ?? null
            : null;

          const actualFohStatusType =
            fohTargetLaborCostAsCents && fohActualLaborCostAsCents
              ? fohTargetLaborCostAsCents >= fohActualLaborCostAsCents
                ? "success"
                : "danger"
              : "danger";
          const actualFohStatusValue =
            fohTargetLaborCostAsCents && fohActualLaborCostAsCents
              ? roundNumberToTwoDecimals(
                  percentage(
                    Math.abs(
                      fohTargetLaborCostAsCents - fohActualLaborCostAsCents
                    ),
                    fohTargetLaborCostAsCents
                  )
                )
              : null;

          fohData.push({
            title: "Actual FOH LC $",
            tbd: !(
              (dailyActuals ?? []).find(
                (dailyActual) => dailyActual.day === day
              )?.foh ?? 0
            ),
            status: {
              type: actualFohStatusType,
              number: actualFohStatusValue,
            },
            value:
              (dailyActuals ?? []).find(
                (dailyActual) => dailyActual.day === day
              )?.foh ?? 0,
          });

          bohData.push({
            title: "Target BOH LC $",

            value: bohTargetLaborCostAsDollars
              ? bohTargetLaborCostAsDollars[day].amount
              : null,
          });

          const bohTargetLaborCostAsCents =
            stats.bohTargetLaborCostAsDollars?.[day]?.amount ?? null;
          const bohProjectedAmountAsCents =
            groupedDailyBOHProjections[day].amount ?? 0;
          const projectedBohStatusType = bohTargetLaborCostAsCents
            ? bohTargetLaborCostAsCents >= bohProjectedAmountAsCents
              ? "success"
              : "danger"
            : "danger";
          const projectedBohStatusValue = bohTargetLaborCostAsCents
            ? roundNumberToTwoDecimals(
                percentage(
                  Math.abs(
                    bohTargetLaborCostAsCents - bohProjectedAmountAsCents
                  ),
                  bohTargetLaborCostAsCents
                )
              )
            : null;
          bohData.push({
            title: "Projected BOH LC$",
            status: {
              type: projectedBohStatusType,
              number: projectedBohStatusValue,
            },
            value: groupedDailyBOHProjections[day].amount,
          });

          const bohActualLaborCostAsCents = stats.dailyActuals
            ? stats.dailyActuals?.find((d) => d.day === day)?.boh ?? null
            : null;

          const actualBohStatusType =
            bohTargetLaborCostAsCents && bohActualLaborCostAsCents
              ? bohTargetLaborCostAsCents >= bohActualLaborCostAsCents
                ? "success"
                : "danger"
              : "danger";
          const actualBohStatusValue =
            bohTargetLaborCostAsCents && bohActualLaborCostAsCents
              ? roundNumberToTwoDecimals(
                  percentage(
                    Math.abs(
                      bohTargetLaborCostAsCents - bohActualLaborCostAsCents
                    ),
                    bohTargetLaborCostAsCents
                  )
                )
              : null;

          bohData.push({
            title: "Actual BOH LC$",
            tbd: !(
              (dailyActuals ?? []).find(
                (dailyActual) => dailyActual.day === day
              )?.boh ?? 0
            ),
            status: {
              type: actualBohStatusType,
              number: actualBohStatusValue,
            },
            value:
              (dailyActuals ?? []).find(
                (dailyActual) => dailyActual.day === day
              )?.boh ?? 0,
          });
          return { fohData, bohData, day: day };
        });
    }
    return [];
  }, [stats]);

  /**
   * sum of all projected sales
   */
  const totalProjectSales = useMemo(() => {
    if (!stats) return 0;
    const { dailyProjectedSales } = stats;
    if (!dailyProjectedSales) return 0;
    return Object.keys(dailyProjectedSales).reduce((acc, day) => {
      return acc + dailyProjectedSales[day].projectedAmount;
    }, 0);
  }, [stats]);

  /**
   * sum of all foh target LC percentage
   */
  const totalTargetFohLCPerc = useMemo(() => {
    if (!stats) return 0;
    const { fohTargetLaborCostAsPercentage } = stats;
    if (!fohTargetLaborCostAsPercentage) return 0;
    return Object.keys(fohTargetLaborCostAsPercentage).reduce((acc, day) => {
      const perc = fohTargetLaborCostAsPercentage[day].percentage;
      if (perc) {
        return acc + perc;
      }
      return acc;
    }, 0);
  }, [stats]);

  /**
   * sum of all foh project LC percentage
   */
  const totalProjectedFohLCPerc = useMemo(() => {
    if (!stats) return 0;
    const { groupedDailyFOHProjections } = stats;
    if (!groupedDailyFOHProjections) return 0;
    return Object.keys(groupedDailyFOHProjections).reduce((acc, day) => {
      return acc + (groupedDailyFOHProjections[day].percentage ?? 0);
    }, 0);
  }, [stats]);

  /**
   * sum of all foh daily actuals LC percentage
   */
  const totalFohActualsPerc = useMemo(() => {
    if (!stats) return 0;
    const { dailyActualsAsPercentagesOfActualSales } = stats;
    if (!dailyActualsAsPercentagesOfActualSales) return 0;
    return dailyActualsAsPercentagesOfActualSales.reduce((acc, dayData) => {
      if (dayData.foh) {
        return acc + dayData.foh;
      }
      return acc;
    }, 0);
  }, [stats]);

  /**
   * Mean of all foh actual LC cents
   */
  const meanFOHActualsCents = useMemo(() => {
    if (!stats) return 0;
    const { dailyActuals } = stats;
    if (!dailyActuals) return 0;
    return _.meanBy(
      _.values(dailyActuals).filter((v) => v.foh),
      (d) => d.foh
    );
  }, [stats]);

  /**
   * Mean of all boh actual LC cents
   */
  const meanBOHActualsCents = useMemo(() => {
    if (!stats) return 0;
    const { dailyActuals } = stats;
    if (!dailyActuals) return 0;
    return _.meanBy(
      _.values(dailyActuals).filter((v) => v.boh),
      (d) => d.foh
    );
  }, [stats]);

  /**
   * Mean of all foh project LC percentage
   */
  const meanProjectedFohLCPerc = useMemo(() => {
    if (!stats) return null;
    const { groupedDailyFOHProjections, dailyProjectedSales } = stats;
    if (!groupedDailyFOHProjections || !dailyProjectedSales) return null;
    const sumOfDailyProjectionsDollars = _.sumBy(
      _.values(groupedDailyFOHProjections),
      (d) => d.amount
    );

    const sumOfDailyProjectedSalesDollars = _.sumBy(
      _.values(dailyProjectedSales),
      (d) => d.projectedAmount
    );

    if (!sumOfDailyProjectionsDollars || !sumOfDailyProjectedSalesDollars)
      return null;

    const targetProjectedFohLCPerc = roundNumberToTwoDecimals(
      (sumOfDailyProjectionsDollars / sumOfDailyProjectedSalesDollars) * 100
    );
    return targetProjectedFohLCPerc ?? null;
  }, [stats]);

  /**
   * Mean of all foh projected LC cents
   */
  const meanProjectedFohLCCents = useMemo(() => {
    if (!stats) return 0;
    const { groupedDailyFOHProjections } = stats;
    if (!groupedDailyFOHProjections) return 0;
    return _.meanBy(
      _.values(groupedDailyFOHProjections).filter((v) => v.amount),
      (d) => d.amount
    );
  }, [stats]);

  /**
   * Mean of all boh projected LC cents
   */
  const meanProjectedBohLCCents = useMemo(() => {
    if (!stats) return 0;
    const { groupedDailyBOHProjections } = stats;
    if (!groupedDailyBOHProjections) return 0;
    return _.meanBy(
      _.values(groupedDailyBOHProjections).filter((v) => v.amount),
      (d) => d.amount
    );
  }, [stats]);

  /**
   * Mean of all boh project LC percentage
   */
  const meanProjectedBohLCPerc = useMemo(() => {
    if (!stats) return null;
    const { groupedDailyBOHProjections, dailyProjectedSales } = stats;
    if (!groupedDailyBOHProjections || !dailyProjectedSales) return null;
    const sumOfDailyProjectionsDollars = _.sumBy(
      _.values(groupedDailyBOHProjections),
      (d) => d.amount
    );

    const sumOfDailyProjectedSalesDollars = _.sumBy(
      _.values(dailyProjectedSales),
      (d) => d.projectedAmount
    );

    if (!sumOfDailyProjectionsDollars || !sumOfDailyProjectedSalesDollars)
      return null;

    const targetProjectedBohLCPerc = roundNumberToTwoDecimals(
      (sumOfDailyProjectionsDollars / sumOfDailyProjectedSalesDollars) * 100
    );
    return targetProjectedBohLCPerc ?? null;
  }, [stats]);

  /**
   * Mean of all foh daily actuals LC percentage
   */
  const meanFohActualsPerc = useMemo(() => {
    if (!stats) return 0;
    const { dailyActuals, dailyActualSales } = stats;
    if (!stats) return 0;
    if (!dailyActuals || !dailyActualSales) return 0;
    const sumOfDailyActualsDollars = _.sumBy(dailyActuals, (d) => d.foh);

    const sumOfDailyProjectedSalesDollars = _.sumBy(
      _.values(dailyActualSales),
      (d) => d.amount
    );

    const targetActualFohLCPerc = roundNumberToTwoDecimals(
      (sumOfDailyActualsDollars / sumOfDailyProjectedSalesDollars) * 100
    );
    if (!sumOfDailyProjectedSalesDollars || !sumOfDailyActualsDollars)
      return null;
    return targetActualFohLCPerc ?? null;
  }, [stats]);

  /**
   * Mean of all foh daily actuals LC percentage
   */
  const meanBohActualsPerc = useMemo(() => {
    if (!stats) return 0;
    const { dailyActuals, dailyActualSales } = stats;
    if (!dailyActuals || !dailyActualSales) return 0;
    const sumOfDailyActualsDollars = _.sumBy(dailyActuals, (d) => d.boh);

    const sumOfDailyActualSalesDollars = _.sumBy(
      _.values(dailyActualSales),
      (d) => d.amount
    );

    const targetActualFohLCPerc = roundNumberToTwoDecimals(
      (sumOfDailyActualsDollars / sumOfDailyActualSalesDollars) * 100
    );
    if (!sumOfDailyActualSalesDollars || !sumOfDailyActualsDollars) return null;
    return targetActualFohLCPerc ?? null;
  }, [stats]);

  /**
   * sum of all boh target LC percentage
   */
  const totalTargetBohLCPerc = useMemo(() => {
    if (!stats) return 0;
    const { bohTargetLaborCostAsPercentage } = stats;
    if (!bohTargetLaborCostAsPercentage) return 0;
    return Object.keys(bohTargetLaborCostAsPercentage).reduce((acc, day) => {
      const perc = bohTargetLaborCostAsPercentage[day].percentage;
      if (perc) {
        return acc + perc;
      }
      return acc;
    }, 0);
  }, [stats]);

  /**
   * sum of all boh project LC percentage
   */
  const totalProjectedBohLCPerc = useMemo(() => {
    if (!stats) return 0;
    const { groupedDailyBOHProjections } = stats;
    if (!groupedDailyBOHProjections) return 0;
    return Object.keys(groupedDailyBOHProjections).reduce((acc, day) => {
      return acc + (groupedDailyBOHProjections[day].percentage ?? 0);
    }, 0);
  }, [stats]);

  /**
   * sum of all boh daily actuals LC percentage
   */
  const totalBohActualsPerc = useMemo(() => {
    if (!stats) return 0;
    const { dailyActualsAsPercentagesOfActualSales } = stats;
    if (!dailyActualsAsPercentagesOfActualSales) return 0;
    return dailyActualsAsPercentagesOfActualSales.reduce((acc, dayData) => {
      if (dayData.boh) {
        return acc + dayData.boh;
      }
      return acc;
    }, 0);
  }, [stats]);

  const updateInputData = useCallback(
    (index: number, type: keyof Omit<InputData, "day">, value: number) => {
      const clonedData = [...inputData];
      clonedData[index][type] = value;
      setInputData(clonedData);
    },
    [stats, inputData]
  );

  const updateInputDataByDayString = useCallback(
    (day: string, type: keyof Omit<InputData, "day">, value: number) => {
      let clonedData = [...inputData];
      const dayData = clonedData.find((data) => data.day === day);
      if (dayData) {
        clonedData = clonedData.map((data) => {
          if (data.day === day) {
            return {
              ...data,
              [type]: value,
            };
          }
          return data;
        });
        setInputData(clonedData);
      }
    },
    [stats, inputData]
  );

  const updateWidgetData = async (
    businessId: string,
    fohScheduleId: string,
    bohScheduleId: string
  ) => {
    setLoading(true);
    const fohLaborCostData = inputData.map((data) => ({
      draftScheduleId: fohScheduleId,
      businessId,
      dayName: data.day,
      targetLaborCostPercentage: data.fohTargetLc,
    }));

    const bohLaborCostData = inputData.map((data) => ({
      draftScheduleId: bohScheduleId,
      businessId,
      dayName: data.day,
      targetLaborCostPercentage: data.bohTargetLc,
    }));
    const dailyProjectedSalesData = inputData.map((data) => ({
      fohDraftScheduleId: fohScheduleId,
      businessId,
      dayName: data.day,
      projectedAmount: data.projectedSales,
    }));
    const laborCostAPIPromises = [...fohLaborCostData, ...bohLaborCostData].map(
      (laborCostData) => {
        return updateTargetLaborCost(laborCostData);
      }
    );
    const dailyProjectedSalesAPIPromises = dailyProjectedSalesData.map(
      (data) => {
        return updateDailyProjectedSales(data);
      }
    );
    try {
      await Promise.all([
        ...laborCostAPIPromises,
        ...dailyProjectedSalesAPIPromises,
      ]);
      setIsEditing(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const totalTargetFOHCentData = useMemo(() => {
    return (
      _.sumBy(_.values(stats.fohTargetLaborCostAsDollars), "amount") ?? null
    );
  }, [stats.fohTargetLaborCostAsDollars]);

  const totalTargetBOHCentData = useMemo(() => {
    return (
      _.sumBy(_.values(stats.bohTargetLaborCostAsDollars), "amount") ?? null
    );
  }, [stats.bohTargetLaborCostAsDollars]);

  const totalProjectionFOHCentData = useMemo(() => {
    return (
      _.sumBy(_.values(stats.groupedDailyFOHProjections), "amount") ?? null
    );
  }, [stats.groupedDailyFOHProjections]);

  const totalProjectionFOHStatus = useMemo(() => {
    const type = totalTargetFOHCentData
      ? totalTargetFOHCentData >= totalProjectionFOHCentData
        ? "success"
        : "danger"
      : "danger";

    const value =
      totalTargetFOHCentData && totalProjectionFOHCentData
        ? roundNumberToTwoDecimals(
            percentage(
              Math.abs(totalTargetFOHCentData - totalProjectionFOHCentData),
              totalTargetFOHCentData
            )
          )
        : null;
    return {
      type,
      number: value,
    } as { type: "success" | "danger"; number: number | null };
  }, [totalTargetFOHCentData, totalProjectionFOHCentData]);

  const totalProjectionBOHCentData = useMemo(() => {
    return (
      _.sumBy(_.values(stats.groupedDailyBOHProjections), "amount") ?? null
    );
  }, [stats.groupedDailyBOHProjections]);

  const totalProjectionBOHStatus = useMemo(() => {
    const type = totalTargetBOHCentData
      ? totalTargetBOHCentData >= totalProjectionBOHCentData
        ? "success"
        : "danger"
      : "danger";

    const value =
      totalTargetBOHCentData && totalProjectionBOHCentData
        ? roundNumberToTwoDecimals(
            percentage(
              Math.abs(totalTargetBOHCentData - totalProjectionBOHCentData),
              totalTargetBOHCentData
            )
          )
        : null;
    return {
      type,
      number: value,
    } as { type: "success" | "danger"; number: number | null };
  }, [totalTargetBOHCentData, totalProjectionBOHCentData]);

  const totalFOHActualsCentData = useMemo(() => {
    return _.sumBy(stats.dailyActuals, "foh");
  }, [stats.dailyActuals]);

  const totalActualFOHStatus = useMemo(() => {
    const type = totalTargetFOHCentData
      ? totalTargetFOHCentData >= totalFOHActualsCentData
        ? "success"
        : "danger"
      : "danger";

    const value =
      totalTargetFOHCentData && totalFOHActualsCentData
        ? roundNumberToTwoDecimals(
            percentage(
              Math.abs(totalTargetFOHCentData - totalFOHActualsCentData),
              totalTargetFOHCentData
            )
          )
        : null;
    return {
      type,
      number: value,
    } as { type: "success" | "danger"; number: number | null };
  }, [totalTargetBOHCentData, totalFOHActualsCentData]);

  const totalBOHActualsCentData = useMemo(() => {
    return _.sumBy(stats.dailyActuals, "boh");
  }, [stats.dailyActuals]);

  const totalActualBOHStatus = useMemo(() => {
    const type = totalTargetBOHCentData
      ? totalTargetBOHCentData >= totalBOHActualsCentData
        ? "success"
        : "danger"
      : "danger";

    const value =
      totalTargetBOHCentData && totalBOHActualsCentData
        ? roundNumberToTwoDecimals(
            percentage(
              Math.abs(totalTargetBOHCentData - totalBOHActualsCentData),
              totalTargetBOHCentData
            )
          )
        : null;
    return {
      type,
      number: value,
    } as { type: "success" | "danger"; number: number | null };
  }, [totalTargetBOHCentData, totalBOHActualsCentData]);

  return {
    stats,
    showBohRoles,
    showFohRoles,
    totalProjectSales,
    totalProjectedFohLCPerc,
    totalTargetFohLCPerc,
    totalFohActualsPerc,
    totalProjectedBohLCPerc,
    totalTargetBohLCPerc,
    totalBohActualsPerc,
    hideActuals,
    setHideActuals,
    widgetFohBohData,
    isExpanded,
    setIsExpanded,
    setShowBohRoles,
    setShowFohRoles,
    setSelectedDataType,
    selectedDataType,
    isEditing,
    setIsEditing,
    inputData,
    updateInputData,
    updateWidgetData,
    loading,
    updateInputDataByDayString,
    widgetDollarFohBohData,
    meanFohActualsPerc,
    meanBohActualsPerc,
    meanProjectedBohLCPerc,
    meanProjectedFohLCPerc,
    meanProjectedBohLCCents,
    meanProjectedFohLCCents,
    meanFOHActualsCents,
    meanBOHActualsCents,

    totalBOHActualsCentData,
    totalFOHActualsCentData,
    totalProjectionBOHCentData,
    totalProjectionFOHCentData,
    totalTargetBOHCentData,
    totalTargetFOHCentData,

    totalProjectionFOHStatus,
    totalProjectionBOHStatus,
    totalActualBOHStatus,
    totalActualFOHStatus,
  };
};
