import UIComponents from "@tangopay/tango-ui-library";
import { createRefund, RefundPaymentDTO, TangoTab } from "controllers/payments";
import React, { useEffect, useState } from "react";
import { ClosedChecksProps } from "../ClosedChecks/useClosedChecks";
import { FormattedPayment } from "../Payments";
const { Modal, Dropdown, Button, TextField } = UIComponents;

interface Props {
    selectedPaymentToRefund: FormattedPayment,
    setRefundSuccess: (success: boolean) => void;
    setUpdateCheckWithRefund: (updatedCheck: ClosedChecksProps) => void;
    closedCheckWithStaff: ClosedChecksProps | null;
    isOpen: boolean;
    closeModal: () => void;
}

const RefundPaymentModal = ({ selectedPaymentToRefund, setRefundSuccess, setUpdateCheckWithRefund, isOpen, closeModal, closedCheckWithStaff }: Props) => {

    const [amount, setAmount] = useState(0)
    const [reason, setReason] = useState('requested_by_customer')//default value to pass to stripe
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (selectedPaymentToRefund && selectedPaymentToRefund.amount) {
            setAmount(selectedPaymentToRefund.amount)
        }
    }, [selectedPaymentToRefund])
    const handleRequestRefund = async () => {
        setLoading(true)

        const businessId = selectedPaymentToRefund.businessId
        const country = selectedPaymentToRefund.paymentDetail.currency === 'cad' ? "CA" : 'USA'
        const tabId = selectedPaymentToRefund.customerDetail.tabId

        const refundData: RefundPaymentDTO = {
            customerIndex: selectedPaymentToRefund.customerDetail.customerIndexInTab,
            amount: amount,//MATH trunc because parse float sometimes returns weird #
            paymentIntentId: selectedPaymentToRefund.paymentDetail.paymentIntentId,
            reverseTransfer: true, //always true to reverse the stripe connect platform fee
            reason: reason,
            cashRefund: selectedPaymentToRefund.type === 'cash' ? true : false
        }

        if (isNaN(refundData.amount)) {
            setLoading(false)
            return console.log('NaN')
        }
        if (refundData.amount > selectedPaymentToRefund.amount) {
            setLoading(false)
            return alert(`Please enter a refund amount less than or equal to the charge amount of $${selectedPaymentToRefund.amount * 0.01}.`)
        }

        const refundSuccess: TangoTab = await createRefund(businessId, country, tabId, refundData)
        if (refundSuccess && closedCheckWithStaff) {
            const checkWithRefund = closedCheckWithStaff
            checkWithRefund.completedCheck = refundSuccess
            setUpdateCheckWithRefund(checkWithRefund)
            setRefundSuccess(true)
            setLoading(false)
            closeModal()
        } else {
            setLoading(false)
        }
    }
    return (
        <Modal isOpen={isOpen} closeModal={closeModal} width={"720"}>
            <div className={"text-big text-black font-lato-black mb-10"}>
                Refund Payment
            </div>
            <div className="text-black font-lato-regular text-base mb-10">
                Refunds take 5-10 days to appear on a customer’s statement. Stripe’s fees for the original payment won’t be returned, but there are no additional fees for the refund.
            </div>
            <div className={"mb-10"}>
                <div className={"text-xl text-black font-lato-black mb-4"}>
                    Request From
                </div>
                <div className="relative">
                    <TextField
                        onChange={(e: any) => {
                            setAmount(Math.trunc(parseFloat(e.target.value) * 100))//truncate since parse float might return weird number
                        }}
                        sizeType="medium"
                        placeholder={`$` + (selectedPaymentToRefund.amount * 0.01).toFixed(2)}
                        className="w-full"
                    />
                    <div className="absolute text-sm font-lato-regular text-black right-3 top-3">{selectedPaymentToRefund.paymentDetail.currency.toUpperCase()}</div>
                </div>
            </div>
            <div className={"mb-10"}>
                <div className={"text-xl text-black font-lato-black mb-4"}>
                    Reason
                </div>
                <Dropdown
                    menuPosition="absolute"
                    options={[
                        {
                            label: "Requested by Customer",
                            value: 'requested_by_customer'
                        },
                        {
                            label: "Duplicate",
                            value: 'duplicate'
                        },
                        {
                            label: "Fraudulent (block future payments from this card) ",
                            value: 'fraudulent'
                        }
                    ]}
                    onChange={(option: any) => {
                        setReason(option.value)
                    }}
                    size="medium"
                />
                {/* <textarea
                    onChange={(e: any) => {
                        setReason(e.target.value)
                    }}
                    className="w-full mt-3 rounded-2xl border-grey-1 border-solid h-20 py-5 px-6 font-lato-regular text-black text-sm" placeholder="Add more details about this refund..." /> */}
            </div>
            <Button
                disabled={loading}
                onClick={() => {
                    handleRequestRefund()
                }}
                label={loading ? "Processing" : "Request"}
                type={"btn-style-2"}
                size={"btn-medium"}
                className={"w-full justify-center"}
            />
        </Modal>
    );
};
export default RefundPaymentModal;
