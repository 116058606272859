import React from "react";

import Header from "components/Header";

import Sidebar from "../Sidebar";
import { headerRoutes, inventorySelectedPage } from "../routes";
import RecipeItemsTable from "./RecipeItemsTable";
import RecipesContaining from "./RecipesContaining";

const Conversions = () => {
  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header routes={headerRoutes} selectedRoute={"/inventory/items/basic"} selectedPage={inventorySelectedPage} />
      <div className="flex h-full overflow-hidden">
        <div
          className={"border-r border-grey-1 border-t-0 border-b-0 overflow-y-auto"}
          style={{ width: "10%", borderRightStyle: "solid" }}
        >
          <Sidebar />
        </div>

        <div
          className={
            "flex px-10 py-6 bg-off-white overflow-y-auto justify-between"
          }
          style={{ width: "90%" }}
        >
          <div
            className={"bg-white border border-solid border-grey-1 rounded-2xl"}
            style={{
              borderBottomStyle: "none",
              height: "fit-content",
              width: "calc(50% - 16px)",
            }}
          >
            <RecipeItemsTable />
          </div>
          <div
            className={
              "w-6/12 bg-white border border-solid border-grey-1 rounded-2xl"
            }
            style={{
              borderBottomStyle: "none",
              height: "fit-content",
              width: "calc(50% - 16px)",
            }}
          >
            <RecipesContaining />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conversions;
