import _ from "lodash";
import moment from "moment-timezone";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  getShiftsForADay,
  groupShiftsByShiftTypeAndTimeFrame,
} from "controllers/schedule";
import { DepartmentId } from "controllers/staff";

import Box from "components/Box";

import { getPositionById } from "model/selectors/businessSettings";

import ShiftCard from "../ShiftCard";
import { SmallSlotCard } from "../SlotCard";
import { RootState } from "model/store";

export const groupShiftsByJobFunction = (
  shifts: TangoShift[],
  jobFunctions: TangoJobFunctions,
  fellowStaffMembers: StaffMember[],
  staffMemberNameFilter: string,
): { name: string; quantity: number; total: number, staffIds: ({ id: string | undefined, name: string | undefined, shift: TangoShift })[] }[] => {
  const groupedShifts = _.groupBy(
    shifts,
    (shift: TangoShift) => shift.position
  );
  return _.keys(groupedShifts).map((key) => {

    return ({
      name: jobFunctions[key]?.title,
      total: groupedShifts[key]?.length,
      quantity: groupedShifts[key].filter(({ staffId }) => staffId).length,
      staffIds: groupedShifts[key].map((shift) => {
        const selectedStaff = fellowStaffMembers.find((item) => item.id === shift.staffId)
        const name = selectedStaff ? selectedStaff.contact.firstName + ' ' + selectedStaff.contact.lastName : undefined;

        return { id: selectedStaff?.id, name, shift }

      }).filter((staffId) => {
        if (staffMemberNameFilter.length) {
          if (staffId.name) {
            return (staffId.name.toLowerCase()).search(staffMemberNameFilter.toLowerCase()) > -1
          }
        } else {
          return true
        }
      }),
    })
  }).filter((groupedShifts) => groupedShifts.staffIds.length);
};

interface WeeklyShiftsProps {
  dayOfWeek: Date;
  weekRange: Date[];
  schedulesWithLocalSchedule: TangoSchedule[];
  business: TangoBusiness;
  fixedSchedules: TangoFixedSchedule[];
  selectedDepartment: "boh" | "foh" | undefined;
  departmentScheduleViewType: DepartmentId | null;
  businessSettings: TangoBusinessSettings;
  setShowSingleDay: (v: boolean) => void;
  jobFunctions: TangoJobFunctions;
  // onShiftSelect?: (groupedShifts: GroupedShifts, dayOfWeek: Date) => void;
  onOptionSelect: (type: 'view' | 'move' | 'delete', groupedShifts: GroupedShifts) => void;
  openAssignShiftModal: (v: TangoShift | null) => void;
  roleFilterId: string | null;
  staffMemberNameFilter: string;
  fohScheduleForAWeekRange: TangoSchedule | undefined;
  bohScheduleForAWeekRange: TangoSchedule | undefined;
  selectedDate: string;
  selectedPosition: string;
  setSelectedDate: (date: string) => void;
  setSelectedPosition: (date: string) => void;
}

export const WeeklyShifts = ({
  dayOfWeek,
  weekRange,
  schedulesWithLocalSchedule,
  business,
  fixedSchedules,
  selectedDepartment,
  departmentScheduleViewType,
  businessSettings,
  setShowSingleDay,
  jobFunctions,
  onOptionSelect,
  openAssignShiftModal,
  roleFilterId,
  staffMemberNameFilter,
  fohScheduleForAWeekRange,
  bohScheduleForAWeekRange,
  selectedDate,
  selectedPosition,
  setSelectedDate,
  setSelectedPosition,
}: WeeklyShiftsProps) => {

  const navigate = useNavigate();
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );
  let shiftsForADay = getShiftsForADay(
    dayOfWeek,
    weekRange,
    schedulesWithLocalSchedule,
    business.timezone,
    fixedSchedules,
    selectedDepartment
  );

  if (departmentScheduleViewType) {
    shiftsForADay = shiftsForADay.filter((shift) => {
      const shiftPosition = shift.position;
      const positionData = getPositionById(businessSettings, shiftPosition);
      return positionData?.departmentId === departmentScheduleViewType;
    });
  }
  let shiftsByShiftType = groupShiftsByShiftTypeAndTimeFrame(shiftsForADay, roleFilterId);
  shiftsByShiftType = shiftsByShiftType.map((groupedShift) => {
    const shiftPositions = groupShiftsByJobFunction(
      groupedShift.shifts,
      jobFunctions,
      fellowStaffMembers,
      staffMemberNameFilter
    )
    return { ...groupedShift, shiftPositions }
  })

  if (shiftsByShiftType.length) {
    return (
      <>
        {shiftsByShiftType
          .sort(
            (a, b) =>
              moment(a.startTime, "HH:mm").unix() -
              moment(b.startTime, "HH:mm").unix()
          )
          .map((groupedShits, i) => {
            if (groupedShits.shifts.length && groupedShits.shiftPositions.length) {
              return (
                <ShiftCard
                  // key={`${groupedShits.shiftTypeId}_${groupedShits.startTime}_${groupedShits.endTime}`}
                  id={i}
                  shiftTypeId={groupedShits.shiftTypeId}
                  startTime={groupedShits.startTime}
                  endTime={groupedShits.endTime}
                  type="time-off"
                  shiftPositions={groupedShits.shiftPositions}
                  openAssignShiftModal={openAssignShiftModal}
                  onOptionSelect={(optionType) => {
                    onOptionSelect(optionType, groupedShits)
                  }}
                  currentDate={moment(groupedShits.shifts[0].startDate.toMillis()).format('MM-DD-YYYY')}
                  fohScheduleForAWeekRange={fohScheduleForAWeekRange}
                  bohScheduleForAWeekRange={bohScheduleForAWeekRange}
                  selectedDate={selectedDate}
                  selectedPosition={selectedPosition}
                  setSelectedDate={(date) => setSelectedDate(date)}
                  setSelectedPosition={(position) => setSelectedPosition(position)}
                />
              )
            }

          })}
      </>
    );
  }
  return null;
};
