import moment from "moment-timezone";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { generateWeekRangeForSelectedDate } from "views/manager/dailyLogs/dailyLogsFunctions";

import { deleteRequest } from "controllers/scheduleEvents";

import { ColumnInstruction } from "components/Table/HorizontalTable";

import { timeOffRequestsSelector } from "model/selectors/scheduleEvents";
import { RootState } from "model/store";

import { ItemProps, SettingsTableItemProp } from "./instructions";

const columns: ColumnInstruction<ItemProps>[] = [
  { type: "projection", header: "Status", attribute: "status" },
  {
    type: "data",
    header: "Dates Requested",
    attribute: "requestedDates",
  },
  { type: "data", header: "Employee", attribute: "employee" },
  { type: "data", header: "Reason", attribute: "reason" },
  { type: "data", header: "Date Submitted", attribute: "createdAt" },
];

export const settingsColumns: ColumnInstruction<SettingsTableItemProp>[] = [
  {
    type: "data",
    header: "Role",
    attribute: "role",
  },
  {
    type: "data",
    header: "Mon",
    attribute: "mon",
  },
  {
    type: "data",
    header: "Tues",
    attribute: "tues",
  },
  {
    type: "data",
    header: "Wed",
    attribute: "wed",
  },
  {
    type: "data",
    header: "Thurs",
    attribute: "thurs",
  },
  {
    type: "data",
    header: "Fri",
    attribute: "fri",
  },
  {
    type: "data",
    header: "Sat",
    attribute: "sat",
  },
  {
    type: "data",
    header: "Sun",
    attribute: "sun",
  },
];

export const settingsItemsData: SettingsTableItemProp[] = [
  {
    uniqueId: "1",
    role: "Server",
    mon: 1,
    tues: 2,
    wed: 1,
    thurs: 1,
    fri: 1,
    sat: 1,
    sun: 1,
  },
  {
    uniqueId: "2",
    role: "Host",
    mon: 1,
    tues: 2,
    wed: 1,
    thurs: 1,
    fri: 1,
    sat: 1,
    sun: 1,
  },
];
const timeOffRequestStatusTitle = (status: ScheduleEventStatus) => {
  switch (status) {
    case "approved":
      return "Approved";
    case "denied":
      return "Denied";
    case "pending":
      return "Pending";
    default:
      return "Pending";
  }
};
const useTradeRequests = () => {
  const [showDenyRequestModal, setShowDenyRequestModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [requestsStatusFilter, setRequestsStatusFilter] = useState<
    "pending" | "reviewed"
  >("pending");

  const [selectedDate, setSelectedDate] = useState(new Date());

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const weekRange = useMemo(() => {
    return generateWeekRangeForSelectedDate(business, selectedDate);
  }, [business, selectedDate]);

  const onCalendarDayPress = useCallback(
    (d: Date) => {
      setSelectedDate(d);
    },
    [setSelectedDate]
  );

  const deleteIds = useCallback((ids: string[]) => {
    return Promise.all(
      ids.map((requestId) => {
        return deleteRequest(requestId);
      })
    );
  }, []);

  const unFilteredTimeOffRequests = useSelector(timeOffRequestsSelector);
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const timeOffRequests = useMemo(() => {
    const now = moment().unix();

    if (requestsStatusFilter === "reviewed") {
      return unFilteredTimeOffRequests
        .filter((to) => to.status !== "pending")
        .filter((to) => to.timeOffRequestStartDate && to.timeOffRequestEndDate)
        .sort((a, b) => {
          const aDate = moment(a.timeOffRequestStartDate.toDate());
          const bDate = moment(b.timeOffRequestStartDate.toDate());
          return Math.abs(aDate.unix() - now) - Math.abs(bDate.unix() - now);
        });
    }
    return unFilteredTimeOffRequests
      .filter(
        (to) =>
          to.timeOffRequestStartDate &&
          to.timeOffRequestEndDate &&
          to.status === "pending"
      )
      .sort((a, b) => {
        const aDate = moment(a.timeOffRequestStartDate.toDate());
        const bDate = moment(b.timeOffRequestStartDate.toDate());
        return Math.abs(aDate.unix() - now) - Math.abs(bDate.unix() - now);
      });
  }, [unFilteredTimeOffRequests, requestsStatusFilter]);

  const transformRequestToTableFormat = useCallback(
    (to: ScheduleEvent): ItemProps | null => {
      const statusTitle = timeOffRequestStatusTitle(to.status);
      const senderStaff = fellowStaffMembers.find(
        (sm) => sm?.id === to.senderStaff?.id
      );
      if (!senderStaff) return null;
      const requestedDates = {
        startDate: to.timeOffRequestStartDate.toDate(),
        endDate: to.timeOffRequestEndDate.toDate(),
      };
      const employeeFullName = `${senderStaff.contact.firstName} ${senderStaff.contact.lastName}`;
      return {
        uniqueId: to.id,
        status: statusTitle,
        requestedDates,
        employee: employeeFullName,
        createdAt: to.createdAt.toDate(),
        reason: to.description ?? "",
      };
    },
    [fellowStaffMembers]
  );

  const itemsData: ItemProps[] = useMemo(() => {
    return timeOffRequests
      .map(transformRequestToTableFormat)
      .filter((x) => !!x) as ItemProps[];
  }, [timeOffRequests, transformRequestToTableFormat]);
  console.log("itemsData", itemsData);
  console.log("timeOffRequests", timeOffRequests);

  return {
    columns,
    itemsData,
    showDenyRequestModal,
    setShowDenyRequestModal,
    showSettingsModal,
    setShowSettingsModal,
    showDeleteModal,
    setShowDeleteModal,
    onCalendarDayPress,
    weekRange,
    deleteIds,
    requestsStatusFilter,
    setRequestsStatusFilter,
  };
};
export default useTradeRequests;
