import { Icon } from "@blueprintjs/core";
import { Grid } from "@mui/material";
import UIComponents from "@tangopay/tango-ui-library";
import "apexcharts";
import firebase from "firebase";
import useFlag from "hooks/useFlag";
import moment, { Moment } from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { useSelector } from "react-redux";

import {
  getSummaryForDate,
  setOverallTarget,
  setSalesProjection,
  setSplitTarget,
} from "controllers/dailyLogs";

import Spinner from "components/Spinner";

import Box from "../../../components/Box";
import { RootState } from "../../../model/store";
import ActualVsProjected from "./graphComponents/actualVsProjected";
import DailySalesGraph from "./graphComponents/salesElement";
import SalesVsLaborCost from "./graphComponents/salesVsLC";
import WTDSalesVsLaborCost from "./graphComponents/wtdSalesVsLC";
import NotesHeader from "./notesComponents/headerBar";
import NewNoteModal, { Note } from "./notesComponents/newNoteModal";
import NoteTile from "./notesComponents/noteTile";
import StatCard, { AugmentedProjection } from "./statsComponents/statsCard";
import "./style.css";

// Start of the day UTC time
// not the start of the business day, which is 4am local time
// day, month, and year come from local time 4 hours ago.
const toCanonicalDate = (date: Date, timezone?: string) => {
  const m = (timezone ? moment(date).tz(timezone) : moment(date)).subtract(4, "hours");
  return moment
    .utc({
      year: m.year(),
      month: m.month(),
      date: m.date(),
    })
    .startOf("day")
    .add(4, "hours")
    .toDate();
};

// Start of business, depends on local timezones
// roughly speaking, this is the most recent local time 4am before the given date
const toStartOfBusinessDay = (date: Date, timezone?: string) => {
  const m = timezone ? moment(date).tz(timezone) : moment(date)
  return m.subtract(4, "hours").startOf("day").add(4, "hours").toDate();
}

const { Button } = UIComponents;
type NavBarArgs = {
  onPreviousClick: () => unknown;
  onNextClick: () => unknown;
  className: string;
};
const NavBar = ({ onPreviousClick, onNextClick, className }: NavBarArgs) => {
  const onPrev = useCallback(() => onPreviousClick(), [onPreviousClick]);
  const onNext = useCallback(() => onNextClick(), [onNextClick]);
  return (
    <div className={className}>
      <div onClick={onPrev} className="calendar-nav-button">
        <Icon icon="chevron-left" iconSize={18} />
      </div>
      <div onClick={onNext} className="calendar-nav-button">
        <Icon icon="chevron-right" iconSize={18} />
      </div>
    </div>
  );
};

const SideBar = (props: {
  firstDayOfWeek: Date;
  selectedDate: Date;
  lastDayOfWeek: Date;
  setSelectedDate: (date: Date) => void;
  notesMode: boolean;
  toggleNotesMode: () => void;
  startOfWeek: number;
}) => {
  const {
    startOfWeek,
    firstDayOfWeek,
    selectedDate,
    lastDayOfWeek,
    setSelectedDate,
    notesMode,
    toggleNotesMode,
  } = props;
  const [selectedMonth, setSelectedMonth] = useState(
    moment(selectedDate).get("month")
  );
  const updateMonth = useCallback(
    (selection) => setSelectedMonth(moment(selection).get("month")),
    []
  );
  const renderDay = useCallback(
    (day: Date) => {
      const thisDate = moment(day).startOf("day").toDate().getTime();
      const thisMonth = moment(day).get("month");
      const classes = ["daily-logs-day-option"];
      if (thisDate == moment(selectedDate).startOf("day").toDate().getTime()) {
        classes.push("selected-day");
      }
      if (thisMonth != selectedMonth) {
        classes.push("other-month");
      }
      if (thisDate == firstDayOfWeek.getTime()) {
        classes.push("first-day-of-week");
      } else if (thisDate == lastDayOfWeek.getTime()) {
        classes.push("last-day-of-week");
      }
      if (
        thisDate >= firstDayOfWeek.getTime() &&
        thisDate <= lastDayOfWeek.getTime()
      ) {
        classes.push("day-of-week");
      }

      return (
        <div className={classes.join(" ")}>
          <div>{moment(day).format("D")}</div>
        </div>
      );
    },
    [firstDayOfWeek, lastDayOfWeek, selectedDate, selectedMonth]
  );
  const weekdayLabels = useMemo(() => ["S", "M", "T", "W", "T", "F", "S"], []);
  return (
    <Box
      display="flex"
      flex={2}
      flexDirection="column"
      className="notes-sidebar"
    >
      <DayPicker
        firstDayOfWeek={startOfWeek}
        renderDay={renderDay}
        onDayClick={setSelectedDate}
        onMonthChange={updateMonth}
        className="WeekSelect"
        showOutsideDays
        navbarElement={NavBar}
        weekdaysShort={weekdayLabels}
      />
      <Button
        className="notes-sidebar-button"
        size="btn-medium"
        onClick={notesMode ? undefined : toggleNotesMode}
        label="Notes"
        type={notesMode ? "btn-style-outline" : "btn-style-minimal"}
      />
      <Button
        className="notes-sidebar-button"
        size="btn-medium"
        onClick={notesMode ? toggleNotesMode : undefined}
        label="Stats"
        type={notesMode ? "btn-style-minimal" : "btn-style-outline"}
      />
    </Box>
  );
};

type Projection = {
  fohCost: number;
  bohCost: number;
  totalCost: number;
  targetAll: number;
  targetFoh: number;
  targetBoh: number;
  actualSales: number;
  projectedSales: number;
  projectionId: string | null;
  date: number; // YYYYMMDD
};
type StatsColumnProps = {
  dailyStats: AugmentedDailyStats[];
  dailyProjections: AugmentedProjection[];
  setSplitTarget: (
    id: string | null,
    date: number,
    fohCents: number,
    bohCents: number
  ) => Promise<string>;
  setOverallTarget: (
    id: string | null,
    date: number,
    cents: number
  ) => Promise<string>;
  setProjection: (
    id: string | null,
    date: number,
    cents: number
  ) => Promise<string>;
};

const toDateString = (date: number) => {
  const asStr = date.toString();
  const YYYY = parseInt(asStr.substring(0, 4));
  const MM = parseInt(asStr.substring(4, 6)) - 1;
  const DD = parseInt(asStr.substring(6, 8));
  return moment().year(YYYY).month(MM).date(DD).format("dddd, MMMM D");
};

const StatsColumn = (props: StatsColumnProps) => {
  const { dailyStats } = props;
  const [openDay, setOpenDay] = useState(-1);
  const toggleOpenIdx = useCallback(
    (idx: number) => {
      if (idx === openDay) setOpenDay(-1);
      else setOpenDay(idx);
    },
    [openDay]
  );
  return (
    <Box
      display="flex"
      flex={3}
      flexDirection="column"
      className="stats-column scrollable-column"
    >
      {dailyStats.map((stat, idx) => (
        <StatCard
          key={props.dailyProjections[idx].date}
          title={toDateString(props.dailyProjections[idx].date)}
          idx={idx}
          toggleOpen={toggleOpenIdx}
          isOpen={openDay === idx}
          stats={stat}
          setSplitTarget={props.setSplitTarget}
          setOverallTarget={props.setOverallTarget}
          setProjection={props.setProjection}
          dailyProjection={props.dailyProjections[idx]}
        />
      ))}
    </Box>
  );
};

const GraphsColumn = (props: {
  todayIdx: number;
  actualSales: number[];
  actualCosts: number[];
  projectedSales: number[];
  projectedCosts: number[];
}) => {
  const { actualSales, projectedSales, todayIdx, actualCosts } = props;
  const [wtdSales, wtdCosts] = useMemo(() => {
    let i = 0;
    let sales = 0;
    let costs = 0;
    while (i <= todayIdx) {
      sales += actualSales[i];
      costs += actualCosts[i];
      i++;
    }
    return [sales, costs];
  }, [actualSales, actualCosts, todayIdx]);
  return (
    <Grid container flex={5} className="daily-logs-column scrollable-column">
      <Grid item xs={4}>
        <DailySalesGraph
          actual={actualSales[todayIdx]}
          projected={projectedSales[todayIdx]}
        />
      </Grid>
      <Grid item xs={8}>
        <WTDSalesVsLaborCost sales={wtdSales} cost={wtdCosts} />
      </Grid>
      <Grid item xs={12}>
        <ActualVsProjected actual={actualSales} projected={projectedSales} />
      </Grid>
      <Grid item xs={12}>
        <SalesVsLaborCost sales={actualSales} laborCost={actualCosts} />
      </Grid>
      {/* <Grid item xs={6}>
        <InventoryStat />
      </Grid> */}
    </Grid>
  );
};

type Person = {
  id: string;
  firstName: string;
  lastName: string;
};
const NotesColumn = (props: {
  openModal: () => void;
  updateSearchValue: (search: string) => void;
  explicitTitle?: string;
  updateNote: (note: { id: string }) => Promise<unknown>;
  selectedDate: Date;
  people: Person[];
  messages: {
    title: string;
    tags: string[];
    message: {
      id: string;
      body: string;
      author: Person;
    };
    replies: {
      id: string;
      body: string;
      author: Person;
    }[];
  }[];
}) => {
  const { messages, openModal, updateSearchValue, selectedDate, people } =
    props;
  return (
    <Grid container flex={8} className="daily-logs-column scrollable-column">
      <Grid item xs={12}>
        <NotesHeader
          title={
            props.explicitTitle ||
            selectedDate.toLocaleDateString("en-US", {
              weekday: "long",
              month: "long",
              day: "numeric",
            })
          }
          onCreateNote={openModal}
          updateSearchValue={updateSearchValue}
        />
      </Grid>
      <Grid item xs={12}>
        {messages.map((message) => (
          <NoteTile
            sendUpdate={props.updateNote}
            key={message.message.id}
            title={message.title}
            tags={message.tags}
            originalMessage={message.message}
            replies={message.replies}
            people={people}
          />
        ))}
      </Grid>
    </Grid>
  );
};

type FirestoreMessage = {
  author: {
    firstName: string;
    lastName: string;
    id: string;
  };
  text: string;
  id: string;
};
type LocalMessage = {
  author: {
    firstName: string;
    lastName: string;
    id: string;
  };
  body: string;
  id: string;
};

const parseMessage = (m: FirestoreMessage): LocalMessage => ({
  author: m.author,
  body: m.text,
  id: m.id,
});

const DailyLogs = () => {
  const db = useMemo(() => firebase.firestore(), []);
  const [statsLoading, setStatsLoading] = useState(false);
  const [notesLoading, setNotesLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    // consistency with dayPicker.
    moment().startOf("day").hours(12).toDate()
  );
  const [searchValue, updateSearchValue] = useState("");

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const timezone = business?.timezone;

  const startOfWeek = moment()
    .day(business?.payrollStartOfTheWeek ?? "Mon")
    .get("day");

  const thisDayOfWeek = useMemo(
    () => moment(selectedDate).get("day"),
    [selectedDate.getTime()]
  );
  const firstDayOfWeek = useMemo(() => {
    return moment(selectedDate)
      .set("day", startOfWeek - (startOfWeek > thisDayOfWeek ? 7 : 0))
      .startOf("day")
      .toDate();
  }, [startOfWeek, thisDayOfWeek, selectedDate]);
  const lastDayOfWeek = useMemo(() => {
    return moment(firstDayOfWeek).add(6, "day").toDate();
  }, [firstDayOfWeek.getTime()]);

  const allTags = useSelector((state: RootState) => {
    // @ts-ignore
    return state.businessSettings?.dailyLogTags ?? ([] as string[]);
  });
  const allAdminUsersRaw = useSelector((state: RootState) => {
    return state.fellowStaffMembers
      .filter((staff) => staff.isAdmin)
      .map(({ id, contact }) => ({
        id,
        firstName: contact.firstName,
        lastName: contact.lastName,
      }));
  });
  const [allAdminUsers, setAllAdminUsers] = useState<
    { id: string; firstName: string; lastName: string }[]
  >([]);

  // updating the user list breaks the messages
  // so we need to make sure it only changes once
  useEffect(() => {
    if (!allAdminUsersRaw) return () => null;
    if (allAdminUsersRaw.length > 1) {
      setAllAdminUsers(allAdminUsersRaw);
      return () => null;
    }
    const to = setTimeout(() => {
      setAllAdminUsers(allAdminUsersRaw);
    }, 3000);
    return () => clearTimeout(to);
    // updates more than it should
  }, [JSON.stringify(allAdminUsersRaw)]);

  const [messages, setMessages] = useState<
    {
      id: string;
      title: string;
      tags: string[];
      message: LocalMessage;
      replies: LocalMessage[];
    }[]
  >([]);

  // temporary solution for search, load all messages and filter them
  const [allMessages, setAllMessages] = useState<
    {
      id: string;
      title: string;
      tags: string[];
      message: LocalMessage;
      replies: LocalMessage[];
    }[]
  >([]);
  useEffect(() => {
    setNotesLoading(true);
    if (!business) return () => null;
    const dayStart = toStartOfBusinessDay(selectedDate, timezone);
    const dayEnd = moment(dayStart).add(1, "day").toDate();
    const unsubscribe = db
      .collection("DailyLogs")
      .where("businessId", "==", business.id)
      // don't filter out deleted, it's an extra index we don't need
      .where("createdAt", ">=", dayStart)
      .where("createdAt", "<=", dayEnd)
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        const data = snapshot.docs
          .map((doc) => {
            return { id: doc.id, d: doc.data() };
          })
          .filter(({ d }) => !d.deleted)
          .map(({ id, d }) => {
            const primaryMessage = parseMessage(d as FirestoreMessage);
            primaryMessage.id = id;
            return {
              id: id,
              title: d.title,
              tags: d.tags as string[],
              message: primaryMessage,
              replies: d.replies.map(parseMessage),
            };
          });
        setMessages(data);
        setNotesLoading(false);
      });
    return unsubscribe;
  }, [business, db, selectedDate, timezone]);

  useEffect(() => {
    if (!business) return () => null;
    const unsubscribe = db
      .collection("DailyLogs")
      .where("businessId", "==", business.id)
      // don't filter out deleted, it's an extra index we don't need
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        const data = snapshot.docs
          .map((doc) => {
            return { id: doc.id, d: doc.data() };
          })
          .filter(({ d }) => !d.deleted)
          .map(({ id, d }) => {
            const primaryMessage = parseMessage(d as FirestoreMessage);
            primaryMessage.id = id;
            return {
              id: id,
              title: d.title,
              tags: d.tags as string[],
              message: primaryMessage,
              replies: d.replies.map(parseMessage),
            };
          });
        setAllMessages(data);
      });
    return unsubscribe;
  }, [business, db]);

  const usableMessages = useMemo(() => {
    if (!searchValue) return messages;
    const searchLower = searchValue.toLowerCase();
    return allMessages.filter((msg) => {
      if (msg.title.toLowerCase().includes(searchLower)) return true;
      if (msg.message.body.toLowerCase().includes(searchLower)) return true;
      if (msg.tags?.some((tag) => tag.toLowerCase().includes(searchLower)))
        return true;
      return false;
    });
  }, [allMessages, messages, searchValue]);

  const week = useMemo(() => {
    return new Array(7).fill(null).map((_, offset) => {
      const date: Moment = moment(firstDayOfWeek).add(offset, "days");
      return date.date() + 100 * (date.month() + 1) + 10000 * date.year();
    });
  }, [firstDayOfWeek?.getTime()]);

  const [projectionLookup, updateProjectionLookup] = useReducer(
    (curr: { [ts: number]: Projection }, add: Partial<Projection>) => {
      if (!add.date) throw "Can't use a dateless projection!";

      const currItem = curr[add.date] as Projection;
      const newItem = {
        ...(currItem ?? {}),
        ...add,
      } as Projection;
      return {
        ...curr,
        [add.date]: newItem,
      };
    },
    {}
  );

  useEffect(() => {
    // can't continue
    if (!business) return;
    // already fetching
    if (statsLoading) return;
    // nothing to do
    if (week.every((day) => day in projectionLookup)) return;
    setStatsLoading(true);
    Promise.all(
      week.map(async (date) => {
        if (projectionLookup[date]) return;
        const r = await getSummaryForDate(business.id, date);
        const value = { ...r, date };
        updateProjectionLookup(value);
      })
    ).finally(() => setStatsLoading(false));
  }, [business, week, statsLoading, projectionLookup]);

  const statistics = useMemo(() => {
    let wtdTargetFoh: number | null = null;
    let wtdTargetBoh: number | null = null;
    let wtdCostFoh = 0;
    let wtdCostBoh = 0;
    let wtdCostAll = 0;
    let wtdTargetAll = 0;
    let wtdSalesProj = 0;
    const wtdSalesReal = 0;
    let combined = true;
    return week.map((day) => {
      const projection = projectionLookup[day];
      if (!projection) {
        return {
          wtdTargetFoh,
          wtdTargetBoh,
          wtdTargetAll,
          wtdCostFoh,
          wtdCostBoh,
          wtdCostAll,
          wtdSalesProj,
          wtdSalesReal,
          targetFoh: null,
          targetBoh: null,
          targetAll: null,
          costFoh: null,
          costBoh: null,
          costAll: null,
          salesProj: 0,
          salesReal: 0,
          date: day,
          id: null,
        };
      }
      if (!combined && projection.targetAll !== null) {
        // we have a combined stat, all future stats are also combined
        combined = true;
        wtdTargetBoh = null;
        wtdTargetFoh = null;
      }
      wtdSalesProj += projection?.projectedSales ?? 0;
      wtdCostAll += projection?.totalCost ?? 0;
      wtdCostBoh += projection?.bohCost ?? 0;
      wtdCostFoh += projection?.fohCost ?? 0;

      if (combined) {
        if (projection.targetAll !== null) {
          wtdTargetAll += projection.targetAll;
        } else {
          wtdTargetAll += projection.targetBoh ?? 0;
          wtdTargetAll += projection.targetFoh ?? 0;
        }
      } else {
        (wtdTargetBoh as number) += projection.targetBoh ?? 0;
        (wtdTargetFoh as number) += projection.targetFoh ?? 0;
      }
      return {
        wtdTargetFoh,
        wtdTargetBoh,
        wtdCostFoh,
        wtdCostBoh,
        wtdTargetAll,
        wtdSalesProj,
        wtdSalesReal,
        targetFoh: projection.targetFoh,
        targetBoh: projection.targetBoh,
        targetAll: projection.targetAll,
        costFoh: projection.fohCost,
        costBoh: projection.bohCost,
        salesProj: projection.projectedSales,
        salesReal: projection.actualSales,
        date: day,
        id: projection.projectionId,
      };
    });
  }, [projectionLookup, week]);

  const updateOverallTarget = useCallback(
    async (projectionId: string | null, day: number, target: number) => {
      const r = await setOverallTarget(business.id, projectionId, day, target);
      if (r) {
        // add to existing projections
        updateProjectionLookup({
          date: day,
          targetAll: target,
          targetFoh: 0,
          targetBoh: 0,
          projectionId: r,
        });
      }
      return r ?? "";
    },
    [db, business?.id]
  );
  const updateSplitTarget = useCallback(
    async (
      projectionId: string | null,
      day: number,
      targetFoh: number,
      targetBoh: number
    ) => {
      const r = await setSplitTarget(
        business.id,
        projectionId,
        day,
        targetFoh,
        targetBoh
      );
      if (r) {
        // add to existing projections
        updateProjectionLookup({
          date: day,
          targetAll: 0,
          targetFoh,
          targetBoh,
          projectionId: r,
        });
      }
      return r ?? "";
    },
    [db, business?.id]
  );
  const updateSalesProjection = useCallback(
    async (
      projectionId: string | null,
      day: number,
      projectedSales: number
    ) => {
      const r = await setSalesProjection(
        business.id,
        projectionId,
        day,
        projectedSales
      );
      if (r) {
        // add to existing projections
        updateProjectionLookup({
          date: day,
          projectedSales,
          projectionId: r,
        });
      }
      return r ?? "";
    },
    [db, business?.id]
  );

  const actualSalesValues = useMemo(
    () =>
      week.map((_day, idx) => {
        const actual = statistics[idx].salesReal;
        const override = null;
        return override ?? actual ?? 0;
      }),
    [statistics]
  );

  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);
  const [notesMode, _notesOn, _notesOff, toggleNotesMode] = useFlag(true);

  const dailyStats = useMemo(() => {
    return week.map((date, idx) => {
      const stat = statistics[idx];
      return {
        date,
        targetLaborCost: {
          total: stat.targetAll,
          foh: stat.targetFoh,
          boh: stat.targetBoh,
        },
        actualLaborCost: {
          total: stat.costAll,
          foh: stat.costFoh,
          boh: stat.costBoh,
        },
        projectedSales: stat.salesProj,
        actualSales: stat.salesReal,
        foodCost: 0,
        final: true,
        wtdSales: stat.wtdSalesReal,
        wtdLC: {
          foh: stat.wtdCostFoh ?? null,
          boh: stat.wtdCostBoh ?? null,
          total: stat.wtdCostAll ?? null,
        },
      };
    });
  }, [statistics, week, actualSalesValues]);

  const [modalOpen, openModal, closeModal] = useFlag(false);
  const submitNewNote = useCallback(
    (note: Note) => {
      const author = allAdminUsers.find(({ id }) => id == note.authorId);
      if (!author) return; // throw
      db.collection("DailyLogs")
        .add({
          author,
          createdAt: new Date(),
          updatedAt: new Date(),
          enabled: true,
          deleted: false,
          businessId: business.id,
          date: toCanonicalDate(new Date(), timezone),
          replies: [],
          text: note.body,
          title: note.title,
          tags: note.tagIds,
        })
        .then(() => {
          if (note.tagIds) {
            return db
              .collection("BusinessSettings")
              .doc(business.id)
              .update({
                dailyLogTags: firebase.firestore.FieldValue.arrayUnion(
                  ...note.tagIds
                ),
              });
          }
        })
        .then(closeModal, (e) => console.error("ERROR posting note", e));
    },
    [db, allAdminUsers, timezone]
  );
  const updateExistingNote = useCallback(
    (note) => {
      return db
        .collection("DailyLogs")
        .doc(note.id as string)
        .update({ ...note, updatedAt: new Date() })
        .catch((e) => console.error("ERROR updating note", e));
    },
    [db]
  );
  const projectedSales = useMemo(
    () => statistics.map((stat) => stat.salesProj ?? 0),
    [statistics]
  );
  const projectedCosts = useMemo(
    () => statistics.map((stat) => stat.targetAll ?? 0),
    [statistics]
  );
  const actualCosts = useMemo(
    () =>
      statistics.map(
        (stat) => stat.costAll ?? (stat.costBoh ?? 0) + (stat.costFoh ?? 0)
      ),
    [statistics]
  );
  return (
    <>
      <Box
        className={`${emulatorMode ? "emulatorMode" : ""} daily-logs-page`}
        display="flex"
        flexDirection="row"
        flex={1}
      >
        <SideBar
          startOfWeek={1}
          firstDayOfWeek={firstDayOfWeek}
          selectedDate={selectedDate}
          lastDayOfWeek={lastDayOfWeek}
          setSelectedDate={setSelectedDate}
          notesMode={notesMode}
          toggleNotesMode={toggleNotesMode}
        />
        {!notesMode && (
          <StatsColumn
            dailyStats={dailyStats}
            dailyProjections={statistics}
            setSplitTarget={updateSplitTarget}
            setOverallTarget={updateOverallTarget}
            setProjection={updateSalesProjection}
          />
        )}
        {!notesMode && (
          <GraphsColumn
            actualSales={actualSalesValues}
            projectedSales={projectedSales}
            todayIdx={(7 + thisDayOfWeek - startOfWeek) % 7}
            actualCosts={actualCosts}
            projectedCosts={projectedCosts}
          />
        )}
        {notesMode && (
          <NotesColumn
            messages={usableMessages}
            people={allAdminUsers}
            openModal={openModal}
            updateSearchValue={updateSearchValue}
            selectedDate={selectedDate}
            updateNote={updateExistingNote}
            explicitTitle={searchValue ? "All Messages" : ""}
          />
        )}
      </Box>
      <NewNoteModal
        open={notesMode && modalOpen}
        people={allAdminUsers}
        onClose={closeModal}
        onSubmit={submitNewNote}
        tagOptions={allTags}
      />
      {(notesMode ? notesLoading : statsLoading) && <Spinner />}
    </>
  );
};

export default DailyLogs;
