import React from "react";

import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";

import Sidebar from "../Sidebar";
import { headerRoutes, inventorySelectedPage } from "../routes";
import CountMethodModal from "./CountMethodModal";
import NewTabletCountModal from "./NewTabletCountModal";
import useCurrentInventory from "./useCurrentInventory";

const Conversions = () => {

  const {
    invCounts,
    locationOptions,
    createCount,
    columns,
    instructions,
    showCountMethodsModal,
    setShowCountMethodsModal,
    showNewTabletCountModal,
    setShowNewTabletCountModal,
  } = useCurrentInventory();
  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header routes={headerRoutes} selectedRoute={"/inventory/items/basic"} selectedPage={inventorySelectedPage} />
      <div className="flex h-full overflow-hidden">
        <div
          className={"border-r border-grey-1 border-t-0 border-b-0 overflow-y-auto"}
          style={{ width: "10%", borderRightStyle: "solid" }}
        >
          <Sidebar />
        </div>

        <div
          className={"flex px-10 py-6 justify-between overflow-y-auto"}
          style={{ width: "90%" }}
        >
          <div className={"w-full"}>
            <HorizontalTable
              title="Current Inventory"
              columns={columns}
              data={invCounts}
              instructions={instructions}
              primaryActionLabel="New Count"
              primaryActionHandler={() => setShowCountMethodsModal(true)}
              isEditing={false}
              hideSave
              hideEdit
              hideCheckboxes
            />
          </div>
          {/* <div className={"w-2/12"}>
            <Filters />
          </div> */}
        </div>
      </div>
      <CountMethodModal
        isOpen={showCountMethodsModal}
        onClose={() => setShowCountMethodsModal(false)}
        onTabletClick={() => {
          setShowCountMethodsModal(false);
          setShowNewTabletCountModal(true);
        }}
      />
      <NewTabletCountModal
        isOpen={showNewTabletCountModal}
        onClose={() => setShowNewTabletCountModal(false)}
        locationOptions={locationOptions}
        onSubmit={createCount}
      />
    </div>
  );
};

export default Conversions;
