import React from 'react';
import UIComponents from '@tangopay/tango-ui-library';
const { Modal, Button } = UIComponents;

type Props = {
    isOpen: boolean;
    closeModal: () => void;
}
const DenyRequestModal = ({ isOpen, closeModal }: Props) => {
    return (
        <Modal isOpen={isOpen} closeModal={closeModal} width="736">
            <div className=' font-lato-black text-lg text-black mb-10'>
                Deny Request
            </div>
            <div>
                <div className=' text-base font-lato-black text-black mb-4'>Reason</div>
                <textarea className='px-6 py-5 w-full rounded-2xl font-lato-regular text-sm text-black' placeholder='Type your message here...' />
            </div>
            <div className='mt-9 flex'>
                <Button label='Cancel' onClick={closeModal} className='w-full mr-4 justify-center' type="btn-style-1" size="btn-medium" />
                <Button label={`Deny Request`} className='w-full justify-center' type="btn-style-1" size="btn-medium" />
            </div>
        </Modal>
    )
}
export default DenyRequestModal;