import { useCallback, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";

import {
  useVendor,
  PurchaseItemDTO,
  useMakePurchase,
  ExpenseDTO,
} from "controllers/vendors";

import { RootState } from "../../../model/store";
import { expensePrefix, taxPrefix, instructionsFactory, purchasePrefix, columns, LineItem, detailColumns, invoiceInstructions, Invoice, summaryColumns } from "./instructions";
import { useUniqueIdList } from "../hooks";
import { UpdateState } from "components/Table/HorizontalTable";

const defaultLineItem = (uniqueId: string): LineItem => {
  if (uniqueId.startsWith(purchasePrefix)) {
    return {
      uniqueId,
      sale: null,
      purchaseCount: 1,
      totalCost: null,
      unitCost: 0,
    }
  } else {
    return {
      uniqueId,
      sale: null,
      purchaseCount: 1,
      totalCost: null,
      unitCost: 0,
    }
  }
}

type EditState = { [id: string]: { count?: number; unitCost?: number; sale?: string } }
const useCreateInvoice = (vendorId: string) => {
  const businessId: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.id;
  const {
    push: addItem,
    remove: deleteIds,
    clear: clearIds,
    addedIds,
  } = useUniqueIdList("new__lineItem");

  const [edits, handleEdit] = useReducer(
    (curr: EditState, action: { uniqueId: string, attribute: string, value: unknown }) => {
      const currItem = curr[action.uniqueId] ?? {};
      const newItem = { ...currItem };
      if (action.attribute == "purchaseCount") {
        newItem.count = Number(action.value);
      } else if (action.attribute == "unitCost") {
        newItem.unitCost = Number(action.value);
      } else if (action.attribute == "sale") {
        newItem.sale = action.value as string;
      } else {
        return curr;
      }
      return {
        ...curr,
        [action.uniqueId]: newItem,
      }
    },
    {} as EditState
  );
  const handleItemUpdate = useCallback(
    (uniqueId: string, attribute: string, value: unknown) => handleEdit({ uniqueId, attribute, value }),
    [handleEdit],
  )

  const [editingSales, setEditingSales] = useState(false);
  const [editingDetails, setEditingDetails] = useState(false);
  const addPurchase = useCallback(() => {
    addItem(purchasePrefix);
    setEditingSales(true);
  }, []);
  const addExpense = useCallback(() => {
    addItem(expensePrefix);
  }, []);
  const addTax = useCallback(() => {
    addItem(taxPrefix);
  }, []);

  const [totalTax, totalExpense, totalPurchase] = useMemo(() => {
    const vals = [0, 0, 0];
    addedIds.forEach(id => {
      const edit = edits[id] ?? {};
      const unitCost = edit.unitCost ?? 100;
      const count = edit.count ?? 1;
      const total = unitCost * count;
      if (id.startsWith(taxPrefix)) vals[0] += total;
      if (id.startsWith(expensePrefix)) vals[1] += total;
      if (id.startsWith(purchasePrefix)) vals[2] += total;
    });
    return vals;
  }, [edits, addedIds])

  const vendorQuery = useVendor(businessId, vendorId);
  const [purchaseId, setPurchaseId] = useState("")
  const invoiceAsList: Invoice[] = useMemo(() => {
    const d = vendorQuery.data;
    if (!d) return [];
    return [{
      uniqueId: d.vendor.id,
      date: new Date().toLocaleDateString(),
      purchaseId,
      vendorName: d.vendor.name,
      totalPurchase,
      totalTax,
      totalExpense,
    }];
  }, [vendorQuery?.data, totalTax, totalExpense, totalPurchase, purchaseId]);

  const saveDetail = useCallback(async (updates: UpdateState) => {
    const edit = updates[vendorId]?.purchaseId;
    if (!edit) return;
    setPurchaseId(edit.newValue as string);
  }, [vendorId]);

  const lineItems = useMemo(() => {
    return addedIds.map(defaultLineItem)
  }, [addedIds]);
  const instructions = useMemo(() => instructionsFactory(vendorQuery.data), [vendorQuery.data])
  const purchaseMutation = useMakePurchase(businessId);
  const makePurchase = useCallback(
    async () => {
      const items = addedIds.map((uniqueId) => {
        const update = edits[uniqueId] ?? {};
        const saleId = update.sale as string;
        // this ignores taxes and expenses
        // the backend doesn't support them yet
        if (!saleId) return null;
        const unitPrice = update.unitCost ?? 100;
        const count = update.count ?? 1;
        const sale = vendorQuery.data?.itemsSold?.find(({ id }) => id == saleId);
        if (!sale) return null;
        const result = {
          saleId,
          itemId: sale.rawItem.id,
          saleUnitCount: count,
          cost: unitPrice * count,
        }
        return result;
      }).filter(
        truthy => truthy
      ).map(dto => {
        if (!dto) throw "Please typescript learn what filter does.";
        return dto as PurchaseItemDTO;
      });
      const expenses = addedIds.map((uniqueId) => {
        if (uniqueId.startsWith(purchasePrefix)) return null;
        const update = edits[uniqueId] ?? {};

        const type = uniqueId.startsWith(taxPrefix) ? "tax" : "expense";
        const name = update.sale ?? "";
        const price = update.unitCost ?? 100;
        const count = update.count ?? 1;
        const result = {
          type,
          name,
          count,
          price,
        }
        return result;
      }).filter(
        truthy => truthy
      ).map(dto => {
        if (!dto) throw "Please typescript learn what filter does.";
        return dto as ExpenseDTO;
      });
      const r = await purchaseMutation.mutateAsync({ vendorId, items, expenses, purchaseId });
      clearIds();
      return r;
    },
    [vendorQuery.data?.itemsSold, addedIds, edits, purchaseId],
  );
  return {
    vendor: vendorQuery.data?.vendor,
    lineItems,
    addPurchase,
    addExpense,
    addTax,
    makePurchase,
    instructions,
    columns,
    editingSales,
    setEditingSales,
    deleteIds,
    invoiceAsList,
    summaryColumns,
    summaryInstructions: invoiceInstructions,
    detailColumns,
    detailInstructions: invoiceInstructions,
    editingDetails,
    setEditingDetails,
    handleItemUpdate,
    saveDetail,
  };
};
export default useCreateInvoice;
