import moment from "moment-timezone";
import { createSelector } from "reselect";

import { RootState } from "model/store";

type TimeOffMonthYear = {
  month: string;
  year: number;
  firstDayReference: Date;
  timeOffNumber: number;
  archiveTimeOffNumber: number;
  pendingTimeOffNumber: number;
};

export const scheduleEventsSelector = (state: RootState) =>
  state.scheduleEvents;

export const timeOffRequestsSelector = createSelector(
  scheduleEventsSelector,
  (scheduleEvents: ScheduleEvent[]) =>
    scheduleEvents.filter((e) => e.eventType === "time_off")
);

export const approverTimeOffRequestsSelector = createSelector(
  scheduleEventsSelector,
  (scheduleEvents: ScheduleEvent[]) =>
    scheduleEvents.filter(
      (e) => e.eventType === "time_off" && e.status === "approved"
    )
);

// const businessSelector: TangoBusiness = (state: RootState) => state.business;

// export const selectCostScheduleData = createSelector(
//   businessSelector,

// )

export const selectMonthsForTimeOffRequestsPage = createSelector(
  timeOffRequestsSelector,
  (scheduleEvents: ScheduleEvent[]) => {
    const months = moment.months();

    const getTimeOffRequestsByFirstDayOfTheMonthReference = (
      firstDayOfTheMonth: Date
    ): ScheduleEvent[] =>
      scheduleEvents.filter((r) => {
        const firstDayOfTimeOff = r?.timeOffRequestStartDate
          ? r?.timeOffRequestStartDate
          : r?.timeOffRequestDates?.sort(
              (a, b) => a?.toMillis() - b?.toMillis()
            )?.[0];
        return moment(firstDayOfTheMonth).isSame(
          moment(firstDayOfTimeOff?.toDate()),
          "month"
        );
      });

    const getPendingTimeOffRequestsByFirstDayOfTheMonthReference = (
      firstDayOfTheMonth: Date
    ): ScheduleEvent[] =>
      scheduleEvents.filter((r) => {
        const firstDayOfTimeOff = r?.timeOffRequestStartDate
          ? r?.timeOffRequestStartDate
          : r?.timeOffRequestDates?.sort(
              (a, b) => a?.toMillis() - b?.toMillis()
            )?.[0];
        return (
          r.status === "pending" &&
          moment(firstDayOfTheMonth).isSame(
            moment(firstDayOfTimeOff?.toDate()),
            "month"
          )
        );
      });

    const getArchivedTimeOffRequestsByFirstDayOfTheMonthReference = (
      firstDayOfTheMonth: Date
    ): ScheduleEvent[] =>
      scheduleEvents.filter((r) => {
        const firstDayOfTimeOff = r?.timeOffRequestStartDate
          ? r?.timeOffRequestStartDate
          : r?.timeOffRequestDates?.sort(
              (a, b) => a?.toMillis() - b?.toMillis()
            )?.[0];
        return (
          r.status !== "pending" &&
          moment(firstDayOfTheMonth).isSame(
            moment(firstDayOfTimeOff?.toDate()),
            "month"
          )
        );
      });

    const currentMonth = moment().month();

    const nextYearMonths: TimeOffMonthYear[] = months
      .slice(currentMonth)
      .map((month) => {
        const firstDayReference = moment()
          .set({ month: months.indexOf(month), date: 1 })
          .toDate();
        return {
          month,
          year: moment().year(),
          timeOffNumber:
            getTimeOffRequestsByFirstDayOfTheMonthReference(firstDayReference)
              ?.length,
          pendingTimeOffNumber:
            getPendingTimeOffRequestsByFirstDayOfTheMonthReference(
              firstDayReference
            ).length,
          archiveTimeOffNumber:
            getArchivedTimeOffRequestsByFirstDayOfTheMonthReference(
              firstDayReference
            ).length,
          firstDayReference,
        };
      });
    const pastYearMonths = months
      .slice(0, currentMonth ? currentMonth - 1 : currentMonth)
      .map((month) => {
        const firstDayReference = moment()
          .set({ month: months.indexOf(month), date: 1 })
          .toDate();

        return {
          month,
          year: moment().year(),
          timeOffNumber:
            getTimeOffRequestsByFirstDayOfTheMonthReference(firstDayReference)
              ?.length,
          pendingTimeOffNumber:
            getPendingTimeOffRequestsByFirstDayOfTheMonthReference(
              firstDayReference
            ).length,
          archiveTimeOffNumber:
            getArchivedTimeOffRequestsByFirstDayOfTheMonthReference(
              firstDayReference
            ).length,
          firstDayReference: moment()
            .set({ month: months.indexOf(month), date: 1 })
            .toDate(),
        };
      });
    const upcomingYears = [moment().year() + 1, moment().year() + 2];
    const pastYears = [moment().year() - 1, moment().year() - 2];

    const upcomingMonths: TimeOffMonthYear[] = [];
    upcomingYears.forEach((year: number) => {
      months.forEach((month) => {
        const firstDayReference = moment()
          .set({ month: months.indexOf(month), date: 1, year })
          .toDate();
        upcomingMonths.push({
          month,
          year,
          timeOffNumber:
            getTimeOffRequestsByFirstDayOfTheMonthReference(firstDayReference)
              ?.length,
          pendingTimeOffNumber:
            getPendingTimeOffRequestsByFirstDayOfTheMonthReference(
              firstDayReference
            ).length,
          archiveTimeOffNumber:
            getArchivedTimeOffRequestsByFirstDayOfTheMonthReference(
              firstDayReference
            ).length,
          firstDayReference,
        });
      });
    });
    const pastMonths: TimeOffMonthYear[] = [];

    pastYears.forEach((year: number) => {
      months.forEach((month) => {
        const firstDayReference = moment()
          .set({ month: months.indexOf(month), date: 1, year })
          .toDate();

        pastMonths.push({
          month,
          year,
          timeOffNumber:
            getTimeOffRequestsByFirstDayOfTheMonthReference(firstDayReference)
              ?.length,
          pendingTimeOffNumber:
            getPendingTimeOffRequestsByFirstDayOfTheMonthReference(
              firstDayReference
            ).length,
          archiveTimeOffNumber:
            getArchivedTimeOffRequestsByFirstDayOfTheMonthReference(
              firstDayReference
            ).length,
          firstDayReference,
        });
      });
    });

    const allMonths: TimeOffMonthYear[] = [
      ...pastMonths,
      ...pastYearMonths,
      ...nextYearMonths,
      ...upcomingMonths,
    ]
      .filter(
        (month) =>
          getTimeOffRequestsByFirstDayOfTheMonthReference(
            month.firstDayReference
          ).length
      )
      .sort(
        (a, b) => a.firstDayReference.getTime() - b.firstDayReference.getTime()
      );

    const pendingMonths: TimeOffMonthYear[] = [
      ...pastMonths,
      ...pastYearMonths,
      ...nextYearMonths,
      ...upcomingMonths,
    ]
      .filter(
        (month) =>
          getPendingTimeOffRequestsByFirstDayOfTheMonthReference(
            month.firstDayReference
          ).length
      )
      .sort(
        (a, b) => a.firstDayReference.getTime() - b.firstDayReference.getTime()
      );
    const archivedMonths: TimeOffMonthYear[] = [
      ...pastMonths,
      ...pastYearMonths,
      ...nextYearMonths,
      ...upcomingMonths,
    ]
      .filter(
        (month) =>
          getArchivedTimeOffRequestsByFirstDayOfTheMonthReference(
            month.firstDayReference
          ).length
      )
      .sort(
        (a, b) => a.firstDayReference.getTime() - b.firstDayReference.getTime()
      );

    const gridMonths = allMonths
      .filter(
        (month) =>
          getTimeOffRequestsByFirstDayOfTheMonthReference(
            month.firstDayReference
          ).length
      )
      .map((month: TimeOffMonthYear) => ({
        monthDetails: month,
        timeOffRequests: getTimeOffRequestsByFirstDayOfTheMonthReference(
          month.firstDayReference
        ),
      }));

    return {
      allMonths,
      gridMonths,
      pendingMonths,
      archivedMonths,
    };
  }
);

export const getStaffMembersThatHaveBeenApprovedForTimeOffDuringShfit = (
  fellowStaffMembers: StaffMember[],
  approvedTimeOffRequests: ScheduleEvent[],
  shift: TangoShift
) => {
  const shiftStartTime = moment(shift.startDate.toDate());
  const shiftEndTime = moment(shift.endDate.toDate());
  return fellowStaffMembers.filter((staffMember) => {
    const timeOffRequestForTheShift = approvedTimeOffRequests.find(
      (timeOffRequest) => {
        const timeOffRequestBelongsToTheStaffMember =
          staffMember.id === timeOffRequest?.senderStaff?.id;
        const sortedTimeOffRequestDates =
          timeOffRequest?.timeOffRequestDates?.sort(
            (a, b) => a.toMillis() - b.toMillis()
          );
        if (
          !sortedTimeOffRequestDates?.length &&
          !(
            timeOffRequest.timeOffRequestStartDate &&
            timeOffRequest.timeOffRequestEndDate
          )
        )
          return false;

        let timeOffRequestIsWthinTheShiftBasedOnStartAndEndDate = false;

        if (
          timeOffRequest.timeOffRequestStartDate &&
          timeOffRequest.timeOffRequestEndDate
        ) {
          timeOffRequestIsWthinTheShiftBasedOnStartAndEndDate =
            (shiftStartTime.isSameOrAfter(
              moment(timeOffRequest.timeOffRequestStartDate.toDate())
            ) &&
              shiftStartTime.isSameOrBefore(
                moment(timeOffRequest.timeOffRequestEndDate.toDate())
              )) ||
            (shiftEndTime.isSameOrAfter(
              moment(timeOffRequest.timeOffRequestStartDate.toDate())
            ) &&
              shiftEndTime.isSameOrBefore(
                moment(timeOffRequest.timeOffRequestEndDate.toDate())
              ));
        }

        if (
          sortedTimeOffRequestDates?.length &&
          !timeOffRequest.timeOffRequestStartDate &&
          !timeOffRequest.timeOffRequestEndDate
        ) {
          timeOffRequestIsWthinTheShiftBasedOnStartAndEndDate =
            sortedTimeOffRequestDates.length > 1
              ? (shiftStartTime.isSameOrAfter(
                  moment(sortedTimeOffRequestDates[0].toDate()).startOf("day")
                ) &&
                  shiftStartTime.isSameOrBefore(
                    moment(
                      sortedTimeOffRequestDates[
                        sortedTimeOffRequestDates.length - 1
                      ].toDate()
                    )
                      .startOf("day")
                      .add(1, "day")
                  )) ||
                (shiftEndTime.isSameOrAfter(
                  moment(sortedTimeOffRequestDates[0].toDate()).startOf("day")
                ) &&
                  shiftStartTime.isSameOrBefore(
                    moment(
                      sortedTimeOffRequestDates[
                        sortedTimeOffRequestDates.length - 1
                      ].toDate()
                    )
                      .startOf("day")
                      .add(1, "day")
                  ))
              : shiftStartTime.isSame(
                  moment(sortedTimeOffRequestDates[0].toDate()),
                  "date"
                );
        }
        if (staffMember.contact.lastName === "Rhyan") {
          console.log(
            "timeOffRequestBelongsToTheStaffMember",
            timeOffRequestBelongsToTheStaffMember
          );
          console.log(
            "timeOffRequestIsWthinTheShiftBasedOnStartAndEndDate",
            timeOffRequestIsWthinTheShiftBasedOnStartAndEndDate
          );
        }
        return (
          timeOffRequestBelongsToTheStaffMember &&
          timeOffRequestIsWthinTheShiftBasedOnStartAndEndDate
        );
      }
    );
    return Boolean(timeOffRequestForTheShift);
  });
};

export const checkForUnavailableIcon = (
  staffMember: StaffMember,
  fixedAvailabilities: FixedAvailability[],
  business: TangoBusiness,
  scheduleToUse: TangoSchedule | null | undefined,
  shift: TangoShift,
  ignoreOverlappingShift = false,
  staffMembersThatHaveApprovedTimeOffForThisShift: StaffMember[]
) => {
  const shiftStartTime = moment(shift.startDate.toDate());
  return fixedAvailabilities.find((a) => {
    const availabilityBelongsToSelectedStaffMember =
      a.staffId === staffMember.id;
    if (!availabilityBelongsToSelectedStaffMember) return false;
    const availabilityShiftDay = a.schedule.find(
      (sh) => sh.day === moment.weekdays()[shiftStartTime.day()]
    );
    if (!availabilityShiftDay) return false;
    const staffAndShiftRequiredPositionAligned =
      staffMember.primaryRole === shift.position ||
      staffMember.secondaryRoles.includes(shift.position);
    const staffMemberIsNotWorkingOverlappingShift = !scheduleToUse?.shifts.find(
      (sh) =>
        sh.id !== shift.id &&
        sh.staffId === staffMember.id &&
        (moment(sh.startDate.toDate()).isBetween(
          moment(shift?.startDate.toDate()),
          moment(shift?.endDate.toDate()),
          null,
          "[]"
        ) ||
          moment(sh.endDate.toDate()).isBetween(
            moment(shift?.startDate.toDate()),
            moment(shift?.endDate.toDate()),
            null,
            "[]"
          ))
    );
    const shiftIsAlignedWithAvailability =
      startTimeAndEndTimeOfTheShiftIsWithinStaffMemberAvailability(
        shift,
        availabilityShiftDay,
        business,
        staffMember,
        a
      );
    const showStaffMember =
      (staffAndShiftRequiredPositionAligned &&
        !shiftIsAlignedWithAvailability &&
        staffMemberIsNotWorkingOverlappingShift) ||
      (staffAndShiftRequiredPositionAligned &&
        staffMembersThatHaveApprovedTimeOffForThisShift.find(
          (sm) => sm.id === staffMember.id
        )) ||
      (staffAndShiftRequiredPositionAligned && !availabilityShiftDay.available);
    return showStaffMember;
  });
};

export const staffMemberIsUnavailableToWorkTheShift = (
  staffMember: StaffMember,
  fixedAvailabilities: FixedAvailability[],
  business: TangoBusiness,
  scheduleToUse: TangoSchedule | null | undefined,
  shift: TangoShift,
  ignoreOverlappingShift = false,
  staffMembersThatHaveApprovedTimeOffForThisShift: StaffMember[]
) => {
  const shiftStartTime = moment(shift.startDate.toDate());
  const shiftEndTime = moment(shift.endDate.toDate());

  return fixedAvailabilities.find((a) => {
    const availabilityBelongsToSelectedStaffMember =
      a.staffId === staffMember.id;
    if (!availabilityBelongsToSelectedStaffMember) return false;
    const availabilityShiftDay = a.schedule.find(
      (sh) => sh.day === moment.weekdays()[shiftStartTime.day()]
    );
    if (!availabilityShiftDay) return false;
    const staffAndShiftRequiredPositionAligned =
      staffMember.primaryRole === shift.position ||
      staffMember.secondaryRoles.includes(shift.position);

    const staffMemberIsWorkingOverlappingShift = scheduleToUse?.shifts.find(
      (sh) => {
        const compareShiftStart = moment(sh.startDate?.toDate());
        const compareShiftEnd = moment(sh.endDate?.toDate());
        const overlaps =
          (compareShiftStart.isSameOrAfter(shiftStartTime) &&
            compareShiftStart.isSameOrBefore(shiftEndTime)) ||
          (compareShiftEnd.isAfter(shiftStartTime) &&
            compareShiftEnd.isSameOrBefore(shiftEndTime)) ||
          (shiftStartTime.isAfter(compareShiftStart) &&
            shiftStartTime.isBefore(compareShiftEnd)) ||
          (shiftEndTime.isSameOrAfter(compareShiftStart) &&
            shiftEndTime.isSameOrBefore(compareShiftStart));
        return sh.id !== shift.id && sh.staffId === staffMember.id && overlaps;
      }
    );

    const staffMemberIsNotWorkingOverlappingShift = !scheduleToUse?.shifts.find(
      (sh) =>
        sh.id !== shift.id &&
        sh.staffId === staffMember.id &&
        (moment(sh.startDate.toDate()).isBetween(
          moment(shift?.startDate.toDate()),
          moment(shift?.endDate.toDate()),
          null,
          "[]"
        ) ||
          moment(sh.endDate.toDate()).isBetween(
            moment(shift?.startDate.toDate()),
            moment(shift?.endDate.toDate()),
            null,
            "[]"
          ))
    );
    const shiftIsAlignedWithAvailability =
      startTimeAndEndTimeOfTheShiftIsWithinStaffMemberAvailability(
        shift,
        availabilityShiftDay,
        business,
        staffMember,
        a
      );
    const staffMemberIsNotAlreadyWorkingThisShift =
      shift.staffId !== staffMember.id;
    const showStaffMember =
      (staffMemberIsNotAlreadyWorkingThisShift &&
        staffAndShiftRequiredPositionAligned &&
        !shiftIsAlignedWithAvailability &&
        !staffMemberIsWorkingOverlappingShift) ||
      (staffAndShiftRequiredPositionAligned &&
        staffMembersThatHaveApprovedTimeOffForThisShift.find(
          (sm) => sm.id === staffMember.id
        )) ||
      (staffAndShiftRequiredPositionAligned && !availabilityShiftDay.available);
    return showStaffMember;
  });
};

const startTimeAndEndTimeOfTheShiftIsWithinStaffMemberAvailability = (
  shift: TangoShift,
  availabilityShiftDay: FixedAvailabilitySchedule,
  business: TangoBusiness,
  staffMember: StaffMember,
  a: FixedAvailability
) => {
  const shiftStartTime = moment(shift.startDate.toDate());
  const shiftEndTime = moment(shift.endDate.toDate());

  const availabilityStartTime = moment.tz(
    shift.startDate.toDate(),
    business.timezone
  );
  availabilityStartTime.set({
    hours: moment(availabilityShiftDay.startTime, "HH:mm").hours(),
    minutes: moment(availabilityShiftDay.startTime, "HH:mm").minutes(),
  });
  const availabilityEndTime = moment.tz(
    shift.startDate.toDate(),
    business.timezone
  );
  availabilityEndTime.set({
    hours: moment(availabilityShiftDay.endTime, "HH:mm").hours(),
    minutes: moment(availabilityShiftDay.endTime, "HH:mm").minutes(),
  });
  if (availabilityStartTime.isSameOrAfter(availabilityEndTime)) {
    availabilityEndTime.add(1, "day");
  }
  let availabilityEndTimeToUse = availabilityEndTime.clone();

  const availabilityForTheShiftEndTime = a.schedule.find(
    (sh) => sh.day === moment.weekdays()[shiftEndTime.day()]
  );
  if (
    availabilityForTheShiftEndTime &&
    availabilityForTheShiftEndTime.day !== availabilityShiftDay.day
  ) {
    const startDateOfAvailabilityForTheShiftEndTime = moment.tz(
      shift.endDate.toDate(),
      business.timezone
    );
    startDateOfAvailabilityForTheShiftEndTime.set({
      hours: moment(availabilityForTheShiftEndTime.startTime, "HH:mm").hours(),
      minutes: moment(
        availabilityForTheShiftEndTime.startTime,
        "HH:mm"
      ).minutes(),
    });

    if (startDateOfAvailabilityForTheShiftEndTime.isSame(availabilityEndTime)) {
      const endDateOfAvailabilityForTheShiftEndTime = moment.tz(
        shift.endDate.toDate(),
        business.timezone
      );
      endDateOfAvailabilityForTheShiftEndTime.set({
        hours: moment(availabilityForTheShiftEndTime.endTime, "HH:mm").hours(),
        minutes: moment(
          availabilityForTheShiftEndTime.endTime,
          "HH:mm"
        ).minutes(),
      });
      if (
        startDateOfAvailabilityForTheShiftEndTime.isSameOrAfter(
          endDateOfAvailabilityForTheShiftEndTime
        )
      ) {
        endDateOfAvailabilityForTheShiftEndTime.add(1, "day");
      }
      availabilityEndTimeToUse =
        endDateOfAvailabilityForTheShiftEndTime.clone();
    }
  }

  const shiftStartTimeIsWithinStaffMembersAvailability =
    shiftStartTime.isBetween(
      availabilityStartTime,
      availabilityEndTimeToUse,
      null,
      "[]"
    );
  const shiftEndTimeIsWithinStaffMembersAvailability = shiftEndTime.isBetween(
    availabilityStartTime,
    availabilityEndTimeToUse,
    null,
    "[]"
  );

  return (
    shiftStartTimeIsWithinStaffMembersAvailability &&
    shiftEndTimeIsWithinStaffMembersAvailability
  );
};

export const staffMemberIsAvailableToWorkTheShift = (
  staffMember: StaffMember,
  fixedAvailabilities: FixedAvailability[],
  business: TangoBusiness,
  scheduleToUse: TangoSchedule | null | undefined,
  shift: TangoShift,
  ignoreOverlappingShift = false
) => {
  const shiftStartTime = moment(shift.startDate.toDate());
  const shiftEndTime = moment(shift.endDate.toDate());

  const result = fixedAvailabilities.find((a) => {
    const availabilityBelongsToSelectedStaffMember =
      a.staffId === staffMember.id;

    if (!availabilityBelongsToSelectedStaffMember) return null;

    const availabilityShiftDay = a.schedule.find(
      (sh) => sh.day === moment.weekdays()[shiftStartTime.day()]
    );

    if (!availabilityShiftDay) return null;
    if (!availabilityShiftDay.available) return null;
    const staffAndShiftRequiredPositionAligned =
      staffMember.primaryRole === shift.position ||
      staffMember.secondaryRoles.includes(shift.position);
    const staffMemberIsWorkingOverlappingShift = scheduleToUse?.shifts.find(
      (sh) => {
        const compareShiftStart = moment(sh.startDate?.toDate());
        const compareShiftEnd = moment(sh.endDate?.toDate());
        const overlaps =
          (compareShiftStart.isSameOrAfter(shiftStartTime) &&
            compareShiftStart.isSameOrBefore(shiftEndTime)) ||
          (compareShiftEnd.isAfter(shiftStartTime) &&
            compareShiftEnd.isSameOrBefore(shiftEndTime)) ||
          (shiftStartTime.isAfter(compareShiftStart) &&
            shiftStartTime.isBefore(compareShiftEnd)) ||
          (shiftEndTime.isSameOrAfter(compareShiftStart) &&
            shiftEndTime.isSameOrBefore(compareShiftStart));
        return sh.id !== shift.id && sh.staffId === staffMember.id && overlaps;
      }
    );
    const shiftIsAlignedWithAvailability =
      startTimeAndEndTimeOfTheShiftIsWithinStaffMemberAvailability(
        shift,
        availabilityShiftDay,
        business,
        staffMember,
        a
      );

    const staffMemberIsNotAlreadyWorkingThisShift =
      shift.staffId !== staffMember.id;

    const showStaffMember =
      staffMemberIsNotAlreadyWorkingThisShift &&
      staffAndShiftRequiredPositionAligned &&
      shiftIsAlignedWithAvailability &&
      !staffMemberIsWorkingOverlappingShift;

    if (staffMember.contact.lastName === "Smith") {
      console.log(
        "staffMemberIsWorkingOverlappingShift",
        staffMemberIsWorkingOverlappingShift
      );
      console.log(
        "staffMemberIsNotAlreadyWorkingThisShift",
        staffMemberIsNotAlreadyWorkingThisShift
      );
      console.log(
        "staffAndShiftRequiredPositionAligned",
        staffAndShiftRequiredPositionAligned
      );
      console.log("a", a);
      console.log("availabilityShiftDay", availabilityShiftDay);
      console.log(
        "shiftIsAlignedWithAvailability",
        shiftIsAlignedWithAvailability
      );
      console.log("Result", showStaffMember);
      console.log("shift", {
        ...shift,
        startDate: shift.startDate.toDate(),
        endDate: shift.endDate.toDate(),
      });
    }

    return showStaffMember;
  });

  return Boolean(result);
};

export const staffMemberIsAvailableToWorkTheShiftWithDetails = (
  staffMember: StaffMember,
  fixedAvailabilities: FixedAvailability[],
  business: TangoBusiness,
  scheduleToUse: TangoSchedule | null | undefined,
  shift: TangoShift
) => {
  const shiftStartTime = moment(shift.startDate.toDate());
  const shiftEndTime = moment(shift.endDate.toDate());
  const staffMemberAvailability = fixedAvailabilities.find((a) => {
    const availabilityBelongsToSelectedStaffMember =
      a.staffId === staffMember.id;
    return availabilityBelongsToSelectedStaffMember;
  });
  if (!staffMemberAvailability) {
    return { available: false, message: "No availability found" };
  }
  const availabilityShiftDay = staffMemberAvailability.schedule.find(
    (sh) => sh.day === moment.weekdays()[shiftStartTime.day()]
  );
  if (!availabilityShiftDay) {
    return {
      available: false,
      message: `${staffMember.contact.firstName} is not available on this day`,
    };
  }

  const staffAndShiftRequiredPositionAligned =
    staffMember.primaryRole === shift.position ||
    staffMember.secondaryRoles.includes(shift.position);

  if (!staffAndShiftRequiredPositionAligned) {
    return {
      available: false,
      message: `${staffMember.contact.firstName} does not have the required role`,
    };
  }

  const staffMemberIsWorkingOverlappingShift = scheduleToUse?.shifts.find(
    (sh) => {
      const compareShiftStart = moment(sh.startDate?.toDate());
      const compareShiftEnd = moment(sh.endDate?.toDate());
      const overlaps =
        (compareShiftStart.isSameOrAfter(shiftStartTime) &&
          compareShiftStart.isSameOrBefore(shiftEndTime)) ||
        (compareShiftEnd.isAfter(shiftStartTime) &&
          compareShiftEnd.isSameOrBefore(shiftEndTime)) ||
        (shiftStartTime.isAfter(compareShiftStart) &&
          shiftStartTime.isBefore(compareShiftEnd)) ||
        (shiftEndTime.isSameOrAfter(compareShiftStart) &&
          shiftEndTime.isSameOrBefore(compareShiftStart));
      return sh.id !== shift.id && sh.staffId === staffMember.id && overlaps;
    }
  );

  if (staffMemberIsWorkingOverlappingShift) {
    return {
      available: false,
      message: `${staffMember.contact.firstName} is already working a shift at this time`,
    };
  }

  const shiftIsAlignedWithAvailability =
    startTimeAndEndTimeOfTheShiftIsWithinStaffMemberAvailability(
      shift,
      availabilityShiftDay,
      business,
      staffMember,
      staffMemberAvailability
    );

  if (!shiftIsAlignedWithAvailability) {
    return {
      available: false,
      message: `${staffMember.contact.firstName} is not available at this time`,
    };
  }

  const staffMemberIsNotAlreadyWorkingThisShift =
    shift.staffId !== staffMember.id;

  if (!staffMemberIsNotAlreadyWorkingThisShift) {
    return {
      available: false,
      message: `${staffMember.contact.firstName} is already working this shift`,
    };
  }

  return {
    available: true,
    message: `${staffMember.contact.firstName} is available to work this shift`,
  };
};
