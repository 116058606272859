import React, { useMemo, useRef } from 'react'
import UIComponents from "@tangopay/tango-ui-library";
import Creatable from 'react-select/creatable';
import { OptionProps, components } from 'react-select';
import useCreateShift, { EditShiftypeProp, Roles, ShiftType } from './useCreateShift';
import moment from 'moment';
import _ from 'lodash';
import { updateRoles } from 'controllers/team';
import { getScheduleForWeekRange } from 'controllers/schedule';
import { createShiftType } from 'controllers/staff';

const { Modal, Dropdown, TimePickerInput, Button, Icon, Popover, Checkbox } = UIComponents;
interface Props {
  isOpen: boolean;
  shiftTypes: ShiftType[];
  closeModal: () => void;
  jobFunctions: TangoBusinessSettings['jobFunctions'];
  schedulesWithLocalSchedule: TangoSchedule[];
  weekRange: Date[];
  business: TangoBusiness;
  selectedDepartment: "boh" | "foh" | undefined;
  editData?: EditShiftypeProp | null;
  fohScheduleForAWeekRange: TangoSchedule | undefined;
  bohScheduleForAWeekRange: TangoSchedule | undefined;
}


const CreateShiftModal = ({ isOpen, closeModal, shiftTypes, jobFunctions, schedulesWithLocalSchedule, weekRange, business, selectedDepartment, editData, fohScheduleForAWeekRange, bohScheduleForAWeekRange }: Props) => {

  const {
    shiftType,
    setShiftType,
    showColorPopover,
    setShowColorPopover,
    days,
    shiftStart,
    shiftEnd,
    setShiftStart,
    setShiftEnd,
    selectedDays,
    updateSelectedDays,
    roles,
    updateRoles,
    addNewRole,
    deleteRole,
    quantityData,
    saveShift,
    updateMultipleShifts,
    showShiftTypeDropdown,
    setShowShiftTypeDropdown,
    shiftTypeColor,
    setShiftTypeColor,
    colors,
    clearState,
  } = useCreateShift(fohScheduleForAWeekRange, bohScheduleForAWeekRange, jobFunctions, editData,);
  const jobRoles = useMemo(() => {
    return _.values(jobFunctions)
      //@ts-ignore
      .filter((jf) => !jf?.deleted).map((item) => {
        return { value: item.id, label: item.title }
      })
  }, [jobFunctions])
  const selectRef = useRef<Creatable<ShiftType>>(null);
  return (
    <Modal isOpen={isOpen} closeModal={closeModal} width="720" onAfterClose={() => clearState()}>
      {
        editData ?
          <div className='flex pl-10 items-center pb-6 border-b border-solid border-grey-1 border-l-0 border-r-0 border-t-0 mb-10 -mx-10'>
            <div className='text-big text-black font-lato-black '>
              Shift Details
            </div>
            <div className='h-6 bg-grey-1 w-px mx-3' />
            <div className=' text-xl font-lato-bold text-black'>
              {moment(editData.selectedDate).format('dddd, MMMM D')}
            </div>
          </div>
          :
          <div className={"text-big text-black font-lato-black mb-10"}>
            Create Shift
          </div>
      }
      <div style={{ maxHeight: "60vh", overflowY: "auto" }} className="pb-6">
        <div className='flex mb-10'>
          <div className='w-2/4 pr-3'>
            <div className=' text-base font-lato-black text-black mb-3'>Shift Type</div>
            <div className='relative' onMouseDown={() => setShowShiftTypeDropdown(true)}
              onBlur={(e) => {
                setShowShiftTypeDropdown(false)
              }} >
              <Creatable
                ref={selectRef}
                options={shiftTypes}
                value={shiftType}
                onChange={(item) => {
                  if (item) {
                    setShiftType(item)
                    setShowShiftTypeDropdown(false)
                  }
                }}
                onCreateOption={async (inputValue) => {
                  await createShiftType(
                    business.id,
                    inputValue,
                    shiftTypeColor.mainColor,
                    shiftTypeColor.backgroundColor
                  );
                }}
                menuIsOpen={showShiftTypeDropdown}

                styles={{
                  control: (baseStyles, state) => {
                    const { menuIsOpen } = state.selectProps
                    return ({
                      ...baseStyles,
                      border: '0px',
                      boxShadow: 'none',
                      backgroundColor: menuIsOpen ? "#fff" : "#F5F7FB",
                      borderTopLeftRadius: '24px',
                      borderTopRightRadius: '24px',
                      borderBottomLeftRadius: menuIsOpen ? '0px' : '24px',
                      borderBottomRightRadius: menuIsOpen ? '0px' : '24px'
                    })
                  },
                  option: (baseStyles, state) => {
                    return ({
                      ...baseStyles,
                      backgroundColor: state.isFocused ? '#F8FAFD' : 'transparent'
                    })
                  },
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    marginTop: '-1px',
                    boxShadow: 'none',
                    borderBottomLeftRadius: '24px',
                    borderBottomRightRadius: '24px',
                    borderTop: '1px solid #DBDDE0',

                  }),
                  container: (baseStyles, state) => {
                    const { menuIsOpen } = state.selectProps;
                    return ({
                      ...baseStyles,
                      zIndex: '100',
                      filter: menuIsOpen ? 'drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.03)) drop-shadow(3px 4px 15px rgba(0, 0, 0, 0.12))' : '',
                    })
                  },
                  valueContainer: (baseStyles, state) => ({
                    padding: '3px 12px'
                  }),
                  input: (baseStyles, state) => {
                    return ({
                      ...baseStyles,
                      //@ts-ignore
                      backgroundColor: state.value.length ? shiftTypeColor.backgroundColor : "transparent",
                      //@ts-ignore
                      borderWidth: state.value.length ? '1px' : "0px",
                      borderColor: shiftTypeColor.mainColor,
                      //@ts-ignore
                      padding: state.value.length ? '4px 16px' : "0px",
                      borderRadius: '100px',
                      borderStyle: "solid",
                    })
                  }
                }}
                components={{
                  SingleValue: (props) => {
                    const { label, borderColor, color, value } = props.data

                    return (
                      <components.SingleValue {...props}>
                        <span style={{ backgroundColor: color, border: `1px solid ${borderColor}`, width: 'fit-content' }} className=" flex py-1 px-4 rounded-full font-lato-bold text-xs text-black">{props.data.label}</span>
                      </components.SingleValue>
                    )
                  },
                  Option: (props) => {
                    const { label, borderColor, color, value } = props.data
                    return (
                      <components.Option {...props}>
                        <div className="cursor-pointer">
                          <span style={{ backgroundColor: color, border: `1px solid ${borderColor}`, width: 'fit-content' }} className=" flex py-1 px-4 rounded-full font-lato-bold text-xs text-black">{props.data.label}</span>

                        </div>
                      </components.Option>

                    );
                  },
                  MenuList: (props) => (
                    <div {...props}>
                      <div className='pl-4 pt-4 pb-4 text-veryTiny font-lato-medium text-black'>Select an option or create one</div>
                      {props.children}
                    </div>
                  ),
                  IndicatorSeparator: null,
                  DropdownIndicator: (props) => {
                    const { menuIsOpen } = props.selectProps;
                    return (
                      <Popover isOpen={showColorPopover}
                        reposition={false}
                        positions={["bottom"]}
                        containerClassName="z-[100]"
                        onClickOutside={() => {
                          setShowColorPopover(false)
                          selectRef.current?.focus()
                        }}
                        content={
                          <div className='p-2 bg-white shadow-card rounded'>
                            <Checkbox onChange={() => setShiftTypeColor(colors[0])} checked={shiftTypeColor.name === 'pink' ? true : false} type="large" colorType="pink" className='mr-2' />
                            <Checkbox onChange={() => setShiftTypeColor(colors[1])} checked={shiftTypeColor.name === 'orange' ? true : false} type="large" colorType="orange" className='mr-2' />
                            <Checkbox onChange={() => setShiftTypeColor(colors[2])} checked={shiftTypeColor.name === 'yellow' ? true : false} type="large" colorType="yellow" className='mr-2' />
                            <Checkbox onChange={() => setShiftTypeColor(colors[3])} checked={shiftTypeColor.name === 'green' ? true : false} type="large" colorType="green" className='mr-2' />
                            <Checkbox onChange={() => setShiftTypeColor(colors[4])} checked={shiftTypeColor.name === 'blue' ? true : false} type="large" colorType="blue" className='mr-2' />
                            <Checkbox onChange={() => setShiftTypeColor(colors[5])} checked={shiftTypeColor.name === 'purple' ? true : false} type="large" colorType="purple" />
                          </div>
                        }>
                        <div className='pr-3 pt-2 relative' {...props}>
                          {
                            menuIsOpen ?
                              <Icon name="more" onClick={() => setShowColorPopover(true)} size='16' />
                              :
                              <Icon name="chevron-down" size='16' />

                          }
                        </div>
                      </Popover>
                    )
                  },
                  Control: (props) => {

                    return (
                      <components.Control {...props}  >
                        {props.children}
                      </components.Control>
                    )
                  },
                }}
              />


            </div>

          </div>
          <div className='w-1/4 mr-3'>
            <div className=' text-base font-lato-black text-black mb-3'>Start Time</div>
            <TimePickerInput size='large' onChange={(date) => setShiftStart(moment(date).format('HH:mm'))} value={moment(shiftStart, 'HH:mm').toDate()} />
          </div>
          <div className='w-1/4'>
            <div className=' text-base font-lato-black text-black mb-3' >End Time</div>
            <TimePickerInput size='large' onChange={(date) => setShiftEnd(moment(date).format('HH:mm'))} value={moment(shiftEnd, "HH:mm").toDate()} />
          </div>
        </div>
        {
          !editData ? <div className='mb-10'>
            <div className=' text-base font-lato-black text-black mb-3'>Shift Days</div>
            <div className='flex flex-wrap'>
              {
                days.map((day, index) => {
                  const isSelected = selectedDays.includes(day)
                  return (
                    <Button
                      onClick={() => updateSelectedDays(day)}
                      label={day}
                      key={index}
                      className={`${isSelected ? '!bg-black !text-white !border-black' : ' border-grey-1 text-grey-2'} mb-2 mr-2 border-solid`}
                      type="btn-style-outline"
                      size="btn-medium"
                      rightIconColor={`${isSelected ? '#fff' : '#424452'}`}
                      rightIcon={`${isSelected ? "checkmark" : 'placeholder'}`} />
                  )
                })
              }
            </div>

          </div>
            :
            null

        }

        <div>
          <div className=' text-base font-lato-black text-black mb-3'>Roles</div>
          {
            roles.map((role, index) => (
              <div className='flex flex-wrap justify-between mb-3' key={index}>

                <Dropdown size='medium'
                  menuPortalTarget={document.body}
                  options={quantityData}
                  styles={{
                    menuPortal: (base, state) => ({
                      ...base,
                      zIndex: '1000 !important',
                    })
                  }}
                  value={{ label: role.quantity, value: role.quantity }}
                  onChange={(item) => {
                    if (item) {
                      //@ts-ignore
                      updateRoles(index, item.value, role.jobfunction)
                    }
                  }}
                />

                <div className='flex flex-1 mx-3 '>
                  <Dropdown size='medium'
                    menuPortalTarget={document.body}

                    options={jobRoles}
                    styles={{
                      menuPortal: (base, state) => ({
                        ...base,
                        zIndex: '1000 !important',
                      })
                    }}
                    className="w-full"
                    value={role.jobfunction.length > 0 ? { value: role.jobfunction, label: jobFunctions[role.jobfunction]['title'] } : null}
                    onChange={(item) => {
                      if (item) {
                        //@ts-ignore
                        updateRoles(index, role.quantity, item.value)
                      }
                    }}

                  />
                </div>
                <Button onClick={() => deleteRole(index, editData ? true : false)} className='text-error-red hover:text-error-red-hover' type="btn-style-minimal" size="btn-medium" label='Delete' />
              </div>
            ))
          }

          <div className='mt-3' onClick={() => addNewRole()}>
            <Icon name="plus" size='12' />
            <span className='text-xs text-black font-lato-bold ml-2'>Add Job Role</span>
          </div>
          <div className='mt-9 flex'>
            <Button label='Cancel' onClick={() => closeModal()} className='w-full mr-4 justify-center' type="btn-style-1" size="btn-medium" />
            <Button disabled={!roles?.filter(r => r.jobfunction && r.quantity)?.length || !days?.length || !shiftType} label={`${editData ? 'Save Shift' : 'Create Shift'}`} onClick={() => {
              if (editData) {
                updateMultipleShifts(business.id, closeModal, weekRange);
              } else if (fohScheduleForAWeekRange && bohScheduleForAWeekRange) {
                console.log("here")
                saveShift(weekRange, business.id, closeModal, { fohScheduleId: fohScheduleForAWeekRange.id, bohScheduleId: bohScheduleForAWeekRange.id })
              } else {
                saveShift(weekRange, business.id, closeModal)
              }

            }} className='w-full justify-center' type="btn-style-1" size="btn-medium" />
          </div>
        </div>
      </div>

    </Modal>
  )
};

export default CreateShiftModal;