import axios from "axios";

import { baseUrl } from "./core";
import { generateBearerToken } from "./init";

const apiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/inventory`,
});
  
apiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});
export const fetchWasteReport = async (
    businessId: string,
    startDate: number,
    endDate: number,
): Promise<Blob> => {
  if (!businessId) throw 'Business ID is required';
  const r = await apiClient.get<unknown>(`${businessId}/reports/waste`, {
    responseType: 'blob',
    params: {
      startDate,
      endDate,
    } 
  });
  return r.data as Blob;
};
export const fetchUsageValuesReport = async (
    businessId: string,
    startDate: number,
    endDate: number,
): Promise<Blob> => {
  if (!businessId) throw 'Business ID is required';
  const r = await apiClient.get<unknown>(`${businessId}/reports/usage`, {
    responseType: 'blob',
    params: {
      startDate,
      endDate,
    } 
  });
  return r.data as Blob;
};
export const fetchUsageCountsReport = async (
    businessId: string,
    startDate: number,
    endDate: number,
): Promise<Blob> => {
  if (!businessId) throw 'Business ID is required';
  const r = await apiClient.get<unknown>(`${businessId}/reports/usageCounts`, {
    responseType: 'blob',
    params: {
      startDate,
      endDate,
    } 
  });
  return r.data as Blob;
};
export const fetchTheoreticalUsageReport = async (
    businessId: string,
    startDate: number,
    endDate: number,
): Promise<Blob> => {
  if (!businessId) throw 'Business ID is required';
  const r = await apiClient.get<unknown>(`${businessId}/reports/theoreticalUsage`, {
    responseType: 'blob',
    params: {
      startDate,
      endDate,
    } 
  });
  return r.data as Blob;
};
export const fetchTop25Report = async (
    businessId: string,
    startDate: number,
    endDate: number,
): Promise<Blob> => {
  if (!businessId) throw 'Business ID is required';
  const r = await apiClient.get<unknown>(`${businessId}/reports/top25`, {
    responseType: 'blob',
    params: {
      startDate,
      endDate,
    } 
  });
  return r.data as Blob;
};
export const fetchBottom10Report = async (
    businessId: string,
    startDate: number,
    endDate: number,
): Promise<Blob> => {
  if (!businessId) throw 'Business ID is required';
  const r = await apiClient.get<unknown>(`${businessId}/reports/bottom10`, {
    responseType: 'blob',
    params: {
      startDate,
      endDate,
    } 
  });
  return r.data as Blob;
};
export const fetchTop25Bottom10Report = async (
    businessId: string,
    startDate: number,
    endDate: number,
): Promise<Blob> => {
  if (!businessId) throw 'Business ID is required';
  const r = await apiClient.get<unknown>(`${businessId}/reports/top25bottom10`, {
    responseType: 'blob',
    params: {
      startDate,
      endDate,
    } 
  });
  return r.data as Blob;
};
export const fetchInventoryReport = async (
    businessId: string,
): Promise<Blob> => {
  if (!businessId) throw 'Business ID is required';
  const r = await apiClient.get<unknown>(`${businessId}/reports/inventory`, {
    responseType: 'blob',
  });
  return r.data as Blob;
};

export const fetchSalesMixByStoreReport = async (
    businessId: string,
    startDate: number,
    endDate: number,
): Promise<Blob> => {
  if (!businessId) throw 'Business ID is required';
  const r = await apiClient.get<unknown>(`${businessId}/reports/salesMixByStore`, {
    responseType: 'blob',
    params: {
      startDate,
      endDate,
    } 
  });
  return r.data as Blob;
};
export const fetchSalesMixByProductReport = async (
    businessId: string,
    startDate: number,
    endDate: number,
): Promise<Blob> => {
  if (!businessId) throw 'Business ID is required';
  const r = await apiClient.get<unknown>(`${businessId}/reports/salesMixByProduct`, {
    responseType: 'blob',
    params: {
      startDate,
      endDate,
    } 
  });
  return r.data as Blob;
};
export const fetchMenuEngineeringReport = async (
    businessId: string,
    startDate: number,
    endDate: number,
): Promise<Blob> => {
  if (!businessId) throw 'Business ID is required';
  const r = await apiClient.get<unknown>(`${businessId}/reports/menuEngineering`, {
    responseType: 'blob',
    params: {
      startDate,
      endDate,
    } 
  });
  return r.data as Blob;
};
export const fetchInvoicesReport = async (
    businessId: string,
    startDate: number,
    endDate: number,
): Promise<Blob> => {
  if (!businessId) throw 'Business ID is required';
  const r = await apiClient.get<unknown>(`${businessId}/reports/invoices`, {
    responseType: 'blob',
    params: {
      startDate,
      endDate,
    } 
  });
  return r.data as Blob;
};