import { baseUrl } from './core';
import { generateBearerToken } from './init';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

// const testURl = 'http://localhost:9001/tango-2/us-central1';
const payoutsApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/payouts`,
});

payoutsApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

export interface FlexCashEvents {
  businessId: string;
  completed: boolean;
  createdAt: any;
  deleted: boolean;
  endingCash: number;
  id: string;
  payouts: Payouts[];
  refunds: any[];
  sales: any[];
  staffId: string;
  startingCash: number;
  updatedAt: any;
}

export interface Payouts {
  payoutAmount: number;
  payoutReason: string;
}
export interface PayoutDTO {
  id: string;
  amount: number;
  staff: string;
  reason: string;
  type: 'Paid In' | 'Paid Out';
}

export interface CashEventStaffMember {
  id: string;
  contact: {
    firstName: string;
    lastName: string;
  };
}
export interface FlexCashEventsWithStaff {
  cashEvent: FlexCashEvents;
  staffMember: CashEventStaffMember | null;
}

export const fetchStaffMembers = async (businessId: string) => {
  if (!businessId) return null;
  const res = await payoutsApiClient.get<CashEventStaffMember[]>(`${businessId}/staffMembers/fetch`);
  return res.data;
};

export const useStaffMembers = (businessId: string) => {
  return useQuery({
    queryKey: ['staffInfo', businessId],
    queryFn: () => fetchStaffMembers(businessId),
    keepPreviousData: true,
    retry: 2,
  });
};

export const fetchPayouts = async (businessId: string, payoutId: string) => {
  if (!businessId) return null;
  const res = await payoutsApiClient.get<FlexCashEventsWithStaff[]>(`${businessId}/${payoutId}`);
  return res.data;
};

export const usePayouts = (businessId: string, payoutId: string) => {
  return useQuery({
    queryKey: ['payoutsInfo', businessId, payoutId],
    queryFn: () => fetchPayouts(businessId, payoutId),
    keepPreviousData: true,
    retry: 2,
  });
};

const setPayouts = async (businessId: string, newPayouts: any[]) => {
  if (!businessId) return null;
  const r = await payoutsApiClient.post(`${businessId}/newPayout`, newPayouts);
  return r.data;
};

export const useSetPayouts = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: { newPayouts: any[] }) => setPayouts(businessId, args.newPayouts),
    onSuccess: (data) => {
      client.setQueryData(['payoutsInfo', businessId, 'start'], data);
    },
  });
};
