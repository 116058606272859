import React, { useCallback, useMemo, useState } from 'react';
import UIComponents from '@tangopay/tango-ui-library';
import DayPicker, { DayModifiers } from "react-day-picker";
import "react-day-picker/lib/style.css";
import moment from 'moment-timezone';
import _ from 'lodash'
import { useSelector } from "react-redux";
import { RootState } from 'model/store';
import { getArrayOfDatesStartingFromADate, getWeekDays, getWeekRange } from "utils/manager";

import {
  duplicateScheduleToWeekRanges,
  duplicateScheduleUntilACertainDate,
} from "controllers/schedule";
const { Modal, Button } = UIComponents;
import { Icon, Spinner, SpinnerSize } from "@blueprintjs/core";
import { generateWeekRangeForSelectedDate } from 'views/manager/dailyLogs/dailyLogsFunctions';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  schedule: TangoSchedule;
  fohScheduleForAWeekRange: TangoSchedule | undefined;
  bohScheduleForAWeekRange: TangoSchedule | undefined;
}


const NavBar = ({
  nextMonth,
  previousMonth,
  onPreviousClick,
  onNextClick,
  className,
  localeUtils,
}: any) => {
  const months = localeUtils.getMonths();
  const prev = months[previousMonth.getMonth()];
  const next = months[nextMonth.getMonth()];
  return (
    <div>
      <Icon className="absolute right-10 top-3 cursor-pointer" color="#424452" icon="chevron-left" iconSize={16} onClick={() => onPreviousClick()} />
      <Icon className="absolute right-2 top-3 cursor-pointer" color="#424452" icon="chevron-right" iconSize={16} onClick={() => onNextClick()} />
    </div>
  );
};

const DuplicateSchedule = ({ isOpen, closeModal, schedule, bohScheduleForAWeekRange, fohScheduleForAWeekRange }: Props) => {
  const [weekRange, setWeekRange] = useState<Date[]>([]);
  const [selectedWeekRanges, setSelectedWeekRanges] = useState<{ startDate: Date; endDate: Date; range: Date[]; }[]>([]);
  const [withAssignments, setWithAssignments] = useState(false);
  const [loading, setLoading] = useState(false)

  const selectedDays = useMemo(() => {
    return _.flatten(selectedWeekRanges.map(swr => swr.range))
  }, [selectedWeekRanges])

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const onDayPress = useCallback((day: Date, modifier: DayModifiers) => {
    if (!modifier.disabled) {
      const weekRange = generateWeekRangeForSelectedDate(business, day)
      const startDate = weekRange[0]
      const endDate = weekRange[weekRange.length - 1]
      const alreadySelectedRange = selectedWeekRanges.some(({ startDate: selectedStartDate }) => moment(startDate).isSame(moment(selectedStartDate), "date"))
      console.log("alreadySelectedRange", alreadySelectedRange)
      if (alreadySelectedRange) {
        setSelectedWeekRanges(selectedWeekRanges.filter(({ startDate: selectedStartDate, endDate: selectedEndDate }) => !moment(startDate).isSame(moment(selectedStartDate), "date")))
      } else {
        setSelectedWeekRanges([...selectedWeekRanges, { startDate, endDate, range: weekRange }])
      }
    }
  }, [setSelectedWeekRanges, selectedWeekRanges, business])


  const duplicateScheduleHandler = useCallback(async () => {
    if (
      business &&
      fohScheduleForAWeekRange &&
      bohScheduleForAWeekRange && selectedWeekRanges.length
    ) {
      setLoading(true)
      await duplicateScheduleToWeekRanges(
        business.id,
        fohScheduleForAWeekRange.id,
        bohScheduleForAWeekRange.id,
        selectedWeekRanges.map(wr => wr.startDate),
        withAssignments
      );
      setLoading(false)
      clearState();
      closeModal();
    }
  }, [
    business,
    selectedWeekRanges,
    withAssignments,
    fohScheduleForAWeekRange,
    bohScheduleForAWeekRange,
  ]);
  const clearState = useCallback(() => {
    setWithAssignments(false);
    setWeekRange([]);
    setSelectedWeekRanges([])
  }, [])

  const firstDayOfTheWeek = useMemo(() => {
    return moment.weekdays().indexOf(business?.payrollStartOfTheWeek) ?? 1
  }, [business?.payrollStartOfTheWeek])

  return (
    <Modal
      isOpen={isOpen}
      closeModal={() => {
        clearState()
        closeModal()
      }}
      width="580"
    >
      <div className=' text-2xl text-black font-lato-black '>
        Duplicate Schedule?
      </div>
      <div className='flex mt-10'>
        <Button
          onClick={() => setWithAssignments(false)}
          label={"Duplicate Roles"}
          className={`${!withAssignments ? 'bg-black text-white border-black' : ' border-grey-1 text-grey-2'} mb-2 mr-2 border-solid`}
          type="btn-style-outline"
          size="btn-medium"
          rightIconColor={`${!withAssignments ? '#fff' : '#424452'}`}
          rightIcon={`${!withAssignments ? "checkmark" : 'placeholder'}`} />
        <Button
          onClick={() => setWithAssignments(true)}
          label={"Duplicate Roles and Assignees"}
          className={`${withAssignments ? 'bg-black text-white border-black' : ' border-grey-1 text-grey-2'} mb-2 mr-2 border-solid`}
          type="btn-style-outline"
          size="btn-medium"
          rightIconColor={`${withAssignments ? '#fff' : '#424452'}`}
          rightIcon={`${withAssignments ? "checkmark" : 'placeholder'}`} />
      </div>
      <div className='rounded-2xl shadow-card mx-auto mt-9 px-5' style={{ width: '400px' }}>
        <DayPicker
          firstDayOfWeek={firstDayOfTheWeek}
          month={schedule?.startDate?.toDate() || undefined}
          className="Selectable-Green"
          selectedDays={selectedDays}
          onDayClick={onDayPress}
          navbarElement={<NavBar />}
          modifiers={{ startDate: selectedDays[0], endDate: selectedDays[selectedDays.length - 1] }}
          enableOutsideDaysClick
          disabledDays={{
            before: schedule && schedule.startDate ? moment(schedule.endDate.toDate()).add(1, "day").toDate() : new Date(),
          }}

        />
      </div>
      <div className='mt-9 flex'>
        <Button label='Cancel' onClick={() => {
          clearState()
          closeModal()
        }} className='w-full mr-4 justify-center' type="btn-style-1" size="btn-medium" />
        {
          loading ? (
            <Spinner className='w-full justify-center' size={SpinnerSize.SMALL} />
          ) : (
            <Button label='Confirm' onClick={duplicateScheduleHandler} className='w-full justify-center' type="btn-style-1" size="btn-medium" />
          )
        }
      </div>
    </Modal>
  )
};
export default DuplicateSchedule;