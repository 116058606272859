import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "model/store";

export const useRequestsStats = () => {
  const scheduleEvents: ScheduleEvent[] = useSelector(
    (state: RootState) => state.scheduleEvents
  );
  const publishedSchedules: TangoSchedule[] = useSelector(
    (state: RootState) => state.schedules
  );
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const availabilityRequests = useSelector(
    (state: RootState) => state.fixedAvailabilities
  );
  const amountOfPendingAvailabilityRequests = useMemo(() => {
    return availabilityRequests.filter(
      (request) => request.status === "pending"
    ).length;
  }, [availabilityRequests]);

  const amountOfPendingTradeRequests = useMemo(() => {
    return scheduleEvents
      .filter(
        (event) => event.eventType === "trade" && event.status === "pending"
      )
      .filter((to: ScheduleEvent) => {
        const senderStaff = fellowStaffMembers.find(
          (sm) => sm?.id === to.senderStaff?.id
        );
        const receiverStaff = fellowStaffMembers.find(
          (sm) => sm?.id === to.receiverStaff?.id
        );
        if (!senderStaff || !receiverStaff) return false;

        const sendingScheduleId = to.sendingScheduleId;
        const sendingShiftId = to.sendingShiftId;
        const receivingScheduleId = to.receivingScheduleId;
        const receivingShiftId = to.receivingShiftId;
        const sendingSchedule = publishedSchedules.find(
          (sch) => sch.id === sendingScheduleId
        );
        const receivingSchedule = publishedSchedules.find(
          (sch) => sch.id === receivingScheduleId
        );
        if (!sendingSchedule || !receivingSchedule) return null;
        const sendingShift = sendingSchedule.shifts.find(
          (sh) => sh.id === sendingShiftId
        );
        const receivingShift = receivingSchedule.shifts.find(
          (sh) => sh.id === receivingShiftId
        );
        if (!sendingShift || !receivingShift) return false;

        return true;
      }).length;
  }, [scheduleEvents, publishedSchedules, fellowStaffMembers]);

  const amountOfPendingCoverRequests = useMemo(() => {
    return scheduleEvents
      .filter(
        (event) => event.eventType === "cover" && event.status === "pending"
      )
      .filter((to) => {
        const senderStaff = fellowStaffMembers.find(
          (sm) => sm?.id === to.senderStaff?.id
        );
        const receiverStaff = fellowStaffMembers.find(
          (sm) => sm?.id === to.receiverStaff?.id
        );

        if (!senderStaff || !receiverStaff) return false;

        const sendingScheduleId = to.sendingScheduleId;
        const sendingShiftId = to.sendingShiftId;

        const sendingSchedule = publishedSchedules.find(
          (sch) => sch.id === sendingScheduleId
        );
        if (!sendingSchedule) return false;
        const sendingShift = sendingSchedule.shifts.find(
          (sh) => sh.id === sendingShiftId
        );
        if (!sendingShift) return false;
        return true;
      }).length;
  }, [scheduleEvents, publishedSchedules, fellowStaffMembers]);

  const amountOfPendingDropRequests = useMemo(() => {
    return scheduleEvents
      .filter(
        (event) => event.eventType === "drop" && event.status === "pending"
      )
      .filter((to: ScheduleEvent) => {
        const senderStaff = fellowStaffMembers.find(
          (sm) => sm?.id === to.senderStaff?.id
        );

        if (!senderStaff) return false;

        const sendingScheduleId = to.sendingScheduleId;
        const sendingShiftId = to.sendingShiftId;

        const sendingSchedule = publishedSchedules.find(
          (sch) => sch.id === sendingScheduleId
        );
        if (!sendingSchedule) return false;
        const sendingShift = sendingSchedule.shifts.find(
          (sh) => sh.id === sendingShiftId
        );
        if (!sendingShift) return false;
        return true;
      }).length;
  }, [scheduleEvents, publishedSchedules, fellowStaffMembers]);

  const amountOfPendingTimeOffRequests = useMemo(() => {
    return scheduleEvents
      .filter(
        (event) => event.eventType === "time_off" && event.status === "pending"
      )
      .filter((to) => to.timeOffRequestStartDate && to.timeOffRequestEndDate)
      .filter((to: ScheduleEvent) => {
        const senderStaff = fellowStaffMembers.find(
          (sm) => sm?.id === to.senderStaff?.id
        );
        if (!senderStaff) return null;

        return true;
      }).length;
  }, [scheduleEvents, fellowStaffMembers]);

  const totalAmountOfPendingRequests = useMemo(() => {
    return (
      amountOfPendingAvailabilityRequests +
      amountOfPendingTradeRequests +
      amountOfPendingCoverRequests +
      amountOfPendingDropRequests +
      amountOfPendingTimeOffRequests
    );
  }, [
    amountOfPendingAvailabilityRequests,
    amountOfPendingTradeRequests,
    amountOfPendingCoverRequests,
    amountOfPendingDropRequests,
    amountOfPendingTimeOffRequests,
  ]);

  return {
    amountOfPendingAvailabilityRequests,
    amountOfPendingTradeRequests,
    amountOfPendingCoverRequests,
    amountOfPendingDropRequests,
    amountOfPendingTimeOffRequests,
    totalAmountOfPendingRequests,
  };
};
