import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import moment from "moment-timezone";
import UIComponents from "@tangopay/tango-ui-library";
import { useState } from "react";
import { approveRequest, denyRequest } from "controllers/scheduleEvents";
const { Dropdown } = UIComponents
export type ItemProps = {
  uniqueId: string;
  status: string | null;
  requestedDates: {
    startDate: Date;
    endDate: Date;
  };
  employee: string;
  reason: string;
  createdAt: Date;
};

export type SettingsTableItemProp = {
  uniqueId: string;
  role: string;
  mon: number;
  tues: number;
  wed: number;
  thurs: number;
  fri: number;
  sat: number;
  sun: number;
}
export const instructions: { [x: string]: RenderInstruction<ItemProps> } = {};
instructions.status = {
  type: "complex-custom",
  viewComponent: (props) => {
    const { status, uniqueId } = props.fullObject;
    if (status === "Pending") {
      return (
        <div className="w-24">
          <Dropdown
            placeholder="Respond"
            options={[
              {
                label: "Approve",
                value: "approve"
              },
              {
                label: "Deny",
                value: "deny"
              }
            ]
            }
            onChange={async (e: any) => {
              console.log("e", e)
              if (e?.value === "approve") {
                await approveRequest(uniqueId)
              } else if (e?.value === "deny") {
                await denyRequest(uniqueId)
              }
            }}

            className="respond-dropdown"
            size="small"
          />
        </div>

      )
    }
    const getClassesBasedOnStatus = (status: "Approved" | "Pending" | "Denied") => {
      if (status === "Approved") {
        return "border-green-3 bg-green-2"
      }

      return "border-orange-4 bg-orange-2"
    }
    return (
      <div
        //@ts-ignore
        className={`w-24 ${getClassesBasedOnStatus(status ?? "Pending")}
        border border-solid inline-flex py-1 px-6 rounded-full text-tiny font-lato-bold text-black`}

      >

        {props.fullObject.status}
      </div>
    )
  },
  editComponent: null,
};

instructions.requestedDates = {
  type: "custom",
  viewComponent: (props) => {
    const requestedStartAndEndDate = props.value as {
      startDate: Date;
      endDate: Date;
    }
    return (
      <div>{moment(requestedStartAndEndDate.startDate).format("MMM DD hh:mm a")} - {moment(requestedStartAndEndDate.endDate).format("MMM DD hh:mm a")}</div>
    )
  },
  editComponent: null,
}

instructions.createdAt = {
  type: "custom",
  viewComponent: (props) => {

    const createAtDate = props.value as Date
    return (
      <div>{moment(createAtDate).format("MMM DD hh:mm a")}</div>
    )
  },
  editComponent: null,
}


export const settingsInstructions: { [x: string]: RenderInstruction<SettingsTableItemProp> } = {};
