import axios from "axios";
import firebase, { firestore, storage } from "config/firebase";

import { baseUrl } from "./core";
import { generateBearerToken } from "./init";

const tangoBusinessApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/business`,
});

tangoBusinessApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

export const updateBusinessSettings = async (
  businessSettings: Partial<TangoBusinessSettings>,
  businessId: string
) => {
  try {
    await firestore
      .collection("BusinessSettings")
      .doc(businessId)
      .update({ ...businessSettings, updatedAt: new Date() });
    return { data: true };
  } catch (error) {
    //@ts-ignore
    return { error: error.message };
  }
};
