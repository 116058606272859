import { Icon, Popover } from "@blueprintjs/core";
import React, { useCallback, useMemo, useState } from "react";
import DayPicker from "react-day-picker";
import moment from 'moment-timezone'
import "react-day-picker/lib/style.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  clearDraftSchedule,
  clearDuplicateSchedule,
  DraftIdWithDepartmentId,
  ScheduleEventType,
} from "controllers/schedule";
import { DepartmentId } from "controllers/staff";

import Box from "components/Box";

import { AvailabilityMoreAction } from "../Actions/AvailabilityMoreAction";
import { DayPickerDay } from "./DayPickerDay";
import { ScheduleStatus } from "./ShiftViewTypeSidebarContent";
import { SideBarContent } from "./SidebarContent";
import { WeeklyScheduleViewType } from "./WeeklyViewSelect";
import UIComponents from '@tangopay/tango-ui-library';
import DuplicateSchedule from "./DuplicateSchedule";
import WeeklyAlphabeticScheduleReportDocument from "views/BusinessAdmin/Reporting/PdfReports/WeeklyAlphabeticScheduleReport";
import WeeklyRoleGroupedScheduleReportDocument from "views/BusinessAdmin/Reporting/PdfReports/WeeklyRoleGroupedScheduleReportDocument";

import { pdf } from "@react-pdf/renderer";


const { Dropdown, Button, DeleteModal } = UIComponents;
import {
  generateDailyScheduleReportData,
  generateDailyScheduleReportDataWithAMPMSplit,
  generateWeeklyAlphabeticScheduleReport,
  generateWeelyRoleGroupedReport,
} from "controllers/reporting";
import { RootState } from "model/store";
import DailyScheduleReportPdfDocument from "views/BusinessAdmin/Reporting/PdfReports/DailyScheduleReportPdfDocument";
import DailyScheduleReportAMPMSplitPdfDocument from "views/BusinessAdmin/Reporting/PdfReports/DailyScheduleReportAMPMSplitPdfDocument";
import PublishScheduleModal from "./PublishScheduleModal";
import { useRequestsStats } from "./useRequestStats";
import {convertToBusinessTimezoneMoment} from "../../../../utils/dateUtils";
import {getWorkEventsForDateRange} from "../../../../controllers/payroll";

const birthdayStyle = `.DayPicker-Day--highlighted {
  background-color: orange;
  border-radius: 50px;
  color: white;
}`;

const NavBar = ({
  nextMonth,
  previousMonth,
  onPreviousClick,
  onNextClick,
  className,
  localeUtils,
}: any) => {
  const months = localeUtils.getMonths();
  const prev = months[previousMonth.getMonth()];
  const next = months[nextMonth.getMonth()];
  return (
    <div>
      <Icon className="absolute right-8 top-0 cursor-pointer" color="#424452" icon="chevron-left" iconSize={16} onClick={() => onPreviousClick()} />
      <Icon className="absolute right-0 top-0 cursor-pointer" color="#424452" icon="chevron-right" iconSize={16} onClick={() => onNextClick()} />
    </div>
  );
};
const getScheduleStatusDescription = (status: ScheduleStatus) => {
  switch (status) {
    case "published":
      return "This schedule is published";
    case "unpublished_changes":
    case "duplicated":
      return "This schedule currently has unpublished changes";
    case "empty":
      return "Add your first shift to create a schedule";
  }
};
const titleByRequestType = (requestType: ScheduleEventType) => {
  if (requestType === ScheduleEventType.drop) {
    return "Dropped Shifts";
  }
  if (requestType === ScheduleEventType.time_off) {
    return "Time Off Requests";
  }
  if (requestType === ScheduleEventType.trade) {
    return "Trade requests";
  }
  if (requestType === ScheduleEventType.cover) {
    return "Cover requests";
  }
};

interface SideBarNavProps {
  selectedRequestType?: ScheduleEventType;
  isAvailabilityView: boolean;
}

const SideBarNav = ({
  selectedRequestType,
  isAvailabilityView = false,
}: SideBarNavProps) => {
  const navigate = useNavigate();

  if (selectedRequestType) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className="side-nav-item current-menu-item"
      >
        <Icon
          onClick={() => {
            navigate(-1);
          }}
          icon="chevron-left"
          iconSize={14}
        />
        <div>{titleByRequestType(selectedRequestType)}</div>
        <Icon icon="more" iconSize={18} />
      </Box>
    );
  }

  if (isAvailabilityView) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className="side-nav-item current-menu-item"
      >
        <Icon
          onClick={() => {
            navigate(-1);
          }}
          icon="chevron-left"
          iconSize={14}
        />
        <div>Availabilities</div>
        <Popover
          className="more-action-popover"
          modifiers={{ arrow: { enabled: false } }}
          position="bottom-right"
          content={<AvailabilityMoreAction />}
          target={<Icon icon="more" iconSize={18} />}
        />
        <Box />
      </Box>
    );
  }

  return null;
};

const getDayPickerClassName = (selectedRequestType?: ScheduleEventType) => {
  if (selectedRequestType) {
    return "Selectable";
  }
  return "Selectable";
};

interface WeeklySidebarProps {
  weekRange: Date[];
  selectedRequestType?: ScheduleEventType;
  isSelectRequestType: boolean | undefined;
  isAvailabilityView: boolean;
  onCalendarDateClick: (d: Date) => void;
  business: TangoBusiness;
  schedulesWithLocalSchedule: TangoSchedule[];
  fixedSchedules: TangoFixedSchedule[];
  schedules: TangoSchedule[];
  scheduleEvents: ScheduleEvent[];

  setAvailabilityViewFilterAnchorEl: (t: any) => void;
  availabilityViewFilterAnchorEl: any;
  availabilityRoleFilterId: string | null;
  businessSettings: TangoBusinessSettings;
  jobFunctions: TangoJobFunctions;
  onAvailabilityFullNameInputChangeDebounced: (v: any) => void;
  setAvailabilityRoleFilterId: (v: any) => void;
  weeklyScheduleViewType: WeeklyScheduleViewType;
  setWeeklyScheduleViewType: (v: WeeklyScheduleViewType) => void;
  departmentScheduleViewType: DepartmentId | null;
  setDepartmentScheduleViewType: (d: DepartmentId | null) => void;

  scheduleForWeekRange: TangoSchedule | null;
  duplicatedScheduleForAWeekRange: TangoSchedule | null;
  publishScheduleLoading: boolean;
  scheduleStatus: ScheduleStatus;

  setWeeklyStaffMemberViewFilterAnchorEl: (t: any) => void;
  weeklyStaffMemberViewFilterAnchorEl: any;
  onFullNameInputChangeDebounced: (e: any) => void;
  roleFilterId: string | null;
  setRoleFilterId: (v: any) => void;
  draftsWithDepartmentForAWeek: DraftIdWithDepartmentId[];
  bohDraftStatus: ScheduleStatus;
  fohDraftStatus: ScheduleStatus;
  mergedScheduleForAWeekRange: TangoSchedule | undefined;
  fohScheduleForAWeekRange: TangoSchedule | undefined;
  bohScheduleForAWeekRange: TangoSchedule | undefined;
  openCreateShiftModal?: () => void;
  setCurrentDateForDailyShiftView: (date: Date | null) => void;
  currentDateForDailyShiftView: Date | null;
}

export const WeeklySidebar = ({
  weekRange,
  selectedRequestType,
  isSelectRequestType = false,
  isAvailabilityView = false,
  onCalendarDateClick,
  business,
  schedulesWithLocalSchedule,
  fixedSchedules,
  schedules,
  scheduleEvents,
  setAvailabilityViewFilterAnchorEl,
  availabilityViewFilterAnchorEl,
  availabilityRoleFilterId,
  businessSettings,
  jobFunctions,
  onAvailabilityFullNameInputChangeDebounced,
  setAvailabilityRoleFilterId,
  weeklyScheduleViewType,
  setWeeklyScheduleViewType,
  departmentScheduleViewType,
  setDepartmentScheduleViewType,
  scheduleStatus,
  publishScheduleLoading,
  scheduleForWeekRange,
  duplicatedScheduleForAWeekRange,
  setWeeklyStaffMemberViewFilterAnchorEl,
  weeklyStaffMemberViewFilterAnchorEl,
  onFullNameInputChangeDebounced,
  roleFilterId,
  setRoleFilterId,
  draftsWithDepartmentForAWeek,
  bohDraftStatus,
  fohDraftStatus,
  mergedScheduleForAWeekRange,
  fohScheduleForAWeekRange,
  bohScheduleForAWeekRange,
  openCreateShiftModal,
  currentDateForDailyShiftView,
  setCurrentDateForDailyShiftView,
}: WeeklySidebarProps) => {
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDuplicateScheduleModal, setShowDuplicateScheduleModal] = useState(false);
  const [showActionPopover, setShowActionPopover] = useState(false);
  const [showActionDropdown, setShowActionDropdown] = useState(false);
  const [showPublishScheduleModal, setShowPublishScheduleModal] = useState(false);
  const [showRequestsMenuList, setShowRequestsMenuList] = useState(false);

  const firstDayOfTheWeek = useMemo(() => {
    return moment.weekdays().indexOf(business?.payrollStartOfTheWeek) ?? 1
  }, [business?.payrollStartOfTheWeek])


  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const clearWeeksSchedule = useCallback(async () => {
    try {
      if (mergedScheduleForAWeekRange) {
        if (mergedScheduleForAWeekRange.mergedSchedules?.length) {
          await Promise.all(
            mergedScheduleForAWeekRange.mergedSchedules.map((scheduleId) =>
              clearDraftSchedule(scheduleId, business.id)
            )
          );
        } else {
          console.log('else')
          // await clearDraftSchedule(mergedScheduleForAWeekRange.id, business.id);
        }
      } else if (duplicatedScheduleForAWeekRange) {
        console.log('else if')
        // await clearDuplicateSchedule(duplicatedScheduleForAWeekRange);
      }
      setShowConfirmationModal(false);
    } catch (error) {
      console.log(error);
    }
  }, [mergedScheduleForAWeekRange, duplicatedScheduleForAWeekRange])

  const generateAlphabeticReport = useCallback(async () => {
    const scheduleForReport =
      scheduleForWeekRange || duplicatedScheduleForAWeekRange;
    if (!scheduleForReport) return null;
    const reportData = generateWeeklyAlphabeticScheduleReport(
      businessSettings,
      fellowStaffMembers,
      scheduleForReport,
      business
    );

    if (reportData) {
      const blob = await pdf(
        <WeeklyAlphabeticScheduleReportDocument
          report={reportData}
          businessName={business.businessName}
        />
      ).toBlob();
      // setReportIdLoading(null)
      const url = URL.createObjectURL(blob);

      window.open(url, "_blank");
    } else {
      alert("No report data available");
    }
  }, [scheduleForWeekRange, duplicatedScheduleForAWeekRange, businessSettings, fellowStaffMembers, business])


  const generateDepartmentOrientedReport = useCallback(async () => {
    const scheduleForReport =
      scheduleForWeekRange || duplicatedScheduleForAWeekRange;
    if (!scheduleForReport) return null;
    const reportData = generateWeelyRoleGroupedReport(
      businessSettings,
      fellowStaffMembers,
      scheduleForReport,
      business
    );

    if (reportData) {
      const blob = await pdf(
        <WeeklyRoleGroupedScheduleReportDocument
          report={reportData}
          businessName={business.businessName}
        />
      ).toBlob();
      // setReportIdLoading(null)
      const url = URL.createObjectURL(blob);

      window.open(url, "_blank");
    } else {
      alert("No report data available");
    }
  }, [scheduleForWeekRange, duplicatedScheduleForAWeekRange, businessSettings, fellowStaffMembers, business])

  const handActionOptionSelect = useCallback((newValue) => {
    if (newValue) {
      //@ts-ignore
      if (newValue.value === 'clear all shifts') {
        setShowConfirmationModal(true)
        setShowActionDropdown(false)
      }
      //@ts-ignore
      if (newValue.value === 'duplicate schedule') {
        setShowDuplicateScheduleModal(true)
        setShowActionDropdown(false)
      }
      //@ts-ignore
      if (newValue.value === 'download schedule') {
        setShowActionPopover(true);
      }
    }

  }, [])


  const {
    amountOfPendingAvailabilityRequests,
    amountOfPendingTradeRequests,
    amountOfPendingCoverRequests,
    amountOfPendingDropRequests,
    amountOfPendingTimeOffRequests,
    totalAmountOfPendingRequests,
  } = useRequestsStats()

  console.log("currentDateForDailyShiftView", currentDateForDailyShiftView)
  return (
    <div style={{ height: '100%', overflowY: "scroll" }}>
      <SideBarContent
        selectedRequestType={selectedRequestType}
        isSelectRequestType={isSelectRequestType}
        isAvailabilityView={isAvailabilityView}
        scheduleEvents={scheduleEvents}
        setAvailabilityViewFilterAnchorEl={setAvailabilityViewFilterAnchorEl}
        availabilityViewFilterAnchorEl={availabilityViewFilterAnchorEl}
        availabilityRoleFilterId={availabilityRoleFilterId}
        businessSettings={businessSettings}
        jobFunctions={jobFunctions}
        onAvailabilityFullNameInputChangeDebounced={
          onAvailabilityFullNameInputChangeDebounced
        }
        setAvailabilityRoleFilterId={setAvailabilityRoleFilterId}
        weeklyScheduleViewType={weeklyScheduleViewType}
        setWeeklyScheduleViewType={setWeeklyScheduleViewType}
        departmentScheduleViewType={departmentScheduleViewType}
        setDepartmentScheduleViewType={setDepartmentScheduleViewType}
        scheduleStatus={scheduleStatus}
        publishScheduleLoading={publishScheduleLoading}
        scheduleForWeekRange={scheduleForWeekRange}
        duplicatedScheduleForAWeekRange={duplicatedScheduleForAWeekRange}
        weekRange={weekRange}
        schedulesWithLocalSchedule={schedulesWithLocalSchedule}
        setWeeklyStaffMemberViewFilterAnchorEl={
          setWeeklyStaffMemberViewFilterAnchorEl
        }
        weeklyStaffMemberViewFilterAnchorEl={
          weeklyStaffMemberViewFilterAnchorEl
        }
        onFullNameInputChangeDebounced={onFullNameInputChangeDebounced}
        roleFilterId={roleFilterId}
        setRoleFilterId={setRoleFilterId}
        draftsWithDepartmentForAWeek={draftsWithDepartmentForAWeek}
        bohDraftStatus={bohDraftStatus}
        fohDraftStatus={fohDraftStatus}
        mergedScheduleForAWeekRange={mergedScheduleForAWeekRange}
        fohScheduleForAWeekRange={fohScheduleForAWeekRange}
        bohScheduleForAWeekRange={bohScheduleForAWeekRange}
      />
      <div className="py-6 px-3 border-b border-solid border-l-0 border-r-0 border-t-0 border-grey-1 relative " >
        <UIComponents.Popover
          reposition={false}
          positions={["right", "bottom"]}
          containerClassName="z-20"
          onClickOutside={() => setShowActionPopover(false)}
          isOpen={showActionPopover}
          content={
            <div className="bg-white shadow-card w-52 px-5 py-3 rounded-2xl mt-24">
              {
                currentDateForDailyShiftView ?
                  <>
                    <div className="text-tiny font-lato-bold text-grey-2">Daily Schedule Download</div>
                    <ul className="m-0 p-0 list-none">
                      <li className="text-xs font-lato-bold text-black py-3 cursor-pointer" onClick={async () => {
                        try {
                          if (scheduleForWeekRange && currentDateForDailyShiftView) {
                            const reportData = generateDailyScheduleReportData(
                              businessSettings,
                              fellowStaffMembers,
                              currentDateForDailyShiftView,
                              schedulesWithLocalSchedule,
                              business
                            );

                            if (reportData) {
                              const blob = await pdf(
                                <DailyScheduleReportPdfDocument
                                  report={reportData}
                                  businessName={business.businessName}
                                />
                              ).toBlob();
                              // setReportIdLoading(null)
                              const url = URL.createObjectURL(blob);


                              window.open(url, "_blank");
                            } else {
                              alert("No report data available");
                            }
                          }
                          setShowActionPopover(false)
                          setShowActionDropdown(false)
                        } catch (e) {
                          alert("Opps, something went wrong generating your report");
                          console.log("error generating report", e);
                        }
                      }}>
                        Download
                      </li>
                      <li className="text-xs font-lato-bold text-black  py-3 cursor-pointer"
                        onClick={async () => {
                          try {
                            if (scheduleForWeekRange && currentDateForDailyShiftView) {
                              const startDateForReport = convertToBusinessTimezoneMoment(
                                currentDateForDailyShiftView,
                                business
                              )
                                .startOf("day")
                                .add(4, "hours")
                              const numericStartDate = startDateForReport.clone().subtract(1, 'day').date() +
                                100 * (startDateForReport.month() + 1) +
                                10000 * startDateForReport.year();
                              const endDateForReport = moment(startDateForReport).add(1, "day");
                              const numericEndDate = endDateForReport.clone().add(1, 'day').date() +
                                100 * (startDateForReport.month() + 1) +
                                10000 * startDateForReport.year();
                              const workEvents = await getWorkEventsForDateRange(business.id, numericStartDate, numericEndDate)
                              const reportData = generateDailyScheduleReportDataWithAMPMSplit(
                                businessSettings,
                                fellowStaffMembers,
                                currentDateForDailyShiftView,
                                schedulesWithLocalSchedule,
                                business,
                                workEvents
                              );

                              if (reportData) {
                                const blob = await pdf(
                                  <DailyScheduleReportAMPMSplitPdfDocument
                                    report={reportData}
                                    businessName={business.businessName}
                                  />
                                ).toBlob();
                                // setReportIdLoading(null)
                                const url = URL.createObjectURL(blob);

                                window.open(url, "_blank");
                              } else {
                                alert("No report data available");
                              }
                            }
                            setShowActionPopover(false)
                            setShowActionDropdown(false)
                          } catch (e) {
                            alert("Opps, something went wrong generating your report");
                            console.log("error generating report", e);
                          }
                        }}
                      >
                        Download with AM/PM Split
                      </li>
                    </ul>
                  </>
                  :
                  <>
                    <div className="text-tiny font-lato-bold text-grey-2">Weekly Schedule Download</div>
                    <ul className="m-0 p-0 list-none">
                      <li className="text-xs font-lato-bold text-black py-3 cursor-pointer" onClick={() => {
                        generateAlphabeticReport()
                        setShowActionPopover(false)
                        setShowActionDropdown(false)
                      }}>
                        All Staff (Alphabetically)
                      </li>
                      <li className="text-xs font-lato-bold text-black  py-3 cursor-pointer"
                        onClick={() => {
                          generateDepartmentOrientedReport()
                          setShowActionPopover(false)
                          setShowActionDropdown(false)
                        }}
                      >
                        Split by Role
                      </li>
                    </ul>
                  </>
              }

            </div>
          }>
          <div onMouseDown={() => {
            setShowActionDropdown(!showActionDropdown)
          }}
            onBlur={(e) => {
              setShowActionDropdown(false)
            }}>
            <Dropdown
              placeholder="Actions"

              menuIsOpen={showActionDropdown}
              onChange={handActionOptionSelect}
              styles={{
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "#424452 !important"
                })
              }}
              value={{ label: "Actions", value: "actions" }}
              options={[{
                label: "Duplicate Schedule",
                value: "duplicate schedule"
              },
              {
                label: "Download Schedule",
                value: "download schedule"
              },
              {
                label: "Clear All Shifts",
                value: "clear all shifts"
              }
              ]}
              size="medium"
            />
          </div>

        </UIComponents.Popover>

        <Button
          label="Create Shift"
          type="btn-style-2"
          className="w-full justify-center mt-3"
          size="btn-medium"
          onClick={openCreateShiftModal}
        />
        <Button
          label="Publish Changes"
          type="btn-style-2"
          className="w-full justify-center mt-3"
          size="btn-medium"
          onClick={() => setShowPublishScheduleModal(true)}
        />
        {/* <div className="text-tiny text-grey-2 font-lato-medium mt-3">This schedule is published.</div> */}
        <div className={`text-tiny ${bohDraftStatus === 'unpublished_changes' ? 'text-error-red' : 'text-grey-2'} font-lato-medium mt-3`}> BOH: {getScheduleStatusDescription(bohDraftStatus)}</div>
        <div className={`text-tiny ${fohDraftStatus === 'unpublished_changes' ? 'text-error-red' : 'text-grey-2'} font-lato-medium mt-3`}> FOH: {getScheduleStatusDescription(fohDraftStatus)}</div>
      </div>
      <div className="px-6 py-6 border-b border-solid border-l-0 border-t-0 border-r-0 border-grey-1">
        <div
          className="text-xs text-grey-3 font-lato-medium items-center flex justify-between py-2 cursor-pointer"
          onClick={() => setShowRequestsMenuList(!showRequestsMenuList)}
        >
          <div className="flex">All Requests <span className="h-5 w-5 flex items-center justify-center bg-error-red rounded-full text-white text-tiny font-lato-bold text-center ml-4">{totalAmountOfPendingRequests}</span></div>
          <UIComponents.Icon name={`${showRequestsMenuList ? 'chevron-down' : 'chevron-right'}`} size="20" />
        </div>
        {
          showRequestsMenuList ?
            <ul className="m-0 p-0 list-none font-lato-medium text-xs text-grey-3">
              <li
                className="p-2 cursor-pointer flex items-center"
                onClick={() => navigate('/manager/scheduling/select-request-type/availability')}>
                Availabilities
                {amountOfPendingAvailabilityRequests ? (
                  <span className="h-5 w-5 bg-error-red flex items-center justify-center rounded-full text-white text-tiny font-lato-bold text-center ml-3">{amountOfPendingAvailabilityRequests}
                  </span>
                ) : null}</li>
              <li className="p-2 cursor-pointer flex items-center"
                onClick={() => navigate('/manager/scheduling/select-request-type/drop-requests')}>Dropped Shifts {amountOfPendingDropRequests ? (
                  <span className="h-5 w-5 bg-error-red flex items-center justify-center rounded-full text-white text-tiny font-lato-bold text-center ml-3">{amountOfPendingDropRequests}</span>
                ) : null}</li>
              <li className="p-2 cursor-pointer flex items-center"
                onClick={() => navigate('/manager/scheduling/select-request-type/time-off-requests')}>Time Off Requests {amountOfPendingTimeOffRequests ? (<span className="h-5 w-5 bg-error-red flex items-center justify-center rounded-full text-white text-tiny font-lato-bold text-center ml-3">{amountOfPendingTimeOffRequests}</span>) : null}</li>
              <li className="p-2 cursor-pointer flex items-center" onClick={() => navigate('/manager/scheduling/select-request-type/trade-requests')}>Trades Requests {amountOfPendingTradeRequests ? (<span className="h-5 w-5 flex bg-error-red items-center justify-center rounded-full text-white text-tiny font-lato-bold text-center ml-3">{amountOfPendingTradeRequests}</span>) : null}</li>
              <li className="p-2 cursor-pointer flex items-center" onClick={() => navigate('/manager/scheduling/select-request-type/cover-requests')}>Cover Requests {amountOfPendingCoverRequests ? (
                <span className="h-5 w-5 flex bg-error-red items-center justify-center rounded-full text-white text-tiny font-lato-bold text-center ml-3">{amountOfPendingCoverRequests}</span>
              ) : null}</li>
            </ul>
            :
            null
        }

      </div>
      <div className="px-6 py-6 border-b border-solid border-l-0 border-t-0 border-r-0 border-grey-1" onClick={() => navigate('/manager/scheduling/settings')}>
        <div

          className="text-xs text-grey-3 font-lato-medium items-center flex justify-between py-2 cursor-pointer">
          Settings
        </div>
      </div>
      <SideBarNav
        selectedRequestType={selectedRequestType}
        isAvailabilityView={isAvailabilityView}
      />
      <style>{birthdayStyle}</style>
      {isAvailabilityView ? null : (
        <DayPicker
          firstDayOfWeek={firstDayOfTheWeek}
          showOutsideDays
          month={weekRange.length ? weekRange[0] : new Date()}
          className={getDayPickerClassName(selectedRequestType)}
          selectedDays={weekRange}
          renderDay={(day) => (
            <DayPickerDay
              business={business}
              day={day}
              requestType={selectedRequestType ?? null}
              schedulesWithLocalSchedule={schedulesWithLocalSchedule}
              fixedSchedules={fixedSchedules}
              schedules={schedules}
              scheduleEvents={scheduleEvents}
            />
          )}
          onDayClick={onCalendarDateClick}
          modifiers={{
            start: weekRange[0],
            end: weekRange[weekRange.length - 1],
          }}
          navbarElement={<NavBar />}
        />
      )}
      <DeleteModal
        isOpen={showConfirmationModal}
        modalTitle="Delete All Shifts?"
        modalSubtitle=""
        closeModal={() => setShowConfirmationModal(false)}
        onDelete={clearWeeksSchedule}
      />
      <DuplicateSchedule
        isOpen={showDuplicateScheduleModal}
        closeModal={() => setShowDuplicateScheduleModal(false)}
        fohScheduleForAWeekRange={fohScheduleForAWeekRange}
        bohScheduleForAWeekRange={bohScheduleForAWeekRange}
        schedule={scheduleForWeekRange as TangoSchedule}

      />
      <PublishScheduleModal
        isOpen={showPublishScheduleModal}
        closeModal={() => setShowPublishScheduleModal(false)}
        scheduleForWeekRange={scheduleForWeekRange}
        duplicatedScheduleForAWeekRange={duplicatedScheduleForAWeekRange}
        schedule={scheduleForWeekRange}
        schedulesWithLocalSchedule={schedulesWithLocalSchedule}
        draftsWithDepartmentForAWeek={draftsWithDepartmentForAWeek}
        business={business}
      />
    </div>
  );
};
