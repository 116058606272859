import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";

import {
  UpdatePrepItemDTO,
  UpdateRawItemDTO,
  useCreatePrepItem,
  useDeletePrepItem,
  usePrepItemsInfo,
  useUpdatePrepItem,
} from "controllers/inventoryItemInfo";

import {
  ColumnInstruction,
  UpdateState,
} from "components/Table/HorizontalTable";

import { RootState } from "../../../model/store";
import instructions, { ItemProps } from "./instructions";

const columns: ColumnInstruction<ItemProps>[] = [
  { type: "data", header: "Item", attribute: "itemName" },
  { type: "data", header: "Primary Group", attribute: "primaryGroup" },
  { type: "data", header: "Secondary Group", attribute: "secondaryGroup" },
  { type: "data", header: "Ingredient Unit", attribute: "ingredientUnitName" },
  { type: "data", header: "Reporting Unit", attribute: "productionUnitName" },
  { type: "data", header: "Conversion Ratio", attribute: "conversionRatio" },
  { type: "data", header: "Locations", attribute: "locations", width: 2, },
  { type: "projection", header: "Stock Level", attribute: "stockLevel" },
];

const usePrepItems = () => {
  const businessId: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.id;
  const itemsQuery = usePrepItemsInfo(businessId, false);
  const updatePrep = useUpdatePrepItem(businessId);
  const createPrep = useCreatePrepItem(businessId);
  const deletePrep = useDeletePrepItem(businessId);

  const [isEditing, setEditing] = useState(false);
  const prefix = "new__item";

  const [addedIds, updateItems] = useReducer(
    (curr: string[], clear: boolean) => {
      if (clear) return [];
      return [...curr, prefix + curr.length];
    },
    []
  );

  const add = useCallback(() => {
    setEditing(true);
    updateItems(false);
  }, []);

  useEffect(() => {
    if (!isEditing) updateItems(true);
  }, [isEditing]);

  const itemsFromServer = useMemo(() => {
    return (
      itemsQuery.data?.map((data) => ({
        uniqueId: data.id,
        itemName: data.name,
        primaryGroup: data.primaryGroup?.id,
        secondaryGroup: data.secondaryGroup?.id,
        productionUnitName: data.productionUnitName,
        ingredientUnitName: data.ingredientUnitName ?? "",
        conversionRatio: data.ingredientsPerProduct,
        locations: data.locations.map(i => i.id),
        minPar: data.minPar,
        maxPar: data.maxPar,
        theoreticalStock: data.theoreticalStock,
      })) ?? []
    );
  }, [itemsQuery.data]);

  const addedItems = useMemo(() => {
    return addedIds.map((uniqueId) => ({
      uniqueId,
      itemName: "",
      primaryGroup: "",
      secondaryGroup: "",
      productionUnitName: "Unit",
      ingredientUnitName: "Unit",
      conversionRatio: 1,
      locations: [],
      minPar: 0,
      maxPar: 0,
      theoreticalStock: [],
    }));
  }, [addedIds]);

  const dataWithAdditions = useMemo(
    () => [...addedItems, ...itemsFromServer],
    [addedItems, itemsFromServer]
  );

  const saveChanges = useCallback(
    async (instructions: UpdateState) => {
      const toCreate = addedIds.map((id) => {
        const updates = instructions[id];
        return {
          // actual values - defaults must stay in sync with addedIds.map...
          name: updates?.itemName?.newValue as string,
          productionUnitName: (updates?.productionUnitName?.newValue ??
            "Unit") as string,
          ingredientUnitName: (updates?.ingredientUnitName?.newValue ??
            "Unit") as string,
          ingredientsPerProduct: (updates?.conversionRatio?.newValue ??
            1) as number,
          locationIds: (updates?.locations?.newValue ?? []) as string[],
          primaryGroupId: (updates?.primaryGroup?.newValue ?? "") as string,
          secondaryGroupId: (updates?.secondaryGroup?.newValue ?? "") as string,
          // some default values
          allergenIds: [],
          isKey: false,
          isActualized: false,
          shelfLifeHours: 24,
          shelfLifeUnits: "days",
          minPar: 1,
          maxPar: 10,
          ingredients: [],
          recipe: "",
        };
      });
      return Promise.all([
        ...Object.entries(instructions)
          .filter(([id]) => !id.startsWith(prefix))
          .map(([itemId, newValue]) => {
            const updateDTO = Object.keys(newValue).reduce((curr, key) => {
              const nv = newValue[key].newValue;
              if (key == "itemName") {
                curr.name = nv as string;
              } else if (key == "locations") {
                curr.locationIds = nv as string[];
              } else if (key == "primaryGroup") {
                curr.primaryGroupId = nv as string;
              } else if (key == "secondaryGroup") {
                curr.secondaryGroupId = nv as string;
              } else if (key == "productionUnitName") {
                curr.productionUnitName = nv as string;
              } else if (key == "conversionRatio") {
                curr.ingredientsPerProduct = Number(nv) as number;
              }
              return curr;
            }, {} as UpdateRawItemDTO | UpdatePrepItemDTO);
            return updatePrep.mutateAsync({
              itemId,
              updatedItem: updateDTO,
            });
          }),
        ...toCreate.map((item) => {
          return createPrep.mutateAsync(item);
        }),
      ]).then((result) => {
        if (toCreate.length === 1) {
          const created = result[result.length - 1];
          return created?.id ?? null;
        }
        return null;
      });
    },
    [addedIds, createPrep, updatePrep, itemsFromServer]
  );

  const deleteItems = useCallback(
    async (ids: string[]) => {
      const deletions = ids.map((id) => {
        const item = itemsFromServer.find((i) => i.uniqueId == id);
        if (!item) return Promise.resolve();
        return deletePrep.mutateAsync(id);
      });
      return Promise.all(deletions);
    },
    [itemsFromServer]
  );

  return {
    itemsData: dataWithAdditions,
    addItem: add,
    saveChanges,
    deleteItems,
    columns,
    instructions,
    isEditing,
    setEditing,
  };
};
export default usePrepItems;
