import { SidebarRoute } from "components/Sidebar";

export const sidebarRoutes: SidebarRoute[] = [
    {
        label: "Gift Cards",
        link: "/payments/gift-cards/",
        subRoutes: [],
        isExpanded: false,
    },
];

export const headerRoutes = [
    {
        label: "Payments",
        link: "/payments",
    },
    {
        label: "Gift Cards",
        link: "/payments/gift-cards/",
    }
];
