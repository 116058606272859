import moment from "moment-timezone";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DepartmentId } from "controllers/staff";

import Box from "components/Box";

import {
  AvailabilitiesContent,
  AvailabilityForADay,
} from "./AvailabilitiesContent";
import { StaffMemberOrientedViewControls } from "./StaffMemberOrientedViewControls";
import { StaffMemberOrientedWeeklyView } from "./StaffMemberOrientedWeeklyView";
import { WeeklyShifts } from "./WeeklyShifts";
import { WeeklyScheduleViewType } from "./WeeklyViewSelect";
import CreateShiftModal from "./CreateShiftModal";
import _ from "lodash";
import { EditShiftypeProp } from "./useCreateShift";
import MoveShiftModal from "./MoveShiftModal";
import AssignShiftModal from "./AssignShiftModal";
import { deleteMultipleShifts, moveOrDuplicateMultipleShifts } from "controllers/schedule";
import UIComponents from "@tangopay/tango-ui-library";
import DailyShiftView from "./NewDailyShiftView";
import CostWidget from "./CostWidget";
const { DeleteModal, Icon } = UIComponents;

interface StaffWeeklyContentProps {
  isAvailabilityView: boolean;
  weekRange: Date[];
  filteredStaffMembersAvailability: StaffMember[];
  generateAvailabilitiesForADay: (d: Date) => AvailabilityForADay[];
  weeklyScheduleViewType: WeeklyScheduleViewType;

  schedulesWithLocalSchedule: TangoSchedule[];
  business: TangoBusiness;
  fixedSchedules: TangoFixedSchedule[];
  selectedDepartment: "boh" | "foh" | undefined;
  departmentScheduleViewType: DepartmentId | null;
  businessSettings: TangoBusinessSettings;
  setShowSingleDay: (v: boolean) => void;
  jobFunctions: TangoJobFunctions;

  setWeeklyStaffMemberViewFilterAnchorEl: (t: any) => void;
  weeklyStaffMemberViewFilterAnchorEl: any;
  onFullNameInputChangeDebounced: (e: any) => void;
  roleFilterId: string | null;
  setRoleFilterId: (v: any) => void;

  scheduleForWeekRange: TangoSchedule | null;
  duplicatedScheduleForAWeekRange: TangoSchedule | null;
  staffMemberNameFilter: string;
  fellowStaffMembers: StaffMember[];
  setSelectedShiftForEdit: (v: TangoShift | null) => void;
  setCurrentDateForDailyShiftView: (date: Date | null) => void;
  fohScheduleForAWeekRange: TangoSchedule | undefined;
  bohScheduleForAWeekRange: TangoSchedule | undefined;
  setWeeklyScheduleViewType: (v: WeeklyScheduleViewType) => void;
  mergedScheduleForAWeekRange: TangoSchedule | undefined;

}

export const StaffWeeklyContent = ({
  isAvailabilityView = false,
  weekRange,
  filteredStaffMembersAvailability,
  generateAvailabilitiesForADay,
  weeklyScheduleViewType,
  schedulesWithLocalSchedule,
  business,
  fixedSchedules,
  selectedDepartment,
  departmentScheduleViewType,
  businessSettings,
  setShowSingleDay,
  jobFunctions,

  roleFilterId,
  scheduleForWeekRange,
  duplicatedScheduleForAWeekRange,
  staffMemberNameFilter,
  fellowStaffMembers,
  setSelectedShiftForEdit,
  setCurrentDateForDailyShiftView,
  fohScheduleForAWeekRange,
  bohScheduleForAWeekRange,
  setWeeklyScheduleViewType,
  mergedScheduleForAWeekRange
}: StaffWeeklyContentProps) => {
  const [showCreateShiftModal, setShowCreateShiftModal] = useState(false);
  const [showMoveShiftModal, setShowMoveShiftModal] = useState(false);
  const [showConfimationModal, setShowConfimationModal] = useState(false);
  const [dayIndex, setDayIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedPosition, setSelectedPosition] = useState('');

  const [editData, setEditData] = useState<EditShiftypeProp | null>(null);
  const shiftTypes: { value: string, label: string, color: string, borderColor: string }[] = useMemo(() => {
    return (businessSettings?.shiftTypes || []).filter(
      (shiftT) => !shiftT?.deleted
    ).map((item) => {
      return { value: item.id, label: item.name, color: item.backgroundColor, borderColor: item.mainColor }
    });
  }, [businessSettings])
  const [groupedShifts, setGroupedShifts] = useState<GroupedShifts | null>(null);

  const moveOrDuplicateShifts = useCallback(async (groupedShifts: GroupedShifts | null, selectedDays: string[], actionType: "move" | "duplicate") => {
    if (groupedShifts) {
      const groupedBySchedules = _.groupBy(groupedShifts.shifts, (item) => item.draftScheduleId)
      try {
        const response = await Promise.all(Object.keys(groupedBySchedules).map((key) => {
          const scheduleId = key;
          const shiftIds = groupedBySchedules[key].map((shiftDetails) => shiftDetails.id)
          return moveOrDuplicateMultipleShifts(business.id, scheduleId, shiftIds, selectedDays, actionType)
        }))
        setGroupedShifts(null)
        setShowMoveShiftModal(false)
      }
      catch (error) {
        //@ts-ignore
        console.log(error.message)
      }
    }

  }, [])
  const handleOptionSelect = (optionType: string, groupedShifts: GroupedShifts) => {
    if (optionType === 'view') {
      const shiftTypes = businessSettings.shiftTypes || [];
      const matchedShift = shiftTypes.find((st) => st.id === groupedShifts.shiftTypeId);
      if (matchedShift) {
        const shiftType = { value: matchedShift.id, label: matchedShift.name, color: matchedShift.backgroundColor, borderColor: matchedShift.mainColor }
        const shiftStarters = groupedShifts.shifts.map((item: TangoShift) => {
          const { draftScheduleId, id, position, staffId, notes, shiftTypeId } = item;
          return { id, startTime: groupedShifts.startTime, endTime: groupedShifts.endTime, position, staffId, notes, draftScheduleId, shiftTypeId }
        })
        const shiftsGroupedByposition = _.groupBy(groupedShifts.shifts, (item) => item.position)
        const roles = Object.keys(shiftsGroupedByposition).map((item: string) => {
          return { quantity: shiftsGroupedByposition[item].length.toString(), jobfunction: shiftsGroupedByposition[item][0].position }
        })
        setEditData({ shiftStarters, roles, shiftType, selectedDate: groupedShifts.shifts[0].startDate.toDate() })
        setShowCreateShiftModal(true)
      }

    }
    if (optionType === 'move') {
      setGroupedShifts(groupedShifts)
      setShowMoveShiftModal(true)
    }
    if (optionType === 'delete') {
      setGroupedShifts(groupedShifts)
      setShowConfimationModal(true)
    }
  }

  const deleteShifts = async (groupedShifts: GroupedShifts | null) => {
    const shiftRequests = groupedShifts?.shifts.map((singleShift) => ({ shiftId: singleShift.id, draftScheduleId: singleShift.draftScheduleId as string }))
    if (shiftRequests) {
      try {
        await deleteMultipleShifts(business.id, shiftRequests);
      } catch (error) {
        //@ts-ignore
        console.log(error.message)
      }
      setGroupedShifts(null)
      setShowConfimationModal(false)
    }

  }
  if (isAvailabilityView) {
    return (
      <AvailabilitiesContent
        weekRange={weekRange}
        filteredStaffMembersAvailability={filteredStaffMembersAvailability}
        generateAvailabilitiesForADay={generateAvailabilitiesForADay}
      />
    );
  }
  if (weeklyScheduleViewType === "shift_view") {
    return (
      <div style={{ width: "80vw", }} className="overflow-hidden flex flex-col relative border-l border-solid border-t-0 border-b-0 border-r-0 border-grey-1">
        <Box className="flex flex-1 flex-col px-10 overflow-y-auto">
          <Box
            flexDirection="column"
            style={{
              width: "100%",
              overflowX: "auto",
              height: '100%',
            }}
          >
            <Box display="flex" flexDirection="row">
              <Box className="day-column" />
              {weekRange.map((item: any, index) => (
                <Box className="day-column" key={new Date(item).toString()}>
                  <Box className="flex group justify-between items-center day-header pt-8 pb-4 border-b border-solid border-grey-1 border-t-0 border-l-0 border-r-0">
                    <div>
                      <span className="text-grey-3 font-lato-bold text-base">{moment(item).format("ddd")}</span>
                      <span className="text-grey-3 font-lato-bold text-base ml-3">{moment(item).format("D")}</span>
                    </div>
                    <Icon name="arrow-right" className="mr-4 hidden group-hover:block cursor-pointer" onClick={() => {
                      setDayIndex(index)
                      setCurrentDateForDailyShiftView(weekRange[index])
                      setWeeklyScheduleViewType('day_view');
                    }} />
                  </Box>
                </Box>
              ))}
            </Box>
            <Box display="flex" flexDirection="row" className="overflow-y-auto">
              <Box className="day-column pt-4" />
              {weekRange.map((item: Date, index) => (
                <Box className="day-column pt-4" key={new Date(item).toString()}>
                  <WeeklyShifts
                    dayOfWeek={item}
                    weekRange={weekRange}
                    schedulesWithLocalSchedule={schedulesWithLocalSchedule}
                    business={business}
                    fixedSchedules={fixedSchedules}
                    selectedDepartment={selectedDepartment}
                    departmentScheduleViewType={departmentScheduleViewType}
                    businessSettings={businessSettings}
                    setShowSingleDay={setShowSingleDay}
                    jobFunctions={jobFunctions}
                    roleFilterId={roleFilterId}
                    staffMemberNameFilter={staffMemberNameFilter}
                    openAssignShiftModal={(shift) => {
                      setSelectedShiftForEdit(shift)
                    }}
                    onOptionSelect={(optionType, groupedShifts) => handleOptionSelect(optionType, groupedShifts)}
                    fohScheduleForAWeekRange={fohScheduleForAWeekRange}
                    bohScheduleForAWeekRange={bohScheduleForAWeekRange}
                    selectedDate={selectedDate}
                    setSelectedDate={(date) => setSelectedDate(date)}
                    selectedPosition={selectedPosition}
                    setSelectedPosition={(position) => setSelectedPosition(position)}
                  />
                </Box>
              ))}
            </Box>
          </Box>
          <CreateShiftModal
            isOpen={showCreateShiftModal}
            closeModal={() => {
              setShowCreateShiftModal(false)
              if (editData) {
                setEditData(null);
              }
            }}
            shiftTypes={shiftTypes}
            jobFunctions={businessSettings.jobFunctions}
            weekRange={weekRange}
            schedulesWithLocalSchedule={schedulesWithLocalSchedule}
            business={business}
            selectedDepartment={selectedDepartment}
            editData={editData}
            fohScheduleForAWeekRange={fohScheduleForAWeekRange}
            bohScheduleForAWeekRange={bohScheduleForAWeekRange}
          />
          <MoveShiftModal
            isOpen={showMoveShiftModal}
            closeModal={() => {
              setShowMoveShiftModal(false)
              setGroupedShifts(null)
            }}
            onSave={(actionType, selectedDays) => {

              moveOrDuplicateShifts(groupedShifts, selectedDays, actionType)

            }}
          />
          <DeleteModal
            isOpen={showConfimationModal}
            modalTitle="Are you sure?"
            modalSubtitle=""
            onDelete={() => deleteShifts(groupedShifts)}
            closeModal={() => setShowConfimationModal(false)}
          />
        </Box>
        <CostWidget business={business} fohScheduleForAWeekRange={fohScheduleForAWeekRange} bohScheduleForAWeekRange={bohScheduleForAWeekRange} />

      </div>

    );

  }
  if (weeklyScheduleViewType === 'day_view') {
    return (
      <>
        <DailyShiftView
          schedulesWithLocalSchedule={schedulesWithLocalSchedule}
          weekRange={weekRange}
          departmentScheduleViewType={departmentScheduleViewType}
          duplicatedScheduleForAWeekRange={
            duplicatedScheduleForAWeekRange
          }
          scheduleForWeekRange={scheduleForWeekRange}
          setSelectedShiftForEdit={setSelectedShiftForEdit}
          jobFunctions={jobFunctions}
          fixedSchedules={fixedSchedules}
          business={business}
          businessSettings={businessSettings}
          fellowStaffMembers={fellowStaffMembers}
          onOptionSelect={(optionType, groupedShifts) => handleOptionSelect(optionType, groupedShifts)}
          staffMemberNameFilter={staffMemberNameFilter}
          roleFilterId={roleFilterId}
          setCurrentDateForDailyShiftView={setCurrentDateForDailyShiftView}
          dayIndex={dayIndex}
          mergedScheduleForAWeekRange={mergedScheduleForAWeekRange}
        />
        <CreateShiftModal
          isOpen={showCreateShiftModal}
          closeModal={() => setShowCreateShiftModal(false)}
          shiftTypes={shiftTypes}
          jobFunctions={businessSettings.jobFunctions}
          weekRange={weekRange}
          schedulesWithLocalSchedule={schedulesWithLocalSchedule}
          business={business}
          selectedDepartment={selectedDepartment}
          editData={editData}
          fohScheduleForAWeekRange={fohScheduleForAWeekRange}
          bohScheduleForAWeekRange={bohScheduleForAWeekRange}
        />

        <MoveShiftModal
          isOpen={showMoveShiftModal}
          closeModal={() => {
            setShowMoveShiftModal(false)
            setGroupedShifts(null)
          }}
          onSave={(actionType, selectedDays) => {

            moveOrDuplicateShifts(groupedShifts, selectedDays, actionType)

          }}
        />
        <DeleteModal
          isOpen={showConfimationModal}
          modalTitle="Are you sure?"
          modalSubtitle=""
          onDelete={() => deleteShifts(groupedShifts)}
          closeModal={() => setShowConfimationModal(false)}
        />
      </>

    )
  }
  return (
    <div style={{ width: "80vw", }} className="overflow-hidden flex flex-col relative border-l border-solid border-t-0 border-b-0 border-r-0 border-grey-1" >
      <StaffMemberOrientedWeeklyView
        weekRange={weekRange}
        business={business}
        businessSettings={businessSettings}
        departmentScheduleViewType={departmentScheduleViewType}
        scheduleForWeekRange={scheduleForWeekRange}
        duplicatedScheduleForAWeekRange={duplicatedScheduleForAWeekRange}
        roleFilterId={roleFilterId}
        staffMemberNameFilter={staffMemberNameFilter}
        fellowStaffMembers={fellowStaffMembers}
        setSelectedShiftForEdit={setSelectedShiftForEdit}
        setWeeklyScheduleViewType={setWeeklyScheduleViewType}
        setDayIndex={(dayIndex) => setDayIndex(dayIndex)}
        mergedScheduleForAWeekRange={mergedScheduleForAWeekRange}
        fohScheduleForAWeekRange={fohScheduleForAWeekRange}
        bohScheduleForAWeekRange={bohScheduleForAWeekRange}
        openAssignShiftModal={(shift) => {
          setSelectedShiftForEdit(shift)
        }}
        setCurrentDateForDailyShiftView={setCurrentDateForDailyShiftView}
      />
      <CostWidget business={business} fohScheduleForAWeekRange={fohScheduleForAWeekRange} bohScheduleForAWeekRange={bohScheduleForAWeekRange} />

    </div>
  );

};
