import _ from "lodash";
import moment from "moment-timezone";
import React, { useState } from "react";

import {
  roundNumberToTwoDecimals,
  sanitizeNumericField,
} from "controllers/reporting";
import { DepartmentId } from "controllers/staff";

import Box from "components/Box";
import UIComponents from "@tangopay/tango-ui-library";
import { convertToBusinessTimezoneMoment } from "utils/dateUtils";

import {
  getPositionById,
  getPositionNameById,
  getShiftTypeById,
} from "model/selectors/businessSettings";
import { WeeklyScheduleViewType } from "./WeeklyViewSelect";
import StaffProfileModal from "./StaffProfileModal";
import AddNewShiftForStaffModal from "./AddNewShiftForStaffModal";

interface StaffMembersWeeklySchedule {
  staffId: string;
  staffFullName: string;
  shifts: TangoShift[];
  totalPay: number;
  totalHours: number;
  isOverTime: boolean;
  primaryRole: string | null;
}

interface StaffMemberOrientedWeeklyViewProps {
  weekRange: Date[];
  business: TangoBusiness;
  businessSettings: TangoBusinessSettings;
  departmentScheduleViewType: DepartmentId | null;
  scheduleForWeekRange: TangoSchedule | null;
  duplicatedScheduleForAWeekRange: TangoSchedule | null;
  roleFilterId: string | null;
  staffMemberNameFilter: string;
  fellowStaffMembers: StaffMember[];
  setSelectedShiftForEdit: (v: TangoShift | null) => void;
  setWeeklyScheduleViewType: (v: WeeklyScheduleViewType) => void;
  setDayIndex: (dayIndex: number) => void;
  mergedScheduleForAWeekRange: TangoSchedule | undefined;
  fohScheduleForAWeekRange: TangoSchedule | undefined;
  bohScheduleForAWeekRange: TangoSchedule | undefined;
  openAssignShiftModal: (v: TangoShift | null) => void;
  setCurrentDateForDailyShiftView: (date: Date | null) => void

}

export const StaffMemberOrientedWeeklyView = ({
  weekRange,
  business,
  businessSettings,
  departmentScheduleViewType,
  scheduleForWeekRange,
  duplicatedScheduleForAWeekRange,
  roleFilterId,
  staffMemberNameFilter,
  fellowStaffMembers,
  setSelectedShiftForEdit,
  setWeeklyScheduleViewType,
  setDayIndex,
  mergedScheduleForAWeekRange,
  fohScheduleForAWeekRange,
  bohScheduleForAWeekRange,
  openAssignShiftModal,
  setCurrentDateForDailyShiftView
}: StaffMemberOrientedWeeklyViewProps) => {
  const [selectedStaffId, setSelectedStaffId] = useState<string | null>(null);
  const [selectedStaffIdForNewShift, setSelectedStaffIdForNewShift] = useState<string>('');
  const [selectedWeekDateForNewShift, setSelectedWeekDateForNewShift] = useState<Date | null>(null);
  const getPayrateByPostionAndStaffId = (
    positionId: string,
    staffId: string
  ) => {
    const staffMemberPayrates =
      fellowStaffMembers.find((s) => s?.id === staffId)?.payRates || [];
    const rolePayrate = staffMemberPayrates.find(
      (payRate) => payRate?.roleId === positionId
    );
    if (rolePayrate?.amount) {
      return rolePayrate?.amount;
    }
    return 0;
  };

  const shiftsForTheWeek =
    scheduleForWeekRange?.shifts ||
    duplicatedScheduleForAWeekRange?.shifts ||
    [];
  const shiftsGroupedByStaffId = _.groupBy(shiftsForTheWeek, "staffId");
  const nameFilter = (staffMember: StaffMember) =>
    staffMemberNameFilter
      ? `${staffMember?.contact?.firstName} ${staffMember?.contact?.lastName}`.includes(
        staffMemberNameFilter
      )
      : true;
  const roleFilter = (staffMember: StaffMember) =>
    roleFilterId
      ? staffMember?.primaryRole === roleFilterId ||
      staffMember?.secondaryRoles?.includes(roleFilterId)
      : true;
  const departmentFilter = (staffMember: StaffMember) =>
    departmentScheduleViewType
      ? [staffMember?.primaryRole, ...(staffMember?.secondaryRoles || [])].find(
        (roleId) =>
          getPositionById(businessSettings, roleId || "")?.departmentId ===
          departmentScheduleViewType
      )
      : true;
  const filteredStaffMembers = fellowStaffMembers.filter(
    (staffMember) =>
      departmentFilter(staffMember) &&
      nameFilter(staffMember) &&
      roleFilter(staffMember)
  );
  const unsortedStaffSchedules = _.keys(shiftsGroupedByStaffId)
    .filter((key) => key !== "null")
    .map((staffId: string): StaffMembersWeeklySchedule | null => {
      const staffMember = filteredStaffMembers?.find(
        (staff) => staff?.id === staffId
      );
      if (!staffMember) return null;
      const staffFirstName = staffMember?.contact?.firstName;
      const staffLastName = staffMember?.contact?.lastName;
      const primaryRole = staffMember.primaryRole;
      const shifts = shiftsGroupedByStaffId?.[staffId] || [];

      const totalPayReducer = (accumulator: number, item: TangoShift) => {
        if (item?.staffId) {
          const hoursDuration = roundNumberToTwoDecimals(
            sanitizeNumericField(
              Math.abs(
                moment
                  .duration(
                    moment(item?.startDate.toMillis()).diff(
                      moment(item?.endDate.toMillis())
                    )
                  )
                  .asHours()
              )
            )
          );
          return (
            accumulator +
            roundNumberToTwoDecimals(
              sanitizeNumericField(
                getPayrateByPostionAndStaffId(item.position, item.staffId) *
                hoursDuration
              )
            )
          );
        }
        return accumulator;
      };
      const totalHoursReducer = (accumulator: number, item: TangoShift) => {
        if (item?.staffId) {
          const hoursDuration = roundNumberToTwoDecimals(
            sanitizeNumericField(
              Math.abs(
                moment
                  .duration(
                    moment(item?.startDate.toMillis()).diff(
                      moment(item?.endDate.toMillis())
                    )
                  )
                  .asHours()
              )
            )
          );
          return accumulator + hoursDuration;
        }
        return accumulator;
      };
      const totalPay = shifts.reduce(totalPayReducer, 0);
      const totalHours = shifts.reduce(totalHoursReducer, 0);
      const isOverTime = totalHours > 40;
      return {
        staffId: staffId,
        staffFullName: `${staffFirstName} ${staffLastName}`,
        shifts,
        totalPay: roundNumberToTwoDecimals(sanitizeNumericField(totalPay)),
        totalHours: roundNumberToTwoDecimals(sanitizeNumericField(totalHours)),
        isOverTime,
        primaryRole,
      };
    })
    .filter((x) => !!x) as StaffMembersWeeklySchedule[];

  const staffSchedules = _.orderBy(unsortedStaffSchedules, [sm => sm.staffFullName.toLowerCase()], ['asc']);
  const highestNumberOfShiftsInADay = (
    sch: StaffMembersWeeklySchedule
  ): number => {
    const staffShiftsGroupedByDay = _.groupBy(sch.shifts, (shift) => {
      const momentWithTimezone = moment(shift?.startDate?.toDate());
      momentWithTimezone.tz(business.timezone, false);
      return convertToBusinessTimezoneMoment(
        shift?.startDate?.toDate(),
        business
      ).day();
    });
    const lengths = _.keys(staffShiftsGroupedByDay).map(
      (key) => staffShiftsGroupedByDay?.[key]?.length
    );
    return _.max(lengths) || 0;
  };
  return (
    <div className="flex flex-1 flex-col px-10 overflow-y-auto">
      <div className="flex">
        <div className="flex flex-1 pt-8 pb-4 border-b border-solid border-grey-1 border-t-0 border-l-0 border-r-0">
          <span className="text-grey-3 font-lato-bold text-base opacity-0">staff name</span>
          <span className="text-grey-3 font-lato-bold text-base ml-3 opacity-0">staff name</span>
        </div>
        {
          weekRange.map((day, index) => (
            <div className="flex group justify-between text-grey-3 font-lato-bold text-base  flex-1 pt-8 pb-4 border-b border-solid border-grey-1 border-t-0 border-l-0 border-r-0">
              <div>
                <span>{moment(day).format("ddd")}</span>
                <span className="ml-3">{moment(day).format("D")}</span>
              </div>
              <UIComponents.Icon name="arrow-right" className="mr-4 hidden group-hover:block cursor-pointer" onClick={() => {
                setDayIndex(index)
                setCurrentDateForDailyShiftView(weekRange[index])
                setWeeklyScheduleViewType('day_view');
              }} />

            </div>
          ))
        }
      </div>
      <div>
        {staffSchedules.map((singleSchedule, index) => {
          const totalPay = (singleSchedule?.totalPay / 100).toLocaleString(
            "en-US",
            {
              style: "currency",
              currency: "USD",
            }
          );
          return (
            <div className="group hover:bg-off-white flex py-2 border-b border-grey-1 border-solid border-l-0 border-r-0 border-t-0" >
              <div className="flex flex-1 flex-col justify-between">
                <div>
                  <div className="font-lato-bold text-sm text-black">
                    {singleSchedule.staffFullName}
                  </div>
                  {
                    singleSchedule.primaryRole ?
                      <div className="mb-1 w-16 font-lato-medium text-xs text-grey-2 pb-1 border-b border-grey-1 border-solid border-l-0 border-r-0 border-t-0">
                        {getPositionNameById(
                          businessSettings,
                          singleSchedule.primaryRole || ""
                        )}
                      </div>
                      :
                      null
                  }

                  <div className=" font-lato-medium text-xs text-grey-2">{totalPay} / {singleSchedule.totalHours} hours</div>
                </div>
                <div onClick={() => {
                  setSelectedStaffId(singleSchedule.staffId)
                }} className=" opacity-0 group-hover:!opacity-100 cursor-pointer text-xs font-lato-bold text-black border border-solid flex self-start px-3 py-1 rounded-full">
                  View Profile
                </div>
              </div>

              {
                weekRange.map((day) => {
                  const shiftsFoADay = singleSchedule?.shifts?.filter(
                    (shift) =>
                      convertToBusinessTimezoneMoment(
                        shift?.startDate?.toDate(),
                        business
                      ).day() ===
                      convertToBusinessTimezoneMoment(day, business).day()
                  );
                  return (
                    <div className="flex flex-1 flex-col px-1 cursor-pointer staff-shifts-column" >
                      {shiftsFoADay.map((shift) => {
                        const shiftType = getShiftTypeById(
                          businessSettings,
                          shift?.shiftTypeId || ""
                        );

                        const shiftTypeName = shiftType?.name ?? "";
                        return (
                          <Box className="flex-col mb-2 " onClick={() => openAssignShiftModal(shift)}>
                            <div className="flex justify-between">
                              <div className="">
                                <div className="text-tiny font-lato-bold text-black uppercase">
                                  {shiftTypeName}
                                </div>
                                <div className=" text-tiny font-lato-medium text-grey-2">
                                  {moment(shift?.startTime, "HH:mm").format(
                                    "hh:mm a"
                                  )} -{" "}
                                  {moment(shift?.endTime, "HH:mm").format(
                                    "hh:mm a"
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              className={`text-xs font-lato-bold text-black rounded-md `}
                              style={{
                                width: "100%",
                                textAlign: "left",
                                backgroundColor: shiftType?.backgroundColor,
                              }}

                            >
                              <div className="px-2 py-1">
                                {getPositionNameById(
                                  businessSettings,
                                  shift?.position || ""
                                )}
                              </div>
                            </div>
                          </Box>
                        );
                      })}

                      <UIComponents.Icon name="plus" onClick={() => {
                        setSelectedWeekDateForNewShift(day)
                        setSelectedStaffIdForNewShift(singleSchedule.staffId)
                      }} className="flex self-center staff-add-button" />
                    </div>
                  )
                })
              }
            </div>
          )
        })}
      </div>
      <StaffProfileModal
        staffId={selectedStaffId}
        closeModal={() => setSelectedStaffId(null)} />
      <AddNewShiftForStaffModal
        selectedDate={selectedWeekDateForNewShift}
        staffId={selectedStaffIdForNewShift}
        jobFunctions={businessSettings.jobFunctions}
        closeModal={() => {
          setSelectedWeekDateForNewShift(null)
          setSelectedStaffIdForNewShift('')
        }}
        schedule={
          scheduleForWeekRange || duplicatedScheduleForAWeekRange || null
        }
        fohScheduleForAWeekRange={fohScheduleForAWeekRange}
        mergedScheduleForAWeekRange={mergedScheduleForAWeekRange}
        weekRange={weekRange}
        bohScheduleForAWeekRange={bohScheduleForAWeekRange}
      />
    </div>
  )
}