import UIComponents from "@tangopay/tango-ui-library";
import React from "react";

const Filters = () => {
  return (
    <div
      className={
        "bg-white mt-8 rounded-2xl w-full border border-solid border-grey-1"
      }
    >
      <div
        className={
          " p-6 border-b border-grey-1 flex justify-between items-center"
        }
        style={{ borderBottomStyle: "solid" }}
      >
        <div className={"font-lato-bold text-xl text-black"}>Filters</div>
        <div className={"text-xs font-lato-bold text-black"}>Reset</div>
      </div>
      <div className={"px-6 py-8"}>
        <div className={"mb-8"}>
          <div className={"text-sm font-lato-bold text-black mb-4"}>
            Locations
          </div>
          <UIComponents.Dropdown
            size={"small"}
            placeholder={"All Locations"}
            options={[
              {
                label: "location 1",
                value: "location 1",
              },
            ]}
          />
        </div>
        <div className={"mb-8"}>
          <div className={"text-sm font-lato-bold text-black mb-4"}>
            Primary Group
          </div>
          <UIComponents.Dropdown
            size={"small"}
            placeholder={"Select Group"}
            options={[
              {
                label: "All",
                value: "All",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "1",
                value: "1",
              },
            ]}
          />
        </div>
        <div className={"mb-8"}>
          <div className={"text-sm font-lato-bold text-black mb-4"}>
            Secondary Group
          </div>
          <UIComponents.Dropdown
            size={"small"}
            placeholder={"Select Group"}
            options={[
              {
                label: "All",
                value: "All",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "1",
                value: "1",
              },
            ]}
          />
        </div>
        <div className={"mb-8"}>
          <div className={"text-sm font-lato-bold text-black mb-4"}>
            Vendors
          </div>
          <UIComponents.Dropdown
            size={"small"}
            placeholder={"All Rating"}
            options={[]}
          />
        </div>
        <div>
          <div className={"text-sm font-lato-bold text-black mb-4"}>Tags</div>
          <ul className={"p-0 m-0"}>
            <li className={"flex items-center mb-4"}>
              <UIComponents.RadioButton
                style={{ cursor: "pointer" }}
                type={"small"}
                className={"mr-4"}
              />
              <div className={"text-tiny font-lato-regular text-black"}>
                Key Items
              </div>
            </li>
            <li className={"flex items-center mb-4"}>
              <UIComponents.RadioButton
                style={{ cursor: "pointer" }}
                type={"small"}
                className={"mr-4"}
              />
              <div className={"text-tiny font-lato-regular text-black"}>
                Prep Item
              </div>
            </li>
            <li className={"flex items-center mb-4"}>
              <UIComponents.RadioButton
                style={{ cursor: "pointer" }}
                type={"small"}
                className={"mr-4"}
              />
              <div className={"text-tiny font-lato-regular text-black"}>
                Raw Item
              </div>
            </li>
            <li className={"flex items-center mb-4"}>
              <UIComponents.RadioButton
                style={{ cursor: "pointer" }}
                type={"small"}
                className={"mr-4"}
              />
              <div className={"text-tiny font-lato-regular text-black"}>
                All Items
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Filters;
