import React, { useCallback, useEffect, useState } from "react";
import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";
import Sidebar from "components/Sidebar";
import { headerRoutes } from "./routes";
import UIComponents from '@tangopay/tango-ui-library';
import usePayouts from "./usePayouts";
import { useNavigate } from "react-router-dom";

const { Icon, Button } = UIComponents;

const Payouts = () => {
    const {
        data,
        noOp,
        logIt,
        columns,
        instructions,
        isEditing,
        setEditing,
        setLastPayoutId,
        addPayout,
        saveChanges,
        staffMembers
    } = usePayouts();

    const [previousPageIds, setPreviousPageIds] = useState(['start'])
    const [pageNumber, setPageNumber] = useState<number>(0)
    const [chunkedPayouts, setChunkedPayouts] = useState<any>([])
    const [displayCounterMessage, setDisplayCounterMessage] = useState<string>('1 - ' + 20)

    useEffect(() => {
        if (chunkedPayouts.length > 0 && pageNumber > 0) {
            const fullList = chunkedPayouts.flat(1)
            const lastStartId = fullList[fullList.length - 1].uniqueId
            const startNumber = fullList.findIndex((payment: any) => payment.uniqueId === lastStartId);
            setDisplayCounterMessage(startNumber + 1 + ' - ' + (fullList.length + data.length))
        } else {
            setDisplayCounterMessage('1 - ' + data.length)
        }
    }, [chunkedPayouts, data])

    return (
        <div className="absolute top-[63px] bottom-0 w-full overflow-hidden">
            <Header routes={headerRoutes} selectedRoute={"/manager/payouts"} />
            <div className="flex h-full">
                {/* <div
                    className={"border-r border-grey-1 border-t-0 border-b-0"}
                    style={{ width: "10%", borderRightStyle: "solid" }}
                >
                    <Sidebar routes={sidebarRoutes} />
                </div> */}
                <div className={"flex px-10 py-6 overflow-y-auto flex-col "} style={{ width: "100%" }}>
                    <HorizontalTable
                        title="Payouts"
                        columns={columns}
                        data={data}
                        instructions={instructions}
                        saveResults={saveChanges}
                        setEditing={setEditing}
                        isEditing={isEditing}
                        hideEdit
                        primaryActionLabel="Add Payout"
                        primaryActionHandler={addPayout}
                    />

                    {data.length > 0 &&
                        <div className="flex justify-between items-center">
                            <div className="mt-7 flex items-center justify-start">
                                Viewing {displayCounterMessage}
                            </div>
                            <div className="mt-7 flex items-center justify-end">
                                <Icon name="chevron-left" className=" cursor-pointer" size="20" onClick={() => {
                                    if (pageNumber > 0 && previousPageIds.length > 0 && chunkedPayouts.length > 0) {
                                        setLastPayoutId(previousPageIds[pageNumber - 1])
                                        setPreviousPageIds([
                                            ...previousPageIds.slice(0, previousPageIds.length - 1),
                                            ...previousPageIds.slice(previousPageIds.length)
                                        ]);
                                        setChunkedPayouts([
                                            ...chunkedPayouts.slice(0, chunkedPayouts.length - 1),
                                            ...chunkedPayouts.slice(chunkedPayouts.length)
                                        ]);
                                        setPageNumber(pageNumber - 1)
                                    }
                                }} />
                                <Icon name="chevron-right" className="cursor-pointer ml-3" size="20" onClick={() => {
                                    if (!data[data.length - 1].cashEventId || data.length < 20) {
                                        return
                                    }

                                    setLastPayoutId(data[data.length - 1].cashEventId)
                                    setChunkedPayouts([...chunkedPayouts, data])
                                    setPageNumber(pageNumber + 1)
                                    setPreviousPageIds([...previousPageIds, data[0].cashEventId])
                                }} />
                            </div>
                        </div>
                    }
                </div>

            </div>
        </div>
    );
};

export default Payouts;
