import UIComponents from "@tangopay/tango-ui-library";
import React, { useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";

import Sidebar from "../Sidebar";
import { headerRoutes, inventorySelectedPage } from "../routes";
import useCreateInvoice from "./useCreateInvoice";

const ActionButtons = (props: {
  addItem: () => unknown,
  addExpense: () => unknown,
  addTax: () => unknown,
}) =>
  <div className="flex justify-between items-center p-6 border-grey-1 border-solid border-l-0 border-t-0 border-r-0 border-b-0">

    <Button
      type="btn-style-2"
      size="btn-medium"
      label="Add Tax"
      onClick={props.addTax}
    />
    <Button
      type="btn-style-2"
      size="btn-medium"
      label="Add Expense"
      onClick={props.addExpense}
    />
    <Button
      type="btn-style-2"
      size="btn-medium"
      label="Add Item"
      onClick={props.addItem}
    />
  </div>

const { Icon, Button } = UIComponents;
const CreateInvoice = () => {
  const navigate = useNavigate();
  const loc = useLocation();
  const [params] = useSearchParams();
  const vendorId = useMemo(() => {
    return params.get("vendorId") as string;
  }, [loc]);
  const {
    vendor,
    lineItems,
    addPurchase,
    addExpense,
    addTax,
    instructions,
    columns,
    deleteIds,
    makePurchase,
    invoiceAsList,
    summaryColumns,
    summaryInstructions,
    detailColumns,
    detailInstructions,
    editingDetails,
    setEditingDetails,
    handleItemUpdate,
    saveDetail,
  } = useCreateInvoice(vendorId);


  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header routes={headerRoutes} selectedRoute={"/inventory/items/basic"} selectedPage={inventorySelectedPage} />
      <div className="flex h-full">
        <div
          className={
            "border-r border-grey-1 border-solid border-t-0 border-b-0"
          }
          style={{ width: "10%" }}
        >
          <Sidebar />
        </div>

        <div style={{ width: "90%" }} className={"bg-off-white"}>
          <div
            className={
              "flex p-8 justify-between items-center bg-white border-b border-grey-1"
            }
            style={{ borderBottomStyle: "solid" }}
          >
            <div className={"flex items-center"}>
              <Icon
                name={"arrow-left"}
                size={"20"}
                className={"cursor-pointer"}
                onClick={() => navigate(-1)}
              />
              <div className={"text-big text-black font-lato-black ml-10"}>
                New Invoice for {vendor?.name}
              </div>
            </div>
            <div className={"flex items-center"}>
              <Button
                label={"Submit Invoice"}
                type={"btn-style-2"}
                size={"btn-large"}
                onClick={() => makePurchase().then(() => navigate(`/inventory/vendor/${vendorId}`))}
              />
            </div>
          </div>
          <div className={"flex p-8"}>
            <div className={"w-4/12"}>
              <HorizontalTable
                title={"Invoice Details"}
                isEditing={editingDetails}
                setEditing={setEditingDetails}
                data={invoiceAsList}
                columns={detailColumns}
                instructions={detailInstructions}
                saveResults={saveDetail}
                isVertical
              />
              <div className={"h-8"} />
              <HorizontalTable
                title={"Invoice Cost Summary"}
                isEditing={false}
                data={invoiceAsList}
                columns={summaryColumns}
                instructions={summaryInstructions}
                isVertical
                hideEdit
              />
            </div>
            <div className={"w-8/12 pl-8"}>
              <div
                className={
                  "bg-white border border-grey border-b-0 border-solid rounded-2xl"
                }
              >
                <HorizontalTable
                  title="Invoice"
                  columns={columns}
                  data={lineItems}
                  instructions={instructions}
                  isEditing
                  saveResults={makePurchase}
                  deleteIds={deleteIds}
                  customHeaderLeftContent={<ActionButtons addExpense={addExpense} addItem={addPurchase} addTax={addTax} />}
                  displayDeleteToTheLeftOfCustomContent
                  recievedUpdate={handleItemUpdate}
                  hideSave
                  hideEdit
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateInvoice;
