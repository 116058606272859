import _ from "lodash";

import { composeUsableOrder } from "controllers/composableTypes";

import { FirebaseOrderDoc, FirebaseTabDoc } from "types/order";

import firebase from "../config/firebase";

const db = firebase.firestore();
const orders = db.collection("Orders");

export const fetchOrders = async (
  lowestDate: Date,
  highestDate: Date,
  businessId: string
): Promise<FirebaseOrderDoc[]> => {
  const querySnapshot = await orders
    .where("businessId", "==", businessId)
    .where("createdAt", ">=", lowestDate)
    .where("createdAt", "<=", highestDate)
    .get();

  const results: FirebaseOrderDoc[] = [];
  querySnapshot.forEach((doc) => {
    if (doc.exists) {
      results.push(composeUsableOrder(doc.data()));
    }
  });

  return results;
};

export const getDuplicatedOrders = (
  orders: FirebaseOrderDoc[],
  tabs: FirebaseTabDoc[]
) => {
  // ChargeIds is an array with an array of chargeIds or null.
  // This is to accomodate for flex customers where the customers
  // array will have multiple successful charge ids.
  const chargeIds = tabs.map((tab) => {
    if (tab.customer && tab.customer.length > 0) {
      return tab.customer.reduce((acc, customer) => {
        if (customer.payment && customer.payment.type === "stripe") {
          if (customer.payment.successfulCharge.id) {
            return [...acc, customer.payment.successfulCharge.id];
          }
        }
        return acc;
      }, [] as string[]);
    }
    return null;
  });

  const indicesToDrop = chargeIds
    .map((id, index) => {
      if (id !== null && id.length > 0) {
        const foundIndex = chargeIds.findIndex((chargeId) =>
          chargeId ? _.isEqual(chargeId.sort(), id.sort()) : false
        );
        if (index !== foundIndex) {
          return index;
        }
      }
      return -1;
    })
    .filter((i) => i !== -1);

  const tabIdsToRemove = indicesToDrop.map((index) => tabs[index].id);
  const orderIdsToRemove = orders
    .filter((order) => tabIdsToRemove.includes(order.tabId))
    .map((order) => order.id);

  return { tabIdsToRemove, orderIdsToRemove };
};

export const getOrdersPaidIncorrectly = (
  orders: FirebaseOrderDoc[],
  tabs: FirebaseTabDoc[]
) => {
  return orders.reduce((acc, order) => {
    const index = tabs.findIndex((tab) => tab.id === order.tabId);
    if (index !== -1) {
      if (tabs[index].customer) {
        // Go through each customer charge
        // if the amount.payment.type is not
        // stripe, then this will not be
        // a very safe check so acc will
        // be returned. Otherwise, we can use this
        // to compare if the correct amount is
        // being charged.
        let total = 0;
        for (const customer of tabs[index].customer) {
          if (customer.payment && customer.payment.type === "stripe") {
            total += customer.payment
              ? customer.payment.successfulCharge
                ? customer.payment.successfulCharge.amount || 0
                : 0
              : 0;
          } else {
            return acc;
          }
        }

        // Check if the customer has the correct amount paid
        if (
          Math.round(total) !==
          Math.round(order.amount.netTotal + order.amount.tip)
        ) {
          return {
            ...acc,
            [order.id]: {
              orderAmount: order.amount.netTotal + order.amount.tip,
              customerAmount: total,
            },
          };
        }
      }
    }
    return acc;
  }, {} as { [T: string]: { orderAmount: number; customerAmount: number } });
};

interface ProductWithMetaData {
  productOrderId: string;
  productId: string;
  businessId: string;
  imageUrl: string;
  menuName: string;
  menuCategory: string;
  name: string;
  quantity: number;
  price: number;
  discountedPrice: number | null;
  taxRate: number | null;
  type: string;
  alcohol: boolean;
  discountsAppliedToProduct: string[];
  selectedModifiers: any[]; // SelectedModifiers[]
  note?: string | null;
  sentQuantity: number;
  orderQuantity: number;
  plu: string | null;
  isCash: boolean;
  currency: string;
  orderType: string;
  orderChannel: string;
}

// NOTE: This isn't being used.
//        It's supposed to be able to settle prices of products in the order to the amount paid.
//        If the result from getOrdersPaidIncorrectly(orders, tabs) has no keys, then there's no issues
//        and there's nothing needed to do.
export const getSalesByProductType = (
  orders: FirebaseOrderDoc[],
  tabs: FirebaseTabDoc[],
  allPricingModels: TangoTax[],
  businessSettings: TangoBusinessSettings | null
) => {
  const ordersPaidIncorrectly = getOrdersPaidIncorrectly(orders, tabs);

  return ([] as ProductWithMetaData[]).concat
    .apply(
      [],
      orders.map((order) => {
        if (Object.keys(ordersPaidIncorrectly).includes(order.id)) {
          let amountToChange =
            ordersPaidIncorrectly[order.id].orderAmount -
            ordersPaidIncorrectly[order.id].customerAmount;
          return order.products.map((product) => {
            if (amountToChange > 0) {
              const pricingIndex = allPricingModels.findIndex(
                (pricingModel) =>
                  pricingModel.orderChannel === order.orderChannel &&
                  pricingModel.orderType === order.orderType
              );

              // Tax has to be accounted for in the new price
              const taxRate =
                product.taxRate || product.taxRate === 0
                  ? product.taxRate
                  : allPricingModels[pricingIndex].taxRate;

              const price = product.discountedPrice
                ? product.discountedPrice
                : product.price;
              const total = price * product.quantity * (1 + taxRate / 100);

              // Total price of item is greater than the amount owed
              if (total - amountToChange >= 0) {
                const newPrice =
                  price -
                  (amountToChange * (1 - taxRate / 100)) / product.quantity;
                amountToChange = 0;

                return {
                  ...product,
                  price: newPrice,
                  isCash: false,
                  currency: order.amount.currency,
                  orderType: order.orderType,
                  orderChannel: order.orderChannel,
                };

                // Amount owed is higher than the total price of item
              } else {
                amountToChange = amountToChange - total;

                return {
                  ...product,
                  price: 0,
                  isCash: false,
                  currency: order.amount.currency,
                  orderType: order.orderType,
                  orderChannel: order.orderChannel,
                };
              }
            }

            return {
              ...product,
              isCash: false,
              currency: order.amount.currency,
              orderType: order.orderType,
              orderChannel: order.orderChannel,
            };
          });
        }

        return order.products.map((product) => ({
          ...product,
          isCash: false,
          currency: order.amount.currency,
          orderType: order.orderType,
          orderChannel: order.orderChannel,
        }));
      })
    )
    .reduce(
      (
        acc: {
          productType: string;
          quantity: number;
          subTotal: number;
          tax: number;
          tip: number;
          grossTotal: number;
          discountTotal: number;
          netTotal: number;
          fees: number;
        }[],
        val
      ) => {
        const index = acc.findIndex((data) => data.productType === val.type);
        const amount = createAmount(
          [val],
          allPricingModels,
          val.currency,
          val.isCash,
          businessSettings,
          true
        );
        const pricingIndex = allPricingModels.findIndex(
          (pricingModel) =>
            pricingModel.orderChannel === val.orderChannel &&
            pricingModel.orderType === val.orderType
        );
        const fees =
          pricingIndex !== -1
            ? (allPricingModels[pricingIndex].tangoFeePercent / 100) *
                amount.netTotal +
              allPricingModels[pricingIndex].tangoFeeCents
            : 0;
        if (index !== -1) {
          acc[index] = {
            productType: val.type,
            quantity: acc[index].quantity + val.quantity,
            subTotal: acc[index].subTotal + amount.subTotal,
            tax: acc[index].tax + amount.tax,
            tip: acc[index].tip + amount.tip,
            grossTotal: acc[index].grossTotal + amount.grossTotal,
            discountTotal: acc[index].discountTotal + amount.discountTotal,
            netTotal: acc[index].netTotal + amount.netTotal,
            fees: acc[index].fees + fees,
          };
          return acc;
        }
        return [
          ...acc,
          {
            productType: val.type,
            quantity: val.quantity,
            subTotal: amount.subTotal,
            tax: amount.tax,
            tip: amount.tip,
            grossTotal: amount.grossTotal,
            discountTotal: amount.discountTotal,
            netTotal: amount.netTotal,
            fees: fees,
          },
        ];
      },
      []
    );
};

interface Amount {
  subTotal: number;
  tax: number;
  deliveryFee: number;
  serviceChargeTotal: number;
  discountTotal: number;
  tip: number;
  grossTotal: number;
  netTotal: number;
  currency: string;
}

export const getTaxRateAndTangoFees = (allPricingModels: TangoTax[]) => {
  const pricing = allPricingModels.filter(
    (price) => "tangoPOS" === price.orderChannel && "dineIn" === price.orderType
  );

  if (pricing.length > 0) {
    return {
      taxRate: pricing[0].taxRate,
      tangoFeeCents: pricing[0].tangoFeeCents,
      tangoFeePercent: pricing[0].tangoFeePercent,
      tangoFeeRate: pricing[0].tangoFeeRate ?? 0,
    };
  }

  return {
    taxRate: 0,
    tangoFeeCents: 0,
    tangoFeePercent: 0,
  };
};

export const createAmount = (
  itemsInCart: any[], // i.e. ItemsInCart[] from the tablet app
  allPricingModels: TangoTax[],
  currency: string,
  isCash: boolean,
  businessSettings: TangoBusinessSettings | null,
  isSpeed: boolean
): Amount => {
  const taxesAndFees = getTaxRateAndTangoFees(allPricingModels);

  let subTotal = 0;
  let discountTotal = 0;
  let tax = 0;

  const allUseDefaultTaxRate = !itemsInCart.some((item) => item.taxRate);

  itemsInCart.forEach((item: any) => {
    const taxRate = item.taxRate ? item.taxRate : taxesAndFees.taxRate;
    const totalRegularPrice =
      item.price +
      item.selectedModifiers.reduce(
        (acc: any, val: any) => acc + val.additionalCost,
        0
      );
    const totalProductPrice =
      (item.discountedPrice || item.discountedPrice === 0
        ? item.discountedPrice
        : totalRegularPrice) * item.quantity;
    const totalProductDiscount =
      item.discountedPrice || item.discountedPrice === 0
        ? (totalRegularPrice - item.discountedPrice) * item.quantity
        : 0;

    subTotal += totalProductPrice;
    discountTotal += totalProductDiscount;
    tax += (taxRate / 100) * totalProductPrice;
  });

  if (isCash) {
    if (allUseDefaultTaxRate) {
      const finalTaxRate = taxesAndFees.taxRate;
      const oldPrice = subTotal + tax;
      const roundingFactor = isSpeed
        ? businessSettings?.speedCashRoundAmount || 0
        : businessSettings?.flexCashRoundAmount || 0;
      const roundingType = isSpeed
        ? businessSettings?.speedCashRoundType
        : businessSettings?.flexCashRoundType;

      if (roundingFactor !== 0) {
        switch (roundingType) {
          case "to":
            const factor = parseFloat(String(roundingFactor)) * 100;
            const upper = (Math.trunc(oldPrice / factor) + 1) * factor;
            const newToPrice =
              (upper - oldPrice) / factor < 0.5
                ? upper
                : oldPrice - (oldPrice % factor);
            subTotal = newToPrice / (1 + finalTaxRate / 100);
            tax = subTotal * (finalTaxRate / 100);
            break;
          case "down":
            const newDownPrice =
              oldPrice -
              (oldPrice % (parseFloat(String(roundingFactor)) * 100));
            subTotal = newDownPrice / (1 + finalTaxRate / 100);
            tax = subTotal * (finalTaxRate / 100);
            break;
          default:
            break;
        }
      }
    }
  }

  return {
    subTotal: subTotal,
    tax: tax,
    deliveryFee: 0,
    serviceChargeTotal: 0,
    discountTotal: discountTotal,
    tip: 0,
    grossTotal: subTotal + tax + discountTotal,
    netTotal: subTotal + tax,
    currency: currency,
  };
};
