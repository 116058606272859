import _ from "lodash";
import moment from "moment-timezone";
import { createSelector } from "reselect";

import {
  extractShiftDurationInHours,
  extractWorkEventDurationInHours,
  formatCentValue,
} from "controllers/reporting";

import { RootState } from "model/store";

import { businessSettingsSelector } from "./businessSettings";

moment.updateLocale("en", {
  week: {
    dow: 1, // First day of week is Monday
  },
});

type TimeOffMonthYear = {
  month: string;
  year: number;
  firstDayReference: Date;
  timeOffNumber: number;
};

export const businessSelector = (state: RootState): TangoBusiness =>
  state.business;

export const draftScheduleSelector = (state: RootState): TangoSchedule[] =>
  state.draftSchedules;
export const duplicatedSchedulesSelector = (
  state: RootState
): TangoSchedule[] => state.fixedSchedules;
export const staffingAppDataSelector = (state: RootState): StaffingRedux =>
  state.staffing;
export const fellowStaffMembersSelector = (state: RootState): StaffMember[] =>
  state.fellowStaffMembers;

export const workEventsSelector = (state: RootState): WorkEvent[] =>
  state.workEvents;

export const adminsSelector = createSelector(
  fellowStaffMembersSelector,
  (fellowStaffMembers: StaffMember[]) => {
    return fellowStaffMembers.filter((sm) => sm.isAdmin);
  }
);

export const staffSelector = createSelector(
  (state: RootState) => state.fellowStaffMembers,
  (state: RootState) => state.staffMembers,
  (fellow: StaffMember[], staff: StaffMember[]) => {
    if (staff.length) {
      return staff;
    }
    return [...staff, ...fellow];
  }
);

type DailyActual = {
  day: string;
  amount: number;
  foh: number;
  boh: number;
};

type DailyActualGroupedByRoles = {
  day: string;
  amount: number;
  bohRoles: {
    roleId: string;
    roleTitle: string;
    dollarAmount: number;
    percentage: number;
  }[];
  fohRoles: {
    roleId: string;
    roleTitle: string;
    dollarAmount: number;
    percentage: number;
  }[];
};

type CostScheduleData = {
  scheduleId: string;
  targetScheduleCost: number;
  actualScheduleCost: number;
  actualFohCost: number;
  actualBohCost: number;
  amountOverBudget: string;
  dailyActuals: DailyActual[];
  dailyActualsAsPercentagesOfProjectedSales?: DailyActual[];
  dailyProjectedSales?: { [dayName: string]: DailyProjectedSales };
  isOverBudget: boolean;
  optionalPrecomposedDuplicateSchedule?: TangoSchedule | null;
  projectedSales: number;
  bohScheduleId: string | null;
  fohScheduleId: string | null;
  selectedDepartment: "boh" | "foh" | undefined;
  groupedDailyFOHProjectionsInPerc?: {
    [dayName: string]: {
      percentage: number;
      dayNameString: string;
      dayIndex: number;
    };
  };
  groupedDailyBOHProjectionsInPerc?: {
    [dayName: string]: {
      percentage: number;
      dayNameString: string;
      dayIndex: number;
    };
  };
  groupedDailyFOHProjectionsInDollars?: {
    [dayName: string]: {
      amount: number;
      dayNameString: string;
      dayIndex: number;
    };
  };
  groupedDailyBOHProjectionsInDollars?: {
    [dayName: string]: {
      amount: number;
      dayNameString: string;
      dayIndex: number;
    };
  };

  fohTargetLaborCostAsPercentage?: {
    [dayName: string]: TargetLaborCostAsPercentage;
  };
  bohTargetLaborCostAsPercentage?: {
    [dayName: string]: TargetLaborCostAsPercentage;
  };
  fohTargetLaborCostAsDollars?: {
    [x: string]: {
      dollars: number;
      percentage: number;
      dayNameString: string;
      dayIndex: number;
    };
  };
  bohTargetLaborCostAsDollars?: {
    [x: string]: {
      dollars: number;
      percentage: number;
      dayNameString: string;
      dayIndex: number;
    };
  };
  avgBohTargetLaborCostAsPercentage?: number;
  avgFohTargetLaborCostAsPercentage?: number;
  avgBohTargetLaborCostAsDollars?: number;
  avgFohTargetLaborCostAsDollars?: number;
  dailyActualsGroupedByRoles?: DailyActualGroupedByRoles[];
};

export const selectCostScheduleData = createSelector(
  businessSelector,
  draftScheduleSelector,
  staffingAppDataSelector,
  fellowStaffMembersSelector,
  (
    business: TangoBusiness,
    draftSchedules: TangoSchedule[],
    staffingData: StaffingRedux,
    fellowStaffMembers: StaffMember[]
  ): CostScheduleData | null => {
    const currentSchedule = staffingData?.currentSchedule;
    if (!currentSchedule?.scheduleId) {
      return null;
    }
    const scheduleType = currentSchedule?.scheduleType;
    const getScheduleForCalculations = ():
      | {
          mergedSchedule: TangoSchedule;
          bohSchedule: TangoSchedule;
          fohSchedule: TangoSchedule;
        }
      | null
      | undefined => {
      if (scheduleType === "draft") {
        const bohSchedule = draftSchedules?.find(
          (schedule) => schedule?.id === currentSchedule?.bohScheduleId
        );
        if (bohSchedule)
          bohSchedule.shifts = bohSchedule?.shifts.map((s) => ({
            ...s,
            department: "boh",
          }));
        const fohSchedule = draftSchedules?.find(
          (schedule) => schedule?.id === currentSchedule?.fohScheduleId
        );
        if (fohSchedule)
          fohSchedule.shifts = fohSchedule?.shifts.map((s) => ({
            ...s,
            department: "foh",
          }));
        if (bohSchedule && fohSchedule) {
          const mergedSchedule: TangoSchedule = {
            ...fohSchedule,
            departmentId: fohSchedule.departmentId || "foh",
            targetScheduleCost:
              (bohSchedule?.targetScheduleCost ?? 0) +
              (fohSchedule?.targetScheduleCost ?? 0),
            shifts: [...bohSchedule.shifts, ...fohSchedule.shifts],
            mergedSchedules: [fohSchedule.id, bohSchedule.id],
          };
          return {
            mergedSchedule,
            bohSchedule,
            fohSchedule,
          };
        }
        return null;
      }
      return null;
    };
    const scheduleForCalculations = getScheduleForCalculations();

    console.log("scheduleForCalculations", scheduleForCalculations);
    if (!scheduleForCalculations) {
      return null;
    }
    if (!scheduleForCalculations) return null;
    const assignedShifts =
      scheduleForCalculations.mergedSchedule?.shifts?.filter((shift) =>
        Boolean(shift?.staffId)
      );
    let targetScheduleCost = 100;
    let projectedSales = 100;

    if (
      !_.isNil(scheduleForCalculations.mergedSchedule?.projectedSales) ||
      scheduleForCalculations.mergedSchedule?.projectedSales === 0
    ) {
      projectedSales = scheduleForCalculations.mergedSchedule.projectedSales;
    }
    if (
      !_.isNil(business?.defaultWeeklyStaffBudget) ||
      business?.defaultWeeklyStaffBudget === 0
    ) {
      targetScheduleCost = business.defaultWeeklyStaffBudget;
    }
    if (
      !_.isNil(scheduleForCalculations.mergedSchedule?.targetScheduleCost) ||
      scheduleForCalculations.mergedSchedule?.targetScheduleCost === 0
    ) {
      targetScheduleCost =
        scheduleForCalculations.mergedSchedule.targetScheduleCost;
    }
    const getPayrateByPostionAndStaffId = (
      positionId: string,
      staffId: string
    ) => {
      const staffMember = fellowStaffMembers.find((s) => s?.id === staffId);
      const payRates = staffMember?.payRates ?? [];
      const rolePayrate = payRates.find(
        (payRate) => payRate?.roleId === positionId
      );
      if (rolePayrate?.amount) {
        return rolePayrate?.amount;
      }
      return 0;
    };
    const actualCostReducer = (
      accumulator: {
        total: number;
        foh: number;
        boh: number;
      },
      item: TangoShift
    ) => {
      if (item?.staffId) {
        const hoursDuration = Math.abs(
          moment
            .duration(
              moment(item?.startDate.toMillis()).diff(
                moment(item?.endDate.toMillis())
              )
            )
            .asHours()
        );
        console.log("Item: ", item);
        const department = item.department;
        const result = { ...accumulator };
        const amount =
          getPayrateByPostionAndStaffId(item.position, item.staffId) *
          hoursDuration;
        result.total += amount;
        if (department == "boh") {
          result.boh += amount;
        }
        if (department == "foh") {
          result.foh += amount;
        }
        return result;
      }
      return accumulator;
    };
    const groupedDailyActuals = _.groupBy(assignedShifts, (shift) => {
      const shiftStartDateWithTimezone = moment(shift?.startDate?.toDate());
      shiftStartDateWithTimezone.tz(business.timezone, true);
      const dayIndex = shiftStartDateWithTimezone.day();
      return moment.weekdays(true)[dayIndex];
    });
    const actualScheduleCost = assignedShifts.reduce(actualCostReducer, {
      total: 0,
      foh: 0,
      boh: 0,
    });
    const weekDays = moment.weekdays(true);
    const dailyActuals: DailyActual[] = weekDays.map((weekday, index) => {
      const shiftsForTheWeekDay = groupedDailyActuals?.[index];
      if (shiftsForTheWeekDay?.length) {
        const weekdayAmount = shiftsForTheWeekDay.reduce(actualCostReducer, {
          total: 0,
          foh: 0,
          boh: 0,
        });
        return {
          day: weekday,
          amount: weekdayAmount.total,
          foh: weekdayAmount.foh,
          boh: weekdayAmount.boh,
        };
      }
      return { day: weekday, amount: 0, foh: 0, boh: 0 };
    });
    const amountOverBudget =
      targetScheduleCost < actualScheduleCost.total
        ? (
            (actualScheduleCost.total - targetScheduleCost) /
            100
          ).toLocaleString("en-US", { style: "currency", currency: "USD" })
        : (0).toLocaleString("en-US", { style: "currency", currency: "USD" });
    const fohScheduleId = currentSchedule?.fohScheduleId;
    const bohScheduleId = currentSchedule?.bohScheduleId;

    return {
      scheduleId: scheduleForCalculations.mergedSchedule?.id,
      targetScheduleCost,
      actualScheduleCost: actualScheduleCost.total,
      actualFohCost: actualScheduleCost.foh,
      actualBohCost: actualScheduleCost.boh,
      amountOverBudget,
      isOverBudget: targetScheduleCost < actualScheduleCost.total,
      dailyActuals,
      optionalPrecomposedDuplicateSchedule:
        currentSchedule?.optionalPrecomposedDuplicateSchedule,
      projectedSales,
      fohScheduleId,
      bohScheduleId,
      selectedDepartment: currentSchedule.departmentId,
    };
  }
);

export const selectScheduleDates = createSelector(
  businessSelector,
  staffingAppDataSelector,
  (business, appData) => {
    const tz = business?.timezone ?? "EST";
    const days: number[] = [];
    const dayNames: string[] = [];
    const currentSchedule = appData.currentSchedule;
    if (currentSchedule?.scheduleId) {
      let currMoment = moment(currentSchedule.startDate).tz(tz).startOf("day");
      const endMoment = moment(currentSchedule.endDate).tz(tz).startOf("day");
      while (currMoment.isSameOrBefore(endMoment)) {
        days.push(
          currMoment.year() * 10000 +
            (1 + currMoment.month()) * 100 +
            currMoment.date()
        );
        dayNames.push(currMoment.format("dddd"));
        currMoment = currMoment.add(1, "day").startOf("day");
      }
    }
    return days.map((day, idx) => ({
      day,
      dayName: dayNames[idx],
    }));
  }
);
