import { useMutation, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  UpdateSchedulingSettingsDTO,
  fetchSchedulingSettingsByBusinessId,
  updateSchedulingSettings,
} from "controllers/schedulingSettings";

import { RootState } from "model/store";

export const useSettingsViewModel = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const businessId = useMemo(() => business?.id, [business]);

  const schedulingSettings = useQuery(
    ["schedulingSettings", businessId],
    () => {
      return fetchSchedulingSettingsByBusinessId(businessId);
    },
    {
      enabled: !!businessId,
    }
  );

  const updateScheduleSettings = useMutation(
    (dto: UpdateSchedulingSettingsDTO) => {
      return updateSchedulingSettings(dto);
    }
  );
  return {
    schedulingSettings,
    updateScheduleSettings,
  };
};
