import React, { useCallback, useEffect, useState } from "react";
import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";
import Sidebar from "components/Sidebar";
import { headerRoutes, sidebarRoutes } from "./routes";
import UIComponents from '@tangopay/tango-ui-library';
import { Route, Routes, useNavigate } from "react-router-dom";
import { downloadCSV } from "controllers/payments";
import useGiftCards, { GiftCardProps } from "./useGiftCards";
import GiftCardDetails from "./GiftCardDetails/Index";

const { Modal, Icon, Button } = UIComponents;

const GiftCardsScreen = () => {

    const {
        businessId,
        data,
        noOp,
        columns,
        instructions,
        isEditing,
        setEditing,
        setLastGiftCardId,
        loading,
        setPreviousPageIds,
        previousPageIds,
        setPageNumber,
        pageNumber,
        setAllGiftCards,
        allGiftCards,
        setDisplayCounterMessage,
        displayCounterMessage
    } = useGiftCards();

    useEffect(() => {
        if (allGiftCards.length > 0 && pageNumber) {
            setDisplayCounterMessage(allGiftCards[pageNumber - 1].length * (pageNumber + 1) - 20 + ' - ' + allGiftCards[pageNumber - 1].length * (pageNumber + 1))
        } else {
            setDisplayCounterMessage('1 - 20')
        }
    }, [allGiftCards])

    const navigate = useNavigate();
    const nav = useCallback((id: string) => {
        const giftCardClicked = data.filter((giftCard: GiftCardProps) => {
            return giftCard.uniqueId === id
        })
        if (giftCardClicked) {
            navigate(`${id}`, { state: giftCardClicked[0] });
        }
    }, [navigate, data]);


    const returnTable = () => {
        return (
            <div className="absolute top-[63px] bottom-10 w-full">
                <Header routes={headerRoutes} selectedRoute={"/payments/gift-cards"} selectedPage={{ label: "Customers", value: "/payments/payments/gift-cards" }} />
                <div className="flex h-full">
                    <div
                        className={"border-r border-grey-1 border-t-0 border-b-0"}
                        style={{ width: "10%", borderRightStyle: "solid" }}
                    >
                        <Sidebar routes={sidebarRoutes} />
                    </div>

                    <div className={"flex px-10 py-6 overflow-y-auto"} style={{ width: "90%" }}>
                        <HorizontalTable
                            title="Gift Cards"
                            columns={columns}
                            data={data}
                            instructions={instructions}
                            saveResults={noOp}
                            setEditing={setEditing}
                            isEditing={isEditing}
                            hideEdit
                            onRowClick={nav}
                            customHeaderRightContent={
                                <div className="flex items-center">
                                    <Button
                                        label="Export"
                                        size="btn-medium"
                                        type="btn-style-1"
                                        onClick={async () =>
                                            await downloadCSV(businessId, data, 'gift-cards')
                                        }
                                    />
                                </div>
                            }
                        />
                    </div>
                </div>


                <Modal
                    isOpen={loading}
                    closeModal={() => { }}
                    width={"472"}
                >
                    <div>
                        <div className={"text-2xl text-black font-lato-black"}>
                            Please wait, getting gift cards...
                        </div>
                        <div className="flex mt-8 center h-full w-full justify-center content-center items-center">
                            <div className="loading-spinner"></div>
                        </div>
                    </div>
                </Modal>


                {data.length > 0 &&
                    <div className="flex justify-between items-center" style={{ marginLeft: '12%', marginRight: "2%", }}>
                        <div className="mt-7 flex items-center justify-start">
                            Viewing {displayCounterMessage}
                        </div>
                        <div className="mt-7 flex items-center justify-end">
                            <Icon name="chevron-left" className=" cursor-pointer" size="20" onClick={() => {
                                if (pageNumber > 0 && previousPageIds.length > 0 && allGiftCards.length > 0) {
                                    setLastGiftCardId(previousPageIds[pageNumber])
                                    setPreviousPageIds([
                                        ...previousPageIds.slice(0, previousPageIds.length - 1),
                                        ...previousPageIds.slice(previousPageIds.length)
                                    ]);
                                    setAllGiftCards([
                                        ...allGiftCards.slice(0, allGiftCards.length - 1),
                                        ...allGiftCards.slice(allGiftCards.length)
                                    ]);
                                    setPageNumber(pageNumber - 1)
                                }
                            }} />

                            <Icon name="chevron-right" className="cursor-pointer ml-3" size="20" onClick={() => {
                                //prevent clicking next if at the end of list or if somehow giftcard has no id
                                if (!data[data.length - 1].uniqueId || data.length < 20) {
                                    return
                                }
                                setPageNumber(pageNumber + 1)
                                setPreviousPageIds([...previousPageIds, data[0].uniqueId])
                                setLastGiftCardId(data[data.length - 1].uniqueId)
                                setAllGiftCards([...allGiftCards, data])
                            }} />
                        </div>
                    </div>
                }
            </div>
        )
    }

    const returnGiftCardDetails = () => {
        return (
            <GiftCardDetails />
        )
    }

    return (
        <div className="bottom-0 w-full overflow-hidden">
            <div className="flex h-full">
                <Routes>
                    <Route index element={returnTable()} />
                    <Route
                        path=":id"
                        element={returnGiftCardDetails()}
                    />
                </Routes>
            </div>
        </div>
    )
};

export default GiftCardsScreen;
