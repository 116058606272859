import firebase from '../config/firebase';
import { FirebaseModifierDoc, ModifierDoc } from '../types/modifiers';
import { FirebaseProductDoc } from '../types/products';
import { isEqual } from '../utils/data';
import _ from 'lodash';
import isDev from 'utils/currentEnv';

const db = firebase.firestore();
const modifiersCollection = db.collection('Modifiers');
const modifierOptionsCollection = db.collection('ModifierOptions');
const productsCollection = db.collection('Products');

export const performSoftDelete = async (modifiers: FirebaseModifierDoc[] | ModifierDoc[], products: FirebaseProductDoc[]) => {
  const productsToUpdate: { id: string; modifiers: string[] }[] = [];
  const productIdsToUpdate: string[] = [];

  // Modifiers and Modifier Options to delete
  const existingModifiers = [];
  const existingModifierOptions = [];

  for (const modifier of modifiers) {
    // Soft delete the modifier using the modifier id
    if (modifier.id && modifier.id.length > 0) {
      existingModifiers.push({ id: modifier.id, deleted: true });
    }

    // Soft delete the modifier options for this modifier
    if (modifier.options && modifier.options.length > 0) {
      for (const option of modifier.options) {
        if (typeof option === 'string') {
          if (option && option.length > 0) {
            existingModifierOptions.push({ id: option, deleted: true });
          }
        } else {
          if (option.id && option.id.length > 0) {
            existingModifierOptions.push({ id: option.id, deleted: true });
          }
        }
      }
    }

    // Check all products to see if this product is included
    products.forEach((product) => {
      // If modifier id exists
      if (modifier.id) {
        // Found modifier id to remove from a product
        if (product.modifiers.includes(modifier.id)) {
          if (productIdsToUpdate.includes(product.id)) {
            const index = productsToUpdate.findIndex((doc) => doc.id === product.id);
            productsToUpdate[index] = {
              id: product.id,
              modifiers: productsToUpdate[index].modifiers.filter((existingModifier) => existingModifier !== modifier.id),
            };
          } else {
            productsToUpdate.push({
              id: product.id,
              modifiers: product.modifiers.filter((existingModifier) => existingModifier !== modifier.id),
            });
            productIdsToUpdate.push(product.id);
          }
        }
      }
    });
  }

  // Batch delete all the existing modifiers
  const existingModifiersChunk = _.chunk(existingModifiers, 500);
  for await (const chunk of existingModifiersChunk) {
    const batch = db.batch();
    chunk.forEach((modifier) => {
      const docRef = modifiersCollection.doc(modifier.id);
      batch.update(docRef, modifier);
    });
    try {
      await batch.commit();
    } catch (err) {
      if (isDev()) {
        console.log('ERROR Occured when deleting modifiers: ', err);
      }
    }
  }

  // Batch delete all the existing modifier options
  const existingModifierOptionsChunk = _.chunk(existingModifierOptions, 500);
  for await (const chunk of existingModifierOptionsChunk) {
    const batch = db.batch();
    chunk.forEach((modifierOption) => {
      const docRef = modifierOptionsCollection.doc(modifierOption.id);
      batch.update(docRef, modifierOption);
    });
    try {
      await batch.commit();
    } catch (err) {
      if (isDev()) {
        console.log('ERROR Occured when deleting modifier options: ', err);
      }
    }
  }

  // Batch update all the products
  const productsToUpdateChunk = _.chunk(productsToUpdate, 500);
  for await (const chunk of productsToUpdateChunk) {
    const batch = db.batch();
    chunk.forEach((product) => {
      const docRef = productsCollection.doc(product.id);
      batch.update(docRef, { modifiers: product.modifiers });
    });
    try {
      await batch.commit();
    } catch (err) {
      if (isDev()) {
        console.log('ERROR Occured when deleting updating products: ', err);
      }
    }
  }
};

export const saveData = async (
  newData: ModifierDoc[],
  existingData: ModifierDoc[],
  businessId: string,
  modifierOptionsRef: { [T: string]: string },
  isEnterpriseLevel: boolean,
  allModifiers: any[],
) => {
  const firebaseModifiers: FirebaseModifierDoc[] = newData
    .map((modifier, index) => ({ ...modifier, index: index }))
    .filter((modifier) => {
      const modifierToCheck = _.omit(modifier, 'index');
      const foundItem = existingData.some((existingModifier) => isEqual(existingModifier, modifierToCheck));

      // Remove modifier documents that have not been edited since existingData
      if (foundItem) {
        return false;
      }
      return true;
    })
    .map((modifier, index) => {
      return {
        businessId: businessId,
        createdAt: new Date(),
        deleted: false,
        description: modifier.description || '',
        enabled: true, // TODO: Do we need this?
        id: modifier.id,
        imageUrl: null,
        // @ts-ignore
        max: parseInt(modifier.max),
        // @ts-ignore
        min: parseInt(modifier.min),
        nameExternal: modifier.nameExternal,
        nameInternal: modifier.nameInternal,
        options: modifier.modifierOptions.map((optionName) => modifierOptionsRef[optionName]).filter((i) => !!i),
        // options: modifier.options.map((option) => option.id),
        tags: [],
        plu: modifier.plu ? modifier.plu : modifier.id, //IF EXIST MODIFIER THAT WAS CHANGED HAS NO PLU SET WITH THE ID
        updatedAt: new Date(),
        // plu: Object.keys(modifier).includes("plu") ? modifier.plu : null,
        enterpriseUpdatedDocument: isEnterpriseLevel,
      };
    });

  const newModifiers = [];
  const existingModifiers = [];
  for (const modifier of firebaseModifiers) {
    // For existing modifiers - just find the differences to update
    if (modifier.id) {
      const existingModifier = existingData.find((existingModifier) => existingModifier.id === modifier.id);
      // const index = existingData.findIndex(
      //   (existingProduct) => existingProduct.id === modifier.id
      // );

      const newDataWithPLU = newData.find((d) => d.plu === modifier.plu);

      if (existingModifier) {
        const dataToUpdate: { [T: string]: any } = Object.keys(existingModifier)

          .filter((key) => {
            // Edge case for options in the new modifier options
            // and modifiers split
            if (key === 'options') {
              const newModifierOptions = (newDataWithPLU?.['modifierOptions'] || []).map((optionName) => modifierOptionsRef[optionName]).filter((i) => !!i);
              return !isEqual(existingModifier[key], newModifierOptions) && Object.keys(modifier).includes(key);
            }
            return (
              // @ts-ignore
              !isEqual(existingModifier[key], newDataWithPLU?.[key]) && Object.keys(modifier).includes(key)
            );
          })
          .reduce(
            (acc, key) => ({
              ...acc,
              // @ts-ignore
              [key]: modifier[key],
              enterpriseUpdatedDocument: isEnterpriseLevel,
            }),
            {},
          );

        existingModifiers.push({ id: modifier.id, ...dataToUpdate });
      } else {
        const docRef = modifiersCollection.doc();
        modifier['id'] = docRef.id;
        modifier['plu'] = docRef.id; //use id as the plu for newly created modifiers

        const finalModifier = _.omit(modifier, 'index');

        newModifiers.push(finalModifier);
      }
    } else {
      const docRef = modifiersCollection.doc();
      modifier['id'] = docRef.id;
      modifier['plu'] = docRef.id; //use id as the plu for newly created modifiers

      const finalModifier = _.omit(modifier, 'index');

      newModifiers.push(finalModifier);
    }
  }

  // Batch write new modifiers
  _.chunk(newModifiers, 500).forEach((modifiers) => {
    const batch = db.batch();
    modifiers.forEach((modifier) => {
      const docRef = modifiersCollection.doc(modifier.id);
      batch.set(docRef, modifier);
    });
    batch.commit();
  });

  // Batch write existing modifier options
  _.chunk(existingModifiers, 500).forEach((modifiers) => {
    const batch = db.batch();
    modifiers.forEach((modifier) => {
      const docRef = modifiersCollection.doc(modifier.id);
      batch.update(docRef, modifier);
    });
    batch.commit();
  });

  return {
    success: true,
  };
};
