import * as Sentry from "@sentry/react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Audiences from "views/Audiences";
import AddSubscrber from "views/Audiences/AddSubscriber";
import EditSubscriber from "views/Audiences/EditSubscriber";
import AudienceProfile from "views/Audiences/profile";
import BusinessInfo from "views/BusinessAdmin/BusinessInfo";
import ActivityReport from "views/BusinessAdmin/Reporting/ActivityReport";
import BankDeposit from "views/BusinessAdmin/Reporting/BankDeposit";
import CashSummary from "views/BusinessAdmin/Reporting/CashSummary";
import CloseOutReportModal from "views/BusinessAdmin/Reporting/CloseOutReport";
import CoreReports from "views/BusinessAdmin/Reporting/CoreReports";
import DailyLaborReport from "views/BusinessAdmin/Reporting/DailyLaborReport";
import DailySalesReport from "views/BusinessAdmin/Reporting/DailySalesReport";
import DiscountSummaryReport from "views/BusinessAdmin/Reporting/DiscountSummary";
import DistributionAndLaborReport from "views/BusinessAdmin/Reporting/DistributionAndLaborReport";
import LaborPayroll from "views/BusinessAdmin/Reporting/LaborPayroll";
import PayPeriodLaborReport from "views/BusinessAdmin/Reporting/PayPeriodLaborReport";
import ProductAnalysis from "views/BusinessAdmin/Reporting/ProductAnalysis";
import SalesMixReport from "views/BusinessAdmin/Reporting/SalesMix";
import SalesMixReportByServerCSV from "views/BusinessAdmin/Reporting/SalesMixByServerCSV";
import SalesMixReportCSV from "views/BusinessAdmin/Reporting/SalesMixCSV";
import TimeCardSummary from "views/BusinessAdmin/Reporting/TimeCardSummary";
import TipsByMenu from "views/BusinessAdmin/Reporting/TipsByMenu";
import VoidSummary from "views/BusinessAdmin/Reporting/VoidSummary";
import WeeklyEmployeeSalarySummary from "views/BusinessAdmin/Reporting/WeeklyEmployeeSalarySummary";
import WeeklySalesMixReport from "views/BusinessAdmin/Reporting/WeeklySalesMix";
import WeeklySalesSummary from "views/BusinessAdmin/Reporting/WeeklySalesSummary";
import Settings from "views/BusinessAdmin/Settings";
import Campaigns from "views/Campaigns";
import CreativeStudio from "views/Campaigns/CreativeStudio";
import CreateCampaign from "views/Campaigns/createCampaign";
import CampaignDashboard from "views/Campaigns/dashboard";
import EditCampaign from "views/Campaigns/editCampaign";
import Templates from "views/Campaigns/templates";
import CloudFiles from "views/Cloud/Files";
import CommsWrapper from "views/Comms/Chat";
import ErrorPage from "views/ErrorPage";
import Flex from "views/Flex";
import GetStarted from "views/GetStarted";
import HelpSupport from "views/HelpSupport";
import MobileFriednlyHowToVideos from "views/HelpSupport/HowToVideos/MobileFriendlyHowToVideos";
import InventoryMeasurements from "views/InventoryManagement/Measurements";
import InventoryLocations from "views/InventoryManagement/Locations";
import InventoryWaste from "views/InventoryManagement/Waste";
import { LaunchpadContext, Overlay } from "views/LaunchPad/LaunchpadProvider";
import GiftCards from "views/MarketingFeedback/GiftCards";
import Reviews from "views/ReputationManagement/Reviews";
import DeviceSetup from "views/StoresAndOps/DeviceSetup";
import TableManagement from "views/StoresAndOps/TableManagement";
import SubAppsIntro from "views/SubAppsIntro";
import SurveyBuilder from "views/SurveyBuilder";
import ViewApplications from "views/manager/Hiring/ViewApplications";
import ViewArchives from "views/manager/Hiring/ViewArchives";
import OrganizationalHierarchy from "views/manager/OrganizationalHierarchy";
import RoleForm from "views/manager/OrganizationalHierarchy/RoleForm";
import SelectBusinessType from "views/manager/SelectBusinessType";
import Document from "views/manager/Staffing/Docs/Document";
import Spreadsheet from "views/manager/Staffing/Docs/Spreadsheet";
import AddEmployee from "views/manager/Team/AddEmployee";
import ApplicationTracker from "views/manager/Team/ApplicationTracker";
import CreateJobPosting from "views/manager/Team/CreateJobPosting";
import EditEmployee from "views/manager/Team/EditEmployee";
import EditJobPosting from "views/manager/Team/EditJobPosting";
import Profile from "views/manager/Team/Profile";
// import Menus from "./views/menuBuilder/AllMenus";
// TODO: Apply the changes to the new menu builder
import Menus from "views/menuBuilder/Menus";

import {
  composeUsableTangoAccount,
  composeUsableTangoBusiness,
} from "controllers/composableTypes";
import { getCloseOutReportForADateRange } from "controllers/reporting";

import Box from "components/Box";
import PrivateRouteWrapper from "components/Navigation/PrivateRoute";
import PublicRoute from "components/Navigation/PublicRoute";
import PublicRouteWrapper from "components/Navigation/PublicRoute";
import Spinner from "components/Spinner";

import componentLoader from "utils/codeSplitting";

import { receiveAccount } from "model/actions/accountsAC";
import { changeEmulatorMode } from "model/actions/emulatorModeAC";
import store, { RootState } from "model/store";

import "../tailwind.css";
import "./App.css";
import firebase from "./config/firebase";
import { subscribeToCollectionDocument } from "./controllers/core";
import {
  initializeData,
  initializeEnterpriseData,
  setUserSubscription,
  switchCurrentLocation,
} from "./controllers/init";
import { clearListeners, clearUserListener } from "./controllers/listeners";
import { receiveBusiness } from "./model/actions/businessAC";
import { logoutUser } from "./model/actions/userAC";
import { isElectron } from "./utils";
import Reporting from "./views/BusinessAdmin/Reporting";
import Calendar from "./views/Comms/Calendar";
import DashBoard from "./views/Dashboard";
import EnterPriseDashboard from "./views/Enterprise/Dashboard";
import StoreEmulator from "./views/Enterprise/StoreEmulator";
import FormPreview from "./views/FormPreview";
import FormResponses from "./views/FormResponses";
import ReportBug from "./views/HelpSupport/ReportBug";
import Allergens from "./views/InventoryManagement/Allergens";
import Conversions from "./views/InventoryManagement/Conversions";
import CountDetail from "./views/InventoryManagement/CountDetail";
import Counts from "./views/InventoryManagement/Counts";
import CurrentInventory from "./views/InventoryManagement/CurrentInventory";
import InventoryInvoices from "./views/InventoryManagement/Invoices";
import InventoryItemDetail from "./views/InventoryManagement/ItemDetail";
import RawItems from "./views/InventoryManagement/Items";
import RecipesItems from "./views/InventoryManagement/RecipesItems";
import InventoryRecipesPrepItems from "./views/InventoryManagement/RecipesPrepItems";
import InventoryRecipesPrepItemDetail from "./views/InventoryManagement/RecipesPrepItemDetail";
import InventoryRecipesMenuItems from "./views/InventoryManagement/RecipesMenuItems";
import InventoryRecipesMenuItemDetail from "./views/InventoryManagement/RecipesMenuItemDetail";
import InventoryRecipesModifiers from "./views/InventoryManagement/RecipesModifiers";
import InventoryRecipesModifierDetail from "./views/InventoryManagement/RecipesModifierDetail";
import RecipesVendorDetail from "./views/InventoryManagement/RecipesVendorDetail";
import InventoryRecipesVendorItems from "./views/InventoryManagement/RecipesVendorItems";
import ReplaceItem from "./views/InventoryManagement/ReplaceItem";
import InventoryVendorDetail from "./views/InventoryManagement/VendorDetail";
import InventoryVendors from "./views/InventoryManagement/Vendors";
import ReviewsTemplates from "./views/ReputationManagement/Templates";
import RenderForm from "./views/SurveyBuilder/renderForm";
import ViewForms from "./views/ViewForms";
import ManagerVerificationCodeScreen from "./views/manager/EnterVerificationCode";
import Manager from "./views/manager/Manager";
import ManagerSignInScreen from "./views/manager/SignIn";
import Discounts from "./views/menuBuilder/Discounts";
import ModifierOptions from "./views/menuBuilder/ModifierOptions";
import Modifiers from "./views/menuBuilder/Modifiers";
import Products from "./views/menuBuilder/Products";
import PublishMenu from "./views/menuBuilder/PublishMenu";
import NotFound from "./views/notFound/NotFound";
import CreateInvoice from "views/InventoryManagement/CreateInvoice";
import PrepItems from "views/InventoryManagement/PrepItems";
import ClosedChecks from "views/Payments/ClosedChecks";
import PaymentsScreen from "views/Payments/PaymentsScreen";
import GiftCardsScreen from "views/Payments/GiftCards/index";
import Disputes from "views/Payments/Disputes"
import DiscountedPayments from "views/Payments/DiscountedPayments";
import DisputeDetail from "views/Payments/DisputeDetail";
import SubmitEvidence from "views/Payments/SubmitEvidence";
import PrimaryGroups from "views/InventoryManagement/PrimaryGroups";
import SecondaryGroups from "views/InventoryManagement/SecondaryGroups";
import Boards from "views/manager/Boards";
import DocumentsStack from "views/Cloud/Files";

export const SwitchLocationContext = createContext({
  switchLocation: (uid: string, locationId: string) => { },
});

export const useSwitchLocation = () => useContext(SwitchLocationContext);

function App() {
  const [loading, setLoading] = useState(true); // default to loading
  const [business, setBusiness] = useState<TangoBusiness | null>(null);
  const [currentUser, setCurrentUser] = useState<StaffMember | null>(null);
  const [launchpadVisible, setLaunchpadVisibility] = useState(false);
  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);
  const currentBusiness: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const navigate = useNavigate();
  const updateLaunchpadVisibility = (val: boolean) => {
    setLaunchpadVisibility(val);
  };

  const switchLocation = useCallback(
    async (uid: string, locationId: string) => {
      await switchCurrentLocation(uid, locationId);
      subscribeToCollectionDocument<TangoBusiness>(
        "Businesses",
        locationId,
        receiveBusiness,
        "business",
        composeUsableTangoBusiness,
        undefined,
        setBusiness
      );
    },
    []
  );

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        clearListeners();
        clearUserListener();
        store.dispatch(logoutUser());
        localStorage.removeItem("locationId");
        setLoading(false);
      } else {
        console.log("here 1");
        const tokenResult = await user.getIdTokenResult();
        setLoading(false);
        console.log("User: ", user);
        const result = await setUserSubscription(
          user,
          tokenResult.claims.admin
        );

        console.log("result ttt", result);

        if (!result.data) {
          clearListeners();
          clearUserListener();
          localStorage.removeItem("locationId");
          store.dispatch(logoutUser());
        } else if (result?.data?.isAdmin) {
          const user: StaffMember = result.data;

          if (user.accountId) {
            subscribeToCollectionDocument<TangoAccount>(
              "Accounts",
              result.data.accountId,
              receiveAccount,
              "account",
              composeUsableTangoAccount,
              undefined,
              () => { }
            );
          }

          if (user.corporateAccess) {
            // navigate("/enterprise");
            initializeEnterpriseData(result.data);
            setCurrentUser(result.data);
          } else {
            subscribeToCollectionDocument<TangoBusiness>(
              "Businesses",
              result.data.businessId,
              receiveBusiness,
              "business",
              composeUsableTangoBusiness,
              undefined,
              setBusiness
            );
            initializeData(result.data);
          }
        } else {
          localStorage.removeItem("locationId");
          clearListeners();
          clearUserListener();
          store.dispatch(logoutUser());
          setLoading(false);
          setBusiness(null);
        }
      }
    });
  }, []);

  return (
    //@ts-ignore
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <div className={`App ${emulatorMode ? "has-emulator" : ""}`}>
        {loading ? (
          <Spinner />
        ) : (
          <SwitchLocationContext.Provider
            value={{
              switchLocation,
            }}
          >

            {emulatorMode ? (
              <div className="emulator-top-bar">
                <div
                  className="exit"
                  onClick={async () => {
                    const user = { ...currentUser };
                    user.businessId =
                      business?.id || currentBusiness
                        ? currentBusiness.id
                        : "";
                    clearListeners();
                    clearUserListener();
                    store.dispatch(changeEmulatorMode(false));
                    navigate("/store-emulator");

                    if (user) {
                      // @ts-ignore
                      await initializeEnterpriseData(user);
                    }
                  }}
                >
                  <img
                    src={require("assets/back-arrow.svg").default}
                    alt="previous icon"
                    className="activity-arrow-back"
                  />
                  Exit
                </div>
                <div className="outer-name">
                  <div className="business-name">
                    {currentBusiness?.businessName || "Emulator mode"}
                  </div>
                </div>
              </div>
            ) : null}

            {/*<CommsWrapper>*/}
            <Routes>
              <Route
                path="manager/*"
                element={
                  <PrivateRouteWrapper business={business} path="manager/*">
                    <Manager />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="help/*"
                element={
                  <PrivateRouteWrapper business={business} path="help">
                    <HelpSupport />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="report-a-bug"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="report-a-bug"
                  >
                    <ReportBug />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="document/:docId"
                element={
                  <PrivateRouteWrapper path="document" business={business}>
                    <Document />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="spreadsheet/:sheetId"
                element={
                  <PrivateRouteWrapper path="spreadsheet" business={business}>
                    <Spreadsheet />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="table-management"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="table-management"
                  >
                    <TableManagement />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="enterprise"
                element={
                  <PrivateRouteWrapper business={business} path="enterprise">
                    <EnterPriseDashboard />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="store-emulator"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="store-emulator"
                  >
                    <StoreEmulator />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="device-setup"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="device-setup"
                  >
                    <DeviceSetup />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="dashboard"
                element={
                  <PrivateRouteWrapper business={business} path="dashboard">
                    <DashBoard />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="flex"
                element={
                  <PrivateRouteWrapper business={business} path="flex">
                    <Flex />
                  </PrivateRouteWrapper>
                }
              />
              {/* <Route
    path="launchpad"
    element={
      <PrivateRouteWrapper business={business} path="launchpad">
        <LaunchPad />
      </PrivateRouteWrapper>
    }
  />  */}
              <Route
                path="menus"
                element={
                  <PrivateRouteWrapper business={business} path="menus">
                    <Menus />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="publish-menu"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="publish-menu"
                  >
                    <PublishMenu />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="products"
                element={
                  <PrivateRouteWrapper business={business} path="products">
                    <Products />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="modifiers"
                element={
                  <PrivateRouteWrapper business={business} path="modifiers">
                    <Modifiers />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="modifier-options"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="modifier-options"
                  >
                    <ModifierOptions />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="discounts"
                element={
                  <PrivateRouteWrapper business={business} path="discounts">
                    <Discounts />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="services-intro"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="services-intro"
                  >
                    <SubAppsIntro />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="reporting"
                element={
                  <PrivateRouteWrapper business={business} path="reporting">
                    <Reporting />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="bank-deposit"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="bank-deposit"
                  >
                    <BankDeposit />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="product-analytics"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="product-analytics"
                  >
                    <ProductAnalysis />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="cash-summary"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="cash-summary"
                  >
                    <CashSummary />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="business-info"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="business-info"
                  >
                    <BusinessInfo />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="activity-report"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="activity-report"
                  >
                    <ActivityReport />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="labor-payroll"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="labor-payroll"
                  >
                    <LaborPayroll />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="core-reports"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="core-reports"
                  >
                    <CoreReports />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="summary-report"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="summary-report"
                  >
                    <CloseOutReportModal />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="daily-labor-report"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="daily-labor-report"
                  >
                    <DailyLaborReport />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="time-card-summary"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="time-card-summary"
                  >
                    <TimeCardSummary />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="weekly-employee-salary-summary"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="weekly-employee-salary-summary"
                  >
                    <WeeklyEmployeeSalarySummary />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="distribution-and-labor"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="distribution-and-labor"
                  >
                    <DistributionAndLaborReport />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="weekly-sales-summary"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="weekly-sales-summary"
                  >
                    <WeeklySalesSummary />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="pay-period-labor-report"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="pay-period-labor-report"
                  >
                    <PayPeriodLaborReport />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="weekly-sales-mix-report"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="weekly-sales-mix-report"
                  >
                    <WeeklySalesMixReport />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="sales-mix-report-csv"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="sales-mix-report-csv"
                  >
                    <SalesMixReportCSV />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="sales-mix-report-by-server-csv"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="sales-mix-report-by-server-csv"
                  >
                    <SalesMixReportByServerCSV />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="tip-by-menu"
                element={
                  <PrivateRouteWrapper business={business} path="tip-by-menu">
                    <TipsByMenu />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="daily-sales-report"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="daily-sales-report"
                  >
                    <DailySalesReport isMediaReport={false} />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="daily-media-report"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="daily-media-report"
                  >
                    <DailySalesReport isMediaReport={true} />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="sales-mix-report"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="sales-mix-report"
                  >
                    <SalesMixReport />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="discount-summary-report"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="discount-summary-report"
                  >
                    <DiscountSummaryReport />
                  </PrivateRouteWrapper>
                }
              />
              {/* <Route
                  path="void-summary-report"
                  element={
                    <PrivateRouteWrapper
                      business={business}
                      path="void-summary-report"
                    >
                      <VoidSummary />
                    </PrivateRouteWrapper>
                  }
                /> */}
              <Route
                path="settings"
                element={
                  <PrivateRouteWrapper business={business} path="settings">
                    <Settings />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="select-business"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="select-business"
                  >
                    <SelectBusinessType />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="gift-cards"
                element={
                  <PrivateRouteWrapper business={business} path="gift-cards">
                    <GiftCards />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="application-tracker"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="application-tracker"
                  >
                    <ApplicationTracker />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="view-profile/:staffMemberId"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="view-profile"
                  >
                    <Profile />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="view-archives"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="view-archives"
                  >
                    <ViewArchives />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="view-applications"
                element={
                  <PrivateRouteWrapper
                    path="view-applications"
                    business={business}
                  >
                    <ViewApplications />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path={`/boards`}
                element={
                  <PrivateRouteWrapper
                    path="survey-builder"
                    business={business}
                  >
                    <Boards />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path={`documents/*`}
                element={
                  <PrivateRouteWrapper
                    path="documents"
                    business={business}
                  >
                    <DocumentsStack />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path={`/forms`}
                element={
                  <PrivateRouteWrapper
                    path="documents"
                    business={business}
                  >
                    <ViewForms />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="survey-builder"
                element={
                  <PrivateRouteWrapper
                    path="survey-builder"
                    business={business}
                  >
                    <SurveyBuilder />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="/form/:id/edit"
                element={
                  <PrivateRouteWrapper
                    path="survey-builder"
                    business={business}
                  >
                    <SurveyBuilder />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="view-forms"
                element={
                  <PrivateRouteWrapper path="view-forms" business={business}>
                    <ViewForms />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="/form/:id/responses"
                element={
                  <PrivateRouteWrapper path="view-forms" business={business}>
                    <FormResponses />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="/form/:id/preview"
                element={
                  <PrivateRouteWrapper path="view-forms" business={business}>
                    <FormPreview />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="/form/:id/:businessId"
                element={
                  <PublicRouteWrapper restricted={false}>
                    <RenderForm isPreview={false} />
                  </PublicRouteWrapper>
                }
              />

              <Route
                path="create-job-post"
                element={
                  <PrivateRouteWrapper
                    path="create-job-post"
                    business={business}
                  >
                    <CreateJobPosting />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="edit-job-post"
                element={
                  <PrivateRouteWrapper
                    path="create-job-post"
                    business={business}
                  >
                    <EditJobPosting />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="templates"
                element={
                  <PrivateRouteWrapper path="templates" business={business}>
                    <Templates />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="signIn/*"
                element={
                  <PublicRouteWrapper restricted>
                    <ManagerSignInScreen />
                  </PublicRouteWrapper>
                }
              />
              <Route
                path="mobile-friendly-tutorials"
                element={
                  <PublicRouteWrapper restricted={false}>
                    <MobileFriednlyHowToVideos />
                  </PublicRouteWrapper>
                }
              />
              <Route
                path="get-started"
                element={
                  <PublicRouteWrapper restricted>
                    <GetStarted />
                  </PublicRouteWrapper>
                }
              />
              <Route
                path="check-verification"
                element={
                  <PublicRouteWrapper restricted>
                    <ManagerVerificationCodeScreen />
                  </PublicRouteWrapper>
                }
              />

              <Route
                path="campaigns"
                element={
                  <PrivateRouteWrapper path="campaigns" business={business}>
                    <Campaigns />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="audiences"
                element={
                  <PrivateRouteWrapper path="audiences" business={business}>
                    <Audiences />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="campaign-dashboard"
                element={
                  <PrivateRouteWrapper
                    path="campaign-dashboard"
                    business={business}
                  >
                    <CampaignDashboard />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="audience-profile"
                element={
                  <PrivateRouteWrapper
                    path="audience-profile"
                    business={business}
                  >
                    <AudienceProfile />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="add-subscriber"
                element={
                  <PrivateRouteWrapper
                    path="add-subscriber"
                    business={business}
                  >
                    <AddSubscrber />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="creative-studio/:templateId"
                element={
                  <PrivateRouteWrapper
                    path="creative-studio"
                    business={business}
                  >
                    <CreativeStudio />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="edit-subscriber/:subscriberId"
                element={
                  <PrivateRouteWrapper
                    path="creative-studio"
                    business={business}
                  >
                    <EditSubscriber />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="create-campaign"
                element={
                  <PrivateRouteWrapper
                    path="create-campaign"
                    business={business}
                  >
                    <CreateCampaign />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="edit-campaign/:campaignId"
                element={
                  <PrivateRouteWrapper
                    path="edit-campaign"
                    business={business}
                  >
                    <EditCampaign />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="add-employee"
                element={
                  <PrivateRouteWrapper
                    path="add-employee"
                    business={business}
                  >
                    <AddEmployee />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="edit-employee/:staffMemberId"
                element={
                  <PrivateRouteWrapper
                    path="edit-employee"
                    business={business}
                  >
                    <EditEmployee />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="organizational-hierarchy"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="organizational-hierarchy"
                  >
                    <OrganizationalHierarchy />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="inventory/measurements"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/measurements"
                  >
                    <InventoryMeasurements />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/locations"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/locations"
                  >
                    <InventoryLocations />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/conversions"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/conversions"
                  >
                    <Conversions />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="inventory/allergens"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/allergens"
                  >
                    <Allergens />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/primaryGroups"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/primaryGroups"
                  >
                    <PrimaryGroups />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/primaryGroups/:id/secondaryGroups"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/primaryGroups"
                  >
                    <SecondaryGroups />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="inventory/items/basic"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/items/basic"
                  >
                    <RawItems />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/items/prep"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/items/prep"
                  >
                    <PrepItems />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/prepItems/:id"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/prepItems"
                  >
                    <InventoryItemDetail />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/rawItems/:id"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/rawItems"
                  >
                    <InventoryItemDetail />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="inventory/vendors"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/vendors"
                  >
                    <InventoryVendors />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/vendor/:id"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/vendor"
                  >
                    <InventoryVendorDetail />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/recipes/prepitems"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/recipes/prepitems"
                  >
                    <InventoryRecipesPrepItems />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/recipes/prepitem/:id"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/recipes/prepitem/:id"
                  >
                    <InventoryRecipesPrepItemDetail />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/recipes/menu-items"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/recipes/menu-items"
                  >
                    <InventoryRecipesMenuItems />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/recipes/modifiers"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/recipes/modifiers"
                  >
                    <InventoryRecipesModifiers />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/recipes/menu-item/products/:id"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/recipes/menu-item"
                  >
                    <InventoryRecipesMenuItemDetail isModifier={false} />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/recipes/menu-item/modifiers/:id"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/recipes/menu-item"
                  >
                    <InventoryRecipesModifierDetail />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/recipes/vendor-items"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/recipes/vendor-items"
                  >
                    <InventoryRecipesVendorItems />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/recipes/vendor/:id"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/recipes/vendor/"
                  >
                    <RecipesVendorDetail />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/recipes/items"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/recipes/items"
                  >
                    <RecipesItems />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/recipes/item/:id"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/recipes/item"
                  >
                    <ReplaceItem />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/current"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/current"
                  >
                    <CurrentInventory />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/counts"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/counts"
                  >
                    <Counts />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/count/:id"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/count"
                  >
                    <CountDetail />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/waste"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/waste"
                  >
                    <InventoryWaste />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/invoices"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/invoices"
                  >
                    <InventoryInvoices />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="inventory/invoices/create"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="inventory/invoices"
                  >
                    <CreateInvoice />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="Calendar"
                element={
                  <PrivateRouteWrapper business={business} path="Calendar">
                    <Calendar />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="reviews"
                element={
                  <PrivateRouteWrapper business={business} path="reviews">
                    <Reviews />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="reviews-templates"
                element={
                  <PrivateRouteWrapper
                    business={business}
                    path="reviews-templates"
                  >
                    <ReviewsTemplates />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="cloud/files"
                element={
                  <PrivateRouteWrapper business={business} path="cloud/files">
                    <CloudFiles />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="payments/closedchecks/*"
                element={
                  <PrivateRouteWrapper business={business} path="payments/closedchecks/*">
                    <ClosedChecks />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="payments/*"
                element={
                  <PrivateRouteWrapper business={business} path="payments/*">
                    <PaymentsScreen />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="payments/disputes"
                element={
                  <PrivateRouteWrapper business={business} path="payments/disputes">
                    <Disputes />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="payments/discounts/*"
                element={
                  <PrivateRouteWrapper business={business} path="payments/discounts/*">
                    <DiscountedPayments />
                  </PrivateRouteWrapper>
                }
              />

              <Route
                path="payments/gift-cards/*"
                element={
                  <PrivateRouteWrapper business={business} path="payments/gift-cards/*">
                    <GiftCardsScreen />
                  </PrivateRouteWrapper>
                }
              />

              {/* <Route
                  path="payouts"
                  element={
                    <PrivateRouteWrapper business={business} path="payouts">
                      <DiscountedPayments />
                    </PrivateRouteWrapper>
                  }
                /> */}
              <Route
                path="payments/dispute/:id"
                element={
                  <PrivateRouteWrapper business={business} path="payments/dispute/:id">
                    <DisputeDetail />
                  </PrivateRouteWrapper>
                }
              />
              <Route
                path="dispute/evidence"
                element={
                  <PrivateRouteWrapper business={business} path="dispute/evidence">
                    <SubmitEvidence />
                  </PrivateRouteWrapper>
                }
              />
              {/*<Route*/}
              {/*  path="role"*/}
              {/*  element={*/}
              {/*    <PrivateRouteWrapper business={business} path="role">*/}
              {/*      <RoleForm />*/}
              {/*    </PrivateRouteWrapper>*/}
              {/*  }*/}
              {/*/>*/}

              {/*<Route*/}
              {/*  path="role/:id"*/}
              {/*  element={*/}
              {/*    <PrivateRouteWrapper business={business} path="role">*/}
              {/*      <RoleForm />*/}
              {/*    </PrivateRouteWrapper>*/}
              {/*  }*/}
              {/*/>*/}

              <Route
                path="notFound"
                element={
                  <React.Suspense fallback={<>Loading...</>}>
                    <NotFound />
                  </React.Suspense>
                }
              />
              <Route
                path="/staffing"
                element={<Navigate replace to="/manager/scheduling" />}
              />
              <Route
                path="/scheduling"
                element={<Navigate replace to="/manager/scheduling" />}
              />
              <Route
                path="/team"
                element={<Navigate replace to="/manager/team" />}
              />
              <Route
                path="/"
                element={<Navigate replace to="/dashboard" />}
              />
              <Route
                path="/*"
                element={<Navigate replace to="/notFound" />}
              />
            </Routes>
            {/*</CommsWrapper>*/}
          </SwitchLocationContext.Provider>
        )}
      </div>
    </Sentry.ErrorBoundary>
  );
}

export default App;
