import HorizontalTable from 'components/Table/HorizontalTable';
import React from 'react';
import RequestSidebar from '../RequestSidebar';
import DenyRequestModal from './DenyRequestModal';
import { instructions } from './instructions';
import useTimeOffRequests from './useTimeOffRequests';
import NavSlider from 'views/manager/NewTeam/views/EmployeeDetails/components/NavSlider';
type Props = {
  weekRange: Date[];
  business: TangoBusiness;
  schedulesWithLocalSchedule: TangoSchedule[];
  fixedSchedules: TangoFixedSchedule[];
  schedules: TangoSchedule[];
  scheduleEvents: ScheduleEvent[];

}
const TimeOffRequests = ({ business, schedulesWithLocalSchedule, fixedSchedules, schedules, scheduleEvents }: Props) => {
  const {
    columns,
    itemsData,
    showDenyRequestModal,
    setShowDenyRequestModal,
    weekRange,
    onCalendarDayPress,
    deleteIds,
    requestsStatusFilter,
    setRequestsStatusFilter

  } = useTimeOffRequests();
  return (
    <>
      <RequestSidebar
        weekRange={weekRange}
        business={business}
        schedulesWithLocalSchedule={schedulesWithLocalSchedule}
        fixedSchedules={fixedSchedules}
        schedules={schedules}
        scheduleEvents={scheduleEvents}
        onDayPress={onCalendarDayPress}
      />
      <div className='border-l border-solid border-grey-1 border-r-0 border-t-0 border-b-0 px-10' style={{ width: "80vw", overflowX: "auto", height: '100%', }}>

        <HorizontalTable
          title="Time-Off Requests"
          columns={columns}
          data={itemsData}
          instructions={instructions}
          loading={false}
          isEditing={false}
          deleteText="Delete"
          deleteIds={deleteIds}
          customHeaderLeftContent={
            <div className="flex row items-center">
              <div className="font-lato-black text-sm text-black">Time-Off Requests</div>
              <div className="ml-5 w-100">
                <NavSlider
                  items={[{
                    index: 0,
                    id: "pending",
                    title: "Pending"
                  },
                  {
                    index: 1,
                    id: "reviewed",
                    title: "Reviewed"
                  }
                  ]}
                  currentIndex={requestsStatusFilter === "pending" ? 0 : 1}
                  onItemPress={(selectedItem: any) => {
                    switch (selectedItem) {
                      case "pending":
                        setRequestsStatusFilter("pending")
                        break
                      case "reviewed":
                        setRequestsStatusFilter("reviewed")
                        break
                      default:
                        break;
                    }
                  }}
                />
              </div>
            </div>
          }
          hideEdit
        // primaryActionLabel="Settings"
        // primaryActionHandler={() => setShowSettingsModal(true)}
        />
      </div>
      <DenyRequestModal isOpen={showDenyRequestModal} closeModal={() => setShowDenyRequestModal(false)} />
      {/* <DeleteModal
        isOpen={showDeleteModal}
        modalTitle='Delete Request'
        modalSubtitle=''
        closeModal={() => setShowDeleteModal(false)}
        onDelete={() => console.log('yey')}
      />
      <TimeOffRequestSettingsModal
        isOpen={showSettingsModal}
        closeModal={() => setShowSettingsModal(false)}
      /> */}
    </>
  )
}

export default TimeOffRequests;