import { Icon, Spinner, SpinnerSize } from "@blueprintjs/core";
import tangoComponents from "@tangopay/tango-ui-library";
import {
  DeepKeys,
  Header,
  Row,
  SortingState,
  Table,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  AccessorFn,
} from "@tanstack/react-table";
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import GenericTableCell, {
  DataPoint,
  RenderInstruction,
  SimpleRenderInstruction,
} from "../GenericCell/TableCell";
import "./style.css";

const { Button, Checkbox, TextField, DateRangePickerInput } =
  tangoComponents;

type TableData = {
  uniqueId: string;
};

export type UpdateInstruction = {
  id: string;
  key: string;
  newValue: DataPoint;
};

export type UpdateState = {
  [id: string]: {
    [attr: string]: UpdateInstruction;
  };
};
const SELECTED_COL = "tableSelectedId";
const SELECTED_IST = {
  type: "boolean",
  useCheckbox: true,
} as SimpleRenderInstruction;
// used to pass info to cells without prop drilling / rerenders.
// if you try to render a table inside a table, you are asking
// a stupid question and will receive a stupid answer
const TableContext = React.createContext({
  updates: {} as UpdateState,
  editing: false,
});

const applyUpdates = <T extends TableData>(
  item: T,
  updates: {
    [attr: string]: UpdateInstruction;
  }
) => {
  const result: { [key: string]: DataPoint } = { ...item };
  Object.entries(updates).forEach(([key, { newValue }]) => {
    result[key] = newValue;
  });
  return result as T;
};

const TableHeader = <T extends TableData>({
  header,
  table,
}: {
  header: Header<T, unknown>;
  table: Table<T>;
}) => {
  if (header.id == SELECTED_COL) {
    return (
      <div key={header.id} onClick={header.column.getToggleSortingHandler()}>
        <Checkbox
          onChange={table.getToggleAllRowsSelectedHandler()}
          checked={table.getIsAllRowsSelected()}
          type="small"
        />
      </div>
    );
  }
  return (
    <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
      {flexRender(header.column.columnDef.header, header.getContext())}
      {header.column.getIsSorted() == "asc" && <tangoComponents.Icon name="chevron-up" size="16" className="ml-1 align-middle" />}
      {header.column.getIsSorted() == "desc" && <tangoComponents.Icon name="chevron-down" size="16" className="ml-1 align-middle" />}
    </th>
  );
};

const TableCell = <T extends TableData, ActionTypes = {}>(props: {
  value: DataPoint;
  uniqueId: string;
  attribute: string;
  instruction: RenderInstruction<T, ActionTypes>;
  onChange: (uniqueId: string, attribute: string, value: DataPoint) => unknown;
  fullObject: T | undefined;
}) => {
  const { updates, editing } = useContext(TableContext);
  const blockEdit =
    props.instruction?.readOnly &&
    !(
      props.instruction?.editablePrefix &&
      props.uniqueId.startsWith(props.instruction.editablePrefix)
    );

  const trueEditing =
    props.attribute == SELECTED_COL || (editing && !blockEdit);
  const trueValue =
    updates?.[props.uniqueId]?.[props.attribute]?.newValue ?? props.value;
  const trueObject = props.fullObject
    ? applyUpdates(props.fullObject, updates?.[props.uniqueId] ?? {})
    : null;
  updates?.[props.uniqueId]?.[props.attribute]?.newValue ?? props.value;
  const onChange = useCallback(
    (_, value) => props.onChange(props.uniqueId, props.attribute, value),
    [props.onChange, props.uniqueId, props.attribute]
  );
  const onChangeFull = useCallback(
    (obj: T) => {
      if (!props.fullObject) return;
      Object.keys(props.fullObject).forEach((rawKey) => {
        const key = rawKey as keyof T;
        props.onChange(props.uniqueId, key as string, obj[key] as DataPoint);
      });
    },
    [props.onChange, props.uniqueId, props.attribute]
  );
  return (
    <GenericTableCell
      value={trueValue}
      idx={0}
      instruction={props.instruction}
      onChange={onChange}
      onChangeFullObject={
        props.instruction?.type == "complex-custom" ? onChangeFull : undefined
      }
      editing={trueEditing}
      fullObject={
        props.instruction?.type == "complex-custom" ? trueObject : null
      }
    />
  );
};

// TODO: weird bug. If all columns are either projections or non-string data
// at least one column must have a string-returning accessor function
// to enable filtering on the table. The returned value does not affect filtering
export type ColumnInstruction<T> = {
  header: string;
  width?: number;
  accessorFunction?: AccessorFn<T>; // Provide a value for sorting the column.
} & (
    | {
      type: "data";
      attribute: DeepKeys<T>
    }
    | {
      type: "projection";
      attribute: string;
    }
  );

export type RowError = {
  rowId: string;
  generalErrorMessage: string | null;
  attributeErrors: { [attrName: string]: string | null } | null;
};

type Props<T extends TableData, ActionPopupType = {}> = {
  title: string;
  isEditing: boolean;
  setEditing?: (isEditing: boolean) => unknown;
  saveResults?: (data: UpdateState) => Promise<unknown>;
  deleteIds?: (uniqueIds: string[]) => unknown;
  columns: ColumnInstruction<T>[];
  data: T[];
  // default behaviour is a plain string
  instructions: { [attribute: string]: RenderInstruction<T, ActionPopupType> };
  primaryActionLabel?: string;
  primaryActionHandler?: () => unknown;
  onRowClick?: (rowId: string) => void;
  filterItems?: (item: T, text: string) => boolean;
  hideEdit?: boolean;
  hideSave?: boolean;
  errorTag?: string;
  subTitle?: string;
  customActionButton?: string;
  onCustomAction?: (uniqueIds: string[]) => unknown;
  showDateRangePicker?: boolean;
  ButtonGroup?: React.ReactNode;
  isVertical?: boolean;

  hideCheckboxes?: boolean;
  recievedUpdate?: (
    uniqueId: string,
    attribute: string,
    value: DataPoint
  ) => void;
  onPopupEvent?: (fullObject: T, event: ActionPopupType) => unknown | null;
  customHeaderComponent?: React.ReactElement;
  customHeaderLeftContent?: React.ReactElement;
  customHeaderRightContent?: ReactElement;
  loading?: boolean;
  disabledAttributes?: string[]; // not used
  errors?: RowError[];
  disableEditStateChangeOnSave?: boolean;
  disableRightArrow?: boolean;
  displayDeleteToTheLeftOfCustomContent?: boolean;
  deleteText?: string;
};
const HorizontalTable = <T extends TableData, ActionPopupType = {}>(
  props: Props<T, ActionPopupType>
) => {
  const {
    title,
    isEditing,
    setEditing,
    saveResults,
    deleteIds,
    columns: cols,
    data,
    instructions,
    primaryActionHandler,
    primaryActionLabel,
    onRowClick,
    filterItems,
    hideEdit,
    hideSave,
    errorTag,
    subTitle,
    customActionButton,
    onCustomAction,
    showDateRangePicker,
    ButtonGroup,
    isVertical,
    customHeaderComponent: CustomHeaderComponent,
    customHeaderLeftContent: CustomHeaderLeftContent,
    customHeaderRightContent: CustomHeaderRightComponent,
    loading,
    errors,
    hideCheckboxes,
    recievedUpdate,
    onPopupEvent,
    disableEditStateChangeOnSave,
    disableRightArrow,
    deleteText,
    displayDeleteToTheLeftOfCustomContent
  } = props;
  const [filterText, setFilterText] = useState("");
  const updateFilterText = useCallback(
    (e) => setFilterText(e.target.value),
    []
  );
  const [sorting, setSorting] = useState<SortingState>([]);
  const [updates, setUpdates] = useState<UpdateState>({});
  const toggleEdit = useCallback(() => {
    if (!setEditing) {
      console.warn("setEditing is not defined, this should be unreachable");
      return;
    }
    setEditing(!isEditing);
  }, [isEditing, setEditing]);

  const filterFunction = useCallback(
    (row: Row<T>) => {
      if (!filterText) return true;
      if (!filterItems) return true;
      return filterItems(row.original, filterText);
    },
    [filterText, filterItems]
  );

  // Clear updates when we stop editing
  useEffect(() => {
    if (!isEditing) setUpdates({});
  }, [isEditing]);
  const onChange = useCallback(
    (uniqueId: string, attribute: string, value: DataPoint) => {
      setUpdates((currentUpdates) => {
        const curr = { ...currentUpdates };
        if (!curr[uniqueId]) curr[uniqueId] = {};
        curr[uniqueId][attribute] = {
          id: uniqueId,
          key: attribute,
          newValue: value,
        };
        return curr;
      });
      if (recievedUpdate) {
        recievedUpdate(uniqueId, attribute, value);
      }
    },
    [setUpdates]
  );

  const sendUpdates = useCallback(() => {
    if (!saveResults) {
      console.warn("saveResults is not defined, this should be unreachable");
      return;
    }
    return saveResults(updates).then(() => {
      if (!setEditing) {
        console.warn("setEditing is not defined, this should be unreachable");
        return;
      }
      if (!disableEditStateChangeOnSave) {
        setEditing(false);
      }
    });
  }, [updates, saveResults, setEditing]);

  const columns = useMemo(() => {
    const helper = createColumnHelper<T>();
    const selector = hideCheckboxes
      ? []
      : [
        helper.display({
          id: SELECTED_COL,
          header: "SELECTED",
          cell: (info) => {
            const { row } = info;
            const uniqueId = row.original.uniqueId;
            return (
              <TableCell
                value={row.getIsSelected()}
                uniqueId={uniqueId}
                attribute={SELECTED_COL}
                instruction={SELECTED_IST}
                onChange={row.getToggleSelectedHandler()}
                fullObject={undefined}
              />
            );
          },
        }),
      ];
    const columnsData = [
      ...selector,
      ...cols.map((col) => {
        const key: string = col.attribute.toString();
        const instruction = instructions[key];

        const accFn = col.accessorFunction ?? null;
        const accStr = col.type == "data" ? col.attribute : null;
        const accessor: DeepKeys<T> | AccessorFn<T, unknown> | null = accFn ?? accStr ?? null;

        if (!accessor) {
          // TODO: because this is type 'display', it is not sortable
          return helper.display({
            id: key,
            header: () => col.header,
            cell: (info) => {
              const { row } = info;
              const uniqueId = row.original.uniqueId;
              return (
                <TableCell
                  value={null}
                  uniqueId={uniqueId}
                  attribute={key}
                  instruction={instruction}
                  onChange={onChange}
                  fullObject={info.row.original}
                />
              );
            },
          });
        }
        return helper.accessor(accessor, {
          id: key,
          enableGlobalFilter: true,
          header: () => col.header,
          cell: (info) => {
            const value = col.type === 'data' ? (info.getValue() as T) : null;
            const uniqueId = info.row.original.uniqueId;
            const isComplex = instruction?.type === "complex-custom";
            if (instruction && "actionPopup" in instruction) {
              const CustomComponent = instruction.actionPopup;
              return (
                <div
                  onClick={info.row.getToggleExpandedHandler()}
                  className="relative"
                >
                  <TableCell
                    value={value}
                    uniqueId={uniqueId}
                    attribute={key}
                    instruction={instruction}
                    onChange={onChange}
                    fullObject={isComplex ? info.row.original : undefined}
                  />
                  {CustomComponent && info.row.getIsExpanded() ? (
                    <CustomComponent
                      fullObject={info.row.original}
                      onSelect={onPopupEvent ?? null}
                    />
                  ) : null}
                </div>
              );
            }
            return (
              <TableCell
                value={value}
                uniqueId={uniqueId}
                attribute={key}
                instruction={instruction}
                onChange={onChange}
                fullObject={isComplex ? info.row.original : undefined}
              />
            );
          },
          // footer: (info) => info.column.id,
        });
      }),
    ];
    return columnsData;
    // avoid adding unstable dependencies here, use context instead
  }, [cols, instructions]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter: filterText,
    },
    getRowCanExpand: () => true,
    globalFilterFn: filterFunction,
    onGlobalFilterChange: setFilterText,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const handleRowClick = useCallback(
    (e) => {
      if (onRowClick) {
        // has form XXXXXXXXXXXXXXXXXXXX-clickable
        // we should not rely on id having a specific length or form
        const elementId: string = e.currentTarget.id;
        const uniqueId = elementId.slice("data-".length, -"-clickable".length);
        onRowClick(uniqueId);
      }
    },
    [onRowClick]
  );
  const sendDelete = useCallback(async () => {
    if (!deleteIds) {
      console.warn("deleteIds is not defined, this should be unreachable");
      return;
    }
    const sels = table
      .getRowModel()
      .rows.filter((r) => r.getIsSelected())
      .map((r) => r.original.uniqueId);
    await deleteIds(sels);
    table.resetRowSelection();
  }, [table, deleteIds]);

  const sendCustomAction = useCallback(() => {
    if (customActionButton && onCustomAction) {
      const sels = table
        .getRowModel()
        .rows.filter((r) => r.getIsSelected())
        .map((r) => r.original.uniqueId);
      onCustomAction(sels);
    }
  }, [table, onCustomAction]);
  const context = useMemo(
    () => ({
      updates,
      editing: isEditing,
    }),
    [updates, isEditing]
  );

  const colWidths = isVertical ? "0.6fr 1fr" : [
    ...(hideCheckboxes ? [] : ["0fr"]),
    ...(props.columns.map(({ width }) => `${width ?? 1}fr`)),
    ...(onRowClick ? ["0fr"] : []),
  ].join(" ");

  const selectedRows = table.getSelectedRowModel().rows;
  return (
    <div
      className={`${isVertical
        ? "border border-solid border-grey-1 rounded-2xl overflow-hidden tango-vertical-table"
        : "tango-hori-table"
        } tango-hori-table-${isEditing ? "editing" : "readonly"}`}
    >
      {CustomHeaderComponent ? (
        CustomHeaderComponent
      ) : (
        <div
          className={
            "flex justify-between items-center p-6 border-grey-1 border-solid border-l-0 border-t-0 border-r-0 border-b-0"
          }
        >
          {displayDeleteToTheLeftOfCustomContent ? (
            <div className={"font-lato-black text-sm text-black"}>
              {title}
            </div>
          ) : null}
          {displayDeleteToTheLeftOfCustomContent && !!deleteIds && (
            <div
              onClick={selectedRows.length > 0 ? sendDelete : undefined}
              className={`mr-5 cursor-pointer text-xs font-lato-bold ${selectedRows.length > 0 ? "text-black" : "text-grey-1"
                }`}
            >
              {deleteText ?? "Delete"}
            </div>
          )}

          {CustomHeaderLeftContent ? (
            CustomHeaderLeftContent
          ) : (
            <div className={"flex items-center"}>
              <div className={"font-lato-black text-sm text-black"}>
                {title}
              </div>
              {subTitle ? (
                <div className="font-lato-bold pl-4 ml-4 border-l border-r-0 border-b-0 border-t-0 border-solid border-grey-1">
                  {subTitle}
                </div>
              ) : null}
              {errorTag ? (
                <div className="text-white text-veryTiny px-3 ml-4 py-0.5 rounded-full font-lato-bold bg-error-red-hover border border-solid border-error-red">
                  {errorTag}
                </div>
              ) : null}
            </div>
          )}
          <div className={"flex items-center"}>
            {!displayDeleteToTheLeftOfCustomContent && !!deleteIds && (
              <div
                onClick={selectedRows.length > 0 ? sendDelete : undefined}
                className={`mr-5 cursor-pointer text-xs font-lato-bold ${selectedRows.length > 0 ? "text-black" : "text-grey-1"
                  }`}
              >
                {deleteText ?? "Delete"}
              </div>
            )}
            {!hideEdit ? (
              <div
                className={"cursor-pointer font-lato-bold text-black mx-5"}
                onClick={toggleEdit}
              >
                {isEditing ? "Cancel" : "Edit"}
              </div>
            ) : null}
            {showDateRangePicker ? (
              <DateRangePickerInput
                onSelect={(o) => console.log(o)}
                size="large"
                onChange={(date) => console.log(date)}
                value={new Date()}
              />
            ) : null}
            {!!isEditing && !hideSave && (
              <>
                <Button
                  label={"Save"}
                  type={"btn-style-1"}
                  size={"btn-small"}
                  disabled={loading}
                  onClick={sendUpdates}
                  className={
                    `${!loading ? 'bg-black text-white hover:bg-black hover:text-white cursor-pointer' : 'text-grey-2 hover:text-grey-2 bg-blue-grey-2 hover:bg-blue-grey-2 cursor-not-drop'}  mr-7`
                  }
                />
                {loading ? <Spinner size={SpinnerSize.SMALL} className="mr-6" /> : null}
              </>
            )}

            {!!primaryActionHandler && !!primaryActionLabel && (
              <>
                <div className={"h-9 w-px bg-grey-1 mr-7"} />
                <Button
                  type="btn-style-2"
                  size="btn-large"
                  label={primaryActionLabel}
                  onClick={primaryActionHandler}
                />
              </>
            )}
            {!!filterItems && (
              <TextField
                className="hori-table-search-input bg-blue-grey-2 border-0"
                value={filterText}
                onChange={updateFilterText}
                sizeType="medium"
                placeholder="Search"
              />
            )}
            {ButtonGroup ?? null}
            {customActionButton ? (
              <Button
                className={`p-0 ${selectedRows.length > 0 ? "text-black" : "text-grey-1"
                  }`}
                type="btn-style-minimal"
                size="btn-medium"
                label={customActionButton}
                onClick={sendCustomAction}
              />
            ) : null}
            {
              CustomHeaderRightComponent && CustomHeaderRightComponent
            }
          </div>

        </div>
      )}
      <TableContext.Provider value={context}>
        <div className={isVertical ? "w-full text-xs font-lato-black" : "text-xs font-lato-black"} style={{
          display: "grid",
          gridTemplateColumns: colWidths,
        }}>
          {!isVertical ? (
            table.getHeaderGroups().map((headerGroup) => <>
              {headerGroup.headers.map((header, idx) => (
                (
                  <div
                    key={`header-${header.column.columnDef.header}`}
                    className="text-xs p-2 font-lato-black bg-blue-grey-2 border-b border-grey-1"
                    style={{
                      borderBottomStyle: "solid",
                      gridColumnStart: idx + 1,
                      gridColumnEnd: idx + 2,
                      gridRowStart: 1,
                      gridRowEnd: 2,
                    }}
                  >
                    <TableHeader
                      header={header}
                      table={table}
                      key={header.id}
                    />
                  </div>
                )
              ))}
              {!!onRowClick && <div
                key="header-row-button"
                className="text-xs font-lato-black bg-blue-grey-2 border-b border-grey-1"
                style={{
                  borderBottomStyle: "solid",
                  gridColumnStart: headerGroup.headers.length + 1,
                  gridColumnEnd: headerGroup.headers.length + 2,
                  gridRowStart: 1,
                  gridRowEnd: 2,
                }}
              />
              }</>
            )) : null}
          {isVertical ? (
            table.getRowModel().rows.map((row) => {
              return row.getVisibleCells().map((cell, cellIndex) => {
                if (cellIndex > 0) {
                  const hName = cols[cellIndex - 1].header;
                  const hId = cols[cellIndex - 1].attribute as string;
                  return (
                    <>
                      <div
                        key={`header-${hId}`}
                        className="bg-blue-grey-2 text-xs font-lato-black text-black px-6 py-2 border-t border-grey-1"
                        style={{
                          borderTopStyle: "solid",
                          gridColumnStart: 1,
                          gridColumnEnd: 2,
                          gridRowStart: cellIndex + 1,
                          gridRowEnd: cellIndex + 2,
                        }}
                      >
                        {hName}
                      </div>
                      <div
                        key={`data-${hId}`}
                        className="font-lato-regular text-xs text-black px-6 py-2 border-t border-grey-1"
                        style={{
                          borderTopStyle: "solid",
                          gridColumnStart: 2,
                          gridColumnEnd: 3,
                          gridRowStart: cellIndex + 1,
                          gridRowEnd: cellIndex + 2,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    </>
                  );
                }
                return null;
              });
            })
          ) : (
            table.getRowModel().rows.map((row, rowIndex: number) => {
              const errorForRow = (errors ?? []).find(
                (er) => er.rowId === row.original.uniqueId
              );
              return <>
                {
                  row.getVisibleCells().map((cell, cellIndex) => {
                    const attributeError =
                      errorForRow?.attributeErrors?.[cell.column.id];
                    const errorComp = attributeError
                      ? <div
                        className="absolute hidden group-hover:flex flex w-full border-grey-1 bg-white p-2 text-red"
                        style={{
                          left: 0,
                          right: 0,
                          bottom: 0,
                          transform: "translateY(100%)",
                          zIndex: 10, // lower than the value of the dropdown
                          color: "#E3564D",
                          borderBottomLeftRadius: 10,
                          borderBottomRightRadius: 10,
                          boxShadow: "4px 6px 20px rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        {attributeError}
                      </div>
                      : null
                      ;
                    return (
                      <div
                        id={`data-${row.original.uniqueId}-${cell.column.id}`}
                        key={`data-${row.original.uniqueId}-${cell.column.id}`}
                        className="font-lato-regular text-xs text-black p-2 border-b border-grey-1 relative group"
                        style={{
                          backgroundColor: attributeError ? "rgba(227, 86, 77, 0.08)" : undefined,
                          borderBottomStyle: "solid",
                          gridColumnStart: cellIndex + 1,
                          gridColumnEnd: cellIndex + 2,
                          gridRowStart: rowIndex + 2,
                          gridRowEnd: rowIndex + 3,
                        }}
                      // onClick={(!isEditing && !!onRowClick && !disableRightArrow) ? handleRowClick : undefined}
                      >
                        {errorComp}
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    );
                  })
                }
                {
                  !!onRowClick && !disableRightArrow && !isEditing && (
                    <div
                      // don't change this id without updating handleRowClick 
                      id={`data-${row.original.uniqueId}-clickable`}
                      key={`data-${row.original.uniqueId}-clickable`}
                      className="cursor-pointer text-right p-2 border-b border-grey-1"
                      onClick={handleRowClick}
                      style={{
                        borderBottomStyle: "solid",
                        gridColumnStart: row.getVisibleCells().length + 1,
                        gridColumnEnd: row.getVisibleCells().length + 2,
                        gridRowStart: rowIndex + 2,
                        gridRowEnd: rowIndex + 3,
                      }}
                    >
                      <Icon icon="chevron-right" color="#7C7F93" size={15} />
                    </div>
                  )
                }
              </>
            })
          )}
        </div>
      </TableContext.Provider>
    </div>
  );
};

export default HorizontalTable;
