import UIComponents from "@tangopay/tango-ui-library";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Header from "components/Header";

import Editor from "../../../components/TextEditor";
import Sidebar from "../Sidebar";
import { headerRoutes } from "../routes";
import usePrepItemDetail from "./usePrepItemDetail";
import HorizontalTable from "components/Table/HorizontalTable";


const { Icon, Button } = UIComponents;
const PrepItemRecipe = () => {
  const navigate = useNavigate();
  const loc = useLocation();
  const itemId = useMemo(() => {
    const split = loc.pathname.split("/");
    return split[split.length - 1];
  }, [loc]);
  const taggables = useMemo(() => {
    return [];
  }, []);

  const {
    itemDetail,
    isEditingDetail,
    setIsEditingDetail,
    updateDetail,
    isEditingIngredients,
    setIsEditingIngredients,
    detailInstructions,
    detailColumns,
    ingredients,
    ingredientInstructions,
    ingredientColumns,
    addIngredient,
    updateIngredients,
    deleteIngredients,
    isEditingRecipe,
    setIsEditingRecipe,
    setEditedRecipe,
    updateRecipe,
  } = usePrepItemDetail(itemId);
  const itemAsList = useMemo(() => itemDetail ? [itemDetail] : [], [itemDetail]);
  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header
        routes={headerRoutes}
        selectedRoute={"/inventory/items/basic"}
      />
      <div className="flex h-full overflow-hidden">
        <div
          className={"border-r border-grey-1 border-t-0 border-b-0 overflow-y-auto"}
          style={{ width: "10%", borderRightStyle: "solid" }}
        >
          <Sidebar />
        </div>

        <div
          style={{ width: "90%" }}
          className={"bg-off-white overflow-y-auto"}
        >
          <div
            className={
              "flex p-8 justify-between items-center bg-white border-b border-grey-1"
            }
            style={{ borderBottomStyle: "solid" }}
          >
            <div className={"flex items-center"}>
              <Icon
                name={"arrow-left"}
                size={"20"}
                className={"cursor-pointer"}
                onClick={() => navigate(-1)}
              />
              <div className={"text-big text-black font-lato-black ml-10"}>
                {itemDetail?.name}
              </div>
            </div>
            <div className={"flex items-center"}>
              <div
                className={
                  "font-lato-bold text-xs text-error-red mr-7 cursor-pointer"
                }
              >
                Delete
              </div>
              {/* <div className={"h-9 w-px bg-grey-1 mr-7"} />
              <Button
                label={"Duplicate Item"}
                type={"btn-style-2"}
                size={"btn-large"}
              /> */}
            </div>
          </div>
          <div className={"flex p-8"}>
            <div className={"w-4/12"}>
              <HorizontalTable
                data={itemAsList}
                instructions={detailInstructions}
                columns={detailColumns}
                title="Details"
                isEditing={isEditingDetail}
                setEditing={setIsEditingDetail}
                saveResults={updateDetail}
                isVertical
              />
            </div>
            <div className={"w-8/12 pl-8"}>
              <div
                className={
                  "bg-white border border-grey border-b-0 border-solid rounded-2xl"
                }
              >
                <HorizontalTable
                  data={ingredients}
                  instructions={ingredientInstructions}
                  columns={ingredientColumns}
                  title="Recipe"
                  isEditing={isEditingIngredients}
                  setEditing={setIsEditingIngredients}
                  primaryActionHandler={addIngredient}
                  primaryActionLabel="Add Ingredient"
                  saveResults={updateIngredients}
                  deleteIds={deleteIngredients}
                />
              </div>
              <div className="bg-white border border-grey border-b-0 border-solid rounded-2xl mt-6">
                <div
                  className="border-b border-b-grey border-b-solid flex flex-row p-6"
                >
                  <div
                    className="flex-1 font-lato-black text-xl"
                  >
                    Instructions
                  </div>
                  {
                    !isEditingRecipe && (
                      <Button
                        type="btn-style-1"
                        label="Edit"
                        size="btn-medium"
                        onClick={() => setIsEditingRecipe(true)}
                      />
                    )
                  }
                  {
                    !!isEditingRecipe && (<>
                      <Button
                        type="btn-style-minimal"
                        label="Cancel"
                        size="btn-medium"
                        onClick={() => setIsEditingRecipe(false)}
                      />
                      <Button
                        type="btn-style-1"
                        label="Save"
                        size="btn-medium"
                        onClick={updateRecipe}
                      />
                    </>)
                  }
                </div>
                <Editor
                  initialValue={itemDetail?.recipe ?? ""}
                  placeholder="Add instructions..."
                  taggables={taggables}
                  readOnly={!isEditingRecipe}
                  onChange={setEditedRecipe}
                  minimal
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrepItemRecipe;
