import React, { useEffect, useState } from 'react';

const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];

const useMoveShift = () => {
    const [selectedAction, setSelectedAction] = useState<"move" | "duplicate">("move");
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    useEffect(() => {
        if (selectedAction == 'move') {
            setSelectedDays([]);
        }
    }, [selectedAction])
    const updateSelectedDays = (day: string) => {
        if (selectedAction === 'move') {
            setSelectedDays([day])
        } else {
            const existingDays = [...selectedDays];
            const dayIndex = existingDays.findIndex((item) => item === day);

            if (dayIndex > -1) {
                existingDays.splice(dayIndex, 1);
            } else {
                existingDays.push(day);
            }
            setSelectedDays(existingDays);
        }

    };
    return {
        selectedAction,
        setSelectedAction,
        days,
        updateSelectedDays,
        selectedDays,
    };
}
export default useMoveShift;