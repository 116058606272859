import { useState } from "react";
import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";
import { TangoTab, useCompletedTabsAndGiftCardPurchases, TabWithStaff, SearchParameters, searchQueryFirestoreForTabs, SearchParam } from "controllers/payments";
import { useSelector } from "react-redux";
import { RootState } from "model/store";
import { CompletedCheckStaff, CustomerModel } from "../ClosedChecks/useClosedChecks";
import { getTimestampCharged, getPaymentStatus } from './utils'

export type CompletedPaymentProps = {
    uniqueId: string;
    date: any;
    amount: number;
    status: string,
    description: string,
    customerName: string,
    last4: string,
    checkNumber: string;
    completedCheck: TangoTab;
    staffMember: CompletedCheckStaff | null;
};

const columns: ColumnInstruction<CompletedPaymentProps>[] = [
    { type: "data", header: "Date", attribute: "date" },
    { type: "data", header: "Total Amount", attribute: "amount" },
    { type: "projection", header: "Status", attribute: "status" },
    { type: "data", header: "Customer", attribute: "customerName" },
    { type: "data", header: "Last 4", attribute: "last4" },
    { type: "data", header: "Check Number", attribute: "checkNumber" },
    { type: "data", header: "Description", attribute: "description" },
];

const instructions: { [x: string]: RenderInstruction<CompletedPaymentProps> } = {};


instructions.amount = {
    type: "currency",
}

instructions.status = {
    type: "complex-custom",
    viewComponent: ({ fullObject }) => {
        if (fullObject.status === 'Succeeded') {
            return (<div style={{ width: "fit-content" }} className='py-1 px-4 rounded-2xl  bg-green-2 border-green-3 text-xs font-lato-bold text-black border-solid border'>
                {fullObject.status}
            </div>
            )
        }
        if (fullObject.status === 'Fully Refunded' || fullObject.status === 'Partially Refunded') {
            return (<div style={{ width: "fit-content" }} className='py-1 px-4 rounded-2xl bg-purple-2 border-purple-3 text-xs font-lato-bold text-black border-solid border'>
                {fullObject.status}
            </div>
            )
        }
        if (fullObject.status === 'Need Response') {
            return (<div style={{ width: "fit-content" }} className='py-1 px-4 rounded-2xl bg-yellow-2 border-yellow-3 text-xs font-lato-bold text-black border-solid border'>
                {fullObject.status}
            </div>
            )
        }
        return (<div style={{ width: "fit-content" }} className='py-1 px-4 rounded-2xl bg-error-red bg-error-red-hover text-xs font-lato-bold text-white border-solid border'>
            {fullObject.status}
        </div>
        )
    },
    editComponent: null,
}

const usePaymentScreen = () => {
    const businessId: string = useSelector(
        (state: RootState) => state.business as TangoBusiness
    )?.id;
    const noOp = () => Promise.resolve(true);
    const logIt = (args: string[]) => console.log(args);
    const [isEditing, setEditing] = useState(false);
    const [searchBy, setSearchBy] = useState({
        value: "Check Number",
        label: "Check Number",
    });
    const [loading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [lastTabId, setLastTabId] = useState('start');
    const [previousPageTabIds, setPreviousPageTabIds] = useState(['start'])
    const [pageNumber, setPageNumber] = useState<number>(0)
    const [chunkedPayments, setChunkedPayments] = useState<CompletedPaymentProps[][]>([])
    const [displayCounterMessage, setDisplayCounterMessage] = useState<string>('1 - ' + 20)
    const [filteredPayments, setFilteredPayments] = useState<CompletedPaymentProps[]>([])

    const query = useCompletedTabsAndGiftCardPurchases(businessId, lastTabId);
    const completedChecksWithGiftCards = query.data ?? []; //query will return null if no business id has propogated 
    const data: CompletedPaymentProps[] = []
    completedChecksWithGiftCards.length > 0 && completedChecksWithGiftCards.forEach((completedCheck: TabWithStaff) => {
        completedCheck.tab.customer.forEach((customer: CustomerModel, index: number) => {
            if (customer.payment.paymentType) {
                const completedPayment: CompletedPaymentProps = {
                    uniqueId: customer.payment.paymentType.type == 'cash' ? completedCheck.tab.id + '-cash-' + index : customer.payment.paymentType.chargeId,
                    date: getTimestampCharged(customer.payment.successfulCharge.timestampCharged, completedCheck.tab.updatedAt, customer.payment.paymentType.created),
                    amount: customer.payment.paymentType.amount,
                    status: getPaymentStatus(customer.amount, customer.payment.paymentType.amount),
                    description: customer.payment.paymentType.type == 'cash' ? 'Cash Payment' :
                        completedCheck.tab.customer[0] && completedCheck.tab.customer[0].orderChannel === 'mobile' ? 'Online Order' : 'Terminal Payment',
                    customerName: customer.firstName ? customer.firstName + ' ' + customer.lastName : 'Guest',
                    last4: customer.payment.paymentType.type == 'cash' ? 'Cash' : customer.payment.paymentType.lastFour,
                    completedCheck: completedCheck.tab,
                    checkNumber: completedCheck.tab.tabNumber.toString(),//stringify in case it's number
                    staffMember: completedCheck.staffMember
                }
                data.push(completedPayment)
            }
        })
    })

    const findInExistingData = (search: SearchParameters) => {
        const filteredPayments = data.filter((payment: CompletedPaymentProps) => {
            return (search.checkNumber.length > 2 && search.checkNumber === payment.checkNumber)
                || (search.checkNumber.length > 2 && payment.checkNumber.includes(search.checkNumber))
                || (search.amount > 0 && Math.round(search.amount * 100) === payment.amount)
                || (search.last4.length === 4 && search.last4 === payment.last4)
        })
        return filteredPayments
    }

    const searchFirestore = async (businessId: string, searchParam: SearchParam, searchValue: string) => {
        const searchResultsFromFirestore: CompletedPaymentProps[] = []
        const results = await searchQueryFirestoreForTabs(businessId, searchParam, searchValue);
        results && results.length > 0 && results.forEach((completedCheck: TabWithStaff) => {
            completedCheck.tab.customer.forEach((customer: CustomerModel, index: number) => {
                if (customer.payment.paymentType) {
                    const completedPayment: CompletedPaymentProps = {
                        uniqueId: customer.payment.paymentType.type == 'cash' ? completedCheck.tab.id + '-cash-' + index : customer.payment.paymentType.chargeId,
                        date: getTimestampCharged(customer.payment.successfulCharge.timestampCharged, completedCheck.tab.updatedAt, customer.payment.paymentType.created),
                        amount: customer.payment.paymentType.amount,
                        status: getPaymentStatus(customer.amount, customer.payment.paymentType.amount),
                        description: customer.payment.paymentType.type == 'cash' ? 'Cash Payment' :
                            completedCheck.tab.customer[0] && completedCheck.tab.customer[0].orderChannel === 'mobile' ? 'Online Order' : 'Terminal Payment',
                        customerName: customer.firstName ? customer.firstName + ' ' + customer.lastName : 'Guest',
                        last4: customer.payment.paymentType.type == 'cash' ? 'Cash' : customer.payment.paymentType.lastFour,
                        completedCheck: completedCheck.tab,
                        checkNumber: completedCheck.tab.tabNumber.toString(),//stringify in case it's number
                        staffMember: completedCheck.staffMember
                    }
                    searchResultsFromFirestore.push(completedPayment)
                }
            })
        })


        return searchResultsFromFirestore
    }

    return {
        businessId,
        data,
        noOp,
        logIt,
        columns,
        instructions,
        isEditing,
        setEditing,
        lastTabId,
        setLastTabId,
        setPreviousPageTabIds,
        previousPageTabIds,
        setPageNumber,
        pageNumber,
        setChunkedPayments,
        chunkedPayments,
        setDisplayCounterMessage,
        displayCounterMessage,
        setSearchBy,
        searchBy,
        setSearchString,
        searchString,
        findInExistingData,
        searchFirestore,
        setFilteredPayments,
        filteredPayments,
        setLoading,
        loading
    };
};
export default usePaymentScreen;
