import _ from "lodash";
import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useSettingsViewModel } from "./viewModel";

export const useSettingsViewController = () => {
  const { schedulingSettings, updateScheduleSettings } = useSettingsViewModel();
  const [firstDayOfTheWeek, setFirstDayOfTheWeek] = useState<string | null>(
    schedulingSettings.data?.payrollStartOfTheWeek ?? null
  );
  useEffect(() => {
    if (schedulingSettings.data?.payrollStartOfTheWeek) {
      setFirstDayOfTheWeek(schedulingSettings.data?.payrollStartOfTheWeek);
    }
  }, [schedulingSettings.data?.payrollStartOfTheWeek, setFirstDayOfTheWeek]);
  const [amPmSplitTime, setAmPmSplitTime] = useState<string | null>(
    schedulingSettings.data?.amPmSplitTime ?? null
  );

  console.log("amPmSplitTime", amPmSplitTime);

  useEffect(() => {
    if (schedulingSettings.data?.amPmSplitTime) {
      setAmPmSplitTime(schedulingSettings.data?.amPmSplitTime);
    }
  }, [schedulingSettings.data?.amPmSplitTime, setAmPmSplitTime]);
  const [payrollCadence, setPayrollCadence] = useState<PayPeriodCadence | null>(
    schedulingSettings.data?.payPeriodCadence ?? null
  );

  useEffect(() => {
    if (schedulingSettings.data?.payPeriodCadence) {
      setPayrollCadence(schedulingSettings.data?.payPeriodCadence);
    }
  }, [schedulingSettings.data?.payPeriodCadence, setPayrollCadence]);
  const [payPeriodStartDate, setPayPeriodStartDate] = useState<Date | null>(
    schedulingSettings.data?.payPeriodStartDate ?? null
  );

  useEffect(() => {
    if (schedulingSettings.data?.payPeriodStartDate) {
      setPayPeriodStartDate(
        moment(schedulingSettings.data?.payPeriodStartDate).toDate()
      );
    }
  }, [schedulingSettings.data?.payPeriodStartDate, setPayPeriodStartDate]);
  const [showFullStaffSchedule, setShowFullStaffSchedule] = useState<boolean>(
    schedulingSettings.data?.showFullStaffSchedule ?? false
  );
  useEffect(() => {
    if (!_.isUndefined(schedulingSettings.data?.showFullStaffSchedule)) {
      setShowFullStaffSchedule(
        !!schedulingSettings.data?.showFullStaffSchedule
      );
    }
  }, [
    schedulingSettings.data?.showFullStaffSchedule,
    setShowFullStaffSchedule,
  ]);
  const [enableEmployeesToDropShifts, setEnableEmployeesToDropShifts] =
    useState<boolean>(schedulingSettings.data?.enableDropShifts ?? false);
  useEffect(() => {
    if (!_.isUndefined(schedulingSettings.data?.enableDropShifts)) {
      setEnableEmployeesToDropShifts(
        !!schedulingSettings.data?.enableDropShifts
      );
    }
  }, [
    schedulingSettings.data?.enableDropShifts,
    setEnableEmployeesToDropShifts,
  ]);
  const [locationVerifiedClockPunch, setLocationVerifiedClockPunch] =
    useState<boolean>(!schedulingSettings.data?.turnLocationOff ?? false);

  useEffect(() => {
    if (!_.isUndefined(schedulingSettings.data?.turnLocationOff)) {
      setLocationVerifiedClockPunch(!schedulingSettings.data?.turnLocationOff);
    }
  }, [schedulingSettings.data?.turnLocationOff, setLocationVerifiedClockPunch]);
  const [overtimeQualification, setOvertimeQualification] = useState<number>(
    schedulingSettings.data?.overtimeQualification?.hours ?? 40
  );

  useEffect(() => {
    if (schedulingSettings.data?.overtimeQualification?.hours) {
      setOvertimeQualification(
        schedulingSettings.data?.overtimeQualification?.hours
      );
    }
  }, [
    schedulingSettings.data?.overtimeQualification?.hours,
    setOvertimeQualification,
  ]);

  const firstDayOfTheWeekDropdownOptions = useMemo(() => {
    return [
      { label: "Sunday", value: "Sunday" },
      { label: "Monday", value: "Monday" },
      { label: "Tuesday", value: "Tuesday" },
      { label: "Wednesday", value: "Wednesday" },
      { label: "Thursday", value: "Thursday" },
      { label: "Friday", value: "Friday" },
      { label: "Saturday", value: "Saturday" },
    ];
  }, []);

  const firstDayOfTheWeekDropdownValue = useMemo(() => {
    return firstDayOfTheWeekDropdownOptions.find(
      (option) => option.value === firstDayOfTheWeek
    );
  }, [firstDayOfTheWeekDropdownOptions, firstDayOfTheWeek]);

  const updateFirstDayOfTheWeek = useCallback(
    (dayName: string) => {
      setFirstDayOfTheWeek(dayName);
    },
    [setFirstDayOfTheWeek]
  );

  const updateAmPmSplitTime = useCallback(
    (time: string) => {
      setAmPmSplitTime(time);
    },
    [setAmPmSplitTime]
  );

  const updatePayrollCadence = useCallback(
    (cadence: PayPeriodCadence) => {
      setPayrollCadence(cadence);
    },
    [setPayrollCadence]
  );

  const updatePayPeriodStartDate = useCallback(
    (date: Date) => {
      setPayPeriodStartDate(date);
    },
    [setPayPeriodStartDate]
  );

  const updateShowFullStaffSchedule = useCallback(
    (show: boolean) => {
      setShowFullStaffSchedule(show);
    },

    [setShowFullStaffSchedule]
  );

  const updateEnableEmployeesToDropShifts = useCallback(
    (enable: boolean) => {
      setEnableEmployeesToDropShifts(enable);
    },
    [setEnableEmployeesToDropShifts]
  );

  const updateLocationVerifiedClockPunch = useCallback(
    (enable: boolean) => {
      setLocationVerifiedClockPunch(enable);
    },
    [setLocationVerifiedClockPunch]
  );

  const updateOvertimeQualification = useCallback(
    (hours: number) => {
      setOvertimeQualification(hours);
    },
    [setOvertimeQualification]
  );

  const payrollCadenceOptions = useMemo(() => {
    return [
      { label: "Weekly", value: "weekly" },
      { label: "Bi-Weekly", value: "biweekly" },
      { label: "Monthly", value: "monthly" },
    ];
  }, []);

  const payrollCadenceValue = useMemo(() => {
    return payrollCadenceOptions.find(
      (option) => option.value === payrollCadence
    );
  }, [payrollCadenceOptions, payrollCadence]);

  const saveSchedulingSettings = useCallback(() => {
    if (schedulingSettings.data) {
      return updateScheduleSettings.mutateAsync({
        businessId: schedulingSettings.data?.businessId,
        payrollStartOfTheWeek: firstDayOfTheWeek ?? undefined,
        amPmSplitTime: amPmSplitTime ?? undefined,
        payPeriodCadence: payrollCadence ?? undefined,
        payPeriodStartDate: payPeriodStartDate ?? undefined,
        showFullStaffSchedule: showFullStaffSchedule,
        enableDropShifts: enableEmployeesToDropShifts,
        turnLocationOff: !locationVerifiedClockPunch,
        overtimeQualification: {
          hours: overtimeQualification,
          cadence: "weekly",
        },
      });
    }
  }, [updateScheduleSettings, schedulingSettings.data]);

  return {
    firstDayOfTheWeek,
    amPmSplitTime,
    payrollCadence,
    payPeriodStartDate,
    showFullStaffSchedule,
    enableEmployeesToDropShifts,
    locationVerifiedClockPunch,
    overtimeQualification,
    updateFirstDayOfTheWeek,
    updateAmPmSplitTime,
    updatePayrollCadence,
    updatePayPeriodStartDate,
    updateShowFullStaffSchedule,
    updateEnableEmployeesToDropShifts,
    updateLocationVerifiedClockPunch,
    updateOvertimeQualification,
    firstDayOfTheWeekDropdownValue,
    firstDayOfTheWeekDropdownOptions,
    saveSchedulingSettings,
    loading: schedulingSettings.isFetching,
    saveLoading: updateScheduleSettings.isLoading,
    payrollCadenceOptions,
    payrollCadenceValue,
  };
};
