import { InventorySidebarAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: {
    expandedSidebarRoutes: { [route: string]: boolean }
} = {expandedSidebarRoutes:{}}

export default function sidebar(
  state = initialState,
  action: InventorySidebarAction
) {
  const expandedSidebarRoutes = {...state.expandedSidebarRoutes};
  switch (action.type) {
    case ActionType.INVENTORY_SIDEBAR_COLLAPSE:
      expandedSidebarRoutes[action.route] = false; 
      return {...state, expandedSidebarRoutes};
    case ActionType.INVENTORY_SIDEBAR_EXPAND:
      expandedSidebarRoutes[action.route] = true;
      return {...state, expandedSidebarRoutes};
    case ActionType.INVENTORY_SIDEBAR_TOGGLE:
      expandedSidebarRoutes[action.route] = !expandedSidebarRoutes[action.route];
      return {...state, expandedSidebarRoutes};
    default:
      return state;
  }
}
