import HorizontalTable from 'components/Table/HorizontalTable';
import React from 'react';
import NavSlider from 'views/manager/NewTeam/views/EmployeeDetails/components/NavSlider';
import RequestSidebar from '../RequestSidebar';
import AssignShiftModal from './AssignShiftModal';
import { instructions } from './instructions';
import useDropRequests from './useDropRequests';
type Props = {
  weekRange: Date[];
  business: TangoBusiness;
  schedulesWithLocalSchedule: TangoSchedule[];
  fixedSchedules: TangoFixedSchedule[];
  schedules: TangoSchedule[];
  scheduleEvents: ScheduleEvent[];

}
const DropRequests = ({ business, schedulesWithLocalSchedule, fixedSchedules, schedules, scheduleEvents }: Props) => {
  const { weekRange, columns, itemsData, showAssignShiftModal,
    setShowAssignShiftModal, onCalendarDayPress, requestsStatusFilter, setRequestsStatusFilter } = useDropRequests();
  return (
    <>
      <RequestSidebar
        weekRange={weekRange}
        business={business}
        schedulesWithLocalSchedule={schedulesWithLocalSchedule}
        fixedSchedules={fixedSchedules}
        schedules={schedules}
        scheduleEvents={scheduleEvents}
        onDayPress={onCalendarDayPress}
      />
      <div className='border-l border-solid border-grey-1 border-r-0 border-t-0 border-b-0 px-10' style={{ width: "80vw", overflowX: "auto", height: '100%', }}>
        <HorizontalTable
          title="Drop Requests"
          customHeaderLeftContent={
            <div className="flex row items-center">
              <div className="font-lato-black text-sm text-black">Drop Requests</div>
              <div className="ml-5 w-100">
                <NavSlider
                  items={[{
                    index: 0,
                    id: "pending",
                    title: "Pending"
                  },
                  {
                    index: 1,
                    id: "reviewed",
                    title: "Reviewed"
                  }
                  ]}
                  currentIndex={requestsStatusFilter === "pending" ? 0 : 1}
                  onItemPress={(selectedItem: any) => {
                    switch (selectedItem) {
                      case "pending":
                        setRequestsStatusFilter("pending")
                        break
                      case "reviewed":
                        setRequestsStatusFilter("reviewed")
                        break
                      default:
                        break;
                    }
                  }}
                />
              </div>
            </div>
          }
          columns={columns}
          data={itemsData}
          instructions={instructions}
          loading={false}
          isEditing={false}
          hideEdit
        />
      </div>
      <AssignShiftModal isOpen={showAssignShiftModal} closeModal={() => setShowAssignShiftModal(false)} />
    </>
  )
}

export default DropRequests;