import { Grid } from "@mui/material";
import UIComponents from "@tangopay/tango-ui-library";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { RootState } from "model/store";

import Layout from "../../Layout";
import ActivityReport from "../ActivityReport";
import BankDeposit from "../BankDeposit";
import CloseOutReport from "../CloseOutReport";
import DailyLaborReport from "../DailyLaborReport";
import DailySalesReport from "../DailySalesReport";
import DiscountSummary from "../DiscountSummary";
import DistributionAndLaborReport from "../DistributionAndLaborReport";
import EnterpriseCoreReports from "../EnterpriseCoreReports";
import LaborReportModal from "../LaborReportModal";
import SalesMix from "../SalesMix";
import SalesMixByServerCSV from "../SalesMixByServerCSV";
import SalesMixCSV from "../SalesMixCSV";
import TimeCardSummary from "../TimeCardSummary";
import TipsByMenu from "../TipsByMenu";
import WeeklyEmployeeSalarySummary from "../WeeklyEmployeeSalarySummary";
import WeeklySalesMix from "../WeeklySalesMix";
import WeeklySalesSummary from "../WeeklySalesSummary";
import "../style.css";
import {
  InventoryReport,
  Invoices,
  MenuEngineering,
  SalesMixByProduct,
  SalesMixByStore,
  TheoreticalUsageReport,
  UsageCountsReport,
  UsageValuesReport,
  VarianceReport,
  WasteReport
} from "../Inventory";

const { Icon, Dropdown } = UIComponents;

const getReportComponent = (link: string) => {
  switch (link) {
    case "/daily-labor-report":
      return <DailyLaborReport />;
    case "/summary-report":
      return <CloseOutReport />;
    case "/activity-report":
      return <ActivityReport />;
    case "/distribution-and-labor":
      return <DistributionAndLaborReport />;
    case "/sales-mix-report":
      return <SalesMix />;
    case "/discount-summary-report":
      return <DiscountSummary />;
    case "/sales-mix-report-by-server-csv":
      return <SalesMixByServerCSV />;
    case "/weekly-sales-mix-report":
      return <WeeklySalesMix />;
    case "/weekly-sales-summary":
      return <WeeklySalesSummary />;
    case "/time-card-summary":
      return <TimeCardSummary />;
    case "/weekly-employee-salary-summary":
      return <WeeklyEmployeeSalarySummary />;
    case "/sales-mix-report-csv":
      return <SalesMixCSV />;
    case "/daily-sales-report":
      return <DailySalesReport isMediaReport={false} />;
    case "/daily-media-report":
      return <DailySalesReport isMediaReport={true} />;
    case "/tip-by-menu":
      return <TipsByMenu />;
    case "/bank-deposit":
      return <BankDeposit />;
    case "/daily-waste-report":
      return <WasteReport />
    case "/usage-values-report":
      return <UsageValuesReport />
    case "/usage-counts-report":
      return <UsageCountsReport />
    case "/theoretical-usage-report":
      return <TheoreticalUsageReport />
    case "/variance-report":
      return <VarianceReport />
    case "/inventory-items-report":
      return <InventoryReport />
    case "/sales-mix-by-store-report":
      return <SalesMixByStore />
    case "/sales-mix-by-product-report":
      return <SalesMixByProduct />
    case "/menu-engineering-report":
      return <MenuEngineering />
    case "/invoices-report":
      return <Invoices />
  }
};
const dropdownOption: { label: string; value: string }[] = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Sales",
    value: "sales",
  },
  {
    label: "Labour",
    value: "labour",
  },
  {
    label: "Inventory",
    value: "inventory",
  },
  {
    label: "Marketing",
    value: "marketing",
  },
  {
    label: "Admin",
    value: "admin",
  },
];
const CoreReports = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedReportType, setSelectedReportType] = useState<{
    label: string;
    value: string;
  }>({ label: "All", value: "all" });
  const [searchQuery, setSearchQuery] = useState("");
  const user: StaffMember = useSelector((state: RootState) => state.user);
  const emulatorMode: boolean = useSelector(
    (state: RootState) => state.emulatorMode
  );
  const [selectedReport, setSelectedReport] = useState<{
    label: string;
    link: string;
  } | null>(null);
  const isEnterpriseLevel = !emulatorMode && user?.corporateAccess;
  const reportTypes: { label: string; link: string; reportType?: string }[] = [
    {
      label: "Summary Reports",
      link: "/summary-report",
      reportType: "labour",
    },
    {
      label: "Bank Deposit Report",
      link: "/bank-deposit",
      reportType: "admin",
    },
    {
      label: "Daily Labor Reports",
      link: "/daily-labor-report",
      reportType: "labour",
    },
    {
      label: "Activity Report",
      link: "/activity-report",
      reportType: "labour",
    },
    // {
    //   label: "Distribution And Labor Reports",
    //   link: "/distribution-and-labor",
    // },
    {
      label: "Daily Sales Mix Report",
      link: "/sales-mix-report",
      reportType: "sales",
    },
    {
      label: "Discount Summary Report",
      link: "/discount-summary-report",
      reportType: "sales",
    },
    {
      label: "Daily Sales Mix by Server CSV",
      link: "/sales-mix-report-by-server-csv",
      reportType: "sales",
    },
    {
      label: "Weekly Sales Mix Report",
      link: "/weekly-sales-mix-report",
      reportType: "sales",
    },
    {
      label: "Weekly Sales Report",
      link: "/weekly-sales-summary",
      reportType: "sales",
    },
    {
      label: "Time Card Summary Report",
      link: "/time-card-summary",
      reportType: "labour",
    },
    {
      label: "Weekly Employee Salary Summary Report",
      link: "/weekly-employee-salary-summary",
      reportType: "labour",
    },
    {
      label: "Sales Mix CSV",
      link: "/sales-mix-report-csv",
      reportType: "sales",
    },
    {
      label: "Daily Sales Report",
      link: "/daily-sales-report",
      reportType: "sales",
    },
    {
      label: "Daily Media Report",
      link: "/daily-media-report",
      reportType: "sales",
    },
    {
      label: "Tip by menu",
      link: "/tip-by-menu",
      reportType: "sales",
    },
    {
      label: "Daily Waste",
      link: "/daily-waste-report",
      reportType: "inventory",
    },
    {
      label: "Usage Values",
      link: "/usage-values-report",
      reportType: "inventory",
    },
    {
      label: "Usage Counts",
      link: "/usage-counts-report",
      reportType: "inventory",
    },
    {
      label: "Theoretical Usage",
      link: "/theoretical-usage-report",
      reportType: "inventory",
    },
    {
      label: "Variance",
      link: "/variance-report",
      reportType: "inventory",
    },
    {
      label: "Inventory",
      link: "/inventory-items-report",
      reportType: "inventory",
    },
    {
      label: "Sales Mix by Store",
      link: "/sales-mix-by-store-report",
      reportType: "inventory",
    },
    {
      label: "Sales Mix by Product",
      link: "/sales-mix-by-product-report",
      reportType: "inventory",
    },
    {
      label: "Menu Engineering",
      link: "/menu-engineering-report",
      reportType: "inventory",
    },
    {
      label: "Invoices",
      link: "/invoices-report",
      reportType: "inventory",
    },
  ];
  const filteredReports = useMemo(() => {
    if (selectedReportType?.value === "all") {
      return reportTypes;
    }
    return reportTypes.filter(
      (reportItem) => reportItem.reportType === selectedReportType.value
    );
  }, [selectedReportType]);
  if (isEnterpriseLevel) {
    return <EnterpriseCoreReports />;
  }
  return (
    <Layout>
      <div
        className={
          "flex overflow-hidden absolute top-[76px] left-0 right-0 bottom-0"
        }
      >
        <div className={"flex w-5/12 overflow-y-auto flex-col"}>
          <div className="px-8 mt-4 flex">
            <div className="w-4/12">
              <Dropdown
                options={dropdownOption}
                size="medium"
                value={selectedReportType}
                onChange={(newValue) => {
                  //@ts-ignore
                  const { label, value } = newValue;
                  setSelectedReportType({ label, value });
                }}
              />
            </div>
            <div className="flex w-8/12 bg-blue-grey-2 ml-4 rounded-full relative">
              <input
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border-0 bg-transparent pl-9 w-full"
                placeholder="search"
              />
              <Icon name="search" size="16" className="absolute top-2 left-5" />
            </div>
          </div>
          <ul className={"p-8 m-0 list-none w-full"}>
            {filteredReports.map((item, index) => {
              let color = "";
              let borderColor = "";
              if (item.reportType === "labour") {
                color = "bg-blue-2";
                borderColor = "border-blue-3";
              }
              if (item.reportType === "sales") {
                color = "bg-green-2";
                borderColor = "border-green-3";
              }
              if (item.reportType === "inventory") {
                color = "bg-yellow-2";
                borderColor = "border-yellow-4";
              }
              if (item.reportType === "admin") {
                color = "bg-orange-2";
                borderColor = "border-orange-4";
              }
              if (
                searchQuery.length &&
                item.label.toLocaleLowerCase().search(searchQuery) === -1
              ) {
                return false;
              }
              return (
                <li
                  key={index}
                  className={
                    "mb-4 cursor-pointer  border border-solid border-grey-1 px-6 py-7 rounded-xl relative"
                  }
                >
                  <div
                    onClick={() => setSelectedReport(item)}
                    className={"flex items-center justify-between"}
                  >
                    <div className={"text-base text-black font-lato-black"}>
                      {item.label}
                    </div>
                    <div>
                      <Icon name={"chevron-right"} size={"24"} />
                    </div>
                  </div>
                  <span
                    className={`${color} ${borderColor} border-solid rounded-full text-xs font-lato-bold text-black absolute right-14 top-7 px-3`}
                  >
                    {item.reportType}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={"flex w-7/12 p-8 overflow-y-auto"}>
          {selectedReport ? getReportComponent(selectedReport.link) : null}
        </div>
      </div>
    </Layout>
  );
  return (
    <Layout>
      <div id="cash-summary" className="daily-labor-report">
        <div className="navigation">
          <div className="navigation-title">Report</div>
          <div className="navigation-subtitle">Select to view below</div>
          {/* <Link to="/reporting">
            <div className="button">Sales Summary</div>
          </Link>
          <Link to="/product-analytics">
            <div  className="button">Product Analytics</div>
          </Link> */}
          <Link to="/core-reports">
            <div className="button active">Core Reports</div>
          </Link>
          <Link to="/bank-deposit">
            <div className="button">Bank Deposit Report</div>
          </Link>

          {/*
          <div  className="button">Cash Summary</div>
          <Link to="/labor-payroll">
            <div  className="button active">Labor/Payroll</div>
          </Link>
          <div className="button">Inventory  Reports</div>
          <div className="button" >Customer  Reports</div>
          <div className="button">Transaction  Reports</div>
          */}
        </div>
        <div className="right-section" id="labor-payroll">
          <div className="body">
            <div className="page-header">
              <div className="page-title">Core Reports</div>
            </div>
            <div className="report-boxes">
              <Grid container spacing={5} columns={18}>
                <Grid item xs={6}>
                  <Link to="/summary-report" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">Summary Reports</div>
                      </div>
                      <div className="report-description">
                        Summary of transactions by day.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/daily-labor-report" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">Daily Labor Reports</div>
                      </div>
                      <div className="report-description">
                        Exportable reports breaking down labor costs by day.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/activity-report" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">Activity Report</div>
                      </div>
                      <div className="report-description">
                        Exportable daily activity reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/distribution-and-labor" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">
                          Distribution And Labor Reports
                        </div>
                      </div>
                      <div className="report-description">
                        Exportable detailed daily labor and distribution
                        reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                {/* <Grid item xs={6}  >
              <Link to="/daily-labor-report" style={{ width: '100%'}}>
                <div className="report-box">
                  <div className="report-box-header">
                    <img src={require('../../../../assets/24-hour.svg').default}/>
                    <div className="report-title">
                      Summary Receipt Report
                    </div>
                  </div>
                  <div className="report-description">
                    Exportable reports breaking down labor costs by day.
                  </div>
                </div>
              </Link>
            </Grid> */}
                <Grid item xs={6}>
                  <Link to="/sales-mix-report" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">
                          Daily Sales Mix Report
                        </div>
                      </div>
                      <div className="report-description">
                        Exportable daily sales mix reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/discount-summary-report" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">
                          Discount Summary Report
                        </div>
                      </div>
                      <div className="report-description">
                        Exportable weekly discount summary reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                {/* <Grid item xs={6}>
                  <Link to="/void-summary-report" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">
                          Void Summary Report
                        </div>
                      </div>
                      <div className="report-description">
                        Exportable weekly void summary reports.
                      </div>
                    </div>
                  </Link>
                </Grid> */}
                <Grid item xs={6}>
                  <Link
                    to="/sales-mix-report-by-server-csv"
                    style={{ width: "100%" }}
                  >
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">
                          Daily Sales Mix by Server CSV
                        </div>
                      </div>
                      <div className="report-description">
                        Exportable daily sales mix by server reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/weekly-sales-mix-report" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">
                          Weekly Sales Mix Report
                        </div>
                      </div>
                      <div className="report-description">
                        Exportable weekly sales mix reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/weekly-sales-summary" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">Weekly Sales Report</div>
                      </div>
                      <div className="report-description">
                        Exportable weekly sales reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/time-card-summary" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">
                          Time Card Summary Report
                        </div>
                      </div>
                      <div className="report-description">
                        Exportable time card summary reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link
                    to="/weekly-employee-salary-summary"
                    style={{ width: "100%" }}
                  >
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">
                          Weekly Employee Salary Summary Report
                        </div>
                      </div>
                      <div className="report-description">
                        Exportable weekly salary reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/sales-mix-report-csv" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">
                          Daily Sales Mix Report CSV
                        </div>
                      </div>
                      <div className="report-description">
                        Exportable daily sales mix reports in CSV format.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/daily-sales-report" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">Daily Sales Report</div>
                      </div>
                      <div className="report-description">
                        Exportable daily sales reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/daily-media-report" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">Daily Media Report</div>
                      </div>
                      <div className="report-description">
                        Exportable daily media reports.
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link to="/tip-by-menu" style={{ width: "100%" }}>
                    <div className="report-box">
                      <div className="report-box-header">
                        <div className="report-title">Tip by menu</div>
                      </div>
                      <div className="report-description">
                        Exportable daily total tips earned by menu.
                      </div>
                    </div>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <LaborReportModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
      />
    </Layout>
  );
};
export default CoreReports;
