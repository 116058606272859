import React from "react";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { CombinedItem } from "controllers/inventoryItemInfo";


export type Ingredient = {
  uniqueId: string;
  itemId: string | null;
  amount: number;
};

type Instruction = RenderInstruction<Ingredient>;

const instructions: { [attr: string]: Instruction } = {};

instructions.amount = {
  type: "number",
};
// instructions.cost = {
//   type: "currency",
// };
// specific components
type ViewProps = {
  fullObject: Ingredient;
};

type ViewItemProps = ViewProps & {
  items: CombinedItem[];
};

const ViewUnit = ({ fullObject, items }: ViewItemProps) => {
  const selected = fullObject.itemId;
  const sel = items.find((opt) => opt.id == selected);
  return <div>{sel?.ingredientUnitName ?? "Select an item"}</div>;
};

const ViewItemType = ({ fullObject, items }: ViewItemProps) => {
  const selected = fullObject.itemId;
  const sel = items.find((opt) => opt.id == selected);
  return <div>{sel?.isRaw ? "Basic" : "Prep"}</div>;
};

export const instructionsFactory = (
  items: CombinedItem[],
  currency: string,
): { [attr: string]: Instruction } => {

  const ViewCost = ({ fullObject, items }: ViewItemProps) => {
    const selected = fullObject.itemId;
    const sel = items.find((opt) => opt.id == selected);
    if (!sel) return <div> - </div>;
    const {
      defaultCost,
    } = sel;
    const totalCost = fullObject.amount * (defaultCost ?? 0) / 100;
    return <div>{totalCost.toLocaleString("en-US", {
      style: "currency",
      currency,
    })}</div>;
  };
  return {
    ...instructions,
    unit: {
      type: "complex-custom",
      viewComponent: ({ fullObject }) => (
        <ViewUnit fullObject={fullObject} items={items} />
      ),
      editComponent: ({ fullObject }) => (
        <ViewUnit fullObject={fullObject} items={items} />
      ),
    },
    itemId: {
      type: "select",
      placeholder: "Select an item",
      options: items.map((i) => ({
        value: i.id,
        label: i.name,
      })),
    },
    cost: {
      type: "complex-custom",
      viewComponent: ({ fullObject }) => (
        <ViewCost fullObject={fullObject} items={items} />
      ),
      editComponent: ({ fullObject }) => (
        <ViewCost fullObject={fullObject} items={items} />
      ),
    },
    itemType: {
      type: "complex-custom",
      viewComponent: ({ fullObject }) => (
        <ViewItemType fullObject={fullObject} items={items} />
      ),
      editComponent: ({ fullObject }) => (
        <ViewItemType fullObject={fullObject} items={items} />
      ),
    }
  };
};

